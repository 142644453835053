import { titles } from '../../../styles/colors';
import { truncateTextStyle } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		'@media only screen and (min-width: 1024px)': {
			maxWidth: 200,
		},
		alignItems: 'center',
		background: '#D7EBF7',
		borderRadius: 20,
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: 12,
		justifyConent: 'center',
		margin: '2px 0',
		maxWidth: 160,
		padding: '5px 10px',
	},
	excludeTag: {
		background: '#FEF3F5',
		color: titles,
	},
	tagRemoveButton: {
		margin: '-5px -5px -5px 5px',
		padding: 5,
	},
	tagText: {
		...truncateTextStyle,
	},
});
