import { StyleSheet } from 'aphrodite';

export const ContactsSelfImportContentPadding = 20;

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
	},
	containerVariableHeight: {
		height: 'auto',
	},
	content: {
		boxSizing: 'border-box',
	},
	importContent: {
		marginTop: '16.6vh',
	},
	mapContent: {
		height: '100%',
		width: '100%',
	},
	uploadContent: {
		maxWidth: 870,
		padding: '30px 0',
		width: '75%',
	},
});
