import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryText, titles } from '../../styles/colors';
import { CssSelectors, cardStyle } from '../../styles/styles';

const cardSpacing = 15;
const cardHeight = 240;
export const styleSheet = StyleSheet.create({
	card: {
		':focus': {
			background: '#f7fcff',
			border: `1px dashed ${brandPrimary}`,
		},
		...cardStyle,
		alignItems: 'center',
		border: `1px solid transparent`,
		color: brandPrimaryText,
		display: 'inline-flex',
		flexDirection: 'column',
		fontSize: 16,
		height: cardHeight,
		justifyContent: 'center',
		marginLeft: cardSpacing,
		marginTop: cardSpacing,
		textAlign: 'center',
		width: 300,
		...CssSelectors.allChildren(
			{
				marginTop: 13,
			},
			':not(:first-child)'
		),
		...CssSelectors.allChildren(
			{
				height: 160,
				width: 160,
			},
			'.svg-icon'
		),
	},
	cards: {
		marginLeft: -(cardSpacing * 2),
		overflow: 'hidden',
		padding: `${cardSpacing}px 0 ${cardSpacing}px ${cardSpacing}px`,
	},
	cardsContent: {
		display: 'flex',
		flexWrap: 'wrap',

		minHeight: cardHeight,
		position: 'relative',
	},
	container: {
		boxSizing: 'border-box',
		padding: '26px 0',
	},
	header: {
		color: titles,
		...CssSelectors.allChildren(
			{
				color: titles,
				fontSize: 18,
				margin: 0,
			},
			'h3'
		),
	},
	headerDetails: {
		color: '#4a4a4a',
		fontSize: 14,
		margin: '8px 0 0',
	},
	triggerSettings: {
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	triggerSettingsModal: {
		...CssSelectors.allDescendants(
			{
				width: 500,
			},
			'.modal-overlay-content'
		),
	},
});
