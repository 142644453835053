import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const NewLeadsAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon
			className={`new-leads-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<g filter='url(#newLeadsAutomationTriggerGraphicFilter)'>
				<circle cx='86' cy='86' r='80' fill='url(#newLeadsAutomationTriggerGraphicGradient)' />
			</g>
			<mask id='newLeadsAutomationTriggerGraphicMask' maskUnits='userSpaceOnUse' x='6' y='6' width='160' height='160'>
				<circle cx='86' cy='86' r='80' fill='#fff' />
			</mask>
			<g mask='url(#newLeadsAutomationTriggerGraphicMask)'>
				<path d='M27.22 65.268h37.282v101.118H27.22V65.268z' fill='#BAC2C5' stroke='#858585' strokeWidth='2' />
				<path fillRule='evenodd' clipRule='evenodd' d='M59.364 65.495h3.683V166.16h-3.683V65.495z' fill='#A7ABAD' />
				<path d='M65.275 61.585h44.649v104.801h-44.65V61.585z' fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M65.502 66.723h44.194v1.227H65.502v-1.227zm0 3.683h44.194v1.227H65.502v-1.227z'
					fill='#00AAE8'
				/>
				<path fill='#00AAE8' d='M67.958 61.812h1.228v8.593h-1.228z' />
				<path fillRule='evenodd' clipRule='evenodd' d='M71.64 61.812h1.228v6.138H71.64v-6.138z' fill='#00AAE8' />
				<path fill='#00AAE8' d='M76.551 61.812h1.228v6.138h-1.228zm3.683 0h1.228v8.593h-1.228z' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M85.144 61.812h1.228v6.138h-1.228v-6.138zm3.683 0h1.228v6.138h-1.228v-6.138z'
					fill='#00AAE8'
				/>
				<path
					fill='#00AAE8'
					d='M93.737 61.812h1.228v8.593h-1.228zm3.683 0h1.228v6.138H97.42zm3.683 0h1.228v6.138h-1.228z'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M106.013 61.812h1.228v8.594h-1.228v-8.594zm4.911-2.455l6.138 14.03V93.73h-6.138V59.357z'
					fill='#00AAE8'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M113.379 101.096h55.242v63.835h-55.242v-63.835z'
					fill='#E8F3F9'
				/>
				<path d='M111.924 95.958h58.152v1.683h-58.152v-1.683z' fill='#CAE0EC' stroke='#00AAE8' strokeWidth='2' />
				<path d='M111.424 99.14h.227v67.746h-.227V99.141z' fill='#D8D8D8' stroke='#00AAE8' />
				<path
					d='M120.74 143.607a1 1 0 011-1h5.375a1 1 0 011 1v16.205a1 1 0 01-1 1h-5.375a1 1 0 01-1-1v-16.205z'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<ellipse cx='122.419' cy='152.058' rx='.67' ry='.697' stroke='#00AAE8' />
				<path
					d='M119.401 161.419h10.053v2.18h-10.053v-2.18zm-1.339 2.787h12.731v2.18h-12.731v-2.18z'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<rect
					x='119.289'
					y='105.778'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path stroke='#00AAE8' strokeWidth='.694' d='M123.494 106.539h.694v9.909h-.694z' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M118.643 110.787h10.396v.707h-10.396z' />
				<rect
					x='119.289'
					y='124.156'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path stroke='#00AAE8' strokeWidth='.694' d='M123.494 124.917h.694v9.909h-.694z' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M118.643 129.164h10.396v.707h-10.396z' />
				<rect
					x='136.638'
					y='105.778'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M140.996 106.692h.388v9.603h-.388v-9.603z' stroke='#00AAE8' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M135.992 110.787h10.396v.707h-10.396z' />
				<rect
					x='136.638'
					y='124.156'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M140.996 125.07h.388v9.603h-.388v-9.603z' stroke='#00AAE8' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M135.992 129.164h10.396v.707h-10.396z' />
				<rect
					x='136.638'
					y='142.534'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M140.996 143.448h.388v9.602h-.388v-9.602z' stroke='#00AAE8' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M135.992 147.542h10.396v.707h-10.396z' />
				<rect
					x='153.987'
					y='105.778'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M158.345 106.692h.387v9.603h-.387v-9.603z' stroke='#00AAE8' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M153.34 110.787h10.396v.707H153.34z' />
				<rect
					x='153.987'
					y='124.156'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M158.345 125.07h.387v9.603h-.387v-9.603z' stroke='#00AAE8' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M153.34 129.164h10.396v.707H153.34z' />
				<rect
					x='153.987'
					y='142.534'
					width='9.103'
					height='10.723'
					rx='1'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M158.345 143.448h.387v9.602h-.387v-9.602z' stroke='#00AAE8' />
				<path stroke='#00AAE8' strokeWidth='.707' d='M153.34 147.542h10.396v.707H153.34z' />
				<path fill='#D8D8D8' stroke='#00AAE8' strokeWidth='.614' d='M156.652 85.444h.614v9.207h-.614z' />
				<path
					d='M151.935 86.865h.227v6.365h-.227v-6.365zm4.394 1.246l.235.646-3.893 1.417-.235-.646 3.893-1.417zm0 2.572l.235.646-3.893 1.417-.235-.646 3.893-1.417z'
					fill='#D8D8D8'
					stroke='#00AAE8'
				/>
				<ellipse cx='171.076' cy='146.916' rx='7.366' ry='7.765' fill='#DBF2C3' stroke='#89C947' strokeWidth='2' />
				<path fill='#00AAE8' d='M70.413 75.316h13.81v2.902h-13.81z' />
				<path fill='#fff' stroke='#00AAE8' strokeWidth='2' d='M72.794 77.767h9.048v12.508h-9.048z' />
				<path fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' d='M92.129 77.767h9.048v12.508h-9.048z' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M89.748 75.316h13.81v2.902h-13.81v-2.902zM73.175 82.57h8.286v1.45h-8.286v-1.45zm19.335 0h8.286v1.45H92.51v-1.45z'
					fill='#00AAE8'
				/>
				<path d='M33.357 73.86h9.049v11.505h-9.049V73.86z' fill='#D3DBDE' stroke='#858585' strokeWidth='2' />
				<path d='M49.316 73.86h9.048v11.505h-9.048V73.86z' fill='#fff' stroke='#858585' strokeWidth='2' />
				<path fillRule='evenodd' clipRule='evenodd' d='M33.585 77.771h8.593V79h-8.593v-1.23z' fill='#858585' />
				<path fill='#858585' d='M49.544 77.771h8.593v1.228h-8.593z' />
				<path
					d='M33.357 93.502h9.049v11.504h-9.049V93.502zm15.959 0h9.048v11.504h-9.048z'
					fill='#fff'
					stroke='#858585'
					strokeWidth='2'
				/>
				<path fillRule='evenodd' clipRule='evenodd' d='M33.585 97.413h8.593v1.227h-8.593v-1.227z' fill='#858585' />
				<path fill='#858585' d='M49.544 97.413h8.593v1.228h-8.593z' />
				<path d='M33.357 113.144h9.049v11.504h-9.049v-11.504z' fill='#fff' stroke='#858585' strokeWidth='2' />
				<path d='M49.316 113.144h9.048v11.504h-9.048v-11.504z' fill='#D3DBDE' stroke='#858585' strokeWidth='2' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M33.585 118.282h8.593v1.228h-8.593v-1.228zm15.959 0h8.593v1.228h-8.593v-1.228z'
					fill='#858585'
				/>
				<path
					d='M33.357 132.786h9.049v11.503h-9.049v-11.503zm15.959 0h9.048v11.503h-9.048v-11.503z'
					fill='#fff'
					stroke='#858585'
					strokeWidth='2'
				/>
				<path fillRule='evenodd' clipRule='evenodd' d='M33.585 137.924h8.593v1.227h-8.593v-1.227z' fill='#858585' />
				<path fill='#858585' d='M49.544 137.924h8.593v1.228h-8.593z' />
				<path d='M32.13 154.883h26.234v11.503H32.13v-11.503z' fill='#D3DBDE' stroke='#858585' strokeWidth='2' />
				<path fill='#858585' d='M32.357 157.565h28.235v1.228H32.357z' />
				<path fill='#00AAE8' d='M70.413 97.413h13.81v2.902h-13.81z' />
				<path
					d='M72.794 99.864h9.048v12.508h-9.048V99.864zm19.335 0h9.048v12.508h-9.048V99.864z'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M89.748 97.413h13.81v2.901h-13.81v-2.901zm-16.573 7.254h8.286v1.451h-8.286v-1.451zm19.335 0h8.286v1.451H92.51v-1.451z'
					fill='#00AAE8'
				/>
				<path fill='#00AAE8' d='M70.413 120.737h13.81v2.902h-13.81z' />
				<path d='M72.794 123.188h9.048v12.508h-9.048v-12.508z' fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' />
				<path d='M92.129 123.188h9.048v12.508h-9.048v-12.508z' fill='#fff' stroke='#00AAE8' strokeWidth='2' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M89.748 120.737h13.81v2.902h-13.81v-2.902zm-16.573 7.254h8.286v1.451h-8.286v-1.451zm19.335 0h8.286v1.451H92.51v-1.451zm-22.097 14.843h13.504v2.455H70.412v-2.455z'
					fill='#00AAE8'
				/>
				<path
					d='M71.413 146.289h11.504v11.504H71.412v-11.504zm.351 12.276h12.03v2.209h-12.03zm-1.404 2.806h14.836v2.209H70.361v-2.209zm-1.402 2.806h17.641v2.209H68.958v-2.209zm23.324-17.888h9.048v11.504h-9.048z'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path fill='#00AAE8' d='M90.055 142.834h13.504v2.455H90.055z' />
				<path fillRule='evenodd' clipRule='evenodd' d='M92.51 150.2h8.593v1.227H92.51V150.2z' fill='#00AAE8' />
				<mask id='b' fill='#fff'>
					<path fillRule='evenodd' clipRule='evenodd' d='M101.001 158.858h1.432v8.528h-1.432v-8.528z' />
				</mask>
				<path
					d='M101.001 158.858v-2h-2v2h2zm1.432 0h2v-2h-2v2zm0 8.528v2h2v-2h-2zm-1.432 0h-2v2h2v-2zm0-6.528h1.432v-4h-1.432v4zm-.568-2v8.528h4v-8.528h-4zm2 6.528h-1.432v4h1.432v-4zm.568 2v-8.528h-4v8.528h4z'
					fill='#00AAE8'
					mask='url(#b)'
				/>
				<path
					d='M101.092 157.858l-.386-2.975h2.307a2 2 0 012 2v.975h-3.921z'
					fill='#00AAE8'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path d='M97.42 156.031a2.148 2.148 0 114.297 0v2.827H97.42v-2.827z' fill='#CAE0EC' />
				<ellipse cx='64.275' cy='140.162' rx='8.593' ry='8.377' fill='#DBF2C3' stroke='#89C947' strokeWidth='2' />
				<mask id='c' fill='#fff'>
					<path fillRule='evenodd' clipRule='evenodd' d='M63.559 142.256h1.432v25.13h-1.432v-25.13z' />
				</mask>
				<path
					d='M63.559 142.256v-2h-2v2h2zm1.432 0h2v-2h-2v2zm0 25.13v2h2v-2h-2zm-1.432 0h-2v2h2v-2zm0-23.13h1.432v-4h-1.432v4zm-.568-2v25.13h4v-25.13h-4zm2 23.13h-1.432v4h1.432v-4zm.568 2v-25.13h-4v25.13h4z'
					fill='#858585'
					mask='url(#c)'
				/>
				<path fill='transparent' d='M75.096 149.972h4.138v7.821h-4.138v-7.821z' stroke='#00AAE8' strokeWidth='2' />
				<circle cx='86.419' cy='35.412' r='13.033' fill='#fff' stroke='#89C947' strokeWidth='2' />
				<path
					d='M86.519 42.24v-1.3c.935-.082 1.67-.354 2.203-.815.533-.461.8-1.062.8-1.801 0-.396-.07-.743-.212-1.041a2.433 2.433 0 00-.61-.796 4.296 4.296 0 00-.982-.627 14.797 14.797 0 00-1.482-.59c-.602-.21-1.013-.414-1.234-.614-.221-.2-.332-.465-.332-.796 0-.357.113-.636.34-.839.226-.202.545-.303.958-.303.417 0 .75.13 1 .388.249.26.373.65.373 1.172h2.18c0-.849-.25-1.54-.754-2.072-.503-.533-1.2-.854-2.09-.963v-1.416H85.47v1.396c-.9.079-1.623.353-2.166.823-.543.47-.815 1.072-.815 1.807 0 .427.08.794.238 1.103.158.31.38.578.664.806a4.6 4.6 0 001.022.607c.398.176.87.354 1.419.532.548.178.937.373 1.166.584.229.211.343.495.343.852a.988.988 0 01-.385.819c-.256.202-.606.303-1.049.303-.533 0-.941-.138-1.226-.414-.284-.276-.426-.673-.426-1.191h-2.18c0 .892.282 1.606.848 2.144.566.537 1.365.854 2.396.95v1.291h1.2z'
					fill='#89C947'
				/>
			</g>
			<defs>
				<linearGradient
					id='newLeadsAutomationTriggerGraphicGradient'
					x1='-15.956'
					y1='13.341'
					x2='-15.956'
					y2='217.253'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E2ECFF' />
					<stop offset='1' stopColor='#FFF0EE' />
				</linearGradient>
				<filter
					id='newLeadsAutomationTriggerGraphicFilter'
					x='0'
					y='0'
					width='172'
					height='172'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
					<feOffset />
					<feGaussianBlur stdDeviation='3' />
					<feColorMatrix values='0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0.100034 0' />
					<feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
					<feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
				</filter>
			</defs>
		</SvgIcon>
	);
};
