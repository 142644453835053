import { StyleSheet } from 'aphrodite';
import { brandSecondary, mention, spanishBlue } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	dragContainer: {
		alignItems: 'center',
		background: mention,
		border: `2px dashed rgba(0,170,232, 1)`,
		borderRadius: 6,
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		cursor: 'pointer',
		height: 250,
		marginBottom: 20,
		opacity: 1,
		textAlign: 'center',
		width: 510,
	},
	dragContainerHover: {
		border: `4px dashed rgba(0,170,232, 1)`,
	},
	dragFooter: {
		color: brandSecondary,
		fontSize: 12,
	},
	dragInfo: {
		marginTop: 60,
	},
	dragText: {
		color: spanishBlue,
		fontSize: 18,
	},
});
