import { StyleSheet } from 'aphrodite';

export const AiAssistantButtonHeight = 28;

export const styleSheet = StyleSheet.create({
	container: {
		fontSize: 12,
		height: AiAssistantButtonHeight,
	},
	icon: {
		filter: 'drop-shadow(0px 0px 16px rgba(0, 170, 232, 0.355447))',
		height: AiAssistantButtonHeight,
		width: 'auto',
	},
});
