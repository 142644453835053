import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	autocompleteBox: {
		width: 350,
	},
	iconSpan: {
		margin: '4px 8px 0px 4px',
	},
	searchBox: {
		backgroundColor: '#f9f9f9',
		border: '1px solid #CCC',
		borderRadius: 3,
		color: '#4a4a4a',
		display: 'inline-block',
		fontSize: 14,
		height: 40,
		outline: 'none',
		padding: 6,
		verticalAlign: 'middle',
		width: 458,
	},
	triggerSettings: {
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	triggerSettingsTitle: {
		marginBottom: 20,
	},
});
