import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	checkboxText: {
		fontSize: 14,
	},
	checkboxWrapper: {},
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		gap: 8,
	},
});
