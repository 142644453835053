import { StyleSheet } from 'aphrodite';
import { destructive, titles, warningDark } from '../../../styles/colors';
import { CssSelectors, Layouts, cardStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	actions: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	actionsVertical: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		...Layouts.verticalStack(10),
		...CssSelectors.allChildren(
			{
				marginRight: 0,
			},
			'button'
		),
	},
	approvalStatusTitle: {
		color: warningDark,
		fontSize: 14,
		lineSpacing: '1.1px',
		textAlign: 'left',
		textTransform: 'uppercase',
	},
	approvedStatusDate: {
		alignItems: 'flex-end',
		fontSize: 14,
		lineSpacing: '1.1px',
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	approvedStatusTitle: {
		color: '#89C947',
		fontSize: 14,
		lineSpacing: '1.1px',
		textAlign: 'left',
		textTransform: 'uppercase',
	},
	buttonActions: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 10,
		...CssSelectors.allChildren(
			{
				fontSize: 14,
			},
			'button'
		),
	},
	cancelButton: {
		flex: 1,
	},
	cancelInstructions: {
		color: destructive,
		fontSize: 14,
	},
	card: {
		...cardStyle,
		boxSizing: 'border-box',
		color: '#4a4a4a',
		fontSize: 14,
		marginRight: 10,
		marginTop: 20,
		padding: '15px 20px',
	},
	cardActions: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		...CssSelectors.allChildren(
			{
				fontSize: 14,
			},
			'button'
		),
	},
	cardTitle: {
		color: titles,
		fontSize: 16,
		margin: '0 0 6px',
	},
	container: {
		...Layouts.verticalStack(20),
		overflowY: 'auto',
	},
	complianceButtons: {
		alignItems: 'flex-start',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 10,
		marginLeft: 20,
		...CssSelectors.allChildren(
			{
				fontSize: 14,
				marginBottom: 8,
			},
			'button'
		),
	},
	complianceIcon: {
		marginRight: 10,
	},
	complianceInput: {
		marginBottom: 10,
		minWidth: 240,
	},
	previewContentTitle: {
		fontSize: 16,
	},
	recipientsCard: {
		fontSize: 14,
	},
	sideHeader: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	socialContainer: {
		...Layouts.verticalStack(20),
	},
	tagList: {
		marginTop: 15,
	},
});
