import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		display: 'inline-block',
	},
	container: {
		textAlign: 'center',
	},
	placeholderClearButton: {
		marginTop: 14,
	},
	placeholderMessage: {
		color: '#4A4A4A',
		fontSize: 14,
		lineHeight: '22px',
		marginTop: 20,
	},
});
