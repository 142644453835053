import { StyleSheet } from 'aphrodite';
import {
	background,
	brandPrimary,
	brandPrimaryHover,
	darkGrayFontColor,
	destructive,
	grayIconFill,
	header,
	inputBorderColor,
	mention,
	mentionDark,
	navigation,
	spanishBlue,
	titles,
} from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	aiAssistantWrap: {
		marginBottom: 10,
	},
	bodyEditor: {
		...CssSelectors.allDescendants(
			{
				overflow: 'inherit',
			},
			'.tox.tox-tinymce'
		),
		...CssSelectors.allDescendants(
			{
				overflow: 'inherit',
			},
			'.tox.tox-tinymce .tox-editor-container'
		),
		...CssSelectors.allDescendants(
			{
				background: '#fff',
				borderBottom: '1px solid #ccc',
				position: 'sticky',
				top: 0,
				zIndex: 1,
			},
			'.tox.tox-tinymce .tox-editor-container .tox-editor-header'
		),
	},
	contentWrap: {
		padding: '20px 20px 68px 35px',
	},
	container: {
		border: `1px solid #CAE0EC`,
		flex: '1 0 0%',
		margin: 15,
		borderRadius: 3,
		boxShadow: `0 0 12px 0 #E8F3F9`,
	},
	subHeader: {
		borderBottom: `1px solid #CAE0EC`,
		borderRadius: 3,
		boxSizing: 'border-box',
		padding: '12px 20px 12px 35px',
	},
	subHeaderContent: {
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		...CssSelectors.allChildren(
			{
				flex: 1,
				margin: 0,
				color: brandPrimary,
			},
			'h1'
		),
	},
	contentBlock: {
		marginBottom: 68,
	},
	contentContainers: {
		color: '#a7abad',
		fontSize: 14,
	},
	dropdownAnchorText: {
		maxWidth: 600,
	},
	dropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		maxHeight: 300,
		overflowY: 'auto',
		width: '100%',
	},
	dropdownInline: {
		borderColor: inputBorderColor,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		padding: 10,
		width: '100%',
	},
	dropdownItem: {
		':hover': {
			background: mention,
		},
		cursor: 'pointer',
		padding: 10,
		...CssSelectors.allChildren({
			color: navigation,
			fontSize: 12,
		}),
	},
	footer: {
		display: 'flex',
		margin: '20px 0 0',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		...CssSelectors.allChildren(
			{
				margin: '0 0 10px',
			},
			'button'
		),
	},
	reject: {
		color: destructive,
	},
	sendFromDropdownContainer: {
		color: darkGrayFontColor,
		fontSize: 14,
		marginBottom: 0,
		marginTop: 10,
		width: '100%',
	},
	sendFromDropdown: {
		marginLeft: '0.5rem',
		maxWidth: 260,
		width: '80%',
	},
	addMediaContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 14,
		maxWidth: 365,
	},
	mainContainer: {
		display: 'flex',
		width: '100%',
	},
	menu: {
		display: 'block',
		...CssSelectors.allChildren(
			{
				display: 'inline-flex',
				marginRight: 10,
			},
			'li'
		),
		...CssSelectors.allChildren(
			{
				marginRight: 0,
			},
			'li:nth-child(2)'
		),
	},
	sidebar: {
		width: 368,
		...CssSelectors.allDescendants(
			{
				minHeight: 'calc(100vh - 50px)',
			},
			'.ai-content-generation-wizard'
		),
	},
	sidebarCenter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	question: {
		color: titles,
		fontSize: 18,
		marginTop: 42,
		marginBottom: 36,
		width: 236,
		textAlign: 'center',
	},
	formControl: {
		marginBottom: 18,
		...CssSelectors.allDescendants(
			{
				display: 'block',
				marginBottom: 12,
				fontSize: 12,
				color: header,
				...CssSelectors.allChildren(
					{
						color: destructive,
					},
					'sup'
				),
			},
			'label'
		),
	},
	editor: {
		background,
		border: '1px solid #ccc',
		minHeight: 273,
		height: '33vh',
		margin: '0 0 12px 0',
		width: '100%',
		borderRadius: 8,
		...CssSelectors.allDescendants(
			{
				borderRadius: '8px 8px 0 0',
				borderBottom: `1px solid ${mentionDark}`,
			},
			'.tox-toolbar-overlord'
		),
	},
	iconButtons: {
		background,
		border: `1px solid ${mentionDark}`,
		color: titles,
		display: 'flex',
		fontSize: 16,
		marginBottom: 4,
		minWidth: 142,
		padding: 8,
	},
	freeImage: {
		paddingLeft: 6,
		paddingRight: 6,
	},
	sourceMention: {
		color: grayIconFill,
		fontSize: 12,
		fontStyle: 'italic',
		minWidth: 142,
		textAlign: 'center',
	},
	ownImage: {
		color: spanishBlue,
		fontSize: 14,
		marginBottom: 13,
		marginTop: 12,
	},
	mediaModalStyle: {
		width: 680,
	},
	subTitle: {
		color: brandPrimary,
		fontSize: 16,
		margin: '12px 0 0',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	imageContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		paddingTop: 22,
	},
	imageWrap: {
		width: 305,
		height: 203,
	},
	imageSection: {
		marginLeft: '20px',
		...CssSelectors.allChildren(
			{
				fontSize: 14,
				marginBottom: 12,
				color: titles,
			},
			'h3'
		),
		...CssSelectors.allChildren(
			{
				color: header,
			},
			'p'
		),
	},
	imageButton: {
		cursor: 'pointer',
		color: brandPrimary,
		textDecoration: 'underline',
		background: 'none',
		border: 'none',
		padding: 0,
		fontWeight: 400,
		transitions: 'color 300ms ease-in-out',
		':hover': {
			color: brandPrimaryHover,
		},
	},
	loadingImages: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
