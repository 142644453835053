import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const LightningBulbIcon: React.FC<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon className={`${css(...styles)} lightning-bulb-icon ${className || ''}`} height={60} width={60}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='30' cy='30' r='30' fill='#FFF' />
				<path
					fill='#00AAE8'
					d='M24.5,42.8783042 C19.5021188,40.7410708 16,35.7795172 16,30 C16,22.2680135 22.2680135,16 30,16 C37.7319865,16 44,22.2680135 44,30 C44,35.3857804 40.9587997,40.0612504 36.5,42.4028089 L36.5,47 C36.5,49.209139 34.709139,51 32.5,51 L28.5,51 C26.290861,51 24.5,49.209139 24.5,47 L24.5,42.8783042 Z'
				/>
				<circle cx='28.75' cy='28.75' r='11.75' fill='#45A9F7' />
				<rect width='2' height='8' x='29.5' y='35.5' fill='#006AC5' rx='1' />
				<path
					stroke='#006AC5'
					strokeWidth='4'
					d='M30.5,36.5 L33.0205655,33.6643638 C33.3874835,33.2515811 33.3503028,32.6195087 32.93752,32.2525907 C32.7544689,32.0898786 32.5180703,32 32.2731562,32 L26.5,32'
				/>
				<rect width='10' height='1.5' x='25.5' y='45' fill='#046CB6' rx='.75' />
				<rect width='8' height='1.5' x='26.5' y='47.5' fill='#046CB6' rx='.75' />
				<path stroke='#F3F9FE' strokeWidth='3' d='M25,19.5 C23,20.5 21.3333333,22 20,24' />
				<rect width='2' height='5' x='29' y='6' fill='#00AAE8' />
				<polygon
					fill='#00AAE8'
					points='15.058 11.873 17.058 11.873 17.058 16.873 15.058 16.873'
					transform='rotate(-50 16.058 14.373)'
				/>
				<polygon
					fill='#00AAE8'
					points='43.558 11.873 45.558 11.873 45.558 16.873 43.558 16.873'
					transform='scale(-1 1) rotate(-50 0 109.928)'
				/>
				<polygon
					fill='#00AAE8'
					points='20.795 7.691 22.795 7.691 22.795 12.691 20.795 12.691'
					transform='rotate(-20 21.795 10.191)'
				/>
				<polygon
					fill='#00AAE8'
					points='37.616 7.665 39.616 7.665 39.616 12.665 37.616 12.665'
					transform='rotate(30 38.616 10.165)'
				/>
			</g>
		</SvgIcon>
	);
};

interface ILightBulbProps {
	className?: string;
	fill?: string;
	styles?: StyleDeclarationValue[];
}

export const LightBulbIcon: React.FC<ILightBulbProps> = props => {
	const { className = '', styles = [], fill = '#00AAE8' } = props;
	return (
		<SvgIcon className={`${css(...styles)} light-bulb-icon ${className}`} width={24} height={24}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.999 23h2v1h2v-1h2v-2h-6v2Zm4.666-6.291a1 1 0 0 0-.667.942V18h-2v-.349a1 1 0 0 0-.666-.942A5.008 5.008 0 0 1 6.998 12c0-2.757 2.244-5 5-5 2.757 0 5 2.243 5 5a5.007 5.007 0 0 1-3.333 4.709M11.998 5c-3.859 0-7 3.14-7 7a7.013 7.013 0 0 0 4 6.319V19a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-.681a7.011 7.011 0 0 0 4-6.319c0-3.86-3.14-7-7-7m1.001-5h-2v4h2V0Zm-13 13h4v-2h-4v2Zm20 0h4v-2h-4v2Zm1.707-9.293-1.414-1.414-3 3 1.414 1.414 3-3Zm-15 1.586-3-3-1.414 1.414 3 3 1.414-1.414Z'
				fill={fill}
			/>
		</SvgIcon>
	);
};
