import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimaryText, brandSecondary, nameCircles, titles } from '../../../styles/colors';
import { CssSelectors, Layouts, cardStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	actionItemContentEditor: {
		borderBottom: `1px solid #D8D8D8`,
		marginTop: 10,
		width: '100%',
	},
	actionItemDueDateOption: {
		alignItems: 'center',
		display: 'flex',
	},
	actionItemDueDateOptions: {
		...Layouts.verticalStack(20),
		marginTop: 14,
	},
	addTagOnLinkClickCheckbox: {
		boxSizing: 'border-box',
	},
	advancedOptionsButton: {
		margin: '1rem 0 0.5rem',
	},
	advancedOptionsChevron: {
		marginLeft: '0.25rem',
	},
	advancedOptionsChevronIcon: {
		transform: 'rotate3d(0,0,1,0deg)',
	},
	advancedOptionsChevronIconFlipped: {
		transform: 'rotate3d(0,0,1,180deg)',
	},
	advancedOptionsContent: {
		paddingTop: '0.5rem',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
	birthdayDayOfScheduler: {
		alignItems: 'center',
		display: 'flex',
	},
	card: {
		...cardStyle,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 14,
		overflow: 'visible',
		padding: '14px 0px',
		...CssSelectors.allChildren(
			{
				borderTop: '1px solid #F2F2F2',
				boxSizing: 'border-box',
			},
			':not(:first-child)'
		),
	},
	cardBody: {
		boxSizing: 'border-box',
		padding: 20,
	},
	cardFooter: {
		boxSizing: 'border-box',
		padding: 20,
	},
	cardHeader: {
		boxSizing: 'border-box',
		padding: '0 20px 20px 20px',
		...Layouts.verticalStack(14),
	},
	cardHeaderInfo: {
		color: '#4A4A4A',
		fontSize: 14,
	},
	checkboxText: {
		color: '#4a4a4a',
		fontSize: 14,
		marginBottom: 2,
		marginLeft: 16,
		marginTop: 0,
		width: 500,
	},
	closeButton: {
		marginLeft: -20,
	},
	dayOf: {},
	dayOfDetails: {
		fontSize: 12,
		fontStyle: 'italic',
		marginTop: 14,
	},
	dayOfDetailsRightAlign: {
		textAlign: 'right',
	},
	dayOfDetailsText: {
		display: 'flex',
	},
	dayOfHorizontalLayout: {
		alignItems: 'center',
		display: 'flex',
		width: '100%',
	},
	dayOfInput: {
		textAlign: 'center',
		width: 30,
	},
	dayOfTextInput: {
		margin: '0 5px',
	},
	emailCreateButton: {
		background: '#F2E0EC',
	},
	emailCta: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
		fontSize: 14,
		height: 60,
		justifyContent: 'center',
		width: '100%',
	},
	emailEditButton: {
		background: '#E8F3F9',
	},
	emailNameInput: {
		background: '#fff',
		marginBottom: 10,
	},
	fieldLabel: {
		marginBottom: 4,
	},
	fileArea: {
		display: 'flex',
		padding: 8,
	},
	fileContainer: {
		alignItems: 'center',
		background: '#EDEDED',
		boxSizing: 'border-box',
		color: brandPrimaryText,
		display: 'inline-flex',
		fontSize: 12,
		marginRight: 4,
		padding: '4px 10px',
	},
	header: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'space-between',
	},
	headerTitle: {
		maxWidth: '80%',
	},
	hoursDaysSelect: {
		marginRight: 5,
		minWidth: 92,
	},
	iconSpan: {
		margin: '0.25rem 0.5rem 0 0.25rem',
	},
	image: {
		borderRadius: 8,
		width: '93%',
	},
	imageArea: {
		display: 'flex',
		flex: 1,
		height: '100%',
		marginRight: -22,
		maxWidth: 240,
		padding: 8,
	},
	imageAreaContainer: {
		alignItems: 'flex-end',
		background: '#fff',
		border: `1px solid ${nameCircles}`,
		borderBottom: 0,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		display: 'flex',
		flexWrap: 'wrap',
	},
	noMargin: {
		marginBottom: 0,
	},
	previewContainer: {
		display: 'flex',
		height: '100%',
		padding: 2,
	},
	scheduler: {
		display: 'block',
		width: '100%',
	},
	schedulerQualifierSelect: {
		flexShrink: 0,
		marginRight: 5,
		width: 140,
	},
	schedulerRow: {
		':not(:first-child)': {
			marginTop: 10,
		},
		alignItems: 'center',
		display: 'flex',
		gap: 2,
		width: '100%',
	},
	sizeWarning: {
		textAlign: 'center',
	},
	tagAutomcomplete: {
		marginTop: '0.5rem',
	},
	tagSearchField: {
		width: '100%',
	},
	textingAddOnContainer: {
		marginLeft: 2,
		marginTop: 6,
	},
	textingAddOnText: {
		color: '#767676',
		fontSize: 12,
		fontStyle: 'italic',
	},
	textingCanSpam: {
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				alignSelf: 'flex-end',
			},
			':not(:first-child)'
		),
	},
	textingCanSpamBubble: {
		'::after': {
			background: '#fff',
			borderBottomLeftRadius: '0.5rem',
			bottom: 0,
			content: '""',
			height: '1rem',
			position: 'absolute',
			right: -40,
			transform: `translate(-30px, -2px)`,
			width: 10,
		},
		'::before': {
			borderBottomLeftRadius: '0.8rem 0.7rem',
			borderRight: `1rem solid ${brandSecondary}`,
			bottom: 0,
			content: '""',
			height: '1rem',
			position: 'absolute',
			right: '-0.35rem',
			transform: 'translate(0, -0.1rem)',
		},
		background: brandSecondary,
		borderRadius: 20,
		boxSizing: 'border-box',
		color: '#fff',
		marginTop: 20,
		padding: '8px 20px',
		position: 'relative',
	},
	textingCanSpamBubbleDetails: {
		color: '#858585',
		fontSize: 11,
		marginTop: 10,
	},
	textingContentEditor: {
		marginTop: 10,
		width: '100%',
	},
	textingContentEditorImages: {
		background: '#fff',
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		borderTop: `1px solid ${borderColor}`,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		paddingBottom: 0,
		width: '100%',
	},
	textingFallbackCheckbox: {
		marginTop: 14,
	},
	textingFallbackSettings: {
		color: '#4a4a4a',
		fontSize: 14,
		marginTop: 20,
	},
	textInputArea: {
		borderTop: `1px solid ${borderColor}`,
	},
	timeDropdown: {
		marginLeft: 5,
		...CssSelectors.allChildren(
			{
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	timeDropdownOption: {
		fontSize: 14,
	},
	timeDropdownTrigger: {
		fontSize: 14,
		height: 'auto',
		width: 108,
	},
	transparentBtn: {
		background: 'none',
		border: 'none',
		outlone: 'none',
	},
	typeSelect: {
		width: '100%',
	},
	typeSelectContent: {
		width: '100% !important',
	},
	typeSelectMenuItem: {
		':not(:first-child)': {
			borderTop: `1px solid ${borderColor}`,
		},
		boxSizing: 'border-box',
		padding: '5px 10px',
	},
});
