import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const NewClientAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon
			className={`new-client-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={160}
			width={160}
		>
			<defs>
				<filter
					id='newClientAutomationTriggerGraphicFilter'
					width='111.2%'
					height='111.2%'
					x='-5.6%'
					y='-5.6%'
					filterUnits='objectBoundingBox'
				>
					<feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='3' />
					<feColorMatrix
						in='shadowBlurOuter1'
						values='0 0 0 0 0.54424426   0 0 0 0 0.54424426   0 0 0 0 0.54424426  0 0 0 0.100033967 0'
					/>
				</filter>
				<linearGradient id='path-d' x1='-14.748%' x2='62.526%' y1='106.162%' y2='21.257%'>
					<stop offset='0%' stopColor='#00D5F5' stopOpacity='0' />
					<stop offset='97.029%' stopColor='#00AAE8' />
				</linearGradient>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(6 6)'>
				<mask id='path-c' fill='#fff'>
					<circle cx='80' cy='80' r='80' />
				</mask>
				<circle fill='#000' id='path-a' cx='80' cy='80' r='80' filter='url(#newClientAutomationTriggerGraphicFilter)' />
				<circle fill='#E8F3F9' cx='80' cy='80' r='80' />
				<g mask='url(#path-c)'>
					<g transform='translate(-28 24)'>
						<polygon fill='url(#path-d)' points='34.402 2 0 75.385 0 159.888 163.134 161 182 117.636' opacity='.225' />
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M34,95 L34,117 C46.0224438,117 46.369946,117 59.5858209,117 C73.4384328,117 73.4384328,117 87.8507463,117 C94.7537313,117 94.7537313,117 100.164179,117 C104.279918,117 104.76387,117 107,117 L107,110 C107,101.715729 100.284271,95 92,95 L34,95 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M34,95 L34,111.285714 C59.3106723,111.285714 59.6723325,111.285714 87.8507463,111.285714 C96.4891433,111.285714 102.877231,112.775647 107,115.815273 L107,110 C107,101.715729 100.284271,95 92,95 L34,95 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M34,95 L34,105.571429 C59.3106723,105.571429 59.6723325,105.571429 87.8507463,105.571429 C96.4594457,105.571429 102.877853,108.504093 107,114.341064 L107,110 C107,101.715729 100.284271,95 92,95 L34,95 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M34,1 L34,99.2857143 C59.3106723,99.2857143 59.6723325,99.2857143 87.8507463,99.2857143 C96.4919044,99.2857143 102.901654,103.530223 107.000001,111.88885 L107,16 C107,7.71572875 100.284271,1 92,1 L34,1 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M108,95 L108,117 C120.022444,117 120.369946,117 133.585821,117 C147.438433,117 147.438433,117 161.850746,117 C168.753731,117 168.753731,117 174.164179,117 C178.279918,117 178.76387,117 181,117 L181,110 C181,101.715729 174.284271,95 166,95 L108,95 Z'
							transform='matrix(-1 0 0 1 289 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M108,95 L108,111.285714 C133.310672,111.285714 133.672332,111.285714 161.850746,111.285714 C170.489143,111.285714 176.877231,112.775647 181,115.815273 L181,110 C181,101.715729 174.284271,95 166,95 L108,95 Z'
							transform='matrix(-1 0 0 1 289 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M108,95 L108,105.571429 C133.310672,105.571429 133.672332,105.571429 161.850746,105.571429 C170.459446,105.571429 176.877853,108.504093 181,114.341064 L181,110 C181,101.715729 174.284271,95 166,95 L108,95 Z'
							transform='matrix(-1 0 0 1 289 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M108,1 L108,99.2857143 C133.310672,99.2857143 133.672332,99.2857143 161.850746,99.2857143 C170.491904,99.2857143 176.901654,103.530223 181.000001,111.88885 L181,16 C181,7.71572875 174.284271,1 166,1 L108,1 Z'
							transform='matrix(-1 0 0 1 289 0)'
						/>
						<polygon fill='#00AAE8' points='75 105 75 123 80.5 120.75 86 123 86 105' />
						<g transform='translate(55 15)'>
							<circle cx='13' cy='13' r='13' fill='#FFF' stroke='#00AAE8' strokeWidth='2' />
							<g
								stroke='#00AAE8'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								transform='translate(7.583 6.5)'
							>
								<path d='M10.5050505,11.4493464 L10.5050505,10.203159 C10.5050505,8.82665849 9.32923268,7.71078431 7.87878788,7.71078431 L2.62626263,7.71078431 C1.17581783,7.71078431 1.45786862e-16,8.82665849 0,10.203159 L0,11.4493464' />
								<ellipse cx='5.515' cy='2.492' rx='2.626' ry='2.492' />
							</g>
						</g>
						<rect width='24' height='3' x='56' y='49' fill='#00AAE8' rx='1.5' />
						<rect width='17' height='3' x='56' y='56' fill='#00AAE8' rx='1.5' />
						<rect width='7' height='3' x='54' y='67' fill='#DBF2C3' rx='1.5' />
						<rect width='11' height='3' x='63' y='67' fill='#DBF2C3' rx='1.5' />
						<rect width='11' height='3' x='54' y='73' fill='#DBF2C3' rx='1.5' />
						<rect width='8' height='3' x='76' y='67' fill='#DBF2C3' rx='1.5' />
						<rect width='8' height='3' x='68' y='73' fill='#DBF2C3' rx='1.5' />
						<g transform='translate(130 15)'>
							<circle cx='13' cy='13' r='13' fill='#FFF' stroke='#00AAE8' strokeWidth='2' />
							<g
								stroke='#00AAE8'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								transform='translate(7.583 6.5)'
							>
								<path d='M10.5050505,11.4493464 L10.5050505,10.203159 C10.5050505,8.82665849 9.32923268,7.71078431 7.87878788,7.71078431 L2.62626263,7.71078431 C1.17581783,7.71078431 1.45786862e-16,8.82665849 0,10.203159 L0,11.4493464' />
								<ellipse cx='5.515' cy='2.492' rx='2.626' ry='2.492' />
							</g>
						</g>
						<rect width='31' height='3' x='131' y='49' fill='#00AAE8' rx='1.5' />
						<rect width='17' height='3' x='131' y='56' fill='#00AAE8' rx='1.5' />
						<rect width='7' height='3' x='129' y='67' fill='#DBF2C3' rx='1.5' />
						<rect width='11' height='3' x='139' y='67' fill='#DBF2C3' rx='1.5' />
						<rect width='11' height='3' x='129' y='73' fill='#DBF2C3' rx='1.5' />
						<rect width='8' height='3' x='153' y='67' fill='#DBF2C3' rx='1.5' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
