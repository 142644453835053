import { StyleSheet } from 'aphrodite';
import { darkGrayFontColor } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	dropdownInline: {
		borderColor: '#CCCCCC',
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		width: '100%',
	},
	followUpDropdown: {
		marginLeft: 8,
		width: 150,
	},
	sendFromDropdownContainer: {
		alignItems: 'center',
		color: darkGrayFontColor,
		display: 'flex',
		flex: 1,
		fontSize: 14,
		marginLeft: 25,
		marginTop: 8,
		width: '50%',
	},
});
