import { StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';

export interface IMainContainerBackgroundContext {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export interface IMainContainerBackgroundContextSetters {
	setClassName?(className?: string): void;
	setStyles?(styles?: StyleDeclarationValue[]): void;
}

// @ts-ignore
export const MainContainerBackgroundContext = React.createContext<IMainContainerBackgroundContext>(null);
// @ts-ignore
export const MainContainerBackgroundSettersContext = React.createContext<IMainContainerBackgroundContextSetters>(null);
