import { css } from 'aphrodite';
import * as React from 'react';
import { MediaType } from '.';
import { IPixabayImage } from '../../../models/Pixabay';
import { brandSecondary } from '../../styles/colors';
import { DashboardCarousel } from '../Happiness/DashboardCarousel';
import { LoadingSpinner } from '../LoadingSpinner';
import { useSuggestedPixabayImages } from './hooks/useSuggestedPixabayImages';
import { styleSheet } from './styles';

export function MediaSuggestionResults({
	keywords,
	mediaType,
	onImageClicked,
}: {
	keywords: string[];
	mediaType: MediaType;
	onImageClicked: (selectedUserImage: IPixabayImage) => void;
}) {
	const { suggestedImages, isLoading } = useSuggestedPixabayImages(keywords);

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>, item: IPixabayImage) => {
		e.preventDefault();
		onImageClicked(item);
	};

	if (isLoading) {
		return <LoadingSpinner />;
	}

	switch (mediaType) {
		case 'image':
			return (
				<DashboardCarousel
					sliderLeftProps={{ fillColor: brandSecondary }}
					sliderRightProps={{ fillColor: brandSecondary }}
					buttonColumnStyleDeclaration={styleSheet.suggestedMediaButtonColumn}
					visibleSlides={4}
				>
					{suggestedImages?.map(item => {
						return (
							<button
								type='button'
								key={item?.id}
								className={css(styleSheet.suggestedMedia)}
								onClick={e => handleClick(e, item)}
							>
								<img src={item.previewURL} className={css(styleSheet.suggestedMediaImg)} />
							</button>
						);
					})}
				</DashboardCarousel>
			);
		// Leaving this as a switch for future adding of gifs and video
		default:
			return null;
	}
}
