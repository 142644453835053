import { brandSecondary } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	bodyText: {
		color: '#4A4A4A',
		fontSize: 14,
		lineHeight: '18px',
		marginBottom: 20,
	},
	container: {
		width: 360,
	},
	searchField: {
		marginBottom: 20,
		width: '100%',
	},
	submitButton: {},
	title: {
		color: brandSecondary,
		fontSize: 18,
		lineHeight: '22px',
		marginBottom: 16,
	},
});
