import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const RenewalAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon
			className={`renewal-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<g filter='url(#renewalAutomationTriggerGraphicFilter)'>
				<circle cx='86' cy='86' r='80' fill='url(#renewalAutomationTriggerGraphicGradient)' />
			</g>
			<mask id='renewalAutomationTriggerGraphicMask' maskUnits='userSpaceOnUse' x='6' y='6' width='160' height='160'>
				<circle cx='86' cy='86' r='80' fill='#fff' />
			</mask>
			<g mask='url(#renewalAutomationTriggerGraphicMask)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M74.003 142.992l3.494 27.079A2.954 2.954 0 0080.448 173h27.104a2.955 2.955 0 002.951-2.929l.954-7.41L114 142.95a2.954 2.954 0 00-2.951-2.95H76.951A2.954 2.954 0 0074 142.95l.003.042zm4.018-1.342h31.958a2.153 2.153 0 012.151 2.132l-3.278 25.417a2.152 2.152 0 01-2.151 2.151H81.299a2.153 2.153 0 01-2.151-2.151l-.003-.039-.897-6.964-2.378-18.414a2.153 2.153 0 012.15-2.132z'
					fill='#F89143'
				/>
				<ellipse cx='93.5' cy='145' rx='18.5' ry='3' fill='#F2F2F2' />
				<path
					d='M92.822 144.514a55.64 55.64 0 003.443-19.498 57.937 57.937 0 00-3.392-19.532 51.667 51.667 0 00-4.808-9.892c-.27-.432.37-.838.638-.41 3.488 5.606 5.938 11.913 7.21 18.56a59.392 59.392 0 01.433 20.098 54.232 54.232 0 01-2.812 10.891c-.178.476-.893.268-.712-.217z'
					fill='#F89143'
				/>
				<path
					d='M95.109 113.269c1.079-1.157 1.373-2.767 1.364-4.292-.01-1.812-.46-3.586-.546-5.393-.077-1.616.17-3.251 1.125-4.603.822-1.163 2.118-2.058 3.605-1.976 3.173.176 4.65 3.786 5.108 6.421 1.275 7.069-2.736 14.031-9.534 16.547-.476.179-.682-.577-.21-.755 5.976-2.252 9.707-8.166 9.125-14.466a11.367 11.367 0 00-1.176-4.345c-.609-1.138-1.556-2.269-2.885-2.56-1.31-.287-2.538.431-3.295 1.454-.955 1.289-1.154 2.848-1.065 4.407.188 3.333 1.552 7.318-1.057 10.115-.346.371-.905-.184-.56-.554zm-.691 16.428c-2.683-1.784-2.951-5.272-3.29-8.21-.191-1.664-.469-3.432-1.471-4.811-.824-1.135-2.221-2.11-3.669-1.846-1.276.233-2.138 1.306-2.608 2.473a9.842 9.842 0 00-.556 4.236 14.96 14.96 0 003.574 8.852 15.609 15.609 0 003.632 3.206c1.402.857 2.967 1.4 4.59 1.594.5.06.506.867 0 .806a12.337 12.337 0 01-4.41-1.37 14.76 14.76 0 01-3.61-2.823 16.154 16.154 0 01-4.451-9.055 12.573 12.573 0 01.16-4.697c.338-1.342 1.013-2.688 2.179-3.466a3.81 3.81 0 014.15.057c1.42.882 2.316 2.347 2.77 3.948.557 1.964.518 4.032.93 6.024.345 1.672 1.02 3.415 2.48 4.386.425.282.029.98-.4.696z'
					fill='#F89143'
				/>
				<path
					d='M69 82c0-7.732 6.268-14 14-14s14 6.268 14 14-6.268 14-14 14c-7.728-.009-13.991-6.272-14-14z'
					fill='#FDA96A'
					stroke='#F89143'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M127.891 173h20.218c1.251-.001 2.269-.977 2.282-2.189l2.606-19.56.003-.04c-.001-1.221-1.023-2.21-2.283-2.211h-25.434c-1.26.001-2.282.99-2.283 2.211l2.609 19.6c.013 1.212 1.031 2.188 2.282 2.189zm-1.63-2.211l-2.609-19.598c.012-.864.738-1.558 1.631-1.559h25.434c.893.001 1.619.695 1.631 1.559l-2.606 19.558-.003.04c-.001.872-.73 1.578-1.63 1.579h-20.218c-.9-.001-1.629-.707-1.63-1.579z'
					fill='#F89143'
				/>
				<ellipse cx='137' cy='151.5' rx='14' ry='2.5' fill='#F2F2F2' />
				<path
					d='M137.881 149.413a41.38 41.38 0 01-2.927-15.424 43.026 43.026 0 012.879-15.45 39.77 39.77 0 014.082-7.823c.351-.522-.479-1.014-.828-.496a41.794 41.794 0 00-6.152 14.744 44.19 44.19 0 00-.386 15.989 40.756 40.756 0 002.407 8.721c.231.577 1.159.324.925-.261z'
					fill='#F89143'
				/>
				<path
					d='M134.871 126.946c-1.942-2.28-.622-5.82-.587-8.503a6.407 6.407 0 00-1.029-3.822c-.684-.972-1.724-1.694-2.898-1.615-2.567.173-3.785 3.264-4.163 5.596-1.038 6.135 2.177 12.185 7.633 14.362.571.235.819-.749.252-.982-4.633-1.899-7.525-6.863-7.081-12.156.073-1.287.39-2.545.93-3.697.471-.935 1.203-1.883 2.232-2.079 1.021-.194 1.934.498 2.467 1.379.68 1.122.766 2.431.674 3.735-.201 2.843-1.128 6.124.898 8.502.413.486 1.084-.236.672-.72zm1.789 11.97c1.927-1.393 2.254-4 2.489-6.313.137-1.351.284-2.806.978-3.972.55-.926 1.527-1.762 2.59-1.646 2.123.23 2.454 3.398 2.368 5.19-.136 2.592-1.055 5.062-2.614 7.019-1.59 1.998-3.592 3.493-6.04 3.816-.568.075-.575 1.062 0 .986 4.887-.644 8.777-5.446 9.45-10.68.294-2.283.162-5.357-1.772-6.795a2.791 2.791 0 00-3.127-.081c-1.07.68-1.863 1.78-2.219 3.078-.486 1.603-.454 3.311-.745 4.955-.24 1.358-.712 2.797-1.812 3.592-.482.348-.031 1.202.454.851z'
					fill='#F89143'
				/>
				<path
					d='M145 111c-7.732 0-14-6.268-14-14s6.268-14 14-14 14 6.268 14 14c-.009 7.728-6.272 13.991-14 14z'
					fill='#FFD1AD'
					stroke='#F89143'
					strokeWidth='2'
				/>
				<path d='M60.337 168L64 166.861 61.407 152 56 153.681 60.337 168z' fill='#FFB8B8' />
				<path d='M72.245 168.838l-12.017 3.688-1.425-4.643 7.374-2.263a4.857 4.857 0 016.068 3.218z' fill='#2F2E41' />
				<path
					d='M27.001 169h4.065L33 153h-6l.001 16zm.966-44.752a3.022 3.022 0 01-.966-2.295 3.012 3.012 0 011.065-2.247L31.01 81 37 82.562l-4.795 37.757c.826 1.238.634 2.926-.447 3.929a2.764 2.764 0 01-3.79 0z'
					fill='#FFB8B8'
				/>
				<circle cx='40' cy='57' r='8' fill='#FFB8B8' />
				<path
					d='M34.32 78.915c-1.747-2.449-1.8-5.821.082-8.163C35.631 69.223 37.686 68 41.117 68c9.146 0 12.615 7.402 12.615 7.402s3.784 7.08 1.577 13.517C53.1 95.356 53.1 96 53.1 96l-14.507-1.287s2.447-6.383-4.273-15.798z'
					fill='#CCC'
				/>
				<path
					d='M28 85.768l3.202-10.287a4.952 4.952 0 012.845-3.095 5.092 5.092 0 014.242.161c2.347 1.194 3.349 3.97 2.29 6.35L36.508 88 28 85.768z'
					fill='#CCC'
				/>
				<path
					d='M51.434 94L64 160.075l-6.766 1.962L40.8 118.533l-7.41 44.486-7.09.981s-3.109-58.861 10.634-69.673l14.5-.327z'
					fill='#2F2E41'
				/>
				<path
					d='M52.441 100c-2.162.001-4.09-1.346-4.81-3.362L44 86.649 49.636 84l3.094 9.34 15.76-.756c.085-.133.178-.259.28-.379a3.56 3.56 0 012.467-1.224 3.57 3.57 0 012.608.895 3.52 3.52 0 01.388 4.801 3.572 3.572 0 01-5.063.471 3.29 3.29 0 01-.252-.237l-15.471 2.992a5.299 5.299 0 01-1.006.097z'
					fill='#FFB8B8'
				/>
				<path
					d='M44.03 77.433c-.155-1.432.285-2.866 1.202-3.913.917-1.047 2.217-1.6 3.549-1.51 2.37.164 4.215 2.288 4.219 4.855V86.7L45.18 88l-1.15-10.567z'
					fill='#CCC'
				/>
				<path
					d='M41.62 65.576c-1.18-.005-2.09-1.292-2.037-2.513.053-1.221.845-2.295 1.799-3.015.954-.72 2.073-1.158 3.138-1.687 1.064-.529 2.119-1.188 2.776-2.204a5.03 5.03 0 00.327-4.477 7.099 7.099 0 00-2.988-3.39c-4.09-2.478-9.332-1.288-12.043 2.735l-1.348 3.8c-1.373 1.709-1.59 4.247-.747 6.292.845 2.044 2.642 3.583 4.67 4.314 2.057.684 4.26.753 6.353.197'
					fill='#2F2E41'
				/>
				<path
					d='M34.402 53.318c-.592-.94-1.617-1.538-2.677-1.889a8.473 8.473 0 00-7.394 1.014 8.325 8.325 0 00-3.67 6.444c-.113 2.048.534 4.05.914 6.065.38 2.015.456 4.241-.665 5.965-.854 1.312-2.278 2.138-3.683 2.844-1.171.588-2.438 1.14-3.745 1.023-1.308-.118-2.622-1.144-2.587-2.446a10.326 10.326 0 00-.877 2.945c-.092 1.023.162 2.127.908 2.84 1.083 1.037 2.8.962 4.289.738 3.223-.485 6.487-1.393 9.034-3.411 2.547-2.018 4.236-5.342 3.566-8.505-.28-1.321-.939-2.528-1.47-3.77-.533-1.244-.948-2.603-.704-3.931a4.782 4.782 0 012.45-3.237 6.458 6.458 0 014.096-.615c.915.148 2.058.38 2.6-.366a1.451 1.451 0 00-.03-1.56 4.315 4.315 0 00-1.175-1.153'
					fill='#2F2E41'
				/>
				<circle cx='112' cy='38' r='2' fill='#F89143' />
				<circle cx='112' cy='46' r='2' fill='#F89143' />
				<circle cx='112' cy='55' r='2' fill='#F89143' />
				<circle cx='112' cy='63' r='2' fill='#F89143' />
				<circle cx='121' cy='38' r='2' fill='#F89143' />
				<circle cx='121' cy='46' r='2' fill='#F89143' />
				<circle cx='121' cy='55' r='2' fill='#F89143' />
				<circle cx='121' cy='63' r='2' fill='#F89143' />
				<circle cx='130' cy='38' r='2' fill='#F89143' />
				<circle cx='130' cy='46' r='2' fill='#F89143' />
				<circle cx='130' cy='55' r='2' fill='#F89143' />
				<circle cx='130' cy='63' r='2' fill='#F89143' />
				<circle cx='138' cy='38' r='2' fill='#F89143' />
				<circle cx='138' cy='46' r='2' fill='#F89143' />
				<circle cx='138' cy='55' r='2' fill='#F89143' />
				<circle cx='138' cy='63' r='2' fill='#F89143' />
				<circle cx='147' cy='38' r='2' fill='#F89143' />
				<circle cx='147' cy='46' r='2' fill='#F89143' />
				<circle cx='147' cy='55' r='2' fill='#F89143' />
				<circle cx='147' cy='63' r='2' fill='#F89143' />
				<circle cx='156' cy='38' r='2' fill='#F89143' />
				<circle cx='156' cy='46' r='2' fill='#F89143' />
				<circle cx='156' cy='55' r='2' fill='#F89143' />
				<circle cx='156' cy='63' r='2' fill='#F89143' />
			</g>
			<defs>
				<linearGradient
					id='renewalAutomationTriggerGraphicGradient'
					x1='6'
					y1='6'
					x2='6'
					y2='166'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#FFF3E9' />
					<stop offset='1' stopColor='#FFE4CF' />
				</linearGradient>
				<filter
					id='renewalAutomationTriggerGraphicFilter'
					x='0'
					y='0'
					width='172'
					height='172'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
					<feOffset />
					<feGaussianBlur stdDeviation='3' />
					<feColorMatrix values='0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0.100034 0' />
					<feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
					<feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
				</filter>
			</defs>
		</SvgIcon>
	);
};
