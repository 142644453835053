import { StyleSheet } from 'aphrodite';
import { alternateTitleColor, success } from '../../../styles/colors';
import { CssSelectors, Layouts, ctaButtonBase, ctaButtonProps, ctaButtonReverseProps } from '../../../styles/styles';
import { ThinkingModalOverlayBackgroundImageUrl } from './models';

const footerHeaderHeight = 40;

const thinkingBackgroundColorAnimation = {
	'0%': {
		backgroundColor: '#C7D4DB',
		transform: 'translate3d(0, 0%, 0)',
	},
	'100%': {},
	'15%': {
		backgroundColor: '#F9F9F9',
		transform: 'translate3d(0, -30%, 0)',
	},
	'35%': {
		backgroundColor: '#C7D4DB',
		transform: 'translate3d(0, 0%, 0)',
	},
	'80%': {},
};

const thinkingElementAnimation = (elementIndex: number) => {
	const baseDuration = 225;
	const animationDuration = 6 * baseDuration;
	const animationDelay = (elementIndex - 1) * (2 * baseDuration);
	return {
		animationDelay: `${animationDelay}ms`,
		animationDuration: `${animationDuration}ms`,
		animationIterationCount: 'infinite',
		animationName: [thinkingBackgroundColorAnimation],
		animationTimingFunction: 'ease-in',
	};
};

export const styleSheet = StyleSheet.create({
	assistantContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginBottom: 20,
	},
	assistantIcon: {
		filter: 'drop-shadow(0px 21px 36px rgba(0, 0, 0, 0.5)) drop-shadow(0px -21px 36px rgba(0, 170, 232, 0.355447))',
		height: 162,
		margin: '0 auto 16px auto',
		width: 'auto',
	},
	background: {
		height: '100%',
		margin: '-20px -45px -20px -45px',
		position: 'absolute',
		width: '100%',
	},
	body: {
		flexGrow: 1,
	},
	caution: {
		borderRadius: 8,
		boxSizing: 'border-box',
		color: '#fff',
		fontSize: 12,
		justifyContent: 'center',
		lineHight: '16px',
		textAlign: 'center',
		maxWidth: 221,
		margin: '23px auto 0',
	},
	container: {
		background: '#00528C',
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		padding: '20px 45px',
		position: 'relative',
		...CssSelectors.allChildren({
			':not(svg)': {
				zIndex: 0,
			},
			width: '100%',
		}),
	},
	footer: {
		marginTop: 40,
		borderTop: '1px solid #fff',
		paddingTop: 24,
	},
	footerButtons: {
		height: footerHeaderHeight,
	},
	footerButtonsCentered: {
		justifyContent: 'center',
	},
	footerCloseButton: {
		...ctaButtonReverseProps('transparent', '#fff', 'transparent'),
	},
	generateBlogButton: {
		fontSize: 12,
	},
	generateButton: {
		...ctaButtonProps(success),
	},
	header: {
		color: '#fff',
		display: 'flex',
		height: footerHeaderHeight,
		justifyContent: 'flex-end',
	},
	headerCloseButton: {
		color: '#fff',
	},
	pill: {
		alignItems: 'center',
		background: alternateTitleColor,
		borderRadius: 60,
		boxSizing: 'border-box',
		display: 'flex',
		height: 'auto',
		margin: 0,
		padding: '14px 32px',
		position: 'relative',
		textAlign: 'center',
		fontSize: 12,
		fontWeight: 'bold',
	},
	refreshIcon: {
		height: 16,
		width: 16,
	},
	thinking: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		position: 'relative',
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginLeft: 5,
			},
			':nth-child(1)': thinkingElementAnimation(1),
			':nth-child(2)': thinkingElementAnimation(2),
			':nth-child(3)': thinkingElementAnimation(3),
			backgroundColor: '#C7D4DB',
			borderRadius: '50%',
			height: 12,
			width: 12,
		}),
	},
	thinkingModal: {
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						...CssSelectors.allChildren({
							':not(:first-child)': {
								marginTop: 22,
							},
						}),
						background: 'none',
						border: 'none',
						boxShadow: 'none',
						color: '#fff',
						marginTop: '12vh',
						padding: 0,
						textAlign: 'center',
					},
					'.modal-overlay-content'
				),
				alignItems: 'flex-start',
				background: 'none',
				backgroundImage: `url(${ThinkingModalOverlayBackgroundImageUrl})`,
				backgroundSize: 'cover',
			},
			'.modal-overlay'
		),
	},
	thinkingModalButtonContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	thinkingModalDismissButton: {
		...ctaButtonBase,
		':active': {
			background: 'transparent',
		},
		':hover': {
			background: 'transparent',
			boxShadow: '0 1px 2px 0 #f9f9f9',
		},
		background: 'transparent',
		border: '1px solid #fff',
	},
	thinkingModalMessages: {
		...Layouts.verticalStack(15),
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
});
