import {
	CampaignViewModel,
	DefaultBulkSendExcludedTags,
	EmailSendStatus,
	PostStatus,
	SocialMediaPostReportViewModel,
	VmUtils,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { AdvancedTagFilterCollection } from '../../../../../models';
import { getCampaignCalendarDateRangeStringValue, getSocialMediaPlatformLogoUrl } from '../../../../../models/UiUtils';
import { baseStyleSheet } from '../../../../styles/styles';
import { VisibilityIcon } from '../../../VisibilityIcon';
import { CheckmarkIcon } from '../../../svgs/icons/CheckmarkIcon';
import { EmailIcon } from '../../../svgs/icons/EmailIcon';
import { ReplyIcon } from '../../../svgs/icons/ReplyIcon';
import { SocialMediaIcon } from '../../../svgs/icons/SocialMediaIcon';
import { CompleteColor, styleSheet } from './styles';

interface IProps {
	campaign: CampaignViewModel | SocialMediaPostReportViewModel;
	className?: string;
	style?: React.CSSProperties;
	styles?: StyleDeclarationValue[];
}

export const CampaignListItem: React.FC<IProps> = props => {
	const { className, style, styles, campaign } = props;
	const isSocial = campaign instanceof SocialMediaPostReportViewModel;
	const completed = isSocial
		? campaign.status === PostStatus.Succeeded ||
			campaign.status === PostStatus.Started ||
			campaign.status === PostStatus.PartiallySucceeded
		: campaign.status === EmailSendStatus.Complete;

	const renderFooter = () => {
		if (completed && !isSocial) {
			return (
				<div className={css(styleSheet.completedFooter)}>
					<div className={css(baseStyleSheet.horizontalStack)}>
						<CheckmarkIcon fillColor={CompleteColor} type='bold' />
						{/* @ts-ignore */}
						<span>{`Sent to ${campaign.sentCount.toLocaleString()}`}</span>
					</div>
					{/* @ts-ignore */}
					{campaign.sentCount > 0 && (
						<div className={css(baseStyleSheet.horizontalStack)}>
							<VisibilityIcon color={CompleteColor} />
							{/* @ts-ignore */}
							{/* @ts-ignore */}
							<span>{`${((campaign.openCount / campaign.sentCount) * 100).toFixed(0)}% viewed`}</span>
						</div>
					)}
					<div className={css(baseStyleSheet.horizontalStack)}>
						<ReplyIcon fillColor={CompleteColor} />
						<span>{`${(campaign.repliedCount || 0).toLocaleString()} replies`}</span>
					</div>
				</div>
			);
		}

		if (!isSocial) {
			let tags: string[] | undefined;
			if (!!campaign && !!campaign.filterRequest && !!campaign.filterRequest.criteria) {
				const sorted = VmUtils.sortContactFilterCriteria(campaign.filterRequest.criteria);
				if (sorted.searches.length > 0 && sorted.searches.every(x => VmUtils.isTagSearchContactFilterCriteria(x))) {
					const filters = AdvancedTagFilterCollection.instanceWithCriteria(sorted.searches);
					filters.sort();
					tags = filters.filters.map(x => x.tag);
				}
			}
			tags = tags?.filter(x => !DefaultBulkSendExcludedTags.has(x));

			if (tags && tags.length > 0) {
				const tagsTooltip = tags.reduce((prev, curr) => {
					return `${prev}${prev ? `, ${curr}` : curr}`;
				}, '');
				return (
					<div title={tagsTooltip}>
						{`Sending to contacts with ${tags.length > 1 ? `${tags.length} tags` : `"${tags[0]}" tag`}`}
					</div>
				);
			}

			// @ts-ignore
			return `Sending to ${campaign.totalCount} contact${campaign.totalCount > 1 ? 's' : ''}`;
		} else {
			return (
				<div className={css(styleSheet.socialInfo)}>
					<div>{`From: ${campaign?.creator?.firstName} ${campaign?.creator?.lastName}`}</div>
					<span className={css(styleSheet.socialInfo)}>
						{campaign?.targets?.map((x, i) => {
							return (
								<span className={css(styleSheet.targetType)} key={i}>
									<img src={getSocialMediaPlatformLogoUrl(x.provider)} />
								</span>
							);
						})}
					</span>
				</div>
			);
		}
	};
	return (
		<div
			className={`${css(
				styleSheet.container,
				completed ? styleSheet.completed : null,
				...(styles || [])
			)} campaign-list-item ${className || ''}`}
			style={style}
		>
			<div className={css(styleSheet.top)}>
				{!isSocial ? (
					<div className={css(styleSheet.icon, completed ? styleSheet.iconCompleted : null)}>
						<EmailIcon />
					</div>
				) : (
					<div className={css(styleSheet.iconSocial)}>
						<SocialMediaIcon />
					</div>
				)}
				<div className={css(styleSheet.details)}>
					<div>
						{getCampaignCalendarDateRangeStringValue(
							isSocial ? campaign?.dueDate : campaign.schedule?.startDate,
							isSocial ? campaign.dueDate : campaign.completedDate
						)}
					</div>
					<div className={css(styleSheet.subject)}>{isSocial ? campaign?.name : campaign.subject}</div>
				</div>
			</div>
			<div className={css(styleSheet.footer)}>{renderFooter()}</div>
		</div>
	);
};
