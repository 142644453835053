import { css, StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { LoadingSpinner } from '../LoadingSpinner';
import { CloudUploadIcon } from '../svgs/icons/CloudUploadIcon';
import { styleSheet } from './styles';

interface IProps {
	accept: string;
	dragDropMessage?: string;
	droppableId: string;
	file: File | null;
	onFileChanged: (file: File) => Promise<void> | void;
	showLoading?: boolean;
	styles?: StyleDeclarationValue[];
}

export function DragDropFileInputTarget({
	accept,
	children,
	droppableId,
	onFileChanged,
	showLoading = false,
	styles = [],
	dragDropMessage,
	file,
}: React.PropsWithChildren<IProps>) {
	const [isDraggingOver, setIsDraggingOver] = React.useState(false);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const onFileChange = async (nextFile: File) => {
		if (!nextFile) {
			return; // If no file is selected, return early
		}
		const promise = onFileChanged(nextFile);
		if (promise) {
			await promise;
		}
		// Clear the input field
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(false);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(false);

		const nextFile = e.dataTransfer.files?.[0];

		if (nextFile) {
			onFileChange(nextFile);
		}
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId={droppableId}>
				{(droppableProvided: DroppableProvided) => {
					return (
						<div className={css(...styles)}>
							{!file && !showLoading ? (
								<>
									<div
										onDragEnter={handleDragEnter}
										onDragOver={handleDragOver}
										onDragLeave={handleDragLeave}
										onClick={() => {
											inputRef.current?.click();
										}}
										onDrop={handleDrop}
										className={css(styleSheet.dragContainer, isDraggingOver ? styleSheet.dragContainerHover : null)}
										ref={droppableProvided ? droppableProvided.innerRef : undefined}
									>
										<input
											ref={inputRef}
											type='file'
											accept={accept}
											style={{ display: 'none' }}
											onChange={ev => {
												if (ev.target.files.length > 0) {
													onFileChange(ev.target.files[0]);
												}
											}}
										/>
										<div className={css(styleSheet.dragInfo)}>
											<CloudUploadIcon />
											<div className={css(styleSheet.dragText)}>
												{dragDropMessage ?? 'Drag and drop a file to upload'}
											</div>
											<div className={css(styleSheet.dragFooter)}>Click to browse device</div>
										</div>
									</div>
								</>
							) : null}
							{showLoading ? <LoadingSpinner type='tiny' /> : null}
							{children}
						</div>
					);
				}}
			</Droppable>
		</DragDropContext>
	);
}
