import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const BoatIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`boat-icon ${className || ''}`} height={41} width={39}>
			<g fill='none' fillRule='evenodd' transform='translate(-1)'>
				<g transform='matrix(-1 0 0 1 39.713 0)'>
					<polygon fill='#CAE0EC' points='0 30 19.224 0 19.224 30' />
					<polygon fill='#9EC5DA' points='24.65 30 38.39 9 38.39 30' transform='matrix(-1 0 0 1 63.04 0)' />
					<rect width='1.828' height='26' x='21.023' y='4' fill='#CAE0EC' fillRule='nonzero' />
				</g>
				<path
					fill='#CAE0EC'
					d='M0,0 L35.1719904,0 L35.1719904,0 C32.1726722,5.32728251 26.5340493,8.62354892 20.4204702,8.62354892 L16.8634224,8.62354892 C11.5017716,8.62354892 6.36463775,6.47075656 2.60485154,2.64828272 L0,0 Z'
					transform='translate(3.247 32.195)'
				/>
			</g>
		</SvgIcon>
	);
};
