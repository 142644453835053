import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
	height?: number;
	width?: number;
}

export const CompactLogoIcon: React.FC<IProps> = ({ className, fill, height = 32, width = 32 }) => {
	return (
		<SvgIcon className={className} height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
			<g fill='none' fillRule='evenodd'>
				<path d='m0 0h32v32h-32z' fill={fill} />
				<path
					d='m22.497 24.615h-11v-18.375h2.863v15.566h7.172v-.579h-6.462v-14.987h1.089v13.901h6.338v2.752h-9.226v-15.565h-.684v16.2h9.91z'
					fill='#fefefe'
				/>
			</g>
		</SvgIcon>
	);
};
