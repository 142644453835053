import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, titles } from '../../styles/colors';
import { BreakPoints, BrowserMediaQueries, CssSelectors, truncateTextStyle } from '../../styles/styles';

export const AppBarHeight = 100;

const searchSectionHeight = 60;
const tabsSectionHeight = 77;
const searchSectionTopMargin = 20;

export const styleSheet = StyleSheet.create({
	arrowContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: '1rem',
		pointerEvents: 'none',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
	},
	arrowLeft: {
		cursor: 'pointer',
		pointerEvents: 'auto',
		transform: 'rotate3d(0,0,1,90deg)',
	},
	arrowLeftContainer: {
		backgroundImage: 'linear-gradient(to left, rgba(249,249,249, 0), rgba(249,249,249, 1) 90%)',
		left: 0,
		paddingRight: '2rem',
	},
	arrowRight: {
		cursor: 'pointer',
		pointerEvents: 'auto',
		transform: 'rotate3d(0,0,1,-90deg)',
	},
	arrowRightContainer: {
		backgroundImage: 'linear-gradient(to right, rgba(249,249,249, 0), rgba(249,249,249, 1) 50%)',
		paddingLeft: '2rem',
		right: 0,
	},
	browse: {
		fontSize: 14,
		margin: '0 10px 0 20px',
		[BreakPoints.forMaxWidth(840)]: {
			color: 'transparent',
			width: 0,
		},
	},
	browseAllCampaigns: {
		cursor: 'pointer',
		marginRight: 10,
		marginTop: 0,
		textAlign: 'right',
	},
	clearSearch: {
		cursor: 'pointer',
		marginLeft: 20,
	},
	containerComponent: {
		boxSizing: 'border-box',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		width: '100%',
	},
	dropdownMenu: {
		marginTop: -1,
		userSelect: 'none',
		width: 158,
	},
	dropdownTrigger: {
		background: '#FFFFFF',
		border: '1px solid #CCCCCC',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: titles,
		fontSize: 14,
		height: 40,
		padding: 8,
		userSelect: 'none',
		width: 160,
		[BreakPoints.forMaxWidth(1024)]: {
			maxWidth: 250,
		},
		[BreakPoints.forMaxWidth(900)]: {
			width: 150,
		},
		...CssSelectors.allChildren(
			{
				...truncateTextStyle,
			},
			'.select-box-trigger-content'
		),
	},
	flexDiv: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	hideGradient: {
		width: 0,
	},
	htmlNewsletterTab: {
		border: `1px solid #C75DA6`,
		borderRadius: 3,
		boxSizing: 'border-box',
		flexShrink: 0,
		padding: '5px 10px',
	},
	industryDropdown: {
		alignItems: 'center',
		display: 'flex',
	},
	industrySection: {
		alignItems: 'center',
		display: 'flex',
		height: 40,
		margin: '0 14px 0 0',
	},
	loadingContainer: {
		marginTop: 30,
	},
	reviewed: {
		color: '#89C947',
		marginLeft: 5,
	},
	searchBox: {
		background: '#FFFFFF',
		border: '1px solid #CCCCCC',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: '#4a4a4a',
		display: 'inline-block',
		fontSize: 14,
		height: 40,
		minWidth: 280,
		[BreakPoints.forMaxWidth(950)]: {
			minWidth: 200,
		},
	},
	searchBoxInput: {
		marginLeft: 10,
		width: '88%',
		[BreakPoints.forMaxWidth(950)]: {
			width: '70%',
		},
		...truncateTextStyle,
	},
	searchIcon: {
		marginBottom: 2,
		verticalAlign: 'middle',
	},
	searchResults: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 14,
		height: 37,
		paddingBottom: 10,
		paddingTop: 10,
		width: '100%',
	},
	searchSection: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: searchSectionTopMargin,
		maxHeight: searchSectionHeight,
		paddingLeft: 30,
		paddingRight: 20,
	},
	socialMediaTab: {
		border: `1px solid #046cb6`,
		borderRadius: 3,
		boxSizing: 'border-box',
		flexShrink: 0,
		padding: '5px 10px',
	},
	tab: {
		':first-child': {
			marginLeft: 0,
		},
		':last-child': {
			marginRight: 10,
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		color: '#858585',
		cursor: 'pointer',
		display: 'flex',
		flexShrink: 0,
		fontSize: 12,
		justifyContent: 'center',
		letterSpacing: 1.05,
		margin: '0 15px 20px',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
	},
	tabNavigation: {
		alignItems: 'center',
		borderBottom: '1px solid #D3DBDE',
		display: 'flex',
		height: tabsSectionHeight,
		justifyContent: 'space-between',
		paddingLeft: 30,
		paddingRight: 20,
		position: 'relative',
	},
	tabSection: {
		'::-webkit-scrollbar': {
			display: 'none',
		},
		alignItems: 'center',
		[BrowserMediaQueries.IE11]: {
			overflowX: 'auto',
			padding: '30px 10px 0px',
		},
		display: 'flex',
		overflowStyle: 'none',
		overflowX: 'scroll',
		padding: '30px 10px 10px',
		scrollbarWidth: 'none',
	},
	tabSelected: {
		color: brandPrimaryHover,
	},
});
