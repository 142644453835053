import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const ComplianceIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`compliance-send-icon ${className || ''}`} height={200} width={220} fill='none'>
			<path fill='#F9F9F9' d='M62 15h97v135H62z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M104 7a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm3.103 1.62c.675 1.505 2.215 2.46 3.897 2.418 2.252-.033 4.053-1.838 4.027-4.038 0-1.643-1.02-3.121-2.579-3.74a4.207 4.207 0 0 0-4.519.924 3.969 3.969 0 0 0-.826 4.436Z'
				fill='url(#undraw_customer_survey_f9ur__a)'
			/>
			<path fill='#00528C' d='M87 12h47v11H87z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M104 7.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm2.75 0a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0Z'
				fill='#00528C'
			/>
			<path fill='#89C947' d='M67 38h19v19H67z' />
			<path fill='#00528C' d='M67 79h19v19H67zm0 41h19v19H67z' />
			<path
				fill='#A7ABAD'
				d='M100 43h26v2h-26zm-1 3h44v2H99zm0 3h41v2H99zm1 35h26v2h-26zm-1 3h44v2H99zm0 3h41v2H99zm1 35h26v2h-26zm-1 3h44v2H99zm0 3h41v2H99z'
			/>
			<path
				d='m72.5 45.998 3.6 3.145L81.2 42l1.8 1.429L76.1 52 71 46.571l1.5-.573Z'
				fill='url(#undraw_customer_survey_f9ur__b)'
			/>
			<path
				d='m73.25 46.6 3 2.829L80.5 43l1.5 1.286L76.25 52 72 47.114l1.25-.514Zm0 41 3 2.829L80.5 84l1.5 1.286L76.25 93 72 88.114l1.25-.514Zm0 41 3 2.829L80.5 125l1.5 1.286L76.25 134 72 129.114l1.25-.514Z'
				fill='#fff'
			/>
			<g opacity='.5' stroke='#89C947'>
				<path d='M164 88.748h1.509' strokeWidth='5' />
				<path d='M168.412 88.748h33.376' strokeWidth='2' strokeDasharray='4' />
				<path d='M203.239 88.748h1.509v-1.51' strokeWidth='2' />
				<path d='M204.748 84.354v-27.4' strokeWidth='2' strokeDasharray='4' />
				<path d='M204.748 55.51V54' strokeWidth='5' />
			</g>
			<path opacity='.5' d='M57 46H23v32m54 78v18h51' stroke='#89C947' strokeWidth='2' strokeDasharray='4' />
			<path d='M220 48h-37V27.003L201.374 11 220 27.003V48Z' fill='#fff' />
			<path d='m183 28 17.878 10L219 28' fill='#E8F3F9' />
			<path d='M201.129 37.8 190 45V18h22v13.731L201.129 37.8Z' fill='#FAFAFA' />
			<path d='M183 48h37l-37-20v20Z' fill='#D7EBF7' />
			<path fill='#A7ABAD' d='M193 22h16v1h-16zm0 2h16v1h-16zm0 2h16v1h-16zm0 2h16v1h-16zm0 2h16v1h-16z' />
			<path fill='#F9F9F9' d='M137 159h43v42h-43z' />
			<path fill='#fff' d='M140 162h37v33h-37z' />
			<path d='M177 183.75c-10.226 0-18.5-2.572-18.5-5.75-10.226 0-18.5 2.237-18.5 5v12h37v-11.25Z' fill='#E8F3F9' />
			<circle cx='171' cy='169' r='4' fill='#00528C' />
			<path fill='#F9F9F9' d='M0 82h49v35H0z' />
			<path
				d='M25.5 91c-.412 0-.824.028-1.232.082L25.5 100.5l-9.477-.632A9.5 9.5 0 0 0 25.5 110a9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5Z'
				fill='#E8F3F9'
			/>
			<path d='M21.83 90c-4.266.563-7.543 4.077-7.83 8.396L23 99l-1.17-9Z' fill='#00528C' />
			<defs>
				<linearGradient
					id='undraw_customer_survey_f9ur__a'
					x1='117.999'
					y1='13.997'
					x2='117.999'
					y2='0'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='undraw_customer_survey_f9ur__b'
					x1='83.001'
					y1='52'
					x2='83.001'
					y2='41.998'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
