import { StyleSheet } from 'aphrodite';

const widthLeft = 100;
const widthRight = 55;
export const styleSheet = StyleSheet.create({
	bumper: {
		bottom: 0,
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
	},
	container: {
		position: 'relative',
	},
	left: {
		transform: 'translate3d(-100%, 0, 0)',
		width: widthLeft,
	},
	right: {
		left: '100%',
		width: widthRight,
	},
	scrollToAnchor: {
		fontSize: 0,
		height: 0,
		opacity: 0,
	},
});
