import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const MeetingReminderTriggerGraphic = ({ className, styles = [] }: IProps) => {
	return (
		<SvgIcon
			className={`manual-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<defs>
				<linearGradient x1='50%' y1='0.346918706%' x2='50%' y2='98.1397509%' id='linearGradient-1'>
					<stop stopColor='#BEF188' offset='0%' />
					<stop stopColor='#EEE0BA' offset='100%' />
				</linearGradient>
				<circle id='path-2' cx='80' cy='80' r='80' />
				<filter x='-5.6%' y='-5.6%' width='111.2%' height='111.2%' filterUnits='objectBoundingBox' id='filter-4'>
					<feOffset dx='0' dy='0' in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur stdDeviation='3' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
					<feColorMatrix
						values='0 0 0 0 0.54424426   0 0 0 0 0.54424426   0 0 0 0 0.54424426  0 0 0 0.100033967 0'
						type='matrix'
						in='shadowBlurOuter1'
					/>
				</filter>
				<linearGradient x1='27.2669831%' y1='96.6730682%' x2='79.1727223%' y2='45.1959552%' id='linearGradient-5'>
					<stop stopColor='#FDF7EC' offset='0%' />
					<stop stopColor='#E0DCD5' offset='100%' />
				</linearGradient>
			</defs>
			<g id='Design' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g id='Automation-Type' transform='translate(-985.000000, -693.000000)'>
					<g id='Group-16-Copy-2' transform='translate(991.000000, 699.000000)'>
						<mask id='mask-3' fill='white'>
							<use xlinkHref='#path-2' />
						</mask>
						<g id='Mask'>
							<use fill='black' fillOpacity='1' filter='url(#filter-4)' xlinkHref='#path-2' />
							<use fill='url(#linearGradient-1)' fillRule='evenodd' xlinkHref='#path-2' />
						</g>
						<g id='Calendar' mask='url(#mask-3)'>
							<g transform='translate(21.000000, 32.000000)'>
								<polygon
									id='Path-4'
									stroke='none'
									fill='url(#linearGradient-5)'
									opacity='0.562386775'
									style={{ mixBlendMode: 'multiply' }}
									points='27.7214814 2 5.76810178e-14 84.3065928 0 135 150 135 150 2'
								/>
								<rect
									id='Rectangle-13'
									stroke='#858585'
									strokeWidth='2'
									fill='#CAE0EC'
									x='36'
									y='35'
									width='115'
									height='69'
									rx='4'
								/>
								<rect
									id='Rectangle-13'
									stroke='#858585'
									strokeWidth='2'
									fill='#E8F3F9'
									x='32'
									y='31'
									width='123'
									height='69'
									rx='4'
								/>
								<rect
									id='Rectangle-13'
									stroke='#858585'
									strokeWidth='2'
									fill='#FFFFFF'
									x='28'
									y='1'
									width='131'
									height='94'
									rx='4'
								/>
								<g
									id='Calendar-Grid'
									stroke='none'
									strokeWidth='1'
									fill='none'
									transform='translate(34.000000, 26.000000)'
								>
									<rect id='Rectangle-17' fill='#E8F3F9' x='0' y='0' width='119' height='1.61290323' />
									<rect id='Rectangle-17' fill='#E8F3F9' x='0' y='16.1290323' width='119' height='1.61290323' />
									<rect id='Rectangle-17' fill='#E8F3F9' x='0' y='32.2580645' width='119' height='1.61290323' />
									<rect
										id='Rectangle-17'
										fill='#E8F3F9'
										x='2.41216216'
										y='48.3870968'
										width='114.175676'
										height='1.61290323'
									/>
									<polygon
										id='Rectangle-14'
										fill='#E8F3F9'
										points='2.41216216 0.806451613 4.02027027 0.806451613 4.02027027 49.1935484 2.41216216 49.1935484'
									/>
									<polygon
										id='Rectangle-14'
										fill='#E8F3F9'
										points='18.4932432 0.806451613 20.1013514 0.806451613 20.1013514 49.1935484 18.4932432 49.1935484'
									/>
									<polygon
										id='Rectangle-14'
										fill='#E8F3F9'
										points='34.5743243 0.806451613 36.1824324 0.806451613 36.1824324 49.1935484 34.5743243 49.1935484'
									/>
									<polygon
										id='Rectangle-14'
										fill='#E8F3F9'
										points='50.6554054 0.806451613 52.2635135 0.806451613 52.2635135 49.1935484 50.6554054 49.1935484'
									/>
									<polygon
										id='Rectangle-14'
										fill='#E8F3F9'
										points='66.7364865 0.806451613 68.3445946 0.806451613 68.3445946 49.1935484 66.7364865 49.1935484'
									/>
									<polygon
										id='Rectangle-14'
										fill='#E8F3F9'
										points='82.8175676 0.806451613 84.4256757 0.806451613 84.4256757 49.1935484 82.8175676 49.1935484'
									/>
									<polygon
										id='Rectangle-14'
										fill='#E8F3F9'
										points='98.8986486 0.806451613 100.506757 0.806451613 100.506757 49.1935484 98.8986486 49.1935484'
									/>
									<rect id='Rectangle-17' fill='#E8F3F9' x='0' y='48.3870968' width='119' height='1.61290323' />
								</g>
								<g id='Labels' stroke='none' strokeWidth='1' fill='none' transform='translate(38.000000, 22.000000)'>
									<rect
										id='Rectangle-25'
										fill='#D8D8D8'
										x='0'
										y='0'
										width='5.61029412'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D8D8D8'
										x='16.0294118'
										y='0'
										width='6.41176471'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D8D8D8'
										x='32.0588235'
										y='0'
										width='7.21323529'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D8D8D8'
										x='48.0882353'
										y='0'
										width='4.80882353'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D8D8D8'
										x='64.1176471'
										y='0'
										width='6.41176471'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D8D8D8'
										x='80.1470588'
										y='0'
										width='5.61029412'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D8D8D8'
										x='96.1764706'
										y='0'
										width='6.41176471'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D0021B'
										x='3.20588235'
										y='9.67741935'
										width='8.01470588'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#00AAE8'
										x='19.2352941'
										y='9.67741935'
										width='4.80882353'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25-Copy'
										fill='#D0021B'
										x='99.3823529'
										y='9.67741935'
										width='4.80882353'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#00AAE8'
										x='19.2352941'
										y='14.516129'
										width='4.80882353'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#00AAE8'
										x='19.2352941'
										y='41.9354839'
										width='6.41176471'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#00AAE8'
										x='19.2352941'
										y='46.7741935'
										width='4.80882353'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25-Copy-2'
										fill='#D3DBDE'
										x='99.3823529'
										y='14.516129'
										width='6.41176471'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D3DBDE'
										x='3.20588235'
										y='14.516129'
										width='6.41176471'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D3DBDE'
										x='3.20588235'
										y='25.8064516'
										width='6.41176471'
										height='2.41935484'
										rx='1.20967742'
									/>
									<rect
										id='Rectangle-25'
										fill='#D3DBDE'
										x='3.20588235'
										y='30.6451613'
										width='8.81617647'
										height='2.41935484'
										rx='1.20967742'
									/>
									<g id='Group-10' transform='translate(35.765378, 8.870968)'>
										<rect
											id='Rectangle-29'
											stroke='#CAE0EC'
											strokeWidth='2'
											x='-1'
											y='1.03011814'
											width='8.41176471'
											height='8.4516129'
											rx='1'
										/>
										<path
											d='M1.65339149,6.83036745 L0.554699968,5.81325032 C0.233272882,5.51568819 0.213926517,5.01389828 0.511488651,4.6924712 C0.518091785,4.68533848 0.524825484,4.67832776 0.531686336,4.67144258 C0.858544797,4.34342512 1.38371232,4.32490499 1.73286527,4.62908276 L3.05519879,5.78108354 L3.05519879,5.78108354 L7.30699386,0.32978863 C7.58944253,-0.0323433495 8.1073123,-0.107105755 8.48065202,0.160353163 C8.83807381,0.416408546 8.92024751,0.913730056 8.66419213,1.27115184 C8.65925933,1.27803742 8.65421696,1.28484384 8.64906694,1.29156849 L4.59991588,6.5787578 C3.92831657,7.45570031 2.6729754,7.62216386 1.7960329,6.95056455 C1.74664225,6.91273912 1.69904336,6.87262981 1.65339149,6.83036745 Z'
											id='Fill-1339'
											fill='#89C947'
										/>
									</g>
									<g id='Group-10' transform='translate(19.735966, 25.000000)'>
										<rect
											id='Rectangle-29'
											stroke='#CAE0EC'
											strokeWidth='2'
											x='-1'
											y='1.03011814'
											width='8.41176471'
											height='8.4516129'
											rx='1'
										/>
										<path
											d='M1.65339149,6.83036745 L0.554699968,5.81325032 C0.233272882,5.51568819 0.213926517,5.01389828 0.511488651,4.6924712 C0.518091785,4.68533848 0.524825484,4.67832776 0.531686336,4.67144258 C0.858544797,4.34342512 1.38371232,4.32490499 1.73286527,4.62908276 L3.05519879,5.78108354 L3.05519879,5.78108354 L7.30699386,0.32978863 C7.58944253,-0.0323433495 8.1073123,-0.107105755 8.48065202,0.160353163 C8.83807381,0.416408546 8.92024751,0.913730056 8.66419213,1.27115184 C8.65925933,1.27803742 8.65421696,1.28484384 8.64906694,1.29156849 L4.59991588,6.5787578 C3.92831657,7.45570031 2.6729754,7.62216386 1.7960329,6.95056455 C1.74664225,6.91273912 1.69904336,6.87262981 1.65339149,6.83036745 Z'
											id='Fill-1339'
											fill='#89C947'
										/>
									</g>
									<g id='Group-10' transform='translate(99.883025, 25.000000)'>
										<rect
											id='Rectangle-29'
											stroke='#CAE0EC'
											strokeWidth='2'
											x='-1'
											y='1.03011814'
											width='8.41176471'
											height='8.4516129'
											rx='1'
										/>
										<path
											d='M1.65339149,6.83036745 L0.554699968,5.81325032 C0.233272882,5.51568819 0.213926517,5.01389828 0.511488651,4.6924712 C0.518091785,4.68533848 0.524825484,4.67832776 0.531686336,4.67144258 C0.858544797,4.34342512 1.38371232,4.32490499 1.73286527,4.62908276 L3.05519879,5.78108354 L3.05519879,5.78108354 L7.30699386,0.32978863 C7.58944253,-0.0323433495 8.1073123,-0.107105755 8.48065202,0.160353163 C8.83807381,0.416408546 8.92024751,0.913730056 8.66419213,1.27115184 C8.65925933,1.27803742 8.65421696,1.28484384 8.64906694,1.29156849 L4.59991588,6.5787578 C3.92831657,7.45570031 2.6729754,7.62216386 1.7960329,6.95056455 C1.74664225,6.91273912 1.69904336,6.87262981 1.65339149,6.83036745 Z'
											id='Fill-1339'
											fill='#89C947'
										/>
									</g>
									<g id='Group-10' transform='translate(3.706555, 10.901086)' stroke='#CAE0EC' strokeWidth='2'>
										<rect id='Rectangle-29' x='79.1470588' y='-1' width='8.41176471' height='8.4516129' rx='1' />
										<rect id='Rectangle-29' x='-1' y='31.2580645' width='8.41176471' height='8.4516129' rx='1' />
									</g>
									<g id='Group-10' transform='translate(51.794790, 8.870968)'>
										<rect
											id='Rectangle-29'
											stroke='#CAE0EC'
											strokeWidth='2'
											x='-1'
											y='1.03011814'
											width='8.41176471'
											height='8.4516129'
											rx='1'
										/>
										<path
											d='M1.65339149,6.83036745 L0.554699968,5.81325032 C0.233272882,5.51568819 0.213926517,5.01389828 0.511488651,4.6924712 C0.518091785,4.68533848 0.524825484,4.67832776 0.531686336,4.67144258 C0.858544797,4.34342512 1.38371232,4.32490499 1.73286527,4.62908276 L3.05519879,5.78108354 L3.05519879,5.78108354 L7.30699386,0.32978863 C7.58944253,-0.0323433495 8.1073123,-0.107105755 8.48065202,0.160353163 C8.83807381,0.416408546 8.92024751,0.913730056 8.66419213,1.27115184 C8.65925933,1.27803742 8.65421696,1.28484384 8.64906694,1.29156849 L4.59991588,6.5787578 C3.92831657,7.45570031 2.6729754,7.62216386 1.7960329,6.95056455 C1.74664225,6.91273912 1.69904336,6.87262981 1.65339149,6.83036745 Z'
											id='Fill-1339'
											fill='#89C947'
										/>
									</g>
								</g>
								<rect id='Rectangle-26' stroke='none' fill='#E8F3F9' x='86' y='44' width='14' height='14' />
								<rect id='Rectangle-26-Copy' stroke='none' fill='#E8F3F9' x='86' y='61' width='14' height='14' />
								<circle id='Oval-3' stroke='none' fill='#E8F3F9' cx='56' cy='6' r='2' />
								<circle id='Oval-3' stroke='none' fill='#E8F3F9' cx='80' cy='6' r='2' />
								<circle id='Oval-3' stroke='none' fill='#E8F3F9' cx='104' cy='6' r='2' />
								<circle id='Oval-3' stroke='none' fill='#E8F3F9' cx='128' cy='6' r='2' />
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
