import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, charmGray, prestoPurple, spanishBlue, success, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	selectLabel: {
		color: charmGray,
		fontSize: 11,
		marginBottom: -4,
		textTransform: 'uppercase',
	},
	templateCard: {
		color: '#3F3F3F',
		paddingBottom: 20,
		width: '100%',
		...CssSelectors.allChildren(
			{
				color: brandPrimaryText,
			},
			'.campaign-template-card-name'
		),
	},
	templateCardContainer: {
		width: '100%',
	},
	templateCardDragging: {
		boxShadow: '0 0 10px 0 rgba(139,139,139,0.20)',
	},
	templateCardsWithIndustrySelector: {
		boxSizing: 'border-box',
		height: `calc(100% - ${84}px)`,
	},
	trigger: {
		height: '40px',
		padding: 0,
		paddingRight: 15,
	},
	triggerLabel: {
		borderRight: '1px solid rgb(216,216,216)',
		color: '#D1d1d1',
		fontSize: 12,
		letterSpacing: 1.1,
		paddingLeft: 10,
		paddingRight: 10,
		textTransform: 'uppercase',
		width: 160,
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
	calendarCardTypeColor: {
		height: 12,
		width: 12,
		borderRadius: '50%',
		marginRight: 8,
		display: 'inline-block',
	},
	calendarCardTypeColorEmail: {
		background: success,
	},
	calendarCardTypeColorSocial: {
		background: spanishBlue,
	},
	calendarCardTypeColorBlog: {
		background: prestoPurple,
	},
	typeskeyInnerWrap: {
		alignItems: 'center',
	},
	typeskeyInnerList: {
		flex: '1 0 0%',
		display: 'flex',
		justifyContent: 'space-around',
		...CssSelectors.allChildren({
			color: charmGray,
			fontSize: 12,
			marginTop: 2,
		}),
	},
	typeskeyInnerWrapTitle: {
		fontSize: 12,
		fontFamily: 'Sora, sans-serif',
		fontWeight: 400,
	},

	showSpan: {
		display: 'none',
		'@media screen and  (min-width: 1332px)': {
			display: 'inline',
		},
	},
	listItem: {
		'@media screen and  (min-width: 1440px)': {
			marginLeft: '0.75rem',
		},
	},
	searchCampaignsContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
	},
	showLabel: {
		display: 'none',
		'@media screen and  (min-width: 1270px)': {
			display: 'inline',
		},
	},
});
