import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { RecipientsCount } from '../../../../../admin/components/email/RecipientsCount';
import { useEmailComposerContext } from '../../../../../models/Email';
import { useEventLogging } from '../../../../../models/Logging';
import { ComposeSurveyFollowUpEmailViewModel } from '../../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../../styles/styles';
import { MonthPickerInput } from '../../../MonthPickerInput';
import { EmailRecipientsList } from '../../EmailRecipientsList';
import { SurveyFollowUpRecipientsEmptyPlaceholder } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	hideDateRangePicker?: boolean;
	onRenderDiscriminator?(): React.ReactNode;
	styles?: StyleDeclarationValue[];
}

const getValidEndDate = (date?: Date) => {
	if (date) {
		const nowMoment = moment();
		const thenMoment = moment(date);
		if (nowMoment.isSame(thenMoment, 'month') && nowMoment.isBefore(thenMoment)) {
			return nowMoment.toDate();
		}
	}

	return date;
};

export const SurveyFollowUpRecipients: React.FC<IProps> = observer(props => {
	const { className, styles = [], onRenderDiscriminator, hideDateRangePicker } = props;
	const { emailComposer } = useEmailComposerContext<
		Api.ISurveyFollowUpOptions,
		Api.SurveyReportViewModel,
		ComposeSurveyFollowUpEmailViewModel
	>();
	const surveyReport = emailComposer.followUpSource;

	const { logApiError } = useEventLogging('SurveyFollowUpRecipients');
	const [emailApproximation, setEmailApproximation] = React.useState<Api.ContactFilterApproximation>(null);

	const getContacts = React.useCallback(async () => {
		try {
			await emailComposer?.getNextBatchOfRecipients();
		} catch (error) {
			logApiError('ContactsLoad-Error', error);
		}
	}, [emailComposer, logApiError]);

	const [loadingApproximation, setLoadinApproximation] = React.useState<boolean>(false);
	const getEmailApproximation = React.useCallback(async () => {
		setLoadinApproximation(true);
		try {
			const opResult = await emailComposer?.getEmailApproximation();
			setEmailApproximation(opResult.value);
		} catch (error) {
			logApiError('ApproximationLoad-Error', error);
		}
		setLoadinApproximation(false);
	}, [emailComposer, logApiError]);

	// #region Date Range
	const [dateRange, setDateRange] = React.useState<{
		start?: Date;
		end?: Date;
	}>(
		emailComposer.selectedFollowUpDateRange
			? {
					...emailComposer.selectedFollowUpDateRange,
					end: getValidEndDate(emailComposer.selectedFollowUpDateRange.end),
				}
			: emailComposer.selectedFollowUpDateRange
	);
	const onDateChanged = React.useCallback(
		(id: 'start' | 'end') => (date: Date) => {
			const nextDateRange: { start?: Date; end?: Date } = {
				end: dateRange.end,
				start: dateRange.start,
			};
			if (id === 'start') {
				const m = moment(date).startOf('month');
				nextDateRange.start = m.toDate();
				if (!nextDateRange.end || m.isAfter(nextDateRange.end)) {
					nextDateRange.end = getValidEndDate(m.add(1, 'month').endOf('month').toDate());
				}
			} else if (id === 'end') {
				const m = moment(date).endOf('month');
				nextDateRange.end = m.toDate();
				if (!nextDateRange.start || m.isBefore(nextDateRange.start)) {
					nextDateRange.start = m.subtract(1, 'month').startOf('month').toDate();
				}
			}
			setDateRange(nextDateRange);
			emailComposer.selectedFollowUpDateRange = nextDateRange;
			getContacts();
			getEmailApproximation();
		},
		[dateRange.end, dateRange.start, emailComposer, getContacts, getEmailApproximation]
	);
	const onShowAllTimeClicked = React.useCallback(() => {
		if (!surveyReport?.survey) {
			return;
		}
		const nextDateRange = {
			end: getValidEndDate(
				moment(surveyReport.survey.expirationMoment || moment())
					.endOf('month')
					.toDate()
			),
			start: moment(surveyReport.survey.startMoment || surveyReport.survey.creationMoment)
				.startOf('month')
				.toDate(),
		};
		setDateRange(nextDateRange);
		emailComposer.selectedFollowUpDateRange = nextDateRange;
		getContacts();
		getEmailApproximation();
	}, [surveyReport.survey, emailComposer, getContacts, getEmailApproximation]);
	// #endregion

	React.useEffect(() => {
		getContacts();
		getEmailApproximation();
	}, [getContacts, getEmailApproximation]);

	// refetch estimate on change of contactsToOmit
	React.useEffect(() => {
		getEmailApproximation();
	}, [getEmailApproximation, emailComposer?.emailMessage?.contactsToOmit?.length]);
	React.useEffect(() => {
		if (!hideDateRangePicker) {
			emailComposer.emailMessage.options.followUp.surveyResponseFilter.startDate = dateRange.start.toISOString();
			emailComposer.emailMessage.options.followUp.surveyResponseFilter.endDate = dateRange.end.toISOString();
		}
	}, [dateRange, emailComposer, hideDateRangePicker]);

	const onRenderEmptyPlaceholder = () => <SurveyFollowUpRecipientsEmptyPlaceholder />;

	return (
		<div className={`${css(styleSheet.container, ...styles)} survey-followup-recipients ${className || ''}`}>
			<div className={css(styleSheet.header)}>Recipients</div>
			<div className={css(styleSheet.discriminator)}>{onRenderDiscriminator?.()}</div>
			{!hideDateRangePicker ? (
				<div className={css(styleSheet.timeRange)}>
					<div className={css(styleSheet.timeRangeHeader)}>
						<div>Time range:</div>
						<button className={css(baseStyleSheet.brandLink)} onClick={onShowAllTimeClicked}>
							<span>Show all time</span>
						</button>
					</div>
					<div className={css(styleSheet.timeRangeInputs)}>
						<MonthPickerInput date={dateRange.start} onDateChanged={onDateChanged('start')} />
						<span>to</span>
						<MonthPickerInput date={dateRange.end} onDateChanged={onDateChanged('end')} />
					</div>
				</div>
			) : null}
			{/* Not sure why emailApproximation.hasEmail !== emailApproximation.total for surveys, so passing total to RecipientsCount instead */}
			<RecipientsCount
				approximation={null}
				followUpCount={emailApproximation?.total || 0}
				className={css(styleSheet.recipientsCount)}
				loading={loadingApproximation}
			/>
			<EmailRecipientsList
				bulkEmailComposer={emailComposer}
				className={css(styleSheet.recipientsList)}
				disableContactAdding={true}
				disableContactEdits={true}
				onRenderEmptyPlaceholder={onRenderEmptyPlaceholder}
				onScrollToBottom={getContacts}
			/>
		</div>
	);
});
