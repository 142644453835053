import { DefaultEditableActionItemContentCSS } from '../../../models';
import { titles } from '../../styles/colors';
import { bs } from '../../styles/styles';
import { IRichContentDocumentEditorProps, RichContentDocumentEditor } from '../richContent/RichContentDocumentEditor';

export function TextCampaignEditor({ styles, config: editorConfig, ...props }: IRichContentDocumentEditorProps) {
	const config = {
		autoresizeToFitContent: true,
		contentRawCss: DefaultEditableActionItemContentCSS.replace('padding: 0;', 'padding: 2px;').replace(
			/color:[ ]?[#]?[a-z0-9]*;/i,
			`color: ${titles};`
		),
		minHeight: 14,
		plugins: ['placeholders'],
		toolbar: false,
		useDefaultEmailPlaceholders: true,
		...editorConfig,
	};

	return <RichContentDocumentEditor config={config} styles={[bs.textField, ...(styles ?? [])]} {...props} />;
}
