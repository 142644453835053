import { brandPrimaryHover, brandSecondary, titles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		margin: '0 4px',
	},
	buttonContainer: {
		display: 'flex',
		marginBottom: 18,
		marginTop: 18,
	},
	checkboxContainer: {
		display: 'flex',
		marginBottom: 18,
		marginTop: 18,
	},
	checkboxText: {
		color: titles,
		fontSize: 14,
		fontWeight: 600,
		paddingRight: 12,
	},
	container: {},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 500,
	},
	numberText: {
		color: brandPrimaryHover,
	},
	searchContainer: {
		marginBottom: 18,
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		marginBottom: 18,
		marginTop: 18,
	},
	warningText: {
		textAlign: 'center',
	},
});
