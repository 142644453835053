import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const ManualAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon
			className={`manual-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<g filter='url(#manualAutomationTriggerGraphicFilter)'>
				<circle cx='86' cy='86' r='80' fill='#F5F5F5' />
			</g>
			<mask id='manualAutomationTriggerGraphicMask' maskUnits='userSpaceOnUse' x='6' y='6' width='160' height='160'>
				<circle cx='86' cy='86' r='80' fill='#fff' />
			</mask>
			<g mask='url(#manualAutomationTriggerGraphicMask)'>
				<path
					d='M113 87.24a29.458 29.458 0 00-3.305-13.652c-1.038-1.98-1.985-3.21-2.722-3.965a1.94 1.94 0 00-2.216-.44 2.002 2.002 0 00-.778.504c-.985 1.024-1.876 2.377-2.653 3.819a29.554 29.554 0 00-3.474 14.027v2.937h-1.878V56.339c0-7.154-1.596-14.213-4.723-20.48-1.48-2.966-2.832-4.809-3.872-5.942a2.68 2.68 0 00-4.036 0c-1.407 1.535-2.677 3.563-3.787 5.72-3.287 6.396-4.957 13.653-4.957 21.02V90.47h-1.624v-3.078a29.555 29.555 0 00-3.474-14.027c-.777-1.44-1.668-2.793-2.653-3.819a1.94 1.94 0 00-2.255-.413l.067.037a2.006 2.006 0 00-.633.445c-.73.755-1.676 1.985-2.722 3.965A29.443 29.443 0 0058 87.232v51.03h5.794l-.632 4.195V155h4.735v-12.543l-.631-4.195h5.71V91.79h1.623v46.263h4.626l-.664 4.402V155h4.737v-12.543l-.665-4.402h6.235l-.665 4.402V155h4.738v-12.543l-.665-4.402h3.694V91.79h1.878v46.542h1.522v-.07h5.033l-.632 4.195V155h4.738v-12.543l-.632-4.195H113V87.24z'
					fill='url(#manualAutomationTriggerGraphicGradient0)'
					opacity='.7'
				/>
				<path d='M69 140h-4l.714-6h2.572l.714 6z' fill='#535461' />
				<path d='M65 137h3l-.201-2H65.2l-.2 2z' fill='#000' opacity='.1' />
				<path d='M107 140h-4l.714-6h2.572l.714 6z' fill='#535461' />
				<path d='M103 137h3l-.201-2h-2.598l-.201 2z' fill='#000' opacity='.1' />
				<path d='M83 140h-4l.714-6h2.572l.714 6z' fill='#535461' />
				<path d='M80 137h3l-.201-2H80.2l-.2 2z' fill='#000' opacity='.1' />
				<path d='M92 140h-4l.714-6h2.572l.714 6z' fill='#535461' />
				<path d='M89 137h3l-.203-2h-2.596L89 137z' fill='#000' opacity='.1' />
				<path fill='#535461' d='M69 90h33v1H69z' />
				<path
					d='M106.37 69.6a1.764 1.764 0 00-1.322-.6c-.503 0-.984.218-1.322.6-.921 1.005-1.752 2.335-2.48 3.748A30.2 30.2 0 0098 87.114V137h14V86.907a30.085 30.085 0 00-3.093-13.413c-.971-1.943-1.856-3.152-2.537-3.894zm-41.737 0c.338-.382.818-.6 1.32-.6.504 0 .984.218 1.322.6.921 1.005 1.752 2.335 2.48 3.748A30.204 30.204 0 0173 87.114V137H59V86.907a30.093 30.093 0 013.092-13.413c.969-1.943 1.854-3.152 2.54-3.894z'
					fill='#E0E0E0'
					stroke='#979797'
				/>
				<path
					d='M59 88l3 .015V92h-3v-4zm11 0l3 .015V92h-3v-4zm28 0l3 .015V92h-3v-4zm11 0l3 .015V92h-3v-4z'
					fill='#00528C'
				/>
				<path fill='url(#manualAutomationTriggerGraphicGradient1)' d='M65 140h4v13h-4z' />
				<path fill='url(#manualAutomationTriggerGraphicGradient2)' d='M79 140h4v13h-4z' />
				<path fill='url(#manualAutomationTriggerGraphicGradient3)' d='M88 140h4v13h-4z' />
				<path fill='url(#manualAutomationTriggerGraphicGradient4)' d='M103 140h4v13h-4z' />
				<path
					d='M87.957 29.898A2.446 2.446 0 0086.069 29c-.728 0-1.419.328-1.888.898-1.316 1.503-2.498 3.491-3.543 5.607C77.564 41.774 76 48.888 76 56.103V136h20V55.78c0-7.01-1.493-13.934-4.42-20.06-1.385-2.906-2.649-4.711-3.623-5.822z'
					fill='#EEE'
					stroke='#979797'
				/>
				<rect x='80' y='39' width='11' height='5' rx='2.5' fill='#00528C' />
				<path
					d='M133.186 150.817c-1.384-8.44-8.951-14.387-17.38-13.659-8.43.727-14.891 7.884-14.841 16.44v.241c-.6.092-1.194.22-1.778.385-1.534-6.179-6.764-10.702-13.038-11.276-6.274-.575-12.222 2.925-14.829 8.725a14.497 14.497 0 00-4.088-.945 16.42 16.42 0 00-1.116-3.725c-.504-7.801-6.855-13.898-14.585-14.002-7.73-.103-14.238 5.822-14.946 13.607a16.43 16.43 0 00-1.45 5.211c-7.027 2.957-10.7 10.813-8.499 18.181h118.732a15.136 15.136 0 00-1.719-12.431 14.81 14.81 0 00-10.463-6.752z'
					fill='url(#manualAutomationTriggerGraphicGradient5)'
					opacity='.7'
				/>
				<circle cx='52' cy='148' r='14' fill='url(#manualAutomationTriggerGraphicGradient6)' />
				<circle cx='52.5' cy='153.5' r='15.5' fill='url(#manualAutomationTriggerGraphicGradient7)' />
				<circle cx='115.5' cy='153.5' r='15.5' fill='url(#manualAutomationTriggerGraphicGradient8)' />
				<circle cx='115' cy='155' r='14' fill='url(#manualAutomationTriggerGraphicGradient9)' />
				<path
					d='M98 157.372c.005 4.604-2.148 8.931-5.787 11.628H75.8c-5.362-3.975-7.279-11.257-4.597-17.461 2.682-6.203 9.231-9.633 15.702-8.225 6.471 1.409 11.098 7.272 11.093 14.058H98z'
					fill='url(#manualAutomationTriggerGraphicGradient10)'
				/>
				<path
					d='M81 164.951c.001 1.372-.2 2.736-.6 4.049H53.6a13.93 13.93 0 012.135-12.328A14.012 14.012 0 0166.916 151h.086c.393 0 .782.016 1.167.048A13.965 13.965 0 0181 164.951z'
					fill='url(#manualAutomationTriggerGraphicGradient11)'
				/>
				<path
					d='M116 167.76a13.375 13.375 0 01-.056 1.24H88.058a13.192 13.192 0 01-.058-1.238c-.005-6.807 5.056-12.593 11.902-13.607a14.483 14.483 0 013.156-.117c5.111.384 9.601 3.482 11.706 8.077A13.5 13.5 0 01116 167.76z'
					fill='url(#manualAutomationTriggerGraphicGradient12)'
				/>
				<path
					d='M143 164.953a13.842 13.842 0 01-.6 4.047h-26.802a13.993 13.993 0 01-.36-6.618c.023-.121.046-.244.073-.362 1.391-6.429 7.095-11.02 13.694-11.02.44 0 .873.019 1.301.061.193.016.385.039.577.064 6.941.941 12.117 6.848 12.117 13.828z'
					fill='url(#manualAutomationTriggerGraphicGradient13)'
				/>
				<path
					d='M55.398 169a13.922 13.922 0 00-3.54-13.951 14.041 14.041 0 00-15.226-2.983c-6.641 2.753-10.111 10.068-8.03 16.928h26.796V169z'
					fill='url(#manualAutomationTriggerGraphicGradient14)'
				/>
				<circle cx='137.5' cy='137.5' r='2.5' fill='url(#manualAutomationTriggerGraphicGradient15)' opacity='.7' />
				<circle cx='137.5' cy='137.5' r='2.5' fill='url(#manualAutomationTriggerGraphicGradient16)' />
				<circle cx='150' cy='163' r='3' fill='url(#manualAutomationTriggerGraphicGradient17)' opacity='.7' />
				<circle cx='149.5' cy='162.5' r='2.5' fill='url(#manualAutomationTriggerGraphicGradient18)' />
				<circle cx='28.5' cy='146.5' r='3.5' fill='url(#manualAutomationTriggerGraphicGradient19)' opacity='.7' />
				<path
					opacity='.2'
					d='M151.222 37.995c.001 11.727-9.113 21.436-20.82 22.18a20.52 20.52 0 01-1.408.047 22.227 22.227 0 01-20.876-14.626 22.218 22.218 0 016.598-24.617 22.234 22.234 0 0125.395-2.23 22.438 22.438 0 016.624 5.862 22.108 22.108 0 014.487 13.384z'
					fill='#046CB6'
					stroke='#00528C'
				/>
				<path
					opacity='.05'
					d='M122.236 21.56a18.605 18.605 0 00-2.648 2.136c-.564.48-1.048 1.152-1.415 1.966-.366.833-.379 1.917-.034 2.77.427.905 1.283 1.049 1.997 1.108 1.336.435 2.722-.266 3.626-1.834l1.527-2.123c.53-.734 1.112-1.75.881-2.784-.175-.793-.757-1.18-1.283-1.31-1.312-.396-2.673-.03-3.819 1.028'
					fill='#F55F44'
				/>
				<circle opacity='.05' cx='116.5' cy='44.944' r='1.389' fill='#F55F44' />
				<circle opacity='.05' cx='138.722' cy='39.389' r='4.167' fill='#F55F44' />
				<path
					opacity='.05'
					d='M145.667 24.171c-.045 2.975-2.406 5.391-5.367 5.492-2.96.102-5.479-2.148-5.725-5.113-.246-2.965 1.868-5.602 4.804-5.994a21.338 21.338 0 016.288 5.615zm0 26.952c-3.841 5.346-9.958 8.694-16.625 9.1a10.295 10.295 0 01-.042-.9c.001-3.859 2.226-7.388 5.751-9.122a10.638 10.638 0 0110.916.922z'
					fill='#F55F44'
				/>
				<circle opacity='.25' cx='140' cy='87' r='1' fill='#535461' />
				<circle opacity='.25' cx='7' cy='113' r='1' fill='#535461' />
				<circle opacity='.25' cx='56' cy='72' r='1' fill='#535461' />
				<circle opacity='.25' cx='52' cy='49' r='1' fill='#535461' />
				<circle opacity='.25' cx='24' cy='94' r='1' fill='#535461' />
				<path
					opacity='.25'
					d='M165 95.245h-1.246V94h-.508v1.245H162v.51h1.246V97h.508v-1.244H165v-.511zM97 21.246h-1.246V20h-.508v1.246H94v.508h1.246V23h.508v-1.246H97v-.508zm59 68.999h-1.247V89h-.508v1.245H153v.51h1.245V92h.508v-1.244H156v-.511zm-32 29h-1.245V118h-.508v1.245H121v.511h1.247V121h.508v-1.244H124v-.511zm-92-60h-.83V58h-.34v1.245H30v.508h.83V61h.34v-1.247H32v-.508zm13 48.001h-1.247V106h-.508v1.246H42v.508h1.245V109h.508v-1.246H45v-.508z'
					fill='#535461'
				/>
				<circle cx='149.5' cy='162.5' r='2.5' fill='url(#manualAutomationTriggerGraphicGradient20)' />
				<circle opacity='.1' cx='47' cy='92' r='.05' fill='#F55F44' />
				<path fill='#000' d='M76 59h20v1H76z' opacity='.1' />
			</g>
			<defs>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient0'
					x1='113'
					y1='155'
					x2='113'
					y2='29.006'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient1'
					x1='64.999'
					y1='140'
					x2='64.999'
					y2='153.002'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E0E0E0' />
					<stop offset='.31' stopColor='#FCCC63' />
					<stop offset='.77' stopColor='#F55F44' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient2'
					x1='79.001'
					y1='140'
					x2='79.001'
					y2='153.002'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E0E0E0' />
					<stop offset='.31' stopColor='#FCCC63' />
					<stop offset='.77' stopColor='#F55F44' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient3'
					x1='88.001'
					y1='140'
					x2='88.001'
					y2='153.002'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E0E0E0' />
					<stop offset='.31' stopColor='#FCCC63' />
					<stop offset='.77' stopColor='#F55F44' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient4'
					x1='103.001'
					y1='140'
					x2='103.001'
					y2='153.002'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E0E0E0' />
					<stop offset='.31' stopColor='#FCCC63' />
					<stop offset='.77' stopColor='#F55F44' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient5'
					x1='146.013'
					y1='170.01'
					x2='146.013'
					y2='133.003'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient6'
					x1='38'
					y1='134'
					x2='38'
					y2='162'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient7'
					x1='37'
					y1='138'
					x2='37'
					y2='169'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient8'
					x1='100'
					y1='138'
					x2='100'
					y2='169'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient9'
					x1='101'
					y1='141'
					x2='101'
					y2='169'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient10'
					x1='70.017'
					y1='143.017'
					x2='70.017'
					y2='169'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient11'
					x1='52.992'
					y1='150.99'
					x2='52.992'
					y2='169.002'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient12'
					x1='87.996'
					y1='153.996'
					x2='87.996'
					y2='169'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient13'
					x1='114.994'
					y1='150.998'
					x2='114.994'
					y2='169.002'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient14'
					x1='28.003'
					y1='151.007'
					x2='28.003'
					y2='169'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient15'
					x1='140'
					y1='140'
					x2='140'
					y2='135'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient16'
					x1='135'
					y1='135'
					x2='135'
					y2='140'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient17'
					x1='152.999'
					y1='166'
					x2='152.999'
					y2='160.002'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient18'
					x1='147'
					y1='160'
					x2='147'
					y2='165'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E0E0E0' />
					<stop offset='.77' stopColor='#B3B3B3' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient19'
					x1='32'
					y1='150'
					x2='32'
					y2='143'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='gray' stopOpacity='.25' />
					<stop offset='.54' stopColor='gray' stopOpacity='.12' />
					<stop offset='1' stopColor='gray' stopOpacity='.1' />
				</linearGradient>
				<linearGradient
					id='manualAutomationTriggerGraphicGradient20'
					x1='147'
					y1='160'
					x2='147'
					y2='165'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#F2F2F2' />
					<stop offset='.31' stopColor='#E6E6E6' />
					<stop offset='.77' stopColor='#CCC' />
				</linearGradient>
				<filter
					id='manualAutomationTriggerGraphicFilter'
					x='0'
					y='0'
					width='172'
					height='172'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
					<feOffset />
					<feGaussianBlur stdDeviation='3' />
					<feColorMatrix values='0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0.100034 0' />
					<feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
					<feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
				</filter>
			</defs>
		</SvgIcon>
	);
};
