import { IConfirmationDialogOption } from '../../../components/ConfirmationDialog';

export const CancelConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Cancel Post',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Nevermind',
	},
];

export const CancelUpdateConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Cancel Update',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Return to Update',
	},
];

export const UpdateConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: false,
		representedObject: true,
		title: 'Confirm Update',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Nevermind',
	},
];
