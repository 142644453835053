import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryText, brandSecondary, header } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		minWidth: 500,
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginTop: 20,
			},
		}),
	},
	importButton: {
		marginTop: 30,
	},
	label: {
		color: brandPrimary,
		display: 'inline-block',
		fontSize: 20,
		marginBottom: 8,
	},
	uploadModalDescription: {
		color: brandSecondary,
		fontSize: 12,
	},
	uploadModalInnerWrap: {
		padding: '38px 0',
	},
	uploadModalRecommendation: {
		color: header,
		fontSize: 12,
		margin: '0 0 12px',
		textAlign: 'right',
	},
	uploadModalSubTitle: {
		color: brandPrimaryText,
		fontSize: 18,
		margin: 0,
	},
	uploadModalTitle: {
		color: brandPrimaryText,
		fontSize: 24,
		margin: 0,
	},
	userSelectBox: {
		maxWidth: '50%',
	},
});
