import { StyleSheet } from 'aphrodite';
import { brandSecondary, success, titles } from '../../styles/colors';
import { CssSelectors, Layouts, cardStyle, ctaButtonProps } from '../../styles/styles';

export const HtmlNewsletterEditorHeaderHeight = 90;

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				width: '100%',
			},
			'*'
		),
	},
	editNameButton: {
		alignItems: 'center',
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
	},
	editNameLabel: {
		color: titles,
		fontSize: 14,
		marginBottom: 6,
	},
	editorContainer: {
		height: '100%',
		width: '100%',
	},
	editorPreviewer: {
		height: '100%',
		width: '100%',
	},
	editorPreviewerContainer: {
		height: '100%',
		position: 'relative',
		width: '100%',
	},
	editorPreviewerWarning: {
		alignItems: 'center',
		background: 'rgba(100,100,100,.3)',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
		zIndex: 1,
	},
	editorPreviewerWarningCard: {
		...cardStyle,
		color: titles,
		fontSize: 14,
		padding: 20,
	},
	editorPreviewerWarningCardTitle: {
		color: brandSecondary,
		fontSize: 18,
		marginBottom: 15,
	},
	editorPreviewerWarningCta: {},
	fileManageInput: {
		height: 0,
		left: -999,
		opacity: 0,
		position: 'fixed',
		top: -999,
		width: 0,
		zIndex: -1,
	},
	header: {
		height: HtmlNewsletterEditorHeaderHeight,
		...Layouts.verticalStack(20),
	},
	headerActions: {
		flexShrink: 0,
	},
	headerRow: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerSecondaryRow: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	headerSendTestEmailCta: {
		alignItems: 'center',
		color: '#767676',
		display: 'flex',
		fontSize: 14,
	},
	infoMessage: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		maxWidth: 310,
		padding: '6px 6px 6px 16px',
	},
	infoMessageContainer: {
		marginBottom: '6px',
	},
	name: {
		maxWidth: '80%',
	},
	nameContainer: {
		color: titles,
		fontSize: 18,
		width: '40%',
	},
	nameLabel: {
		color: '#858585',
		fontSize: 14,
		marginBottom: 4,
	},
	sendButton: {
		...ctaButtonProps(success),
	},
	checkboxText: {
		color: titles,
	},
});
