import * as Api from '@ViewModels';
import moment from 'moment';
import { useMemo, useState } from 'react';

export const KeyFactErrors = {
	Policy: 'Policy',
	StartDate: 'StartDate',
	EndDate: 'EndDate',
	Age: 'Age',
};

export const AGE_RANGE_KEY = 'AgeRange';

const findMatchingKeyFact = (criterion: Api.IContactFilterCriteria) => {
	return criterion.criteria?.find(subCriterion => {
		const isMatch = subCriterion.property === Api.ContactFilterCriteriaProperty.KeyDateKind;
		if (isMatch) {
			return true;
		}
		if (subCriterion.op === Api.FilterOperator.Or && subCriterion.criteria) {
			return subCriterion.criteria.find(subSubCriterion => {
				if (subSubCriterion.op === Api.FilterOperator.And && subSubCriterion.criteria) {
					return subSubCriterion.criteria.find(subSubSubCriterion => {
						return (
							subSubSubCriterion.property === Api.ContactFilterCriteriaProperty.KeyDateKind &&
							subSubSubCriterion.value === Api.KeyDateKind.Birthday
						);
					});
				}
			});
		}
	});
};

const getInitialValues = (criteria: Api.IContactFilterCriteria[]) => {
	const keyFactCriteria = criteria.find(criterion => findMatchingKeyFact(criterion));

	if (!keyFactCriteria?.criteria) {
		return {
			keyFact: null,
			policies: null,
			startDate: null,
			endDate: null,
			minAge: null,
			maxAge: null,
		};
	}

	const startDateCriteria = keyFactCriteria.criteria.find(criterion => {
		return criterion.op === Api.FilterOperator.Gte;
	});
	const startDate = startDateCriteria?.value ? moment(startDateCriteria.value).toDate() : null;

	const endDateCriteria = keyFactCriteria.criteria.find(criterion => {
		return criterion.op === Api.FilterOperator.Lte;
	});
	const endDate = endDateCriteria?.value ? moment(endDateCriteria.value).toDate() : null;

	const keyDateKindCriteria = keyFactCriteria.criteria.find(criterion => {
		return criterion.property === Api.ContactFilterCriteriaProperty.KeyDateKind;
	});
	const getKeyFact = () => {
		const startMoment = moment(startDate);
		const endMoment = moment(endDate);
		const isAYearOrMoreApart = endMoment.diff(startMoment, 'days') > 364;
		if (isAYearOrMoreApart) {
			return AGE_RANGE_KEY;
		}
		return keyDateKindCriteria?.value || null;
	};
	const keyFact = getKeyFact();

	const guessAge = (date: Date) => {
		return moment().diff(date, 'years');
	};

	let minAge: number = null;
	let maxAge: number = null;
	if (keyFact === AGE_RANGE_KEY) {
		minAge = guessAge(endDate);
		maxAge = guessAge(startDate);
	}

	const policyCriteria = keyFactCriteria.criteria.find(criterion => {
		return criterion.criteria?.find(subCriterion => subCriterion.property === Api.ContactFilterCriteriaProperty.Policy);
	});
	const policies = policyCriteria?.criteria ? policyCriteria.criteria.map(criterion => criterion.value) : [];

	return {
		keyFact,
		policies,
		startDate,
		endDate,
		minAge,
		maxAge,
	};
};

export const useKeyFactFilters = (initialFilterCriteria: Api.IContactFilterCriteria[] = []) => {
	const { keyFact, policies, startDate, endDate, minAge, maxAge } = getInitialValues(initialFilterCriteria);
	const [revertableData] = useState({ keyFact, policies, startDate, endDate });
	const [selectedKeyFact, setSelectedKeyFact] = useState((keyFact as Api.KeyDateKind | typeof AGE_RANGE_KEY) ?? null);

	const [selectedPolicies, setSelectedPolicies] = useState<string[]>(policies ?? []);
	const [selectedKeyFactStartDate, setSelectedKeyFactStartDate] = useState<Date>(startDate);
	const [selectedKeyFactEndDate, setSelectedKeyFactEndDate] = useState<Date>(endDate);
	const [selectedMinAge, setSelectedMinAge] = useState<number>(minAge);
	const [selectedMaxAge, setSelectedMaxAge] = useState<number>(maxAge);

	const keyDateCriteria: Api.IContactFilterCriteria = useMemo(() => {
		return {
			property: Api.ContactFilterCriteriaProperty.KeyDateKind,
			value: selectedKeyFact ? selectedKeyFact.toString() : undefined,
		};
	}, [selectedKeyFact]);

	const startDateCriteria: Api.IContactFilterCriteria = useMemo(() => {
		return {
			op: Api.FilterOperator.Gte,
			property: Api.ContactFilterCriteriaProperty.KeyDate,
			value: moment(selectedKeyFactStartDate).format('YYYY-MM-DD'),
		};
	}, [selectedKeyFactStartDate]);

	const endDateCriteria: Api.IContactFilterCriteria = useMemo(() => {
		return {
			op: Api.FilterOperator.Lte,
			property: Api.ContactFilterCriteriaProperty.KeyDate,
			value: moment(selectedKeyFactEndDate).format('YYYY-MM-DD'),
		};
	}, [selectedKeyFactEndDate]);

	const keyFactCriteria = useMemo(() => {
		if (selectedKeyFact === AGE_RANGE_KEY) {
			const newCriteria = [];

			const newMinAge = selectedMinAge ?? 0;
			const newMaxAge = selectedMaxAge ?? 100;

			const today = moment();
			const thisDayOfMonth = today.date();
			const thisMonth = today.month() + 1; // 0 based month()
			const thisYear = today.year();

			const latestYear = thisYear - newMinAge;
			const earliestYear = thisYear - (newMaxAge + 1);

			const minDate = moment(`${earliestYear}/${thisMonth}/${thisDayOfMonth}`).add(1, 'day').format('YYYY-MM-DD');
			const maxDate = moment(`${latestYear}/${thisMonth}/${thisDayOfMonth}`).format('YYYY-MM-DD');

			newCriteria.push({
				op: Api.FilterOperator.Gte,
				property: Api.ContactFilterCriteriaProperty.KeyDate,
				value: minDate,
			});
			newCriteria.push({
				op: Api.FilterOperator.Lte,
				property: Api.ContactFilterCriteriaProperty.KeyDate,
				value: maxDate,
			});
			newCriteria.push({
				property: Api.ContactFilterCriteriaProperty.KeyDateKind,
				value: Api.KeyDateKind.Birthday,
			});

			return {
				criteria: newCriteria,
			} as Api.IContactFilterCriteria;
		}
		const criteria = [startDateCriteria, endDateCriteria];
		criteria.push(keyDateCriteria);

		return {
			criteria,
			op: Api.FilterOperator.And,
		} as Api.IContactFilterCriteria;
	}, [selectedKeyFact, keyDateCriteria, startDateCriteria, endDateCriteria, selectedMinAge, selectedMaxAge]);

	const getKeyFactCriteria = () => {
		const newKeyFactCriteria: PartiallyRequired<Api.IContactFilterCriteria, 'criteria'> = JSON.parse(
			JSON.stringify(keyFactCriteria)
		);

		if (selectedKeyFact === Api.KeyDateKind.Renewal) {
			const newPoliciesCriteria: Api.IContactFilterCriteria = {
				op: Api.FilterOperator.Or,
				criteria: [],
			};
			selectedPolicies.forEach(policy => {
				newPoliciesCriteria.criteria!.push({
					property: Api.ContactFilterCriteriaProperty.Policy,
					value: policy,
				});
			});
			newKeyFactCriteria.criteria.push(newPoliciesCriteria);
		}

		return newKeyFactCriteria;
	};

	const keyFactErrors = useMemo(() => {
		const errors: string[] = [];
		if (!selectedKeyFact) {
			return errors;
		}
		if (selectedKeyFact === Api.KeyDateKind.Renewal) {
			if (selectedPolicies.length === 0) {
				errors.push(KeyFactErrors.Policy);
			}
		}
		const isAgeRange = selectedKeyFact === AGE_RANGE_KEY;
		if (!isAgeRange && !selectedKeyFactStartDate) {
			errors.push(KeyFactErrors.StartDate);
		}
		if (!isAgeRange && !selectedKeyFactEndDate) {
			errors.push(KeyFactErrors.EndDate);
		}
		return errors;
	}, [selectedKeyFact, selectedPolicies, selectedKeyFactStartDate, selectedKeyFactEndDate]);

	const cancelChanges = () => {
		const { keyFact: _keyFact, policies: _policies, startDate: _startDate, endDate: _endDate } = revertableData;
		setSelectedKeyFact((_keyFact as Api.KeyDateKind) ?? null);
		setSelectedPolicies(_policies ?? []);
		setSelectedKeyFactStartDate(_startDate);
		setSelectedKeyFactEndDate(_endDate);
	};

	const refreshKeyFacts = (newKeyFactCriteria: Api.IContactFilterCriteria[]) => {
		const {
			keyFact: _keyFact,
			policies: _policies,
			startDate: _startDate,
			endDate: _endDate,
		} = getInitialValues(newKeyFactCriteria);
		setSelectedKeyFact((_keyFact as Api.KeyDateKind) ?? null);
		setSelectedPolicies(_policies ?? []);
		setSelectedKeyFactStartDate(_startDate);
		setSelectedKeyFactEndDate(_endDate);
	};

	return {
		findMatchingKeyFact,
		getKeyFactCriteria,
		selectedKeyFact,
		setSelectedKeyFact,
		selectedPolicies,
		setSelectedPolicies,
		selectedKeyFactStartDate,
		setSelectedKeyFactStartDate,
		selectedKeyFactEndDate,
		setSelectedKeyFactEndDate,
		selectedMinAge,
		setSelectedMinAge,
		selectedMaxAge,
		setSelectedMaxAge,
		keyFactErrors,
		cancelChanges,
		refreshKeyFacts,
	};
};
