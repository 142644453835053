import { StyleSheet } from 'aphrodite';
import { label, titles } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		height: `calc(100% - 56px)`,
		width: '100%',
	},
	header: {
		color: titles,
		display: 'flex',
		width: '100%',
	},
	infoPopoverAnchor: {
		paddingLeft: 6,
	},
	infoPopoverContent: {
		color: label,
		fontSize: 14,
		padding: '0px 8px',
		width: 260,
	},
});
