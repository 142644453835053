export const ApproveAllIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
			<path
				fill='#0D0F11'
				fillOpacity='.7'
				fillRule='evenodd'
				d='M6.589 13a7.95 7.95 0 0 1-.59-3c0-4.411 3.59-8 8-8 4.412 0 8 3.589 8 8a7.954 7.954 0 0 1-1.764 5h2.412A9.928 9.928 0 0 0 24 10c0-5.514-4.486-10-10-10S4 4.486 4 10a9.98 9.98 0 0 0 .46 3h2.13ZM18 11h-4a1 1 0 0 1-1-1V4h2v5h3v2ZM6 21h10.5l1.5-2H9v-1h.009a.996.996 0 0 1 .284-.707l1.49-1.49-.289-.579L6 16.721V21Zm-2-5H2v6h2v-6Zm16 1a.998.998 0 0 1 .8 1.6l-3 4a1 1 0 0 1-.8.4H6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h4c.423 0 .781.266.927.637l4.757-1.586a1 1 0 0 1 1.21.502l1 2a1 1 0 0 1-.187 1.154l-.293.293H20Z'
				clipRule='evenodd'
			/>
		</svg>
	);
};
