import { ModalChildComponentContextKey } from '../../../../models';
import { UserSessionViewModelKey } from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { ComplianceCompleteIcon } from '../../svgs/icons/ComplianceCompleteIcon';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	onDismiss?(e: React.MouseEvent<HTMLElement>): void;
	styles?: StyleDeclarationValue[];
}
class _ComplianceConfirmation extends React.Component<IProps> {
	public render() {
		const { onDismiss } = this.props;
		return (
			<div className={`${css(styleSheet.container)} send-scheduler-complete`}>
				<div className={css(styleSheet.iconContainer)}>
					<ComplianceCompleteIcon className={css(styleSheet.icon)} />
				</div>
				<div className={css(styleSheet.titleMessage)}>We&apos;ve emailed you a copy of the campaign.</div>
				<div className={css(styleSheet.detailMessage)}>
					This campaign is now pending. You can come back and mark this campaign as &quot;Approved&quot; later.
				</div>
				<button
					className={css(baseStyleSheet.ctaButton, baseStyleSheet.verticalStack, styleSheet.stack)}
					onClick={onDismiss}
				>
					<span>Okay</span>
				</button>
			</div>
		);
	}
}

const ComplianceConfirmationAsObserver = observer(_ComplianceConfirmation);
export const ComplianceConfirmation = inject(
	UserSessionViewModelKey,
	ModalChildComponentContextKey
)(ComplianceConfirmationAsObserver);
