import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	circle: {
		padding: 0,
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		height: '70vh',
		justifyContent: 'center',
		maxHeight: 680,
		maxWidth: 860,
		minHeight: 600,
		width: '70vw',
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'center',
		maxHeight: '100%',
		width: '100%',
		...CssSelectors.allChildren(
			{
				maxWidth: '97%',
			},
			'img'
		),
	},
	pointLeft: {
		height: 15,
		transform: 'rotateZ(90deg)',
		width: 20,
	},
	pointRight: {
		height: 15,
		transform: 'rotateZ(270deg)',
		width: 20,
	},
	tabNavigation: {
		alignItems: 'center',
		display: 'flex',
		gap: 6,
		justifyContent: 'center',
		paddingBottom: 6,
		paddingTop: 6,
	},
});
