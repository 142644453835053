import { titles } from '../../../styles/colors';
import { BreakPoints, CssSelectors, baseStyleSheet } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	advancedTagSearchButton: {
		fontSize: 12,
	},
	bold: {
		...baseStyleSheet.fontBold,
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		height: 28,
	},
	defaultSearchText: {
		marginLeft: 10,
	},
	label: {
		color: '#4A4A4A',
		fontSize: 12,
		fontWeight: 700,
		marginRight: 10,
	},
	plusMoreTags: {
		color: titles,
		fontSize: 12,
		marginRight: 10,
	},
	tagToken: {
		[BreakPoints.tabletCompactWidth]: {
			maxWidth: 150,
		},
	},
	tagTokenOpPrefix: {
		color: titles,
		fontSize: 12,
	},
	tokens: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		maxHeight: 62,
		overflowY: 'hidden',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
});
