import { css } from 'aphrodite';
import * as React from 'react';
import { animated, useSpring } from 'react-spring-legacy';
import { useLambda } from '../../../../../models/hooks/useLambda';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { DisclosureIcon } from '../../../../components/svgs/icons/DisclosureIcon';
import { brandPrimary } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	defaultCountToShow?: number;
	items: React.ReactNodeArray;
	itemHeightPx: number;
	showLoader?: boolean;
}

export const PeopleCollapsibleSection: React.FC<IProps> = props => {
	const { items = [], itemHeightPx: itemHeight, defaultCountToShow = 4, showLoader } = props;
	const [isExpanded, , setExpanded] = useLambda(false);

	const styleProps = useSpring({
		height: isExpanded ? `${items.length * itemHeight}px` : `${defaultCountToShow * itemHeight}px`,
	});

	if (items.length < defaultCountToShow) {
		return <div>{items}</div>;
	}

	return (
		<div>
			{showLoader ? (
				<div style={{ minHeight: `${defaultCountToShow * itemHeight}px` }}>
					<LoadingSpinner />
				</div>
			) : (
				<>
					<animated.div style={styleProps} className={css(styleSheet.expander)}>
						{items}
					</animated.div>
					{items.length > defaultCountToShow && (
						<button className={css(baseStyleSheet.brandLink, styleSheet.moreButton)} onClick={setExpanded(!isExpanded)}>
							<span className={css(styleSheet.chevron)}>
								<DisclosureIcon
									type='chevron'
									className={css(styleSheet.chevronIcon, isExpanded && styleSheet.chevronFlip)}
									fillColor={brandPrimary}
								/>
							</span>
							<span>
								{isExpanded
									? 'View less'
									: `View ${items.length > defaultCountToShow ? items.length - defaultCountToShow : ''} more`}
							</span>
						</button>
					)}
				</>
			)}
		</div>
	);
};
