import { StyleSheet } from 'aphrodite';
import { background, darkGrayFontColor, mention, navigation } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const platformTable = {
	accountLogin: {
		flexGrow: 1,
		margin: '0 10px',
		width: 200,
	},
	charLimit: {
		flexGrow: 1,
		margin: '0 10px',
		width: 120,
	},
	names: {
		flexGrow: 1,
		margin: 0,
		width: 160,
	},
	postLocation: {
		flexGrow: 1,
		margin: '0 10px',
		width: 180,
	},
};

export const styleSheet = StyleSheet.create({
	accountLogin: {
		...platformTable.accountLogin,
		alignItems: 'center',
		...CssSelectors.allChildren(
			{
				margin: 0,
			},
			'p'
		),
	},
	basicContainer: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		paddingBottom: 4,
		paddingRight: 8,
		paddingTop: 4,
		...CssSelectors.allChildren({
			color: 'black',
			display: 'flex',
			opacity: 1,
		}),
		...CssSelectors.allChildren(
			{
				paddingLeft: 4,
			},
			'div'
		),
	},
	cancelMargin: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 4,
			},
			'svg'
		),
	},
	charLimit: {
		...platformTable.charLimit,
	},
	checkboxContainer: {
		alignItems: 'center',
		background: '#f1f1f1',
		border: '1px solid #cccccc',
		borderRadius: 2,
		display: 'inline-flex',
		margin: 0,
		padding: 8,
		paddingBottom: 4,
		paddingTop: 4,
		...CssSelectors.allChildren({
			color: 'black',
			opacity: 1,
		}),
	},
	checkboxContainerDisabled: {
		background: '#fff',
	},
	checkboxContainerSelected: {
		background: mention,
		border: '1px solid #046cb6',
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		margin: 0,
		marginBottom: 16,
		marginTop: 4,
	},
	disconnected: {
		opacity: 0.6,
	},
	iconRow: {
		alignItems: 'center',
		display: 'flex',
		height: 16,
		marginLeft: 8,
		...CssSelectors.allChildren(
			{
				height: 16,
				padding: 4,
			},
			'img'
		),
	},
	iconRowBasic: {
		height: 20,
		marginLeft: 0,
		...CssSelectors.allChildren(
			{
				paddingLeft: 0,
			},
			'img'
		),
	},
	listContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	nameAdjustment: {
		fontSize: 14,
		marginLeft: 4,
		marginRight: 4,
	},
	names: {
		...platformTable.names,
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		margin: 0,
		maxWidth: 180,
		padding: '6px 6px 6px 6px',
	},
	popoverMargin: {
		margin: 2,
	},
	postLocation: {
		...platformTable.postLocation,
	},
	reconnectButton: {
		marginLeft: 16,
	},
	spacer: {
		background,
		height: 8,
		marginTop: 10,
	},
	tableHeaderElement: {
		color: navigation,
		fontSize: 12,
	},
	tableRowElement: {
		alignItems: 'center',
		color: darkGrayFontColor,
		fontSize: 14,
	},
	typeContainer: {
		...CssSelectors.allDescendants(
			{
				':not(:first-child)': {
					paddingTop: 6,
				},
			},
			'li'
		),
		marginTop: 10,
	},
});
