import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { EditAutomationTemplateItemBumpers } from '../EditAutomationTemplateItemBumpers';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const EditAutomationTemplateItemSeparator: React.FC<IProps> = props => {
	const { className, styles = [], children } = props;
	const onRenderLeftBumperContent = React.useCallback(() => {
		return (
			<div className={css(styleSheet.lineContainer)}>
				<div className={css(styleSheet.verticalLine)} />
			</div>
		);
	}, []);
	return (
		<EditAutomationTemplateItemBumpers
			className={`${css(...styles)} edit-automation-template-item-separator ${className || ''}`}
			onRenderLeftBumperContent={onRenderLeftBumperContent}
		>
			{children}
		</EditAutomationTemplateItemBumpers>
	);
};
