import { StyleSheet } from 'aphrodite';
import { GripperColumnStyle } from '../../../../admin/components/templates/IndustryTemplatesTable/styles';
import { brandPrimary, grayIconFill, mentionDark } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	addMediaContainer: {
		display: 'flex',
		marginBottom: 10,
		marginTop: 14,
		...CssSelectors.allChildren({
			paddingRight: 15,
		}),
	},
	contentContainers: {
		color: '#a7abad',
		fontSize: 14,
		marginRight: 20,
	},
	enlargeImage: {
		color: brandPrimary,
		display: 'flex',
		fontSize: 14,
		padding: '10px 0',
	},
	freeImage: {
		paddingLeft: 6,
		paddingRight: 6,
	},
	gripperCol: {
		...GripperColumnStyle,
		alignItems: 'center',
		display: 'flex',
		paddingLeft: 10,
		paddingRight: 10,
	},
	hide: {
		display: 'none',
	},
	iconButtons: {
		background: '#f9f9f9',
		border: `1px solid ${mentionDark}`,
		color: '#0D0F11',
		display: 'flex',
		fontSize: 16,
		marginBottom: 4,
		padding: 8,
	},
	imageContainer: {
		border: `1px solid ${mentionDark}`,
		borderRadius: 3,
		boxShadow: '0px 0px 12px #E8F3F9',
		boxSizing: 'border-box',
		display: 'flex',
		marginTop: 18,
	},
	imageDisplayArea: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	imageDisplayAreaClose: {
		margin: '10px 10px 0',
	},
	imageDisplayAreaImageContainer: {
		alignItems: 'center',
		color: grayIconFill,
		display: 'flex',
		overflow: 'hidden',
		width: '100%',
	},
	imageDisplayAreaInfo: {
		marginLeft: 20,
		overflow: 'hidden',
		width: '100%',
	},
	imagesContainer: {
		marginBottom: 20,
	},
	ownImage: {
		color: '#046CB6',
		fontSize: 14,
		marginBottom: 13,
		marginTop: 12,
	},
	sourceMention: {
		color: ' #A7ABAD',
		fontSize: 12,
		fontStyle: 'italic',
		marginRight: 10,
		textAlign: 'center',
	},
	upArrow: {
		height: 18,
		paddingRight: 8,
		paddingTop: 20,
		width: 18,
	},
});
