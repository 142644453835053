import * as React from 'react';
import { IImpersonationContext, IOperationResultNoValue } from '../../../../extViewmodels';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages } from '../../../../models/hooks/appStateHooks';
import { useGenerateKeywords } from '../../../../queries';
import { useMediaChooserContext } from '../context';

export const CONTENT_MINIMUM = 100;
export const KEYWORDS_LIMIT = 6;

export function useMediaChooserSuggestions({
	content,
	impersonationContext,
}: { content?: string; impersonationContext?: IImpersonationContext }) {
	const { setShowSuggestionsNoResults } = useMediaChooserContext();

	const [prevKeywords, setPrevKeywords] = React.useState<string[]>([]);
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('MediaChooserSuggestions');
	const {
		mutate: getKeywords,
		data: keywords,
		isLoading: keywordsLoading,
		isError: keywordsError,
	} = useGenerateKeywords({
		onError: (error: IOperationResultNoValue) => {
			setShowSuggestionsNoResults(true);
			errorMessages.pushApiError(error);
			logApiError('MutateGetKeywordsAsync-Error', error);
		},
	});

	React.useEffect(() => {
		if (keywords?.length > 0 || prevKeywords?.length > 0 || keywordsLoading) {
			setShowSuggestionsNoResults(false);
			return;
		}
		if (content && content.length > CONTENT_MINIMUM && !keywordsError) {
			getKeywords({ content, impersonationContext });
			setShowSuggestionsNoResults(false);
			return;
		}
		setShowSuggestionsNoResults(true);
	}, [
		content,
		getKeywords,
		keywords?.length,
		keywordsError,
		keywordsLoading,
		prevKeywords?.length,
		setShowSuggestionsNoResults,
		impersonationContext,
	]);

	const onReloadClicked = () => {
		const nextKeywords =
			prevKeywords?.length > 0 && prevKeywords?.length < KEYWORDS_LIMIT
				? prevKeywords.concat(keywords)
				: prevKeywords?.length >= KEYWORDS_LIMIT
					? []
					: keywords;
		getKeywords({
			content,
			prevKeywords: nextKeywords,
		});
		setPrevKeywords(nextKeywords);
	};

	return {
		keywords,
		onReloadClicked,
	} as const;
}
