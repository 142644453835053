import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { isScheduledOrPendingOrDraftOrNew } from '../../../../models/UiUtils';
import { useEditSocialMediaPostContext } from '../../../containers/socialMedia/EditSocialMediaPost/context';
import { SocialMediaPlatformTableType } from './models';
import { SocialMediaPlatformRow } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	connections?: Api.ISocialMediaConnection[];
	onSelectionChanged?(selectedTargets: Api.IPostTarget[]): void;
	source?: Api.SocialMediaPostReportViewModel | Api.SocialMediaPostViewModel;
	styles?: StyleDeclarationValue[];
	type: SocialMediaPlatformTableType;
}

// @ts-ignore
export const SocialMediaPlatformTable: React.FC<IProps> = observer(props => {
	const { className, styles, connections, onSelectionChanged, type, source } = props;
	const { postTargets } = useEditSocialMediaPostContext();
	const onSelectedChanged = React.useCallback(
		(selected: boolean, connection: Api.ISocialMediaConnection) => {
			const conns = postTargets ? [...postTargets] : [];

			if (selected && !conns.find(x => x?.provider === connection?.type && x?.pageId === connection?.postTargetId)) {
				conns.push({ pageId: connection.postTargetId, provider: connection.type, userId: connection.userId });
				onSelectionChanged?.(conns);
				return;
			}

			if (!selected) {
				onSelectionChanged?.(conns.filter(x => x?.pageId !== connection?.postTargetId) || []);
			}
		},
		[onSelectionChanged, postTargets]
	);

	// @ts-ignore
	const multipleConnectionsExist = isScheduledOrPendingOrDraftOrNew(source)
		? Api.SupportedSocialMediaTypes.map(x => {
				// @ts-ignore
				return connections?.filter(y => y?.type === x)?.length > 1;
			}).includes(true)
		: Api.SupportedSocialMediaTypes.map(x => {
				// @ts-ignore
				return postTargets?.filter(y => y?.provider === x)?.length > 1;
			}).includes(true);

	if (postTargets === null) {
		return;
	}

	return (
		<section className='social-media-reporting-table'>
			<article className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<p className={css(styleSheet.tableHeaderElement, styleSheet.names)}>Share on selected platform(s)</p>
				<p className={css(styleSheet.tableHeaderElement, styleSheet.accountLogin)}> Connected account(s)</p>
				<p className={css(styleSheet.tableHeaderElement, styleSheet.postLocation)}> Post location(s)</p>
			</article>
			{Api.SupportedSocialMediaTypes.map((socialType, j) => {
				return (
					<div className={css(styleSheet.typeContainer)} key={j}>
						{connections
							// @ts-ignore
							// @ts-ignore
							?.sort((u, v) => (u.postTargetId > v.postTargetId ? 1 : -1))
							?.sort((a, b) => (a.type === b.type ? 0 : a.type > b.type ? 1 : -1))
							?.filter(x => x?.type === socialType)
							?.map((connection, i) => {
								// sets the postTarget to the connection type if it exists
								// @ts-ignore
								const postTarget = postTargets.find(
									x =>
										x?.provider === connection?.type &&
										(x?.state?.lastConnectionState?.postTargetId === connection?.postTargetId ||
											x?.pageId === connection?.postTargetId)
								);
								// Identifies if the connection is selected
								const selected = postTarget ? true : false;

								return (
									<SocialMediaPlatformRow
										connection={connection}
										key={`${connection?.type}-${connection?.postTargetId}-${i}`}
										onSelectedChanged={onSelectedChanged}
										postSource={source}
										postTarget={postTarget}
										selected={selected}
										type={type}
									/>
								);
							})}
						{socialType && multipleConnectionsExist && connections?.find(x => x.type === socialType) && (
							<div className={css(styleSheet.spacer)} />
						)}
					</div>
				);
			})}
		</section>
	);
});
