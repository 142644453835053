import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const ComplianceCompleteIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`compliance-complete-icon ${className || ''}`} height={160} width={160}>
			<circle cx='133.5' cy='8.5' r='4.5' fill='#F1F1F1' />
			<circle cx='109.5' cy='170.5' r='4.5' fill='#F1F1F1' />
			<circle cx='4.5' cy='28.5' r='4.5' fill='#F1F1F1' />
			<path d='M36.31.158l145.182 43.657-35.024 116.797L1.285 116.955 36.31.158z' fill='#F1F1F1' />
			<path fill='#046CB6' d='M48 24.158h87v109H48z' />
			<path fill='#F9F9F9' d='M60 50.158h63v3H60zm0 10h63v3H60zm0 10h63v3H60zm0 10h63v3H60z' />
			<circle cx='136' cy='132.158' r='25' fill='#89C947' stroke='#DBF2C3' strokeWidth='2' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M133.815 144.969l-10.514-10.512 4.597-4.597 5.088 5.088 10.769-15.077 5.288 3.775-15.228 21.323z'
				fill='#fff'
			/>
		</SvgIcon>
	);
};
