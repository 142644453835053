import { useMemo } from 'react';
import { VmUtils } from '../../../../extViewmodels';
import { IPixabayImage, IPixabayImageSearchOptions } from '../../../../models/Pixabay';
import { usePixabayImageQueries } from '../../../../queries/Pixabay/usePixabayImageQueries';

function interleaveArrays(...args: IPixabayImage[][]) {
	const result: IPixabayImage[] = [];
	const maxLength = Math.max(...args.map(x => x?.length ?? 0));
	for (let i = 0; i < maxLength; i++) {
		args.forEach(images => {
			if (i < images?.length) {
				result.push(images[i]);
			}
		});
	}
	return VmUtils.dedupeById(result);
}

export function useSuggestedPixabayImages(
	searchTerms: string[] = [],
	options: IPixabayImageSearchOptions = { per_page: 25 }
) {
	const suggestionQueries = usePixabayImageQueries({
		querys: searchTerms.filter(Boolean),
		options: { per_page: options?.per_page || 25, ...(options || {}) },
	});
	const isLoading = suggestionQueries.some(x => x.isLoading);
	const suggestedImages = useMemo(() => {
		const resultsArray: IPixabayImage[][] = new Array(suggestionQueries.length);
		for (let index = 0; index < suggestionQueries.length; index++) {
			const query = suggestionQueries[index];
			// check if the query has data
			if (query.data && query.data.hits?.length <= 0) {
				continue;
			}
			resultsArray[index] = query.data?.hits ?? [];
		}
		return interleaveArrays(...resultsArray);
	}, [suggestionQueries]);

	return { suggestedImages, isLoading };
}
