import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import { useState } from 'react';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	hoverColor?: string;
}

export const ClipboardIcon: React.FC<IProps> = props => {
	const { className, fillColor, hoverColor } = props;
	const [hover, setHover] = useState(false);
	const onMouseEntered = () => setHover(true);
	const onMouseLeft = () => setHover(false);

	const stroke = (hover ? hoverColor : fillColor) || navigation;

	return (
		<SvgIcon
			className={className}
			onMouseEnter={onMouseEntered}
			onMouseLeave={onMouseLeft}
			width={15}
			height={19}
			viewBox='0 0 15 19'
		>
			<path
				fill={stroke}
				d='M5.83354 0H9.16646C9.70511 0 10.1877 0.155237 10.6178 0.465711C11.048 0.776185 11.3491 1.17643 11.5231 1.66646H12.4994C13.1895 1.66646 13.7787 1.91147 14.2668 2.39963C14.755 2.88778 15 3.47693 15 4.16708V15.8304C15 16.5206 14.7569 17.1097 14.2668 17.5979C13.7787 18.086 13.1895 18.331 12.4994 18.331H2.50062C1.81047 18.331 1.22132 18.086 0.733167 17.5979C0.245012 17.1097 0 16.5206 0 15.8304V4.16521C0 3.47506 0.245012 2.88591 0.733167 2.39776C1.22132 1.9096 1.81047 1.66459 2.50062 1.66459H3.47693C3.65087 1.17456 3.95199 0.774314 4.38217 0.46384C4.81234 0.155237 5.29489 0 5.83354 0ZM12.4994 3.33292H11.5231C11.3491 3.82294 11.048 4.22319 10.6178 4.53367C10.1877 4.84414 9.70511 4.99938 9.16646 4.99938H5.83354C5.29489 4.99938 4.81234 4.84414 4.38217 4.53367C3.95199 4.22319 3.65087 3.82294 3.47693 3.33292H2.50062C2.27057 3.33292 2.07419 3.41334 1.91147 3.57606C1.74875 3.73878 1.66646 3.93516 1.66646 4.16521V15.8286C1.66646 16.0586 1.74875 16.255 1.91147 16.4177C2.07419 16.5804 2.27057 16.6608 2.50062 16.6608H12.4975C12.7276 16.6608 12.9239 16.5786 13.0867 16.4177C13.2494 16.255 13.3317 16.0586 13.3317 15.8286V4.16521C13.3317 3.93516 13.2494 3.73878 13.0867 3.57606C12.9258 3.41334 12.7294 3.33292 12.4994 3.33292ZM9.16646 1.66646H5.83354C5.60349 1.66646 5.40711 1.74688 5.24439 1.91147C5.08167 2.07419 5.00125 2.27057 5.00125 2.50062C5.00125 2.73067 5.08354 2.92706 5.24439 3.08978C5.40711 3.25249 5.60349 3.33479 5.83354 3.33479H9.16646C9.39651 3.33479 9.59289 3.25249 9.75561 3.08978C9.91833 2.92706 9.99875 2.73067 9.99875 2.50062C9.99875 2.27057 9.91646 2.07419 9.75561 1.91147C9.59289 1.74688 9.39651 1.66646 9.16646 1.66646Z'
			/>
		</SvgIcon>
	);
};
