import { useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useLocation } from 'react-router';
import { RecipientsCount } from '../../../../admin/components/email/RecipientsCount';
import { DefaultBulkContactsRequest } from '../../../../extViewmodels/Utils';
import { ContactSortKey, ICreateCampaignRequest, ILocationState } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	ComposeEmailViewModel,
	ComposeFollowUpEmailViewModel,
	ComposeResourceEmailViewModel,
	ContactFilterCriteriaProperty,
	ContactViewModel,
	DefaultBulkSendExcludedTags,
	IContactFilterCriteria,
	IContactsFilterRequest,
	IOperationResultNoValue,
	VmUtils,
} from '../../../../viewmodels/AppViewModels';
import { Search } from '../../../containers/templates/Search';
import { success } from '../../../styles/colors';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { AdvancedFiltersFlyout } from '../../AdvancedFiltersFlyout/';
import { ISelectOption, Select } from '../../Select';
import { DefaultSelectBox, ISelectBoxOption } from '../../SelectBox';
import { HomeIcon } from '../../svgs/icons/HomeIcon';
import { EmailDuplicateContactSendCondition } from '../EmailDuplicateContactSendCondition';
import { EmailRecipientsList } from '../EmailRecipientsList';
import { RenewalEmailPolicySelector } from '../PolicySelector';
import { TagEmailTagList } from '../TagEmailTagList';
import { styleSheet } from './styles';

export type BulkDeliveryMethodOptions = 'all' | 'withEmails' | 'withTexts' | 'withAddress';
const BulkDeliveryOptionsMap: Record<BulkDeliveryMethodOptions, ISelectBoxOption<BulkDeliveryMethodOptions>> = {
	all: {
		title: 'Show All',
		value: 'all',
	},
	withEmails: {
		title: 'Have Emails',
		value: 'withEmails',
	},
	withTexts: {
		title: 'Have Texts',
		value: 'withTexts',
	},
	withAddress: {
		title: 'Have an Address',
		value: 'withAddress',
	},
};
export const BulkDeliveryMethodOptions: ISelectBoxOption<BulkDeliveryMethodOptions>[] = [
	BulkDeliveryOptionsMap.all,
	BulkDeliveryOptionsMap.withEmails,
	BulkDeliveryOptionsMap.withTexts,
	BulkDeliveryOptionsMap.withAddress,
];

export const bulkDeliveryMethodFromFilterCriteria = (filterCriterias: IContactFilterCriteria[]) => {
	const isAll =
		['ReceiveEmail', 'ReceiveCard', 'ReceiveText'].every(capableOfValue => {
			for (const filterCriteria of filterCriterias) {
				if (
					VmUtils.findFilterCritiera({
						criteria: filterCriteria,
						predicate: c => c.property === ContactFilterCriteriaProperty.CapableOf && c.value === capableOfValue,
					})
				) {
					return true;
				}
			}
			return false;
		}) ||
		// handle the case where we don't have anything that specifies ContactFilterCriteriaProperty.CapableOf
		filterCriterias.every(filterCriteria =>
			Boolean(
				VmUtils.findFilterCritiera({
					criteria: filterCriteria,
					predicate: c => c.property !== ContactFilterCriteriaProperty.CapableOf,
				})
			)
		);
	if (isAll) {
		return BulkDeliveryOptionsMap.all;
	}
	if (
		filterCriterias.find(
			filter => filter.property === ContactFilterCriteriaProperty.CapableOf && filter.value === 'ReceiveEmail'
		) != null
	) {
		return BulkDeliveryOptionsMap.withEmails;
	}
	if (
		filterCriterias.find(
			filter => filter.property === ContactFilterCriteriaProperty.CapableOf && filter.value === 'ReceiveText'
		) != null
	) {
		return BulkDeliveryOptionsMap.withTexts;
	}
	if (
		filterCriterias.find(
			filter => filter.property === ContactFilterCriteriaProperty.CapableOf && filter.value === 'ReceiveCard'
		) != null
	) {
		return BulkDeliveryOptionsMap.withAddress;
	}
	return null;
};
interface IProps {
	disableContactAdding?: boolean;
	disableContactEdits?: boolean;
	disableDropdown?: boolean;
	emailComposer: ComposeEmailViewModel | ComposeResourceEmailViewModel | ComposeFollowUpEmailViewModel;
	disableContactRemoval?: boolean;
	onContactSelected?(contact: ContactViewModel): void;
	onScrollerRef?(ref?: HTMLElement): void;
	isAutomation: boolean;
	resetOnMount?: boolean;
	showForInitialSelection?: boolean;
	styles?: StyleDeclarationValue[];
	onCtaClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
	ctaButtonText: string;
}

const getSelectedTags = (criteria: Api.IContactFilterCriteria[] = []) => {
	let selected: IContactFilterCriteria[] = [];

	criteria.forEach(c => {
		if (c.criteria) {
			selected = [...selected, ...getSelectedTags(c.criteria)];
		} else if (c.property === Api.ContactFilterCriteriaProperty.Tag) {
			selected.push(c);
		}
	});

	return selected;
};

function _TagEmailRecipientsList({
	disableContactAdding,
	disableContactEdits,
	disableContactRemoval,
	resetOnMount = true,
	emailComposer,
	disableDropdown,
	styles,
	onContactSelected,
	onScrollerRef,
	isAutomation = false,
	onCtaClick,
	ctaButtonText,
}: IProps) {
	const userSession = useUserSession();
	const location = useLocation<ILocationState<any, ICreateCampaignRequest>>();
	const { logApiError, logEvent } = useEventLogging('TagEmailRecipientList');
	const resourceVm = emailComposer as ComposeResourceEmailViewModel;
	const isForResource = resourceVm?.resourceSelector;
	const alreadyExistsAsCampaign = !!emailComposer?.campaign?.id;
	const sortedCriteria = VmUtils.sortContactFilterCriteria(
		emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
	);
	const hasUnsubscribeTagNot =
		sortedCriteria.searches.filter(x => x.value === 'Unsubscribe' && x.op === Api.FilterOperator.Not).length > 0;
	const hasEmailBouncedTagNot =
		sortedCriteria.searches.filter(x => x.value === 'Email Bounced' && x.op === Api.FilterOperator.Not).length > 0;
	const [showSearch, setShowSearch] = React.useState(false);
	const [showingAdvancedFiltersFlyout, setShowingAdvancedFiltersFlyout] = React.useState(false);
	const [selectedResourceSortOption, setSelectedResourceSortOption] = React.useState<ISelectOption<string>>();
	const selectedDeliveryMethodOption = bulkDeliveryMethodFromFilterCriteria(sortedCriteria.filters);
	const selectedTags = getSelectedTags(
		emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
	);
	const onlyDefaultTagsAreSelected =
		selectedTags.length === 0 ||
		(selectedTags.length === DefaultBulkSendExcludedTags.size &&
			Array.from(DefaultBulkSendExcludedTags).filter(d => !!selectedTags.find(s => s.value === d)).length ===
				DefaultBulkSendExcludedTags.size);

	const filterOptions = React.useMemo(() => {
		const contactFilterOptions: ISelectBoxOption<IContactFilterCriteria>[] = [
			{
				title: 'All contacts',
				value: {
					property: ContactFilterCriteriaProperty.All,
				},
			},
			{
				title: 'Contacts I own',
				value: {
					property: ContactFilterCriteriaProperty.OwnedBy,
					value: userSession?.user?.id,
				},
			},
			{
				title: 'My connections',
				value: {
					property: ContactFilterCriteriaProperty.Connections,
				},
			},
		];
		if (emailComposer.emailMessage?.options.sendEmailFrom === Api.SendEmailFrom.SelectedUser) {
			contactFilterOptions.push({
				title: 'Contacts they own',
				value: {
					property: ContactFilterCriteriaProperty.OwnedBy,
					value: emailComposer.emailMessage?.options.sendEmailFromUserId,
				},
			});
		}
		return contactFilterOptions;
	}, [
		userSession,
		emailComposer.emailMessage?.options.sendEmailFrom,
		emailComposer.emailMessage?.options.sendEmailFromUserId,
	]);
	const duplicateContactsFeatureFlag = !!userSession.account.preferences.showDuplicateContacts;
	const canShowShowingDropdown = React.useMemo(() => {
		const canShowSuggestion = emailComposer.isSuggestion && userSession?.account?.isAdmin;
		return (
			(canShowSuggestion || (!emailComposer?.reachOutInfo && !alreadyExistsAsCampaign)) &&
			(!isForResource ||
				resourceVm.resourceSelector === Api.ResourceSelectorId.HappyBirthday ||
				resourceVm.resourceSelector === Api.ResourceSelectorId.PolicyRenew) &&
			!emailComposer.isFollowUp
		);
	}, [
		emailComposer.isSuggestion,
		emailComposer?.reachOutInfo,
		emailComposer.isFollowUp,
		userSession?.account?.isAdmin,
		alreadyExistsAsCampaign,
		isForResource,
		resourceVm.resourceSelector,
	]);
	const selectedFilterOption = React.useMemo(() => {
		const criteria = sortedCriteria.filters.find(
			x =>
				x.property === ContactFilterCriteriaProperty.OwnedBy ||
				x.property === ContactFilterCriteriaProperty.All ||
				x.property === ContactFilterCriteriaProperty.Connections
		) || { property: ContactFilterCriteriaProperty.All };
		if (emailComposer?.emailMessage?.options?.sendEmailFrom === Api.SendEmailFrom.ContactOwner) {
			return filterOptions.find(x => x.value?.property === ContactFilterCriteriaProperty.All);
		}

		return filterOptions.find(x => {
			return (
				(criteria.property === ContactFilterCriteriaProperty.All && x.value?.property === criteria.property) ||
				(criteria.property === ContactFilterCriteriaProperty.OwnedBy &&
					x.value?.property === criteria.property &&
					x.value?.value === criteria.value) ||
				(criteria.property === ContactFilterCriteriaProperty.Connections && x.value?.property === criteria.property)
			);
		});
	}, [filterOptions, sortedCriteria, emailComposer?.emailMessage?.options?.sendEmailFrom]);
	const resourceSortOptions = React.useMemo(() => {
		let type = '';
		if (isForResource) {
			switch (resourceVm.resourceSelector) {
				case Api.ResourceSelectorId.PolicyRenew:
					type = 'renewal date';
					break;
				case Api.ResourceSelectorId.FinancialReview:
					type = 'review date';
					break;
				case Api.ResourceSelectorId.HappyBirthday:
					type = 'birthday';
					break;
				default:
					break;
			}
		}
		return [
			{
				dataContext: ContactSortKey.Handle,
				id: 'sort-option-first-name',
				text: 'Sort by first name A - Z',
				type: 'default',
			},
			{
				dataContext: ContactSortKey.HandleReverse,
				id: 'sort-option-last-name',
				text: 'Sort by last name A - Z',
				type: 'default',
			},
			{
				dataContext: ContactSortKey.KeyDate,
				id: 'sort-option-type',
				text: `Sort by ${type}`,
				type: 'default',
			},
		] as ISelectOption<ContactSortKey>[];
	}, [isForResource, resourceVm]);

	const getContacts = React.useCallback(
		(reset = false, loadApproximation = false) =>
			() => {
				const resourceSelector = (emailComposer as ComposeResourceEmailViewModel)?.resourceSelector ?? null;

				let makeRequest = true;

				if (!resourceSelector) {
					if (emailComposer.emailMessage.contactsToAdd.length > 0 && onlyDefaultTagsAreSelected) {
						// if there are individual contacts selected and no tags have been selected
						// do not make the getContacts request.
						makeRequest = false;
					} else if (!emailComposer?.emailMessage?.contactsFilterRequest && !emailComposer?.isFollowUp) {
						makeRequest = false;
					}
				}
				if (loadApproximation) {
					const approxPromise = isForResource
						? resourceVm.getEmailApproximationWithResource()
						: // If existing campaign and  only default tags, pass default filter else use normal approx.
							alreadyExistsAsCampaign &&
								onlyDefaultTagsAreSelected &&
								emailComposer?.emailMessage?.contactsFilterRequest
							? emailComposer.emailMessage.getEmailApproximation(DefaultBulkContactsRequest)
							: emailComposer.emailMessage.getEmailApproximation();
					approxPromise?.catch((error: IOperationResultNoValue) => {
						logApiError('HasEmailApproximationLoad-Error', error);
					});
				}
				// if there are individual contacts selected do not make the getContacts request if there are no tags selected.
				if (makeRequest) {
					if (reset) {
						emailComposer?.resetRecipientsResultsList();
					}
					const promise = emailComposer?.getNextBatchOfRecipients();
					if (promise) {
						logEvent('ContactsLoad');
						promise.catch((error: IOperationResultNoValue) => {
							logApiError('ContactsLoad-Error', error);
						});
					}
					return promise;
				}
			},
		[
			alreadyExistsAsCampaign,
			emailComposer,
			isForResource,
			logApiError,
			logEvent,
			onlyDefaultTagsAreSelected,
			resourceVm,
		]
	);
	const handleDuplicateContactsToggleChange = () => {
		emailComposer.emailMessage.contactsFilterRequest.groupByDuplicate =
			!emailComposer.emailMessage.contactsFilterRequest.groupByDuplicate;

		/** Below's taken from the getContacts logic because calling getContacts(true, true) here often ends up doing nothing
		 * because you can't control makeRequest from the outside.
		 **/
		const approxPromise = isForResource
			? resourceVm.getEmailApproximationWithResource()
			: // If existing campaign and  only default tags, pass default filter else use normal approx.
				alreadyExistsAsCampaign && onlyDefaultTagsAreSelected && emailComposer?.emailMessage?.contactsFilterRequest
				? emailComposer.emailMessage.getEmailApproximation(DefaultBulkContactsRequest)
				: emailComposer.emailMessage.getEmailApproximation();
		approxPromise?.catch((error: IOperationResultNoValue) => {
			logApiError('HasEmailApproximationLoad-Error', error);
		});
		emailComposer?.resetRecipientsResultsList();
		const promise = emailComposer?.getNextBatchOfRecipients();
		if (promise) {
			logEvent('ContactsLoad');
			promise.catch((error: IOperationResultNoValue) => {
				logApiError('ContactsLoad-Error', error);
			});
		}
		return promise;
	};
	const onOwnershipTypeFilterOptionChanged = React.useCallback(
		(selectedOption: ISelectBoxOption<IContactFilterCriteria>) => {
			// do not want to change filter if the campaign has already been created.
			if (emailComposer?.campaign?.id) {
				return;
			}
			emailComposer.userSelectedFilterOption = selectedOption; // preserve the user's selection in case of customizing per contact
			const sorted = VmUtils.sortContactFilterCriteria(
				emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
			);
			const filterRequest: IContactsFilterRequest = {
				criteria: VmUtils.concatContactFilterCriteria(
					[
						...(sorted.filters || []).filter(
							x => filterOptions.map(opt => opt.value?.property).indexOf(x.property) < 0
						),
						...sorted.searches,
					],
					selectedOption.value ? [selectedOption.value] : []
				),
			};
			if (emailComposer?.emailMessage?.contactsFilterRequest) {
				emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
			}
			getContacts(true, true)();
		},
		[emailComposer, filterOptions, getContacts]
	);
	const onDeliveryMethodOptionChanged = (selectedOption: ISelectBoxOption<BulkDeliveryMethodOptions>) => {
		const sorted = VmUtils.sortContactFilterCriteria(
			emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
		);
		const filterCriteriaToBeAdded: Record<string, IContactFilterCriteria> = {
			all: {
				criteria: [
					{
						property: ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveEmail',
					},
					{
						property: ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveCard',
					},
					{
						property: ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveText',
					},
				],
				op: Api.FilterOperator.Or,
			},
			withEmails: {
				property: ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveEmail',
			},
			withTexts: {
				property: ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveText',
			},
			withAddress: {
				property: ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveCard',
			},
		};
		const filterRequest: IContactsFilterRequest = {
			criteria: VmUtils.concatContactFilterCriteria(
				[
					...(sorted.filters || []).filter(
						x => !VmUtils.areAllCriteriaOfProperty({ criteria: x, property: ContactFilterCriteriaProperty.CapableOf })
					),
					...sorted.searches,
				],
				selectedOption.value ? [filterCriteriaToBeAdded[selectedOption.value]] : []
			),
		};
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
		}
		getContacts(true, true)();
	};
	const onAdvancedFiltersFlyoutSave = (filterCriteria: IContactFilterCriteria[], householdGrouping?: boolean) => {
		const nextFilterRequest: IContactsFilterRequest = {
			criteria: filterCriteria,
		};
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = nextFilterRequest;
		}
		if (householdGrouping !== null) {
			emailComposer.emailMessage.groupByHousehold = householdGrouping;
		}
		getContacts(true, true)();
		setShowingAdvancedFiltersFlyout(false);
	};
	const onAdvancedFiltersFlyoutCancel = () => {
		setShowingAdvancedFiltersFlyout(false);
	};
	const onSearchesChanged = (searches: IContactFilterCriteria[]) => {
		const sorted = VmUtils.sortContactFilterCriteria(
			(emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest || {}).criteria
		);
		const nextFilterRequest: IContactsFilterRequest = {
			...(emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest || {}),
			criteria: sorted.filters.concat(searches),
		};

		emailComposer?.resetRecipientsResultsList();
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = nextFilterRequest;
		}
		getContacts(true, true)();
	};
	const onResourceSortOptionClick = (_selectedResourceSortOption: ISelectOption<ContactSortKey>) => {
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest = {
				...emailComposer.emailMessage.contactsFilterRequest,
				sortAscending: true,
				sortProperty: _selectedResourceSortOption.dataContext as any,
			};
		}
		getContacts(true, true)();
		setSelectedResourceSortOption(_selectedResourceSortOption);
	};
	React.useEffect(() => {
		if (disableDropdown && emailComposer?.emailMessage?.options?.sendEmailFrom === Api.SendEmailFrom.ContactOwner) {
			onOwnershipTypeFilterOptionChanged(
				filterOptions.find(
					option => option.value?.property === ContactFilterCriteriaProperty.All
				) as ISelectBoxOption<Api.IContactFilterCriteria>
			);
		}
	}, [
		disableDropdown,
		emailComposer?.emailMessage?.options?.sendEmailFrom,
		filterOptions,
		onOwnershipTypeFilterOptionChanged,
	]);

	// effect for what used to be setDefaultSelectedOption() on mount
	React.useEffect(() => {
		if (!emailComposer?.allowSenderSelection) {
			return;
		}
		const ownedByFilter = emailComposer.emailMessage.contactsFilterRequest?.contactFilterRequest?.criteria?.filter(
			x => x.property === ContactFilterCriteriaProperty.OwnedBy
		);

		let defaultSelectedOption: ISelectBoxOption<IContactFilterCriteria>;
		// If OwnedBy has been explicitly set, do not augment the filter at all.
		// There are a few future scenarios where this early return could be a problem:
		// - We ever have a scenario that we'd allow providing or setting emailComposer.userSelectedFilterOption
		// - If we show the Showing filter/dropdown, we wouldn't have a filter option for the specified user.
		if (ownedByFilter !== null) {
			return;
		}
		// If the user has already changed the filter, use that value over the defaults
		if (emailComposer?.userSelectedFilterOption) {
			defaultSelectedOption = emailComposer?.userSelectedFilterOption;
		} else if (emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.SelectedUser)) {
			defaultSelectedOption = filterOptions.find(
				x =>
					x.value?.property === ContactFilterCriteriaProperty.OwnedBy &&
					x.value?.value === emailComposer?.emailMessage?.options?.sendEmailFromUserId
			);
		} else {
			// emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.ContactOwner) or otherwise

			defaultSelectedOption = filterOptions.find(x => x.value?.property === ContactFilterCriteriaProperty.All);
		}
		const sorted = VmUtils.sortContactFilterCriteria(
			emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
		);
		const filterRequest: IContactsFilterRequest = {
			criteria: VmUtils.concatContactFilterCriteria(
				[
					...(sorted.filters || []).filter(x => x.property !== ContactFilterCriteriaProperty.OwnedBy),
					...sorted.searches,
				],
				[defaultSelectedOption?.value].filter(Boolean) as Api.IContactFilterCriteria[]
			),
		};

		if (isForResource) {
			filterRequest.criteria = VmUtils.concatContactFilterCriteria(filterRequest.criteria, [
				{
					criteria: [
						{
							property: ContactFilterCriteriaProperty.CapableOf,
							value: 'ReceiveEmail',
						},
						{
							property: ContactFilterCriteriaProperty.CapableOf,
							value: 'ReceiveCard',
						},
						{
							property: ContactFilterCriteriaProperty.CapableOf,
							value: 'ReceiveText',
						},
					],
					op: Api.FilterOperator.Or,
				},
			]);
		}

		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
		}
		getContacts(resetOnMount, true)();
	}, [
		emailComposer,
		filterOptions,
		getContacts,
		isForResource,
		resetOnMount,
		userSession.account.preferences?.showAllContactsByDefault,
	]);

	React.useEffect(() => {
		if (
			!showSearch &&
			!!emailComposer &&
			!isForResource &&
			!emailComposer.isFollowUp &&
			!emailComposer.emailMessage?.hasUserSelectedContactFilterSearchCriteria &&
			!emailComposer.campaign?.id &&
			emailComposer.emailMessage.contactsToAdd?.length === 0
		) {
			logEvent('ShowGlobalSearchPanel');
			// reset excluded
			emailComposer.emailMessage.contactsToOmit.clear();
			setShowSearch(true);
		}
	}, [
		showSearch,
		emailComposer,
		isForResource,
		emailComposer.isFollowUp,
		emailComposer.emailMessage?.hasUserSelectedContactFilterSearchCriteria,
		emailComposer.campaign?.id,
		emailComposer.emailMessage.contactsToAdd,
		logEvent,
	]);
	function renderContactCount() {
		const onlyIndividualContactsSelected =
			emailComposer.emailMessage.contactsToAdd.length > 0 &&
			onlyDefaultTagsAreSelected &&
			location.state?.model?.type === 'Contacts';
		const approximation = isForResource
			? resourceVm?.approximation
			: emailComposer.emailMessage?.contactEmailApproximation;
		const sortOption = selectedResourceSortOption || resourceSortOptions[0];
		const followUpCount =
			emailComposer.emailMessage?.options?.followUp?.excludeContactIds?.length > 0 &&
			emailComposer.followUpCount > 0 &&
			emailComposer.campaign?.status !== Api.EmailSendStatus.Queued
				? emailComposer.followUpCount - emailComposer.emailMessage?.options?.followUp?.excludeContactIds?.length
				: emailComposer.followUpCount ?? 0;
		return (
			<div className={css(styleSheet.headerCount, styleSheet.headerCountPolicies)}>
				<RecipientsCount
					className={css(
						styleSheet.headerCountNumbers,
						(approximation?.total || 0) <= 0 && !emailComposer.followUpCount && styleSheet.headerCountNumbersImportant
					)}
					approximation={onlyIndividualContactsSelected || emailComposer?.isFollowUp ? null : approximation}
					followUpCount={emailComposer.emailMessage?.options?.followUp ? followUpCount : undefined}
					hasUnsubscribeTagNot={hasUnsubscribeTagNot}
					hasEmailBouncedTagNot={hasEmailBouncedTagNot}
					loading={isForResource ? resourceVm.isApproximating : emailComposer.emailMessage.isApproximating}
					fallbackCount={
						onlyIndividualContactsSelected
							? emailComposer.emailMessage.contactsToAdd.length
							: emailComposer.estimatedRecipientsTotal
					}
					styles={[isForResource && styleSheet.recipientCountWithSort]}
				/>
				{isForResource && (
					<div>
						<Select
							onOptionClick={onResourceSortOptionClick}
							options={resourceSortOptions}
							selectedOption={sortOption}
							selectedOptionTitle={<span className={css(styleSheet.sortText)}>{sortOption.text}</span>}
							styles={[styleSheet.sort]}
							triggerStyles={[styleSheet.sortTrigger]}
						/>
					</div>
				)}
			</div>
		);
	}
	const renderRecipientsTagHeader = () => {
		const isHouseholdOptionEnabled =
			userSession.account.features?.households?.enabled &&
			!(isForResource && resourceVm.householdOffForResourceSelector) &&
			!(emailComposer.emailMessage.contactsToAdd.length > 0 && onlyDefaultTagsAreSelected) &&
			!isAutomation;

		const type = isForResource
			? resourceVm.resourceSelector === Api.ResourceSelectorId.PolicyRenew
				? 'Renewals'
				: resourceVm.resourceSelector === Api.ResourceSelectorId.FinancialReview
					? 'Reviews'
					: ''
			: '';

		const title =
			isForResource &&
			(resourceVm.resourceSelector === Api.ResourceSelectorId.PolicyRenew ||
				resourceVm.resourceSelector === Api.ResourceSelectorId.FinancialReview)
				? `${moment()
						.set('month', parseInt(resourceVm.qualifier, 10) - 1)
						.format('MMMM')} ${type}`
				: 'Recipients';
		return (
			<div className={css(styleSheet.header)}>
				<div className={css(styleSheet.headerTitle)}>
					<div>{title}</div>
					{!emailComposer.isFollowUp && emailComposer.allowContactFilterTagSearchChanges ? (
						<>
							<button className={css(baseStyleSheet.brandLink)} onClick={() => setShowingAdvancedFiltersFlyout(true)}>
								<div>Advanced Filters</div>
							</button>
							<AdvancedFiltersFlyout
								initialFilterCriteria={
									emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria || []
								}
								onSave={onAdvancedFiltersFlyoutSave}
								isOpen={showingAdvancedFiltersFlyout}
								initialHouseholdGroupingOn={emailComposer.emailMessage.groupByHousehold}
								onDismissFlyout={onAdvancedFiltersFlyoutCancel}
								showHousehold={isHouseholdOptionEnabled}
								showOwnershipFilters
								disableOwnershipFilters={
									emailComposer?.emailMessage?.options?.sendEmailFrom === Api.SendEmailFrom.ContactOwner
								}
							/>
						</>
					) : null}
				</div>
				<div>
					{(emailComposer as ComposeResourceEmailViewModel).resourceSelector === Api.ResourceSelectorId.PolicyRenew ? (
						<RenewalEmailPolicySelector />
					) : null}
				</div>
				{!emailComposer.isFollowUp ? (
					<div>
						<TagEmailTagList
							emailComposer={emailComposer as ComposeEmailViewModel}
							onSearchesChanged={onSearchesChanged}
							readOnly={false}
						/>
					</div>
				) : null}
				{emailComposer.emailMessage.groupByHousehold ? (
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.groupingLabel)}>
						<HomeIcon stroke={success} width={12} height={14} />
						<span>Grouping recipients by households</span>
					</div>
				) : null}
				<div className={css(baseStyleSheet.horizontalStack, baseStyleSheet.flex)}>
					{canShowShowingDropdown ? (
						<div className={css(baseStyleSheet.verticalStack, styleSheet.headerOptions)}>
							<div>Showing:</div>
							<DefaultSelectBox
								disabled={
									disableDropdown || (emailComposer.emailMessage.contactsToAdd.length > 0 && onlyDefaultTagsAreSelected)
								}
								contentClassName={css(styleSheet.showingContentClassName)}
								menuStyles={[styleSheet.filterDropdownMenu]}
								onSelectionChanged={onOwnershipTypeFilterOptionChanged}
								options={filterOptions}
								selectedOption={selectedFilterOption}
								triggerStyles={[styleSheet.filterDropdownTrigger]}
							/>
						</div>
					) : null}
					{isForResource ? (
						<div className={css(baseStyleSheet.verticalStack, styleSheet.headerOptions)}>
							<div>Filter by available info</div>
							<DefaultSelectBox
								menuStyles={[styleSheet.filterDropdownMenu]}
								contentClassName={css(styleSheet.filterDropdownContent)}
								onSelectionChanged={onDeliveryMethodOptionChanged}
								options={BulkDeliveryMethodOptions}
								selectedOption={selectedDeliveryMethodOption}
								triggerStyles={[styleSheet.filterDropdownTrigger]}
							/>
						</div>
					) : null}
				</div>
				{renderContactCount()}
			</div>
		);
	};
	const renderRecipientsSearch = () => {
		return (
			<Search
				onNextClick={explicitelyPreventContactFilterRequest => {
					if (explicitelyPreventContactFilterRequest) {
						emailComposer?.setAllowContactFilterTagSearchChangesOverride(false);
					}
					onApplySearchCriteria();
				}}
			/>
		);
	};

	const onApplySearchCriteria = () => {
		if (emailComposer.emailMessage?.hasUserSelectedContactFilterSearchCriteria) {
			emailComposer?.reloadRecipientsList();
		} else {
			emailComposer?.resetRecipientsResultsList();
		}
		setShowSearch(false);
	};

	if (showSearch) {
		return renderRecipientsSearch();
	}

	return (
		<>
			<div className={`${css(styleSheet.container, ...(styles || []))} tag-email-recipients-list`}>
				{renderRecipientsTagHeader()}
				<EmailRecipientsList
					allowNoContactsMessaging={selectedFilterOption?.title === filterOptions[2]?.title}
					bulkEmailComposer={emailComposer}
					className={css(styleSheet.list)}
					disableContactAdding={emailComposer.isFollowUp ? true : disableContactAdding}
					disableContactEdits={emailComposer.isFollowUp ? true : disableContactEdits}
					disableContactRemoval={disableContactRemoval}
					onContactSelected={onContactSelected}
					onScrollerRef={onScrollerRef}
					onScrollToBottom={getContacts(
						false,
						emailComposer?.emailMessage &&
							!emailComposer.emailMessage.isApproximating &&
							!emailComposer.emailMessage.contactEmailApproximation
					)}
				/>
			</div>
			<div className={css(bs.flex, bs.flexCol, bs.gap2, bs.pt2)}>
				{!isForResource && emailComposer?.emailMessage?.contactsFilterRequest && duplicateContactsFeatureFlag ? (
					<div>
						<EmailDuplicateContactSendCondition
							filterRequest={emailComposer.emailMessage.bulkContactsRequest}
							isSelected={emailComposer.emailMessage.contactsFilterRequest.groupByDuplicate ?? true}
							onChange={handleDuplicateContactsToggleChange}
						/>
					</div>
				) : null}
				<button
					className={css(bs.ctaButton, bs.wFull)}
					onClick={onCtaClick}
					disabled={emailComposer?.recipients?.length <= 0}
				>
					<span>{ctaButtonText}</span>
				</button>
			</div>
		</>
	);
}

export const TagEmailRecipientsList = observer(_TagEmailRecipientsList);
