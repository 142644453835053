import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { animated, useSpring } from 'react-spring-legacy';
import useMeasure from 'react-use-measure';

interface IProps {
	children: React.ReactNode;
	isCollapsed?: boolean;
}

export const Collapsible: React.FC<IProps> = ({ children, isCollapsed = true }) => {
	const [contentRef, { height }] = useMeasure();
	const styleProps = useSpring({
		height: isCollapsed ? 0 : height,
	});

	return (
		<animated.div
			style={{ ...styleProps, overflow: styleProps.height.interpolate(h => (h === height ? 'unset' : 'hidden')) }}
		>
			<div ref={contentRef} className={css(styleSheet.collapsibleInner)}>
				{children}
			</div>
		</animated.div>
	);
};
