import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../styles/styles';
import { Modal } from '../Modal';
import { SimpleAutoCompleteSearchField } from '../autocomplete/SimpleAutoCompleteSearchField';
import { styleSheet } from './styles';

interface IProps {
	onClose: () => void;
	onUserSelected: (user: Api.IUser) => void;
	bodyText: string;
}

export const SelectAnEmployeeExternalEmailBody =
	'Since you are the admin of this Levitate account, you can select another employee so this email will come from their email instead. They must be a Levitate user.';
export const SelectAnEmployeeInternalEmailBody =
	'Since you are the admin of this Levitate account, you can select another employee to send this email to. They must be a Levitate user.';
export const SelectAnEmployeeCardBody =
	'Since you are the admin of this Levitate account, you can select another employee so this card will come from them instead. They must be a Levitate user.';

export function SelectAnEmployeeModal(props: IProps) {
	const [tempSelectedEmployee, setTempSelectedEmployee] = React.useState<Api.IUser>();

	const onSaveSelectAnEmployee = () => {
		props.onUserSelected(tempSelectedEmployee);
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => {
				props.onClose();
			}}
			useDefaultHeader={true}
		>
			<div className={css(styleSheet.selectAnEmployeeModal)}>
				<div className={css(styleSheet.selectAnEmployeeTitle, baseStyleSheet.truncateText)}>Select an Employee</div>
				<div className={css(styleSheet.modalBodyText)}>{props.bodyText}</div>
				<SimpleAutoCompleteSearchField
					onClear={() => setTempSelectedEmployee(null)}
					onItemSelected={ev => {
						if (ev.target) {
							ev.target.setSearchQuery(Api.VmUtils.getDisplayName(ev.selection as Api.IUser));
						}
						setTempSelectedEmployee(ev.selection as Api.IUser);
					}}
					onKeyDown={ev => {
						if (ev.sourceEvent && ev.sourceEvent.keyCode !== 13) {
							setTempSelectedEmployee(null);
						}
					}}
					placeholder='Search employee'
					style={styleSheet.searchEmployeeBox}
					type={Api.ResourceAutoCompleteViewModelType.User}
					onCreateAutoCompleteViewModel={(_, vm) => {
						vm.addParam({ includeDeactivated: false });
						return vm;
					}}
				/>
				<button
					className={css(baseStyleSheet.ctaButton, styleSheet.selectAnEmployeeCTA)}
					style={{ marginBottom: 5 }}
					onClick={onSaveSelectAnEmployee}
					disabled={!tempSelectedEmployee}
				>
					<span>Save</span>
				</button>
			</div>
		</Modal>
	);
}
