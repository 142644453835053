import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const SpreadsheetIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`spreadsheet-check-icon ${className || ''}`} height={97.455} width={80}>
			<defs>
				<rect id='spreadsheet-b' width='80' height='97.455' rx='3' />
				<filter id='spreadsheet-a' width='108.8%' height='107.2%' x='-4.4%' y='-2.6%' filterUnits='objectBoundingBox'>
					<feOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1' />
					<feColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' />
				</filter>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(2 1)'>
				<use fill='#000' filter='url(#spreadsheet-a)' xlinkHref='#spreadsheet-b' />
				<use fill='#FFF' xlinkHref='#spreadsheet-b' />
				<rect width='20.364' height='76.364' x='29.818' y='10.909' fill='#CAE0EC' />
				<path
					stroke='#00AAE8'
					strokeLinecap='square'
					d='M10.5454545 17.0909091L69.0909091 17.0909091M10.5454545 11.2727273L69.0909091 11.2727273M29.4545455 11.2727273L29.4545455 86.9090909M9.81818182 11.2727273L9.81818182 86.9090909M49.8181818 11.2727273L49.8181818 84.7272727M69.4545455 11.2727273L69.4545455 86.9090909M10.5454545 22.9090909L69.0909091 22.9090909M10.5454545 28.7272727L69.0909091 28.7272727M10.5454545 34.5454545L69.0909091 34.5454545M10.5454545 34.5454545L69.0909091 34.5454545M10.5454545 40.3636364L69.0909091 40.3636364M10.5454545 46.1818182L69.0909091 46.1818182M10.5454545 52L69.0909091 52M10.5454545 57.8181818L69.0909091 57.8181818M10.5454545 63.6363636L69.0909091 63.6363636M10.5454545 69.4545455L69.0909091 69.4545455M10.5454545 75.2727273L69.0909091 75.2727273M10.5454545 81.0909091L69.0909091 81.0909091M10.5454545 86.9090909L69.0909091 86.9090909'
				/>
				<rect
					width='60.364'
					height='6.545'
					x='9.455'
					y='22.545'
					fill='#00AAE8'
					opacity='.518'
					style={{ mixBlendMode: 'multiply' }}
				/>
			</g>
		</SvgIcon>
	);
};
