import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { postNotification } from '../../../../models/LocalNotifications';
import { createRichContentEditorStateWithText } from '../../../../models/UiUtils';
import {
	useErrorMessages,
	useFullscreenModal,
	useToaster,
	useUserSession,
} from '../../../../models/hooks/appStateHooks';
import {
	invalidateBlogPostDrafts,
	useApproveBlogPostMutation,
	useBlogUpdateMutation,
	useDeleteBlogPostMutation,
	useRejectBlogPostMutation,
} from '../../../../queries';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '../../../components/ConfirmationDialog';
import {
	ApprovalStatus,
	ScheduleBy,
	ScheduleDate,
} from '../../../components/campaigns/CampaignDetailsSummary/presentation';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { green3 } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { BlogPostEditor } from './BlogPostEditor';
import { BlogPostEditorContext, REDUCER_ACTION_KEYS, useBlogPostEditingRequest, validateContent } from './hooks';
import { IBlogPostComponentProps } from './models';
import { BlogPostEditorLayout } from './presentation';
import { styleSheet } from './styles';

interface IEditBlogPostProps extends IBlogPostComponentProps, IImpersonationContextComponentProps {
	/** return true to indicate the action is handled externally */
	onPostApproved?(blogPost: Api.IBlogPost): boolean;
	/** return true to indicate the action is handled externally */
	onPostRejected?(blogPost: Api.IBlogPost): boolean;
}

function EditBlogPostBase({
	impersonationContext,
	onPostApproved,
	onPostRejected,
	onScheduleClicked,
}: IEditBlogPostProps) {
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const toaster = useToaster();

	const {
		postEditorContext,
		blogPost: post,
		setBlogPost,
	} = useBlogPostEditingRequest({
		impersonationContext,
	});
	const { state, dispatch } = postEditorContext;

	const approveMutation = useApproveBlogPostMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: val => {
			postNotification({
				info: val,
				topic: Topics.EDIT_BLOG_POST,
			});

			if (onPostApproved?.(val)) {
				return;
			}
			fullscreenModal.history.push({
				pathname: '/blog/post/success',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	const rejectMutation = useRejectBlogPostMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: val => {
			postNotification({
				info: val,
				topic: Topics.EDIT_BLOG_POST,
			});

			if (onPostRejected?.(val)) {
				return;
			}
			fullscreenModal.dismissModal();
		},
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	const updateBlogPostMutation = useBlogUpdateMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: blog => {
			setBlogPost(blog);
			toaster.push({
				message: 'Blog post saved successfully',
				type: 'successMessage',
			});
			if (!impersonationContext?.isValid) {
				invalidateBlogPostDrafts({ userId: userSession.user.id });
			}
			postNotification({
				info: blog,
				topic: Topics.EDIT_BLOG_POST,
			});
		},
	});

	const deleteBlogPostMutation = useDeleteBlogPostMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: val => {
			postNotification({
				info: val,
				topic: Topics.EDIT_BLOG_POST,
			});
			fullscreenModal.dismissModal();
		},
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	React.useEffect(() => {
		if (post) {
			const _content = post.content.document.replace(/<\/?lev-content>/gim, '');

			dispatch({
				type: REDUCER_ACTION_KEYS.SET_STATE,
				payload: {
					title: post.title,
					content: createRichContentEditorStateWithText(_content),
					blogId: post.id,
					selectedImage: post.mainImage,
					blogGenerated: true,
					showingAiContentGenWizard: false,
					showScheduleAction: post.status === Api.BlogStatus.Draft,
					sendFromUser: post.creator as Api.IUser,
					status: post.status,
					scheduledSendDate: new Date(post.scheduledSendDate) || new Date(),
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let statusDescription = '';
	// convert to switch statement based on stat.status
	switch (state.status) {
		case Api.BlogStatus.Pending:
			statusDescription = 'Pending Approval';
			break;
		case Api.BlogStatus.Completed:
			statusDescription = 'Published';
			break;
		default:
			statusDescription = 'Draft';
			break;
	}

	const handleScheduleClick = () => {
		const errors = validateContent(state);
		if (errors.length !== 0) {
			errorMessages.push({
				messages: errors,
			});
			return;
		}

		const postToSave: Api.IBlogPost = {
			...post,
			content: state.content.getRawRichTextContent(),
			title: state.title,
			mainImage: state.selectedImage,
			id: state.blogId,
			templateId: post?.templateId,
			sendFromUserId: state.sendFromUser?.id,
		};

		setBlogPost(postToSave); // context
		if (onScheduleClicked?.(postToSave)) {
			return;
		}
		fullscreenModal.history.push({
			pathname: '/blog/post/schedule',
		});
	};

	const handleApproveBlogPost = () => {
		approveMutation.mutate({ id: state.blogId });
	};

	const handleRejectBlogPost = () => {
		rejectMutation.mutate({ id: state.blogId });
	};

	const hanldleSaveClick = () => {
		const postToSave: Api.IBlogPost = {
			...post,
			content: state.content.getRawRichTextContent(),
			title: state.title,
			mainImage: state.selectedImage,
			id: state.blogId,
			templateId: post?.templateId,
			sendFromUserId: state.sendFromUser?.id,
		};

		updateBlogPostMutation.mutate({ post: postToSave });
	};

	const handleDeleteClick = () => {
		dispatch({ type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST, payload: { value: true } });
	};
	const onDeleteConfirmationRequestClose = React.useCallback(
		(result?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
			if (result?.isDestructive && !cancel) {
				dispatch({ type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST, payload: { value: false } });
				deleteBlogPostMutation.mutate({ id: state.blogId });
				return;
			}
			dispatch({ type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST, payload: { value: false } });
		},
		[deleteBlogPostMutation, dispatch, state.blogId]
	);

	return (
		<BlogPostEditorContext.Provider value={postEditorContext}>
			<BlogPostEditorLayout
				status={state.status}
				header='Edit Blog'
				content={<BlogPostEditor />}
				subHeader={
					<>
						<h1>Edit Blog</h1>
						<menu className={css(styleSheet.menu)}>
							{impersonationContext?.isValid ? null : (
								<li>
									<button type='button' className={css(bs.ctaButtonReverseSmall)} onClick={hanldleSaveClick}>
										Save Blog
									</button>
								</li>
							)}
							<li>
								<button type='button' className={css(bs.ctaButtonDestructiveSmall)} onClick={handleDeleteClick}>
									Delete
								</button>
							</li>
						</menu>
					</>
				}
				sidebar={
					<>
						{state.showScheduleAction ? (
							<>
								<h2 className={css(styleSheet.question)}>What would you like to do with this blog?</h2>
								<button type='button' className={css(bs.ctaButton)} onClick={handleScheduleClick}>
									Schedule
								</button>
							</>
						) : null}
						{post?.actor ? <ScheduleBy name={`${post.actor!.name}`} /> : null}
						{state.status !== Api.BlogStatus.Draft ? (
							<ScheduleDate
								scheduleDate={`${moment(state.scheduledSendDate).utc()}`}
								title='Scheduled Date'
								showChangeButton={state.status !== Api.BlogStatus.Completed}
								disabled={false}
								onClick={handleScheduleClick}
							/>
						) : null}
						{state.status === Api.BlogStatus.Pending || state.status === Api.BlogStatus.Completed ? (
							<ApprovalStatus
								title={statusDescription}
								textColor={state.status === Api.BlogStatus.Completed ? green3 : undefined}
							/>
						) : null}
						<footer className={css(styleSheet.footer)}>
							{state.status === Api.BlogStatus.Pending && !impersonationContext?.isValid ? (
								<>
									<button type='button' className={css(bs.ctaButton)} onClick={handleApproveBlogPost}>
										Approve Blog Post
									</button>
									<button className={css(styleSheet.reject)} onClick={handleRejectBlogPost}>
										Reject this blog post
									</button>
								</>
							) : null}
						</footer>
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: state.showConfirmationModal,
								onRequestClose: onDeleteConfirmationRequestClose,
							}}
							options={DefaultDeleteConfirmationOptions}
							title='Are you sure you want to delete this blog post?'
						/>
					</>
				}
				sidebarStyles={[state.showScheduleAction ? styleSheet.sidebarCenter : null]}
			/>
		</BlogPostEditorContext.Provider>
	);
}

export const EditBlogPost = inject(ImpersonationContextKey)(observer(EditBlogPostBase));
