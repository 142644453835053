import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, brandSecondary, destructive, header, success, titles } from '../../styles/colors';
import { cardStyle, ctaButtonProps } from '../../styles/styles';
import TileBackgroundImageUrl from './editAutomationTemplateBackground.png';

const headerFooterHeight = 70;

export const styleSheet = StyleSheet.create({
	addStepButton: {
		...cardStyle,
		color: brandPrimaryText,
		flexGrow: 1,
		fontSize: 14,
		height: 60,
		justifyContent: 'center',
	},
	autosaveInfo: {
		color: header,
		fontSize: 14,
	},
	body: {
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		// top of screen plus automation header height
		minHeight: `calc(100vh - 175px)`,
		padding: '30px 0',
		position: 'relative',
	},
	bodyBackground: {
		backgroundImage: `url(${TileBackgroundImageUrl})`,
		backgroundRepeat: 'repeat',
		backgroundSize: '60px',
		bottom: 0,
		content: '',
		display: 'block',
		height: '100%',
		left: 0,
		opacity: 0.2,
		position: 'absolute',
		right: 0,
		top: 0,
		width: '100%',
		zIndex: 0,
	},
	bodyContent: {
		width: 560,
		zIndex: 0,
	},
	dangerIcon: {
		height: 23,
		width: 30,
	},
	deleteButton: {
		color: destructive,
		fontSize: 16,
		justifyContent: 'center',
		padding: 5,
	},
	enableButton: {
		...ctaButtonProps(success, success, success),
	},
	headerFooter: {
		alignItems: 'center',
		background: 'white',
		display: 'flex',
		height: headerFooterHeight,
		justifyContent: 'space-between',
		zIndex: 1,
	},
	nameContainer: {
		marginBottom: 15,
	},
	nameInput: {
		marginBottom: 6,
	},
	nameInputHint: {
		color: header,
		fontSize: 14,
		fontStyle: 'italic',
	},
	saveAsNewLabel: {
		color: header,
		fontSize: 12,
		fontStyle: 'italic',
	},
	scrollView: {
		height: '100%',
	},
	sectionTitle: {
		color: titles,
		fontSize: 18,
		margin: '0 0 16px',
	},
	segments: {},
	segmentSeparator: {
		height: 40,
	},
	stepIndicator: {
		height: '100%',
		position: 'relative',
		width: '100%',
	},
	stepIndicatorIcon: {
		color: '#fff',
		flexShrink: 0,
		fontSize: 26,
		position: 'absolute',
		top: 40,
	},
	stepIndicatorIconForSwitchChildStep: {
		color: brandSecondary,
		fontSize: 22,
		right: 10,
	},
	stepIndicatorIconHorizontalLine: {
		borderTop: `1px dashed ${brandSecondary}`,
		left: 62,
		position: 'absolute',
		top: 71,
		width: `calc(100% - 62px)`,
	},
	stepIndicatorIconHorizontalLineForSwitchChildStep: {
		left: 31,
		top: 58,
		width: `calc(100% - 78px)`,
	},
	stepIndicatorIconVerticalLine: {
		borderLeft: `1px dashed ${brandSecondary}`,
		left: 31,
		position: 'absolute',
	},
	stepIndicatorIconVerticalLineBottom: {
		height: `calc(100% - 100px)`,
		top: 100,
	},
	stepIndicatorIconVerticalTop: {
		height: 42,
		top: 0,
	},
	stepsFooter: {
		marginTop: 37,
	},
	triggerIndicator: {
		color: '#fff',
		flexShrink: 0,
		fontSize: 26,
		position: 'absolute',
		top: 0,
	},
	triggerIndicatorHorizontalLine: {
		borderTop: `1px dashed ${brandSecondary}`,
		left: 62,
		position: 'absolute',
		top: 31,
		width: `calc(100% - 62px)`,
	},
	triggerIndicatorIcon: {
		marginTop: 8,
	},
	triggerIndicatorVerticalLine: {
		borderLeft: `1px dashed ${brandSecondary}`,
		height: `calc(100% - 62px)`,
		left: 31,
		position: 'absolute',
		top: 62,
	},
	unpublished: {
		alignItems: 'flex-end',
		color: destructive,
		display: 'flex',
		marginRight: 20,
		textTransform: 'uppercase',
	},
});
