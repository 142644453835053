import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	accountUsersSelectContainer: {
		marginTop: 10,
		...CssSelectors.allDescendants(
			{
				minWidth: '100%',
			},
			'.dropdown'
		),
		...CssSelectors.allDescendants(
			{
				minWidth: '100%',
			},
			'.dropdown-anchor'
		),
		...CssSelectors.allDescendants(
			{
				flexGrow: 1,
			},
			'.autocomplete-search-field-input'
		),
	},
});
