import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	collapsible: {
		overflow: 'hidden',
	},
	collapsibleExpanded: {
		overflow: 'unset',
	},
	collapsibleInner: {},
});
