import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ImpersonationContextKey } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { useTemplateCreateMutation } from '../../../queries';
import { bs } from '../../styles/styles';
import { Checkbox } from '../Checkbox';
import { LoadingSpinner } from '../LoadingSpinner';
import { TextInputFormField } from '../TextInputFormField';
import { AutoCompleteSearchField } from '../autocomplete/AutoCompleteSearchField';
import { styleSheet } from './styles';

interface Props {
	canImpersonateOnTemplateSave?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	template: Api.ITemplate;
	onSuccess: (template: Api.ITemplate) => void;
	onCancel: () => void;
}

const _SaveTemplate = (props: Props) => {
	const { logApiError } = useEventLogging('SaveTemplate');
	const errorMessages = useErrorMessages();
	const [name, setName] = React.useState('');
	const [selectedImpersonatedAuthor, setSelectedImpersonatedAuthor] = React.useState<Api.IUser>(null);
	const [selectedTemplateScope, setSelectedTemplateScope] = React.useState<
		Api.TemplateScope.User | Api.TemplateScope.Account
	>(props.template?.scope === Api.TemplateScope.Account ? Api.TemplateScope.Account : Api.TemplateScope.User);
	const templateCreateMutation = useTemplateCreateMutation({
		onError: error => {
			logApiError('SaveEmailMessageTemplate-SaveError', error);
			errorMessages.pushApiError(error);
		},
		onSuccess: template => {
			props.onSuccess(template);
		},
	});
	const handleImpersonatedAuthorSelected = (user: Api.IUser) => {
		setSelectedImpersonatedAuthor(user);
	};
	const handleTemplateScopeToggle = () => {
		setSelectedTemplateScope(templateScope => {
			return templateScope === Api.TemplateScope.Account ? Api.TemplateScope.User : Api.TemplateScope.Account;
		});
	};

	const handleSave = () => {
		const trimmedName = name.trim();
		if (!trimmedName) {
			errorMessages.push({
				messages: ['Please enter a name'],
			});
			return;
		}
		const templateToSave = {
			...props.template,
			name: trimmedName,
			scope: selectedTemplateScope,
		};
		delete templateToSave.id;
		if (selectedImpersonatedAuthor) {
			templateToSave.creator = selectedImpersonatedAuthor as Api.IUserReference;
		}
		templateCreateMutation.mutate({
			template: templateToSave,
			impersonationContext: selectedImpersonatedAuthor
				? { ...props.impersonationContext, user: selectedImpersonatedAuthor }
				: props.impersonationContext,
		});
	};

	return (
		<div className={css(bs.boxBorder)}>
			<div className={css(bs.textTitles, bs.textLg)}>Save as New Template</div>
			<div className={css(bs.pt6, bs.relative)}>
				<div>
					<div className={css(bs.textHeader, bs.textXs)}>
						Template Name:
						<span className='required'>*</span>
					</div>
					<TextInputFormField
						className={css(bs.mt1)}
						onChange={ev => setName(ev.target.value)}
						type='text'
						value={name}
					/>
				</div>
				{templateCreateMutation.isLoading ? <LoadingSpinner className={css(bs.absoluteCenter)} type='large' /> : null}
				{props.canImpersonateOnTemplateSave && props.impersonationContext ? (
					<div className={css(styleSheet.accountUsersSelectContainer)}>
						<div className={css(bs.textHeader, bs.textXs)}>You are creating this template for...</div>
						<AutoCompleteSearchField
							inputId='employeeSearchBox2'
							inputProps={{ placeholder: 'Search' }}
							onItemSelected={handleImpersonatedAuthorSelected}
							placeholder='Search account users'
							preventImpersonation={false}
							resultsLimit={5}
							type={Api.ResourceAutoCompleteViewModelType.User}
						/>
					</div>
				) : null}
				<div className={css(bs.mt4)}>
					<div className={css(bs.flex, bs.itemsCenter, bs.justifyBetween, bs.mb5, bs.textSm)}>
						<Checkbox
							checked={selectedTemplateScope === Api.TemplateScope.Account}
							onChange={handleTemplateScopeToggle}
						>
							<span>Share template with co-workers</span>
						</Checkbox>
					</div>
					<div className={css(bs.flex, bs.itemsCenter, bs.gap2)}>
						<button className={css(bs.ctaButton)} disabled={templateCreateMutation.isLoading} onClick={handleSave}>
							Save
						</button>
						<button
							className={css(bs.ctaButtonReverse)}
							disabled={templateCreateMutation.isLoading}
							onClick={props.onCancel}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const SaveTemplateWithObserver = observer(_SaveTemplate);
export const SaveTemplate = inject(ImpersonationContextKey)(SaveTemplateWithObserver);
