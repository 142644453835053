import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { EditAutomationTemplateItemIdPrefix } from '../../../../models/Automations';
import { styleSheet } from './styles';

interface IProps {
	children?: React.ReactNode;
	className?: string;
	onRenderLeftBumperContent?(): React.ReactNode;
	onRenderRightBumperContent?(): React.ReactNode;
	scrollAnchorId?: string;
	scrollToAnchorRef?: React.MutableRefObject<HTMLElement>;
	styles?: StyleDeclarationValue[];
	stylesLeft?: StyleDeclarationValue[];
	stylesRight?: StyleDeclarationValue[];
}

export const EditAutomationTemplateItemBumpers = React.forwardRef<HTMLDivElement, IProps>(
	function EditAutomationTemplateItemBumpersBase(props, ref) {
		const {
			children,
			className,
			onRenderLeftBumperContent,
			onRenderRightBumperContent,
			scrollAnchorId,
			scrollToAnchorRef,
			styles = [],
			stylesLeft = [],
			stylesRight = [],
		} = props;
		return (
			<div
				className={`${css(styleSheet.container, ...styles)} edit-automation-template-item-bumpers ${className || ''}`}
				ref={ref}
			>
				<div className={css(styleSheet.bumper, styleSheet.left, ...stylesLeft)}>{onRenderLeftBumperContent?.()}</div>
				<div className={css(styleSheet.bumper, styleSheet.right, ...stylesRight)}>{onRenderRightBumperContent?.()}</div>
				<span
					className={`${css(styleSheet.scrollToAnchor)}`}
					id={scrollAnchorId ? `${EditAutomationTemplateItemIdPrefix}-${scrollAnchorId}` : ''}
					ref={scrollToAnchorRef}
				/>
				{children}
			</div>
		);
	}
);
