import { brandPrimaryHover } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		border: `1px solid ${brandPrimaryHover}`,
		borderRadius: 3,
		boxShadow: 'none',
		color: brandPrimaryHover,
	},
	container: {
		background: 'linear-gradient(180deg, #0B90EF 0%, #00AAE8 100%)',
		borderLeft: '3px solid #78DBFF',
		borderRadius: 3,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.100034)',
		color: '#FFF',
		marginTop: 14,
		padding: 10,
	},
	ctaContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	pendingList: {
		margin: 10,
	},
	pendingListItem: {
		margin: '5px 10px',
	},
	title: {
		fontSize: 18,
		marginLeft: 20,
	},
	titleContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-start',
		margin: '10px 14px',
	},
});
