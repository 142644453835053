import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { getCampaignCalendarDateRangeStringValue } from '../../../../models/UiUtils';
import { CompleteColor } from '../../../components/reporting/emails/CampaignListItem/styles';
import { BlogIcon } from '../../../components/svgs/icons/BlogIcon';
import { CheckmarkIcon } from '../../../components/svgs/icons/CheckmarkIcon';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

export function BlogCampaignPendingListItem({ report, index }: { report: Api.IBlogReportView; index?: number }) {
	return (
		<div
			className={css(
				bs.bgOrangeTransparant,
				bs.borderSolid,
				bs.border,
				bs.bgOrangeTransparant,
				bs.textOrange,
				bs.rounded,
				bs.textXs,
				bs.p2,
				bs.textLeft,
				index ? styleSheet.plannedCampaignListItem : undefined
			)}
			style={{ '--stagger-anim-order': index } as React.CSSProperties}
		>
			<div className={css(bs.flex, bs.itemsCenter)}>
				<div
					className={css(
						bs.itemsCenter,
						bs.roundedFull,
						bs.flex,
						bs.bgOrange,
						bs.flexShrink0,
						bs.w10,
						bs.h10,
						bs.justifyCenter,
						bs.mr3,
						bs.bgOrange
					)}
				>
					<BlogIcon fill='white' width={18} />
				</div>
				<div className={css(bs.flex1)}>
					<div>{getCampaignCalendarDateRangeStringValue(report.scheduledSendDate, report.scheduledSendDate)}</div>
					<div className={css(bs.fontBold)}>{report.title}</div>
				</div>
			</div>
			<div className={css(bs.mt1, bs.flex, bs.itemsCenter, bs.justifyBetween)}>
				<div className={css(bs.flex, bs.itemsCenter, bs.gap2)}>
					<CheckmarkIcon fillColor={CompleteColor} type='bold' />
				</div>
			</div>
		</div>
	);
}
