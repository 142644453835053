import { AIContentGenerationStatus } from '../../../../models/Ai';
import { brandSecondary } from '../../../styles/colors';
import { Modal } from '../../Modal';
import { useAIContentGenerationStatusContext } from './context';
import {
	AIAssistantErrorImageUrl,
	AIAssistantThinkingImageUrl,
	AIAssistantTimeOutImageUrl,
	AIContentGenerationWizardLoadingMessages,
	AIContentGenerationWizardType,
} from './models';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { animated, useTransition } from 'react-spring-legacy';

export const AIContentGenerationWizardBackground = () => {
	return (
		<svg
			className={css(styleSheet.background)}
			width='100%'
			height='100%'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect width='100%' height='100%' fill='#00528C' />
			<mask
				height='100%'
				id='mask0_0_3013'
				maskUnits='userSpaceOnUse'
				style={{ maskType: 'luminance' }}
				width='100%'
				x='0'
				y='0'
			>
				<rect width='100%' height='100%' fill='white' />
			</mask>
			<g mask='url(#mask0_0_3013)'>
				<ellipse cx='468' cy='260.5' rx='504' ry='616.5' fill='url(#radialGradient0)' />
				<ellipse cx='53.5' cy='36' rx='285.5' ry='349' fill='url(#radialGradient1)' />
			</g>
			<defs>
				<radialGradient
					id='radialGradient0'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(468 260.5) scale(526.132 643.573)'
				>
					<stop stopColor='#FF0085' stopOpacity='0.706649' />
					<stop offset='1' stopColor='#00528C' stopOpacity='0.01' />
				</radialGradient>
				<radialGradient
					id='radialGradient1'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(53.5 36) scale(298.037 364.326)'
				>
					<stop stopColor='#2A008F' stopOpacity='0.706649' />
					<stop offset='1' stopColor='#00528C' stopOpacity='0.01' />
				</radialGradient>
			</defs>
		</svg>
	);
};

function AIContentGenerationWizardAssistantLoadingMessage({ type }: { type: AIContentGenerationWizardType }) {
	const [index, setIndex] = React.useState<number>(0);
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setIndex(i => {
				if (i < AIContentGenerationWizardLoadingMessages[type].length) {
					return i + 1;
				}
				return i;
			});
		}, 5000);
		return () => clearTimeout(timeout);
	}, [index, type]);

	const currentMessage = AIContentGenerationWizardLoadingMessages[type][index];

	const messageTransition = useTransition(currentMessage, currentMessage, {
		enter: { opacity: 1 },
		from: { opacity: 0 },
		leave: { color: brandSecondary, opacity: 0, position: 'absolute' },
	});

	return (
		<>
			<div>
				<img src={AIAssistantThinkingImageUrl} />
			</div>
			<div className={css(styleSheet.thinkingModalMessages)}>
				{currentMessage ? (
					<p className={css(styleSheet.pill)}>
						{messageTransition.map(({ key, item, props: style }) => (
							<animated.span key={key} style={style}>
								{item}
							</animated.span>
						))}
					</p>
				) : null}
				<div className={css(styleSheet.thinking)}>
					<span />
					<span />
					<span />
				</div>
			</div>
		</>
	);
}

const AIErrorMessage: React.FC<{ imageUrl: string; onRequestClose: () => void }> = ({
	children,
	imageUrl,
	onRequestClose,
}) => {
	return (
		<>
			<figure>
				<img src={imageUrl} alt='ai error image' />
			</figure>
			<div className={css(styleSheet.thinkingModalMessages)}>{children}</div>
			<footer className={css(styleSheet.thinkingModalButtonContainer)}>
				<button className={css(styleSheet.thinkingModalDismissButton)} onClick={onRequestClose}>
					<span>Close</span>
				</button>
			</footer>
		</>
	);
};

function AIContentGenerationWizardAssistantTimeOutMessage({ onRequestClose }: { onRequestClose: () => void }) {
	return (
		<AIErrorMessage imageUrl={AIAssistantTimeOutImageUrl} onRequestClose={onRequestClose}>
			<p className={css(styleSheet.pill)}>...Well it took too long and I fell asleep.</p>
			<p className={css(styleSheet.pill)}>Please try again later</p>
		</AIErrorMessage>
	);
}

const ServiceErrorMessages = [
	"Uh oh, looks like our website's having some issues. Time to call in the tech team and get this fixed!",
	"Sorry folks, bear with us while we sort this out. Check back soon, and we'll have everything up and running again.",
	"Uh oh, I wish I could finish this, but my server is acting up. I'm not lion. Could you come back and try again later?",
];

function AIContentGenerationWizardAssistantErrorMessage({ onRequestClose }: { onRequestClose: () => void }) {
	const errorMessageIndex = React.useRef(Math.round((ServiceErrorMessages.length - 1) * Math.random())).current;
	return (
		<AIErrorMessage imageUrl={AIAssistantErrorImageUrl} onRequestClose={onRequestClose}>
			<p className={css(styleSheet.pill)}>Click* Click* Clack*... *Backspace, backspace, Backspace...</p>
			<p className={css(styleSheet.pill)}>{ServiceErrorMessages[errorMessageIndex]}</p>
		</AIErrorMessage>
	);
}

const AIContentGenerationWizardAssistantLoadingModalScreens = [
	AIContentGenerationStatus.Error,
	AIContentGenerationStatus.Generating,
	AIContentGenerationStatus.TimeOut,
];

export const AIContentGenerationWizardAssistantLoadingModal = ({ type }: { type: AIContentGenerationWizardType }) => {
	const { clearContentGenerationStatus, contentGenerationStatus } = useAIContentGenerationStatusContext();
	const [open, setOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (AIContentGenerationWizardAssistantLoadingModalScreens.includes(contentGenerationStatus)) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [contentGenerationStatus]);

	const onRequestClose = () => {
		clearContentGenerationStatus?.();
		setOpen(false);
	};

	return (
		<Modal isOpen={open} shouldCloseOnOverlayClick={false} className={css(styleSheet.thinkingModal)}>
			{contentGenerationStatus === AIContentGenerationStatus.Generating ? (
				<AIContentGenerationWizardAssistantLoadingMessage type={type} />
			) : contentGenerationStatus === AIContentGenerationStatus.TimeOut ? (
				<AIContentGenerationWizardAssistantTimeOutMessage onRequestClose={onRequestClose} />
			) : contentGenerationStatus === AIContentGenerationStatus.Error ? (
				<AIContentGenerationWizardAssistantErrorMessage onRequestClose={onRequestClose} />
			) : null}
		</Modal>
	);
};
