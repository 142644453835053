import * as Api from '@ViewModels';
import * as React from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';

export const useAutomationCardData = (automationTemplate: Api.AutomationTemplateViewModel<Api.UserSessionContext>) => {
	const userSession = useUserSession();
	// @ts-ignore
	let automationName: string = automationTemplate?.status;
	let statusIcon: React.ReactNode = null;
	const trigger = automationTemplate?.draftTriggerReference || automationTemplate?.publishedTriggerReference;
	const triggerDescription =
		(trigger as Api.ITagAutomationTrigger)?.tag ||
		Api.VmUtils.Automations.triggers.getAutomationTriggerTitle(
			trigger,
			// @ts-ignore
			automationTemplate.isImpersonating ? automationTemplate.impersonationContext : userSession
		);

	const inProgressContactString = `${automationTemplate?.stats?.inProgress} contact${
		// @ts-ignore
		automationTemplate?.stats?.inProgress > 1 || automationTemplate?.stats?.inProgress === 0 ? 's' : ''
	}`;
	const completedContactString = `${automationTemplate?.stats?.completed} contact${
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		automationTemplate.stats.completed > 1 || automationTemplate.stats.completed === 0 ? 's' : ''
	}`;
	// @ts-ignore
	const showCompletedButton = automationTemplate.stats?.completed > 0 && !automationTemplate?.isImpersonating;
	// @ts-ignore
	const showInProgressButton = automationTemplate.stats?.inProgress > 0 && !automationTemplate?.isImpersonating;

	const automationId = automationTemplate?.id;

	switch (automationName) {
		case Api.TemplateStatus.Published: {
			statusIcon = <SuccessCheckIcon />;
			automationName = 'Enabled';
			break;
		}
		default: {
			break;
		}
	}

	return {
		automationId,
		automationName,
		completedContactString,
		inProgressContactString,
		showCompletedButton,
		showInProgressButton,
		statusIcon,
		trigger,
		triggerDescription,
	};
};
