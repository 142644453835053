import { useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import produce from 'immer';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import {
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '../../../models';
import * as AppState from '../../../models/AppState';
import { EmailComposerContext, IEmailComposerContext } from '../../../models/Email';
import { Topics } from '../../../models/LocalNotificationTopics';
import { useAdminUserSession } from '../../../models/hooks/adminStateHooks';
import { useErrorMessages, useFullscreenModal, useToaster } from '../../../models/hooks/appStateHooks';
import { useEmailCampaignCancelScheduleMutation } from '../../../queries';
import { invalidateAllInfiniteCampaignReportQueries } from '../../../queries/Reports/useInfiniteCampaignReportsQuery';
import { CampaignsApprovalViewModel, ComposeEmailViewModel } from '../../../viewmodels/AppViewModels';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../components/ConfirmationDialog';
import { FabContext } from '../../components/FabContext';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../components/LocalNotificationObserver/WithNotificationService';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { PrivateRoute } from '../../components/PrivateRoute';
import { CampaignDetailsSummary } from '../../components/campaigns/CampaignDetailsSummary';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { ViewCitationsModal } from '../../components/templates/ViewCitationsModal';
import { BulkEmailApprovalComposer } from '../bulkEmailApproval/BulkEmailApprovalComposer';
import { BulkEmailApprovalConfirmation } from '../bulkEmailApproval/BulkEmailApprovalConfirmation';
import { BulkEmailApprovalSetSchedule } from '../bulkEmailApproval/BulkEmailApprovalSetSchedule';
import { EditCampaign } from '../templates/EditCampaign';
import { EditSendOptions } from '../templates/EditSendOptions';
import { styleSheet } from './styles';

const getCancelConfirmationOptions = (
	destructiveOptionText: string,
	cancelText = 'Cancel'
): IConfirmationDialogOption<boolean>[] => {
	return [
		{
			isDestructive: true,
			representedObject: true,
			title: destructiveOptionText,
		},
		{
			isCancel: true,
			representedObject: false,
			title: cancelText,
		},
	];
};

interface IProps
	extends AppState.IFabComponentProps,
		IModalContext,
		INotificationServiceComponentProps<Api.ICampaign[]>,
		IImpersonationContextComponentProps {
	approval?: CampaignsApprovalViewModel;
	campaign?: Api.CampaignViewModel;
	campaignGroup?: Api.CampaignViewModel[];
	className?: string;
	disableCampaignEdits?: boolean;
	disableContactEdits?: boolean;
	onContextFinishedEditingClicked?(e: React.MouseEvent<HTMLElement>): void;
	onScheduleCtaClicked?(e: React.MouseEvent<HTMLElement>, schedule: Api.IScheduledSend): void;
	readonlyContent?: boolean;
	requireEstimateToSend?: boolean;
	styles?: StyleDeclarationValue[];
}

export function _EditQueuedCampaign({
	postNotification,
	onContextFinishedEditingClicked: onContextFinishedEditingClickedProp,
	disableCampaignEdits,
	disableContactEdits,
	readonlyContent,
	onScheduleCtaClicked: onScheduleCtaClickedProp,
	requireEstimateToSend,
	parentModal,
	styles,
	className,
	campaignGroup,
	approval: approvalProp,
	impersonationContext: impersonationContextProp,
	campaign: campaignProp,
}: IProps) {
	const userSession = useAdminUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const match = useRouteMatch();
	const history = useHistory();
	const fullscreenModal = useFullscreenModal();
	const { logEvent, logApiError } = useEventLogging('EditQueuedCampaign');
	const [impersonationContext] = React.useState<Api.IImpersonationContext>(
		(impersonationContextProp?.isValid ? impersonationContextProp : null) || approvalProp?.impersonationContext
	);
	const [approval] = React.useState<CampaignsApprovalViewModel>(() => {
		if (approvalProp) {
			return approvalProp;
		}
		if (campaignProp?.status === Api.EmailSendStatus.WaitingForApproval) {
			return new CampaignsApprovalViewModel(userSession, [campaignProp]).impersonate(impersonationContext);
		}
		return null;
	});
	const [emailComposer, setEmailComposer] = React.useState<ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions>>();
	const [composerContext, setComposerContext] =
		React.useState<IEmailComposerContext<ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions>>>();
	const [isLoading, setIsLoading] = React.useState(true);
	const [showingCancelConfirmation, setShowingCancelConfirmation] = React.useState(false);
	const [showingCitations, setShowingCitations] = React.useState(false);
	const campaign = React.useMemo(
		() => approval?.activeCampaign || campaignGroup?.[0] || campaignProp,
		[approval?.activeCampaign, campaignGroup, campaignProp]
	);
	const cancelScheduleMutation = useEmailCampaignCancelScheduleMutation({
		onSuccess: _campaign => {
			campaign.setCampaign(_campaign);
			toaster.push({
				message: `Scheduled send cancelled`,
				type: 'successMessage',
			});
			postNotification({
				info: [_campaign],
				topic: Topics.EDIT_CAMPAIGNS_ITEM,
			});
			invalidateAllInfiniteCampaignReportQueries();
			fullscreenModal?.dismissModal();
		},
		onError: error => {
			logApiError('CancelCampaign-Error', error);
			errorMessages.pushApiError(error);
		},
	});
	React.useEffect(() => {
		async function load() {
			try {
				logEvent('CampaignLoadInstanceForCampaign', { id: campaign.id });
				let _emailComposer: ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions> = null;
				if (approval) {
					await approval.campaignComposer.loadForEditing();
					_emailComposer = approval.campaignComposer as ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions>;
				} else {
					_emailComposer = (await ComposeEmailViewModel.loadInstanceForCampaign(userSession, campaign)).impersonate(
						impersonationContext
					) as ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions>;
				}
				if (_emailComposer) {
					setComposerContext({
						emailComposer: _emailComposer,
					});
					setEmailComposer(_emailComposer);
					setIsLoading(false);
				}
			} catch (err) {
				logApiError('CampaignLoadInstanceForCampaign-Error', err);
				errorMessages.pushApiError(err);
			}
		}
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [approval, logApiError, impersonationContext, userSession, errorMessages, logEvent]);
	const onComposerRequestClose = (_?: any, canceled?: boolean) => {
		if (canceled) {
			logEvent('Cancel');
		}
		parentModal?.onRequestClose(null, canceled);
	};
	const onNavigateTo = (path: string, locationState?: ILocationState<any, any>) => () => {
		history.push(`${match.url}${path}`, locationState);
	};
	const onDone = async (showNextComposer: boolean) => {
		if (approval?.campaignComposer && showNextComposer) {
			try {
				await approval?.campaignComposer.loadForEditing(approval.campaignComposer.campaign);
				await approval?.campaignComposer.loadDefaultMessageContent(true);
				setEmailComposer(approval.campaignComposer as ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions>);
				onNavigateTo('', { viewModel: approval })();
			} catch (error) {
				logApiError(`CampaignApprovalLoadContent-Error`, error);
				errorMessages.pushApiError(error);
			}
		} else {
			fullscreenModal.dismissModal();
		}
	};
	const onApproved = () => {
		const locationState: ILocationState<CampaignsApprovalViewModel, any> = {
			viewModel: approval,
		};
		onNavigateTo('/approval/confirmation', locationState)();
	};
	const updateCampaign = async () => {
		emailComposer.editModeIsActive = false;

		try {
			await emailComposer?.updateCampaign();
			return true;
		} catch (error) {
			logApiError('UpdateCampaign-Error', error);
			errorMessages.pushApiError(error);
			return false;
		}
	};
	const onSaveAndReturnToSummaryClicked = async (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		try {
			const result = await updateCampaign();
			if (result) {
				invalidateAllInfiniteCampaignReportQueries();
				fullscreenModal?.history?.goBack();
			}
		} catch (error) {
			// handled by update
		}
	};
	const onScheduleCtaClicked = (e: React.MouseEvent<HTMLElement>) => {
		onScheduleCtaClickedProp?.(e, emailComposer?.emailMessage?.options?.scheduledSend);
		if (!e.isDefaultPrevented()) {
			onSaveAndReturnToSummaryClicked(e);
		}
	};
	const onFinishComposing = (approved: boolean) => {
		if (approved) {
			onNavigateTo('/approval/set-schedule', fullscreenModal?.history?.location?.state)();
		} else {
			const locationState: ILocationState<CampaignsApprovalViewModel, any> = {
				viewModel: approval,
			};
			if (approval.pendingCampaigns.length === 0) {
				parentModal?.onRequestClose();
			} else {
				onNavigateTo('/approval/confirmation', locationState)();
			}
		}
	};
	const onContextFinishedEditingClicked = (e: React.MouseEvent<HTMLElement>) => {
		onContextFinishedEditingClickedProp?.(e);
		if (!e.isDefaultPrevented()) {
			if (emailComposer.isFollowUp) {
				e.preventDefault();
				fullscreenModal?.history?.goBack();
				return;
			}
			onSaveAndReturnToSummaryClicked(e);
		}
	};
	const showCancelConfirmation = () => {
		setShowingCancelConfirmation(true);
	};
	const approveCampaign = () => {
		const _campaign = approval.activeCampaign;
		if (_campaign && !approval.isBusy) {
			// approve also updates
			const promise = approval.approveCampaign();
			if (promise) {
				promise
					.then(() => {
						postNotification({
							info: [_campaign.toJs()],
							topic: Topics.EDIT_CAMPAIGNS_ITEM,
						});
						invalidateAllInfiniteCampaignReportQueries();
						onApproved();
					})
					.catch((error: Api.IOperationResultNoValue) => {
						logApiError(`Approve-Error`, error);
						errorMessages.pushApiError(error);
					});
				return promise;
			}
		}
		return null;
	};
	const onSendCompliance = (sendWithComplianceEmail?: string) => {
		const _campaign = approval?.activeCampaign;
		if (_campaign && !approval.isBusy) {
			// approve also updates
			approval
				.sendCompliance(sendWithComplianceEmail)
				.then(() => {
					postNotification({
						info: [_campaign.toJs()],
						topic: Topics.EDIT_CAMPAIGNS_ITEM,
					});
					invalidateAllInfiniteCampaignReportQueries();
					toaster.push({
						message: `Copy sent to ${sendWithComplianceEmail || userSession?.user?.email}`,
						type: 'successMessage',
					});
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError(`Send-Error`, error);
					errorMessages.pushApiError(error);
				});
		}
	};
	const onApproveWithoutCompliance = () => {
		const nextEmailComposer = produce(emailComposer, draftEmailComposer => {
			draftEmailComposer.emailMessage.options = draftEmailComposer.emailMessage.options || {};
			draftEmailComposer.emailMessage.options.sendWithCompliance = false;
		});
		setEmailComposer(nextEmailComposer);
		const _campaign = approval?.activeCampaign;
		if (_campaign && !approval.isBusy) {
			approval
				.approveCampaign()
				.then(() => {
					postNotification({
						info: [_campaign.toJs()],
						topic: Topics.EDIT_CAMPAIGNS_ITEM,
					});
					invalidateAllInfiniteCampaignReportQueries();
					onApproved();
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError(`Approve-Error`, error);
					errorMessages.pushApiError(error);
				});
		}
	};
	const onRejectionConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!canceled && result?.representedObject) {
			const _campaign = approval.activeCampaign;
			const promise = approval.rejectCampaign();
			if (promise) {
				logEvent('RejectCampaign');
				promise
					.then(() => {
						toaster.push({
							message: `Scheduled send rejected`,
							type: 'successMessage',
						});
						postNotification({
							info: [_campaign.toJs()],
							topic: Topics.EDIT_CAMPAIGNS_ITEM,
						});
						invalidateAllInfiniteCampaignReportQueries();
						onFinishComposing(false);
					})
					.catch((error: Api.IOperationResultNoValue) => {
						logApiError(`RejectCampaign-Error`);
						errorMessages.pushApiError(error);
					});
			}
		}
		setShowingCancelConfirmation(false);
	};
	const cancelCampaign = async () => {
		logEvent('CancelCampaign', { id: campaign?.id });
		cancelScheduleMutation.mutate({
			campaignId: campaign.id,
			impersonationContext: impersonationContextProp?.isValid
				? {
						account: impersonationContextProp?.account,
						// TODO: might need to impersonate with active admin, this user could have been deactivated.
						user: campaign?.creator,
					}
				: null,
		});
	};
	const onCancelConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!canceled && result?.representedObject) {
			cancelCampaign();
		}
		setShowingCancelConfirmation(false);
	};
	const renderCancelConfirmationDialog = () => {
		const showForRejectAction = approval && !impersonationContextProp?.isValid;
		return (
			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: showingCancelConfirmation,
					onRequestClose: showForRejectAction ? onRejectionConfirmationRequestClose : onCancelConfirmationRequestClose,
				}}
				options={getCancelConfirmationOptions(showForRejectAction ? 'Reject' : 'Cancel Campaign', 'Nevermind')}
				title={`Are you sure you want to ${showForRejectAction ? 'reject' : 'cancel'} this email campaign?`}
			/>
		);
	};
	const onRenderViewCitations = () => {
		if (emailComposer.campaign.emailBodyTemplate?.citation == null) {
			return null;
		}
		return (
			<ViewCitationsModal
				modalProps={{
					isOpen: showingCitations,
					onRequestClose: () => setShowingCitations(false),
				}}
			>
				{/* @ts-ignore */}
				{emailComposer.campaign.emailBodyTemplate.citation}
			</ViewCitationsModal>
		);
	};
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} edit-queued-campaign ${className || ''}`}>
			{!emailComposer?.isUserEditingMessage && (
				<MultiContainerHeader fullscreenHeader='View Campaign' onFullscreenRequestClose={onComposerRequestClose} />
			)}
			{isLoading ? null : (
				<EmailComposerContext.Provider value={composerContext}>
					<Switch>
						<PrivateRoute
							exact={true}
							path={`${match.url}/approval/confirmation`}
							render={props => (
								<BulkEmailApprovalConfirmation {...props} approval={approval} disableHeader={true} onDone={onDone} />
							)}
							userSession={userSession}
						/>
						<PrivateRoute
							exact={true}
							path={`${match.url}/approval/set-schedule`}
							render={props => (
								<BulkEmailApprovalSetSchedule
									{...props}
									approval={approval}
									disableHeader={true}
									onApproved={onApproved}
									onScheduleCtaClicked={onScheduleCtaClicked}
									requireEstimateToSend={requireEstimateToSend}
									scheduleCtaText='Okay'
								/>
							)}
							userSession={userSession}
						/>
						<PrivateRoute
							exact={true}
							path={`${match.url}/approval`}
							render={props => (
								<BulkEmailApprovalComposer {...props} approval={approval} onFinish={onFinishComposing} />
							)}
							userSession={userSession}
						/>
						<PrivateRoute
							exact={true}
							path={`${match.url}/send-options`}
							render={props => (
								<EditSendOptions
									{...props}
									campaign={campaign}
									disableSendNow={true}
									emailComposer={emailComposer}
									onScheduleCtaClicked={onScheduleCtaClicked}
									requireEstimateToSend={requireEstimateToSend}
									styles={[styleSheet.childRoute]}
									submitButtonText='Okay'
								/>
							)}
							userSession={userSession}
						/>
						<PrivateRoute
							exact={false}
							path={match.url}
							render={props => (
								<EditCampaign
									contextFinishedEditingCtaText='Okay'
									disableCampaignEdits={disableCampaignEdits}
									disableContactEdits={disableContactEdits}
									emailComposer={
										(approval?.campaignComposer ||
											emailComposer) as ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions>
									}
									initialEmailBodyTemplate={emailComposer.campaign?.emailBodyTemplate || undefined}
									isQueuedCampaign={true}
									onContentFinishedEditingClicked={updateCampaign}
									onContextFinishedEditingClicked={onContextFinishedEditingClicked}
									onRejectClicked={showCancelConfirmation}
									readonlyContent={readonlyContent}
									styles={[styleSheet.childRoute]}
								>
									{props.location.pathname === match.url && (
										<CampaignDetailsSummary
											campaign={campaign}
											campaignGroup={campaignGroup}
											emailComposer={emailComposer}
											onApproveClicked={approveCampaign}
											onCancelClicked={showCancelConfirmation}
											onChangeScheduleClicked={() => {
												onNavigateTo(
													approval ? '/approval/set-schedule' : '/send-options',
													fullscreenModal?.history?.location?.state
												)();
											}}
											onSendCompliance={onSendCompliance}
											onApproveWithoutCompliance={onApproveWithoutCompliance}
											onEditRecipientsClicked={() => {
												onNavigateTo(
													'/recipients',
													!approval ? fullscreenModal?.history?.location?.state : undefined
												)();
											}}
											onViewCitationsClicked={() => setShowingCitations(true)}
										/>
									)}
								</EditCampaign>
							)}
							userSession={userSession}
						/>
						<Redirect to={`${match.url}`} push={false} />
					</Switch>
					{renderCancelConfirmationDialog()}
					{onRenderViewCitations()}
				</EmailComposerContext.Provider>
			)}
			<FabContext appearance={{ hidden: true }} />
		</div>
	);
}

const EditQueuedCampaignAsObserver = observer(_EditQueuedCampaign);
const EditQueuedCampaignWithContext = inject(
	ModalChildComponentContextKey,
	AppState.FabViewModelKey,
	ImpersonationContextKey
)(EditQueuedCampaignAsObserver);
export const EditQueuedCampaign = withNotificationService(EditQueuedCampaignWithContext);
