import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const recipientsFooterHeight = 42;

export const styleSheet = StyleSheet.create({
	container: {},
	followUpContainer: {
		display: 'flex',
		marginBottom: 12,
	},
	recipientsFooter: {
		hegiht: recipientsFooterHeight,
		marginTop: 10,
		...CssSelectors.allChildren({
			display: 'block',
			width: '100%',
		}),
	},
	tag: {
		cursor: 'pointer',
	},
	tagContainer: {
		marginLeft: 10,
	},
	tagRow: {
		':not(:first-child)': {
			marginTop: 15,
		},
	},
});
