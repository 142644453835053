import { css } from 'aphrodite';
import React from 'react';
import { CampaignType } from '../../../../models/AdminModels';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

export const ContentCalendarLegend = ({
	campaignTypes,
	showText,
	// onSetCampaignType, // TODO: add back in feature branch
}: {
	campaignTypes: CampaignType[];
	showText: boolean;
	onSetCampaignType: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
	const getCardKeyLabel = (key: string): React.ReactNode => {
		return key === CampaignType.Email ? (
			'Emails'
		) : key === CampaignType.Social ? (
			<>
				Social <span className={css(styleSheet.showSpan)}>Media</span>
			</>
		) : key === CampaignType.Blog ? (
			'Blogs'
		) : null;
	};

	return (
		<ul className={css(bs.flex, bs.horizontalStack)}>
			{campaignTypes.map((type, i) => {
				return (
					<li className={css(bs.block, i === 0 ? styleSheet.listItem : undefined)} key={type}>
						<div>
							<span
								className={css(
									styleSheet.calendarCardTypeColor,
									type === CampaignType.Email ? styleSheet.calendarCardTypeColorEmail : undefined,
									type === CampaignType.Social ? styleSheet.calendarCardTypeColorSocial : undefined,
									type === CampaignType.Blog ? styleSheet.calendarCardTypeColorBlog : undefined
								)}
							/>
							<span className={css(!showText ? styleSheet.showLabel : undefined)}>{getCardKeyLabel(type)}</span>
						</div>
					</li>
				);
			})}
		</ul>
	);
};
