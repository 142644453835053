import * as Api from '@ViewModels';
import * as React from 'react';
import { AIContentGenerationStatus } from '../../../../models/Ai';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';
import { useConnectionTypes, useEmailContentGeneration } from '../../../../queries';
import {
	AIContentGenerationStatusContext,
	IAIContentGenerationStatusContext,
} from '../../../components/ai/AIContentGenerationWizard/context';

export interface IEmailContentGenerationContext {
	generateEmailContentAsync?(
		request: Api.IEmailContentGenerationRequest
	): Promise<Api.IAIGeneratedContent<Api.IEmailMessageCompose>>;
}

// @ts-ignore
export const EmailContentGenerationContext = React.createContext<IEmailContentGenerationContext>(null);

export const useEmailContentGenerationContext = () =>
	useContextGuard(EmailContentGenerationContext, 'useEmailContentGenerationContext');
export interface IEmailContentGenerationContextProviderComponentProps {
	emailContentGenerationContext?: IEmailContentGenerationContext & IAIContentGenerationStatusContext;
}

export function withEmailContentGenerationContextProvider<P>(
	WrappedComponent: React.ComponentType<P & IEmailContentGenerationContextProviderComponentProps>
): React.ComponentType<P & IEmailContentGenerationContextProviderComponentProps> {
	return function EmailContentGenerationContextWrapper(props: P) {
		const userSession = useUserSession();

		const [context, setContext] = React.useState<IEmailContentGenerationContext & IAIContentGenerationStatusContext>({
			clearContentGenerationStatus: () =>
				setContext(c => ({ ...c, contentGenerationStatus: AIContentGenerationStatus.Idle })),
			contentGenerationStatus: AIContentGenerationStatus.Idle,
			generateEmailContentAsync: (request: Api.IEmailContentGenerationRequest) => {
				setContext(ctx => ({ ...ctx, contentGenerationStatus: AIContentGenerationStatus.Generating }));
				return mutateAsyncRef.current?.(request);
			},
		});

		const { mutateAsync } = useEmailContentGeneration({
			onError: error =>
				setContext(c => ({
					...c,
					contentGenerationStatus:
						error.systemCode === 408 ? AIContentGenerationStatus.TimeOut : AIContentGenerationStatus.Error,
				})),
			onSuccess: () => setContext(c => ({ ...c, contentGenerationStatus: AIContentGenerationStatus.Success })),
			userSession,
		});
		const mutateAsyncRef = React.useRef(mutateAsync);
		mutateAsyncRef.current = mutateAsync;

		return (
			<EmailContentGenerationContext.Provider value={context}>
				<AIContentGenerationStatusContext.Provider
					value={{
						clearContentGenerationStatus: context.clearContentGenerationStatus,
						contentGenerationStatus: context.contentGenerationStatus,
					}}
				>
					<WrappedComponent emailContentGenerationContext={context} {...props} />
				</AIContentGenerationStatusContext.Provider>
			</EmailContentGenerationContext.Provider>
		);
	};
}

export function withConnectionTypes<T = any>(
	WrappedComponent: React.ComponentType<T>,
	options: {
		refetchOnWindowFocus: boolean;
	}
) {
	const Sfc: React.FC<T & IConnectionTypeComponentProps> = props => {
		const userSession = useUserSession();
		const connectionTypeQuery = useConnectionTypes({
			// @ts-ignore
			enabled: userSession.account.features.connectionTypes?.enabled ?? false,
			refetchOnWindowFocus: options.refetchOnWindowFocus,
		});

		return <WrappedComponent connectionTypes={connectionTypeQuery.data?.availableConnectionTypes ?? []} {...props} />;
	};
	return Sfc;
}

export interface IConnectionTypeComponentProps {
	connectionTypes?: Api.IConnectionType[];
}
