import * as React from 'react';

interface IProps<TState = any> {
	onDidMount?(component: LifecycleHooks): void;
	onDidUpdate?(component: LifecycleHooks, prevProps: IProps, prevState?: TState): void;
	onWillUnmount?(component: LifecycleHooks): void;
}

export class LifecycleHooks<TState = any> extends React.PureComponent<IProps, TState> {
	public componentDidMount() {
		const { onDidMount } = this.props;
		if (onDidMount) {
			onDidMount.call(this, this);
		}
	}

	public componentDidUpdate(prevProps: IProps<TState>, prevState: TState) {
		const { onDidUpdate } = this.props;
		if (onDidUpdate) {
			onDidUpdate.call(this, this, prevProps, prevState);
		}
	}

	public componentWillUnmount() {
		const { onWillUnmount } = this.props;
		if (onWillUnmount) {
			onWillUnmount.call(this, this);
		}
	}

	public render() {
		return this.props.children || null;
	}
}
