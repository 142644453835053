import { useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { Provider, observer } from 'mobx-react';
import * as React from 'react';
import ReactModal from 'react-modal';
import { EmailComposerContext } from '../../../../models/Email';
import { FirstNamePlaceholder, Token } from '../../../../models/Token';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	defaultRichContentForEmailAutomationStep,
	getFileSizeStringValue,
} from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useAutomationTemplateStepEmailAttachmentCreateMutation,
	useAutomationTemplateStepEmailAttachmentDeleteMutation,
	useAutomationTemplateStepEmailUpdateMutation,
} from '../../../../queries';
import EmailAutomationGraphic from '../../../assets/email_automation_graphic.svg';
import { AIAssistantButton } from '../../../containers/AIAssistantButton';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { AIContentGenerationWizard } from '../../ai/AIContentGenerationWizard';
import { AIContentGenerationStatusContext } from '../../ai/AIContentGenerationWizard/context';
import {
	AIContentGenerationWizardAssistantEmailMessages,
	AIContentGenerationWizardType,
} from '../../ai/AIContentGenerationWizard/models';
import { useEmailAiContentWizard } from '../../ai/AIContentGenerationWizard/useEmailAiContentWizard';
import { EmailComposer } from '../../email/EmailComposer';
import { styleSheet } from './styles';

interface Props {
	automationTemplateId: string;
	step: Api.IEmailAutomationStep;
	className?: string;
	onRequestRemoveSavedEmailAttachment?(savedAttachment: Api.IFileAttachment): Promise<any>;
	readonly?: boolean;
	trigger?: Api.IResourceSelectorAutomationTrigger;
	styles?: StyleDeclarationValue[];
	onRequestClose?(result?: Api.IEmailAutomationStep, canceled?: boolean): void;
	syncSubjectWithStep?: boolean;
	onAttachmentRemoved?: (data: Api.IEmailAutomationStep) => void;
	impersonationContext?: Api.IImpersonationContext;
	defaultInitialContent?: Api.IRawRichTextContentState;
}

function _EditAutomationStepEmailComposerModal({
	automationTemplateId,
	step,
	trigger,
	readonly,
	onRequestClose,
	syncSubjectWithStep = false,
	onAttachmentRemoved,
	impersonationContext,
	defaultInitialContent = defaultRichContentForEmailAutomationStep,
}: Props) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging();
	const attachmentByteSizeLimit = Api.VmUtils.getMaxAttachmentByteCountForEmailConfigurations(
		impersonationContext
			? impersonationContext.account.emailProviderConfigurations
			: userSession.account.toJs().emailProviderConfigurations
	);
	const [attachments] = React.useState(new Api.AttachmentsToBeUploadedViewModel<File>([], attachmentByteSizeLimit));
	const [flow] = React.useState(trigger?.resourceSelector);
	const [emailBodyEditorState, setEmailBodyEditorState] = React.useState(
		convertRawRichTextContentStateToRichContentEditorState(step.content ?? defaultInitialContent)
	);
	const [emailMessageTemplate, setEmailMessageTemplate] = React.useState<Api.ITemplate>(
		step.options?.templateReference?.templateId
			? {
					id: step.options.templateReference.templateId,
					name: step.options.templateReference.name,
				}
			: null
	);
	const [subject, setSubject] = React.useState(step.options.subject ?? step.subject);
	const [messageTemplateEdited, setMessageTemplateEdited] = React.useState(false);
	const [messageFieldError, setMessageFieldError] = React.useState<string>();
	const [subjectFieldError, setSubjectFieldError] = React.useState<string>();
	const canShowAiContentAssistant = (impersonationContext?.account ? impersonationContext.account : userSession.account)
		?.features?.contentGeneration?.enabled;
	const composerContext = { flow };
	const { aiContentGenContext, onGenerate, onUndoClicked, setShowingAiContentGenWizard, showingAiContentGenWizard } =
		useEmailAiContentWizard({
			isRewriting: !!emailMessageTemplate,
			onEmailGenerated: email => {
				setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(email.content));
				if (email.subject) {
					setSubject(email.subject);
				}
			},
			onUndo: undoContent => {
				if (undoContent) {
					setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(undoContent.content));
					if (undoContent.subject) {
						setSubject(undoContent.subject);
					}
				}
			},
		});
	const onGenerateClicked = (options: Api.IAIContentGenerationOptions) => {
		const request: Api.IEmailContentGenerationRequest = {
			...options,
			content: emailBodyEditorState?.getRawRichTextContent(),
			subject,
			templateId: emailMessageTemplate?.id,
		};
		return onGenerate(request);
	};

	const clearErrorMessages = () => {
		if (!!messageFieldError || !!subjectFieldError) {
			setMessageFieldError('');
			setSubjectFieldError('');
		}
	};

	const onMessageEditorStateChanged = (editorState: Api.IRichContentEditorState) => {
		setEmailBodyEditorState(editorState);
		setEmailMessageTemplate(editorState.hasContent() ? emailMessageTemplate : null);
		setMessageFieldError('');
		setMessageTemplateEdited(editorState.hasContent() ? !!emailMessageTemplate : false);
		setSubjectFieldError('');
	};

	const onEmailMessageTemplateChanged = (template: Api.ITemplate) => {
		setEmailMessageTemplate(template);
		setMessageFieldError('');
		setSubjectFieldError('');
		if (template) {
			setEmailBodyEditorState(convertRawRichTextContentStateToRichContentEditorState(template.content));
			setSubject(template.subject || '');
		}
	};

	const onSubjectChanged = (_subject: string) => {
		setMessageFieldError('');
		setSubject(_subject);
		setSubjectFieldError('');
	};

	const onCancelButtonClicked = () => {
		onRequestClose(null, true);
	};
	const emailStepUpdateMutation = useAutomationTemplateStepEmailUpdateMutation({
		onError: error => {
			logApiError('SaveEamilAutomationStep-Error', error);
			errorMessages.pushApiError(error);
		},
		onSuccess: data => {
			onRequestClose(data, false);
		},
	});
	const attachmentCreateMutation = useAutomationTemplateStepEmailAttachmentCreateMutation();
	const saving = attachmentCreateMutation.isLoading || emailStepUpdateMutation.isLoading;
	const attachmentDeleteMutation = useAutomationTemplateStepEmailAttachmentDeleteMutation();
	const handleRequestRemoveSavedEmailAttachment = async (attachment: Api.IFileAttachment) => {
		try {
			const stepData = await attachmentDeleteMutation.mutateAsync({
				templateId: automationTemplateId,
				stepId: step.id,
				attachmentId: attachment.id,
				impersonationContext,
			});
			onAttachmentRemoved?.(stepData);
		} catch (error) {
			logApiError('DeleteEmailAutomationStepAttachment-Error', error);
			errorMessages.pushApiError(error);
		}
	};
	const onSaveButtonClicked = async () => {
		if (!subject) {
			setMessageFieldError('');
			setSubjectFieldError('Please enter a subject before saving.');
			return;
		}

		if (!emailBodyEditorState || (!!emailBodyEditorState && !emailBodyEditorState.hasContent())) {
			setMessageFieldError('Please enter a message before saving.');
			setSubjectFieldError('');
			return;
		}

		const newContent = emailBodyEditorState.getRawRichTextContent();
		if (!Token.isFirstNameTokenFormattedCorrectlyLoose(newContent)) {
			errorMessages.push({
				messages: [
					`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
				],
			});
			return;
		}

		const hasExceededMaxByteCount =
			attachments.hasExceededMaxByteCount ||
			attachments.byteCount + (step.attachments ?? []).reduce((count, x) => count + x.fileSize, 0) >
				attachments.maxByteCount;
		if (hasExceededMaxByteCount) {
			setMessageFieldError(
				`You’ve exceeded the attachment size limit of ${getFileSizeStringValue(attachments.maxByteCount)}.`
			);
			setSubjectFieldError('');
			return;
		}
		const newTemplateReference: Api.ITemplateReference | null = emailMessageTemplate?.id
			? {
					isCustomized: !!messageTemplateEdited,
					isSystemTemplate:
						emailMessageTemplate?.scope === Api.TemplateScope.System ||
						emailMessageTemplate?.scope === Api.TemplateScope.Industry,
					name: emailMessageTemplate.name,
					templateId: emailMessageTemplate.id,
				}
			: null;
		let newStep = step;
		if (attachments?.count > 0) {
			try {
				newStep = await attachmentCreateMutation.mutateAsync({
					templateId: automationTemplateId,
					stepId: step.id,
					attachmentFiles: attachments.attachments,
					impersonationContext,
				});
			} catch (error) {
				logApiError('SaveEamilAutomationStepAttachments-Error', Api.asApiError(error));
				errorMessages.pushApiError(Api.asApiError(error));
				return;
			}
		}
		newStep = {
			...newStep,
			...(syncSubjectWithStep && {
				name: subject,
				subject,
			}),
			content: newContent,
			options: {
				subject,
				templateReference: newTemplateReference,
			},
		};
		emailStepUpdateMutation.mutate({
			templateId: automationTemplateId,
			stepId: step.id,
			impersonationContext,
			step: newStep,
		});
	};

	return (
		<ReactModal
			onRequestClose={() => onRequestClose(null, true)}
			isOpen={true}
			className={css(styleSheet.modalContent)}
			portalClassName={css(styleSheet.modalRoot)}
			overlayClassName={css(styleSheet.modalOverlay)}
			shouldCloseOnOverlayClick={false}
		>
			<div className={css(styleSheet.modalHeader)}>
				<h2 className={css(styleSheet.modalHeaderTitle)}>Edit Email</h2>
				<DeprecatedCloseButton onClick={() => onRequestClose(null, true)} fillColor='white' />
			</div>
			<div className={css(styleSheet.modalBody)}>
				<div className={css(styleSheet.leftContainer)}>
					<h3 className={css(styleSheet.header)}>{`${!readonly ? 'Edit' : 'View'} Email`}</h3>
					<div className={css(styleSheet.body)}>
						<div className={css(styleSheet.bodyInputs)}>
							{!readonly && canShowAiContentAssistant ? (
								<AIAssistantButton onClick={() => setShowingAiContentGenWizard(true)}>
									{emailMessageTemplate ? `Rewrite this email using our AI assistant` : `Create this email with AI`}
								</AIAssistantButton>
							) : null}
							<Provider impersonationContext={impersonationContext}>
								<EmailComposerContext.Provider value={composerContext}>
									<EmailComposer
										attachments={attachments}
										bodyEditorState={emailBodyEditorState}
										bodyError={messageFieldError}
										bodyTemplate={emailMessageTemplate}
										className={css(styleSheet.bodyEditor)}
										hideBodyFooterToolbar={true}
										onBodyEditorFocusChanged={clearErrorMessages}
										onBodyEditorStateChanged={onMessageEditorStateChanged}
										onBodyFieldScroll={clearErrorMessages}
										onBodyTemplateChanged={onEmailMessageTemplateChanged}
										onRequestRemoveSavedEmailAttachment={
											!readonly ? handleRequestRemoveSavedEmailAttachment : undefined
										}
										onSubjectChanged={onSubjectChanged}
										readonly={readonly}
										savedAttachments={step.attachments ?? []}
										showCcField={false}
										subject={subject}
										subjectError={subjectFieldError}
									/>
								</EmailComposerContext.Provider>
							</Provider>
						</div>
						{!!saving && <LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />}
					</div>
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.footer)}>
						<button
							className={css(baseStyleSheet.ctaButton)}
							onClick={!readonly ? onSaveButtonClicked : onCancelButtonClicked}
						>
							<span>{!readonly ? 'Save' : 'Close'}</span>
						</button>
						{!readonly ? (
							<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancelButtonClicked}>
								<span>Cancel</span>
							</button>
						) : null}
					</div>
				</div>
				<div className={css(styleSheet.rightContainer)}>
					{showingAiContentGenWizard ? (
						<AIContentGenerationStatusContext.Provider value={aiContentGenContext}>
							<AIContentGenerationWizard<Api.IEmailMessageCompose, Api.IAIGeneratedContent<Api.IEmailMessageCompose>>
								cautionMessage='Please review content created with AI before sending.'
								isInitiallyRewriting={!!emailMessageTemplate}
								onCloseClicked={() => setShowingAiContentGenWizard(false)}
								onGenerateClicked={onGenerateClicked}
								onRetryClicked={onUndoClicked}
								type={AIContentGenerationWizardType.Email}
								assistantMessageMap={AIContentGenerationWizardAssistantEmailMessages}
								requireInstructions
							/>
						</AIContentGenerationStatusContext.Provider>
					) : (
						<div className={css(styleSheet.rightContainerPlaceholder)}>
							<img src={EmailAutomationGraphic} className={css(styleSheet.emailAutomationGraphic)} />
							<p className={css(styleSheet.rightInfoText)}>
								For any email that is part of an automation, the default signature of the sender will be automatically
								used.
							</p>
						</div>
					)}
				</div>
			</div>
		</ReactModal>
	);
}

export const EditAutomationStepEmailComposerModal = observer(_EditAutomationStepEmailComposerModal);
