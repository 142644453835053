import { ContactFilterApproximation } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useToggle } from '../../../../models/hooks/useToggle';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { PopoverType, TinyPopover } from '../../../../web/components/TinyPopover';
import { InfoIcon } from '../../../../web/components/svgs/icons/InfoIcon';
import * as Colors from '../../../../web/styles/colors';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	loading?: boolean;
	approximation?: ContactFilterApproximation | null | undefined;
	followUpCount?: number;
	fallbackCount?: number;
	styles?: StyleDeclarationValue[];
	hasUnsubscribeTagNot?: boolean;
	hasEmailBouncedTagNot?: boolean;
}

export const RecipientsCount: React.FC<IProps> = observer(props => {
	const {
		className,
		styles,
		loading,
		followUpCount,
		fallbackCount: manualCount,
		approximation,
		hasUnsubscribeTagNot,
		hasEmailBouncedTagNot,
	} = props;
	const hasRecipientsWithoutEmails = approximation ? approximation.hasEmail !== approximation.total : false;
	const flatCount =
		(approximation ? approximation.total || 0 : !Number.isNaN(followUpCount) ? followUpCount : manualCount) ??
		(manualCount || 0);

	const recipients = !hasRecipientsWithoutEmails
		? `${flatCount}` + ` recipient${flatCount > 1 || flatCount === 0 ? 's' : ''}`
		: `Approx. ${approximation?.hasEmail} out of ${approximation?.total} have emails`;

	let info;
	if (hasUnsubscribeTagNot && hasEmailBouncedTagNot) {
		info = 'Excludes contacts who unsubscribed or whose email bounced.';
	} else if (hasEmailBouncedTagNot) {
		info = 'Excludes contacts whose email bounced.';
	} else if (hasUnsubscribeTagNot) {
		info = 'Excludes contacts who unsubscribed.';
	}

	const [isOpen, , setOpen] = useToggle(false);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} recipients-count ${className || ''}`}>
			{loading ? (
				<LoadingSpinner type='tiny' />
			) : (
				<h5 className={css(styleSheet.recipientCountHeader)}>
					<span className={css(styleSheet.recipientCountLabel)}>Sending to: </span>
					<span className={css(styleSheet.recipientCountResult, baseStyleSheet.fontBold)}>{recipients}</span>
					{!!info && (
						<div onMouseEnter={setOpen(true)} onMouseLeave={setOpen(false)}>
							<TinyPopover
								anchor={<InfoIcon fillColor={Colors.brandSecondary} className={css(styleSheet.infoIcon)} />}
								isOpen={isOpen}
								dismissOnOutsideAction={true}
								onRequestClose={setOpen(false)}
								align='start'
								placement={['right', 'bottom']}
								type={PopoverType.lightBlue}
							>
								<div className={css(styleSheet.infoMessage)}>{info}</div>
							</TinyPopover>
						</div>
					)}
				</h5>
			)}
		</div>
	);
});
