import * as Api from '@ViewModels';
import { useState } from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';

export enum EOwnershipOption {
	All,
	Me,
	Owned,
	Connections,
}

const allCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.All,
};
const meCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.OwnedBy,
};
const ownedCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.OwnedBy,
};
const connectionsCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.Connections,
};
export const EOwnershipOptionsMap = {
	[EOwnershipOption.All]: {
		criteria: allCriteria,
		text: 'All contacts',
	},
	[EOwnershipOption.Me]: {
		criteria: meCriteria,
		text: 'Contacts I own',
	},
	[EOwnershipOption.Owned]: {
		criteria: ownedCriteria,
		text: 'Contacts owned by',
	},
	[EOwnershipOption.Connections]: {
		criteria: connectionsCriteria,
		text: 'My connections',
	},
};

const findMatchingOwnership = (criterion: Api.IContactFilterCriteria) => {
	return Object.entries(EOwnershipOptionsMap).find(entry => {
		const value = entry[1];
		return criterion.property === value.criteria.property;
	});
};

export const useOwnershipFilters = (initialFilterCriteria: Api.IContactFilterCriteria[] = []) => {
	const userSession = useUserSession();

	const getInitialValues = (criteria: Api.IContactFilterCriteria[]) => {
		const ownershipCriterion = criteria.find(criterion => {
			return (
				criterion.property &&
				[Api.ContactFilterCriteriaProperty.OwnedBy, Api.ContactFilterCriteriaProperty.Connections].indexOf(
					criterion.property
				) > -1
			);
		});

		const isCurrentUserOwner = !!ownershipCriterion && ownershipCriterion.value === userSession.user?.id;

		if (!ownershipCriterion) {
			return { ownership: EOwnershipOption.All, userId: null };
		} else if (isCurrentUserOwner && ownershipCriterion.property === Api.ContactFilterCriteriaProperty.OwnedBy) {
			return { ownership: EOwnershipOption.Me, userId: ownershipCriterion.value };
		} else if (
			!!ownershipCriterion.value &&
			ownershipCriterion.property === Api.ContactFilterCriteriaProperty.OwnedBy
		) {
			return { ownership: EOwnershipOption.Owned, userId: ownershipCriterion.value };
		} else if (ownershipCriterion.property === Api.ContactFilterCriteriaProperty.Connections) {
			return { ownership: EOwnershipOption.Connections, userId: ownershipCriterion.value };
		} else {
			return { ownership: null, userId: null };
		}
	};

	const { ownership, userId } = getInitialValues(initialFilterCriteria);

	const [selectedUserId, setSelectedUserId] = useState<string | null | undefined>(userId);

	const [selectedOwnership, setSelectedOwnership] = useState<EOwnershipOption | null>(ownership);

	const getOwnershipCriteria = () => {
		if (selectedOwnership === null) {
			return {};
		}
		const criteria: Api.IContactFilterCriteria = JSON.parse(
			JSON.stringify(EOwnershipOptionsMap[selectedOwnership].criteria)
		);

		const newUserId =
			selectedOwnership === EOwnershipOption.Me || selectedOwnership === EOwnershipOption.Connections
				? userSession.user?.id
				: selectedOwnership === EOwnershipOption.Owned
					? selectedUserId ?? ''
					: null;

		if (!!newUserId && criteria.value !== newUserId) {
			criteria.value = newUserId;
		}

		return criteria;
	};

	const refreshOwnership = (criteria: Api.IContactFilterCriteria[]) => {
		const { ownership: initialOwnership, userId: initialUserId } = getInitialValues(criteria);
		setSelectedOwnership(initialOwnership);
		setSelectedUserId(initialUserId);
	};

	return {
		findMatchingOwnership,
		getOwnershipCriteria,
		refreshOwnership,
		selectedOwnership,
		setSelectedOwnership,
		selectedUserId,
		setSelectedUserId,
	};
};
