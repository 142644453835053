import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const SocialMediaBannerGraphic: React.FC<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon
			className={`social-media-banner-graphic ${className || ''} ${css(...(styles || []))}`}
			height={40}
			width={76}
		>
			<rect width='76' height='40' rx='3' fill='#046CB6' />
			<mask id='social-media-banner-graphic-mask' maskUnits='userSpaceOnUse' x='0' y='0' width='276' height='40'>
				<rect width='276' height='40' rx='3' fill='white' />
			</mask>
			<g mask='url(#social-media-banner-graphic-mask)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M59.2346 20.9973C59.8961 21.0118 60.5681 20.9693 61.2447 20.8656C64.2592 20.4033 66.9667 18.7625 68.7714 16.3042C70.5761 13.8459 71.3302 10.7714 70.8679 7.75709C69.9053 1.48009 64.036 -2.82808 57.7586 -1.86547C51.9861 -0.980286 47.8785 4.05362 48.0036 9.73859C47.9893 10.3731 48.03 11.0176 48.1295 11.6666C48.9765 17.19 53.7945 21.1198 59.2346 20.9973ZM59.2346 20.9973C59.7084 20.9866 60.187 20.9452 60.668 20.8714C66.6721 19.9506 70.793 14.3365 69.8722 8.33201C69.4301 5.44855 67.8606 2.85885 65.5092 1.13261C63.1578 -0.593629 60.2169 -1.315 57.3337 -0.872803C51.9786 -0.0515151 48.1216 4.50326 48.0036 9.73859C48.0146 10.2359 48.0579 10.7381 48.1353 11.243C48.9943 16.8434 53.759 20.8765 59.2346 20.9973Z'
					fill='#D8F5FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M62.7471 39.997C63.2421 39.9858 63.742 39.9425 64.2446 39.8655C67.2591 39.4032 69.9665 37.7624 71.7712 35.3041C73.1776 33.3884 73.946 31.0986 73.9979 28.7546C74.0862 32.6507 72.0957 36.3504 68.7019 38.4072C66.8687 39.5182 64.8 40.0444 62.7471 39.997ZM62.7471 39.997C57.0589 40.1257 52.0208 36.0174 51.1352 30.2429C50.1725 23.9659 54.481 18.097 60.7584 17.1344C67.0358 16.1718 72.9051 20.48 73.8678 26.757C73.9698 27.4223 74.0126 28.0906 73.9979 28.7546C73.9872 28.2829 73.946 27.8083 73.8732 27.3334C72.9524 21.3289 67.3381 17.2078 61.3334 18.1286C56.936 18.8029 53.3777 22.0598 52.3179 26.3805C51.2581 30.7012 52.9055 35.2348 56.4918 37.8674C58.3501 39.2315 60.5395 39.946 62.7471 39.997Z'
					fill='#D8F5FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M58.5525 22.0181L68.197 23.4391C68.2149 23.441 68.2327 23.4434 68.2505 23.4463C68.7385 23.5266 69.069 23.9873 68.9887 24.4752L67.5644 34.1406C67.5007 34.6709 67.0224 35.0513 66.4914 34.9942L56.8497 33.5736C56.3121 33.4787 55.9425 32.9799 56.0082 32.4379L57.4325 22.7726L57.4333 22.7684C57.5352 22.2522 58.0363 21.9162 58.5525 22.0181ZM58.0007 31.7284L59.3723 31.9999L60.0007 26.2714L58.6291 25.9999L58.0007 31.7284ZM59.8616 25.9901L59.8486 25.9881C59.304 25.9043 58.9299 25.3958 59.012 24.851C59.094 24.3062 59.6012 23.9305 60.1463 24.0107C60.6913 24.0909 61.0688 24.5969 60.9904 25.1423C60.9119 25.6876 60.4072 26.0667 59.8616 25.9901ZM64.6829 32.7515L66.4751 32.9999L66.955 29.9379C67.2013 28.3654 66.4257 27.5127 65.2493 27.3496C64.5878 27.2421 63.9167 27.483 63.4996 27.9777L63.6138 27.2483L61.8217 26.9999L61.0007 32.2411L62.7928 32.4895L63.2605 29.5042C63.2757 29.3629 63.3187 29.2254 63.3873 29.0989C63.5802 28.7478 63.9876 28.5495 64.4037 28.604C65.0521 28.6939 65.2395 29.1991 65.1319 29.8857L64.6829 32.7515Z'
					fill='#D8F5FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M66.5672 8.86733C65.97 4.97769 62.4322 2.23639 58.5167 2.62923C54.6012 3.02207 51.6786 6.41155 51.8659 10.3423C52.0533 14.273 55.2852 17.3691 59.2203 17.3877L58.4367 12.2783L56.5796 12.5631L56.2554 10.4487L58.1125 10.1639L57.8654 8.5526C57.5844 6.72028 58.521 5.53989 60.191 5.28377C60.7406 5.2073 61.2951 5.17118 61.85 5.1757L62.126 6.97537L61.2035 7.11684C60.2957 7.25606 60.0981 7.86305 60.1869 8.44215L60.3972 9.81386L62.4251 9.50288L62.4255 11.6669L60.7215 11.9282L61.5051 17.038C64.9724 15.9302 67.119 12.4652 66.5672 8.86732L66.5672 8.86733Z'
					fill='#D8F5FF'
				/>
				<circle cx='23.031' cy='6.41818' r='5.35714' fill='#FFB6B6' />
				<path
					d='M16.4123 46.4693L16.3837 46.4288C14.859 44.2706 17.1522 39.0844 17.498 38.3325V38.3325C16.6511 38.0547 16.1162 37.2108 16.2202 36.3167L16.3693 35.0353L16.5247 33.7784L15.4113 32.6527L15.4082 32.6246L14.7675 26.9562L13.8359 23.1912C13.1212 20.2933 14.0082 17.231 16.1563 15.1808L18.3097 14.8351L20.9267 12.2856H25.1471L26.5941 13.6835C29.4391 16.0413 30.1145 15.017 33.3225 19.4555L31.0255 25.5741L32.3094 34.5984L32.3102 36.2315L32.9789 36.9829C33.4673 37.5317 33.6707 38.2815 33.5275 39.0055L33.4396 39.45V39.45C34.1292 39.7488 34.6326 40.3674 34.7899 41.1092L35.0247 42.2172C35.2257 42.4853 36.552 44.2733 36.552 44.7621C36.552 44.8355 36.4984 44.9064 36.3834 44.9851C35.4795 45.6039 30.4838 46.8655 28.2727 45.9709C25.9175 45.0188 16.5552 46.4472 16.4609 46.4617L16.4123 46.4693Z'
					fill='#3F3D56'
				/>
				<path
					d='M13.5928 24.8865L18.0058 24.0205C19.6378 26.4638 20.514 33.1447 20.514 33.1447L25.393 43.2882C26.2226 43.7934 26.5661 44.8257 26.2054 45.729C25.8446 46.6322 24.8853 47.1416 23.9372 46.9333C22.9891 46.725 22.3302 45.8601 22.3794 44.8883L16.0159 32.8702L13.5928 24.8865Z'
					fill='#FFB6B6'
				/>
				<path
					d='M13.2084 25.961L13.082 19.5102L15.1734 16.7777C16.015 15.6864 17.4178 15.1561 18.7924 15.4097V15.4097C20.6854 15.7718 21.9677 17.504 21.7264 19.3731C21.3714 22.1046 20.5655 25.5229 18.7422 26.2963L18.7237 26.3042L20.6134 32.7585L13.6057 33.7145L13.2084 25.961Z'
					fill='#3F3D56'
				/>
				<path
					d='M41.5099 20.624C41.5099 20.624 34.9162 19.3172 32.0757 20.0394L31.4492 24.3507L39.4921 25.0409L53.22 22.6282C54.1222 22.9731 55.1501 22.638 55.6521 21.8353C56.1541 21.0326 55.9836 19.9968 55.2486 19.3845C54.5137 18.7722 53.429 18.7622 52.6821 19.3608L41.5099 20.624Z'
					fill='#FFB6B6'
				/>
				<path
					d='M33.836 25.457L27.3582 24.7593L24.8864 22.4142C23.9 21.4715 23.5468 20.0637 23.9752 18.7823V18.7823C24.578 17.0194 26.4758 16.0139 28.3175 16.4816C31.0086 17.1674 33.7015 16.5277 34.2449 18.3681L35.2148 18.0581L41.5843 19.2434L41.653 26.0611L33.836 25.457Z'
					fill='#3F3D56'
				/>
				<path
					d='M17.2764 2.63538C17.8783 1.71311 19.0078 1.09844 20.1028 1.27116C20.3106 -0.139636 21.2938 -1.3187 22.6533 -1.78742C24.0128 -2.25614 25.5227 -1.93662 26.5701 -0.958555C26.9138 -1.19822 27.359 -1.24235 27.7439 -1.0749C28.1207 -0.905186 28.4486 -0.644536 28.6975 -0.316823C29.9777 1.26437 30.2763 3.41468 29.4747 5.27948C29.6085 4.79764 29.1209 4.34435 28.6283 4.23622C28.1357 4.1281 27.619 4.23039 27.1194 4.15998C26.4792 4.06975 25.9036 3.70189 25.2624 3.61911C24.7186 3.58408 24.1731 3.65351 23.6559 3.82356C23.1388 3.9936 22.5941 4.06604 22.0501 4.03714C21.5103 3.97368 20.8151 5.99943 20.8244 7.42625C20.8262 7.69664 20.77 8.0438 20.5032 8.10116C20.1746 8.17182 19.9842 7.72269 19.6715 7.60031C19.3719 7.51527 19.0581 7.68052 18.9618 7.97406C18.8818 8.26536 18.9489 8.57699 19.1419 8.81066C19.3341 9.03897 19.5623 9.23504 19.8177 9.39127L19.6889 9.49737C19.4367 9.76126 19.0311 9.81117 18.7214 9.61641C18.4261 9.41833 18.187 9.14865 18.0268 8.83307C17.4232 7.9086 16.9917 6.88452 16.7527 5.80934C16.517 4.7238 16.7041 3.59016 17.2764 2.63538V2.63538Z'
					fill='#EEEEED'
				/>
			</g>
		</SvgIcon>
	);
};
