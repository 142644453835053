import { StyleSheet } from 'aphrodite';
import * as Colors from '../../../styles/colors';
import { BreakPoints, CssSelectors, Layouts } from '../../../styles/styles';

const fadeInKeyframes = {
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
};

export const styleSheet = StyleSheet.create({
	body: {
		...Layouts.horizontalStack(30),
		borderBottom: `1px solid ${Colors.nameCircles}`,
		height: 300,
		padding: '20px 0 10px 0',
	},
	campaignList: {
		minHeight: '100%',
	},
	campaignListContainer: {
		flexGrow: 1,
		height: '100%',
		overflowY: 'auto',
		position: 'relative',
		width: 250,
	},
	container: {
		textAlign: 'center',
	},
	content: {
		margin: '0 auto',
		maxWidth: 700,
		width: '60%',
		[BreakPoints.tabletWidth]: {
			width: '90%',
		},
	},
	dayPicker: {
		flexShrink: 0,
		margin: 'auto',
	},
	dayPickerDateHasCompletedCampaign: {
		color: Colors.success,
	},
	dayPickerDateHasQueuedCampaign: {
		color: Colors.brandSecondary,
	},
	dayPickerDateSelected: {
		background: Colors.brandPrimary,
		borderRadius: '50%',
		color: Colors.white,
	},
	dayPickerDateWithCampaign: {
		overflow: 'visible',
		position: 'relative',
	},
	dayPickerDateWithCampaignIndicator: {
		animationDuration: '150ms',
		animationIterationCount: 1,
		animationName: [fadeInKeyframes],
		animationTimingFunction: 'ease-in',
		background: Colors.black,
		borderRadius: '50%',
		display: 'inline-block',
		height: 6,
		overflow: 'visible',
		position: 'absolute',
		right: 0,
		top: 0,
		width: 6,
	},
	dayPickerPopover: {
		padding: 10,
		width: 300,
	},
	dayPickerPopoverAnchor: {
		pointerEvents: 'all',
		position: 'relative',
	},
	dayPickerPopoverContent: {
		color: Colors.grayIconFill,
		fontSize: 12,
		margin: 'auto',
		padding: 10,
		...CssSelectors.allChildren(
			{
				fontSize: 12,
			},
			'span'
		),
	},
	expirationWarning: {
		alignItems: 'center',
		background: Colors.warningBackground,
		border: `1px solid ${Colors.destructive}`,
		borderRadius: 20,
		color: Colors.label,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		marginBottom: 20,
		padding: '10px 10px',
	},
	footer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '94%',
		padding: '50px 0 36px 0',
	},
	footerInputs: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginLeft: 'auto',
		marginRight: 'auto',
		position: 'relative',
	},
	footerInputsInner: {
		flexGrow: 1,
		maxWidth: 600,
		...CssSelectors.allChildren(
			{
				justifyContent: 'flex-start',
			},
			'*:first-child'
		),
	},
	footerWithWarning: {
		padding: '30px 0 36px 0',
	},
	header: {
		alignItems: 'center',
		borderBottom: `1px solid ${Colors.nameCircles}`,
		boxSizing: 'border-box',
		color: Colors.titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'center',
		padding: '20px 0 30px 0',
	},
	headerDescription: {
		color: Colors.label,
		fontSize: 14,
		marginTop: 5,
	},
	headerText: {
		marginLeft: 30,
		textAlign: 'left',
	},
	scheduleCta: {
		flexGrow: 1,
	},
	scheduleTypeDropDown: {
		...CssSelectors.allChildren(
			{
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	scheduleTypeDropDownOption: {
		fontSize: 14,
		textAlign: 'left',
	},
	scheduleTypeDropDownTrigger: {
		fontSize: 14,
		height: 'auto',
		width: 160,
	},
	selectedDayStringRep: {
		color: Colors.label,
		fontSize: 14,
		marginTop: 10,
		textAlign: 'left',
		width: 160,
	},
	sendWaitTime: {
		background: 'none',
		border: 'none',
		margin: '0 auto',
		textAlign: 'left',
	},
	timeDropdown: {
		...CssSelectors.allChildren(
			{
				maxWidth: 150,
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	timeDropdownOption: {
		fontSize: 14,
	},
	timeDropdownPlaceholder: {
		color: Colors.navigation,
	},
	timeDropdownTrigger: {
		fontSize: 14,
		height: 'auto',
		minWidth: 108,
	},
});
