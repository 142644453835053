import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as Api from '../../../extViewmodels';
import { useErrorMessages, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { useIsMounted } from '../../../models/hooks/useIsMounted';
import { baseStyleSheet } from '../../styles/styles';
import { Modal } from '../Modal';
import { RadioButton } from '../RadioButton';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../autocomplete/SimpleAutoCompleteSearchField';
import { WarningIcon } from '../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

interface IProps {
	account?: Api.IAccount;
	className?: string;
	onClose(deactivate?: boolean): void;
	styles?: StyleDeclarationValue[];
	userId?: string;
}

enum DeactivateUserCheckbox {
	ArchiveContacts = 'archive-contacts-checkbox',
	ReassignContacts = 'reassign-contacts-checkbox',
}

export const DeactivateUserModal: React.FC<IProps> = observer(props => {
	const { account, className, styles = [], onClose, userId } = props;
	// @ts-ignore
	const [checked, setChecked] = React.useState<DeactivateUserCheckbox>(null);
	// @ts-ignore
	const [selectedUser, setSelectedUser] = React.useState<Api.IUser>(null);
	// @ts-ignore
	const [systemJobVM, setSystemJobVM] = React.useState<Api.SystemJobViewModel>(null);
	// @ts-ignore
	const [contactsVM, setContactsVM] = React.useState<Api.ContactsViewModel>(null);
	const [isMainContact, setIsMainContact] = React.useState(false);

	const isMounted = useIsMounted();
	const userSession = useUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();

	const onArchiveContactsChecked = () => {
		setChecked(DeactivateUserCheckbox.ArchiveContacts);
		clearSelectedUser();
	};
	const onReassignContactsChecked = () => setChecked(DeactivateUserCheckbox.ReassignContacts);

	const filter: Api.IContactFilterCriteria = {
		criteria: [{ property: Api.ContactFilterCriteriaProperty.OwnedBy, value: userId }],
	};

	React.useEffect(() => {
		const contacts = new Api.ContactsViewModel(userSession);
		if (account) {
			contacts.impersonate({ account: { id: account.id } });
		}
		contacts.getContacts({ filter });
		const mainContactCheck = account?.additionalInfo?.mainContactUserId === userId;
		setIsMainContact(mainContactCheck);
		setContactsVM(contacts);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMainContact]);

	const continueWatching = (opResult: Api.IOperationResult<Api.ISystemJob>) => {
		if (!opResult.success || !isMounted) {
			return false;
		}

		// @ts-ignore
		// @ts-ignore
		if (opResult.value.percentComplete >= 100) {
			// @ts-ignore
			toaster.push({
				message: 'Contact owner assigned successfully!',
				type: 'successMessage',
			});
			onClose(true);
			return false;
		}
		return true;
	};

	const onDeactivate = async () => {
		if (!isDeactivateEnabled()) {
			return;
		}
		if (checked === DeactivateUserCheckbox.ArchiveContacts) {
			try {
				await onArchive();
			} catch (error) {
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(error);
				return;
			}
			onClose(true);
		} else if (checked === DeactivateUserCheckbox.ReassignContacts) {
			onReassign();
		} else {
			onClose(true);
		}
	};

	const onArchive = () => {
		if (!systemJobVM) {
			return contactsVM.deleteMany();
		}
	};

	const onReassign = () => {
		if (!systemJobVM && !!selectedUser) {
			const request: Api.IContactsAssignOwnerRequest = {
				filter,
				// @ts-ignore
				newOwnerId: selectedUser.id,
			};
			const promise = contactsVM.assignOwner(userSession, request);
			if (promise) {
				promise
					.then(systemJob => {
						if (isMounted) {
							const sysJob = new Api.SystemJobViewModel(userSession, systemJob);
							sysJob.startWatching(continueWatching);
							setSystemJobVM(sysJob);
						}
					})
					.catch((error: Api.IOperationResultNoValue) => {
						// @ts-ignore
						errorMessages.pushApiError(error);
					});
			}
		}
	};

	const onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(Api.VmUtils.getDisplayName(e.selection));
		}
		setSelectedUser(e.selection);
	};

	const clearSelectedUser = () => {
		if (selectedUser) {
			// @ts-ignore
			setSelectedUser(null);
		}
	};

	const onSearchFieldBlur = (e: ISimpleAutoCompleteSearchFieldEvent<React.FocusEvent<HTMLInputElement>>) => {
		if (!selectedUser && !!e.target) {
			e.target.clearInput();
		}
	};

	const onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (!!e.sourceEvent && e.sourceEvent.keyCode !== 13) {
			clearSelectedUser();
		}
	};

	const isDeactivateEnabled = React.useCallback(() => {
		const NO_RADIO_OPTION_SELECTED =
			checked !== DeactivateUserCheckbox.ArchiveContacts &&
			checked !== DeactivateUserCheckbox.ReassignContacts &&
			contactsVM?.totalNumberOfResults > 0;
		return checked === DeactivateUserCheckbox.ReassignContacts
			? !!selectedUser && userId !== selectedUser && !!contactsVM?.fetchResults?.length
			: NO_RADIO_OPTION_SELECTED
				? false
				: true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser, checked, contactsVM]);

	const onCancel = () => onClose(false);

	return (
		<Modal
			className={`${css(styleSheet.container, ...styles)} deactivate-user-modal ${className || ''}`}
			isOpen={!!userId}
			onRequestClose={onClose}
			useDefaultHeader={true}
		>
			{!isMainContact ? (
				<div className={css(styleSheet.content)}>
					<WarningIcon />
					<div className={css(styleSheet.title)}>Deactivate User</div>
					{contactsVM?.totalNumberOfResults > 0 && (
						<>
							<div>
								{'This user is associated with '}
								<span className={css(styleSheet.numberText)}>{contactsVM?.totalNumberOfResults}</span>
								{' unique contacts. Would you like to archive their contacts or assign them to another user?'}
							</div>
							<div className={css(styleSheet.checkboxContainer)}>
								<RadioButton
									id={DeactivateUserCheckbox.ArchiveContacts}
									type='small'
									onChange={onArchiveContactsChecked}
									checked={checked === DeactivateUserCheckbox.ArchiveContacts}
								>
									<span className={css(styleSheet.checkboxText)}>Archive contacts</span>
								</RadioButton>
								<RadioButton
									id={DeactivateUserCheckbox.ReassignContacts}
									type='small'
									onChange={onReassignContactsChecked}
									checked={checked === DeactivateUserCheckbox.ReassignContacts}
								>
									<span className={css(styleSheet.checkboxText)}>Reassign contacts</span>
								</RadioButton>
							</div>
						</>
					)}
					{checked === DeactivateUserCheckbox.ReassignContacts && (
						<div className={css(styleSheet.searchContainer)}>
							<SimpleAutoCompleteSearchField
								onBlur={onSearchFieldBlur}
								onClear={clearSelectedUser}
								// @ts-ignore
								onItemSelected={onUserSelected}
								onKeyDown={onSearchFieldKeyDown}
								pageSize={5}
								placeholder='Search co-workers or add yourself'
								resultsLimit={5}
								type={Api.ResourceAutoCompleteViewModelType.User}
							/>
						</div>
					)}
					<div>Deactivating user will reset the password. Are you sure?</div>
					<div className={css(styleSheet.buttonContainer)}>
						<button
							className={css(styleSheet.button, baseStyleSheet.ctaButtonDestructiveSolid)}
							onClick={onDeactivate}
							disabled={!isDeactivateEnabled()}
						>
							<span>Deactivate</span>
						</button>
						<button className={css(styleSheet.button, baseStyleSheet.ctaButtonReverse)} onClick={onCancel}>
							<span>Cancel</span>
						</button>
					</div>
				</div>
			) : (
				<div className={css(styleSheet.content)}>
					<WarningIcon />
					<div className={css(styleSheet.title)}>Deactivate User</div>
					<div className={css(styleSheet.warningText)}>
						This user is currently the main contact for the account. Assign a different user as the main contact before
						deactivating.
					</div>
				</div>
			)}
		</Modal>
	);
});
