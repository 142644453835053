import { StyleSheet } from 'aphrodite';
import {
	brandPrimary,
	brandPrimaryHover,
	brandPrimaryText,
	brandSecondary,
	campaignTintColor,
	darkGrayFontColor,
	mention,
	mentionDark,
	navigation,
	titles,
} from '../../../styles/colors';
import { BreakPoints, CssSelectors, Layouts, baseStyles } from '../../../styles/styles';

const contextContainerWidth = 360;
const columnSpacing = 30;
const readonlyTemplatePadding = 30;
const readonlyTemplateBottomMargin = 20;
const templateContentHeaderHeight = 70;
const bodyEditorFooterHeight = 50;
const emailContentActionsHeaderHeight = 42;

export const styleSheet = StyleSheet.create({
	aiAssistantButton: {
		marginTop: 12,
	},
	aiContentGenerationWizard: {
		height: '100%',
		position: 'absolute',
		right: 0,
		top: 0,
		width: contextContainerWidth + columnSpacing,
	},
	attachmentContainer: {
		marginBottom: 30,
		marginTop: 30,
		padding: `0 ${readonlyTemplatePadding}px`,
	},
	automationPreview: {
		boxSizing: 'border-box',
		overflowY: 'auto',
		padding: '0 30px 30px 30px',
		width: '100%',
	},
	automationPreviewActions: {
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 0px',
	},
	automationPreviewEditAutomationButton: {
		color: brandPrimary,
	},
	automationTemplateSelector: {
		maxWidth: '60%',
	},
	automationTemplateSelectorAutomationIcon: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 15,
		display: 'flex',
		flexShrink: 0,
		height: 30,
		justifyContent: 'center',
		width: 30,
		...CssSelectors.allChildren(
			{
				height: 15,
				width: 15,
			},
			'.svg-icon'
		),
	},
	automationTemplateSelectorOption: {
		color: brandPrimaryHover,
		fontSize: 24,
	},
	bannerText: {
		paddingLeft: 10,
	},
	bodyEditor: {
		flex: 1,
	},
	bodyEditorContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		marginTop: 12,
		padding: '0 20px 12px 20px',
		position: 'relative',
		...Layouts.verticalStack(20),
	},
	bodyEditorContainerWithTemplateSaveButton: {
		flex: 1,
	},
	bodyEditorFooter: {
		height: bodyEditorFooterHeight,
	},
	contactContext: {
		height: '100%',
	},
	container: {
		position: 'relative',
		...Layouts.horizontalStack(columnSpacing),
	},
	contentCreationTipsPopoverAnchor: {
		color: brandSecondary,
		fontSize: 14,
		paddingTop: 10,
		textDecoration: 'underline',
	},
	contentCreationTipsPopoverContent: {
		color: '#fff',
		fontSize: 14,
		fontStyle: 'italic',
		padding: 20,
		width: 400,
	},
	contentCreationTipsPopoverContentTitle: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 16,
		fontWeight: 600,
		marginBottom: 20,
		...CssSelectors.allChildren(
			{
				flexShrink: 0,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	contextContainer: {
		flexShrink: 0,
		height: '100%',
		overflow: 'hidden',
		width: contextContainerWidth,
		...CssSelectors.allChildren({
			height: '100%',
		}),
	},
	dropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
	},
	dropdownAnchorText: {
		maxWidth: 600,
	},
	dropdownIcon: {
		height: 16,
		marginLeft: 12,
		transform: 'rotate(90deg)',
		width: 10,
	},
	dropdownInline: {
		borderColor: '#CCCCCC',
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		padding: 10,
		width: '100%',
	},
	dropdownItem: {
		':hover': {
			background: '#E8F3F9',
		},
		cursor: 'pointer',
		padding: 10,
		...CssSelectors.allChildren({
			color: '#A7ABAD',
			fontSize: 12,
		}),
	},
	dropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		maxHeight: 300,
		overflowY: 'auto',
		width: '100% !important',
	},
	duplicateWarning: {
		maxWidth: 378,
	},
	emailComposerFooter: {
		color: darkGrayFontColor,
		fontSize: 12,
		fontStyle: 'italic',
		letterSpacing: '0',
		lineHeight: '22px',
		whiteSpace: 'pre',
	},
	emailContentActionsHeader: {
		boxSizing: 'border-box',
		height: emailContentActionsHeaderHeight,
	},
	emailContentContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		maxWidth: '100%',
	},
	emailHeaderContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		[BreakPoints.forMaxWidth(1240)]: {
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
	},
	emailHeaderContainerLeft: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 20,
	},
	emailHeaderContainerRight: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	expirationDate: {
		color: brandPrimaryText,
	},
	expirationDateContainer: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 14,
		marginTop: '10px',
	},
	expirationDateIcon: {
		display: 'inline-block',
		marginRight: 4,
	},
	followUpRecipients: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: 0,
	},
	hbdCard: {
		alignItems: 'center',
		backgroundColor: '#F9F1FF',
		borderRadius: '0.5rem',
		cursor: 'pointer',
		display: 'flex',
		height: 90,
		overflow: 'hidden',
		width: 180,
	},
	hbdCardGraphic: {
		display: 'block',
		marginLeft: '-0.25rem',
		marginRight: '0.5rem',
	},
	hbdCardText: {
		color: campaignTintColor,
		fontSize: '14px',
		margin: 0,
		padding: 0,
	},
	isDuplicateModalContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allDescendants(
			{
				color: 'rgba(13,15,17,1)',
				fontSize: 14,
				fontStyle: 'normal',
				fontWeight: 400,
				letterSpacing: 0,
				lineHeight: '16px',
				margin: '0 0 5px',
				textAlign: 'center',
			},
			'p'
		),
	},
	keyDateMessage: {
		color: '#65319D',
		fontSize: 14,
		paddingLeft: 10,
	},
	leftContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: `calc(100% - ${contextContainerWidth + columnSpacing}px)`,
	},
	maxBannerWidth: {
		maxWidth: '100%',
	},
	modalBodyText: {
		color: '#4A4A4A',
		fontSize: 14,
		lineHeight: '18px',
		marginTop: 10,
	},
	nameContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	optionButton: {
		color: brandPrimaryHover,
		flexShrink: 0,
		fontSize: 14,
	},
	optionsContainer: {
		...Layouts.horizontalStack(30),
	},
	reachOutTagsContent: {
		...Layouts.verticalStack(15),
	},
	reachOutTagsContentTag: {
		cursor: 'pointer',
	},
	readonlyTemplateContent: {
		height: '100%',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				padding: 0,
			},
			'.rich-content-document-editor-readonly'
		),
	},
	readonlyTemplateContentBody: {
		padding: `0 ${readonlyTemplatePadding}px`,
	},
	readonlyTemplateContentFooter: {
		marginTop: 0,
		padding: `0 ${readonlyTemplatePadding}px`,
	},
	readonlyTemplateContentFooterUnsubscribe: {
		marginTop: 30,
	},
	readonlyTemplateContentHeader: {
		alignItems: 'flex-start',
		boxSizing: 'border-box',
		color: navigation,
		display: 'flex',
		fontSize: 14,
		height: templateContentHeaderHeight,
		justifyContent: 'space-between',
		marginBottom: readonlyTemplateBottomMargin,
		padding: `12px ${readonlyTemplatePadding}px 0 ${readonlyTemplatePadding}px`,
	},
	readonlyTemplateContentScroller: {
		boxSizing: 'border-box',
		height: `calc(100% - ${templateContentHeaderHeight + readonlyTemplateBottomMargin}px)`,
		overflowY: 'auto',
		paddingTop: 5,
	},
	readonlyTemplateContentWithActionsHeader: {
		height: `calc(100% - ${emailContentActionsHeaderHeight}px)`,
	},
	readonlyTemplateEditor: {
		color: titles,
	},
	recipients: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		width: contextContainerWidth,
	},
	recipientsApproval: {
		height: `calc(100% - 70px)`,
	},
	recipientsApprovalFooter: {
		display: 'flex',
		justifyContent: 'center',
		left: 0,
		marginTop: 12,
		position: 'absolute',
		top: '100%',
		width: '100%',
	},
	recipientsFooter: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		paddingTop: '0.5rem',
		...CssSelectors.allChildren({
			display: 'block',
			width: '100%',
		}),
	},
	recipientsList: {
		flex: 1,
		overflow: 'auto',
	},
	searchEmployeeBox: {
		fontSize: 14,
		marginTop: 16,
	},
	searchEmployeeBoxInput: {
		marginLeft: 10,
	},
	selectAnEmployeeCTA: {
		marginTop: 100,
	},
	selectAnEmployeeModal: {
		maxWidth: 360,
	},
	selectedContactHeader: {
		...Layouts.horizontalStack(15),
		color: titles,
		fontSize: 18,
		marginTop: 10,
	},
	selectedContactHeaderAvatar: {
		height: 40,
		width: 40,
	},
	selectedContactHeaderDetails: {
		width: 'calc(100% -55px)',
	},
	selectedContactHeaderDetailsMessage: {
		color: navigation,
		fontSize: 12,
	},
	sendFromAnchorText: {
		display: 'inline-block',
		padding: `11px 10px 10px`,
	},
	sendFromDropdown: {
		marginLeft: 8,
		maxWidth: 260,
		width: '80%',
	},
	sendFromDropdownContainer: {
		alignItems: 'center',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 14,
		width: '100%',
	},
	sendFromDropdownContainerWithAutomationsBanner: {
		marginTop: 10,
	},
	socialMediaBannerContainer: {
		background: '#046cb6',
		borderRadius: 3,
		display: 'flex',
		height: 40,
		maxWidth: 470,
		...CssSelectors.allChildren({
			alignItems: 'center',
			color: 'white',
			display: 'flex',
			fontSize: 14,
			justifyContent: 'space-between',
			paddingRight: 10,
		}),
	},
	startAutomationBanner: {
		background: '#FFFFFF',
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		flex: 1,
		flexGrow: 1.5,
	},
	startAutomationBannerContent: {
		background: mention,
		borderRadius: 8,
		height: 100,
		maxWidth: 480,
		overflow: 'hidden',
		...Layouts.horizontalStack(20),
		...CssSelectors.allChildren(
			{
				flexShrink: 0,
				height: 110,
				marginLeft: '-25px',
				width: 110,
				[BreakPoints.forMaxWidth(1120)]: {
					display: 'none',
				},
			},
			':nth-child(1)'
		),
		...CssSelectors.allChildren(
			{
				marginRight: 20,
				...Layouts.verticalStack(1),
				...CssSelectors.allChildren(
					{
						color: brandPrimaryHover,
						fontSize: 16,
						[BreakPoints.forMaxWidth(1220)]: {
							fontSize: 14,
						},
					},
					':nth-child(1)'
				),
				...CssSelectors.allChildren(
					{
						color: '#4A4A4A',
						fontSize: 14,
						marginRight: 10,
						[BreakPoints.forMaxWidth(1024)]: {
							display: 'none',
						},
						[BreakPoints.forMaxWidth(1220)]: {
							fontSize: '13px',
						},
					},
					':nth-child(2)'
				),
			},
			':nth-child(2)'
		),
	},
	subject: {
		color: titles,
		fontSize: 16,
		marginRight: 30,
		marginTop: 10,
	},
	tags: {
		height: '100%',
		position: 'relative',
	},
	tagsContent: {
		boxSizing: 'border-box',
		height: `calc(100% - ${126}px)`,
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: `0 0 120px 0`,
	},
	tagsCtaGraphic: {
		position: 'absolute',
		right: 0,
		top: 0,
	},
	tagsFooter: {
		alignItems: 'center',
		display: 'flex',
		height: 50,
		marginTop: 20,
		...CssSelectors.allChildren({
			width: '100%',
		}),
	},
	tagsHeader: {
		color: titles,
		fontSize: 18,
		height: 30,
		marginBottom: 26,
	},
	templateContainer: {
		background: '#fff',
		border: '1px solid #CAE0EC',
		borderRadius: 3,
		boxShadow: '0 0 12px 0 #E8F3F9',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'space-between',
		maxHeight: '100%',
		width: '100%',
	},
	templateContainerHeader: {
		borderBottom: `1px solid ${mentionDark}`,
		boxSizing: 'border-box',
		color: brandPrimaryText,
		fontSize: 24,
		padding: '12px 20px',
		width: '100%',
	},
	templateContainerHeaderNewEmailMessage: {
		color: navigation,
		fontSize: 12,
		marginTop: 10,
	},
	templateContainerHeaderRow: {
		':not(:first-child)': {
			marginTop: 8,
		},
		display: 'flex',
		justifyContent: 'space-between',
		[BreakPoints.forMaxWidth(1100)]: {
			display: 'block',
		},
		...CssSelectors.allChildren({
			flex: 1,
		}),
		...CssSelectors.allChildren(
			{
				display: 'flex',
				justifyContent: 'flex-end',
				marginLeft: 10,
				maxWidth: '45%',
				[BreakPoints.forMaxWidth(1100)]: {
					justifyContent: 'flex-start',
					marginLeft: 0,
					marginTop: 10,
					maxWidth: '100%',
				},
			},
			':not(:first-child)'
		),
	},
	templateContentContainer: {
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
	},
	templateTypeTag: {
		flexShrink: 0,
		marginLeft: 20,
	},
	title: {
		color: brandSecondary,
		fontSize: 22,
		fontWeight: 600,
		maxWidth: '80%',
	},
	translationgLoadingSpinner: {
		...baseStyles.absoluteCenter,
		transform: 'translate(-50%, calc(-50% - 64px))',
	},
});
