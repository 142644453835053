import { NavIcon } from '../../svgs/icons/NavIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export interface INavBarLink {
	icon?: React.ReactNode | ((isActive: boolean) => React.ReactNode);
	linkName?: string;
	/** Accepts either a path as a string or a list of paths as strings the first one will be used as the link path */
	path?: string | string[];
}

interface IProps extends INavBarLink {
	accessory?: JSX.Element;
	className?: string;
	compactLayout?: boolean;
	onLinkClick?(e: React.MouseEvent<HTMLElement>): void;
}

export function NavBarLink({ accessory, className, compactLayout, onLinkClick, linkName, path, icon }: IProps) {
	const location = useLocation();
	const [isMouseOver, setIsMouseOver] = React.useState(false);

	const getActiveStatus = (paths: string | string[], pathname: string): boolean => {
		const isActivePath = (pathToCheck: string) => {
			return new RegExp(`^${pathToCheck}`).test(pathname);
		};
		return typeof paths === 'object' ? paths.some(x => isActivePath(x)) : isActivePath(paths);
	};
	// @ts-ignore
	const isRouteActive = getActiveStatus(path, location.pathname);
	const isActive = isMouseOver || isRouteActive;
	const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (onLinkClick) {
			onLinkClick(e);
		}
	};
	return (
		<NavLink
			isActive={(_match, _location) => {
				// @ts-ignore
				return getActiveStatus(path, _location.pathname);
			}}
			activeClassName={`${css(styleSheet.linkActive)}`}
			className={`${css(styleSheet.link, compactLayout && styleSheet.linkCompact)} ${className || ''}`}
			location={location}
			onClick={handleLinkClick}
			onMouseEnter={() => setIsMouseOver(true)}
			onMouseLeave={() => setIsMouseOver(false)}
			title={linkName ? linkName : undefined}
			// @ts-ignore
			to={typeof path === 'object' ? path[0] : path}
		>
			{!!linkName && (
				<div className={css(styleSheet.icon)}>
					{icon ? (
						typeof icon === 'function' ? (
							icon(isActive)
						) : (
							icon
						)
					) : (
						<NavIcon isActive={isActive} iconName={linkName} fillColor='white' />
					)}
				</div>
			)}
			{!compactLayout && <span className={css(styleSheet.linkName)}>{linkName}</span>}
			{!!accessory && <div className={css(compactLayout ? styleSheet.compactFlag : styleSheet.flag)}>{accessory}</div>}
		</NavLink>
	);
}
