import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const AnalysisIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`analysis-icon ${className || ''}`} height={16} width={20}>
			<polygon
				fill={fillColor || navigation}
				fillRule='evenodd'
				points='10.056 15.8 5.843 4.847 3.91 10 0 10 0 8.334 2.756 8.334 5.824 .153 9.944 10.866 12.345 3.661 14.682 8.334 20 8.334 20 10 13.651 10 12.655 8.005'
			/>
		</SvgIcon>
	);
};
