import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import equal from 'fast-deep-equal/react';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import {
	DefaultEditableActionItemContentCSS,
	IImpersonationContextComponentProps,
	ImpersonationContextKey,
} from '../../../../models';
import { AutomationStepAction } from '../../../../models/Automations';
import { useEventLogging } from '../../../../models/Logging';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultRichContentForTextingStep,
	getDisplayName,
	getRichContentMessageForTextingStep,
} from '../../../../models/UiUtils';
import { useEnvironment, useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useAutomationTemplateStepDeleteMutation,
	useAutomationTemplateStepHwcCreateMutation,
	useUserQuery,
} from '../../../../queries';
import { IConnectionTypeComponentProps, withConnectionTypes } from '../../../containers/templates/EditCampaign/context';
import { titles } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { Collapsible } from '../../Collapsible';
import { InputFieldError } from '../../InputFieldError';
import { MoreMenu, MoreMenuItem } from '../../MoreMenu';
import { ISelectOption, Select } from '../../Select';
import {
	SelectAnEmployeeExternalEmailBody,
	SelectAnEmployeeInternalEmailBody,
	SelectAnEmployeeModal,
} from '../../SelectAnEmployee';
import { DefaultSelectBox, ISelectBoxOption } from '../../SelectBox';
import { TextInput } from '../../TextInput';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../autocomplete/SimpleAutoCompleteSearchField';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../richContent/RichContentDocumentEditor';
import { AutomationStepIcon } from '../../svgs/icons/AutomationStepIcon';
import { AutomationTagAutocomplete } from '../AutomationTriggerCard/presentation';
import { EditAutomationStepEmailComposerModal } from '../EditAutomationStepEmailComposerModal';
import { HwcAutomationStepCardContent } from './components/HwcAutomationStepCardContent';
import {
	AdvancedOptionsButton,
	AutomationStepCardSchedule,
	AutomationStepCardTextingInput,
	EditAutomationTemplateStepCardHeader,
} from './presentation';
import { styleSheet } from './styles';
const AutomationStepSelectionBoxOption = ({ children }: { children: React.ReactNode }) => {
	return <div className={css(bs.flex, bs.itemsCenter, bs.gap2, bs.textTitles, bs.textSm)}>{children}</div>;
};

const StepTypeOptions = [
	{
		title: () => (
			<AutomationStepSelectionBoxOption>
				<AutomationStepIcon type='EmailAutomationStep' />
				<span>Send an email</span>
			</AutomationStepSelectionBoxOption>
		),
		value: Api.AutomationStepType.Email,
	},
	{
		title: () => (
			<AutomationStepSelectionBoxOption>
				<AutomationStepIcon type='TextingAutomationStep' />
				<span>Send a Text</span>
			</AutomationStepSelectionBoxOption>
		),
		value: Api.AutomationStepType.Texting,
	},
	{
		title: () => (
			<AutomationStepSelectionBoxOption>
				<AutomationStepIcon type='ActionItemAutomationStep' />
				<span>Create an action item</span>
			</AutomationStepSelectionBoxOption>
		),
		value: Api.AutomationStepType.ActionItem,
	},
	{
		title: () => (
			<AutomationStepSelectionBoxOption>
				<AutomationStepIcon type='AddTagAutomationStep' />
				<span>Add a tag</span>
			</AutomationStepSelectionBoxOption>
		),
		value: Api.AutomationStepType.AddTag,
	},
	{
		title: () => (
			<AutomationStepSelectionBoxOption>
				<AutomationStepIcon type='RemoveTagAutomationStep' />
				<span>Delete a tag</span>
			</AutomationStepSelectionBoxOption>
		),
		value: Api.AutomationStepType.RemoveTag,
	},
	{
		title: () => (
			<AutomationStepSelectionBoxOption>
				<AutomationStepIcon type='HandwrittenCardAutomationStep' />
				<span>Send a card</span>
			</AutomationStepSelectionBoxOption>
		),
		value: Api.AutomationStepType.HandwrittenCard,
	},
	{
		title: () => (
			<AutomationStepSelectionBoxOption>
				<AutomationStepIcon type='NoActionAutomationStep' />
				<span>Do Nothing</span>
			</AutomationStepSelectionBoxOption>
		),
		value: Api.AutomationStepType.NoAction,
	},
];

const DefaultActionItemEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentRawCss: DefaultEditableActionItemContentCSS.replace(/color:[ ]?[#]?[a-z0-9]*;/i, `color: ${titles};`),
	minHeight: 14,
	plugins: [], // none
	toolbar: false,
};

interface Props extends IImpersonationContextComponentProps, IConnectionTypeComponentProps {
	index: number;
	onMenuItemClicked?(action: AutomationStepAction): void;
	onRenderTitle?(): React.ReactNode;
	readonly?: boolean;
	steps: Api.AutomationTemplateEditorStep[];
	template: Api.AutomationTemplateViewModel;
}

function _AutomationStepCard({
	steps,
	index,
	template,
	readonly,
	onMenuItemClicked,
	onRenderTitle,
	impersonationContext,
	connectionTypes,
}: Props) {
	const { logApiError, logEvent } = useEventLogging('AutomationStepCard');
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const environment = useEnvironment();

	const trigger =
		template?.parentTemplate?.draftTriggerReference ||
		template?.parentTemplate?.publishedTriggerReference ||
		template?.draftTriggerReference ||
		template?.publishedTriggerReference;
	const step = steps[index];
	const [schedule, setSchedule] = React.useState<Api.IAutomationStepSchedule>(() => {
		const automationStep = step?.automationStep;
		if (automationStep?.type) {
			return automationStep?.schedule;
		}
		const criteria =
			trigger?._type === Api.AutomationTriggerType.Meeting ||
			trigger?.resourceSelector?.toLowerCase()?.includes('custom')
				? Api.AutomationStepScheduleCriteria.RelativeToAnchor
				: Api.AutomationStepScheduleCriteria.AfterAutomationStart;
		return {
			criteria: automationStep?.schedule?.criteria || criteria,
			numberOfDays: automationStep?.schedule?.numberOfDays || 0,
			numberOfHours: automationStep?.schedule?.numberOfHours || undefined,
			numberOfMinutes: automationStep?.schedule?.numberOfMinutes || undefined,
		};
	});
	const [actionItemContentEditorState, setActionItemContentEditorState] =
		React.useState<Api.IRichContentEditorState | null>(() => {
			if (step.automationStep?.type === Api.AutomationStepType.ActionItem) {
				return convertRawRichTextContentStateToRichContentEditorState(
					(step.automationStep as Api.ActionItemAutomationStepViewModel).content
				);
			}
			return null;
		});
	const [selectedTag, setSelectedTag] = React.useState<string | null>(
		step.automationStep?.type === Api.AutomationStepType.RemoveTag ||
			step.automationStep?.type === Api.AutomationStepType.AddTag
			? (step.automationStep.toJs() as Api.ITagAutomationStep).tagName
			: null
	);
	const [name, setName] = React.useState(step.automationStep?.type ? step.automationStep.name : null);
	const [isUpdating, setIsUpdating] = React.useState(false);
	const [tagToAddOnClick, setTagToAddOnClick] = React.useState(
		step.automationStep?.type === Api.AutomationStepType.Email
			? (step.automationStep as Api.EmailAutomationStepViewModel).tagToAddOnClick
			: null
	);
	const [sendFrom, setSendFrom] = React.useState(step.automationStep?.sendFromOptions);
	const [sendToSender, setSendToSender] = React.useState(
		step.automationStep?.type === Api.AutomationStepType.Email
			? !!(step.automationStep as Api.EmailAutomationStepViewModel).sendToSender
			: false
	);
	const [showEmailLinkClickTags, setShowEmailLinkClickTags] = React.useState(
		step.automationStep?.type === Api.AutomationStepType.Email
			? !!(step.automationStep as Api.EmailAutomationStepViewModel).tagToAddOnClick
			: false
	);
	const hasSendOptionSelected = () => {
		return (
			!!step.automationStep?.sendFromOptions &&
			step.automationStep.sendFromOptions.mode !== Api.SendEmailFrom.CurrentUser
		);
	};
	const [showEmailEditor, setShowEmailEditor] = React.useState(false);
	const [advancedOptionsToggled, setAdvancedOptionsToggled] = React.useState(() => {
		switch (step.automationStep?.type) {
			case Api.AutomationStepType.Email: {
				const emailStep = step.automationStep as Api.EmailAutomationStepViewModel;
				return !!emailStep.tagToAddOnClick || !!emailStep.sendToSender || hasSendOptionSelected();
			}
			default: {
				return hasSendOptionSelected();
			}
		}
	});
	const [showingSendFrom, setShowingSendFrom] = React.useState(() => {
		return hasSendOptionSelected() && !sendToSender;
	});
	const [selectAnEmployeeModalIsOpen, setSelectAnEmployeeModalIsOpen] = React.useState(false);
	const [selectedSendFromUser, setSelectedSendFromUser] = React.useState<Api.IUser | null>();
	const onSaveSelectAnEmployee = (user: Api.IUser) => {
		setSelectAnEmployeeModalIsOpen(false);
		setSelectedSendFromUser(user);
		setIsUpdating(true);
		const updatedOption = { mode: Api.SendEmailFrom.SelectedUser, selectedUser: user.id };
		setSendFrom(updatedOption);
		save({ newSendFrom: updatedOption }).finally(() => {
			setIsUpdating(false);
		});
	};
	const clearInlineErrorMessage = () => {
		if (step.automationStep) {
			step.automationStep.showRequiredFieldError = false;
		}
	};
	const stepHwcCreateMutation = useAutomationTemplateStepHwcCreateMutation({
		onError: error => {
			logApiError(`HandwrittenCardStep-Error`, error);
			errorMessages.pushApiError(error);
		},
	});
	const stepDeleteMutation = useAutomationTemplateStepDeleteMutation({
		onError: (error, variables) => {
			logApiError(`${variables.stepType}-Error`, error);
			errorMessages.pushApiError(error);
		},
	});
	const onCloseSelectAnEmployeeModal = () => setSelectAnEmployeeModalIsOpen(false);
	const selectedUserQuery = useUserQuery({
		enabled: Boolean(step.automationStep?.sendFromOptions?.selectedUser),
		id: step.automationStep?.sendFromOptions?.selectedUser ?? '',
		impersonationContext,
	});
	const onHwcTypeSelected = async () => {
		clearInlineErrorMessage();
		const automationStep: Api.HandwrittenCardAutomationStep = {
			_type: 'HandwrittenCardAutomationStep',
			schedule: {
				criteria: Api.VmUtils.Automations.triggers.triggerUsesRelativeToAnchorScheduleCriteria(trigger)
					? Api.AutomationStepScheduleCriteria.RelativeToAnchor
					: Api.AutomationStepScheduleCriteria.AfterAutomationStart,
				numberOfDays: 0,
				numberOfHours: 0,
				numberOfMinutes: 0,
			},
		};
		if (trigger?.resourceSelector === Api.ResourceSelectorId.HappyBirthday) {
			automationStep.schedule.numberOfDays = -7;
		}
		let stepVm: Api.AutomationStepViewModel<Api.UserSessionContext, Api.IAutomationStep>;
		if (step.automationStep?.id) {
			const stepModelToReplace = step.automationStep.toJs();
			const templateResult = await stepDeleteMutation.mutateAsync({
				templateId: template.id,
				stepType: Api.AutomationStepType.HandwrittenCard,
				stepId: stepModelToReplace.id,
				impersonationContext,
			});
			template.mSetModel(templateResult);
			const newSteps =
				template.draftVersion?.steps.length > 0 ? template.draftVersion?.steps : template.publishedVersion?.steps;
			const indexOfStepToReplace = newSteps?.backingArray.findIndex(x => x.id === stepModelToReplace.id);
			const beforeStepId =
				indexOfStepToReplace + 1 < newSteps?.length ? newSteps?.getByIndex(indexOfStepToReplace + 1)?.id : null;
			const stepResult = await stepHwcCreateMutation.mutateAsync({
				templateId: template.id,
				step: automationStep,
				beforeId: beforeStepId,
				withEmailFallback: false,
				impersonationContext,
			});
			stepVm = new Api.HwcAutomationStepViewModel(userSession, stepResult, template.id, !template.canEdit);
			template.draftVersion.steps.splice(indexOfStepToReplace, 1, stepVm);
		} else {
			const beforeIndex = index + 1 < steps.length ? index + 1 : -1;
			const beforeId = beforeIndex > -1 ? steps[beforeIndex]?.automationStep?.id : null;
			const stepResult = await stepHwcCreateMutation.mutateAsync({
				templateId: template.id,
				step: automationStep,
				beforeId,
				withEmailFallback: false,
				impersonationContext,
			});
			stepVm = await template.onAfterAddStep(stepResult, beforeId);
		}
		step.automationStep = stepVm;
	};
	const onTypeSelectionChanged = (selectedOption: ISelectBoxOption<Api.AutomationStepType>) => {
		if (selectedOption.value === step.automationStep?.type) {
			return;
		}
		if (selectedOption.value === Api.AutomationStepType.HandwrittenCard) {
			onHwcTypeSelected();
			return;
		}
		let automationStep: Api.IAutomationStep = null;
		let eventName: string = null;
		switch (selectedOption.value) {
			case Api.AutomationStepType.ActionItem: {
				const actionItemStep: Api.IActionItemAutomationStep = {
					_type: 'ActionItemAutomationStep',
				};
				automationStep = actionItemStep;
				eventName = 'AddActionItemStep';
				break;
			}
			case Api.AutomationStepType.AddTag: {
				const addTagStep: Api.IAddTagAutomationStep = {
					_type: 'AddTagAutomationStep',
					tagName: null,
				};
				automationStep = addTagStep;
				eventName = 'AddAddTagStep';
				break;
			}
			case Api.AutomationStepType.Email: {
				const emailStep: Api.IEmailAutomationStep = {
					_type: 'EmailAutomationStep',
					options: {
						noteVisibility: 'all',
						saveAsNote: true,
					},
					schedule: {
						numberOfHours: schedule?.numberOfHours > 0 ? schedule?.numberOfHours : 11,
					},
					tagToAddOnClick: '',
				};
				automationStep = emailStep;
				eventName = 'AddEmailStep';
				break;
			}
			case Api.AutomationStepType.RemoveTag: {
				const removeTagStep: Api.IRemoveTagAutomationStep = {
					_type: 'RemoveTagAutomationStep',
					tagName: null,
				};
				automationStep = removeTagStep;
				eventName = 'AddRemoveTagStep';
				break;
			}
			case Api.AutomationStepType.Texting: {
				const textingStep: Api.ITextingAutomationStep = {
					_type: 'TextingAutomationStep',
					content: getDefaultRichContentForTextingStep(
						template?.impersonationContext?.account || userSession.account.toJs(),
						trigger
					),
					name: 'Texting Step',
				};
				automationStep = textingStep;
				eventName = 'TextingStep';
				break;
			}
			case Api.AutomationStepType.NoAction: {
				const noActionStep: Api.INoActionAutomationStep = {
					_type: 'NoActionAutomationStep',
				};
				automationStep = noActionStep;
				eventName = 'NoActionStep';
				break;
			}
			default: {
				break;
			}
		}

		if (automationStep) {
			const nextStepType = Api.VmUtils.Automations.steps.getAutomationStepTypeForAutomationStep(automationStep);
			automationStep.schedule = Api.VmUtils.Automations.steps.getDefaultScheduleForAutomationStep(
				nextStepType,
				trigger
			);

			if (trigger?._type === Api.AutomationTriggerType.Meeting && automationStep?._type === 'TextingAutomationStep') {
				const textingStep: Api.ITextingAutomationStep = {
					_type: 'TextingAutomationStep',
					content: getDefaultRichContentForTextingStep(
						template?.impersonationContext?.account || userSession.account.toJs(),
						trigger,
						userSession,
						automationStep.schedule
					),
					name: 'Texting Step',
				};
				automationStep = { ...automationStep, ...textingStep };
			}

			const beforeIndex = index + 1 < steps.length ? index + 1 : -1;
			const beforeId = beforeIndex > -1 ? steps[beforeIndex]?.automationStep?.id : null;
			const promise = step.automationStep?.id
				? template.replaceStep<Api.IAutomationStep, Api.AutomationStepViewModel>(
						automationStep,
						step.automationStep.toJs()
					)
				: template.addStep<Api.IAutomationStep, Api.AutomationStepViewModel>(automationStep, beforeId);
			if (promise) {
				// @ts-ignore
				logEvent(eventName, { templateId: template.id });
				setActionItemContentEditorState(null);
				setSelectedTag(null);
				setName(automationStep.name ?? null);
				setSchedule(automationStep.schedule);
				promise
					.then(stepVm => {
						step.automationStep = stepVm;
					})
					.catch((error: Api.IOperationResultNoValue) => {
						logApiError(`${eventName}-Error`, error);
						errorMessages.pushApiError(error);
					});
			}
		}
		clearInlineErrorMessage();
	};
	const saveStep = (
		automationStep?: Api.IAutomationStep,
		newSchedule?: Api.IAutomationStepSchedule,
		loggingEventParams: Api.IDictionary<string | number | boolean> = {}
	) => {
		if (automationStep) {
			if (newSchedule) {
				automationStep.schedule = newSchedule;
			}
			const promise = step.automationStep.update(automationStep);
			if (promise) {
				logEvent('SaveAutomationStep', {
					id: step.automationStep.id,
					type: step.automationStep.type,
					...loggingEventParams,
				});
				promise.catch((error: Api.IOperationResultNoValue) => {
					logApiError('SaveAutomationStep-Error', error);
				});
			}
			return promise;
		}
	};
	const save = ({
		newSchedule = schedule,
		newSendFrom = sendFrom,
		newTagToAddOnClick = tagToAddOnClick,
		newSendToSender = sendToSender,
		newSelectedTag = selectedTag,
	}: {
		newSchedule?: Api.IAutomationStepSchedule;
		newSendFrom?: Api.ISendFromOptions;
		newTagToAddOnClick?: string;
		newSendToSender?: boolean;
		newSelectedTag?: string;
	} = {}) => {
		let automationStep: Api.IAutomationStep = null;
		const eventParams: Api.IDictionary<string | number | boolean> = {};
		switch (step.automationStep?.type) {
			case Api.AutomationStepType.RemoveTag:
			case Api.AutomationStepType.AddTag: {
				const stepModel: Api.ITagAutomationStep = { tagName: newSelectedTag };
				automationStep = stepModel;
				eventParams.tagLength = newSelectedTag?.length;
				break;
			}
			case Api.AutomationStepType.ActionItem: {
				const stepModel: Api.IActionItemAutomationStep = {
					content: actionItemContentEditorState?.getRawRichTextContent(),
				};
				stepModel.sendFromOptions = newSendFrom;
				automationStep = stepModel;
				eventParams.hasContent = actionItemContentEditorState?.hasContent();
				break;
			}
			case Api.AutomationStepType.Email: {
				const stepModel: Partial<Api.IEmailAutomationStep> = {
					...((step?.automationStep as Api.EmailAutomationStepViewModel)?.toJs() || {}),
					name,
					tagToAddOnClick: newTagToAddOnClick,
				};
				stepModel.sendFromOptions = newSendFrom;
				stepModel.options.sendToSender = newSendToSender;
				automationStep = stepModel;
				eventParams.nameLength = name?.length;
				break;
			}
			case Api.AutomationStepType.Texting: {
				const stepModel: Partial<Api.ITextingAutomationStep> = {
					...((step?.automationStep as Api.TextingAutomationStepViewModel)?.toJs() || {}),
					name,
				};
				stepModel.sendFromOptions = newSendFrom;
				automationStep = stepModel;
				eventParams.nameLength = name?.length;
				break;
			}
			case Api.AutomationStepType.NoAction: {
				const stepModel: Partial<Api.INoActionAutomationStep> = {
					...((step?.automationStep as Api.NoActionAutomationStepViewModel)?.toJs() || {}),
					name,
				};
				automationStep = stepModel;
				break;
			}
			default: {
				break;
			}
		}

		return saveStep(automationStep, newSchedule);
	};
	const onRequestSetSchedule = (newSchedule: Api.IAutomationStepSchedule) => {
		clearInlineErrorMessage();
		setIsUpdating(true);
		setSchedule(newSchedule);
		save({ newSchedule }).finally(() => {
			setIsUpdating(false);
		});
	};
	const onNameInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
		clearInlineErrorMessage();
	};
	const onEmailCtaClicked = () => {
		setShowEmailEditor(true);
		clearInlineErrorMessage();
	};
	const onTagForLinkClickChanged = (tag: string) => {
		setTagToAddOnClick(tag);
		save({ newTagToAddOnClick: tag });
	};
	const onAddTagToLinkClickCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		setShowEmailLinkClickTags(checked);
		if (!checked) {
			onTagForLinkClickChanged('');
		}
	};
	const onShowSendFromCheckboxChanged = () => {
		if (showingSendFrom) {
			setSendFrom(null);
			setShowingSendFrom(false);
			setSelectedSendFromUser(null);
			save({ newSendFrom: null });
		} else {
			const newSendFrom = sendFromOptions[0].dataContext;
			const newSendToSender = false;
			setSendFrom(newSendFrom);
			setShowingSendFrom(true);
			setSendToSender(newSendToSender);
			setSelectedSendFromUser(null);
			save({ newSendFrom, newSendToSender });
		}
	};
	const getSendFromOptionLabel = (option: Api.ISendFromOptions) => {
		const allOptions = sendFromOptions;
		if (!allOptions) {
			return null;
		}

		if (option.mode === Api.SendEmailFrom.SelectedUser) {
			if (selectedSendFromUser) {
				return getDisplayName(selectedSendFromUser);
			}

			return selectedUserQuery.data ? getDisplayName(selectedUserQuery.data) : 'Selected User';
		}
		return allOptions.find(
			i => i.dataContext.mode === option.mode && i.dataContext.connectionType === option.connectionType
		)?.text;
	};
	const onSendToSenderCheckboxChanged = () => {
		if (sendToSender) {
			const newSendToSender = false;
			setSendFrom(null);
			setSendToSender(newSendToSender);
			setSelectedSendFromUser(null);
			save({ newSendFrom: null, newSendToSender });
		} else {
			const newSendFrom = sendFromOptions[0].dataContext;
			const newSendToSender = true;
			setSendFrom(newSendFrom);
			setSendToSender(newSendToSender);
			setShowingSendFrom(false);
			setSelectedSendFromUser(null);
			save({ newSendFrom, newSendToSender });
		}
	};
	const onEmailComposerModalRequestClose = (data: Api.IEmailAutomationStep) => {
		if (data) {
			step.automationStep.mSetModel(data);
		}
		setShowEmailEditor(false);
	};
	const onActionItemContentEditorStateChanged = (newActionItemContentEditorState: Api.IRichContentEditorState) => {
		setActionItemContentEditorState(newActionItemContentEditorState);
		clearInlineErrorMessage();
	};
	const onTagSearchFieldChanged = (e: ISimpleAutoCompleteSearchFieldEvent<React.ChangeEvent<HTMLInputElement>>) => {
		if (e.sourceEvent) {
			const inputElement = e.sourceEvent.target as HTMLInputElement;
			setSelectedTag(inputElement.value || '');
		}
	};
	const onTagSearchClearButtonClicked = () => {
		setSelectedTag(null);
		save({ newSelectedTag: null });
	};
	const onSearchTagSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<string>) => {
		if (e.target) {
			e.target.setSearchQuery(e.selection);
		}
		const newSelectedTag = e.selection;
		setSelectedTag(newSelectedTag);
		save({ newSelectedTag });
	};
	const onTagSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (!!e.sourceEvent && e.sourceEvent.keyCode === 13) {
			const inputElement = e.sourceEvent.target as HTMLInputElement;
			inputElement.blur();
		}
	};
	React.useEffect(() => {
		const scheduleWatcherDisposer = autorun(() => {
			if (!equal(schedule, step?.automationStep?.schedule)) {
				setSchedule(step.automationStep?.schedule);
			}
		});
		return () => {
			scheduleWatcherDisposer();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const showActionMenu =
		step.automationStep?.type !== Api.AutomationStepType.Texting ||
		index > 0 ||
		(steps.length > 1 &&
			index < steps.length - 1 &&
			steps[index + 1]?.automationStep?.type !== Api.AutomationStepType.Switch) ||
		steps.length > 1;
	const canEdit =
		!readonly && (step.automationStep ? step.automationStep.canEdit : true) && (template ? template.canEdit : true);
	const supportedTypes = new Set(
		(template?.rootTemplate?.supportedStepModelTypes || []).map(x =>
			Api.VmUtils.Automations.steps.getAutomationStepTypeForAutomationStep({
				_type: x,
			})
		)
	);
	let stepOptions = StepTypeOptions.filter(x => !!supportedTypes.has(x.value));
	if (
		environment.appType === 'admin' &&
		!impersonationContext.isValid &&
		step.automationStep?.type !== Api.AutomationStepType.Texting
	) {
		stepOptions = stepOptions.filter(x => x.value !== Api.AutomationStepType.Texting);
	}
	let errorMessage = null;
	if (step.automationStep?.showRequiredFieldError && !step.automationStep?.hasAllRequiredInfo) {
		switch (step.automationStep?.type) {
			case Api.AutomationStepType.Email: {
				errorMessage = 'Please provide email content and description/name.';
				break;
			}
			case Api.AutomationStepType.ActionItem: {
				errorMessage = 'Please provide action item content.';
				break;
			}
			case Api.AutomationStepType.RemoveTag:
			case Api.AutomationStepType.AddTag: {
				errorMessage = 'Please provide a tag name.';
				break;
			}
			default: {
				break;
			}
		}
	}
	const connectionOptions = connectionTypes?.length
		? connectionTypes.map(connectionType => {
				return {
					id: `connectionType-${connectionType.value}`,
					dataContext: {
						mode: Api.SendEmailFrom.ConnectionType,
						connectionType: connectionType.value,
					} as Api.ISendFromOptions,
					text: connectionType.label,
				};
			})
		: [];
	const sendFromOptions = [
		{
			id: Api.SendEmailFrom.ContactOwner,
			dataContext: { mode: Api.SendEmailFrom.ContactOwner },
			text: 'Contact Owner',
		},
		...connectionOptions,
		{
			id: Api.SendEmailFrom.SelectedUser,
			dataContext: { mode: Api.SendEmailFrom.SelectedUser },
			text: 'Selected User',
		},
	];

	const renderFooter = () => {
		const baseKey = `${step.automationStep?.type}-${step.uuid}-${index}-${step.automationStep?.id || ''}`;

		const onSendFromSelectionChanged = (option: ISelectOption<Api.ISendFromOptions>, wasSelected: boolean) => {
			if (wasSelected) {
				if (option.dataContext.mode === Api.SendEmailFrom.SelectedUser) {
					setSelectAnEmployeeModalIsOpen(true);
					return;
				}
				setSelectedSendFromUser(null);
				setIsUpdating(true);
				setSendFrom(option.dataContext);
				save({ newSendFrom: option.dataContext }).finally(() => {
					setIsUpdating(false);
				});
			}
		};

		switch (step.automationStep?.type) {
			case Api.AutomationStepType.Email: {
				const emailStep = step.automationStep as Api.EmailAutomationStepViewModel;
				return (
					<>
						<div className={css(styleSheet.fieldLabel)}>
							Email title: &nbsp;
							<span className={css(bs.required)}>*</span>
						</div>
						<TextInput
							className={css(styleSheet.emailNameInput)}
							disabled={!canEdit}
							inputId={`automation-email-step-name-input-${step?.uuid || ''}`}
							key={`${baseKey}-name-input`}
							onBlur={() => save()}
							onChange={onNameInputChanged}
							onFocus={clearInlineErrorMessage}
							placeholder='Enter a brief description of this email'
							type='text'
							value={name || ''}
						/>
						<InputFieldError errorMessage={errorMessage}>
							<button
								className={css(
									styleSheet.emailCta,
									!emailStep.content ? styleSheet.emailCreateButton : styleSheet.emailEditButton
								)}
								disabled={!step.automationStep}
								onClick={onEmailCtaClicked}
							>
								<span>{`${emailStep.content ? 'Preview / Edit' : 'Create'} Email`}</span>
							</button>
						</InputFieldError>
						<AdvancedOptionsButton
							isCollapsed={!advancedOptionsToggled}
							onClick={() => setAdvancedOptionsToggled(!advancedOptionsToggled)}
						/>
						<Collapsible isCollapsed={!advancedOptionsToggled}>
							<div className={css(styleSheet.advancedOptionsContent)}>
								<Checkbox
									className={css(styleSheet.addTagOnLinkClickCheckbox)}
									id={step.uuid ? `automation-add-tag-on-link-click-checkbox-${step.uuid}` : uuid()}
									checked={showEmailLinkClickTags}
									type='large'
									onChange={onAddTagToLinkClickCheckChanged}
								>
									<div>
										<div className={css(styleSheet.checkboxText)}>
											Add a tag if the recipient clicks (any) link in the email
										</div>
									</div>
								</Checkbox>
								{showEmailLinkClickTags ? (
									<AutomationTagAutocomplete
										className={css(styleSheet.tagAutomcomplete)}
										limit={5}
										onTagSelected={onTagForLinkClickChanged}
										initialTag={tagToAddOnClick}
									/>
								) : null}

								<Checkbox
									className={css(styleSheet.addTagOnLinkClickCheckbox)}
									id={step.uuid ? `automation-send-from-checkbox-${step.uuid}` : uuid()}
									checked={showingSendFrom}
									type='large'
									onChange={onShowSendFromCheckboxChanged}
								>
									<div>
										<p className={css(styleSheet.checkboxText)}>Always send this step from</p>
									</div>
								</Checkbox>
								{showingSendFrom ? (
									<Select
										maxOptionsToShow={99}
										onOptionClick={onSendFromSelectionChanged}
										options={sendFromOptions}
										selectedOptionTitle={getSendFromOptionLabel(sendFrom)}
									/>
								) : null}
								{userSession.account.features.automation.showInternalSendOption ? (
									<>
										<Checkbox
											className={css(styleSheet.addTagOnLinkClickCheckbox)}
											id={step.uuid ? `automation-send-to-checkbox-${step.uuid}` : uuid()}
											checked={sendToSender}
											type='large'
											onChange={onSendToSenderCheckboxChanged}
										>
											<div>
												<p className={css(styleSheet.checkboxText)}>Send this email to an internal user</p>
											</div>
										</Checkbox>
										{sendToSender ? (
											<Select
												maxOptionsToShow={99}
												onOptionClick={onSendFromSelectionChanged}
												options={sendFromOptions}
												selectedOptionTitle={getSendFromOptionLabel(sendFrom)}
											/>
										) : null}
									</>
								) : null}
								{selectAnEmployeeModalIsOpen ? (
									<SelectAnEmployeeModal
										onClose={onCloseSelectAnEmployeeModal}
										onUserSelected={onSaveSelectAnEmployee}
										bodyText={sendToSender ? SelectAnEmployeeInternalEmailBody : SelectAnEmployeeExternalEmailBody}
									/>
								) : null}
							</div>
						</Collapsible>
						{showEmailEditor ? (
							<EditAutomationStepEmailComposerModal
								automationTemplateId={template.id}
								step={step.automationStep.model as Api.IEmailAutomationStep}
								impersonationContext={template.impersonationContext}
								onRequestClose={onEmailComposerModalRequestClose}
								onAttachmentRemoved={data => {
									step.automationStep.mSetModel(data);
								}}
								readonly={!canEdit}
								trigger={trigger}
								defaultInitialContent={Api.createRawRichTextContentStateWithText(
									getRichContentMessageForTextingStep(userSession, trigger, schedule)
								)}
							/>
						) : null}
					</>
				);
			}
			case Api.AutomationStepType.Texting: {
				const automationStep = step.automationStep as Api.TextingAutomationStepViewModel;
				return (
					<>
						<div className={css(styleSheet.fieldLabel)}>
							Text content: &nbsp;
							<span className={css(bs.required)}>*</span>
						</div>
						<InputFieldError errorMessage={errorMessage}>
							<AutomationStepCardTextingInput
								index={index}
								onRequestClearErrorMessages={clearInlineErrorMessage}
								onRequestSave={saveStep}
								schedule={schedule}
								step={automationStep}
								trigger={trigger}
							/>
						</InputFieldError>
						<AdvancedOptionsButton
							isCollapsed={!advancedOptionsToggled}
							onClick={() => setAdvancedOptionsToggled(!advancedOptionsToggled)}
						/>
						<Collapsible isCollapsed={!advancedOptionsToggled}>
							<div className={css(styleSheet.advancedOptionsContent)}>
								<>
									<Checkbox
										className={css(styleSheet.addTagOnLinkClickCheckbox)}
										id={step.uuid ? `automation-send-from-checkbox-${step.uuid}` : uuid()}
										checked={showingSendFrom}
										type='large'
										onChange={onShowSendFromCheckboxChanged}
									>
										<div>
											<p className={css(styleSheet.checkboxText)}>Always send this step from</p>
										</div>
									</Checkbox>
									{showingSendFrom ? (
										<Select
											maxOptionsToShow={99}
											onOptionClick={onSendFromSelectionChanged}
											options={sendFromOptions}
											selectedOptionTitle={getSendFromOptionLabel(sendFrom)}
										/>
									) : null}
									{selectAnEmployeeModalIsOpen ? (
										<SelectAnEmployeeModal
											onClose={onCloseSelectAnEmployeeModal}
											onUserSelected={onSaveSelectAnEmployee}
											bodyText={SelectAnEmployeeExternalEmailBody}
										/>
									) : null}
								</>
							</div>
						</Collapsible>
					</>
				);
			}
			case Api.AutomationStepType.ActionItem: {
				return (
					<>
						<div className={css(styleSheet.fieldLabel)}>Type action item below:</div>
						<InputFieldError errorMessage={errorMessage}>
							<RichContentDocumentEditor
								config={DefaultActionItemEditorConfig}
								contentPlaceholderText='Type action item...'
								contentState={actionItemContentEditorState}
								key={`${baseKey}-action-item-content`}
								onBlur={() => save()}
								onContentStateChanged={onActionItemContentEditorStateChanged}
								onFocus={clearInlineErrorMessage}
								readOnly={!canEdit}
								styles={[styleSheet.actionItemContentEditor]}
							/>
						</InputFieldError>
						<AdvancedOptionsButton
							isCollapsed={!advancedOptionsToggled}
							onClick={() => setAdvancedOptionsToggled(!advancedOptionsToggled)}
						/>
						<Collapsible isCollapsed={!advancedOptionsToggled}>
							<div className={css(styleSheet.advancedOptionsContent)}>
								<>
									<Checkbox
										className={css(styleSheet.addTagOnLinkClickCheckbox)}
										id={step.uuid ? `automation-send-from-checkbox-${step.uuid}` : uuid()}
										checked={showingSendFrom}
										type='large'
										onChange={onShowSendFromCheckboxChanged}
									>
										<div>
											<p className={css(styleSheet.checkboxText)}>Always assign this action item to</p>
										</div>
									</Checkbox>
									{showingSendFrom ? (
										<Select
											maxOptionsToShow={99}
											onOptionClick={onSendFromSelectionChanged}
											options={sendFromOptions}
											selectedOptionTitle={getSendFromOptionLabel(sendFrom)}
										/>
									) : null}
									{selectAnEmployeeModalIsOpen ? (
										<SelectAnEmployeeModal
											onClose={onCloseSelectAnEmployeeModal}
											onUserSelected={onSaveSelectAnEmployee}
											bodyText={SelectAnEmployeeExternalEmailBody}
										/>
									) : null}
								</>
							</div>
						</Collapsible>
					</>
				);
			}
			case Api.AutomationStepType.RemoveTag:
			case Api.AutomationStepType.AddTag: {
				return (
					<>
						<div className={css(styleSheet.fieldLabel)}>
							Select a tag: &nbsp;
							<span className={css(bs.required)}>*</span>
						</div>
						<InputFieldError errorMessage={errorMessage}>
							<SimpleAutoCompleteSearchField
								disabled={!canEdit}
								initialSearchQuery={selectedTag || undefined}
								key={`${baseKey}-tag-select`}
								onBlur={() => save()}
								onChange={onTagSearchFieldChanged}
								onClear={onTagSearchClearButtonClicked}
								onFocus={clearInlineErrorMessage}
								// @ts-ignore
								onItemSelected={onSearchTagSelected}
								onKeyDown={onTagSearchFieldKeyDown}
								resultsLimit={10}
								pageSize={10}
								placeholder='Search or add a new tag'
								renderNoResultsItem={false}
								style={styleSheet.tagSearchField}
								type={Api.ResourceAutoCompleteViewModelType.Tag}
							/>
						</InputFieldError>
					</>
				);
			}
			case Api.AutomationStepType.NoAction: {
				return (
					<>
						<div className={css(styleSheet.fieldLabel)}>Take no action.</div>
					</>
				);
			}
			default: {
				break;
			}
		}
	};
	return (
		<div className='automation-step-card'>
			<EditAutomationTemplateStepCardHeader
				canEdit={canEdit}
				onRenderTitle={onRenderTitle}
				ActionMenu={
					showActionMenu ? (
						<MoreMenu>
							{step.automationStep?.type !== Api.AutomationStepType.Texting ? (
								<MoreMenuItem onClick={() => onMenuItemClicked(AutomationStepAction.Copy)}>Duplicate Step</MoreMenuItem>
							) : null}
							{index > 0 ? (
								<MoreMenuItem onClick={() => onMenuItemClicked(AutomationStepAction.MoveUp)}>
									Move up a Step
								</MoreMenuItem>
							) : null}
							{steps.length > 1 &&
							index < steps.length - 1 &&
							steps[index + 1]?.automationStep?.type !== Api.AutomationStepType.Switch ? (
								<MoreMenuItem onClick={() => onMenuItemClicked(AutomationStepAction.MoveDown)}>
									Move down a Step
								</MoreMenuItem>
							) : null}
							{steps.length > 1 ? (
								<MoreMenuItem onClick={() => onMenuItemClicked(AutomationStepAction.Delete)}>Delete Step</MoreMenuItem>
							) : null}
						</MoreMenu>
					) : null
				}
			/>
			<div className={css(styleSheet.card)}>
				<div className={css(styleSheet.cardHeader)}>
					<div className={css(styleSheet.cardHeaderInfo)}>
						Select a choice below for this step of the automation flow.
					</div>
					<DefaultSelectBox
						className={css(styleSheet.typeSelect)}
						disabled={!canEdit}
						onSelectionChanged={onTypeSelectionChanged}
						options={stepOptions}
						optionStyles={[styleSheet.typeSelectMenuItem]}
						placeholder={<span className={css(bs.textTitles, bs.textSm)}>Select a choice below...</span>}
						contentClassName={css(styleSheet.typeSelectContent)}
						selectedOption={
							step.automationStep?.type ? stepOptions.find(x => x.value === step.automationStep?.type) : undefined
						}
					/>
				</div>
				{step.automationStep?.type === Api.AutomationStepType.HandwrittenCard ? (
					<HwcAutomationStepCardContent templateId={template.id} canEdit={canEdit} step={step} trigger={trigger} />
				) : (
					step.automationStep?.type && (
						<>
							<div className={css(styleSheet.cardBody)}>
								<AutomationStepCardSchedule
									canEdit={canEdit}
									index={index}
									onRequestSetSchedule={onRequestSetSchedule}
									schedule={schedule}
									step={step}
									stepType={step.automationStep?.type}
									trigger={trigger}
									isBusy={isUpdating}
								/>
							</div>
							<div className={css(styleSheet.cardFooter)}>{renderFooter()}</div>
						</>
					)
				)}
			</div>
		</div>
	);
}

const AutomationStepCardAsObserver = observer(_AutomationStepCard);
const AutomationStepCardWithContext = inject(ImpersonationContextKey)(AutomationStepCardAsObserver);
export const AutomationStepCard = withConnectionTypes(AutomationStepCardWithContext, {
	refetchOnWindowFocus: false,
});
