import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	styles?: StyleDeclarationValue[];
}

export const ArrowIcon: React.FC<IProps> = props => {
	const { className, fillColor, styles } = props;
	return (
		<SvgIcon className={`arrow-icon ${className || ''} ${css(...(styles || []))}`} height={9} width={15}>
			<polygon
				fill={fillColor || brandSecondary}
				fillRule='evenodd'
				points='10.418 0 9.043 1.354 11.279 3.545 0 3.545 0 5.457 11.27 5.457 9.042 7.65 10.42 9 15 4.492'
			/>
		</SvgIcon>
	);
};
