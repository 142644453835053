import { styleSheet } from './styles';
import { ContentSourceFormat, EmailCategories, ITemplate } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	emailCategory?: EmailCategories;
	styles?: StyleDeclarationValue[];
	template?: ITemplate;
}

export const TemplateTypeTag: React.FC<IProps> = props => {
	const { className, styles, template, emailCategory } = props;
	// @ts-ignore
	let styleForType: StyleDeclarationValue = null;
	let content: React.ReactNode = null;
	if (
		template?.content?.sourceFormat === ContentSourceFormat.UnlayerHtmlNewsletter ||
		emailCategory === EmailCategories.HtmlNewsletter
	) {
		styleForType = styleSheet.html;
		content = 'HTML';
	}
	return (
		<div
			className={`${css(styleSheet.container, styleForType, ...(styles || []))} template-type-tag ${className || ''}`}
		>
			{content}
		</div>
	);
};
