import useEmblaCarousel from 'embla-carousel-react';
import * as React from 'react';

export function useEmblaSlider() {
	const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);
	const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'start', containScroll: 'trimSnaps' });
	const handlePrev = React.useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
	const handleNext = React.useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
	const onSelect = React.useCallback(() => {
		if (!emblaApi) {
			return;
		}
		setPrevBtnEnabled(emblaApi.canScrollPrev());
		setNextBtnEnabled(emblaApi.canScrollNext());
	}, [emblaApi]);
	React.useEffect(() => {
		if (!emblaApi) {
			return;
		}
		onSelect();
		emblaApi.on('select', onSelect);
		emblaApi.on('reInit', onSelect);
		return () => {
			emblaApi.off('select', onSelect);
			emblaApi.off('reInit', onSelect);
		};
	}, [emblaApi, onSelect]);

	return {
		emblaRef,
		handleNext,
		handlePrev,
		nextBtnEnabled,
		prevBtnEnabled,
	};
}
