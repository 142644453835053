import { brandSecondary, destructive } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addTag: {
		position: 'relative',
		width: 460,
	},
	addTagFooter: {
		alignItems: 'center',
		display: 'flex',
	},
	addTagFooterAddButton: {
		width: 90,
	},
	addTagFooterErrorMessage: {
		color: destructive,
		fontSize: 14,
		letterSpacing: 0,
		marginLeft: '0.5rem',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: 'calc(100% - 100px)',
	},
	addTagHeader: {
		textAlign: 'right',
	},
	addTagModalRoot: {
		width: 460,
	},
	addTagTextInput: {
		marginBottom: '2rem',
	},
	addTagTitle: {
		color: brandSecondary,
		fontSize: 18,
		lineHeight: '22px',
		marginBottom: '1rem',
	},
});
