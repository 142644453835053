import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const NewCommercialClientAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon
			className={`new-commercial-client-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<defs>
				<circle id='path-a' cx='80' cy='80' r='80' />
				<filter
					id='newCommercialClientAutomationTriggerGraphicFilter'
					width='111.2%'
					height='111.2%'
					x='-5.6%'
					y='-5.6%'
					filterUnits='objectBoundingBox'
				>
					<feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='3' />
					<feColorMatrix
						in='shadowBlurOuter1'
						values='0 0 0 0 0.54424426   0 0 0 0 0.54424426   0 0 0 0 0.54424426  0 0 0 0.100033967 0'
					/>
				</filter>
				<linearGradient id='path-d' x1='-14.748%' x2='62.526%' y1='106.162%' y2='21.257%'>
					<stop offset='0%' stopColor='#00D5F5' stopOpacity='0' />
					<stop offset='97.029%' stopColor='#00AAE8' />
				</linearGradient>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(6 6)'>
				<mask id='newCommercialClientAutomationTriggerGraphicMask' fill='#fff'>
					<circle cx='80' cy='80' r='80' />
				</mask>
				<circle fill='#000' filter='url(#newCommercialClientAutomationTriggerGraphicFilter)' cx='80' cy='80' r='80' />
				<circle fill='#BBE2FE' cx='80' cy='80' r='80' />
				<g mask='newCommercialClientAutomationTriggerGraphicMask'>
					<g transform='translate(4 42)'>
						<polygon fill='url(#path-d)' points='24.762 1 0 53.615 0 114.203 107 123 131 83.909' opacity='.225' />
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,68 L25,83 C42.9468955,83 43.3085155,83 63.4925373,83 C72.2386346,83 72.8496325,83 77,83 C77,74.7157288 70.2842712,68 62,68 L25,68 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,68 L25,78.952381 C42.9468955,78.952381 43.3085155,78.952381 63.4925373,78.952381 C69.4392038,78.952381 73.9315777,79.9121397 76.9590104,81.8826137 C76.3873966,74.1201774 69.9084203,68 62,68 L25,68 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,68 L25,74.9047619 C42.9468955,74.9047619 43.3085155,74.9047619 63.4925373,74.9047619 C69.2769119,74.9047619 73.7241337,76.6993926 76.7520898,80.2695772 C75.4683115,73.2889959 69.3518113,68 62,68 L25,68 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,1 L25,71 C42.9468955,71 43.3085155,71 63.4925373,71 C69.4651783,71 73.9901996,73.766211 77,79.1897947 L77,16 C77,7.71572875 70.2842712,1 62,1 L25,1 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,68 L78,83 C95.9468955,83 96.3085155,83 116.492537,83 C125.238635,83 125.849633,83 130,83 C130,74.7157288 123.284271,68 115,68 L78,68 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,68 L78,78.952381 C95.9468955,78.952381 96.3085155,78.952381 116.492537,78.952381 C122.439204,78.952381 126.931578,79.9121397 129.95901,81.8826137 C129.387397,74.1201774 122.90842,68 115,68 L78,68 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,68 L78,74.9047619 C95.9468955,74.9047619 96.3085155,74.9047619 116.492537,74.9047619 C122.276912,74.9047619 126.724134,76.6993926 129.75209,80.2695772 C128.468311,73.2889959 122.351811,68 115,68 L78,68 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,1 L78,71 C95.9468955,71 96.3085155,71 116.492537,71 C122.465178,71 126.9902,73.766211 130,79.1897947 L130,16 C130,7.71572875 123.284271,1 115,1 L78,1 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<polygon fill='#00AAE8' points='54 75 54 88 58 86.375 62 88 62 75' />
						<g transform='translate(40 10)'>
							<circle cx='9.5' cy='9.5' r='9.5' fill='#FFF' stroke='#00AAE8' strokeWidth='2' />
							<g
								stroke='#00AAE8'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								transform='translate(5.542 4.75)'
							>
								<path d='M7.67676768,8.36683007 L7.67676768,7.45615468 C7.67676768,6.45025043 6.81751619,5.63480392 5.75757576,5.63480392 L1.91919192,5.63480392 C0.85925149,5.63480392 1.06536553e-16,6.45025043 0,7.45615468 L0,8.36683007' />
								<ellipse cx='4.03' cy='1.821' rx='1.919' ry='1.821' />
							</g>
						</g>
						<rect width='17' height='2' x='41' y='35' fill='#00AAE8' rx='1' />
						<rect width='12' height='2' x='41' y='40' fill='#00AAE8' rx='1' />
						<rect width='5' height='2' x='39' y='48' fill='#DBF2C3' rx='1' />
						<rect width='8' height='2' x='46' y='48' fill='#DBF2C3' rx='1' />
						<rect width='8' height='2' x='39' y='52' fill='#DBF2C3' rx='1' />
						<rect width='6' height='2' x='55' y='48' fill='#DBF2C3' rx='1' />
						<rect width='6' height='2' x='49' y='52' fill='#DBF2C3' rx='1' />
					</g>
				</g>
				<g fill='#00AAE8' mask='url(#path-c)'>
					<path
						d='M4.99870002 20.0005L2.99870002 20.0005 2.99870002 6.7815 4.99870002 6.2815 4.99870002 20.0005zM5.75570002 4.0305L1.75570002 5.0305C1.31070002 5.1415.998700024 5.5415.998700024 6.0005L.998700024 21.0005C.998700024 21.5525 1.44670002 22.0005 1.99870002 22.0005L5.99870002 22.0005C6.55070002 22.0005 6.99870002 21.5525 6.99870002 21.0005L6.99870002 5.0005C6.99870002 4.6925 6.85670002 4.4015 6.61370002 4.2125 6.37170002 4.0225 6.05370002 3.9545 5.75570002 4.0305L5.75570002 4.0305zM21.9987 20.0005L18.9987 20.0005 18.9987 9.7665 21.9987 11.5665 21.9987 20.0005zM16.9987 20.0005L14.9987 20.0005 14.9987 17.5005C14.9987 16.6725 14.3267 16.0005 13.4987 16.0005 12.6707 16.0005 11.9987 16.6725 11.9987 17.5005L11.9987 20.0005 9.99870002 20.0005 9.99870002 11.0005 11.9987 11.0005 11.9987 9.0005 9.99870002 9.0005 9.99870002 7.0005 11.9987 7.0005 11.9987 5.0005 9.99870002 5.0005 9.99870002 2.2195 12.9987 2.8205 12.9987 12.0005C12.9987 12.3145 13.1467 12.6115 13.3987 12.8005L16.9987 15.5005 16.9987 20.0005zM23.5137 10.1425L18.5137 7.1425C18.2047 6.9575 17.8187 6.9525 17.5057 7.1305 17.1927 7.3075 16.9987 7.6405 16.9987 8.0005L16.9987 13.0005 14.9987 11.5005 14.9987 2.0005C14.9987 1.5235 14.6627 1.1135 14.1947 1.0205L9.19470002.0205C8.89870002-.0395 8.59670002.0365 8.36470002.2265 8.13270002.4165 7.99870002.7005 7.99870002 1.0005L7.99870002 21.0005C7.99870002 21.5525 8.44670002 22.0005 8.99870002 22.0005L22.9987 22.0005C23.5507 22.0005 23.9987 21.5525 23.9987 21.0005L23.9987 11.0005C23.9987 10.6485 23.8147 10.3235 23.5137 10.1425L23.5137 10.1425z'
						transform='translate(96 66)'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
