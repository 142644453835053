import ErrorImageUrl from '../../../assets/aiAssistantError.png';
import WorkingLionImageUrl from '../../../assets/aiAssistantThinking.png';
import TimeOutImageUrl from '../../../assets/aiAssistantTimeOut.png';
import BackgroundImageUrl from './aiAssistantThinkingBackground.svg';

export const AIContentGenerationWizardImageUrls = [
	ErrorImageUrl,
	TimeOutImageUrl,
	BackgroundImageUrl,
	WorkingLionImageUrl,
];
export const ThinkingModalOverlayBackgroundImageUrl = BackgroundImageUrl;
export const AIAssistantThinkingImageUrl = WorkingLionImageUrl;
export const AIAssistantTimeOutImageUrl = TimeOutImageUrl;
export const AIAssistantErrorImageUrl = ErrorImageUrl;

export enum AIContentGenerationWizardStep {
	Config = 'Config',
	ConfigWithContent = 'ConfigWithContent',
	Generating = 'Generating',
	RetryPrompt = 'RetryPrompt',
}

export enum AIContentGenerationWizardType {
	Email = 'Email',
	Social = 'Social',
	BlogPost = 'Blog',
}

export const AIContentGenerationWizardLoadingMessages: { [type in AIContentGenerationWizardType]: string[] } = {
	[AIContentGenerationWizardType.Email]: [
		"I'm working on that update.",
		'click* clack* click* clack* …This email is going to be great!',
		'Almost finished...',
	],
	[AIContentGenerationWizardType.Social]: [
		"I'm working on that update.",
		'click* clack* click* clack* …This post is going to be great!',
		'Almost finished...',
	],
	[AIContentGenerationWizardType.BlogPost]: [
		"I'm working on that blog post...",
		'All I do is blog blog blog no matter what...',
		'click* clack* click* clack* …This blog post is going to be great!',
		'Almost finished...',
	],
};

export const AIContentGenerationWizardAssistantEmailMessages = {
	[AIContentGenerationWizardStep.Config]: [
		`Let's get started on your email campaign.`,
		"Using plain language describe the type of content you'd like to create.",
	],
	[AIContentGenerationWizardStep.ConfigWithContent]: ['Ok, What would you like to change about this content?'],
	[AIContentGenerationWizardStep.Generating]: [''],
	[AIContentGenerationWizardStep.RetryPrompt]: ['Done! Do you like this new version?'],
};

export const AIContentGenerationWizardAssistantSocialMessages = {
	[AIContentGenerationWizardStep.Config]: [
		`Let's get started on your social campaign.`,
		"Using plain language describe the type of content you'd like to create.",
	],
	[AIContentGenerationWizardStep.ConfigWithContent]: ['Ok, What would you like to change about this content?'],
	[AIContentGenerationWizardStep.Generating]: [''],
	[AIContentGenerationWizardStep.RetryPrompt]: ['Done! Do you like this new version?'],
};

export const AIContentGenerationWizardAssistantBlogPostMessages = {
	[AIContentGenerationWizardStep.Config]: [
		`Let's get started on your blog post.`,
		"Using plain language describe the type of content you'd like to create.",
	],
	[AIContentGenerationWizardStep.ConfigWithContent]: ['Ok, What would you like to change about this content?'],
	[AIContentGenerationWizardStep.Generating]: [''],
	[AIContentGenerationWizardStep.RetryPrompt]: ['Done! Do you like this blog post?'],
};
