import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
	color?: string;
}

export const VisibilityIcon: React.FC<IProps> = props => {
	const { className = '', color, ...restProps } = props;
	return (
		<svg
			height='10'
			viewBox='0 0 15 10'
			width='15'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
			className={`${css(styleSheet.icon)} ${className}`}
		>
			<g fill={color || '#A7ABAD'} fillRule='evenodd'>
				<path d='M7.5,8.18181818 C4.66022727,8.18181818 2.35295455,5.74363636 1.54704545,4.77204545 C2.35090909,3.79977273 4.65,1.36363636 7.5,1.36363636 C10.3397727,1.36363636 12.6470455,3.80181818 13.4529545,4.77340909 C12.6490909,5.74568182 10.35,8.18181818 7.5,8.18181818 M14.8752273,4.38 C14.7497727,4.20136364 11.7422727,0 7.5,0 C3.25772727,0 0.250227273,4.20136364 0.124772727,4.38 C-0.0415909091,4.61590909 -0.0415909091,4.92954545 0.124772727,5.16545455 C0.250227273,5.34409091 3.25772727,9.54545455 7.5,9.54545455 C11.7422727,9.54545455 14.7497727,5.34409091 14.8752273,5.16545455 C15.0415909,4.92954545 15.0415909,4.61590909 14.8752273,4.38' />
				<path d='M7.5,6.13636364 C6.74795455,6.13636364 6.13636364,5.52477273 6.13636364,4.77272727 C6.13636364,4.02136364 6.74795455,3.40909091 7.5,3.40909091 C8.25204545,3.40909091 8.86363636,4.02136364 8.86363636,4.77272727 C8.86363636,5.52477273 8.25204545,6.13636364 7.5,6.13636364 M7.5,2.04545455 C5.99590909,2.04545455 4.77272727,3.26863636 4.77272727,4.77272727 C4.77272727,6.27681818 5.99590909,7.5 7.5,7.5 C9.00409091,7.5 10.2272727,6.27681818 10.2272727,4.77272727 C10.2272727,3.26863636 9.00409091,2.04545455 7.5,2.04545455' />
			</g>
		</svg>
	);
};
