import { brandPrimary } from '../../../styles/colors';
import { Fonts } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	compactFlag: {
		position: 'absolute',
		transform: 'translate3d(22px, -9px, 0)',
	},
	container: {},
	flag: {
		marginLeft: 10,
	},
	icon: {
		display: 'flex',
		height: 20,
		justifyContent: 'center',
		textAlign: 'center',
		verticalAlign: 'middle',
		width: 22,
	},
	link: {
		':hover': {
			backgroundColor: 'rgba(84, 160, 215, 0.26)',
			borderRadius: '0.75rem',
		},
		':not(:first-child)': {
			marginTop: '0.5rem',
		},
		alignItems: 'center',
		boxSizing: 'border-box',
		color: 'white',
		display: 'flex',
		height: 50,
		padding: '0.25rem 1rem',
		position: 'relative',
		textDecoration: 'none',
		width: '100%',
	},
	linkActive: {
		':before': {
			background: '#0095FF',
			content: '" "',
			display: 'block',
			height: 34,
			left: '-1rem',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 6,
		},
		backgroundColor: 'rgba(84, 160, 215, 0.26)',
		borderRadius: '0.75rem',
	},
	linkCompact: {
		justifyContent: 'center',
	},
	linkName: {
		fontFamily: Fonts.lexend,
		marginLeft: 15,
	},
	linkNameOutlined: {
		border: `1px solid ${brandPrimary}`,
		borderRadius: 3,
		padding: '4px 10px',
	},
});
