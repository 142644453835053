import { darkGrayFontColor, destructive, inputBorderColor, navigation, hotPink, titles } from '../../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	attachmentContainer: {
		margin: '10px 0',
	},
	body: {
		background: '#F9F9F9',
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 3,
		boxSizing: 'border-box',
		flexGrow: 1,
		marginTop: 10,
		minHeight: 280,
		overflow: 'auto',
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	bodyReadOnly: {
		background: '#fff',
		border: 'none',
		borderRadius: 0,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		boxSizing: 'border-box',
		paddingBottom: 30,
	},
	footerButtonGroup: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	footerButtonGroupRight: {
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	footerButtons: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 28,
	},
	footerCreatorLabel: {
		color: navigation,
		fontSize: 11,
		maxWidth: 100,
		...truncateTextStyle,
	},
	footerDeleteButton: {
		color: destructive,
	},
	footerIconButton: {
		alignItems: 'center',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 12,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	footerMessage: {
		color: '#858585',
		fontSize: 12,
		fontStyle: 'italic',
	},
	footerMessageNotCreator: {
		marginTop: 12,
	},
	inputLabel: {
		color: '#858585',
		fontSize: 12,
		marginRight: 10,
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	new: {
		color: hotPink,
		flexShrink: 0,
		fontSize: 18,
		fontWeight: 700,
	},
	readOnlyTemplateField: {
		alignItems: 'center',
		color: titles,
		fontSize: 16,
		marginBottom: 10,
	},
	readOnlyTemplateFieldLabel: {
		color: '#858585',
		fontSize: 12,
	},
	readOnlyTemplateFieldTitle: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	saveAsNewCompoundButton: {
		width: 200,
	},
	saveButton: {
		height: 40,
	},
	shareCheckbox: {
		color: darkGrayFontColor,
		fontSize: 12,
	},
	subjectInput: {
		marginTop: 10,
	},
});
