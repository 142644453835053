import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { Checkbox } from '../../../components/Checkbox';
import { EStatusOption, StatusFilterCheckboxesMap } from '../hooks/useStatusFilters';
import { styleSheet } from './styles';
import { v4 as uuid } from 'uuid';

interface ICheckboxOption {
	text: string;
	value: number;
}

// Note: There is a very similar component in the PeopleContainer that handles immediate saves only.
export const StatusFilterCheckboxes = ({
	className,
	styles,
	selectedStatuses,
	setSelectedStatuses,
}: {
	selectedStatuses: EStatusOption[];
	setSelectedStatuses: React.Dispatch<React.SetStateAction<EStatusOption[]>>;
	className?: string;
	styles?: StyleDeclarationValue[];
}) => {
	const userSession = useUserSession();

	const options = Object.keys(StatusFilterCheckboxesMap)
		.map(checkbox => {
			const filterOptionKey = parseInt(checkbox, 10);
			const option = StatusFilterCheckboxesMap[filterOptionKey as EStatusOption];
			return {
				text: option.text,
				value: filterOptionKey,
			};
		})
		.filter(option => {
			if (option.value === EStatusOption.Archived && userSession.userRole !== 'superAdmin') {
				return false;
			}
			return true;
		});

	const toggleOption = (option: ICheckboxOption) => {
		const selectionIndex = selectedStatuses.indexOf(option.value);
		if (selectionIndex > -1) {
			// @ts-ignore
			setSelectedStatuses(selectedStatuses.filter(value => value !== option.value));
			return;
		}
		// @ts-ignore
		setSelectedStatuses([...selectedStatuses, option.value]);
	};

	const disabledOptions = options
		.filter(option => {
			const opposingValues = {
				[EStatusOption.NoEmail]: EStatusOption.WithEmail,
				[EStatusOption.WithEmail]: EStatusOption.NoEmail,
				[EStatusOption.NoAddress]: EStatusOption.WithAddress,
				[EStatusOption.WithAddress]: EStatusOption.NoAddress,
				[EStatusOption.WithPhoneNumber]: EStatusOption.WithoutPhoneNumber,
				[EStatusOption.WithoutPhoneNumber]: EStatusOption.WithPhoneNumber,
			};
			const opposite: EStatusOption = opposingValues[option.value as keyof typeof opposingValues];

			if (opposite === undefined) {
				return false;
			}

			if (selectedStatuses.indexOf(opposite) > -1) {
				return true;
			}
			return false;
		})
		.map(option => option.value);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			{options.map(option => {
				return (
					<div className={css(styleSheet.checkboxWrapper)} key={option.value}>
						<Checkbox
							id={uuid()}
							onChange={() => toggleOption(option)}
							checked={selectedStatuses.indexOf(option.value) > -1}
							disabled={disabledOptions.indexOf(option.value) > -1}
						>
							<div className={css(styleSheet.checkboxText)}>{option.text}</div>
						</Checkbox>
					</div>
				);
			})}
		</div>
	);
};
