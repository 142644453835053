import { StyleSheet } from 'aphrodite';
import { header, titles } from '../../../../web/styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
	},
	infoIcon: {
		height: 14,
		marginLeft: 4,
		width: 14,
	},
	infoMessage: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		maxWidth: 310,
		padding: '6px 6px 6px 16px',
	},
	recipientCountHeader: {
		color: header,
		display: 'flex',
		gap: 4,
		margin: 0,
		fontSize: 14,
	},
	recipientCountLabel: {
		minWidth: 92,
	},
	recipientCountResult: {
		color: titles,
	},
});
