import * as Api from '@ViewModels';
import * as React from 'react';
import { isTagSearchContactFilterCriteria } from '../../../../extViewmodels/Utils';
import { AdvancedTagFilterCollection } from '../../../../models';

const findIndexOfMatchingTag = (collection: string[], item: string) => {
	if (!item || !collection?.length) {
		return -1;
	}
	return collection.findIndex(x => (x ? x.trim().toLocaleLowerCase() === item.trim().toLocaleLowerCase() : false));
};

const useTagList = (initialTags: string[] = []) => {
	const [tags, setTags] = React.useState<string[]>(initialTags);
	const [revertableTags] = React.useState<string[]>(initialTags);

	const addTags = (...args: string[]) => {
		const clonedTags = [...tags];
		args?.forEach(x => {
			const matchIndex = findIndexOfMatchingTag(clonedTags, x);
			if (matchIndex < 0) {
				clonedTags.push(x);
			}
		});
		if (clonedTags.length !== tags.length) {
			setTags(clonedTags);
		}
	};

	const removeTags = (...args: string[]) => {
		const t = [...tags];
		args?.forEach(x => {
			const matchIndex = findIndexOfMatchingTag(t, x);
			if (matchIndex >= 0) {
				t.splice(matchIndex, 1);
			}
		});
		if (t.length !== tags.length) {
			setTags(t);
		}
	};

	const cancelChanges = () => {
		setTags(revertableTags);
	};

	return [tags, addTags, removeTags, cancelChanges, setTags] as const;
};

export function useTagFilters({
	initialFilterCriteria = [],
}: {
	initialFilterCriteria?: Api.IContactFilterCriteria[];
}) {
	const initialTagFilters = React.useMemo(() => {
		const tagFilterCriteriaCollection = initialFilterCriteria.filter(x => isTagSearchContactFilterCriteria(x));
		return AdvancedTagFilterCollection.instanceWithCriteria(tagFilterCriteriaCollection);
	}, [initialFilterCriteria]);

	const [andTags, addAndTag, removeAndTag, cancelAndTags, setAndTags] = useTagList(
		initialTagFilters.filters.filter(x => x.type === Api.FilterOperator.And).map(x => x.tag)
	);

	const [notTags, addNotTag, removeNotTag, cancelNotTags, setNotTags] = useTagList(
		initialTagFilters.filters.filter(x => x.type === Api.FilterOperator.Not).map(x => x.tag)
	);

	const filteredNotTags = React.useMemo(() => {
		return notTags.filter(x => x !== 'Email Bounced' && x !== 'Unsubscribe');
	}, [notTags]);

	const [orTags, addOrTag, removeOrTag, cancelOrTags, setOrTags] = useTagList(
		initialTagFilters.filters.filter(x => x.type === Api.FilterOperator.Or).map(x => x.tag)
	);

	const [showingAndTags, setShowingAndTags] = React.useState(andTags.length > 0);
	const [showingNotTags, setShowingNotTags] = React.useState(filteredNotTags.length > 0);

	React.useEffect(() => {
		if (andTags.length > 0) {
			setShowingAndTags(true);
		}
		if (filteredNotTags.length > 0) {
			setShowingNotTags(true);
		}
	}, [andTags.length, filteredNotTags.length]);

	const toCriteria = React.useCallback(() => {
		const criteria = [
			[andTags, Api.FilterOperator.And] as const,
			[notTags, Api.FilterOperator.Not] as const,
			[orTags, Api.FilterOperator.Or] as const,
		]
			.filter(x => {
				const [collection] = x;
				return collection.length !== 0;
			})
			.map(x => {
				const [collection, op] = x;
				return collection.reduce<Api.IContactFilterCriteria>(
					(res, tag) => {
						const newCriterion: Api.IContactFilterCriteria = {
							op,
							property: Api.ContactFilterCriteriaProperty.Tag,
							value: tag,
						};
						res.criteria!.push(newCriterion);
						return res;
					},
					{ criteria: [], op }
				);
			});
		const atfCollection = AdvancedTagFilterCollection.instanceWithCriteria(criteria);
		return atfCollection.toCriteriaCollection();
	}, [andTags, notTags, orTags]);

	const isTagCriteria = (criteria: Api.IContactFilterCriteria) => {
		if (criteria.property === Api.ContactFilterCriteriaProperty.Tag) {
			return true;
		}
		if (criteria.criteria) {
			return !!criteria.criteria.find(criterion => {
				return criterion.property === Api.ContactFilterCriteriaProperty.Tag;
			});
		}
		return false;
	};

	const handleNotCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		setShowingNotTags(checked);

		const isHiddenTags = !checked && notTags.length > filteredNotTags.length;
		if (isHiddenTags) {
			const hiddenTags = notTags.filter(tag => tag === 'Email Bounced' || tag === 'Unsubscribe');
			setNotTags(hiddenTags);
		} else if (!checked) {
			setNotTags([]);
		}
	};
	const handleAndCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		setShowingAndTags(checked);
		if (!checked) {
			setAndTags([]);
		}
	};
	const cancelChanges = () => {
		cancelAndTags();
		cancelNotTags();
		cancelOrTags();
	};
	const refreshTags = (newCriteria: Api.IContactFilterCriteria[]) => {
		const tagFilterCriteriaCollection = newCriteria.filter(x => isTagSearchContactFilterCriteria(x));
		const newCriteriaCollection = AdvancedTagFilterCollection.instanceWithCriteria(tagFilterCriteriaCollection);

		setAndTags(newCriteriaCollection.filters.filter(x => x.type === Api.FilterOperator.And).map(x => x.tag));
		setNotTags(newCriteriaCollection.filters.filter(x => x.type === Api.FilterOperator.Not).map(x => x.tag));
		setOrTags(newCriteriaCollection.filters.filter(x => x.type === Api.FilterOperator.Or).map(x => x.tag));
	};

	return {
		addAndTag,
		addNotTag,
		addOrTag,
		andTags,
		filteredNotTags,
		handleAndCheckChange,
		handleNotCheckChange,
		notTags,
		orTags,
		removeAndTag,
		removeNotTag,
		removeOrTag,
		showingAndTags,
		showingNotTags,
		toCriteria,
		cancelChanges,
		refreshTags,
		isTagCriteria,
	};
}
