import { StyleSheet } from 'aphrodite';
import { background, grayIconFill, mentionDark, spanishBlue, titles } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	addMediaContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 14,
		maxWidth: 365,
	},
	contentContainers: {
		color: '#a7abad',
		fontSize: 14,
	},
	iconButtons: {
		background,
		border: `1px solid ${mentionDark}`,
		color: titles,
		display: 'flex',
		fontSize: 16,
		marginBottom: 4,
		minWidth: 142,
		padding: 8,
	},
	freeImage: {
		paddingLeft: 6,
		paddingRight: 6,
	},
	sourceMention: {
		color: grayIconFill,
		fontSize: 12,
		fontStyle: 'italic',
		minWidth: 142,
		textAlign: 'center',
	},
	ownImage: {
		color: spanishBlue,
		fontSize: 14,
		marginBottom: 13,
		marginTop: 12,
	},
});
