import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	/** If this is truthy, then it will be displayed */
	errorMessage?: React.ReactNode;
}

/** Wrapper component that provides a means of showing an styled error message below an input field (props.children) */
export const InputFieldError: React.FC<IProps> = props => {
	const { className, children, errorMessage } = props;
	return (
		<div className={`input-field-error ${className || ''}`}>
			{children}
			{!!errorMessage && <div className={`input-field-error-message ${css(styleSheet.message)}`}>{errorMessage}</div>}
		</div>
	);
};
