import * as React from 'react';
import { brandPrimary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fill?: string;
	height?: number;
	width?: number;
}

export const EnlargeIcon: React.FC<IProps> = props => {
	const { className, fill = brandPrimary, height = 20, width = 20 } = props;
	return (
		<>
			<SvgIcon
				className={`enlargeIcon ${className ?? ''}`}
				width={width}
				height={height}
				viewBox={`0 0 ${width} ${height}`}
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M8.29178 14C11.6008 14 14.2918 11.309 14.2918 8C14.2918 4.691 11.6008 2 8.29178 2C4.98278 2 2.29178 4.691 2.29178 8C2.29178 11.309 4.98278 14 8.29178 14M8.29178 0C12.7028 0 16.2918 3.589 16.2918 8C16.2918 9.846 15.6578 11.542 14.6038 12.897L19.9988 18.293L18.5848 19.707L13.1898 14.312C11.8348 15.366 10.1378 16 8.29178 16C3.88078 16 0.291779 12.411 0.291779 8C0.291779 3.589 3.88078 0 8.29178 0'
					fill={fill}
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.29199 7H7.29199V4H9.29199V7H12.292V9H9.29199V12H7.29199V9H4.29199V7Z'
					fill={fill}
				/>
			</SvgIcon>
		</>
	);
};
