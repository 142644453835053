import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useLocation } from 'react-router';
import {
	ICampaignApprovalCommand,
	ICreateCampaignRequest,
	ICreateSocialMediaPostRequest,
	IImpersonationContextComponentProps,
	ILocationState,
	ImpersonationContextKey,
	TemplateOrTemplateFilter,
} from '../../../models';
import { CampaignType } from '../../../models/AdminModels';
import { BlogPostRequest } from '../../../models/Blogs';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { usePersistChangeInBrowserStorage } from '../../../models/hooks/usePersistChangeInBrowserStorage';
import { ILocalNotification } from '../../../models/LocalNotifications';
import { Topics } from '../../../models/LocalNotificationTopics';
import { useEventLogging } from '../../../models/Logging';
import { storagePrefixExcludedFromClear } from '../../../models/Storage';
import {
	getBlogPostAsync,
	getBlogPostTemplateAsync,
	invalidateAllContentCalendarSuggestions,
	invalidateEmailContentCalPendingApprovalQuery,
	invalidateSocialContentCalPendingApprovalQuery,
	useContentCalendarSuggestionFilterQueries,
	useContentCalendarSuggestionFilterQuery,
	useEmailContentCalApproveMutation,
	useEmailContentCalPendingApprovalQuery,
	useSocialContentCalApproveMutation,
	useSocialContentCalPendingApprovalQuery,
} from '../../../queries';
import { CampaignsApprovalViewModel, ITemplateCard } from '../../../viewmodels/AppViewModels';
import { CampaignCalendar, ICampaignCalendarComponent } from '../../components/campaigns/CampaignCalendar';
import {
	EventDataType,
	ICampaignCalendarEventPlaceholder,
	ICampaignCalendarOptions,
} from '../../components/campaigns/CampaignCalendar/models';
import { FabContext } from '../../components/FabContext';
import { MediaQueryObserver } from '../../components/helpers/mediaQuery';
import { LocalNotificationObserver } from '../../components/LocalNotificationObserver';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { ISelectOption, Select } from '../../components/Select';
import { ApproveAllIcon } from '../../components/svgs/icons/ApproveAllIcon';
import { Toggle } from '../../components/Toggle';
import { brandPrimary, inputBorderColor } from '../../styles/colors';
import { bs } from '../../styles/styles';
import {
	ContentCalendarTemplateBrowser,
	IContentCalendarTemplateBrowserComponent,
} from './ContentCalendarTemplateBrowser';
import { ContentCalendarLegend } from './ContentCalendarTemplateBrowser/presentation';
import { CalendarType, GhostCalendarAccountAgeOptions, GhostCalendarCampaignTypeOptions } from './models';
import { styleSheet } from './styles';

interface Props extends IImpersonationContextComponentProps {
	calendarOptions?: ICampaignCalendarOptions;
	templateType?: CampaignType;
	styles?: StyleDeclarationValue[];
	className?: string;
	onOpenCampaignComposer?(template: Api.ITemplate, schedule?: Api.IScheduledSend, referrer?: string): boolean;
	onCampaignClicked?(e: React.MouseEvent<HTMLElement>, campaign: Api.CampaignViewModel): void;
	onBlogPostClicked?(e: React.MouseEvent<HTMLElement, MouseEvent>, blog: Api.IBlogReportView): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	/** @returns True if handled */
	onNotifyClientClicked?(campaign: EventDataType): void;
	onCampaignCalendarRef?(ref?: ICampaignCalendarComponent): void;
	onRenderHeaderAccessory?(): React.ReactNode;
	onSocialMediaPostClicked?(
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		postReport: Api.SocialMediaPostReportViewModel
	): Promise<any>;
	onSuggestionClicked?(
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		suggestion: Api.IContentCalendarSuggestion
	): Promise<any>;
	onSuggestedSocialMediaEventClicked?(
		e: React.MouseEvent<HTMLElement>,
		suggestion: Api.IContentCalendarSuggestion
	): Promise<any>;
}

export interface IContentCalendarComponent {
	loadAllCalendarData?(): Promise<any>;
}

const newAccountCutoffDays = 30;
const numMonthsInQuarterly = 3;
const droppableType = 'campaignTemplateCard';

const _ContentCalendar = React.forwardRef<IContentCalendarComponent, Props>(function ContentCalendarBase(props, ref) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const location = useLocation<ICampaignApprovalCommand>();
	const { logApiError, logEvent } = useEventLogging('ContentCalendar');
	const fullscreenModal = useFullscreenModal();
	const [month, setMonth] = React.useState(moment().startOf('month').toDate());
	const [templateType, setTemplateType] = React.useState(props.templateType);
	const [calendarType, setCalendarType] = usePersistChangeInBrowserStorage<CalendarType>({
		storageType: 'localStorage',
		storageKey: `${storagePrefixExcludedFromClear}-content-calendar-calendar-type`,
		defaultIfEmpty: CalendarType.Quarterly,
		skipPersistFn: () => props.impersonationContext?.isValid,
	});
	const [selectedAccountAge, setSelectedAccountAge] = React.useState<
		ISelectOption<Api.ContentCalendarSuggestionAccountAge>
	>(() => {
		if (!props.impersonationContext) {
			const isNewAccount = moment(userSession.account.creationDate) > moment().subtract(newAccountCutoffDays, 'days');
			if (isNewAccount) {
				return GhostCalendarAccountAgeOptions.find(
					option => option.dataContext === Api.ContentCalendarSuggestionAccountAge.New
				);
			}
		}
		return GhostCalendarAccountAgeOptions[0];
	});

	const isImpersonating = props.impersonationContext?.account?.id;
	const isBlogsEnabled = isImpersonating
		? props.impersonationContext.account.features?.blogFeature?.enabled
		: userSession.account?.features?.blogFeature.enabled;

	const isSocialMediaEnabled = isImpersonating
		? props.impersonationContext.account.features?.socialMedia?.enabled
		: userSession.account?.features?.socialMedia?.enabled;

	const enabledTypes = React.useMemo<CampaignType[]>(() => {
		const types = [CampaignType.Email];
		if (isSocialMediaEnabled) {
			types.push(CampaignType.Social);
		}
		if (isBlogsEnabled) {
			types.push(CampaignType.Blog);
		}
		return types;
	}, [isBlogsEnabled, isSocialMediaEnabled]);
	const templateTypeOptions = React.useMemo<ISelectOption<CampaignType>[]>(() => {
		return enabledTypes.map(x => {
			return {
				dataContext: x,
				id: `template-type-option-${x}`,
				text: x.valueOf(),
			};
		});
	}, [enabledTypes]);

	const [selectedCampaignType, setSelectedCampaignType] = usePersistChangeInBrowserStorage<ISelectOption<CampaignType>>(
		{
			storageType: 'localStorage',
			storageKey: `${storagePrefixExcludedFromClear}-content-calendar-selected-campaign-type`,
			skipPersistFn: () => props.impersonationContext?.isValid,
			defaultIfEmpty: GhostCalendarCampaignTypeOptions.find(
				x => x.dataContext === (isSocialMediaEnabled ? null : CampaignType.Email)
			),
		}
	);
	const [placeholders, setPlaceholders] = React.useState<ICampaignCalendarEventPlaceholder<ITemplateCard>[]>([]);
	const [showSuggestionsToggleValue, setShowSuggestionsToggleValue] = usePersistChangeInBrowserStorage<boolean>({
		storageType: 'localStorage',
		storageKey: `${storagePrefixExcludedFromClear}-content-calendar-suggestion-toggle`,
		skipPersistFn: () => props.impersonationContext?.isValid,
		defaultIfEmpty: false,
	});
	const campaignCalendarRef = React.useRef<ICampaignCalendarComponent>(null);
	const contentCalendarTemplateBrowserRef = React.useRef<IContentCalendarTemplateBrowserComponent>();
	const lastCampaignIdForApprovalRef = React.useRef<string>(null);
	const canShowSuggestions =
		Api.isAdmin(userSession.user) ||
		(props.impersonationContext && Api.isImpersonatingAccountAdmin(props.impersonationContext));
	React.useImperativeHandle(
		ref,
		() => ({
			loadAllCalendarData,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedCampaignType, month]
	);
	const emailContentCalPendingApprovalQuery = useEmailContentCalPendingApprovalQuery({
		enabled: !props.impersonationContext,
	});
	const socialContentCalPendingApprovalQuery = useSocialContentCalPendingApprovalQuery({
		enabled: !props.impersonationContext,
	});
	let totalPendingApprovals = 0;
	if (emailContentCalPendingApprovalQuery.data != null) {
		totalPendingApprovals += emailContentCalPendingApprovalQuery.data;
	}
	if (socialContentCalPendingApprovalQuery.data != null) {
		totalPendingApprovals += socialContentCalPendingApprovalQuery.data;
	}
	const [campaignTypes, setCampaignTypes] = usePersistChangeInBrowserStorage<string[]>({
		storageKey: `${storagePrefixExcludedFromClear}-content-calendar-campaign-type`,
		storageType: 'localStorage',
		defaultIfEmpty: enabledTypes,
	});
	const emailContentCalApproveMutation = useEmailContentCalApproveMutation();
	const socialContentCalApproveMutation = useSocialContentCalApproveMutation();
	const suggestionQueriesParams = Array.from({ length: numMonthsInQuarterly }, (_, i) => i).map(i => {
		const currentMonth = new Date(month);
		const suggestionMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + i));
		const param: Parameters<typeof useContentCalendarSuggestionFilterQuery>[0] = {
			enabled: Boolean(showSuggestionsToggleValue),
			month: suggestionMonth.toISOString(),
			impersonationContext: props.impersonationContext,
			request: {
				accountAges:
					selectedAccountAge.dataContext === Api.ContentCalendarSuggestionAccountAge.New
						? [Api.ContentCalendarSuggestionAccountAge.New]
						: [Api.ContentCalendarSuggestionAccountAge.New, Api.ContentCalendarSuggestionAccountAge.Existing],
				industries: [
					props.impersonationContext?.account?.additionalInfo?.industry ?? userSession.account.additionalInfo.industry,
				],
				subverticals: props.impersonationContext?.account?.preferences?.subverticalSettings?.subverticals ??
					userSession.account.preferences.subverticalSettings?.subverticals ?? [Api.ContentCalendarSuggestionAll],
			},
		};
		return param;
	});
	const suggestionQueries = useContentCalendarSuggestionFilterQueries(suggestionQueriesParams);
	let suggestions: Api.IContentCalendarSuggestion[] = [];
	for (const suggestionQuery of suggestionQueries) {
		if (suggestionQuery.isSuccess) {
			suggestions = suggestions.concat(
				suggestionQuery.data.filter(suggestion => {
					if (selectedCampaignType.dataContext === CampaignType.Social) {
						return suggestion?.templateReference?.templateType === Api.TemplateType.SocialMediaPost;
					}
					if (selectedCampaignType.dataContext === CampaignType.Email) {
						return suggestion?.templateReference?.templateType === Api.TemplateType.Email;
					}
					// TODO: Support for blog only suggestions filter?
					return Boolean(suggestion);
				})
			);
		}
	}
	const loadAllCalendarData = async () => {
		invalidateAllContentCalendarSuggestions();
		if (!props.impersonationContext) {
			invalidateEmailContentCalPendingApprovalQuery();
			invalidateSocialContentCalPendingApprovalQuery();
		}
		if (campaignCalendarRef.current) {
			await campaignCalendarRef.current.loadCampaigns();
		}
	};
	const launchReviewAndApproveSocial = async (campaign: Api.SocialMediaPostReportViewModel) => {
		lastCampaignIdForApprovalRef.current = campaign.id;
		try {
			const post = await campaign?.load();
			const locationState: ILocationState<Api.SocialMediaPostReportViewModel, any> = {
				viewModel: new Api.SocialMediaPostReportViewModel(userSession, post),
			};
			fullscreenModal?.history?.push({
				pathname: '/social-media/post/approval-preview',
				state: locationState,
			});
		} catch (error) {
			logApiError('LaunchReviewAndApproveSocial-Error', error);
			errorMessages.pushApiError(error);
		}
	};
	const loadBlogPostAsync = async (blogId: string) => {
		return getBlogPostAsync({ id: blogId, impersonationContext: props.impersonationContext, userSession });
	};
	const launchEditBlogPost = (post: Api.IBlogPost) => {
		const locationState: ILocationState<any, BlogPostRequest> = {
			model: {
				post,
			},
		};
		fullscreenModal?.history?.push({
			pathname: `/blog/post/edit/${post.id}`,
			state: locationState,
		});
	};
	React.useEffect(() => {
		if (props.impersonationContext?.account && props.impersonationContext?.user) {
			loadAllCalendarData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.impersonationContext?.user]);
	React.useEffect(() => {
		const initialize = async () => {
			const campaignApprovalCommand = location.state;
			if (campaignApprovalCommand) {
				switch (campaignApprovalCommand.command) {
					case 'social-approval': {
						const vm = new Api.SocialMediaPostReportViewModel(userSession, {
							id: campaignApprovalCommand.id,
						}).impersonate(props.impersonationContext);
						launchReviewAndApproveSocial(vm);
						break;
					}
					case 'blog-approval': {
						try {
							const blogPost = await loadBlogPostAsync(campaignApprovalCommand.id);
							launchEditBlogPost(blogPost);
						} catch (error) {
							logApiError('BlogPostLoad-Error', error);
						}
						break;
					}
					default: {
						break;
					}
				}
			}
		};
		initialize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const loadTemplate = (templateId: string) => {
		try {
			return new Api.TemplatesViewModel(userSession)
				.impersonate(props.impersonationContext)
				.getByIdExpandedByLastUsedBy(templateId);
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadTemplate-Error', error);
		}
	};
	const openCampaignComposer = (
		template: Api.ITemplate,
		schedule?: Api.IScheduledSend,
		referrer = 'contentCalendar'
	) => {
		if (!props.onOpenCampaignComposer?.(template, schedule, referrer)) {
			if (template?.templateType === Api.TemplateType.Email || !template?.templateType) {
				const locationStateEmail: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
					model: {
						context: template,
						onFinish: (didSend: boolean) => {
							if (didSend) {
								loadAllCalendarData();
							}
						},
						schedule,
						type: 'Template',
					},
				};
				fullscreenModal?.history.push({
					pathname: '/email/campaigns/create/from-template',
					state: locationStateEmail,
				});
			} else if (template.templateType === Api.TemplateType.Blog) {
				const locationState: ILocationState<any, BlogPostRequest> = {
					model: {
						schedule,
						template,
					},
				};
				fullscreenModal?.history.push({
					pathname: `/blog/post/create-from-template/${template.id}`,
					state: locationState,
				});
			} else if (template.templateType === Api.TemplateType.SocialMediaPost) {
				const locationStateSocial: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate>> = {
					model: {
						context: template,
						onFinish: (didSend: boolean) => {
							if (didSend) {
								loadAllCalendarData();
							}
						},
						schedule,
						sendFromUser: userSession.user,
						type: 'Template',
					},
				};
				fullscreenModal?.history.push({
					pathname: `/social-media/post/create/from-template`,
					state: locationStateSocial,
				});
			}
		}
	};
	const onDragEnd = async (result: DropResult) => {
		if (result.destination?.droppableId && result.destination?.droppableId !== result.source?.droppableId) {
			const templateId = result.draggableId;
			const templateCard: ITemplateCard =
				contentCalendarTemplateBrowserRef.current?.getTemplateCards?.()?.[result.source.index];
			if (templateCard) {
				setPlaceholders([
					{
						dataContext: templateCard,
						moment: moment(result.destination.droppableId),
						status:
							templateType === CampaignType.Email
								? Api.EmailSendStatus.Queued
								: templateType === CampaignType.Social
									? Api.PostStatus.Scheduled
									: Api.BlogStatus.Queued,
						subject: templateCard.name,
					},
				]);
			}
			try {
				const template = await loadTemplate(templateId);
				openCampaignComposer(template, {
					startDate: result.destination?.droppableId,
				});
			} catch (error) {
				setPlaceholders([]);
			}
		}
	};
	const onApprovePendingApprovals = async () => {
		logEvent('ApprovePendingApprovals');
		try {
			await Promise.all([
				emailContentCalApproveMutation.mutateAsync({
					impersonationContext: props.impersonationContext,
				}),
				socialContentCalApproveMutation.mutateAsync({
					impersonationContext: props.impersonationContext,
				}),
			]);
			loadAllCalendarData();
		} catch (error) {
			logApiError('ApprovePendingApprovals-Error', error);
		}
	};
	const toggle = () => {
		setShowSuggestionsToggleValue(!showSuggestionsToggleValue);
	};
	const launchReviewAndApprove = (campaign: Api.CampaignViewModel) => {
		lastCampaignIdForApprovalRef.current = campaign.id;
		const locationState: ILocationState<CampaignsApprovalViewModel, any> =
			campaign instanceof Api.CampaignViewModel
				? {
						viewModel: new CampaignsApprovalViewModel(userSession, [campaign]),
					}
				: null;
		fullscreenModal?.history?.push({
			pathname: '/email/campaigns/edit',
			state: locationState,
		});
	};
	const onCampaignClicked = async (e: React.MouseEvent<HTMLElement>, campaign: Api.CampaignViewModel) => {
		props.onCampaignClicked?.(e, campaign);
		if (!e.defaultPrevented && campaign.status === Api.EmailSendStatus.Queued) {
			e.preventDefault();
			const locationState: ILocationState<Api.CampaignViewModel, any> = {
				viewModel: campaign,
			};
			fullscreenModal?.history?.push({
				pathname: '/email/campaigns/edit',
				state: locationState,
			});
		}
	};
	const onBlogPostClicked = async (e: React.MouseEvent<HTMLElement>, blog: Api.IBlogReportView) => {
		props.onBlogPostClicked?.(e, blog);
		if (!e.defaultPrevented && blog.id) {
			e.preventDefault();
			try {
				const result = await loadBlogPostAsync(blog.id);
				launchEditBlogPost(result);
			} catch (error) {
				errorMessages.pushApiError(error);
			}
		}
	};
	const onMonthsChange = (value: moment.Moment) => {
		const newMonth = value.toDate();
		setMonth(newMonth);
	};
	const onCalendarRef = (calRef: ICampaignCalendarComponent) => {
		campaignCalendarRef.current = calRef;
		props.onCampaignCalendarRef?.(calRef);
	};
	const onSocialMediaEventClicked = async (
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		postReport: Api.SocialMediaPostReportViewModel
	) => {
		props.onSocialMediaPostClicked?.(e, postReport);
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();
		e?.stopPropagation();
		const locationState: ILocationState<Api.SocialMediaPostReportViewModel, any> = { viewModel: postReport };

		if (postReport?.status === Api.PostStatus.Pending) {
			fullscreenModal?.history.push({
				pathname: '/social-media/post/approval-preview',
				state: locationState,
			});
		} else {
			fullscreenModal?.history.push({
				pathname: `/social-media/post/edit-from-post-report`,
				state: locationState,
			});
		}
	};

	const onSuggestedEventClicked = async (
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		suggestion: Api.IContentCalendarSuggestion
	) => {
		props.onSuggestionClicked?.(e, suggestion);
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();

		if (suggestion.templateReference.templateType === Api.TemplateType.Blog) {
			try {
				const result = await getBlogPostTemplateAsync({
					id: suggestion.templateReference.templateId,
					userSession,
					impersonationContext: props.impersonationContext?.toJs(),
				});
				if (!result.contentCalendarSuggestionId) {
					result.contentCalendarSuggestionId = suggestion.id;
				}
				const locationState: ILocationState<any, BlogPostRequest> = {
					model: {
						impersonationContext: props.impersonationContext?.toJs(),
						template: result,
						schedule: suggestion.schedule,
					},
				};
				fullscreenModal?.history?.push({
					pathname: `/blog/post/create-from-template/${result.id}`,
					state: locationState,
				});
			} catch (error) {
				logApiError('LoadSuggestion-Error', error);
				errorMessages.pushApiError(error);
			}
			return;
		}

		const viewModel = new Api.ContentCalanderSuggestionViewModel(userSession, suggestion).impersonate(
			props.impersonationContext
		);
		try {
			await viewModel.load();
			const locationState: ILocationState<Api.ContentCalanderSuggestionViewModel, any> = { viewModel };
			fullscreenModal?.history?.push({
				pathname: '/email/campaigns/create/from-suggested-campaign',
				state: locationState,
			});
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadSuggestion-Error', error);
		}
	};

	const onSuggestedSocialMediaEventClicked = async (
		e: React.MouseEvent<HTMLElement>,
		suggestion: Api.IContentCalendarSuggestion
	) => {
		props.onSuggestedSocialMediaEventClicked?.(e, suggestion);
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();

		try {
			const locationState: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate | string>> = {
				model: {
					context: suggestion?.templateReference?.templateId,
					onFinish: (didSend: boolean) => {
						if (didSend) {
							loadAllCalendarData();
						}
					},
					schedule: suggestion.schedule,
					targets: suggestion.targets,
					type: 'Template',
				},
			};
			fullscreenModal?.history.push({
				pathname: `/social-media/post/edit-template/${suggestion.templateReference.templateId}/${suggestion.id}`,
				state: locationState,
			});
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadSuggestion-Error', error);
		}
	};
	const onFullscreenModalLifecycleNotification = (notification: ILocalNotification<string>) => {
		if (notification.info === 'onAfterClose') {
			setPlaceholders([]);
		}
	};
	const onEditCampaignNotificationReceived = async (notification?: ILocalNotification<Api.ICampaign[]>) => {
		if (
			notification?.info?.some(
				x => x.id === lastCampaignIdForApprovalRef.current && x.status !== Api.EmailSendStatus.WaitingForApproval
			) ||
			notification?.info.length === 0 ||
			notification?.info[0]?.status === Api.EmailSendStatus.Cancelled
		) {
			// reload if the status has changed
			loadAllCalendarData();
			lastCampaignIdForApprovalRef.current = null;
		}
	};
	const onRefreshFromNotification = async (notification?: ILocalNotification<any>) => {
		if (notification?.info) {
			loadAllCalendarData();
		}
	};
	const onEditSocialNotificationReceived = async (notification?: ILocalNotification<Api.SocialMediaPostViewModel>) => {
		if (notification?.info) {
			// reload if the status has changed
			loadAllCalendarData();
		}
	};
	const onEditBlogPost = (notification?: ILocalNotification<Api.IBlogPost>) => {
		if (notification?.info) {
			// reload if the status has changed
			loadAllCalendarData();
		}
	};

	// order matters
	const computedCalendarContainerCardStyles = [
		bs.flex1,
		styleSheet.calendarContainerCard,
		showSuggestionsToggleValue && calendarType === CalendarType.Quarterly
			? styleSheet.calendarContainerCardWithSuggestions
			: null,
		totalPendingApprovals > 0 &&
			calendarType === CalendarType.Quarterly &&
			(showSuggestionsToggleValue
				? styleSheet.calendarContainerCardWithAlertAndSuggestions
				: styleSheet.calendarContainerCardWithAlert),
	];
	let monthsToShow: number;
	switch (calendarType) {
		case CalendarType.Month:
			monthsToShow = 1;
			break;
		case CalendarType.Weekly:
			monthsToShow = 0;
			break;
		case CalendarType.Quarterly:
		default:
			monthsToShow = numMonthsInQuarterly;
			break;
	}

	return (
		<div className={`${css(styleSheet.container, ...(props.styles || []))} content-calendar ${props.className || ''}`}>
			<MultiContainerHeader appBarHeader={<h1 className={css(bs.breadcrumbTitle)}>Content Calendar</h1>} />
			<DarkMainContainerBackground />
			<FabContext appearance={{ hidden: true }} />
			<div className={css(bs.flex, bs.boxBorder, bs.hFull, bs.pt8, bs.gap4)}>
				<DragDropContext onDragEnd={onDragEnd}>
					<>
						<div className={css(bs.flex, bs.flexCol, bs.flex1, bs.pb2, styleSheet.calendarContainer)}>
							{totalPendingApprovals > 0 ? (
								<div className={css(styleSheet.pendingApprovalAlert)}>
									<figure>
										<ApproveAllIcon />
									</figure>
									<span className={css(bs.uppercase, styleSheet.pendingApprovalAlertText)}>
										<strong className={css(styleSheet.pendingApprovalText)}>Pending Approval</strong>
										<strong>{totalPendingApprovals}</strong>
									</span>
									<div className={css(styleSheet.pendingApprovalAlertButtonContainer)}>
										<button className={css(styleSheet.pendingApprovalAlertButton)} onClick={onApprovePendingApprovals}>
											<span>Approve All</span>
										</button>
									</div>
								</div>
							) : null}

							{month && (
								<div className={css(bs.horizontalStack, styleSheet.suggestionsToggleContainer)}>
									<div className={css(bs.flex, styleSheet.typesKey)}>
										<label className={css(styleSheet.selectLabel, styleSheet.campaignTypeLabel)}>Campaigns</label>
										<ContentCalendarLegend
											campaignTypes={enabledTypes}
											onSetCampaignType={setCampaignTypes}
											showText={!showSuggestionsToggleValue}
										/>
									</div>

									<div className={css(styleSheet.filterDropdownContainer)}>
										{showSuggestionsToggleValue ? (
											<>
												<div>
													<label className={css(styleSheet.selectLabel)}>Showing</label>
													<Select
														onOptionClick={setSelectedAccountAge}
														options={GhostCalendarAccountAgeOptions}
														selectedOptionTitle={
															<div className={css(styleSheet.triggerContainer)}>
																<span className={css(styleSheet.triggerValue)}>{selectedAccountAge?.text}</span>
															</div>
														}
														styles={[styleSheet.triggerContainerShowing]}
													/>
												</div>
												<div>
													<label className={css(styleSheet.selectLabel)}>Campaign Type</label>
													{isSocialMediaEnabled ? (
														<Select
															onOptionClick={setSelectedCampaignType}
															options={GhostCalendarCampaignTypeOptions}
															selectedOptionTitle={
																<div
																	className={css(styleSheet.triggerContainer, styleSheet.triggerContainerCampaignType)}
																>
																	<span className={css(styleSheet.triggerValue)}>{selectedCampaignType?.text}</span>
																</div>
															}
															styles={[styleSheet.triggerContainerShowing]}
														/>
													) : null}
												</div>
											</>
										) : null}
										{canShowSuggestions ? (
											<div className={css(styleSheet.suggestionToggleWrap)}>
												<Toggle
													checkedColor={brandPrimary}
													id='show-suggestions-toggle'
													isOn={showSuggestionsToggleValue}
													onToggleCheckChanged={toggle}
													text={!showSuggestionsToggleValue ? 'Turn on suggestions!' : 'Turn off suggestions'}
													textStyles={[styleSheet.suggestionsToggleText]}
													uncheckedColor={inputBorderColor}
												/>
											</div>
										) : null}
									</div>
								</div>
							)}
							<div className={css(...computedCalendarContainerCardStyles)}>
								<MediaQueryObserver minWidth={1300}>
									{() => {
										return (
											<CampaignCalendar
												calendarType={calendarType}
												droppableType={droppableType}
												initialStartDate={month}
												key={monthsToShow}
												numberOfMonthsToShow={monthsToShow}
												onApproveCampaignClicked={launchReviewAndApprove}
												onApproveSocialCampaignClicked={launchReviewAndApproveSocial}
												onCalendarTypeChanged={setCalendarType}
												onCampaignClicked={onCampaignClicked}
												onCampaignGroupClicked={props.onCampaignGroupClicked}
												onBlogPostClicked={onBlogPostClicked}
												onMonthsChange={onMonthsChange}
												onNotifyClientClicked={props.onNotifyClientClicked}
												ref={onCalendarRef}
												onRenderHeaderAccessory={props.onRenderHeaderAccessory}
												onSocialMediaEventClicked={onSocialMediaEventClicked}
												onSuggestedEventClicked={onSuggestedEventClicked}
												onSuggestedSocialMediaEventClicked={onSuggestedSocialMediaEventClicked}
												options={props.calendarOptions}
												placeholders={placeholders}
												styles={[styleSheet.calendar]}
												suggestions={showSuggestionsToggleValue ? suggestions : null}
												templateType={templateType}
												campaignTypes={campaignTypes}
											/>
										);
									}}
								</MediaQueryObserver>
							</div>
						</div>
						<ContentCalendarTemplateBrowser
							ref={contentCalendarTemplateBrowserRef}
							cardCtaText={`View ${templateType === CampaignType.Blog ? 'Blog Post' : 'Campaign'}`}
							dragDropProps={{
								droppableId: 'campaignTemplatesList',
								type: droppableType,
								draggingStyles: [
									calendarType === CalendarType.Month
										? styleSheet.campaignTemplateCardDraggingOverDay
										: styleSheet.campaignTemplateCardDragging,
								],
							}}
							templateTypeOptions={templateTypeOptions}
							onTemplateTypeSelected={setTemplateType}
							onCampaignTemplateClicked={openCampaignComposer}
						/>
					</>
				</DragDropContext>
			</div>
			<LocalNotificationObserver
				topic={Topics.APP_FULLSCREEN_MODAL_APPEARANCE}
				onNotificationReceived={onFullscreenModalLifecycleNotification}
			/>
			<LocalNotificationObserver
				onNotificationReceived={onEditCampaignNotificationReceived}
				topic={Topics.EDIT_CAMPAIGNS_ITEM}
			/>
			<LocalNotificationObserver
				onNotificationReceived={onRefreshFromNotification}
				topic={Topics.CREATE_CAMPAIGNS_ITEM}
			/>
			<LocalNotificationObserver onNotificationReceived={onRefreshFromNotification} topic={Topics.SEND_EMAIL} />
			<LocalNotificationObserver
				onNotificationReceived={onEditSocialNotificationReceived}
				topic={Topics.EDIT_SOCIAL_POST}
			/>
			<LocalNotificationObserver
				onNotificationReceived={onEditSocialNotificationReceived}
				topic={Topics.CREATE_SOCIAL_POST}
			/>
			<LocalNotificationObserver onNotificationReceived={onEditBlogPost} topic={Topics.EDIT_BLOG_POST} />
		</div>
	);
});
const ContentCalendarAsObserver = observer(_ContentCalendar);
export const ContentCalendar = inject(ImpersonationContextKey)(ContentCalendarAsObserver);
