import * as Api from '@ViewModels';
import { UseQueryResult } from '@tanstack/react-query';
import { CampaignType } from '../../../../models/AdminModels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useBlogCardsAllCategoryTemplates,
	useBlogCardsCategories,
	useBlogCardsCategoryTemplates,
	useBlogsCardSearchTemplates,
	useEmailAllCategoryTemplates,
	useEmailCategories,
	useEmailCategoryTemplates,
	useEmailSearchTemplates,
	useMyTemplates,
	useSocialAllCategoryTemplates,
	useSocialCategories,
	useSocialSearchTemplates,
	useTemplateCategorySocialQuery,
} from '../../../../queries';
import { ITemplateCard, KnownCategories } from '../../../../viewmodels/AppViewModels';

export type ContentCalendarTemplateBrowserParams = {
	impersonationContext?: Api.IImpersonationContext;
	templateType?: CampaignType;
	searchQuery?: string;
	industry?: Api.Industry;
	category?: string;
	onCategoriesSuccess?: (categories: string[]) => void;
};
const categoriesWithOwnQueries = [KnownCategories.All, KnownCategories.MyTemplates];

export function useSocialMediaTemplates({
	templateType,
	impersonationContext,
	category,
	industry,
	searchQuery,
	onCategoriesSuccess,
}: ContentCalendarTemplateBrowserParams) {
	const userSession = useUserSession();
	const emailTemplateTypeSelected = templateType === CampaignType.Social;
	const account = impersonationContext?.account?.id ? impersonationContext.account : userSession?.account;
	const socialMediaEnabled = account?.features?.socialMedia.enabled;
	const isSearching = Boolean(searchQuery);

	const templatesByIndustryAndCategoryEnabled =
		socialMediaEnabled &&
		emailTemplateTypeSelected &&
		Boolean(category) &&
		Boolean(industry) &&
		category !== KnownCategories.All &&
		!isSearching;
	const socialTemplatesQuery = useTemplateCategorySocialQuery({
		industry,
		categoryName: category === KnownCategories.MyTemplates ? 'me' : category,
		enabled: templatesByIndustryAndCategoryEnabled,
		impersonationContext,
	});

	const categoriesEnabled = socialMediaEnabled && emailTemplateTypeSelected && Boolean(industry);
	const socialTemplateCategoriesQuery = useSocialCategories({
		industry,
		impersonationContext,
		enabled: categoriesEnabled,
		onSuccess: onCategoriesSuccess,
		select: categories => {
			const newCategories = categories.filter(
				c => c !== 'Archived' && c !== 'Uncategorized' && c !== 'HTML Newsletters' && c !== 'My Templates'
			);
			return [KnownCategories.All, KnownCategories.MyTemplates, ...newCategories];
		},
	});

	const browseEnabled =
		socialMediaEnabled && emailTemplateTypeSelected && category === KnownCategories.All && !isSearching;
	const allSocialTemplatesQuery = useSocialAllCategoryTemplates({
		enabled: browseEnabled,
		industry,
		impersonationContext,
	});

	const searchEnabled = socialMediaEnabled && emailTemplateTypeSelected && isSearching;
	const socialTemplateSearchQuery = useSocialSearchTemplates({
		impersonationContext,
		query: searchQuery,
		industry,
		enabled: searchEnabled,
	});

	const socialTemplatesLoading =
		(searchEnabled && socialTemplateSearchQuery.isLoading) ||
		(browseEnabled && allSocialTemplatesQuery.isLoading) ||
		(templatesByIndustryAndCategoryEnabled && socialTemplatesQuery.isLoading) ||
		(categoriesEnabled && socialTemplateCategoriesQuery.isLoading);

	return {
		socialTemplatesLoading,
		socialTemplateCategoriesQuery,
		socialTemplatesQuery,
		socialTemplateSearchQuery,
		allSocialTemplatesQuery,
	};
}

export function useEmailTemplates({
	templateType,
	impersonationContext,
	category,
	industry,
	searchQuery,
	onCategoriesSuccess,
}: ContentCalendarTemplateBrowserParams) {
	const emailTemplateTypeSelected = templateType === CampaignType.Email;
	const isSearching = Boolean(searchQuery);

	const templatesByIndustryAndCategoryEnabled =
		emailTemplateTypeSelected &&
		Boolean(category) &&
		Boolean(industry) &&
		!categoriesWithOwnQueries.includes(category) &&
		!isSearching;
	const emailTemplatesQuery = useEmailCategoryTemplates({
		industry,
		categoryName: category,
		enabled: templatesByIndustryAndCategoryEnabled,
		impersonationContext,
		// We don't want to retry if the category doesn't exist
		retry: (_, error) => error.systemCode !== 404,
	});

	const categoriesEnabled = emailTemplateTypeSelected && Boolean(industry);
	const emailTemplateCategoriesQuery = useEmailCategories({
		industry,
		impersonationContext,
		enabled: categoriesEnabled,
		onSuccess: onCategoriesSuccess,
	});

	const browseEnabled = emailTemplateTypeSelected && category === KnownCategories.All && !isSearching;
	const allEmailTemplatesQuery = useEmailAllCategoryTemplates({
		enabled: browseEnabled,
		industry,
		impersonationContext,
	});

	const searchEnabled = emailTemplateTypeSelected && isSearching;
	const emailTemplateSearchQuery = useEmailSearchTemplates({
		impersonationContext,
		query: searchQuery,
		industry,
		enabled: searchEnabled,
	});

	const myTemplatesEnabled = emailTemplateTypeSelected && category === KnownCategories.MyTemplates && !isSearching;
	const myEmailTemplatesQuery = useMyTemplates<ITemplateCard>({
		impersonationContext,
		industry,
		enabled: myTemplatesEnabled,
	});

	const emailTemplatesLoading =
		(searchEnabled && emailTemplateSearchQuery.isLoading) ||
		(browseEnabled && allEmailTemplatesQuery.isLoading) ||
		(templatesByIndustryAndCategoryEnabled && emailTemplatesQuery.isLoading) ||
		(categoriesEnabled && emailTemplateCategoriesQuery.isLoading) ||
		(myTemplatesEnabled && myEmailTemplatesQuery.isLoading);

	return {
		myEmailTemplatesQuery,
		emailTemplatesLoading,
		emailTemplateCategoriesQuery,
		emailTemplatesQuery,
		emailTemplateSearchQuery,
		allEmailTemplatesQuery,
	};
}

export function useBloggingTemplates({
	templateType,
	impersonationContext,
	category,
	industry,
	searchQuery,
	onCategoriesSuccess,
}: ContentCalendarTemplateBrowserParams) {
	const userSession = useUserSession();
	const account = impersonationContext?.account?.id ? impersonationContext.account : userSession?.account;
	const blogsEnabled = account?.features?.blogFeature.enabled;
	const blogsTemplateTypeSelected = templateType === CampaignType.Blog;
	const isSearching = Boolean(searchQuery);

	const templatesByIndustryAndCategoryEnabled =
		blogsTemplateTypeSelected &&
		blogsEnabled &&
		Boolean(category) &&
		Boolean(industry) &&
		!categoriesWithOwnQueries.includes(category) &&
		!isSearching;
	const blogTemplatesQuery = useBlogCardsCategoryTemplates({
		impersonationContext,
		category,
		enabled: templatesByIndustryAndCategoryEnabled,
		industry,
		// We don't want to retry if the category doesn't exist
		retry: (_, error) => error.systemCode !== 404,
	});

	const categoriesEnabled = blogsTemplateTypeSelected && blogsEnabled && Boolean(industry);
	const blogTemplateCategoriesQuery = useBlogCardsCategories({
		enabled: categoriesEnabled,
		impersonationContext,
		industry,
		withAll: true,
		onSuccess: onCategoriesSuccess,
	});

	const browseEnabled = blogsTemplateTypeSelected && blogsEnabled && category === KnownCategories.All && !isSearching;
	const allBlogTemplatesQuery = useBlogCardsAllCategoryTemplates({
		enabled: browseEnabled,
		industry,
		impersonationContext,
	});

	const searchEnabled = blogsTemplateTypeSelected && blogsEnabled && isSearching;
	const blogSearchQuery = useBlogsCardSearchTemplates({
		impersonationContext,
		search: searchQuery,
		industry,
		enabled: searchEnabled,
	});

	const blogTemplatesLoading =
		(searchEnabled && blogSearchQuery.isLoading) ||
		(browseEnabled && allBlogTemplatesQuery.isLoading) ||
		(templatesByIndustryAndCategoryEnabled && blogTemplatesQuery.isLoading) ||
		(categoriesEnabled && blogTemplateCategoriesQuery.isLoading);

	return {
		blogSearchQuery,
		allBlogTemplatesQuery,
		blogTemplatesQuery,
		blogTemplateCategoriesQuery,
		blogTemplatesLoading,
	};
}

export function useContentCalendarTemplateBrowserQueries({
	templateType,
	impersonationContext,
	category,
	industry,
	searchQuery,
	onCategoriesSuccess,
}: ContentCalendarTemplateBrowserParams) {
	const {
		socialTemplatesLoading,
		socialTemplateCategoriesQuery,
		socialTemplatesQuery,
		socialTemplateSearchQuery,
		allSocialTemplatesQuery,
	} = useSocialMediaTemplates({
		templateType,
		searchQuery,
		category,
		industry,
		impersonationContext,
		onCategoriesSuccess,
	});

	const {
		blogTemplatesQuery,
		blogTemplatesLoading,
		blogTemplateCategoriesQuery,
		allBlogTemplatesQuery,
		blogSearchQuery,
	} = useBloggingTemplates({
		templateType,
		searchQuery,
		category,
		industry,
		impersonationContext,
		onCategoriesSuccess,
	});

	const {
		emailTemplateCategoriesQuery,
		allEmailTemplatesQuery,
		emailTemplateSearchQuery,
		emailTemplatesQuery,
		myEmailTemplatesQuery,
		emailTemplatesLoading,
	} = useEmailTemplates({
		templateType,
		searchQuery,
		category,
		industry,
		impersonationContext,
		onCategoriesSuccess,
	});

	const isLoading = blogTemplatesLoading || emailTemplatesLoading || socialTemplatesLoading;
	let templatesByCategoryQuery: UseQueryResult<Api.IDictionary<ITemplateCard[]>, Api.IOperationResultNoValue>;
	let myTemplatesQuery: UseQueryResult<ITemplateCard[], Api.IOperationResultNoValue>;
	let templatesQuery: UseQueryResult<ITemplateCard[], Api.IOperationResultNoValue>;
	let templateSearchQuery: UseQueryResult<ITemplateCard[], Api.IOperationResultNoValue>;
	let templateCategoriesQuery: UseQueryResult<string[], any>;

	switch (templateType) {
		case CampaignType.Email: {
			templatesByCategoryQuery = allEmailTemplatesQuery;
			myTemplatesQuery = myEmailTemplatesQuery;
			templatesQuery = emailTemplatesQuery;
			templateSearchQuery = emailTemplateSearchQuery;
			templateCategoriesQuery = emailTemplateCategoriesQuery;
			break;
		}
		case CampaignType.Social: {
			templatesByCategoryQuery = allSocialTemplatesQuery;
			myTemplatesQuery = null;
			templatesQuery = socialTemplatesQuery;
			templateSearchQuery = socialTemplateSearchQuery;
			templateCategoriesQuery = socialTemplateCategoriesQuery;
			break;
		}
		case CampaignType.Blog: {
			templatesByCategoryQuery = allBlogTemplatesQuery;
			myTemplatesQuery = null;
			templatesQuery = blogTemplatesQuery;
			templateSearchQuery = blogSearchQuery;
			templateCategoriesQuery = blogTemplateCategoriesQuery;
			break;
		}
		default: {
			break;
		}
	}

	return {
		isLoading,
		templatesByCategoryQuery,
		myTemplatesQuery,
		templatesQuery,
		templateSearchQuery,
		templateCategoriesQuery,
	};
}
