import { navigation } from '../../../../web/styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	option: {
		display: 'block',
		paddingTop: 7,
	},
	optionSelected: {
		background: '#F0F0F0',
	},
	selectOption: {
		height: 50,
	},
	selectOptionEmail: {
		color: navigation,
		fontSize: 12,
	},
});
