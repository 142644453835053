import * as Api from '@ViewModels';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '@WebComponents/autocomplete/SimpleAutoCompleteSearchField';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { getDisplayName } from '../../../../models/UiUtils';
import { useUserQuery } from '../../../../queries';
import { ISelectOption, Select } from '../../../components/Select';
import { EOwnershipOption, EOwnershipOptionsMap } from '../hooks/useOwnershipFilters';
import { styleSheet } from './styles';

const baseOwnershipOptions: ISelectOption<EOwnershipOption>[] = [
	{ id: uuidgen(), dataContext: EOwnershipOption.All, text: EOwnershipOptionsMap[EOwnershipOption.All].text },
	{ id: uuidgen(), dataContext: EOwnershipOption.Me, text: EOwnershipOptionsMap[EOwnershipOption.Me].text },
	{ id: uuidgen(), dataContext: EOwnershipOption.Owned, text: EOwnershipOptionsMap[EOwnershipOption.Owned].text },
	{
		id: uuidgen(),
		dataContext: EOwnershipOption.Connections,
		text: EOwnershipOptionsMap[EOwnershipOption.Connections].text,
	},
];

export const OwnershipFilterDropdown = ({
	showOwnedByOption = false,
	selectedOwnership,
	setSelectedOwnership,
	selectedUserId,
	setSelectedUserId,
	className,
	styles,
	disabled = false,
}: {
	showOwnedByOption?: boolean;
	selectedOwnership: EOwnershipOption | null;
	setSelectedOwnership: React.Dispatch<React.SetStateAction<EOwnershipOption | null>>;
	selectedUserId: string | null | undefined;
	setSelectedUserId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
	className?: string;
	styles?: StyleDeclarationValue[];
	disabled?: boolean;
}) => {
	const userQuery = useUserQuery({
		enabled: Boolean(selectedUserId),
		id: selectedUserId ?? '',
	});

	const onRenderOwnershipOptionPlaceholder = React.useCallback(() => 'Please select...', []);

	const onOwnershipOptionChanged = (value: ISelectOption<EOwnershipOption>) => {
		setSelectedOwnership(value.dataContext);
	};

	const onUserSelected = (userSelectedEvent: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IUser>) => {
		setSelectedUserId(userSelectedEvent.selection.id);
	};

	const options = baseOwnershipOptions.filter(option => {
		const ownershipAlreadySelected = selectedOwnership === EOwnershipOption.Owned;
		const optionIsNotOwnedBy = option.dataContext !== EOwnershipOption.Owned;

		return ownershipAlreadySelected || showOwnedByOption || optionIsNotOwnedBy;
	});

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<Select
				disabled={disabled}
				onOptionClick={onOwnershipOptionChanged}
				onRenderPlaceholder={onRenderOwnershipOptionPlaceholder}
				options={options}
				selectedOption={baseOwnershipOptions?.find(x => x.dataContext === selectedOwnership)}
			/>

			{selectedOwnership === EOwnershipOption.Owned ? (
				<>
					<h6 className={css(styleSheet.label)}>Owner</h6>
					<SimpleAutoCompleteSearchField
						key={userQuery.data?.id && !userQuery.isLoading ? userQuery.data?.id : 'searching'}
						disabled={disabled || !showOwnedByOption}
						// @ts-ignore
						onItemSelected={onUserSelected}
						placeholder='Search name'
						type={Api.ResourceAutoCompleteViewModelType.User}
						initialSearchQuery={userQuery.data ? getDisplayName(userQuery.data) : ''}
					/>
					{!selectedUserId ? (
						<p role='alert' className={css(styleSheet.formErrorLine)}>
							Owner is required when selecting Contacts owned by
						</p>
					) : null}
				</>
			) : null}
		</div>
	);
};
