import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../../models/UiUtils';
import {
	aceGray,
	background,
	brandPrimary,
	brandPrimaryHover,
	charmGray,
	destructive,
	magicOrange,
	prestoPurple,
	spanishBlue,
	success,
	titles,
	white,
} from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const backgroudKeyframes = {
	'0%': {
		backgroundPosition: '0% 50%',
	},
	'50%': {
		backgroundPosition: '100% 50%',
	},
	'100%': {
		backgroundPosition: '0% 50%',
	},
};

export const styleSheet = StyleSheet.create({
	button: {
		width: '100%',
		marginBottom: 8,
	},
	buttonSmall: {
		marginBottom: 4,
	},
	calendarCardApprove: {
		background: white,
		fontSize: 12,
		borderRadius: 4,
		margin: 0,
		padding: '4px 0px',
		color: brandPrimary,
		textShadow: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	calendarCardApproveQuarterly: {
		fontSize: 12,
		display: 'inline-flex',
		gap: 4,
	},
	calendarCardApproveForwardIcon: {
		height: 'auto',
		transform: 'rotate3d(0,0,1,-90deg)',
		width: 11,
	},
	cardLabel: {
		position: 'absolute',
		top: -1,
		left: -1,
		borderRadius: '8px 0 8px 0',
		fontSize: 10,
		padding: '4px 12px',
		color: white,
	},
	cardLabelMonthly: {
		position: 'absolute',
		top: -1,
		left: -1,
		borderRadius: '8px 8px 0px 0',
		fontSize: 10,
		padding: '4px 12px',
		color: white,
		width: 'calc(100% + 2px)',
		boxSizing: 'border-box',
	},
	cardLabelQuarterlyFeatured: {
		textAlign: 'left',
	},
	featuredHeader: {
		background: 'linear-gradient(270deg, #32C5FF 10%, #B620E0 100%)',
		animationDuration: '30s',
		animationIterationCount: 'infinite',
		animationFillMode: 'linear',
		backgroundSize: '200% 200%',
		animationName: [backgroudKeyframes],
		textAlign: 'center',
	},
	cardHeader: {
		position: 'relative',
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		display: 'flex',
		width: '100%',
		gap: 8,
		alignItems: 'center',
		padding: '28px 28px 8px 12px',
		boxSizing: 'border-box',
	},
	cardHeaderSuggestion: {
		padding: '28px 28px 8px 12px',
	},
	cardBody: {
		padding: '0px 28px 4px 12px',
		width: '100%',
		boxSizing: 'border-box',
	},
	smallCardBody: {
		padding: '0px 6px 0px',
	},
	smallCardHeader: {
		padding: '26px 6px 0',
		...CssSelectors.allChildren(
			{
				textAlign: 'center',
			},
			'span'
		),
	},
	cardHeaderSmallCardSuggestion: {
		padding: '26px 6px 0',
	},
	cardHeaderTitle: {
		width: '100%',
		overflow: 'hidden',
		'-webkit-box-orient': 'vertical',
		'-webkit-line-clamp': '2',
		clamp: '2',
	},
	smallCardHeaderTitle: {
		fontSize: 10,
	},
	cardWrapper: {
		borderRadius: '8px 8px 0 0',
		border: `1px solid transparent`,
		padding: 0,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		borderBottom: 0,
		boxSizing: 'border-box',
		background: white,
	},
	cardWrapperSmallCards: {
		padding: '0 0 6px',
		width: 'calc(100% - 16px)',
		margin: '0 auto',
	},
	cardWrapperEmail: {
		borderColor: success,
		...CssSelectors.allChildren(
			{
				background: success,
			},
			'header > span'
		),
	},
	cardWrapperSocial: {
		borderColor: spanishBlue,
		...CssSelectors.allDescendants(
			{
				background: spanishBlue,
			},
			'header > span'
		),
	},
	cardWrapperBlog: {
		borderColor: prestoPurple,
		...CssSelectors.allDescendants(
			{
				background: prestoPurple,
			},
			'header > span'
		),
	},
	cardWrapperSuggestion: {
		borderStyle: 'dashed',
	},
	cardWrapperFeature: {
		background: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(270deg, #32C5FF 10%, #B620E0 100%) border-box',
		animationDuration: '5s',
		animationIterationCount: 'infinite',
		animationFillMode: 'linear',
		backgroundSize: '200% 200%',
		animationName: [backgroudKeyframes],
		borderTop: 0,
	},
	eventDate: {
		position: 'absolute',
		top: 2,
		right: 12,
		color: charmGray,
		fontSize: 12,
	},
	eventFeaturedDate: {
		color: white,
	},
	footer: {
		width: '100%',
		borderTop: `1px solid transparent`,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		marginTop: -1,
	},
	footerSmallCards: {
		maxWidth: 'calc(100% - 16px)',
		margin: '-2px auto 0',
	},
	footerInner: {
		width: '100%',
		padding: 6,
		borderRadius: '0 0 8px 8px',
		border: `1px solid transparent`,
		borderColor: destructive,
		margin: 0,
		boxSizing: 'border-box',
		fontSize: 10,
		...CssSelectors.allChildren(
			{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			},
			'div'
		),
	},
	footerIcon: {
		width: 15,
		height: 15,
	},
	suggestionFooterInner: {
		padding: 8,
		borderColor: aceGray,
		background,
		...CssSelectors.allDescendants(
			{
				position: 'relative',
				':after': {
					content: '""',
					display: 'block',
					width: 30,
					height: 30,
					marginLeft: 4,
					position: 'absolute',
					top: '50%',
					left: -11,
					transform: 'translateY(-50%)',
				},
			},
			'.checkbox'
		),
	},
	footerInnerSmallCards: {
		padding: '6px 6px 8px',
	},
	footerInnerScheduled: {
		borderColor: brandPrimaryHover,
		background: hexToRGBA(brandPrimaryHover, 0.1),
	},
	footerInnerPending: {
		borderColor: magicOrange,
		background: hexToRGBA(magicOrange, 0.1),
	},
	footerInnerComplete: {
		borderColor: success,
		background: hexToRGBA(success, 0.1),
	},
	maxWidthContainer: {
		maxWidth: '88%',
		...CssSelectors.allChildren({
			color: hexToRGBA(titles, 0.7),
		}),
	},
	scheduleLabel: {
		fontSize: 10,
		display: 'block',
		marginLeft: 4,
		color: hexToRGBA(titles, 0.7),
	},
	scheduleLabelLink: {
		fontSize: 10,
		display: 'block',
		marginLeft: 4,
		color: brandPrimary,
	},
});
