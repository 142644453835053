import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Spring } from 'react-spring-legacy/renderprops';

interface IProps {
	forceClose?: boolean;
	threshold: number;
	onRenderTrigger?: (isExpanded: boolean) => JSX.Element;
}

export const OverflowAccordion: React.FC<IProps> = ({ children, forceClose, threshold, onRenderTrigger }) => {
	const childOuterRef = useRef(null);
	const childInnerRef = useRef(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const [showTrigger, setShowTrigger] = useState(false);

	useEffect(() => {
		// timeout required due to animation
		setTimeout(() => {
			// @ts-ignore
			setShowTrigger(childInnerRef?.current?.clientHeight > threshold);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childInnerRef, childOuterRef, children]);

	useEffect(() => {
		if (forceClose) {
			setIsExpanded(false);
		}
	}, [forceClose]);

	const onTriggerClick = () => {
		setIsExpanded(!isExpanded);
	};

	const renderTrigger = () => {
		return (
			<button className={css(baseStyleSheet.minorActionLink, styleSheet.trigger)} onClick={onTriggerClick}>
				{onRenderTrigger?.(isExpanded) ?? <span>{isExpanded ? 'Collapse' : 'Show all'}</span>}
			</button>
		);
	};

	return (
		<div>
			<Spring
				config={{ friction: 28, tension: 200 }}
				from={{ maxHeight: isExpanded ? threshold : 'auto' }}
				to={{ maxHeight: isExpanded ? 'auto' : threshold }}
			>
				{(props: React.CSSProperties) => (
					<div ref={childOuterRef} style={props as React.CSSProperties} className={css(styleSheet.childOuter)}>
						<div ref={childInnerRef} className={css(styleSheet.childInner)}>
							{children}
						</div>
					</div>
				)}
			</Spring>
			{showTrigger && renderTrigger()}
		</div>
	);
};
