import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const TagAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon className={`tag-automation-trigger-graphic ${className || ''} ${css(...styles)}`} height={172} width={172}>
			<defs>
				<linearGradient id='tagAutomationTriggerGraphicGradient' x1='50%' x2='50%' y1='0%' y2='100%'>
					<stop offset='0%' stopColor='#F7F7F7' />
					<stop offset='100%' stopColor='#E4FFEC' />
				</linearGradient>
				<filter
					id='tagAutomationTriggerGraphicFilter'
					width='111.2%'
					height='111.2%'
					x='-5.6%'
					y='-5.6%'
					filterUnits='objectBoundingBox'
				>
					<feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='3' />
					<feColorMatrix
						in='shadowBlurOuter1'
						values='0 0 0 0 0.54424426   0 0 0 0 0.54424426   0 0 0 0 0.54424426  0 0 0 0.100033967 0'
					/>
				</filter>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(6 6)'>
				<mask id='tagAutomationTriggerGraphicMask0' fill='#fff'>
					<circle cx='80' cy='80' r='80' />
				</mask>
				<circle fill='#000' filter='url(#tagAutomationTriggerGraphicFilter)' cx='80' cy='80' r='80' />
				<circle fill='url(#tagAutomationTriggerGraphicGradient)' cx='80' cy='80' r='80' />
				<g mask='url(#tagAutomationTriggerGraphicMask0)'>
					<g transform='translate(-13 17.888)'>
						<g transform='translate(0 13.112)'>
							<rect width='22' height='8' x='30' fill='#D0F5AA' fillRule='nonzero' rx='2' />
							<rect width='38' height='8' x='131' y='86' fill='#B9E092' fillRule='nonzero' rx='2' />
							<rect width='30' height='8' x='149' y='69' fill='#CAE0EC' fillRule='nonzero' rx='2' />
							<rect width='30' height='8' x='110' y='51' fill='#DFE9ED' fillRule='nonzero' rx='2' />
							<rect width='30' height='8' x='8' y='13' fill='#ECC1C7' fillRule='nonzero' rx='2' />
							<rect width='38' height='8' y='40' fill='#EFEAA7' fillRule='nonzero' rx='2' />
							<rect width='30' height='8' x='44' y='20' fill='#F5E7E9' fillRule='nonzero' rx='2' />
						</g>
						<g transform='rotate(30 44.712 166.867)'>
							<path
								fill='#B2D3E4'
								fillRule='nonzero'
								d='M27.2314627,17.7364355 L49.14632,17.7364355 L66.050226,41.4213072 L66.050226,84.4592166 C66.050226,86.3250381 64.5376788,87.8375853 62.6718573,87.8375853 L13.6855117,87.8375853 C11.8196902,87.8375853 10.307143,86.3250381 10.307143,84.4592166 L10.307143,41.4213072 L27.2314627,17.7364355 Z'
								transform='rotate(-20 38.179 52.787)'
							/>
							<path
								fill='#00AAE8'
								fillRule='nonzero'
								d='M21.3193176,17.7364355 L43.2341748,17.7364355 L60.1380808,41.4213072 L60.1380808,84.4592166 C60.1380808,86.3250381 58.6255336,87.8375853 56.7597122,87.8375853 L7.77336653,87.8375853 C5.90754503,87.8375853 4.39499786,86.3250381 4.39499786,84.4592166 L4.39499786,41.4213072 L21.3193176,17.7364355 Z'
							/>
							<path
								fill='#ACE6FF'
								fillRule='nonzero'
								transform='translate(6.084 19.426)'
								d='M2.3044563e-12,22.5371826 L0,65.0335968 C0,65.9665075 0.756273586,66.7227811 1.68918433,66.7227811 L50.67553,66.7227811 C51.6084407,66.7227811 52.3647143,65.9665075 52.3647143,65.0335968 L52.3647143,22.536651 L36.2802941,3.8407605e-13 L16.1042241,0 L2.3044563e-12,22.5371826 Z'
							/>
							<rect width='27.027' height='1.689' x='18.753' y='50.676' fill='#00AAE8' fillRule='nonzero' rx='.845' />
							<rect width='27.027' height='1.689' x='18.753' y='59.121' fill='#00AAE8' fillRule='nonzero' rx='.845' />
							<rect width='27.027' height='1.689' x='18.753' y='67.567' fill='#00AAE8' fillRule='nonzero' rx='.845' />
							<circle
								cx='32.267'
								cy='31.25'
								r='5.068'
								fill='#F2F2F2'
								fillRule='nonzero'
								stroke='#00AAE8'
								strokeWidth='2'
							/>
							<g transform='translate(15.375)'>
								<mask id='tagAutomationTriggerGraphicMask1' fill='#fff'>
									<path d='M13.5134747,28.7161336 C12.9504132,29.2791951 12.6688825,30.405318 12.6688825,32.0945023 C12.6688825,33.7836866 17.4549048,34.3467481 27.0269493,33.7836866 L41.3850161,6.75673733 L21.9593963,0 L5.067553,4.22296083 L0,14.3580668 L1.68918433,23.6485806 L5.91214516,17.7364355 L13.5134747,28.7161336 Z' />
								</mask>
								<circle
									cx='16.892'
									cy='17.736'
									r='13.513'
									stroke='#00AAE8'
									strokeWidth='2'
									mask='url(#tagAutomationTriggerGraphicMask1)'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
