import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { IAccount, UserViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { IAdminUserSessionComponentProps } from '../../../../models/AdminModels';
import { UserSessionViewModelKey } from '../../../../models/AppState';
import { AccountUsersViewModel } from '../../../../viewmodels/AdminViewModels';
import { ISelectOption, Select } from '../../../../web/components/Select';
import { UserSelectModal } from '../../../../web/components/UserSelectModal';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IImpersonationContextComponentProps,
		IAdminUserSessionComponentProps {
	account?: IAccount;
	admins?: UserViewModel[];
	defaultOption: ISelectOption<string | UserViewModel>;
	initialSelectedOption?: ISelectOption<string | UserViewModel>;
	adminsFilter?: (user: UserViewModel) => boolean;
	onOptionSelected?(option: ISelectOption<string | UserViewModel>, user?: UserViewModel): void;
	pinnedOptions?: ISelectOption<string | UserViewModel>[];
	onRenderPlaceholder?(): React.ReactNode;
	styles?: StyleDeclarationValue[];
}

interface IState {
	admins?: UserViewModel[];
	isLoading?: boolean;
	userSelectModalProps?: {
		isOpen: boolean;
		onRequestClose(user?: UserViewModel, canceled?: boolean): void;
	};
}

class _AccountUserSelectBox extends React.Component<IProps, IState> {
	// @ts-ignore
	@observable.ref private mSelectedUser: UserViewModel;
	// @ts-ignore
	@observable.ref private mDefaultAdminUser: UserViewModel;

	constructor(props: IProps) {
		super(props);
		this.state = {
			isLoading: true,
			userSelectModalProps: {
				isOpen: false,
				onRequestClose: this.onUserSelectRequestClose,
			},
		};
		if (!!props.initialSelectedOption?.dataContext && typeof props.initialSelectedOption?.dataContext === 'object') {
			this.mSelectedUser = props.initialSelectedOption.dataContext;
		}
	}

	public async componentDidMount() {
		const {
			logApiError,
			logEvent,
			admins: propsAdmins,
			impersonationContext,
			userSession,
			account,
			adminsFilter,
		} = this.props;
		const nextState: IState = {
			isLoading: false,
		};

		if (!propsAdmins || propsAdmins?.length === 0) {
			// @ts-ignore
			logEvent('LoadAdmins');
			try {
				const users = new AccountUsersViewModel(
					// @ts-ignore
					userSession,
					// @ts-ignore
					account || impersonationContext?.account || userSession.account.toJs()
				);
				await users.loadAdmins();

				nextState.admins = adminsFilter ? users.admins.filter(adminsFilter) : users.admins;
				// @ts-ignore
				this.mDefaultAdminUser = users.admins.length > 0 ? users.admins[0] : null;
			} catch (error) {
				// @ts-ignore
				// @ts-ignore
				logApiError('LoadAdmins-Error', error);
			}
		} else {
			nextState.admins = propsAdmins;
			this.mDefaultAdminUser = propsAdmins?.[0];
		}
		this.setState(nextState);
	}

	public render() {
		const { styles } = this.props;
		const { userSelectModalProps, isLoading } = this.state;
		return (
			<>
				<Select
					disabled={!this.mDefaultAdminUser || isLoading}
					key={this.mSelectedUser?.id || 'default'}
					onOptionClick={this.onOptionSelected}
					options={this.options}
					selectedOption={this.selectedOptions}
					optionStyles={[styleSheet.selectOption]}
					onRenderPlaceholder={this.props.onRenderPlaceholder}
					styles={styles}
				/>
				<UserSelectModal modalProps={userSelectModalProps} title='User Search' />
			</>
		);
	}

	@computed
	private get selectedOptions() {
		const { defaultOption } = this.props;
		if (this.mSelectedUser) {
			return this.options.find(x => x.dataContext === this.mSelectedUser);
		}

		return defaultOption;
	}

	@computed
	private get options(): ISelectOption[] {
		const { defaultOption } = this.props;
		const { admins } = this.state;
		// @ts-ignore
		return [
			defaultOption,
			this.mSelectedUser
				? {
						dataContext: this.mSelectedUser,
						hoverText: this.mSelectedUser.email,
						id: `option-${this.mSelectedUser.id}`,
						text: this.mSelectedUser.name,
					}
				: null,
			...(admins || [])
				.filter(x => x.id !== this.mSelectedUser?.id)
				.map<ISelectOption>(x => {
					return {
						component: (style, selected) => {
							return (
								<div className={css(style, styleSheet.option, selected && styleSheet.optionSelected)}>
									<div>{`${x.name} (Admin)`}</div>
									{!!x.email && <div className={css(styleSheet.selectOptionEmail)}>{x.email}</div>}
								</div>
							);
						},
						dataContext: x,
						id: `option-${x.id}`,
					};
				}),
			{
				dataContext: 'search',
				id: 'option-search',
				text: 'Search Employee',
			},
		].filter(x => !!x);
	}

	@action
	private onOptionSelected = (option: ISelectOption<string | UserViewModel>, wasSelected: boolean) => {
		const { onOptionSelected } = this.props;
		if (wasSelected) {
			if (typeof option.dataContext === 'string') {
				if (option.dataContext === 'search') {
					this.setState({
						// @ts-ignore
						userSelectModalProps: {
							...this.state.userSelectModalProps,
							isOpen: true,
						},
					});
					return;
				}

				// fall back to default option
				// @ts-ignore
				this.mSelectedUser = null;
			} else {
				this.mSelectedUser = option.dataContext;
			}
			onOptionSelected?.(option, this.mSelectedUser || this.mDefaultAdminUser);
		}
	};

	private onUserSelectRequestClose = async (user?: UserViewModel, cancel?: boolean) => {
		const { onOptionSelected } = this.props;
		const nextState: IState = {
			// @ts-ignore
			userSelectModalProps: {
				...this.state.userSelectModalProps,
				isOpen: false,
			},
		};

		if (!cancel) {
			// @ts-ignore
			this.mSelectedUser = user;
			onOptionSelected?.(
				{
					dataContext: this.mSelectedUser,
					// @ts-ignore
					id: this.mSelectedUser.id,
					text: this.mSelectedUser?.name,
				},
				this.mSelectedUser
			);
		}
		this.setState(nextState);
	};
}

const AccountUserSelectBoxAsObserver = observer(_AccountUserSelectBox);
const AccountUserSelectBoxWithContext = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey
)(AccountUserSelectBoxAsObserver);
export const AccountUserSelectBox = withEventLogging(AccountUserSelectBoxWithContext, 'UserSelectBox');
