import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		width: 'auto',
	},
	selectOption: {
		':hover:not(.select-option-hover-prevented)': {
			backgroundColor: '#E8F3F9',
			cursor: 'pointer',
		},
		height: 50,
	},
});
