import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	flagged?: boolean;
	isAccount?: boolean;
}

export const FlagIcon: React.FC<IProps> = props => {
	const { className, flagged, isAccount } = props;

	const color = isAccount ? (flagged ? '#D0021B' : '#90A4AE') : '#00AAE8';
	return (
		<SvgIcon className={`flag-icon ${className || ''}`} width={10} height={11} viewBox='0 0 10 11'>
			<path fillRule='evenodd' clipRule='evenodd' d='M9.35 0H0v11h1.1V6.6h8.25V0z' fill={color} />
		</SvgIcon>
	);
};
