import { css } from 'aphrodite';
import * as React from 'react';
import { NavLink, RouteComponentProps, match, withRouter } from 'react-router-dom';
import { baseStyleSheet } from '../../styles/styles';
import NavBreadcrumbsCaretUrl from './navBreadcrumbsCaret.svg';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	currentLocationName?: string;
	pathComponentNameProvider?(pathComponent: string): string | undefined | null;
}

const NavBreadcrumbsBar: React.FC<IProps & RouteComponentProps<any, any, any>> = props => {
	const pathComponents = (props.location.pathname || '').split('/').filter(x => !!x);
	const breadcrumbs: React.ReactChild[] = [];
	const isActive = (m: match<any>, location: any) => {
		if (!m || !location) {
			return false;
		}
		return m.path === (location as Location).pathname;
	};

	while (pathComponents.length > 0) {
		const path = pathComponents.reduce((prev, curr) => {
			return `${prev}/${curr}`;
		}, '');

		const lastPathComp = pathComponents[pathComponents.length - 1];

		const name = props.pathComponentNameProvider
			? props.pathComponentNameProvider(lastPathComp)
			: `${lastPathComp[0].toLocaleUpperCase()}${lastPathComp.substring(1)}`;

		if (name) {
			if (breadcrumbs.length > 0) {
				breadcrumbs.push(
					<img className={css(styleSheet.caret)} src={NavBreadcrumbsCaretUrl} key={breadcrumbs.length} />
				);
			}
			breadcrumbs.push(
				<NavLink
					to={path}
					key={breadcrumbs.length}
					className={css(styleSheet.navLink, baseStyleSheet.truncateText)}
					activeClassName={css(styleSheet.activeNavLink)}
					isActive={isActive}
				>
					{breadcrumbs.length === 0 ? (
						props.currentLocationName || name
					) : (
						<span className={css(styleSheet.capitalize)}>{name.includes('-') ? name.replace('-', ' ') : name}</span>
					)}
				</NavLink>
			);
		}
		pathComponents.pop();
	}

	return (
		<div className={`navigation-breadcrumbs-bar ${css(styleSheet.navigationBreadcrumsbBar)} ${props.className || ''}`}>
			{breadcrumbs.reverse()}
		</div>
	);
};

export const NavigationBreadcrumbsBar = withRouter(NavBreadcrumbsBar);
