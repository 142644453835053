import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const SocialMediaIcon: React.FC<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon className={`social-media-icon ${className || ''} ${css(...(styles || []))}`} height={100} width={100}>
			<circle cx='50' cy='50' r='50' fill='#046CB6' />
			<mask id='social-media-icon-mask' maskUnits='userSpaceOnUse' x='0' y='0' width='100' height='100'>
				<circle cx='50' cy='50' r='50' fill='white' />
			</mask>
			<g mask='url(#social-media-icon-mask)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M70.5403 39.9973C71.2018 40.0118 71.8737 39.9693 72.5504 39.8656C75.5649 39.4033 78.2723 37.7625 80.077 35.3042C81.8817 32.8459 82.6359 29.7714 82.1736 26.7571C81.2109 20.4801 75.3417 16.1719 69.0642 17.1345C63.2917 18.0197 59.1842 23.0536 59.3093 28.7386C59.295 29.3731 59.3356 30.0176 59.4352 30.6666C60.2821 36.19 65.1002 40.1198 70.5403 39.9973ZM70.5403 39.9973C71.0141 39.9866 71.4927 39.9452 71.9737 39.8714C77.9778 38.9506 82.0986 33.3365 81.1779 27.332C80.7357 24.4485 79.1663 21.8588 76.8149 20.1326C74.4634 18.4064 71.5226 17.685 68.6393 18.1272C63.2842 18.9485 59.4273 23.5033 59.3093 28.7386C59.3202 29.2359 59.3636 29.7381 59.441 30.243C60.2999 35.8434 65.0646 39.8765 70.5403 39.9973Z'
					fill='#D8F5FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M74.0518 58.997C74.5468 58.9858 75.0467 58.9425 75.5493 58.8655C78.5638 58.4032 81.2712 56.7624 83.0759 54.3041C84.4823 52.3884 85.2507 50.0986 85.3026 47.7546C85.3909 51.6507 83.4004 55.3504 80.0066 57.4072C78.1734 58.5182 76.1047 59.0444 74.0518 58.997ZM74.0518 58.997C68.3636 59.1257 63.3255 55.0174 62.4399 49.2429C61.4772 42.9659 65.7857 37.097 72.0631 36.1344C78.3405 35.1718 84.2098 39.48 85.1725 45.757C85.2745 46.4223 85.3173 47.0906 85.3026 47.7546C85.2919 47.2829 85.2507 46.8083 85.1779 46.3334C84.2571 40.3289 78.6428 36.2078 72.6381 37.1286C68.2406 37.8029 64.6824 41.0598 63.6226 45.3805C62.5628 49.7012 64.2101 54.2348 67.7965 56.8674C69.6548 58.2315 71.8442 58.946 74.0518 58.997Z'
					fill='#D8F5FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M69.8572 41.0181L79.5017 42.4391C79.5196 42.441 79.5374 42.4434 79.5552 42.4463C80.0432 42.5266 80.3737 42.9873 80.2933 43.4752L78.8691 53.1406C78.8054 53.6709 78.3271 54.0513 77.7961 53.9942L68.1544 52.5736C67.6168 52.4787 67.2472 51.9799 67.3129 51.4379L68.7372 41.7726L68.738 41.7684C68.8399 41.2522 69.341 40.9162 69.8572 41.0181ZM69.3054 50.7284L70.677 50.9999L71.3054 45.2714L69.9338 44.9999L69.3054 50.7284ZM71.1663 44.9901L71.1532 44.9881C70.6087 44.9043 70.2346 44.3958 70.3166 43.851C70.3987 43.3062 70.9059 42.9305 71.451 43.0107C71.996 43.0909 72.3735 43.5969 72.2951 44.1423C72.2166 44.6876 71.7119 45.0667 71.1663 44.9901ZM75.9876 51.7515L77.7797 51.9999L78.2597 48.9379C78.506 47.3654 77.7304 46.5127 76.5539 46.3496C75.8925 46.2421 75.2214 46.483 74.8043 46.9777L74.9185 46.2483L73.1264 45.9999L72.3054 51.2411L74.0975 51.4895L74.5651 48.5042C74.5804 48.3629 74.6234 48.2254 74.692 48.0989C74.8849 47.7478 75.2923 47.5495 75.7084 47.604C76.3568 47.6939 76.5441 48.1991 76.4366 48.8857L75.9876 51.7515Z'
					fill='#D8F5FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M77.8738 27.8673C77.2766 23.9777 73.7389 21.2364 69.8234 21.6292C65.9079 22.0221 62.9852 25.4115 63.1726 29.3423C63.36 33.273 66.5918 36.3691 70.5269 36.3877L69.7434 31.2783L67.8863 31.5631L67.562 29.4487L69.4191 29.1639L69.172 27.5526C68.891 25.7203 69.8276 24.5399 71.4977 24.2838C72.0473 24.2073 72.6018 24.1712 73.1567 24.1757L73.4326 25.9754L72.5101 26.1168C71.6023 26.2561 71.4047 26.863 71.4935 27.4421L71.7039 28.8139L73.7317 28.5029L73.7321 30.6669L72.0281 30.9282L72.8117 36.038C76.279 34.9302 78.4257 31.4652 77.8738 27.8673L77.8738 27.8673Z'
					fill='#D8F5FF'
				/>
				<path
					d='M26.9829 61.2568C26.2982 62.1104 26.6406 65.0126 26.6406 65.0126C26.6406 65.0126 25.4422 74.4021 25.9558 75.2557C26.4694 76.1093 25.6134 76.7922 25.0999 77.9872C24.5863 79.1822 24.0728 82.0844 24.0728 82.0844C21.1626 84.4744 21.3338 95.4004 21.3338 95.4004L20.3066 105.131C20.649 106.156 25.4422 106.326 26.127 106.156C26.8117 105.985 33.488 81.9137 33.488 81.9137C33.488 81.9137 33.1457 105.131 33.1457 106.156C33.1457 107.18 37.7677 106.668 38.7948 106.668C39.822 106.668 46.327 64.8419 46.327 64.8419V62.4518L45.4711 61.2568C45.4711 61.2568 27.6677 60.4032 26.9829 61.2568Z'
					fill='#2F2E41'
				/>
				<circle cx='34.3376' cy='25.4182' r='5.35714' fill='#FFB6B6' />
				<path
					d='M27.7179 65.4693L27.6894 65.4288C26.1647 63.2706 28.4578 58.0844 28.8037 57.3325C27.9568 57.0547 27.4219 56.2108 27.5259 55.3167L27.675 54.0353L27.8304 52.7784L26.717 51.6527L26.7139 51.6246L26.0731 45.9562L25.1415 42.1912C24.4268 39.2933 25.3139 36.231 27.4619 34.1808L29.6153 33.8351L32.2324 31.2856H36.4527L37.8998 32.6835C40.7448 35.0413 41.4201 34.017 44.6282 38.4555L42.3312 44.5741L43.6151 53.5984L43.6159 55.2315L44.2846 55.9829C44.773 56.5317 44.9764 57.2815 44.8332 58.0055L44.7452 58.45C45.4349 58.7488 45.9383 59.3674 46.0955 60.1092L46.3304 61.2172C46.5313 61.4853 47.8576 63.2733 47.8576 63.7621C47.8576 63.8355 47.804 63.9064 47.6891 63.9851C46.7852 64.6039 41.7895 65.8655 39.5784 64.9709C37.2232 64.0188 27.8609 65.4472 27.7666 65.4617L27.7179 65.4693Z'
					fill='#3F3D56'
				/>
				<path
					d='M24.8984 43.8865L29.3115 43.0205C30.9435 45.4638 31.8196 52.1447 31.8196 52.1447L36.6987 62.2882C37.5282 62.7934 37.8718 63.8257 37.511 64.729C37.1503 65.6322 36.1909 66.1416 35.2429 65.9333C34.2948 65.725 33.6359 64.8601 33.6851 63.8883L27.3215 51.8702L24.8984 43.8865Z'
					fill='#FFB6B6'
				/>
				<path
					d='M24.515 44.961L24.3887 38.5102L26.48 35.7777C27.3216 34.6864 28.7245 34.1561 30.0991 34.4097C31.992 34.7718 33.2743 36.504 33.033 38.3731C32.678 41.1046 31.8721 44.5229 30.0489 45.2963L30.0304 45.3042L31.92 51.7585L24.9123 52.7145L24.515 44.961Z'
					fill='#3F3D56'
				/>
				<path
					d='M52.8165 39.624C52.8165 39.624 46.2229 38.3172 43.3823 39.0394L42.7559 43.3507L50.7987 44.0409L64.5266 41.6282C65.4288 41.9731 66.4567 41.638 66.9587 40.8353C67.4607 40.0326 67.2902 38.9968 66.5553 38.3845C65.8203 37.7722 64.7356 37.7622 63.9887 38.3608L52.8165 39.624Z'
					fill='#FFB6B6'
				/>
				<path
					d='M45.1417 44.457L38.6639 43.7593L36.1921 41.4142C35.2057 40.4715 34.8525 39.0637 35.2808 37.7823C35.8837 36.0194 37.7815 35.0139 39.6231 35.4816C42.3143 36.1674 45.0072 35.5277 45.5506 37.3681L46.5204 37.0581L52.89 38.2434L52.9587 45.0611L45.1417 44.457Z'
					fill='#3F3D56'
				/>
				<path
					d='M28.582 21.6354C29.1839 20.7131 30.3135 20.0984 31.4085 20.2712C31.6163 18.8604 32.5994 17.6813 33.959 17.2126C35.3185 16.7439 36.8284 17.0634 37.8758 18.0414C38.2195 17.8018 38.6647 17.7576 39.0495 17.9251C39.4264 18.0948 39.7543 18.3555 40.0031 18.6832C41.2833 20.2644 41.582 22.4147 40.7803 24.2795C40.9142 23.7976 40.4266 23.3443 39.934 23.2362C39.4414 23.1281 38.9247 23.2304 38.4251 23.16C37.7848 23.0697 37.2093 22.7019 36.568 22.6191C36.0243 22.5841 35.4788 22.6535 34.9616 22.8236C34.4445 22.9936 33.8998 23.066 33.3557 23.0371C32.816 22.9737 32.1208 24.9994 32.1301 26.4262C32.1319 26.6966 32.0757 27.0438 31.8088 27.1012C31.4803 27.1718 31.2899 26.7227 30.9771 26.6003C30.6775 26.5153 30.3638 26.6805 30.2675 26.9741C30.1875 27.2654 30.2546 27.577 30.4476 27.8107C30.6398 28.039 30.868 28.235 31.1234 28.3913L30.9946 28.4974C30.7424 28.7613 30.3368 28.8112 30.0271 28.6164C29.7318 28.4183 29.4926 28.1487 29.3324 27.8331C28.7288 26.9086 28.2973 25.8845 28.0584 24.8093C27.8227 23.7238 28.0097 22.5902 28.582 21.6354Z'
					fill='#EEEEED'
				/>
			</g>
		</SvgIcon>
	);
};
