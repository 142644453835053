// eslint-disable-next-line import/no-internal-modules
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { IPostTarget, SocialMediaType } from '../../../../extViewmodels';
import { getShortenedString, getSocialMediaPlatformLogoUrl, sortPlatformTargets } from '../../../../models/UiUtils';
import { useEditSocialMediaPostContext } from '../../../containers/socialMedia/EditSocialMediaPost/context';
import { brandSecondary } from '../../../styles/colors';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { SocialMediaImagesPreviewModal } from '../SocialMediaImagesPreview';
import {
	BasicImagePreview,
	BasicVideoPreview,
	InstagramImagePreview,
	LinkedInImagePreview,
	PreviewPostContentView,
} from './presentation';
import { styleSheet } from './styles';

interface IProps {
	children?: React.ReactNode;
	className?: string;
	postTargets: IPostTarget[];
	styles?: StyleDeclarationValue[];
}

export const PreviewSocialMediaPost: React.FC<IProps> = props => {
	const { className = '', children, styles = [], postTargets } = props;
	const { postContent, postImages } = useEditSocialMediaPostContext();
	const [initialImageUrl, setInitialImageUrl] = React.useState<string>('');
	const [imagePreviewModalOpen, setImagePreviewModalOpen] = React.useState(false);

	const onClickEnlargeImage = (initialUrl: string) => {
		setInitialImageUrl(initialUrl);
		setImagePreviewModalOpen(true);
	};

	const getContentLength = (targetChoice: SocialMediaType) => {
		if (targetChoice === SocialMediaType.Instagram) {
			return 125;
		} else if (targetChoice === SocialMediaType.LinkedIn) {
			return 150;
		}
		return 220;
	};

	const [targetIndex, setTargetIndex] = React.useState(0);
	const onSetTargetPreview = (index?: number) => () => {
		setTargetIndex(index);
	};

	// Update target index back to zero when the targets input has changed (when they are desellected)
	React.useEffect(() => {
		setTargetIndex(0);
	}, [postTargets]);

	const contentDisplayLength = getContentLength(postTargets?.[targetIndex]?.provider);
	const displayContent =
		postContent?.length > contentDisplayLength
			? getShortenedString(postContent.trim(), contentDisplayLength).trim()
			: postContent?.trim();

	if (postTargets === null) {
		return null;
	}

	return (
		<div className={`preview-social-media-post ${className} ${css(styleSheet.previewContentContainer, ...styles)}`}>
			<section className={css(styleSheet.previewContentSection)}>
				<h3 className={css(styleSheet.previewHeader)}> PREVIEW POST </h3>
				<p className={css(styleSheet.iconRow)}>
					{postTargets?.length > 1 && (
						<button onClick={onSetTargetPreview(Math.max(targetIndex - 1, 0))}>
							<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointLeft)} />
						</button>
					)}
					{sortPlatformTargets(postTargets)?.map((x, i) => (
						<span className={css(styleSheet.iconRow)} key={`${x}-${i}`}>
							<button
								className={
									x === postTargets?.[targetIndex]
										? css(styleSheet.iconRow, styleSheet.iconRowSelected)
										: css(styleSheet.iconRow)
								}
								onClick={onSetTargetPreview(i)}
							>
								<img key={i} src={getSocialMediaPlatformLogoUrl(x.provider)} />
							</button>
						</span>
					))}
					{postTargets?.length > 1 && (
						<button onClick={onSetTargetPreview(Math.min(targetIndex + 1, postTargets.length - 1))}>
							<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointRight)} />
						</button>
					)}
				</p>
				<figure
					className={css(
						styleSheet.previewContentBox,
						postTargets?.[targetIndex]?.provider === SocialMediaType.Instagram &&
							postImages?.length > 1 &&
							styleSheet.previewContentBoxInstagram
					)}
				>
					<PreviewPostContentView
						previewContent={displayContent}
						postContent={postContent}
						truncate={postContent?.length > contentDisplayLength}
						target={postTargets?.[targetIndex]}
					/>
					{postImages?.length > 0 ? (
						postImages.some(x => x.mimeType?.startsWith('video/')) ? (
							<BasicVideoPreview postImages={postImages} controls={true} />
						) : postTargets?.[targetIndex]?.provider === SocialMediaType.Instagram && postImages?.length > 1 ? (
							<InstagramImagePreview postImages={postImages} onClickEnlargeImage={onClickEnlargeImage} />
						) : postTargets?.[targetIndex]?.provider === SocialMediaType.LinkedIn ? (
							<LinkedInImagePreview postImages={postImages} onClickEnlargeImage={onClickEnlargeImage} />
						) : (
							<BasicImagePreview postImages={postImages} onClickEnlargeImage={onClickEnlargeImage} />
						)
					) : null}
				</figure>
				<span className={css(styleSheet.disclaimerText)}>
					This is only a preview. Content may display slightly differently when published.
				</span>
			</section>
			{children}
			<SocialMediaImagesPreviewModal
				modalProps={{
					isOpen: imagePreviewModalOpen,
					onRequestClose: () => setImagePreviewModalOpen(false),
				}}
				initialImageUrl={initialImageUrl}
				images={postImages}
			/>
		</div>
	);
};
