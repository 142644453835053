import { titles } from '../../../styles/colors';
import { Layouts } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		textAlign: 'center',
		...Layouts.verticalStack(20),
	},
	detailMessage: {
		color: '#4A4A4A',
		fontSize: 14,
		margin: '0 auto',
		marginTop: 16,
		width: 430,
	},
	icon: {},
	iconContainer: {
		margin: '100px auto 0',
		position: 'relative',
	},
	link: {
		':hover': {
			textDecoration: 'underline',
		},
		textDecoration: 'none',
	},
	stack: {
		display: 'inline-grid',
		marginTop: 16,
	},
	titleMessage: {
		color: titles,
		fontSize: 18,
		marginTop: 26,
	},
});
