import * as H from 'history';
import * as React from 'react';
import { SwitchProps } from 'react-router';
import { Switch } from 'react-router-dom';
import shallowEqual from 'shallowequal';

/**
 * Only render this inside the FullscreenModal.
 *
 * @param location Is required and should be same as that used in the parent `<Router>`
 */
export class FullscreenModalRouterSwitch extends React.Component<SwitchProps & { location: H.Location<any> }> {
	public shouldComponentUpdate(nextProps: SwitchProps) {
		const currentLocation = this.props.location;
		const nextLocation = nextProps.location;
		if (!nextLocation || (!!currentLocation.pathname && !nextLocation.pathname)) {
			// don't update to the next location because the modal is closing.
			// we do this to retain the last route while the modal close animation is running.
			return false;
		}

		return !shallowEqual(this.props, nextProps);
	}

	public render() {
		return <Switch {...this.props} />;
	}
}
