import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	emptyMessage?: React.ReactNode;
	emptyText: string;
	hideClearButton?: boolean;
	icon?: React.ReactNode;
	onClearButtonClicked?(): void;
}

export const ResourceTablePlaceholder: React.FC<IProps> = props => {
	const { className, onClearButtonClicked, hideClearButton, icon, emptyMessage } = props;
	return (
		<div className={`${css(styleSheet.container)} resource-table-placeholder ${className || ''}`}>
			{icon}
			{emptyMessage ? (
				emptyMessage
			) : (
				<div className={css(styleSheet.placeholderMessage)}>
					<div>{props.emptyText}</div>
					<div>Please clear your filters and try again.</div>
				</div>
			)}
			{!hideClearButton && (
				<div className={css(styleSheet.buttonContainer)}>
					<button
						className={css(baseStyleSheet.ctaButton, styleSheet.placeholderClearButton)}
						onClick={onClearButtonClicked}
					>
						<span>Clear filters</span>
					</button>
				</div>
			)}
		</div>
	);
};
