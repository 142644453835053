import querystring from 'query-string';
import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Industry } from '../../../extViewmodels';
import { getIndustries } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { KnownCategories } from '../../../viewmodels/AppViewModels';
import { TabValues } from '../Campaigns/presentation';

interface IValues {
	category?: string;
	industry?: string;
	search?: string;
	sortBy?: string;
	tabUrlParam?: string;
}

export function useCampaignParams() {
	const history = useHistory();
	const location = useLocation();
	const params = querystring.parse(location.search);

	const category = params.category;
	const industry = params.industry;
	const search = params.search ?? '';
	const sortBy = params.sortBy;

	const setCampaignBrowserParam = React.useCallback(
		(values: IValues = {}) => {
			if (
				values.category == null &&
				values.industry == null &&
				values.search == null &&
				values.sortBy == null &&
				values.tabUrlParam == null
			) {
				return;
			}

			const queryParams = querystring.parse(location.search);

			if (values.category != null) {
				queryParams.category = values.category;
			}
			if (values.industry != null) {
				queryParams.industry = values.industry;
			}
			if (values.search != null) {
				queryParams.search = values.search;
			}
			if (values.sortBy != null) {
				queryParams.sortBy = values.sortBy;
			}

			const pathname = values.tabUrlParam ?? location.pathname;

			history.replace(`${pathname}?${querystring.stringify(queryParams)}`);
		},
		[history, location.search, location.pathname]
	);
	return { category, industry, search, setCampaignBrowserParam, sortBy };
}

// This handles the case where the user navigates to /campaigns/email without any industry or category query params
// Ensures industry and category are always set
export const useEnforceIndustryAndCategory = ({
	tabUrlParam,
	setCampaignBrowserParam,
}: {
	tabUrlParam: keyof typeof TabValues;
	setCampaignBrowserParam: (values?: IValues) => void;
}) => {
	const userSession = useUserSession();
	const params = useParams<{ tab: string }>();
	const { industry, category } = useCampaignParams();
	React.useEffect(() => {
		const tabValue = tabUrlParam.toLocaleLowerCase();
		if (params.tab !== tabValue) {
			return;
		}
		const industries = getIndustries({ accountId: userSession.account.id });
		const userIndustry = industries.includes(userSession?.account?.additionalInfo?.industry)
			? userSession.account.additionalInfo.industry
			: Industry.Insurance;
		if (!industry && !category) {
			setCampaignBrowserParam({ category: KnownCategories.All, industry: userIndustry });
		} else if (!industry) {
			setCampaignBrowserParam({ industry: userIndustry });
		} else if (!category) {
			setCampaignBrowserParam({ category: KnownCategories.All });
		}
	}, [industry, category, setCampaignBrowserParam, userSession, tabUrlParam, params.tab]);
};
