import { css } from 'aphrodite';
import React from 'react';
import { getFileSizeStringValue } from '../../../models/UiUtils';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface ImagePreviewProps {
	url: string;
	fileName: string;
	source?: 'Upload' | 'Pixabay';
	fileSize: number; // Assuming the size is in bytes
	onRemove: () => void; // Callback function to handle the removal of the image
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({ url, fileName, fileSize, source, onRemove }) => {
	// Convert file size from bytes to a more readable format (KB/MB)
	const readableFileSize = getFileSizeStringValue(fileSize);

	return (
		<div className={css(styleSheet.imageContainer)}>
			<figure className={css(styleSheet.imageWrap)}>
				<img src={url} alt='Preview' className={css(styleSheet.image)} />
			</figure>
			<section className={css(styleSheet.imageSection)}>
				<h3>File Name: {fileName}</h3>
				<p className={css(baseStyleSheet.m0)}>File Size: {readableFileSize}</p>
				{source ? <p className={css(baseStyleSheet.m0)}>Source: {source}</p> : null}
				<button onClick={onRemove} className={css(styleSheet.imageButton)}>
					Change Image
				</button>
			</section>
		</div>
	);
};
