import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'block',
		height: '100%',
		overflow: 'auto',
		width: '100%',
	},
	header: {
		borderBottom: '1px solid #D3DBDE',
		boxSizing: 'border-box',
		color: titles,
		fontSize: 18,
		margin: '0 auto',
		padding: '30px 0 20px 0',
		textAlign: 'center',
		width: '60%',
	},
	headerContent: {
		margin: '0 auto',
		width: 430,
	},
	headerIcon: {
		margin: 'auto',
	},
	headerMessage: {
		color: '#4A4A4A',
		fontSize: 14,
		marginTop: 15,
	},
	headerMessageTitle: {
		color: titles,
		fontSize: 18,
		marginBottom: 10,
	},
	sendOptions: {
		boxSizing: 'border-box',
		width: '100%',
	},
});
