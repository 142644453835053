import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
	fillColor?: string;
	withoutRing?: boolean;
}

export const AutomationPendingIcon: React.FC<IProps> = props => {
	const { className, fillColor, withoutRing, ...restProps } = props;
	if (withoutRing) {
		return (
			<SvgIcon {...restProps} className={`automation-icon ${className || ''}`} height={48} width={48}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M31.998 33H28v-3h3.998v3zM20 33h-4v-3h4v3zm2-16h4v3h-4v-3zm10.998 11h-2v-1c0-1.654-1.345-3-2.998-3h-3v-2h2a1 1 0 001-1v-5a1 1 0 00-1-1h-6a1 1 0 00-1 1v5a1 1 0 001 1h2v2h-3c-1.654 0-3 1.346-3 3v1h-2a1 1 0 00-1 1v5a1 1 0 001 1h6a1 1 0 001-1v-5a1 1 0 00-1-1h-2v-1c0-.551.449-1 1-1h8a1 1 0 01.998 1v1H27a1 1 0 00-1 1v5a1 1 0 001 1h5.998a1 1 0 001-1v-5a1 1 0 00-1-1z'
					fill='#00AAE8'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M26.667 12.667h-4V6L16 15.333h4V22l6.667-9.333z'
					fill='#00528C'
				/>
			</SvgIcon>
		);
	}

	return (
		<SvgIcon {...restProps} className={`automation-icon ${className || ''}`} height={48} width={48}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24 47c12.703 0 23-10.297 23-23S36.703 1 24 1 1 11.297 1 24s10.297 23 23 23z'
				fill='#E8F3F9'
				stroke='#046CB6'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M31.998 33H28v-3h3.998v3zM20 33h-4v-3h4v3zm2-16h4v3h-4v-3zm10.998 11h-2v-1c0-1.654-1.345-3-2.998-3h-3v-2h2a1 1 0 001-1v-5a1 1 0 00-1-1h-6a1 1 0 00-1 1v5a1 1 0 001 1h2v2h-3c-1.654 0-3 1.346-3 3v1h-2a1 1 0 00-1 1v5a1 1 0 001 1h6a1 1 0 001-1v-5a1 1 0 00-1-1h-2v-1c0-.551.449-1 1-1h8a1 1 0 01.998 1v1H27a1 1 0 00-1 1v5a1 1 0 001 1h5.998a1 1 0 001-1v-5a1 1 0 00-1-1z'
				fill='#00AAE8'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M26.667 12.667h-4V6L16 15.333h4V22l6.667-9.333z' fill='#00528C' />
		</SvgIcon>
	);
};
