import { brandSecondary } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	lineContainer: {
		height: '100%',
		position: 'relative',
		width: '100%',
	},
	verticalLine: {
		borderLeft: `1px dashed ${brandSecondary}`,
		height: '100%',
		left: 31,
		position: 'absolute',
		top: 0,
	},
});
