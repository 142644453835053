import { RatingGraphic } from '../../../svgs/graphics/RatingGraphic';
import { styleSheet } from './styles';
import { css } from 'aphrodite';

export const SurveyFollowUpRecipientsEmptyPlaceholder = () => {
	return (
		<div className={css(styleSheet.emptyPlaceholder)}>
			<RatingGraphic className={css(styleSheet.emptyPlaceholderGraphic)} />
			<div>
				<div>No recipients for the</div>
				<div>selected date range</div>
			</div>
		</div>
	);
};
