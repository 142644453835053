import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';

interface IProps extends IUserSessionComponentProps {
	actions?: React.ReactNode;
	canManage?: boolean;
	className?: string;
	comingSoon?: boolean;
	enabled?: boolean;
	icon?: React.ReactNode;
	manageLabel?: string;
	onManageClicked?(): void;
	onRenderCtaButton?(): React.ReactNode;
	status?: React.ReactNode;
	styles?: StyleDeclarationValue[];
}

const IntegrationCardSfc: React.FC<IProps> = props => {
	const {
		className,
		styles,
		actions,
		status,
		icon,
		enabled,
		manageLabel,
		onManageClicked,
		comingSoon,
		canManage,
		onRenderCtaButton,
	} = props;
	const enabledHasValue = enabled !== null && enabled !== undefined;
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} integration-card ${className || ''}`}>
			<div className={css(styleSheet.icon, !!status || !!enabled ? styleSheet.iconWithStatus : null)}>{icon}</div>
			{(!!status || !!enabledHasValue) && (
				<div className={css(styleSheet.status)}>
					{status || (
						<div className={css(styleSheet.enabledStatus)}>
							{!!enabled && <SuccessCheckIcon />}
							<span>{enabled ? 'Enabled' : 'Disabled'}</span>
						</div>
					)}
				</div>
			)}
			<div className={css(styleSheet.actions)}>
				{actions ||
					(comingSoon ? (
						<span className={css(styleSheet.actionsPlaceholder)}>Coming Soon!</span>
					) : (
						!!canManage &&
						(onRenderCtaButton ? (
							onRenderCtaButton()
						) : (
							<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={onManageClicked}>
								<span>{manageLabel ?? 'Manage Integration'}</span>
							</button>
						))
					))}
			</div>
		</div>
	);
};

export const IntegrationCard = inject(UserSessionViewModelKey)(IntegrationCardSfc);
