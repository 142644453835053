import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const TextingCampaignAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon
			className={`texting-campaign-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<defs>
				<linearGradient
					id='texting-campaign-automation-trigger-graphic-gradient'
					x1='50%'
					x2='50%'
					y1='0%'
					y2='99.732%'
				>
					<stop offset='0%' stopColor='#FFF' />
					<stop offset='100%' stopColor='#D5D4FA' />
				</linearGradient>
				<filter
					id='texting-campaign-automation-trigger-graphic-filter'
					width='111.2%'
					height='111.2%'
					x='-5.6%'
					y='-5.6%'
					filterUnits='objectBoundingBox'
				>
					<feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='3' />
					<feColorMatrix
						in='shadowBlurOuter1'
						values='0 0 0 0 0.54424426   0 0 0 0 0.54424426   0 0 0 0 0.54424426  0 0 0 0.100033967 0'
					/>
				</filter>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(6 6)'>
				<mask fill='#fff' id='texting-campaign-automation-trigger-graphic-mask'>
					<circle id='path-a' cx='80' cy='80' r='80' />
				</mask>
				<circle cx='80' cy='80' r='80' fill='#000' filter='url(#texting-campaign-automation-trigger-graphic-filter)' />
				<circle cx='80' cy='80' r='80' fill='url(#texting-campaign-automation-trigger-graphic-gradient)' />
				<g fillRule='nonzero' mask='url(#texting-campaign-automation-trigger-graphic-mask)'>
					<g transform='translate(19 28)'>
						<path
							fill='#FFB8B8'
							d='M75.5463351,59.0729411 C76.121534,59.2304962 76.6003031,59.6457811 76.8538163,60.2070518 C77.1073295,60.7683225 77.1095454,61.4179243 76.8598678,61.9810635 L82,67.1530575 L78.379036,68 L74.0530212,62.9587414 C73.1543652,62.4471744 72.7644592,61.3151726 73.1454436,60.3238006 C73.5264281,59.3324286 74.5578795,58.7950446 75.5463351,59.0729411 Z'
						/>
						<path
							fill='#00528C'
							d='M82.9516925,73.3578653 L76.2979701,66.9069333 C76.1124205,66.7270214 76.0050356,66.477297 76.0001726,66.2144027 C75.9953096,65.9515085 76.0933805,65.6977251 76.272141,65.5106158 L77.6308391,64.0885869 C77.9649137,63.7398617 78.4975125,63.7014959 78.8751704,63.9989514 L84.4510771,68.4028437 L87.9298524,59.6837931 C88.2073096,58.9884085 88.7509182,58.4427338 89.4315849,58.1763529 C90.1122516,57.9099721 90.8692515,57.9466499 91.5228162,58.2776764 C92.5697741,58.8012241 93.1567151,59.976074 92.9634679,61.1613555 C92.3321169,65.1273526 88.5078803,70.3703114 86.2844259,73.1426287 C85.6620471,73.9165742 84.6353409,74.2013018 83.7216477,73.8533419 C83.4356293,73.7428201 83.1738557,73.5743653 82.9516925,73.3578653 Z'
						/>
						<path
							fill='#FFF'
							d='M79.0279202,67.7980927 L74.07477,59.8833882 C73.9421312,59.670912 73.9906421,59.3802488 74.1832102,59.233647 L74.3821838,59.0825248 C74.474778,59.0122068 74.5889368,58.985295 74.6995431,59.0077105 C74.8101494,59.0301261 74.9081416,59.1000325 74.9719595,59.2020492 L79.9250589,67.1167513 C79.9888969,67.2187463 80.0133412,67.3445024 79.9930141,67.4663532 C79.972687,67.588204 79.9092537,67.6961678 79.8166695,67.7664925 L79.6176959,67.9176147 C79.4248249,68.0637754 79.1609511,68.0102986 79.0279202,67.7980904 L79.0279202,67.7980927 Z'
						/>
						<path
							fill='#3F3D56'
							d='M78.0738824,67.458566 L74.1940946,61.0527186 C73.8498035,60.4829064 73.9756885,59.7033836 74.4754935,59.3101998 L74.8708208,59 L80,67.4685771 L79.6047228,67.7788862 L79.6046747,67.7788862 C79.1041467,68.1710562 78.4191661,68.0277232 78.0738824,67.458566 Z'
						/>
						<polygon fill='#FFB8B8' points='78.768 116 76 115.712 76.367 106 80 106.378' />
						<path
							fill='#2F2E41'
							d='M78.6010243,118 L70,117.028029 L70.0125501,116.921509 C70.1149803,116.052131 70.5659366,115.258207 71.2662091,114.714396 C71.9664816,114.170586 72.8587047,113.921439 73.7465903,114.021767 L73.7468094,114.02179 L79,114.615452 L78.6010243,118 Z'
						/>
						<polygon fill='#FFB8B8' points='107 115.259 104.751 116 101 107.094 104.32 106' />
						<path
							fill='#2F2E41'
							d='M109 116.392537L100.194242 119 100.159835 118.89323C99.8790085 118.021821 99.970795 117.079159 100.415001 116.272628 100.859208 115.466097 101.619445 114.861767 102.528461 114.59259L102.528678 114.592525 107.90692 113 109 116.392537zM75.7170216 113.990833C75.1924165 113.831952 74.8912457 113.294458 75.0364479 112.776226L76.5344487 95.9021498C76.6038014 95.1323494 76.8511987 94.3880709 77.2579174 93.7256424L86.3259864 79 94.4406238 80.4267594 94.493083 80.4480145C98.4368884 82.8115191 101.938405 94.272016 105.324638 105.3552 105.851224 107.07874 106.395693 108.860973 106.927259 110.564232 107.132732 111.069995 106.888429 111.643808 106.376762 111.857229L103.919747 112.869437C103.437307 113.06965 102.878368 112.878478 102.628444 112.427777L89.9969849 89.799659C89.9651872 89.7420875 89.906417 89.7035664 89.8399073 89.6967019 89.773901 89.6884615 89.7081063 89.7133972 89.664993 89.7629932L82.334405 97.8799492 79.6404518 113.348519 79.6295429 113.382064C79.4283634 113.839291 78.9138894 114.081842 78.4230795 113.950857L75.7806897 113.9998 75.7641998 114 75.7170216 113.990833z'
						/>
						<path
							fill='#046CB6'
							d='M84.8374782,80.3245625 L84.7869089,80.3079435 L84.7716544,80.255453 C84.7343768,80.1275711 81.0826633,67.3627685 84.3733302,59.2251282 L84.3767154,59.2177318 C84.4730946,59.0239821 86.7685187,54.4680564 89.4358281,54.0121592 L89.5068513,54 L90.373687,55.6744248 C90.6461515,55.9566362 95.338528,60.9895379 93.2088407,69.6404262 C93.400032,70.3497629 95.9913157,80.0886592 94.5821989,81.5442045 C94.2650516,81.8719147 93.6280086,82 92.8238585,82 C89.9944426,82 85.1012115,80.4108969 84.8374782,80.3245625 Z'
						/>
						<circle cx='89.5' cy='45.5' r='6.5' fill='#2F2E41' />
						<circle cx='87.5' cy='47.5' r='5.5' fill='#FFB8B8' />
						<path
							fill='#2F2E41'
							d='M90.4313289,51.83217 C89.9765186,51.9889314 89.4189308,52.127142 89.0610386,51.8020188 C88.71164,51.4846133 88.7752975,50.9041704 88.9811243,50.4765532 C89.186951,50.0489359 89.5061618,49.6684049 89.6040377,49.2029738 C89.7443115,48.5359283 89.3816395,47.8429319 88.8639227,47.4080426 C88.3462059,46.9731534 87.6945232,46.7447081 87.0583424,46.5248783 L83.5688729,45.319112 C82.7051245,45.020648 81.7743537,44.6700413 81.2909996,43.8846065 C80.5916098,42.7481172 81.2309852,41.1235779 82.3782015,40.4604151 C83.5254177,39.7972522 84.9744838,39.9207986 86.2122087,40.3871036 C86.8967724,40.6450076 87.5479822,41.0003946 88.2559387,41.181945 C89.6531513,41.5402458 91.2418193,41.2188138 92.4696298,41.9846009 C93.5384316,42.6512143 94.0341723,44.0113923 93.9981738,45.2840721 C93.9621753,46.5567518 93.480116,47.7687898 92.9716377,48.9327177 C92.6691263,49.6251795 92.3459742,50.3256176 91.8335829,50.8763788 C91.3211917,51.42714 90.5807539,51.8124098 89.841124,51.7110719 L90.4313289,51.83217 Z'
						/>
						<circle cx='94.5' cy='43.5' r='4.5' fill='#2F2E41' />
						<path
							fill='#FFF'
							d='M88.0000004,49.4448783 C87.9997037,49.22137 88.1862747,49.0324556 88.4356852,49.0037209 C88.6850957,48.9749862 88.9197029,49.1153765 88.9835455,49.3315636 C89.0473881,49.5477507 88.9205914,49.7724344 88.687447,49.8562502 L88.687447,50.8333798 C88.687447,50.9254016 88.6035069,51 88.4999615,51 C88.3964162,51 88.3124761,50.9254016 88.3124761,50.8333798 L88.3124761,49.8562502 C88.1237355,49.7885137 88.0001198,49.6257745 88.0000004,49.4448783 Z'
						/>
						<path
							fill='#FFB8B8'
							d='M74.9056481,64.0098468 C75.5208243,63.951254 76.1315464,64.1562158 76.5734817,64.5695811 C77.0154171,64.9829464 77.2431688,65.5622533 77.1954537,66.1516223 L84,68.9892034 L80.7659548,71 L74.8000565,67.9971077 C73.7467747,67.8516377 72.9737437,66.9758841 73.0006829,65.958628 C73.0276221,64.9413718 73.8460163,64.1041503 74.9056481,64.0098388 L74.9056481,64.0098468 Z'
						/>
						<path
							fill='#046CB6'
							d='M87.7192594,73.8174661 L79.5608645,70.2562553 C79.3333488,70.1569292 79.1540513,69.9662825 79.0636406,69.7275577 C78.9732298,69.4888329 78.9793683,69.2222625 79.0806636,68.9883102 L79.8506123,67.2102336 C80.0402287,66.7738833 80.5118246,66.5516032 80.9524188,66.6909128 L87.4614899,68.7587975 L87.7718423,59.5619849 C87.7966032,58.8284966 88.1122668,58.1383834 88.6438715,57.6555288 C89.1754763,57.1726741 89.8755961,56.9401549 90.5779713,57.0131881 C91.7009208,57.124033 92.6187421,57.9922147 92.830773,59.144149 C93.5541034,62.9932059 91.792722,69.1323294 90.6783621,72.4492646 C90.3657618,73.3758418 89.5255922,73.9971033 88.5812084,74 C88.2850681,73.9994441 87.9920182,73.9373853 87.7192594,73.8174661 Z'
						/>
						<path
							fill='#B797D0'
							d='M36,82.7253478 C36,75.3948683 30.1797023,58 23.000001,58 C15.8202997,58 10,75.3948683 10,82.7253478 C10.0039665,90.0579222 15.8231024,96 22.999999,96 C30.1768957,96 35.9960316,90.0579222 35.999998,82.7253478 L36,82.7253478 Z'
						/>
						<path
							fill='#E6E6E6'
							d='M22.4999965,115 C22.6325614,115.000014 22.7597154,114.984506 22.8534849,114.956887 C22.9472543,114.929268 22.9999576,114.891801 23,114.852729 L23,63.1468218 C22.9989729,63.0656459 22.7754164,63 22.5,63 C22.2245836,63 22.0010271,63.0656459 22,63.1468218 L22,114.852729 C22.0000406,114.891801 22.0527423,114.929268 22.1465106,114.956887 C22.240279,114.984506 22.3674323,115.000014 22.4999965,115 Z'
						/>
						<path
							fill='#E6E6E6'
							d='M18.1395968 66.9999999C18.1766365 67.0000505 18.2121695 66.9853297 18.2383338 66.9590946L24.9591621 60.2383876C25.0136464 60.1838194 25.0136077 60.0953855 24.9590757 60.040865 24.9045436 59.9863446 24.8161684 59.9863833 24.7616841 60.0409515L18.0408598 66.7616585C18.0009533 66.8016013 17.9890257 66.8616683 18.0106403 66.9138437 18.0322548 66.966019 18.0831535 67.0000246 18.1395968 66.9999999zM22.8542771 87.9999999C22.9131974 88.0000271 22.9663302 87.9637028 22.9888931 87.9079692 23.011456 87.8522357 22.9990043 87.7880724 22.9573457 87.7454065L14.2487706 79.0437435C14.1918961 78.9854549 14.099644 78.9854136 14.0427197 79.0436512 13.9857954 79.1018888 13.985755 79.196352 14.0426294 79.2546407L22.7512086 87.9563057C22.7785234 87.9843254 22.8156135 88.0000491 22.8542771 87.9999999z'
						/>
						<path
							fill='#B797D0'
							d='M32.553408,117.944384 C32.5276411,117.963721 32.5005529,117.981087 32.4745201,118 L10.3752322,118 C9.94220609,116.295363 9.789295,114.588914 10.4361081,113.106972 C11.3576457,110.995306 14.0851132,109.677154 16.2534699,110.583991 C16.767149,110.804602 17.2146678,111.148627 17.5539314,111.583708 C18.401314,110.109953 19.9213467,108.998786 21.6758705,109.000001 C23.8740054,109.001524 25.701633,110.748072 26.2980213,112.765503 C27.2584773,111.190405 29.155525,110.089658 31.009711,110.563176 C34.1110418,111.355159 35.0906271,116.039209 32.553408,117.944384 Z'
						/>
						<path
							fill='#CCC'
							d='M0.201793722,119 L44.7982063,119 C44.9096539,119 45,118.776142 45,118.5 C45,118.223858 44.9096539,118 44.7982063,118 L0.201793722,118 C0.0903461267,118 0,118.223858 0,118.5 C0,118.776142 0.0903461267,119 0.201793722,119 Z'
						/>
						<path
							fill='#6E27A3'
							d='M73.6653543,43 L3.33464567,43 C1.49383372,42.9978932 0.00207937934,41.4864887 0,39.6214286 L0,3.37857143 C0.00207826799,1.51351083 1.49383325,0.00210564406 3.33464567,0 L73.6653543,0 C75.5061663,0.00210677005 76.9979206,1.5135113 77,3.37857143 L77,39.6214286 C76.9979195,41.4864882 75.5061658,42.9978921 73.6653543,43 Z'
						/>
						<path
							fill='#FFF'
							d='M73.2619048,40 L3.73809524,40 C2.778616,39.9989293 2.00107169,39.2220721 2,38.2634409 L2,3.73655914 C2.00107057,2.77792741 2.77861554,2.00106962 3.73809524,2 L73.2619048,2 C74.2213845,2.00106962 74.9989294,2.77792741 75,3.73655914 L75,38.2634409 C74.9989283,39.2220721 74.221384,39.9989293 73.2619048,40 Z'
						/>
						<path
							fill='#6E27A3'
							d='M68.5799383,49.5810868 L60,41 L70.9999219,41.278107 L70.9999219,48.5785676 C71.0059863,49.1538429 70.6585333,49.6739124 70.1248028,49.8884506 C69.9501131,49.9615815 69.7627033,49.9994898 69.5733285,50 C69.199522,49.9987856 68.8417662,49.8479198 68.5799383,49.5810868 Z'
						/>
						<path
							fill='#CCC'
							d='M64.3829787 20L29.6170213 20C28.7239651 20 28 19.1045695 28 18 28 16.8954305 28.7239651 16 29.6170213 16L64.3829787 16C65.2760349 16 66 16.8954305 66 18 66 19.1045695 65.2760349 20 64.3829787 20zM52.4242424 13L29.5757576 13C28.7054907 13 28 12.1045695 28 11 28 9.8954305 28.7054907 9 29.5757576 9L52.4242424 9C53.2945093 9 54 9.8954305 54 11 54 12.1045695 53.2945093 13 52.4242424 13z'
						/>
						<circle cx='14.5' cy='14.5' r='5.5' fill='#00AAE8' />
						<circle cx='54' cy='32' r='2' fill='#E6E6E6' />
						<circle cx='60' cy='32' r='2' fill='#E6E6E6' />
						<circle cx='66' cy='32' r='2' fill='#E6E6E6' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
