import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';
import { destructiveHover, error, label } from '../../../../web/styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		width: '80%',
		...CssSelectors.allChildren({
			borderRadius: 6,
			marginTop: 10,
		}),
	},
	label: {
		color: label,
		marginBottom: 8,
		marginTop: 16,
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
		marginTop: 0,
	},
});
