import * as Api from '@ViewModels';
import * as React from 'react';

export enum EStatusOption {
	WithEmail,
	WithPhoneNumber,
	WithAddress,
	HaveAlertsDue,
	PrivateContacts,
	KeepInTouch,
	WithoutTags,
	NoEmail,
	WithoutPhoneNumber,
	NoAddress,
	Archived,
}

const withEmailCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.WithEmailAddress };
const withPhoneNumberCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithPhoneNumber,
};
const withAddressCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithMailingAddress,
};
const haveAlertsDueCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.TagAlert };
const privateContactsCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.PrivateContacts,
};
const keepInTouchCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.KeepInTouch };
const withoutTagsCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.WithoutTags };
const noEmailCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithoutEmailAddresses,
};
const withoutPhoneNumberCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithPhoneNumber,
	op: Api.FilterOperator.Not,
};
const noAddressCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithMailingAddress,
	op: Api.FilterOperator.Not,
};
const archivedCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.Archived };

export const StatusFilterCheckboxesMap = {
	[EStatusOption.WithEmail]: { text: 'With Email', criteria: withEmailCriteria },
	[EStatusOption.WithPhoneNumber]: { text: 'With Phone Number', criteria: withPhoneNumberCriteria },
	[EStatusOption.WithAddress]: { text: 'With Address', criteria: withAddressCriteria },
	[EStatusOption.HaveAlertsDue]: { text: 'Have Alerts Due', criteria: haveAlertsDueCriteria },
	[EStatusOption.PrivateContacts]: { text: 'Private contacts', criteria: privateContactsCriteria },
	[EStatusOption.KeepInTouch]: { text: 'Keep in touch', criteria: keepInTouchCriteria },
	[EStatusOption.WithoutTags]: { text: 'Without Tags', criteria: withoutTagsCriteria },
	[EStatusOption.NoEmail]: { text: 'No Email', criteria: noEmailCriteria },
	[EStatusOption.WithoutPhoneNumber]: { text: 'No Phone Number', criteria: withoutPhoneNumberCriteria },
	[EStatusOption.NoAddress]: { text: 'No Address', criteria: noAddressCriteria },
	[EStatusOption.Archived]: { text: 'Archived', criteria: archivedCriteria },
};

const findMatchingStatus = (criterion: Api.IContactFilterCriteria) => {
	return Object.entries(StatusFilterCheckboxesMap).find(entry => {
		const value = entry[1];
		return criterion.property === value.criteria.property && criterion.op === value.criteria.op;
	});
};

export const useStatusFilters = (initialFilterCriteria: Api.IContactFilterCriteria[] = []) => {
	const getInitialValues = (criteria: Api.IContactFilterCriteria[]) => {
		return criteria
			.map(criterion => {
				const match = findMatchingStatus(criterion);
				return match ? (parseInt(match[0], 10) as EStatusOption) : undefined;
			})
			.filter(x => x !== undefined) as EStatusOption[];
	};

	const [selectedStatuses, setSelectedStatuses] = React.useState<EStatusOption[]>(
		getInitialValues(initialFilterCriteria)
	);

	const getStatusCriteria = () => {
		return selectedStatuses.map(statusOption => StatusFilterCheckboxesMap[statusOption].criteria);
	};

	const refreshStatuses = (criteria: Api.IContactFilterCriteria[]) => {
		setSelectedStatuses(getInitialValues(criteria));
	};

	return {
		findMatchingStatus,
		getStatusCriteria,
		selectedStatuses,
		setSelectedStatuses,
		refreshStatuses,
	};
};
