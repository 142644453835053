import * as React from 'react';
import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

export const BlogIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
	fill = navigation,
	width = 21,
	height = 18,
	viewBox = '0 0 21 18',
	...props
}) => {
	return (
		<SvgIcon height={height} width={width} viewBox={viewBox} {...props}>
			<g fill={fill} fillRule='evenodd'>
				<path d='M16,20 L2,20 C0.897,20 0,19.103 0,18 L0,4 C0,2.898 0.897,2 2,2 L9,2 L9,4 L2,4 L2,18 L16,18 L16,9 L18,9 L18,18 C18,19.103 17.103,20 16,20' />
				<polygon points='7.8076 8.3647 10.6356 11.1927 7.1006 11.8997' />
				<path d='M18.4141,3.4141 C19.1951,2.6331 19.1951,1.3671 18.4141,0.5861 C17.6331,-0.1949 16.3671,-0.1949 15.5861,0.5861 L9.2221,6.9501 L12.0501,9.7781 L18.4141,3.4141 Z' />
			</g>
		</SvgIcon>
	);
};
