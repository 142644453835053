import {
	IContact,
	IEmailMessageComposeContact,
	IOperationResultNoValue,
	ResourceAutoCompleteViewModelType,
	SendEmailFrom,
} from '@ViewModels';
import { css } from 'aphrodite';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey } from '../../../../models/Storage';
import {
	getDisplayName,
	getDisplayNameForBulkEmail,
	getKeyDateKindFromResourceSelectorId,
	getPrincipalInitials,
} from '../../../../models/UiUtils';
import { useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	ComposeEmailViewModel,
	ComposeResourceEmailViewModel,
	ContactViewModel,
	EmailCategories,
	HouseholdTitle,
	KeyDateKind,
} from '../../../../viewmodels/AppViewModels';
import { bs } from '../../../styles/styles';
import { Avatar2 } from '../../Avatar2';
import { CloseButton } from '../../CloseButton';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { LoadingSpinner } from '../../LoadingSpinner';
import { AutoCompleteSearchField } from '../../autocomplete/AutoCompleteSearchField';
import { ContactsInfoIcon } from '../../svgs/icons/ContactsInfoIcon';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';
import './styles.less';

interface IProps extends IImpersonationContextComponentProps {
	allowNoContactsMessaging?: boolean;
	bulkEmailComposer: ComposeEmailViewModel;
	className?: string;
	disableContactAdding?: boolean;
	disableContactEdits?: boolean;
	disableContactRemoval?: boolean;
	onContactSelected?(contact: ContactViewModel): void;
	onOptionClicked?(): void;
	onRenderEmptyPlaceholder?(): React.ReactNode;
	onScrollerRef?(ref?: HTMLElement): void;
	onScrollToBottom?(): void;
}

const SuppressConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Suppress',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

function _EmailRecipientsList({
	onRenderEmptyPlaceholder,
	onScrollToBottom,
	onContactSelected,
	allowNoContactsMessaging,
	impersonationContext,
	disableContactAdding,
	disableContactRemoval,
	className,
	bulkEmailComposer,
	onScrollerRef,
}: IProps) {
	const { logApiError } = useEventLogging('EmailRecipientsList');
	const userSession = useUserSession();
	const toaster = useToaster();
	const [temporaryCustomMessage, setTemporaryCustomMessage] = React.useState<IEmailMessageComposeContact>(null);
	const [isSuppressConfirmationOpen, setIsSuppressConfirmationOpen] = React.useState(false);
	const [onConfirmationModalCloseAction, setOnConfirmationModalCloseAction] = React.useState<() => void>(null);
	const isForResource =
		bulkEmailComposer?.resourceSelector ||
		bulkEmailComposer?.campaign?.categories?.includes(EmailCategories.ResourceSelector);
	const resourceSelector = (bulkEmailComposer as ComposeResourceEmailViewModel)?.resourceSelector;
	const keyDateKind = getKeyDateKindFromResourceSelectorId(resourceSelector);
	const isExistingCampaign = !!bulkEmailComposer?.campaign?.status;
	/**
	 *  When an email is being created from scratch the view model corresponds to the type correctly.
	 *  If creating an email from resource the bulkEmailComposer is of type ComposeResourceEmailViewModel.
	 *  When editing a scheduled email (via the reports page) the bulkEmailComposer will _always_ be of type ComposeEmailViewModal.
	 *  This is a bug with a decent amount of surface area, for now the workaround is to just use the method on ComposeEmailViewModal.
	 * */
	const getApproximation = () =>
		isForResource && !isExistingCampaign
			? (bulkEmailComposer as ComposeResourceEmailViewModel).getEmailApproximationWithResource()
			: bulkEmailComposer.emailMessage.getEmailApproximation();

	const onItemSelected = (contactModel: IContact) => {
		const contact = new ContactViewModel(userSession, contactModel);
		if (bulkEmailComposer.emailMessage.contactsToOmit.has(contact)) {
			bulkEmailComposer.emailMessage.contactsToOmit.removeItems([contact]);
		}
		bulkEmailComposer.emailMessage.contactsToAdd.splice(0, 0, contact);

		const promise = getApproximation();

		promise?.catch((error: IOperationResultNoValue) => {
			logApiError('HasEmailApproximationLoad-Error', error);
		});

		if (bulkEmailComposer.emailMessage.options?.sendEmailFrom === SendEmailFrom.ContactOwner) {
			bulkEmailComposer.emailMessage.options = {
				...bulkEmailComposer.emailMessage.options,
				sendEmailFrom: SendEmailFrom.CurrentUser,
			};
		}
	};
	const updateApproximation = () => {
		const promise = getApproximation();

		promise?.catch((error: IOperationResultNoValue) => {
			logApiError('HasEmailApproximationLoad-Error', error);
		});
	};
	const toggleSuppressionConfirmation = (_isSuppressConfirmationOpen: boolean) => {
		// skip ahead to the removing if the contact will show up on the dashboard again
		if (
			_isSuppressConfirmationOpen &&
			userSession.account.preferences.suppressContactsRemovedFromResourceSends === false
		) {
			onConfirmationModalCloseAction?.();
			return;
		}
		setIsSuppressConfirmationOpen(_isSuppressConfirmationOpen);
	};
	const onUndoRemoveContact = (contact: ContactViewModel) => () => {
		if (temporaryCustomMessage) {
			bulkEmailComposer.emailMessage.setCustomMessageForContact(contact, temporaryCustomMessage);
		}
		bulkEmailComposer.emailMessage.contactsToOmit.removeItems([contact]);
		toaster.currentToastMessage = null;
		updateApproximation();
	};
	const onRemoveCustomMessageForContact = (contact: ContactViewModel, shouldUpdateApproximation = true) => {
		const onRemoveAction = () => {
			setTemporaryCustomMessage(bulkEmailComposer.emailMessage.getCustomMessageForContact(contact));
			// If error try putting the below statement in setTimeout
			runInAction(() => {
				bulkEmailComposer.emailMessage.setCustomMessageForContact(contact, null);
				if (bulkEmailComposer.emailMessage.contactsToAdd.has(contact)) {
					bulkEmailComposer.emailMessage.contactsToAdd.removeItems([contact]);
				}
				bulkEmailComposer.emailMessage.contactsToOmit.add(contact);
				if (bulkEmailComposer.emailMessage?.options?.followUp) {
					bulkEmailComposer.emailMessage.options.followUp.excludeContactIds =
						bulkEmailComposer.emailMessage.options.followUp.excludeContactIds || [];
					bulkEmailComposer.emailMessage.options.followUp?.excludeContactIds.push(contact.id);
				}
				if (bulkEmailComposer.emailMessage.groupByHousehold) {
					const spouse = contact.householdMembers?.find(
						c => c.id !== contact.id && c.householdRelationship?.title === HouseholdTitle.Spouse
					);
					if (spouse) {
						const spouseVm = new ContactViewModel(userSession, spouse);
						if (bulkEmailComposer.emailMessage.contactsToAdd.has(spouseVm)) {
							bulkEmailComposer.emailMessage.contactsToOmit.removeItems([spouseVm]);
						}
						bulkEmailComposer.emailMessage.contactsToOmit.add(spouseVm);
					}
				}

				if (bulkEmailComposer.emailMessage.contactsFilterRequest?.groupByDuplicate) {
					// remove the stack of contacts instead of just the parent
					contact.duplicateContacts?.forEach(x => onRemoveCustomMessageForContact(x, false));
				}

				if (shouldUpdateApproximation) {
					updateApproximation();
				}
			});
		};

		if (isForResource) {
			const hasBeenNotifiedAboutSnooze = Boolean(
				localStorage.getItem(HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey)
			);
			if (!hasBeenNotifiedAboutSnooze) {
				setOnConfirmationModalCloseAction(onRemoveAction);
				// If error try putting the below statement in setTimeout
				toggleSuppressionConfirmation(true);
			} else {
				onRemoveAction();
				toaster.push({
					linkTitle: 'Undo',
					message: `The key date for ${getDisplayName(contact)} will be suppressed`,
					onLinkClicked: onUndoRemoveContact(contact),
					type: 'successMessage',
				});
			}
		} else {
			onRemoveAction();
		}
	};
	const onForceSuppressionConfirmationRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		if (!!result && !canceled && !!result.representedObject) {
			localStorage.setItem(HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey, 'true');
			onConfirmationModalCloseAction?.();
		}
		toggleSuppressionConfirmation(false);
	};
	return (
		<div className={`email-recipients-list ${css(styleSheet.container)} ${className || ''}`}>
			{!disableContactAdding && (
				<AutoCompleteSearchField
					anchorClassName='email-recipients-list-autocomplete-search-field-anchor'
					className='email-recipients-list-autocomplete-search-field'
					clearSearchFieldAfterSelectingItem={true}
					dropdownContentClassName={css(styleSheet.autocompleteDropdownContent)}
					inputId='email-recipients-list-search-input'
					inputProps={{
						placeholder: 'Add additional contacts',
					}}
					leftAccessory={<SearchIcon className='email-recipients-list-autocomplete-search-field-icon' />}
					onItemSelected={onItemSelected}
					resultsLimit={6}
					type={ResourceAutoCompleteViewModelType.Contact}
				/>
			)}
			{bulkEmailComposer?.recipients?.length > -1 ? (
				<div ref={ref => onScrollerRef?.(ref)}>
					{bulkEmailComposer.recipients.map(contact => {
						const keyFactsResult = contact?.keyFactsCollection?.find(
							x =>
								x?.keyDate?.kind === KeyDateKind.Birthday ||
								KeyDateKind.Turning65 ||
								KeyDateKind.Turning72 ||
								KeyDateKind.Turning73
						);
						const birthdayKeyFactValue =
							(keyDateKind === KeyDateKind.Birthday ||
								keyDateKind === KeyDateKind.Turning65 ||
								keyDateKind === KeyDateKind.Turning72 ||
								keyDateKind === KeyDateKind.Turning73) &&
							keyFactsResult
								? keyFactsResult.value
								: null;
						let companyLine: string | undefined;
						if (contact.companyName != null) {
							companyLine =
								contact.jobTitle != null ? `${contact.jobTitle} at ${contact.companyName}` : contact.companyName;
						}
						const hasEmailAddresses = contact.emailAddresses && contact.emailAddresses.length > 0;
						const hasCustomEmailMessageContent =
							!!bulkEmailComposer.emailMessage.getCustomMessageForContact(contact)?.content;
						const hasCustomEmailMessageAttachments =
							!!bulkEmailComposer.emailMessage.getCustomMessageForContact(contact)?.attachments;
						const showTertiaryNode =
							(!hasEmailAddresses || bulkEmailComposer.canCustomizeIndividualEmails) &&
							(bulkEmailComposer.emailMessage?.options?.sendEmailFrom === SendEmailFrom.CurrentUser || isForResource) &&
							!impersonationContext?.isValid &&
							!bulkEmailComposer.isFollowUp;
						const isSending =
							bulkEmailComposer.emailMessage.isSending ||
							(!!bulkEmailComposer.sendSystemJob && bulkEmailComposer.sendSystemJob.percentComplete < 100);
						const secondaryText = birthdayKeyFactValue ?? companyLine;
						const spouses = contact.householdMembers?.filter(
							i => i.householdRelationship?.title === HouseholdTitle.Spouse
						);
						const multipleHouseholdMembers =
							bulkEmailComposer.emailMessage.groupByHousehold && spouses && spouses.length > 1;
						const nameLabel =
							multipleHouseholdMembers && contact.household.name
								? contact.household.name
								: getDisplayNameForBulkEmail(contact);

						return (
							<div
								key={contact.id}
								className={css(
									bs.flex,
									bs.itemsCenter,
									bs.border0,
									bs.borderB,
									bs.borderSolid,
									bs.borderGray200,
									bs.gap2,
									bs.boxBorder
								)}
							>
								{!impersonationContext?.isValid && !disableContactRemoval ? (
									<CloseButton
										styles={[bs.py1, bs.px2]}
										onClick={ev => {
											ev.stopPropagation();
											onRemoveCustomMessageForContact(contact);
										}}
									/>
								) : null}
								<div
									className='email-recipients-list-item'
									onClick={
										!!bulkEmailComposer.canCustomizeIndividualEmails &&
										!impersonationContext?.isValid &&
										!bulkEmailComposer.isFollowUp
											? () => onContactSelected?.(contact)
											: undefined
									}
								>
									{multipleHouseholdMembers ? (
										<div className={css(styleSheet.recipientsListItemAvatarList)}>
											{spouses.slice(0, 2).map((householdMember, i) => {
												return (
													<Avatar2
														styleDeclaration={
															i === 0
																? styleSheet.recipientsListItemAvatarsFirst
																: styleSheet.recipientsListItemAvatarsSecond
														}
														key={householdMember.id}
														imgSrc={householdMember.profilePic}
														fallbackText={getPrincipalInitials(householdMember)}
													/>
												);
											})}
										</div>
									) : (
										<Avatar2
											styleDeclaration={styleSheet.avatar}
											imgSrc={contact.profilePic}
											fallbackText={getPrincipalInitials(contact)}
										/>
									)}

									<div className='email-recipients-list-item-details'>
										<div className='email-recipients-list-item-details-name'>{nameLabel}</div>
										{secondaryText ? (
											<div className='email-recipients-list-item-details-company-name truncate-text'>
												{secondaryText}
											</div>
										) : null}
										{showTertiaryNode ? (
											<div
												className={`email-recipients-list-item-details-info truncate-text ${
													!hasEmailAddresses
														? 'invalid-email'
														: hasCustomEmailMessageContent || hasCustomEmailMessageAttachments
															? 'email-customized'
															: ''
												}`}
											>
												{!hasEmailAddresses
													? 'No email address'
													: hasCustomEmailMessageContent
														? 'See customized email'
														: 'Customize email'}
											</div>
										) : null}
									</div>
									{isSending ? (
										<LoadingSpinner className='email-recipients-list-item-right-accessory' type='small' />
									) : null}
								</div>
							</div>
						);
					})}
					{!bulkEmailComposer.isFetchingContacts && (
						<Waypoint bottomOffset='-200px' key='email-recipients-list-waypoint' onEnter={onScrollToBottom} />
					)}
				</div>
			) : allowNoContactsMessaging &&
				bulkEmailComposer?.emailMessage?.options?.sendEmailFrom === SendEmailFrom.SelectedUser &&
				!bulkEmailComposer.recipients.length ? (
				<div className={css(styleSheet.noContactsMessage)}>
					<div>
						<ContactsInfoIcon />
					</div>
					<div>
						This sender does not own any contacts for the given filter. You can change the option to &quot;Showing all
						contacts&quot;.
					</div>
				</div>
			) : (
				!bulkEmailComposer.recipients.length && !bulkEmailComposer.isBusy && onRenderEmptyPlaceholder?.()
			)}
			<ConfirmationDialog
				className={css(styleSheet.modal)}
				icon={<WarningIcon />}
				modalProps={{
					isOpen: isSuppressConfirmationOpen,
					onRequestClose: onForceSuppressionConfirmationRequestClose,
				}}
				options={SuppressConfirmationOptions}
				title='Excluding a contact from this send will suppress the contact from appearing on the dashboard. Are you sure?'
			/>
		</div>
	);
}

const EmailRecipientsListAsObserver = observer(_EmailRecipientsList);
export const EmailRecipientsList = inject(ImpersonationContextKey)(EmailRecipientsListAsObserver);
