import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { CampaignsApprovalViewModel, EmailSendStatus } from '../../../../viewmodels/AppViewModels';
import EmailGraphic from '../../../assets/email_graphic.svg';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { SvgIcon } from '../../../components/svgs/icons/SvgIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps {
	approval: CampaignsApprovalViewModel;
	className?: string;
	disableHeader?: boolean;
	onDone?(showNextComposer?: boolean): void;
	styles?: StyleDeclarationValue[];
}

class _BulkEmailApprovalConfirmation extends React.Component<IProps> {
	public render() {
		const { approval, className, styles, disableHeader } = this.props;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} bulk-email-approval-campaign-confirmation ${
					className || ''
				}`}
			>
				{!disableHeader && (
					<MultiContainerHeader
						fullscreenHeader='Confirmation'
						onFullscreenRequestBack={this.onFullscreenRequestBack}
					/>
				)}
				<div className={css(styleSheet.content)}>
					<div className={css(styleSheet.image)}>
						<SvgIcon height={175} width={182}>
							<g fill='none' fillRule='evenodd'>
								<circle cx='133.5' cy='8.5' r='4.5' fill='#F1F1F1' fillRule='nonzero' />
								<circle cx='109.5' cy='170.5' r='4.5' fill='#F1F1F1' fillRule='nonzero' />
								<circle cx='4.5' cy='28.5' r='4.5' fill='#F1F1F1' fillRule='nonzero' />
								<polygon
									fill='#F1F1F1'
									fillRule='nonzero'
									points='15.563 19.389 167.168 19.446 167.214 141.382 15.61 141.324'
									transform='rotate(16.714 91.389 80.385)'
								/>
								<g fillRule='nonzero' transform='translate(48 24)'>
									<rect width='87' height='109' y='.158' fill='#046CB6' />
									<rect width='63' height='3' x='12' y='26.158' fill='#F9F9F9' />
									<rect width='63' height='3' x='12' y='36.158' fill='#F9F9F9' />
									<rect width='63' height='3' x='12' y='46.158' fill='#F9F9F9' />
									<rect width='63' height='3' x='12' y='56.158' fill='#F9F9F9' />
								</g>
								{this.lastCompletedCampaign?.status === EmailSendStatus.Rejected ? (
									<>
										<circle
											cx='26'
											cy='26'
											r='25'
											fill='#F89143'
											stroke='#F6D1B6'
											strokeWidth='2'
											transform='translate(110 106.158)'
										/>
										<polygon
											fill='#FFF'
											points='21.634 2.126 19.91 .403 11.019 9.294 2.127 .403 .403 2.126 9.295 11.018 .403 19.909 2.127 21.633 11.019 12.743 19.91 21.633 21.634 19.909 12.742 11.018'
											transform='translate(125 121)'
										/>
									</>
								) : (
									<g transform='translate(110 106.158)'>
										<circle cx='26' cy='26' r='25' fill='#89C947' stroke='#DBF2C3' strokeWidth='2' />
										<polygon
											fill='#FFF'
											points='23.815 38.811 13.301 28.299 17.898 23.702 22.986 28.79 33.755 13.713 39.043 17.488'
										/>
									</g>
								)}
							</g>
						</SvgIcon>
					</div>
					<div className={css(styleSheet.title)}>
						{`Campaign ${this.lastCompletedCampaign?.status === EmailSendStatus.Rejected ? 'Rejected' : 'Approved!'}`}
					</div>
					<div className={css(styleSheet.message)}>
						{this.lastCompletedCampaign?.status === EmailSendStatus.Queued && (
							<div>
								{'See scheduled emails on the '}
								<NavLink to='/reporting/group-email' className={css(styleSheet.link)}>
									Group Email Reports
								</NavLink>
								{' page.'}
							</div>
						)}
						{!!approval?.campaignComposer && <div>You have another campaign to review</div>}
					</div>
					{approval?.campaignComposer ? (
						<div className={css(styleSheet.anotherCampaign)}>
							<div className={css(styleSheet.anotherCampaignRow)}>
								<img src={EmailGraphic} className={css(styleSheet.anotherCampaignImage)} />
								<div className={css(styleSheet.anotherCampaignText)}>
									<div className={css(styleSheet.anotherCampaignTitle)}>
										{approval?.activeCampaign?.templateReference?.name ||
											approval?.campaignComposer?.emailMessage?.subject}
									</div>
									<div className={css(styleSheet.anotherCampaignMessage)}>
										{`Sending on ${moment(approval?.activeCampaign?.schedule?.startDate).format('L')}`}
									</div>
								</div>
							</div>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.anotherCampaignButton)}
								onClick={this.onDone(true)}
							>
								<span>Review & Approve</span>
							</button>
						</div>
					) : (
						<button className={css(baseStyleSheet.ctaButton, styleSheet.button)} onClick={this.onDone(false)}>
							<span>Close window</span>
						</button>
					)}
				</div>
			</div>
		);
	}

	@computed
	private get lastCompletedCampaign() {
		const { approval } = this.props;
		return approval?.completedCampaigns?.length > 0
			? approval.completedCampaigns[approval.completedCampaigns.length - 1]
			: null;
	}

	private onFullscreenRequestBack = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		this.onDone(false)();
	};

	private onDone = (showNext: boolean) => () => {
		const { onDone } = this.props;
		if (onDone) {
			onDone(showNext);
		}
	};
}

const BulkEmailApprovalConfirmationAsObserver = observer(_BulkEmailApprovalConfirmation);
export const BulkEmailApprovalConfirmation = withEventLogging(
	BulkEmailApprovalConfirmationAsObserver,
	'BulkEmailApprovalConfirmation'
);
