import { brandPrimaryHover } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	textingTriggerIcon: {
		alignItems: 'center',
		border: `1px ${brandPrimaryHover} solid`,
		borderRadius: '50%',
		boxSizing: 'border-box',
		display: 'inline-flex',
		height: 30,
		justifyContent: 'center',
		width: 30,
		...CssSelectors.allChildren({
			height: 'auto',
			width: 16,
		}),
	},
});
