import { AIContentGenerationStatus } from '../../../../models/Ai';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useEmailContentGeneration } from '../../../../queries';
import * as Api from '@ViewModels';
import * as React from 'react';

export function useEmailAiContentWizard({
	isRewriting = false,
	onEmailGenerated,
	onUndo,
}: {
	isRewriting?: boolean;
	onEmailGenerated: (email: Api.IEmailMessageCompose<Api.IEmailMessageFollowUpOptions>) => void;
	onUndo: (undoContent: Api.IEmailMessageCompose) => void;
}) {
	const userSession = useUserSession();
	// @ts-ignore
	const [undoContent, setUndoContent] = React.useState<Api.IEmailMessageCompose>(null);
	const [contentGenerationStatus, setContentGenerationStatus] = React.useState(AIContentGenerationStatus.Idle);
	const [showingAiContentGenWizard, setShowingAiContentGenWizard] = React.useState(false);
	const emailContentGenMutation = useEmailContentGeneration({
		onError: error => {
			setContentGenerationStatus(
				error.systemCode === 408 ? AIContentGenerationStatus.TimeOut : AIContentGenerationStatus.Error
			);
		},
		onSuccess: generatedContent => {
			const email = generatedContent.content;
			onEmailGenerated(email);
			setContentGenerationStatus(AIContentGenerationStatus.Success);
		},
		userSession,
	});
	const onGenerate = (request: Api.IEmailContentGenerationRequest) => {
		const { content, subject, templateId, ...rest } = request;
		setUndoContent({ content, subject });
		setContentGenerationStatus(AIContentGenerationStatus.Generating);
		return emailContentGenMutation.mutateAsync(isRewriting ? request : rest);
	};
	const aiContentGenContext = React.useMemo(
		() => ({
			clearContentGenerationStatus: () => {
				setContentGenerationStatus(AIContentGenerationStatus.Idle);
			},
			contentGenerationStatus,
		}),
		[contentGenerationStatus]
	);
	const onUndoClicked = () => {
		onUndo(undoContent);
	};

	return {
		aiContentGenContext,
		onGenerate,
		onUndoClicked,
		setShowingAiContentGenWizard,
		showingAiContentGenWizard,
	};
}
