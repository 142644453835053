// eslint-disable-next-line import/no-internal-modules
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IPostTarget, ITemplate, SocialMediaPostViewModel, SocialMediaType } from '../../../../extViewmodels';
import {
	getShortenedString,
	getSocialMediaPlainTextContentFromRawRichContent,
	getSocialMediaPlatformLogoUrl,
} from '../../../../models/UiUtils';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import {
	BasicImagePreview,
	BasicVideoPreview,
	InstagramImagePreview,
	LinkedInImagePreview,
	PreviewPostContentView,
} from '../PreviewSocialMediaPost/presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	post?: SocialMediaPostViewModel;
	styles?: StyleDeclarationValue[];
	targets?: IPostTarget[];
	template?: ITemplate;
}

export const PreviewSocialMediaPostGhostCalendar: React.FC<IProps> = observer(props => {
	const { className = '', template, targets, post, styles = [] } = props;

	const postContent = template
		? getSocialMediaPlainTextContentFromRawRichContent(template?.content)
		: getSocialMediaPlainTextContentFromRawRichContent(post?.content);
	const postImages = template ? template?.attachments : post?.attachments;
	const getContentLength = (targetChoice: SocialMediaType) => {
		if (targetChoice === SocialMediaType.Instagram) {
			return 125;
		} else if (targetChoice === SocialMediaType.LinkedIn) {
			return 150;
		}
		return 220;
	};

	const [targetIndex, setTargetIndex] = React.useState(0);
	const onSetTargetPreview = React.useCallback(
		(index?: number) => () => {
			if (targets?.length > 0) {
				setTargetIndex(index);
			}
		},
		[targets]
	);

	// Update target index back to zero when the targets input has changed (when they are desellected)
	React.useEffect(() => {
		setTargetIndex(0);
	}, [targets]);

	const contentDisplayLength = targets?.length > 0 ? getContentLength(targets[targetIndex].provider) : null;
	const displayContent =
		postContent?.length > contentDisplayLength
			? getShortenedString(postContent.trim(), contentDisplayLength).trim()
			: postContent?.trim();

	return targets?.length > 0 ? (
		<div className={`preview-social-media-post ${className} ${css(styleSheet.previewContentContainer, ...styles)}`}>
			<h3 className={css(styleSheet.previewHeader)}> PREVIEW POST </h3>
			<p className={css(styleSheet.iconRow)}>
				{targets?.length > 1 && (
					<button onClick={onSetTargetPreview(Math.max(targetIndex - 1, 0))}>
						<DisclosureIcon fillColor='#00AAE8' type='chevron' className={css(styleSheet.pointLeft)} />
					</button>
				)}
				{targets
					?.sort((a, b) => (a.provider > b.provider ? 1 : -1))
					?.map((x, i) => (
						<span className={css(styleSheet.iconRow)} key={`${x}-${i}`}>
							<button
								className={
									x === targets[targetIndex]
										? css(styleSheet.iconRow, styleSheet.iconRowSelected)
										: css(styleSheet.iconRow)
								}
								onClick={onSetTargetPreview(i)}
							>
								<img key={i} src={getSocialMediaPlatformLogoUrl(x.provider)} />
							</button>
						</span>
					))}
				{targets?.length > 1 && (
					<button onClick={onSetTargetPreview(Math.min(targetIndex + 1, targets?.length - 1))}>
						<DisclosureIcon fillColor='#00AAE8' type='chevron' className={css(styleSheet.pointRight)} />
					</button>
				)}
			</p>
			<figure
				className={css(
					styleSheet.previewContentBox,
					targets[targetIndex].provider === SocialMediaType.Instagram &&
						postImages?.length > 1 &&
						styleSheet.previewContentBoxInstagram
				)}
			>
				<PreviewPostContentView
					previewContent={displayContent}
					postContent={postContent}
					truncate={postContent?.length > contentDisplayLength}
					target={targets[targetIndex]}
				/>
				{postImages?.length > 0 ? (
					postImages.some(x => x.mimeType?.startsWith('video/')) ? (
						<BasicVideoPreview postImages={postImages} controls={true} />
					) : targets[targetIndex].provider === SocialMediaType.Instagram && postImages?.length > 1 ? (
						<InstagramImagePreview postImages={postImages} />
					) : targets[targetIndex].provider === SocialMediaType.LinkedIn ? (
						<LinkedInImagePreview postImages={postImages} />
					) : (
						<BasicImagePreview postImages={postImages} />
					)
				) : null}
			</figure>
			<span
				className={css(
					targets[targetIndex].provider === SocialMediaType.Instagram && postImages?.length > 1
						? styleSheet.disclaimerTextInstagram
						: styleSheet.disclaimerText
				)}
			>
				This is only a preview. Content may display slightly differently when published.
			</span>
		</div>
	) : null;
});
