import { emailActivityTintColor } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		borderRadius: 3,
		boxSizing: 'border-box',
		color: '#fff',
		display: 'inline-block',
		fontSize: 14,
		padding: '5px 10px',
	},
	html: {
		background: emailActivityTintColor,
	},
});
