import { BreakPoints, CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const ManageUsersTableInitialsColumnWidth = 150;
export const ManageUsersTableEmailColumnWidth = 150;
export const ManageUsersTableStatusColumnWidth = 150;
export const ManageUsersTableAssignmentColumnWidth = '99%';
export const ManageUsersTableStatusIndicatorColumnWidth = 30;
export const ManageUsersTableCellBorder = '1px solid #D8D8D8';

export const styleSheet = StyleSheet.create({
	actionToolbar: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		height: '50px',
		justifyContent: 'space-between',
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: '1rem',
			},
			':not(:first-child)'
		),
	},
	autoCompleteDropdownContent: {
		maxHeight: 'auto',
		overflow: 'inherit',
	},
	autoCompleteDropdownItems: {
		maxHeight: 300,
		overflow: 'auto',
	},
	cell: {
		borderBottom: ManageUsersTableCellBorder,
		padding: '20px 10px 20px 0',
	},
	container: {
		border: 0,
		borderCollapse: 'collapse',
		borderSpacing: 0,
		width: '100%',
	},
	dropdownContainer: {
		alignItems: 'center',
		display: 'flex',
		width: 350,
	},
	dropdownText: {
		display: 'block',
		width: 150,
	},
	emailInput: {
		color: '#4A4A4A',
		fontSize: 12,
		width: 200,
		[BreakPoints.forMinWidth(900)]: {
			width: 300,
		},
		[BreakPoints.forMinWidth(850)]: {
			[BreakPoints.forMaxWidth(900)]: {
				width: 250,
			},
		},
	},
	root: {},
	tableContainer: {
		alignItems: 'stretch',
		border: '1px solid #D3DBDE',
		borderRadius: 10,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
		display: 'flex',
		marginBottom: '1.5rem',
		padding: '20px 30px',
	},
	tableDisabled: {
		opacity: 0.5,
	},
	tableHeader: {
		background: '#fff',
	},
	tableHeaderAssignment: {
		minWidth: ManageUsersTableAssignmentColumnWidth,
		whiteSpace: 'nowrap',
		width: ManageUsersTableAssignmentColumnWidth,
	},
	tableHeaderCell: {
		borderBottom: ManageUsersTableCellBorder,
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		paddingBottom: 20,
		textTransform: 'uppercase',
	},
	tableHeaderEditHeaderEditButton: {
		alignItems: 'center',
		display: 'flex',
	},
	tableHeaderEditHeaderEditButtonIcon: {
		marginRight: '0.5rem',
	},
	tableHeaderEmailHeader: {
		minWidth: ManageUsersTableEmailColumnWidth,
		whiteSpace: 'nowrap',
		width: ManageUsersTableEmailColumnWidth,
	},
	tableHeaderInitialsHeader: {
		minWidth: ManageUsersTableInitialsColumnWidth,
		whiteSpace: 'nowrap',
		width: ManageUsersTableInitialsColumnWidth,
	},
	tableHeaderRow: {},
	tableHeaderStatusHeader: {
		minWidth: ManageUsersTableStatusColumnWidth,
		whiteSpace: 'nowrap',
		width: ManageUsersTableStatusColumnWidth,
	},
	tableHeaderStatusIndicatorHeader: {
		minWidth: ManageUsersTableStatusIndicatorColumnWidth,
		whiteSpace: 'nowrap',
		width: ManageUsersTableInitialsColumnWidth,
	},
});
