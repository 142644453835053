import { ContactViewModel, ContactsViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getContactTitleAndCompany } from '../../../../models/UiUtils';
import { grayIconFill } from '../../../styles/colors';
import { Avatar } from '../../Avatar';
import { Checkbox } from '../../Checkbox';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { TrashIcon } from '../../svgs/icons/TrashIcon';
import { styleSheet } from './styles';

interface IProps {
	checkboxId?: string;
	className?: string;
	contact: ContactViewModel;
	contacts?: ContactsViewModel;
	hideCheckbox?: boolean;
	onCheckChanged?(): void;
	onClick?(): void;
	onRemoveClicked?(): void;
	selected?: boolean;
	success?: boolean;
}

interface IState {
	hideCheckbox?: boolean;
}

class _ContactsListItem extends React.Component<IProps, IState> {
	public static defaultProps: Partial<IProps> = {
		hideCheckbox: false,
	};

	public readonly state: IState = {};

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, contact, onClick, onRemoveClicked, success, checkboxId } = this.props;
		const { hideCheckbox } = this.state;
		const containerStyles: StyleDeclarationValue[] = [
			styleSheet.container,
			// @ts-ignore
			!!this.isSelected && !hideCheckbox ? styleSheet.containerChecked : null,
		];
		const titleAndCompany = this.contactTitleAndCompany;
		return (
			<div className={`${css(containerStyles)} contacts-list-item ${className || ''}`} onClick={onClick}>
				{!hideCheckbox && success ? (
					<div className={css(styleSheet.success)}>
						<SuccessCheckIcon />
					</div>
				) : (
					!hideCheckbox && (
						<div className={css(styleSheet.check)}>
							<Checkbox
								checked={!!this.isSelected || false}
								disabled={!!contact.isBusy}
								id={`${checkboxId}contacts-list-item-check-${contact.id}`}
								onChange={this.onCheckChanged}
							/>
						</div>
					)
				)}
				{/* @ts-ignore */}
				<Avatar className={css(styleSheet.avatar)} entityVm={contact} />
				<div className={css(styleSheet.info, hideCheckbox ? styleSheet.infoWithoutCheckbox : null)}>
					<div className={css(styleSheet.infoName)}>{contact.name}</div>
					{!!titleAndCompany && <div className={css(styleSheet.infoTitle)}>{titleAndCompany}</div>}
				</div>
				{!!onRemoveClicked && !success && (
					<div className={css(styleSheet.trash)} onClick={onRemoveClicked}>
						<TrashIcon fillColor={grayIconFill} />
					</div>
				)}
			</div>
		);
	}

	/** Helper for composing contact title and company string */
	@computed
	private get contactTitleAndCompany() {
		const { contact } = this.props;
		return getContactTitleAndCompany(contact);
	}

	/**
	 * Helper for capturing selected state. Having this as a computed variablew instead of a local variable in render()
	 * should help reduce calls to render.
	 */
	@computed
	private get isSelected() {
		const { contact, contacts, selected } = this.props;
		if (selected) {
			return true;
		} else {
			if (contacts?.selectionState === 'all' || contacts?.selectionState === 'some') {
				if (contacts.selectedContacts.length === 0) {
					// select all checked... only check excluded
					return !contacts.excludedContacts.has(contact);
				}
				return contacts.selectedContacts.has(contact);
			}
			return contacts?.selectedContacts.has(contact);
		}
	}

	private onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { contact, contacts, onCheckChanged } = this.props;

		if (onCheckChanged) {
			onCheckChanged();
		} else {
			if (e.target.checked) {
				// contact should be included in effective selection
				if (contacts?.selectionState === 'none') {
					contacts.selectedContacts.add(contact);
				} else if (contacts?.selectionState === 'some') {
					if (contacts.selectedContacts.length > 0) {
						contacts.selectedContacts.add(contact);
					} else {
						// some selected but explicit list of selected contacts === 0
						// make sure to remove the contact from the exclusion list
						contacts?.excludedContacts.removeItems([contact]);
					}
				}
			} else {
				// contact should be excluded in effective selection
				if (contacts?.selectionState === 'some') {
					if (contacts.selectedContacts.has(contact)) {
						contacts.selectedContacts.removeItems([contact]);
					} else {
						contacts.excludedContacts.add(contact);
					}
				} else if (contacts?.selectionState === 'all') {
					contacts?.excludedContacts.add(contact);
				}
			}
		}
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};
		const { hideCheckbox } = this.state;

		if (nextProps.hideCheckbox !== hideCheckbox) {
			nextState.hideCheckbox = nextProps.hideCheckbox;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	};
}

export const ContactsListItem = observer(_ContactsListItem);
