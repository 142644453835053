import NotFoundIconUrl from '../../assets/404Icon.png';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps {
	className?: string;
	message?: string;
}

export const NotFound: React.FC<IProps> = props => {
	const { className = '', message } = props;
	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<div className={css(styleSheet.content)}>
				<div>
					<img className={css(styleSheet.icon)} src={NotFoundIconUrl} />
				</div>
				<div className={css(styleSheet.message)}>{message || 'The page you are looking for magically vanished.'}</div>
			</div>
		</div>
	);
};

const NotFoundRedirectSfc: React.FC<RouteComponentProps<any> & { push?: boolean }> = props => {
	return <Redirect push={props.push} to='/notfound' />;
};

export const NotFoundRedirect = withRouter(NotFoundRedirectSfc);
