import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	childRoute: {
		boxSizing: 'border-box',
		height: '100%',
		overflow: 'auto',
		paddingBottom: 40,
	},
	composerContainer: {
		boxSizing: 'border-box',
		height: '100%',
		padding: 30,
		position: 'relative',
	},
	container: {},
});
