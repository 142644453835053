import { NoteViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../models/UiUtils';
import { Attachments } from '../../Attachments';
import { RichContentDocumentEditor } from '../../richContent/RichContentDocumentEditor';
import { ActionItemFeedCard } from '../ActionItemFeedCard';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	mountExpanded?: boolean;
	note: NoteViewModel;
	onRenderExpansionToggle?(isExpanded: boolean, toggle: (expand: boolean) => void): React.ReactNode;
	styles?: StyleDeclarationValue[];
}

interface IState {
	showingPreview?: boolean;
}

class _FeedCardNoteContent extends React.Component<IProps, IState> {
	public static defaultProps: Partial<IProps> = {
		mountExpanded: false,
	};
	public readonly state: IState = {
		showingPreview: !this.props.mountExpanded,
	};

	public render() {
		const { className, note, styles, onRenderExpansionToggle } = this.props;
		const { showingPreview } = this.state;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} feed-card-note-content ${className || ''}`}>
				{!!showingPreview && !!note.preview ? (
					<>
						{`${note.preview}...`}
						{onRenderExpansionToggle ? (
							onRenderExpansionToggle(!showingPreview, this.onExternalExpansionToggleClicked)
						) : (
							<div>
								<button
									className={`${css(styleSheet.readMoreButton)} feed-card-note-content-more-toggle`}
									onClick={this.toggleShowMore(true)}
								>
									<span>Read More</span>
								</button>
							</div>
						)}
					</>
				) : (
					<>
						<RichContentDocumentEditor
							contentClassName={css(styleSheet.readOnlyContent)}
							// @ts-ignore
							contentState={this.readOnlyEditorState}
							readOnly={true}
						/>
						{!!note.actionItems && note.actionItems.length > 0 && (
							<div className={css(styleSheet.actionItems)}>
								{note.actionItems.map(x => {
									return (
										<ActionItemFeedCard
											actionItem={x}
											className={css(styleSheet.actionItem)}
											hideHeaders={true}
											key={x.id}
											moreMenuDisabled={true}
											readonly={true}
											sendMessageDisabled={true}
										/>
									);
								})}
							</div>
						)}
						{!!note.attachments && note.attachments.length > 0 && (
							<Attachments attachments={note.attachments} className={css(styleSheet.attachments)} />
						)}
						{!!note.preview &&
							(onRenderExpansionToggle ? (
								onRenderExpansionToggle(!showingPreview, this.onExternalExpansionToggleClicked)
							) : (
								<div>
									<button
										className={`${css(styleSheet.readMoreButton)} feed-card-note-content-more-toggle`}
										onClick={this.toggleShowMore(false)}
									>
										<span>Show less</span>
									</button>
								</div>
							))}
					</>
				)}
			</div>
		);
	}

	@computed
	private get readOnlyEditorState() {
		const { note } = this.props;
		return note ? convertRawRichTextContentStateToRichContentEditorState(note.rawContentState, note.preview) : null;
	}

	private onExternalExpansionToggleClicked = (showMore: boolean) => {
		this.toggleShowMore(showMore)();
	};

	private toggleShowMore = (showMore: boolean) => () => {
		this.setState({
			showingPreview: !showMore,
		});
	};
}

export const FeedCardNoteContent = observer(_FeedCardNoteContent);
