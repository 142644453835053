import { StyleSheet } from 'aphrodite';
import { brandPrimaryText } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	actionItem: {
		':not(:last-child)': {
			marginBottom: 15,
		},
	},
	actionItems: {
		marginTop: 30,
	},
	attachments: {
		marginTop: 20,
	},
	container: {
		fontSize: 14,
	},
	readMoreButton: {
		color: brandPrimaryText,
		cursor: 'pointer',
		fontSize: 14,
		marginTop: 10,
	},
	readOnlyContent: {
		overflowX: 'auto',
	},
});
