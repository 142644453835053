import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	closeButton: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 28,
	},
	container: {
		color: titles,
		textAlign: 'center',
	},
	detail: {
		fontSize: 14,
		marginTop: 16,
	},
	header: {
		textAlign: 'center',
	},
	icon: {
		position: 'relative',
	},
	iconSpinner: {
		left: '50%',
		position: 'absolute',
		top: 30,
		transform: 'translateX(-50%)',
	},
	iconSuccess: {
		height: 38,
		width: 38,
	},
	message: {
		fontSize: 18,
		marginTop: 16,
	},
});
