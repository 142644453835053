import { CSSProperties, StyleSheet } from 'aphrodite';
import { brandSecondary, dashboardNavBackground } from '../../styles/colors';

const unreadTextMessageCount: CSSProperties = {
	alignItems: 'center',
	background: brandSecondary,
	borderRadius: '50%',
	color: '#fff',
	display: 'flex',
	justifyContent: 'center',
};

export const styleSheet = StyleSheet.create({
	compactUnreadTextMessageCount: {
		...unreadTextMessageCount,
		fontSize: '10px',
		height: 14,
		width: 14,
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: '40px 0',
	},
	link: {
		':not(:first-child)': {
			marginTop: 23,
		},
	},
	links: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	logoContainer: {
		alignItems: 'center',
		// boxSizing: 'border-box',
		color: '#aaa',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 11,
		letterSpacing: '1.1px',
		marginBottom: 20,
		paddingTop: 20,
		textTransform: 'uppercase',
	},
	nav: {
		background: `radial-gradient(1000px 700px at 100% 0px, #040058 0%, rgba(0, 82, 140, 0.0001) 100%), radial-gradient(383px 607px at 100% 201px, rgba(255, 0, 133, 1) 0%, transparent 30%), ${dashboardNavBackground}`,
		boxShadow: '0px 0px 16px rgba(11, 34, 85, 0.600224)',
		height: '100%',
		minWidth: 90,
		overflowX: 'hidden',
		overflowY: 'auto',
		position: 'relative',
		zIndex: 0,
	},
	unreadTextMessageCount: {
		...unreadTextMessageCount,
		fontSize: '12px',
		height: 18,
		width: 18,
	},
});
