import { navigation, titles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	activeNavLink: {
		':hover': {
			color: navigation,
			textDecoration: 'underline',
		},
		':visited': {
			color: navigation,
			textDecoration: 'none',
		},
		color: navigation,
	},
	capitalize: {
		textTransform: 'capitalize',
	},
	caret: {
		marginLeft: 15,
		marginRight: 15,
	},
	navigationBreadcrumsbBar: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
	},
	navLink: {
		':hover': {
			color: titles,
			textDecoration: 'underline',
		},
		':visited': {
			color: titles,
			textDecoration: 'none',
		},
		color: titles,
		maxWidth: 300,
		textDecoration: 'none',
	},
});
