import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';
import { destructiveHover, error, label } from '../../../../web/styles/colors';

export const styleSheet = StyleSheet.create({
	ageRange: {
		fontSize: 14,
		color: label,
	},
	container: {
		width: '100%',
		...CssSelectors.allChildren({
			borderRadius: 6,
		}),
	},
	twoColumn: {
		display: 'flex',
		gap: 8,
		marginBottom: 12,
	},
	fullWidth: {
		maxWidth: '100%',
		width: '100%',
	},
	subtitle: {
		marginTop: 16,
		marginBottom: 8,
		color: label,
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
		marginTop: 0,
	},
});
