import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const CircularAddIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`circular-add-icon ${className || ''}`} height={40} width={40}>
			<g fill='none' fillRule='evenodd'>
				<circle fill={fillColor || brandSecondary} cx='20' cy='20' r='20' />
				<path
					d='M8.7158302,0 C8.1626925,0 7.7142857,0.4480965 7.7142857,1.0085081 L7.7142857,7.7142857 L1.0085081,7.7142857 C0.4515245,7.7142857 -3.55271368e-15,8.1621944 -3.55271368e-15,8.7158302 L-3.55271368e-15,9.2841698 C-3.55271368e-15,9.8373075 0.4480965,10.2857143 1.0085081,10.2857143 L7.7142857,10.2857143 L7.7142857,16.9914919 C7.7142857,17.5484755 8.1621944,18 8.7158302,18 L9.2841698,18 C9.8373075,18 10.2857143,17.5519035 10.2857143,16.9914919 L10.2857143,10.2857143 L16.9914919,10.2857143 C17.5484755,10.2857143 18,9.8378056 18,9.2841698 L18,8.7158302 C18,8.1626925 17.5519035,7.7142857 16.9914919,7.7142857 L10.2857143,7.7142857 L10.2857143,1.0085081 C10.2857143,0.4515245 9.8378056,0 9.2841698,0 L8.7158302,0 Z'
					fill='#FFF'
					transform='translate(11 11)'
				/>
			</g>
		</SvgIcon>
	);
};
