import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addTagCta: {
		margin: 2,
	},
	container: {
		border: '1px solid #d8d8d8',
		borderRadius: 3,
		boxSizing: 'border-box',
		display: 'flex',
		flexWrap: 'wrap',
		margin: '5px 0',
		minHeight: 40,
		padding: 2,
		width: 300,
		...CssSelectors.allDescendants(
			{
				margin: 2,
			},
			'.entity-property-search-tag'
		),
	},
	disabled: {
		opacity: 0.5,
	},
	dropdownItemsContainer: {
		paddingBottom: 8,
		paddingTop: 8,
	},
	search: {
		height: 30,
		margin: 2,
		...CssSelectors.allDescendants(
			{
				height: 30,
			},
			'.dropdown-anchor'
		),
		...CssSelectors.allDescendants(
			{
				backgroundColor: '#fff',
			},
			'.autocomplete-search-field-input'
		),
	},
	searchCancelButton: {
		marginLeft: 8,
	},
	searchContainer: {
		display: 'flex',
	},
});
