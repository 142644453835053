import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
		padding: '12px 20px 0 12px',
	},
	disabled: {
		borderColor: '#ffcdd2',
		color: '#ffcdd2',
		opacity: 1,
	},
	disabledEdit: {
		opacity: 0.5,
		...CssSelectors.allDescendants({
			':disabled': {
				opacity: 1,
			},
		}),
	},
	dropDownHeader: {
		color: '#858585',
		fontSize: 12,
	},
	fullHeaderContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	popover: {
		padding: '4px 8px',
	},
	saveText: {
		color: '#8e8e8e',
		fontSize: 12,
	},
});
