import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, inputBorderColor, navigation } from '../../styles/colors';
import { CssSelectors, baseStyles } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		color: navigation,
		diplay: 'flex',
		flexShrink: 0,
		fontSize: 12,
	},
	content: {
		':after': {
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%)',
			bottom: 0,
			content: '""',
			display: 'block',
			height: 25,
			position: 'absolute',
			width: '100%',
		},
		position: 'relative',
		width: '100% !important',
	},
	menu: {
		minHeight: 338,
		paddingBottom: 25,
	},
	option: {
		borderBottom: `1px solid ${inputBorderColor}`,
	},
	originalContentOption: {
		':first-child': {
			color: brandPrimaryHover,
		},
	},
	placeholder: {
		color: navigation,
		fontSize: 12,
	},
	select: {
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.select-box-menu'
		),
	},
	selectTrigger: {
		...baseStyles.textField,
		height: 30,
		minWidth: 200,
	},
});
