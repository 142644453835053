import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const SocialMediaPostGraphic: React.FC<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon
			className={`social-media-post-graphic ${className || ''} ${css(...(styles || []))}`}
			height={305}
			width={349}
		>
			<path
				d='M65.7294 214.548L62.1478 242.952L56.8772 210.686C31.5469 200.9 1.9301 199 1.9301 199C1.9301 199 -4.60565 260.626 16.9732 286.394C38.552 312.162 61.3031 308.245 80.0739 292.314C98.8445 276.384 106.527 254.472 84.9477 228.704C80.1447 222.969 73.3648 218.317 65.7294 214.548Z'
				fill='#D7EBF7'
			/>
			<path
				d='M58.2903 247.395L59.3616 248.662C71.7387 263.449 78.9635 277.031 80.9169 289.229C80.9485 289.406 80.9685 289.582 81 289.76L80.1716 289.887L79.3737 290C77.4087 276.707 68.8849 262.747 58.0926 249.846C57.7489 249.427 57.3999 249.002 57.0382 248.587C52.4212 243.166 47.4367 237.946 42.3835 233.053C41.9957 232.67 41.5963 232.287 41.1969 231.904C34.4935 225.479 27.7282 219.643 21.6854 214.712C21.2599 214.362 20.8281 214.016 20.4016 213.678C10.1392 205.373 2.146 199.829 0.363841 198.612C0.149138 198.46 0.0275036 198.38 0 198.36L0.454445 197.688L0.45543 197.677L0.916165 197C0.943695 197.02 1.35293 197.287 2.09122 197.804C4.8645 199.722 12.3146 204.993 21.5523 212.442C21.9672 212.78 22.399 213.125 22.8245 213.476C27.7326 217.472 33.0937 222.043 38.4952 227.03C39.854 228.28 41.1781 229.528 42.4673 230.772C42.8729 231.15 43.2723 231.533 43.6601 231.915C49.1234 237.205 54.0001 242.364 58.2903 247.395Z'
				fill='white'
			/>
			<path
				d='M22.1594 212.848C21.7816 213.262 21.3974 213.68 20.996 214.092C19.9077 215.211 18.7624 216.273 17.5645 217.276C12.4646 221.555 6.4623 224.643 0 226.314C0.0170437 226.872 0.0341255 227.43 0.050173 228C6.89113 226.293 13.2485 223.057 18.6369 218.538C19.9155 217.468 21.1362 216.331 22.294 215.133C22.6889 214.727 23.0731 214.308 23.4456 213.889C26.5377 210.445 29.0848 206.555 31 202.352C30.4316 202.234 29.875 202.116 29.3301 202C27.4911 205.947 25.0748 209.602 22.1594 212.848V212.848Z'
				fill='white'
			/>
			<path
				d='M42.7011 230.536C42.2891 230.924 41.8666 231.3 41.4334 231.662C41.3069 231.779 41.1688 231.895 41.037 232.005C29.954 241.29 15.0002 244.598 1 240.863C1.06114 241.441 1.12228 242.02 1.195 242.599C15.5475 246.216 30.7744 242.736 42.0954 233.253C42.2712 233.106 42.4469 232.958 42.6174 232.805C43.0515 232.43 43.474 232.055 43.8913 231.674C50.3043 225.718 54.8553 218.052 57 209.594C56.4869 209.394 55.9738 209.194 55.4544 209C53.4027 217.259 48.9705 224.744 42.7011 230.536Z'
				fill='white'
			/>
			<path
				d='M58.4004 247.542C57.9955 247.95 57.5802 248.345 57.1596 248.734C56.7078 249.149 56.2508 249.558 55.7832 249.954C40.8023 262.623 19.309 263.848 3 252.963C3.14519 253.708 3.28508 254.447 3.44272 255.182C20.2313 265.568 41.75 263.966 56.8343 251.207C57.3019 250.811 57.7641 250.409 58.2044 249.992C58.6365 249.605 59.0519 249.21 59.4619 248.808C67.6948 240.644 72.5341 229.638 73 218.021C72.4802 217.668 71.9469 217.337 71.4084 217C71.1873 228.494 66.5213 239.45 58.4004 247.542Z'
				fill='white'
			/>
			<path
				d='M200.333 72.0041V26.3965C200.333 11.8182 188.498 0.000100662 173.899 0H77.1347C70.1239 -4.40747e-05 63.4002 2.78097 58.4428 7.73125C53.4853 12.6815 50.7003 19.3956 50.7002 26.3964V56.6858H49V67.3583H50.7002V78.7422H49V95.1067H50.7002V102.222H49V118.586H50.7002V276.604C50.7002 283.604 53.4852 290.318 58.4425 295.269C63.3999 300.219 70.1235 303 77.1343 303H173.898C180.909 303 187.633 300.219 192.59 295.269C197.548 290.318 200.333 283.604 200.333 276.604V104.468H202V72.0041H200.333Z'
				fill='#00528C'
			/>
			<path
				d='M175.218 7.00002H162.56C163.742 9.89514 163.404 13.1881 161.658 15.7832C159.912 18.3783 156.988 19.935 153.858 19.9353H98.3064C95.1766 19.935 92.2522 18.3784 90.5064 15.7832C88.7606 13.1881 88.4225 9.89513 89.6046 7H77.7824C66.8569 7 58 15.848 58 26.7627V277.237C58 282.479 60.0842 287.505 63.7941 291.212C67.504 294.918 72.5358 297 77.7824 297H175.218C186.143 297 195 288.152 195 277.237V277.237V26.7627C195 21.5213 192.916 16.4946 189.206 12.7883C185.496 9.08213 180.464 7.00001 175.218 7.00002Z'
				fill='white'
			/>
			<path
				d='M177.328 94H75.672C74.197 93.9983 73.0017 92.7925 73 91.3046V49.6954C73.0017 48.2075 74.197 47.0017 75.672 47H177.328C178.803 47.0017 179.998 48.2075 180 49.6954V91.3046C179.998 92.7925 178.803 93.9983 177.328 94Z'
				fill='#046CB6'
			/>
			<path
				d='M168.033 114H84.9667C83.3282 114 82 112.657 82 111C82 109.343 83.3282 108 84.9667 108H168.033C169.672 108 171 109.343 171 111C171 112.657 169.672 114 168.033 114Z'
				fill='#046CB6'
			/>
			<path
				d='M167.783 128H85.2169C83.4402 128 82 126.657 82 125C82 123.343 83.4402 122 85.2169 122H167.783C169.56 122 171 123.343 171 125C171 126.657 169.56 128 167.783 128V128Z'
				fill='#E8F3F9'
			/>
			<path
				d='M167.783 143H85.2169C83.4402 143 82 141.657 82 140C82 138.343 83.4402 137 85.2169 137H167.783C169.56 137 171 138.343 171 140C171 141.657 169.56 143 167.783 143V143Z'
				fill='#E8F3F9'
			/>
			<path
				d='M177.328 218H75.672C74.197 217.998 73.0017 216.793 73 215.305V173.695C73.0017 172.207 74.197 171.002 75.672 171H177.328C178.803 171.002 179.998 172.207 180 173.695V215.305C179.998 216.793 178.803 217.998 177.328 218Z'
				fill='#E8F3F9'
			/>
			<path
				d='M167.783 238H85.2169C83.4402 238 82 236.657 82 235C82 233.343 83.4402 232 85.2169 232H167.783C169.56 232 171 233.343 171 235C171 236.657 169.56 238 167.783 238Z'
				fill='#E8F3F9'
			/>
			<path
				d='M167.783 252H85.2169C83.4402 252 82 250.657 82 249C82 247.343 83.4402 246 85.2169 246H167.783C169.56 246 171 247.343 171 249C171 250.657 169.56 252 167.783 252Z'
				fill='#E8F3F9'
			/>
			<path
				d='M167.783 267H85.2169C83.4402 267 82 265.657 82 264C82 262.343 83.4402 261 85.2169 261H167.783C169.56 261 171 262.343 171 264C171 265.657 169.56 267 167.783 267Z'
				fill='#E8F3F9'
			/>
			<path d='M329 287.341L323.536 291L307 271.4L315.063 266L329 287.341V287.341Z' fill='#9E616A' />
			<path
				d='M334 291.182L316.502 303L316.355 302.774C313.868 298.933 314.901 293.759 318.662 291.218L318.662 291.218L329.349 284L334 291.182Z'
				fill='#00AAE8'
			/>
			<path d='M257.999 297H251.223L248 272L258 272.001L257.999 297V297Z' fill='#9E616A' />
			<path
				d='M260 304L239 303.999V303.716C239 298.902 242.66 295 247.174 295H247.174L260 295.001L260 304Z'
				fill='#00AAE8'
			/>
			<path
				d='M211.49 122.435L186.631 104.463C186.937 101.839 185.127 99.4437 182.545 99.0541C179.963 98.6644 177.542 100.421 177.078 103.021C176.613 105.621 178.274 108.124 180.827 108.674C183.935 111.315 206.63 134.159 209.88 132.954C213.178 131.731 227.599 129.517 227.599 129.517L228 115.66L211.49 122.435Z'
				fill='#9E616A'
			/>
			<path
				d='M231 154.45L231.234 185.738C231.234 185.738 236.594 232.098 238.571 239.929C240.548 247.761 248.572 284 248.572 284L261.617 281.885L253.216 234.505L255.755 195.236L270.397 234.592L307.577 273.654L317 266.012L284.638 218.212L274.754 179.054C274.754 179.054 275.983 158.8 264.908 151L231 154.45Z'
				fill='#046CB6'
			/>
			<path d='M236.622 171.816L241.952 159.918L257.42 166.848L252.089 178.746L236.622 171.816Z' fill='#00528C' />
			<path
				d='M260.738 99.8461C259.06 96.4826 258.245 92.3275 254.667 91.237C252.252 90.5006 242.481 91.6041 240.28 92.8471C236.617 94.9147 239.936 98.789 237.776 102.415C235.427 106.356 233.078 110.297 230.73 114.239C229.115 116.948 227.483 119.696 226.61 122.732C225.737 125.767 225.695 129.174 227.183 131.956C228.514 134.447 230.925 136.213 232.264 138.7C233.656 141.286 231.157 152.832 229.861 155.468L230.483 155.757C239.097 156.267 247.74 155.974 256.3 154.882C258.425 154.612 260.631 154.258 262.382 153.015C264.961 151.186 265.981 147.865 266.618 144.754C269.685 129.543 267.615 113.736 260.738 99.8461V99.8461Z'
				fill='#E4E4E4'
			/>
			<path
				d='M231.744 131.897C235.569 128.053 239.413 124.186 242.562 119.746C245.71 115.306 248.159 110.225 248.843 104.785C249.135 103.049 249.019 101.268 248.504 99.587C247.917 97.9395 246.618 96.4773 244.943 96.1014C242.264 95.4997 239.827 97.687 237.915 99.6989C232.277 105.631 226.638 111.563 221 117.495C224.592 122.33 228.183 127.165 231.775 132L231.744 131.897Z'
				fill='#E4E4E4'
			/>
			<path
				d='M273.955 135.176C272.754 131.868 270.58 117.403 270.58 117.403L256.97 117L263.624 133.561L245.468 159.212C245.468 159.212 245.501 159.269 245.562 159.369C243.609 159.053 241.663 159.978 240.661 161.701C239.659 163.423 239.807 165.589 241.033 167.156C242.258 168.724 244.312 169.372 246.204 168.788C248.097 168.205 249.441 166.51 249.59 164.519C249.728 164.599 249.902 164.568 250.006 164.447C252.026 161.888 275.156 138.485 273.955 135.176Z'
				fill='#9E616A'
			/>
			<path
				d='M275.896 126.08C274.635 120.816 273.364 115.523 271.237 110.538C269.11 105.554 266.065 100.846 261.782 97.478C260.455 96.3393 258.872 95.5297 257.165 95.1163C255.449 94.7816 253.515 95.1562 252.309 96.4097C250.38 98.4155 250.961 101.635 251.668 104.313C253.754 112.209 255.841 120.104 257.927 128C263.951 127.369 269.976 126.737 276 126.106L275.896 126.08Z'
				fill='#E4E4E4'
			/>
			<path
				d='M188.596 95.8198L179.408 109.907C178.962 110.594 178.207 111.005 177.4 111C176.434 111 175.562 110.407 175.19 109.496C175.126 109.345 175.077 109.188 175.046 109.027C174.922 108.388 175.051 107.725 175.404 107.182L184.593 93.0948C185.069 92.3642 185.891 91.9488 186.749 92.0051C187.606 92.0613 188.369 92.5807 188.751 93.3676C189.132 94.1545 189.073 95.0892 188.596 95.8198H188.596Z'
				fill='#046CB6'
			/>
			<path
				d='M180.778 108.076L178.804 111.276L175.311 114.622C174.838 115.075 174.144 115.126 173.62 114.746C173.095 114.367 172.871 113.651 173.074 113.002L174.603 108.14L176.528 105L180.778 108.076Z'
				fill='#00528C'
			/>
			<path
				d='M188.921 88.3371L184.525 92.5166C184.234 92.7939 184.078 93.2017 184.104 93.6202C184.13 94.0387 184.335 94.4203 184.659 94.6528L185.491 95.2506L183.086 99.1443C182.985 99.308 182.972 99.5174 183.053 99.6936C183.134 99.8699 183.296 99.9863 183.478 99.9989C183.659 100.011 183.834 99.9184 183.935 99.7548L186.34 95.8611L187.172 96.4589C187.746 96.8712 188.521 96.7051 188.906 96.0873C188.967 95.9885 189.015 95.8816 189.051 95.7692L190.934 89.7837C191.116 89.2045 190.914 88.5656 190.442 88.2265C189.971 87.8874 189.346 87.9328 188.921 88.3372L188.921 88.3371Z'
				fill='#00528C'
			/>
			<path
				d='M348.362 304H0.637782C0.285246 303.999 0 303.776 0 303.5C0 303.224 0.285246 303.001 0.637782 303H348.362C348.715 303.001 349 303.224 349 303.5C349 303.776 348.715 303.999 348.362 304Z'
				fill='#CAE0EC'
			/>
			<path
				d='M129.903 12H115.097C114.491 12 114 11.5523 114 11C114 10.4477 114.491 10 115.097 10H129.903C130.509 10 131 10.4477 131 11C131 11.5523 130.509 12 129.903 12Z'
				fill='white'
			/>
			<circle cx='137' cy='11' r='1' fill='white' />
			<path
				d='M260.817 84.4714C259.773 76.9292 258.465 68.8316 253.066 63.387C249.605 59.8962 244.846 57.9504 239.894 58.001C234.941 58.0515 230.224 60.0938 226.837 63.6546C222.908 67.7855 221.172 73.586 221.013 79.246C220.855 84.9059 222.134 90.503 223.553 95.9897C231.063 96.1375 238.517 94.6917 245.41 91.7503C247.099 90.9378 248.852 90.2599 250.651 89.723C252.472 89.2662 253.77 90.4155 255.464 91.2169L256.393 89.4625C257.155 90.8584 259.512 90.4446 260.367 89.1024C261.221 87.7603 261.034 86.0415 260.817 84.4714Z'
				fill='#2F2E41'
			/>
			<circle cx='238.5' cy='77.5' r='14.5' fill='#9E616A' />
			<path
				d='M256.676 78.6263C256.224 75.1326 255.751 71.6096 254.688 68.3199C253.625 65.0301 251.911 61.9489 249.452 60.0853C245.56 57.1348 240.52 57.7038 236.156 59.401C232.781 60.7136 229.486 62.7085 227.106 65.9158C224.726 69.123 223.389 73.6994 224.275 77.893C228.93 76.6004 233.585 75.3079 238.24 74.0153L237.731 74.4385C240.752 76.1368 243.046 79.6507 243.732 83.6294C244.421 87.5941 243.54 91.6709 241.274 95C244.449 93.6764 247.624 92.3528 250.799 91.0293C252.758 90.2123 254.856 89.271 256.018 87.1754C257.365 84.7459 257.052 81.5257 256.676 78.6263Z'
				fill='#2F2E41'
			/>
		</SvgIcon>
	);
};
