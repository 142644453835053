import { titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { tableBorderColor } from '../ContactsSelfImportMapping/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	checkboxPlaceholder: {
		height: 17,
		marginBottom: 20,
	},
	checkboxText: {
		marginBottom: 20,
	},
	tableHeaderSelectBox: {
		alignItems: 'center',
		alignSelf: 'flex-start',
		boxSizing: 'border-box',
		color: titles,
		display: 'inline-flex',
		marginBottom: 20,
		...CssSelectors.allDescendants(
			{
				minWidth: 122,
			},
			'.dropdown-anchor'
		),
		...CssSelectors.allDescendants(
			{
				maxHeight: 300,
				overflowY: 'auto',
			},
			'.select-box-menu'
		),

		':nth-child(1n) > .dropdown-content ': {
			boxSizing: 'border-box',
			width: 208,
		},
	},
	tableHeaderSelectBoxTrigger: {
		backgroundImage: 'linear-gradient(-180deg, #FDFDFD 0%, #F9F9F9 100%)',
		border: '1px solid #979797',
		borderRadius: 12,
		fontSize: 12,
		height: 30,
		padding: '0 10px',
		width: '100%',
	},
	tableHeaderSelectFilter: {
		padding: '16px 20px',
	},
	tableHeadTrTh: {
		fontWeight: 'normal',
		minWidth: 200,
		padding: 0,
		textAlign: 'left',
	},
	tableHeadTrThName: {
		borderBottomWidth: 0,
		borderLeft: 'solid transparent',
		borderLeftWidth: 0,
		borderRight: `solid ${tableBorderColor}`,
		borderRightWidth: 1,
		borderTop: `solid ${tableBorderColor}`,
		borderTopWidth: 1,
		boxSizing: 'border-box',
		fontWeight: 700,
		marginRight: -1,
		padding: 10,
	},
	tableHeadTrThNameFirst: {
		borderBottomWidth: 0,
		borderLeft: `solid ${tableBorderColor}`,
		borderLeftWidth: 1,
		borderRight: `solid ${tableBorderColor}`,
		borderRightWidth: 1,
		borderTop: `solid ${tableBorderColor}`,
		borderTopWidth: 1,
	},
});
