import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { AccountUserSelectBox } from '../../../../admin/components/user/AccountUserSelectBox';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { FabContext } from '../../../components/FabContext';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { ISelectOption } from '../../../components/Select';
import { UserSelectBox } from '../../../components/UserSelectBox';
import { REDUCER_ACTION_KEYS, useBlogPostEditorContext } from './hooks';
import { styleSheet } from './styles';

export interface IBlogPostEditorLayoutProps extends IImpersonationContextComponentProps {
	content?: React.ReactNode;
	header?: React.ReactNode;
	sidebar?: React.ReactNode;
	sidebarStyles?: StyleDeclarationValue[];
	status?: Api.BlogStatus;
	styles?: StyleDeclarationValue[];
	subHeader?: React.ReactNode;
}

const BlogPostCreatorDropdown = inject(ImpersonationContextKey)(
	observer(function BlogPostCreatorDropdown({ impersonationContext }: IImpersonationContextComponentProps) {
		const userSession = useUserSession();
		const { state, dispatch } = useBlogPostEditorContext();

		const [initialSelectedUserOption] = React.useState<ISelectOption<Api.UserViewModel<Api.IUser>>>(() => {
			const user =
				state.sendFromUser ||
				(impersonationContext?.isValid && impersonationContext?.user ? impersonationContext.user : null);
			if (user) {
				const activeUser = new Api.UserViewModel(userSession, user).impersonate(impersonationContext);
				return {
					dataContext: activeUser,
					id: activeUser.id,
					text: activeUser.name,
				};
			}
		});

		const onUserOptionSelected = async (option: ISelectOption<Api.UserViewModel>) => {
			const user = option.dataContext;
			if (impersonationContext) {
				impersonationContext.update({
					account: impersonationContext.account,
					user,
				});
				user.impersonate(impersonationContext);
			}
			dispatch({ type: REDUCER_ACTION_KEYS.SET_SEND_ON_BEHALF_OF_USER, payload: user.toJs() });
		};

		return (
			<div className={css(styleSheet.formControl, styleSheet.sendFromDropdownContainer)}>
				<label>
					Post creator <sup>*</sup>
				</label>
				{impersonationContext?.isValid ? (
					<AccountUserSelectBox
						defaultOption={null}
						onOptionSelected={onUserOptionSelected}
						onRenderPlaceholder={() => <span>Select a User</span>}
						initialSelectedOption={initialSelectedUserOption}
					/>
				) : (
					<UserSelectBox
						defaultOption={null}
						includeSearch={userSession.account.isAdmin}
						onOptionSelected={onUserOptionSelected}
						onRenderPlaceholder={() => <span>Select a User</span>}
						initialSelectedOption={initialSelectedUserOption}
					/>
				)}
			</div>
		);
	})
);

export function BlogPostEditorLayout({
	styles,
	header,
	children,
	sidebarStyles = [],
	subHeader,
	content,
	sidebar,
	status,
}: React.PropsWithChildren<IBlogPostEditorLayoutProps>) {
	return (
		<div className={css(styles)}>
			<MultiContainerHeader fullscreenHeader={header} />
			<div className={css(styleSheet.mainContainer)}>
				<div className={css(styleSheet.container)}>
					<section className={css(styleSheet.subHeader)}>
						<div className={css(styleSheet.subHeaderContent)}>{subHeader}</div>
						{status !== Api.BlogStatus.Pending ? <BlogPostCreatorDropdown /> : null}
					</section>
					<div className={css(styleSheet.contentWrap)}>{content}</div>
				</div>
				<section className={css(styleSheet.sidebar, ...sidebarStyles)}>{sidebar}</section>
			</div>
			{children}
			<FabContext appearance={{ hidden: true }} />
		</div>
	);
}
