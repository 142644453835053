import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'inline-block',
		textAlign: 'center',
	},
	content: {
		color: '#B6DFF5',
		display: 'inline-block',
		margin: '0 auto',
		textAlign: 'center',
	},
	icon: {
		height: 222,
		width: 600,
	},
	message: {
		color: '#4A4A4A',
		fontSize: '24px',
		marginTop: 70,
	},
});
