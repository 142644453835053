import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	button: {
		width: 80,
	},
	container: {},
	dropDown: {
		alignItems: 'center',
		borderLeftColor: brandPrimary,
		borderLeftStyle: 'solid',
		borderLeftWidth: 1,
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		padding: '0 6px',
	},
	saveAsButton: {
		width: 120,
	},
	saveButton: {
		height: 28,
	},
	saveTemplateModal: {
		':nth-child(1n) > .modal-overlay': {
			':nth-child(1n) > .modal-overlay-content': {
				width: 540,
			},
		},
	},
});
