import { css } from 'aphrodite';
import moment from 'moment';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

export const ScheduleBy = ({ name }: { name: string }) => {
	return (
		<div className={css(styleSheet.card)}>
			<div className={css(styleSheet.cardTitle)}>Scheduled By</div>
			<div className={css(styleSheet.cardActions)}>
				<span className={css(styleSheet.approvalStatusTitle)}>{name}</span>
			</div>
		</div>
	);
};

export const ScheduleDate = ({
	scheduleDate,
	title,
	showChangeButton,
	onClick,
	disabled,
}: {
	scheduleDate: string;
	title: string;
	showChangeButton: boolean;
	disabled: boolean;
	onClick: () => void;
}) => {
	return (
		<div className={css(styleSheet.card)}>
			<div className={css(styleSheet.cardTitle)}>{title}</div>
			<div className={css(styleSheet.cardActions)}>
				<time dateTime={`${moment(new Date(scheduleDate)).format('dddd, MMMM Do, h:mm A')}`}>
					{moment(new Date(scheduleDate)).format('dddd, MMMM Do, h:mm A')}
				</time>
				{showChangeButton ? (
					<button className={css(bs.brandLink)} disabled={disabled} onClick={onClick}>
						<span>Change</span>
					</button>
				) : null}
			</div>
		</div>
	);
};

export const ApprovalStatus = ({ title, textColor }: { title: string; textColor?: string }) => {
	return (
		<div className={css(styleSheet.card)}>
			<div className={css(styleSheet.approvalStatusTitle)} style={{ color: textColor ?? undefined }}>
				{title}
			</div>
		</div>
	);
};
