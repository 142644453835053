import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	fill?: string;
	className?: string;
	stroke?: string;
	width?: number;
	height?: number;
}

export const ContactsInfoIcon: React.FC<IProps> = props => {
	const { fill = '#E8F3F9', className, height = 74, stroke = '#00AAE8', width = 56 } = props;

	return (
		<SvgIcon
			className={`contacts-info-icon ${className || ''}`}
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect x='3' y='1.33334' width='47.3333' height='63.3333' stroke={stroke} strokeWidth='2' />
			<rect x='4.6665' y='3' width='44' height='60' fill={fill} />
			<circle cx='26.9998' cy='24' r='12.3333' fill='white' stroke={stroke} strokeWidth='2' />
			<path
				d='M31.697 29.2222V28.0371C31.697 26.7279 30.6116 25.6667 29.2727 25.6667H24.4242C23.0854 25.6667 22 26.7279 22 28.0371V29.2222'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<ellipse
				cx='27.0907'
				cy='20.7037'
				rx='2.42424'
				ry='2.37037'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<rect x='18.6665' y='49' width='17.3333' height='4' rx='1' fill='white' stroke={stroke} strokeWidth='2' />
			<rect x='18.6665' y='42.3334' width='17.3333' height='4' rx='1' fill='white' stroke={stroke} strokeWidth='2' />
			<rect
				x='0.999837'
				y='14'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='30'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='18'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='34'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='22'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='38'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='46'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='26'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='42'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='0.999837'
				y='50'
				width='3.33333'
				height='0.666667'
				rx='0.333333'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='54.3333'
				y='14.6667'
				width='0.666667'
				height='48'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='18.9998'
				y='68.6667'
				width='29.3333'
				height='0.666667'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
			<rect
				x='23.6663'
				y='72.6667'
				width='11.3333'
				height='0.666667'
				fill='#D8D8D8'
				stroke={stroke}
				strokeWidth='0.666667'
			/>
		</SvgIcon>
	);
};
