import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	disabled?: boolean;
	onNextClicked?(e: React.MouseEvent<HTMLElement>): void;
	onPreviousClicked?(e: React.MouseEvent<HTMLElement>): void;
	styles?: StyleDeclarationValue[];
}

export const NextPreviousInputAccessory: React.FC<IProps> = ({
	className,
	disabled,
	onNextClicked,
	onPreviousClicked,
	styles = [],
}) => {
	return (
		<span
			className={`${css(
				baseStyleSheet.horizontalStack,
				styleSheet.container,
				...styles
			)} next-previous-input-accessory ${className || ''}`}
		>
			<button className={css(styleSheet.button)} disabled={disabled} onClick={onPreviousClicked}>
				<svg xmlns='http://www.w3.org/2000/svg' width='4' height='8' viewBox='0 0 4 8'>
					<polygon
						fill='#767676'
						fillRule='evenodd'
						points='139 21 135 17 143 17'
						transform='matrix(0 -1 -1 0 21 143)'
					/>
				</svg>
			</button>
			<button className={css(styleSheet.button)} disabled={disabled} onClick={onNextClicked}>
				<svg xmlns='http://www.w3.org/2000/svg' width='4' height='8' viewBox='0 0 4 8'>
					<polygon fill='#767676' fillRule='evenodd' points='154 21 150 17 158 17' transform='rotate(-90 70.5 87.5)' />
				</svg>
			</button>
		</span>
	);
};
