import { EditAutomationTemplateStepsContext } from '../../../../models/Automations';
import { EditAutomationStep } from '../../../containers/EditAutomationTemplate/presentation';
import { EditAutomationTemplateItemSeparator } from '../EditAutomationTemplateItemSeparator';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Flipped, Flipper, HandleEnterUpdateDelete } from 'react-flip-toolkit';

export const EditAutomationTemplateSteps = observer(
	React.forwardRef<Flipper, { children?: React.ReactChild }>(function EditAutomationTemplateStepsBase(props, ref) {
		const { children } = props;
		const context = React.useContext(EditAutomationTemplateStepsContext);

		const onHandleEnterUpdateDelete = React.useCallback<HandleEnterUpdateDelete>(
			({ hideEnteringElements, animateEnteringElements, animateExitingElements, animateFlippedElements }) => {
				// simulatneous animations
				hideEnteringElements();
				animateExitingElements();
				animateFlippedElements();
				animateEnteringElements();
			},
			[]
		);
		const onAnimateIn = React.useCallback((el: HTMLElement, index: number) => {
			requestAnimationFrame(() => {
				el.style.transition = `opacity 0ms`;
				el.style.opacity = `0`;
				requestAnimationFrame(() => {
					el.style.transition = `opacity ${250 + index * 100}ms ease`;
					el.style.opacity = `1`;
				});
			});
		}, []);
		const onAnimateOut = React.useCallback((el: HTMLElement, index: number, removeElement: () => void) => {
			requestAnimationFrame(() => {
				const time = 250 + index * 100;
				el.style.transition = `opacity ${time}ms ease`;
				el.style.opacity = `0`;
				setTimeout(removeElement, time);
			});
		}, []);
		const steps = context?.editorSteps?.steps;

		return (
			<>
				<Flipper
					flipKey={steps?.map(x => x.uuid).join('') || ''}
					handleEnterUpdateDelete={onHandleEnterUpdateDelete}
					ref={ref}
				>
					{steps?.map((x, i) => {
						const isSwitchStep = x.automationStep?.type === Api.AutomationStepType.Switch;
						const isSwitchStepChild =
							!isSwitchStep &&
							!!context.editorSteps.automationTemplate.rootTemplate &&
							context.editorSteps.automationTemplate.rootTemplate !== context.editorSteps.automationTemplate;
						return (
							<Flipped flipId={x.uuid} key={x.uuid} onAppear={onAnimateIn} onExit={onAnimateOut}>
								<div>
									{isSwitchStepChild ? (
										<div className={css(styleSheet.stepSeparator)} />
									) : (
										<EditAutomationTemplateItemSeparator className={css(styleSheet.stepSeparator)} />
									)}
									<EditAutomationStep
										automationTemplate={context.editorSteps.automationTemplate}
										index={i}
										key={x.uuid}
										step={x}
										steps={steps}
									/>
								</div>
							</Flipped>
						);
					})}
				</Flipper>
				{children}
			</>
		);
	})
);
