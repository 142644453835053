import * as React from 'react';
import { storageTypeAvailable } from '../Storage';
type Behavior<T> = {
	storageType: 'localStorage' | 'sessionStorage';
	defaultIfEmpty?: T;
	storageKey: string;
	skipPersistFn?: () => boolean;
};

export const usePersistChangeInBrowserStorage = <T>({
	storageType,
	storageKey,
	skipPersistFn,
	defaultIfEmpty,
}: Behavior<T>): [T | null, React.Dispatch<React.SetStateAction<T>>] => {
	const getStoredValue = (): T | null => {
		if (!storageTypeAvailable(storageType) || skipPersistFn?.()) {
			return defaultIfEmpty ?? null;
		}
		const potentialValue = window[storageType].getItem(storageKey);
		if (potentialValue) {
			try {
				return JSON.parse(potentialValue);
			} catch {
				return defaultIfEmpty ?? null;
			}
		}
		return defaultIfEmpty ?? null;
	};

	const persistValueInLocalStorage = React.useCallback(
		(valueToStore: T) => {
			const stringifiedValue = JSON.stringify(valueToStore);
			window[storageType].setItem(storageKey, stringifiedValue);
		},
		[storageKey, storageType]
	);

	const storedValue = getStoredValue();
	const [value, setValue] = React.useState<T>(storedValue);

	React.useEffect(() => {
		if (!storageTypeAvailable(storageType) || (skipPersistFn && skipPersistFn())) {
			return;
		}
		persistValueInLocalStorage(value);
	}, [value, persistValueInLocalStorage, skipPersistFn, storageType]);

	return [value, setValue];
};
