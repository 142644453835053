import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryHover, header, nameCircles, titles } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	imageContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: 22,
		boxShadow: '0 0 6px 0 rgba(139,139, 139, 0.1)',
		border: `1px solid ${nameCircles}`,
	},
	imageWrap: {
		minWidth: 305,
		height: 203,
	},
	imageSection: {
		marginLeft: '20px',
		...CssSelectors.allChildren(
			{
				fontSize: 14,
				marginBottom: 12,
				color: titles,
				marginTop: 20,
			},
			'h3'
		),
		...CssSelectors.allChildren(
			{
				color: header,
				fontSize: 14,
				margin: 0,
			},
			'p'
		),
	},
	imageButton: {
		cursor: 'pointer',
		color: brandPrimary,
		background: 'none',
		border: 'none',
		padding: 0,
		marginTop: 12,
		':hover': {
			color: brandPrimaryHover,
			textDecoration: 'underline',
		},
	},
});
