import { isIE11 } from '../../../../../models/Browser';
import { Layouts } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const fadeInKeyframes = {
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
};

export const styleSheet = StyleSheet.create({
	container: {
		...Layouts.verticalStack(20),
		position: 'relative',
		textAlign: 'left',
	},
	listItem: {
		':not(first-child)': {
			marginTop: 20,
		},
		...(!isIE11()
			? {
					animationDelay: 'calc(var(--stagger-anim-order) * 100ms)',
					animationDuration: '300ms',
					animationFillMode: 'forwards',
					animationIterationCount: 1,
					animationName: [fadeInKeyframes],
					animationTimingFunction: 'ease-in-out',
					opacity: 0,
				}
			: {}),
	},
	sectionLabel: {
		marginBottom: 10,
	},
});
