import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	styles?: StyleDeclarationValue[];
}

export const ReplyIcon: React.FC<IProps> = props => {
	const { className, fillColor, styles } = props;
	return (
		<SvgIcon className={`reply-icon ${css(...(styles || []))} ${className || ''}`} height={7} width={11}>
			<path
				fill={fillColor || grayIconFill}
				fillRule='evenodd'
				d='M11,6.33247909 C11,4.2496628 9.27107062,2.5558403 7.14508498,2.5558403 L3.68915367,2.5558403 L4.93525495,1.33504181 L3.57254249,0 L0,3.5 L3.57254249,7 L4.93525495,5.66495819 L3.68915367,4.4441597 L7.14508498,4.4441597 C8.2080778,4.4441597 9.07254249,5.29107095 9.07254249,6.33247909 L11,6.33247909 Z'
			/>
		</SvgIcon>
	);
};
