import { brandPrimary, navigation } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const statusHeight = 22;
const actionsHeight = 26;
const iconHeight = 100;
const iconHeightWithStatus = 58;

export const styleSheet = StyleSheet.create({
	actions: {
		height: actionsHeight,
	},
	actionsPlaceholder: {
		color: navigation,
		fontSize: 14,
	},
	container: {
		alignItems: 'center',
		background: '#fff',
		border: '1px solid #D3DBDE',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		padding: 30,
		textAlign: 'center',
		...CssSelectors.allChildren({
			marginLeft: 'auto',
			marginRight: 'auto',
		}),
	},
	enabledStatus: {
		alignItems: 'center',
		color: '#4A4A4A',
		display: 'flex',
		fontSize: 14,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	icon: {
		alignItems: 'center',
		display: 'flex',
		height: iconHeight,
		justifyContent: 'center',
		marginBottom: 22,
		maxWidth: '100%',
	},
	iconWithStatus: {
		height: iconHeightWithStatus,
	},
	manageButton: {
		':hover': {
			textDecoration: 'none',
		},
		border: `1px solid ${brandPrimary}`,
		borderRadius: 3,
		boxShadow: '0 1px 2px 0 #f9f9f9',
		boxSizing: 'border-box',
		fontSize: 12,
		height: '100%',
		justifyContent: 'center',
		minWidth: 90,
		padding: '0 20px',
		textAlign: 'center',
	},
	status: {
		alignItems: 'center',
		display: 'flex',
		height: statusHeight,
		justifyContent: 'center',
		marginBottom: 10,
	},
});
