import { brandPrimary, header, navigation, tagBackgroundColor, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	advancedTagFiltersContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: 4,
		...CssSelectors.allChildren({
			fontSize: '12px',
		}),
	},
	contactContainer: {
		...CssSelectors.allChildren(
			{
				alignItems: 'flex-end',
				display: 'flex',
				flexGrow: 1,
				flexWrap: 'nowrap',
				maxWidth: 342,
				...CssSelectors.allChildren(
					{
						display: 'inline-block',
						maxWidth: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},
					'span'
				),
				...CssSelectors.allChildren(
					{
						flexShrink: 0,
					},
					'span:first-child'
				),
				...CssSelectors.allChildren(
					{
						flexShrink: 1,
					},
					'span:last-child'
				),
			},
			'.contact-content'
		),
	},
	container: {},
	contentContainer: {
		':hover': {
			cursor: 'pointer',
		},
		...CssSelectors.allChildren(
			{
				fontSize: '14px',
			},
			':first-child:not(.tag):not(.saved-search)'
		),
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				display: 'flex',
				marginLeft: 8,
				textTransform: 'capitalize',
			},
			':last-child'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 0,
			},
			'.saved-search'
		),
	},
	flex: {
		display: 'flex',
	},
	globalSearch: {
		paddingBottom: 20,
	},
	header: {
		marginBottom: 8,
	},
	itemContainer: {
		':last-child': {
			marginBottom: 0,
		},
		marginBottom: 8,
	},
	pill: {
		borderRadius: 30,
		fontSize: '12px',
		padding: '3px 10px',
		...CssSelectors.allChildren(
			{
				height: 13,
				marginRight: 8,
				width: 13,
			},
			'svg'
		),
	},
	pillSelected: {
		background: brandPrimary,
		color: '#fff',
	},
	pillUnselected: {
		background: tagBackgroundColor,
	},
	removeContactButton: {
		background: 'none',
		border: 'none',
		marginRight: 8,
		outline: 'none',
		paddingTop: 2,
		...CssSelectors.allChildren(
			{
				height: 10,
				width: 10,
			},
			'svg'
		),
	},
	sectionContainer: {
		paddingBottom: 20,
	},
	sendATestButton: {
		':hover': {
			color: titles,
		},
		alignItems: 'center',
		color: header,
		display: 'flex',
		fontSize: '14px',
		minHeight: 21,
		minWidth: 106,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				marginRight: 8,
			},
			'svg'
		),
	},
	sendATestWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: 30,
	},
	smallGray: {
		color: navigation,
		fontSize: 12,
	},
	tags: {
		height: '100%',
		overflow: 'auto',
		position: 'relative',
	},
	tagsContainer: {
		overflow: 'hidden',
		transition: 'height .25s ease-in-out',
	},
	tagsFooter: {
		alignItems: 'center',
		display: 'flex',
		height: 50,
		marginTop: 20,
		...CssSelectors.allChildren({
			width: '100%',
		}),
	},
	tagsHeader: {
		color: titles,
		fontSize: 18,
		height: 30,
		marginBottom: 26,
	},
	tagsInner: {
		height: '100%',
		overflow: 'auto',
	},
});
