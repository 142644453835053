import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const GhostCalendarLogoGraphic: React.FC<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon
			className={`ghost-calendar-logo-graphic ${className || ''} ${css(...(styles || []))}`}
			height={159}
			width={159}
		>
			<circle cx='79.5' cy='79.5' r='79.5' fill='#046CB6' />
			<ellipse cx='71.5247' cy='49.968' rx='29.6048' ry='29.968' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M42.2227 49.5575L101.129 65.1573C101.129 65.1573 112.622 63.8724 112.569 71.1085C112.535 75.6809 101.28 87.3455 101.129 89.7885C99.5021 116.19 87.2061 129.045 78.4189 126.735C69.6317 124.425 65.4415 115.241 65.4415 115.241C65.4415 115.241 61.7498 127.772 53.288 126.122C44.8263 124.471 45.9753 112.778 45.9753 112.778C45.9753 112.778 35.4312 125.093 27.3203 120.988C19.2094 116.883 34.7373 103.35 37.6663 88.1695C40.5954 72.9887 42.2227 49.5575 42.2227 49.5575Z'
				fill='white'
			/>
			<ellipse cx='62.197' cy='48.3259' rx='3.24436' ry='5.33677' fill='#00528C' />
			<ellipse cx='80.8523' cy='48.3259' rx='3.24436' ry='5.33677' fill='#00528C' />
			<ellipse cx='72.7415' cy='65.1573' rx='4.05545' ry='4.10521' fill='#00528C' />
			<rect x='112.566' y='63.1194' width='33.4342' height='31.9403' fill='#F89143' />
		</SvgIcon>
	);
};
