import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		height: '100%',
		width: '100%',
	},
	modalContainer: {
		padding: '0 20px',
	},
	editor: {
		boxSizing: 'border-box',
		height: '100%',
		paddingTop: 26,
	},
	header: {},
});
