import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, brandSecondary, titles } from '../../styles/colors';
import { BreakPoints, CssSelectors, Layouts } from '../../styles/styles';

const cardGridItemSpacing = 20;

export const styleSheet = StyleSheet.create({
	automationCard: {
		width: 300,
	},
	body: {
		...Layouts.verticalStack(30),
		boxSizing: 'border-box',
		paddingBottom: 30,
	},
	cardGrid: {
		boxSizing: 'border-box',
	},
	cardGridContent: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: -cardGridItemSpacing,
		marginTop: -cardGridItemSpacing,
		...CssSelectors.allChildren({
			marginLeft: cardGridItemSpacing,
			marginTop: cardGridItemSpacing,
		}),
	},
	cardGridTitle: {
		color: titles,
		marginBottom: 20,
	},
	container: {
		background: '#F9F9F9',
		boxSizing: 'border-box',
		overflowY: 'auto',
	},
	createNewButton: {
		...Layouts.horizontalStack(16),
		background: '#fff',
		border: `1px dashed ${brandSecondary}`,
		borderRadius: 52,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.36)',
		boxSizing: 'border-box',
		color: brandPrimaryText,
		fontSize: 18,
		height: 80,
		justifyContent: 'center',
		marginRight: -180,
		transform: 'translate3d(-180px, 0, 0)',
		width: 230,
	},
	description: {
		color: '#4A4A4A',
		fontSize: 14,
		lineHeight: '22px',
		width: '50%',
	},
	descriptionTitle: {
		color: titles,
		fontSize: 18,
		marginBottom: 14,
	},
	header: {
		...Layouts.horizontalStack(30),
		alignItems: 'center',
		background: '#FFF',
		display: 'flex',
		justifyContent: 'space-between',
		margin: '40px 0',
		padding: '20px',
	},
	headerWorkflowGraphic: {
		flexShrink: 0,
		[BreakPoints.tabletWidth]: {
			height: 'auto',
			width: 200,
		},
		[BreakPoints.tabletCompactWidth]: {
			display: 'none',
		},
	},
	levTemplatesHeaderIndustrySelector: {
		width: 200,
	},
	levTemplatesHeaderIndustrySelectorOption: {
		fontSize: 14,
	},
	sampleAutomationsSectionTitle: {
		...Layouts.horizontalStack(30),
		alignItems: 'center',
		display: 'flex',
	},
});
