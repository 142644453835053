import * as Api from '@ViewModels';
import * as React from 'react';
import { BlogPostRequest } from '../../../models/Blogs';

export interface IBlogPostContext {
	blogPost?: Api.IBlogPost;
	request?: BlogPostRequest;
	setBlogPost(blogPost: Api.IBlogPost): void;
	setRequest: (request: BlogPostRequest) => void;
}

export const BlogPostContext = React.createContext<IBlogPostContext>(null);

export const useBlogPostContextContext = () => {
	const context = React.useContext(BlogPostContext);
	return context;
};

export const BlogPostContextProvider: React.FC = ({ children }) => {
	const context = useCreatePostContext();

	return <BlogPostContext.Provider value={context}>{children}</BlogPostContext.Provider>;
};

export const useCreatePostContext = () => {
	const [context, setContext] = React.useState<IBlogPostContext>({
		blogPost: null,
		setBlogPost: (blogPost: Api.IBlogPost) => {
			setContext(prev => ({ ...prev, blogPost }));
		},
		request: null,
		setRequest: (request: BlogPostRequest) => {
			setContext(prev => ({ ...prev, request }));
		},
	});

	return context;
};
