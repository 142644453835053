import { StyleSheet } from 'aphrodite';
import {
	background,
	brandPrimaryHover,
	brandSecondary,
	header,
	label,
	nameCircles,
	surveysTint,
	titles,
	white,
} from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const ContentWidth = 800;
export const ImageContainerHeight = 350;
export const GifContentWidth = 460;
export const GifsContainerHeight = 350;

export const styleSheet = StyleSheet.create({
	clearSearch: {
		color: brandPrimaryHover,
	},
	container: {
		minWidth: 500,
		width: ContentWidth,
	},
	footer: {
		margin: '20px 0',
		padding: '0 20px',
	},
	gifs: {
		display: 'flex',
		height: GifsContainerHeight,
		overflowY: 'auto',
		position: 'relative',
	},
	header: {
		borderBottom: `1px solid ${nameCircles}`,
		color: titles,
		fontSize: 18,
		justifyContent: 'space-between',
		marginBottom: 20,
		padding: '0 20px',
	},
	headerPoweredBy: {
		fontSize: 11,
	},
	headerPoweredByGiphy: {
		alignItems: 'flex-end',
		display: 'flex',
		height: 24,
		marginRight: 20,
		marginTop: 6,
		width: 'auto',
	},
	headerPoweredByGiphyImage: {
		height: 24,
		width: 'auto',
	},
	headerPoweredByGiphyLogo: {
		display: 'flex',
	},
	headerPoweredByImage: {
		height: 16,
		marginRight: 20,
		width: 'auto',
	},
	headerTabs: {
		alignItems: 'flex-end',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 50,
			},
			':first-child'
		),
	},
	headerTitle: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	hide: {
		display: 'none',
	},
	imageGrid: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		flexWrap: 'wrap',
		height: ImageContainerHeight,
		overflowY: 'auto',
		padding: '0 20px',
		position: 'relative',
		...CssSelectors.allChildren(
			{
				':first-child': {
					marginRight: 6,
				},
				height: 28,
			},
			'button'
		),
	},
	imageGridTile: {
		boxSizing: 'border-box',
		height: '100px !important',
		padding: '0 5px 5px 0',
		...CssSelectors.allChildren(
			{
				height: '100%',
				width: 'auto',
			},
			'img'
		),
	},
	imageGridTileSelected: {
		...CssSelectors.allChildren(
			{
				height: 'auto',
				maxHeight: '50vh',
				maxWidth: '100%',
				width: 'auto',
			},
			'img'
		),
		height: '100%',
		padding: 20,
	},
	keywordLabel: {
		alignItems: 'center',
		background: surveysTint,
		borderRadius: 11,
		color: label,
		display: 'flex',
		fontSize: 14,
		height: 23,
		padding: '2px 12px',
	},
	keywordsSection: {
		height: 23,
	},
	mediaMenu: {
		':hover': {
			background,
		},
		background: '#ddd5d552',
		border: `1px solid transparent`,
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		color: header,
		fontSize: 18,
		fontVariant: 'all-small-caps',
		letterSpacing: 1.2,
		marginLeft: 2,
		padding: '8px 40px',
	},
	modal: {
		...CssSelectors.allDescendants(
			{
				padding: 0,
			},
			'.modal-overlay-content'
		),
		...CssSelectors.allDescendants(
			{
				paddingRight: 20,
				paddingTop: 10,
			},
			'.modal-overlay-content-header'
		),
	},
	noSearchResults: {
		margin: '20px 20px 0px 20px',
	},
	noSuggestions: {
		alignItems: 'center',
		background,
		display: 'flex',
		flexDirection: 'column',
		height: 200,
		justifyContent: 'center',
		padding: 10,
	},
	noSuggestionsText: {
		color: label,
		fontSize: 12,
		padding: 10,
		textAlign: 'center',
		width: 200,
	},
	noSuggestionsTitle: {
		color: brandSecondary,
		fontSize: 16,
	},
	search: {
		color: label,
		fontSize: 14,
		marginBottom: 14,
		marginTop: 24,
		padding: '0 20px',
		position: 'relative',
	},
	searchIcon: {
		height: 50,
		width: 50,
	},
	searchInput: {
		width: '85%',
	},
	selectedMedia: {
		':hover': {
			background: white,
		},
		background: white,
		border: `1px solid ${nameCircles}`,
		borderBottom: '1px solid transparent',
		color: brandPrimaryHover,
		marginBottom: -1,
	},
	suggestedMedia: {
		alignItems: 'center',
		background,
		border: `1px solid ${nameCircles}`,
		borderRadius: '0.8125rem',
		boxShadow: '0px 1px 2px rgba(167,171,173, 0.22)',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		overflow: 'hidden',
	},
	suggestedMediaButtonColumn: {
		alignItems: 'center',
		width: 28,
	},
	suggestedMediaHeader: {
		color: label,
		fontSize: 14,
		fontWeight: 400,
	},
	suggestedMediaImg: {
		height: 135,
		minHeight: 135,
		objectFit: 'cover',
		width: '100%',
	},
	suggestionContainer: {
		borderTop: '1px solid #D3DBDE',
		height: 310,
		padding: '0 20px',
	},
	suggestionHeader: {
		color: brandPrimaryHover,
		fontSize: 16,
		fontWeight: 600,
	},
	suggestionsContainerHidden: {
		display: 'none',
	},
	suggestionSubHeader: {
		color: label,
		fontSize: 14,
		fontWeight: 400,
		marginTop: 0,
	},
});
