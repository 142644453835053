import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	childInner: {
		height: 'auto',
	},
	childOuter: {
		overflow: 'hidden',
	},
	trigger: {
		alignItems: 'center',
		display: 'flex',
		padding: '6px 0',
	},
});
