import * as React from 'react';
import { alternateTitleColor } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const CloudUploadIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;

	return (
		<SvgIcon className={`cloud-icon ${className || ''}`} width={96} height={69} viewBox='0 0 96 69'>
			<mask id='path-1-outside-1_0_309' maskUnits='userSpaceOnUse' x='0' y='0' width='96' height='60' fill='black'>
				<rect fill='white' width='96' height='60' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M71.5714 26.76C71.5714 27.2559 71.5564 27.7482 71.5266 28.2366C73.4864 27.2901 75.6818 26.76 78 26.76C86.2843 26.76 93 33.5295 93 41.88C93 50.2305 86.2843 57 78 57H18C9.71573 57 3 50.2305 3 41.88C3 35.5421 6.86861 30.115 12.3554 27.8671C12.3094 27.5046 12.2857 27.1351 12.2857 26.76C12.2857 21.9883 16.1233 18.12 20.8571 18.12C22.586 18.12 24.1953 18.6359 25.5418 19.5234C28.5785 9.93985 37.4852 3 48 3C61.0181 3 71.5714 13.6377 71.5714 26.76Z'
				/>
			</mask>
			<path
				d='M71.5266 28.2366L68.5322 28.0545L68.2214 33.1644L72.8313 30.938L71.5266 28.2366ZM12.3554 27.8671L13.4928 30.6431L15.6211 29.7712L15.3316 27.4894L12.3554 27.8671ZM25.5418 19.5234L23.8908 22.0283L27.2033 24.2115L28.4017 20.4296L25.5418 19.5234ZM74.5211 28.4187C74.5545 27.8698 74.5714 27.3167 74.5714 26.76H68.5714C68.5714 27.1951 68.5582 27.6267 68.5322 28.0545L74.5211 28.4187ZM78 23.76C75.2197 23.76 72.5792 24.3967 70.222 25.5351L72.8313 30.938C74.3936 30.1835 76.1439 29.76 78 29.76V23.76ZM96 41.88C96 31.8952 87.9636 23.76 78 23.76V29.76C84.6049 29.76 90 35.1637 90 41.88H96ZM78 60C87.9636 60 96 51.8648 96 41.88H90C90 48.5963 84.6049 54 78 54V60ZM78 54H18V60H78V54ZM0 41.88C0 51.8648 8.03638 60 18 60V54C11.3951 54 6 48.5963 6 41.88H0ZM11.2181 25.091C4.63307 27.7888 0 34.294 0 41.88H6C6 36.7902 9.10416 32.4411 13.4928 30.6431L11.2181 25.091ZM9.28571 26.76C9.28571 27.2617 9.31744 27.7573 9.37929 28.2447L15.3316 27.4894C15.3014 27.2519 15.2857 27.0085 15.2857 26.76H9.28571ZM20.8571 15.12C14.4439 15.12 9.28571 20.354 9.28571 26.76H15.2857C15.2857 23.6225 17.8026 21.12 20.8571 21.12V15.12ZM27.1928 17.0185C25.3725 15.8188 23.1921 15.12 20.8571 15.12V21.12C21.9799 21.12 23.0182 21.4531 23.8908 22.0283L27.1928 17.0185ZM48 0C36.1268 0 26.0981 7.83599 22.6819 18.6172L28.4017 20.4296C31.0589 12.0437 38.8437 6 48 6V0ZM74.5714 26.76C74.5714 12.0034 62.6975 0 48 0V6C59.3388 6 68.5714 15.272 68.5714 26.76H74.5714Z'
				fill={fillColor || alternateTitleColor}
				mask='url(#path-1-outside-1_0_309)'
			/>
			<rect x='35' y='56' width='26' height='5' fill='#E0F2F8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M55 47.0958L48 40L41 47.0958L43.1028 49.2273L46.5129 45.7707V68.3054H49.4871V45.7707L52.8972 49.2273L55 47.0958Z'
				fill={fillColor || '#00AAE8'}
			/>
			<path d='M63 58.5H60' stroke={fillColor || '#00AAE8'} strokeWidth='3' strokeLinecap='round' />
			<path d='M36 58.5H33' stroke={fillColor || '#00AAE8'} strokeWidth='3' strokeLinecap='round' />
		</SvgIcon>
	);
};
