import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { AccountUserSelectBox } from '../../../../admin/components/user/AccountUserSelectBox';
import { IImpersonationContext, IUser, UserViewModel } from '../../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useErrorMessages, useToaster } from '../../../../models/hooks/appStateHooks';
import { useBlogFileImportMutation } from '../../../../queries/Blog/useBlogFileImportMutation';
import { useBlogUrlImportMutation } from '../../../../queries/Blog/useBlogUrlImportMutation';
import { baseStyleSheet } from '../../../styles/styles';
import { DragDropFileInputTarget } from '../../DragDropFileInputTarget';
import { asModalComponent } from '../../Modal';
import { ISelectOption } from '../../Select';
import { FileIcon } from '../../svgs/icons/FileIcon';
import { TrashIcon } from '../../svgs/icons/TrashIcon';
import { TextInput } from '../../TextInput';
import { styleSheet } from './styles';

interface HandleBlogImportProps {
	onFinish?: () => void;
}

export const BlogRssUpload = inject(ImpersonationContextKey)(
	(props: HandleBlogImportProps & IImpersonationContextComponentProps) => {
		const toaster = useToaster();
		const errorMessages = useErrorMessages();
		const [blogUrl, setBlogUrl] = React.useState('');
		const [impersonationCtx, setImpersonationContext] = React.useState<IImpersonationContext>(
			props.impersonationContext
		);
		const [file, setFile] = React.useState<File>(null);
		const defaultUserSelectBoxOption: ISelectOption<string | UserViewModel> = {
			dataContext: null,
			id: 'default-user-option-all',
			text: 'Must Choose User',
		};

		const onError = (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
		};

		const onSuccess = () => {
			toaster.push({
				message: 'Blog import was successful',
				type: 'successMessage',
			});
			props.onFinish?.();
		};

		const importBlogsMutation = useBlogUrlImportMutation({
			onSuccess,
			onError,
		});
		const importFileBlogsMutation = useBlogFileImportMutation({
			onSuccess,
			onError,
		});

		const onFileChanged = async (nextFile: File) => {
			if (!nextFile) {
				return;
			}
			setFile(nextFile);
		};

		const handleImportClick = async () => {
			if (!impersonationCtx?.user) {
				toaster.push({
					message: 'Must Select a user',
					type: 'errorMessage',
				});
				return;
			}

			if (file) {
				importFileBlogsMutation.mutate({ file, impersonationContext: impersonationCtx });
				return;
			}

			if (!blogUrl || !blogUrl.match(/^(http|https):\/\/[^ "]+$/)) {
				toaster.push({
					message: 'Invalid URL',
					type: 'errorMessage',
				});
				return;
			}
			importBlogsMutation.mutate({ url: blogUrl, impersonationContext: impersonationCtx });
		};

		const onUserOptionSelected = async (
			option: ISelectOption<string | UserViewModel<IUser>>,
			user?: UserViewModel<IUser>
		) => {
			if (user) {
				setImpersonationContext({
					account: props.impersonationContext?.account,
					user: option.dataContext ? user : null,
				});
			}
		};
		return (
			<div className={css(styleSheet.container)}>
				{props.impersonationContext ? (
					<div className={css(baseStyleSheet.horizontalStack)}>
						<label>Choose User:</label>
						<AccountUserSelectBox
							defaultOption={defaultUserSelectBoxOption}
							onOptionSelected={onUserOptionSelected}
							styles={[styleSheet.userSelectBox]}
						/>
					</div>
				) : null}
				<div>
					<label className={css(styleSheet.label)} htmlFor='blogUrlInput'>
						Import Blogs from URL
					</label>
					<TextInput
						id='blogUrlInput'
						value={blogUrl}
						onChange={e => setBlogUrl(e.target.value)}
						placeholder='Enter blog URL'
						type='text'
					/>
				</div>
				<div>
					<label className={css(styleSheet.label)}>Or import from a file</label>
					<DragDropFileInputTarget
						dragDropMessage='Drag and drop rss feed to upload'
						accept='.rss'
						droppableId='file-upload'
						onFileChanged={onFileChanged}
						file={file}
					>
						{file ? (
							<div className={css(baseStyleSheet.horizontalStack)}>
								<FileIcon type='other' />
								<p className={css(baseStyleSheet.truncateText, baseStyleSheet.m0)}>{file.name}</p>
								<button className={css(baseStyleSheet.flexCenter)} onClick={() => setFile(null)}>
									<TrashIcon />
								</button>
							</div>
						) : null}
					</DragDropFileInputTarget>
				</div>
				<button
					className={css(styleSheet.importButton, baseStyleSheet.ctaButton)}
					onClick={handleImportClick}
					disabled={!blogUrl && !file}
				>
					<span>Import Blogs</span>
				</button>
			</div>
		);
	}
);

export const BlogRssUploadModal = asModalComponent(BlogRssUpload, {
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
	className: 'blog-rss-upload-modal',
});
