import { ContentCalendarSuggestionAccountAge } from '../../../extViewmodels';
import { CampaignType } from '../../../models/AdminModels';
import { ISelectOption } from '../../components/Select';

export enum CalendarType {
	Quarterly = 'Quarter',
	Month = 'Month',
	Weekly = 'Week',
}

export const CalendarViewGroupOptions: ISelectOption<CalendarType>[] = Object.values(CalendarType).map(
	calendarType => ({
		dataContext: calendarType,
		id: `calendar-view-group-option-${calendarType}`,
		text: calendarType,
	})
);

export const GhostCalendarCampaignTypeOptions: ISelectOption<CampaignType>[] = [
	{
		dataContext: null,
		id: 'campaign-type-option-all',
		text: 'All',
	},
	{
		dataContext: CampaignType.Email,
		id: 'campaign-type-option-email',
		text: 'Email',
	},
	{
		dataContext: CampaignType.Social,
		id: 'campaign-type-option-social',
		text: 'Social Media',
	},
];
export const GhostCalendarAccountAgeOptions: ISelectOption<ContentCalendarSuggestionAccountAge>[] = [
	{
		dataContext: null,
		id: 'account-age-option-all',
		text: 'Suggestions for all users',
	},
	{
		dataContext: ContentCalendarSuggestionAccountAge.New,
		id: 'account-age-option-new',
		text: 'Suggestions for new users',
	},
];
