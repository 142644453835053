import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useHistory, useRouteMatch } from 'react-router';
import { useEmailComposerContext } from '../../../../models/Email';
import { useEventLogging } from '../../../../models/Logging';
import { useToggle } from '../../../../models/hooks/useToggle';
import {
	ComposeEventRegistrationSurveyFollowUpEmailViewModel,
	ComposeFollowUpEmailViewModel,
	ComposeSatisfactionSurveyFollowUpEmailViewModel,
	ComposeSurveyFollowUpEmailViewModel,
} from '../../../../viewmodels/AppViewModels';
import { Checkbox } from '../../../components/Checkbox';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { Popover, PopoverType } from '../../../components/Popover';
import { ISelectOption, Select } from '../../../components/Select';
import { AIContentGenerationWizard } from '../../../components/ai/AIContentGenerationWizard';
import {
	AIContentGenerationWizardAssistantEmailMessages,
	AIContentGenerationWizardType,
} from '../../../components/ai/AIContentGenerationWizard/models';
import { ContactContextTabView } from '../../../components/email/ContactContextTabView';
import { EventRegistrationSurveyFollowUpOptions } from '../../../components/email/followups/EventRegistrationSurveyFollowUpOptions';
import {
	FollowUpRecipientDiscriminator,
	IFollowUpRecipientDiscriminatorOption,
} from '../../../components/email/followups/FollowUpRecipientDiscriminator';
import { SatisfactionSurveyFollowUpOptions } from '../../../components/email/followups/SatisfactionSurveyFollowUpOptions';
import { SurveyFollowUpRecipients } from '../../../components/email/followups/SurveyFollowUpRecipients';
import { AutomationBannerIcon } from '../../../components/svgs/icons/AutomationsBannerIcon';
import { AutomationsIcon } from '../../../components/svgs/icons/AutomationsIcon';
import { BoatIcon } from '../../../components/svgs/icons/BoatIcon';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { FollowUpTagsList } from '../../../components/tags/FollowUpTagsList';
import { baseStyleSheet } from '../../../styles/styles';
import { useEmailContentGenerationContext } from './context';
import { styleSheet } from './styles';
import { EDuplicateType } from './utils';

interface IStartAutomationBannerProps
	extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	account?: Api.IAccount;
	resourceSelectorId?: Api.ResourceSelectorId;
	styles?: StyleDeclarationValue[];
}

export const StartAutomationBanner: React.FC<IStartAutomationBannerProps> = props => {
	const { className, resourceSelectorId, styles = [], account, ...restProps } = props;
	let title = '';
	let description = '';
	switch (resourceSelectorId) {
		case Api.ResourceSelectorId.HappyBirthday: {
			title = 'Explore additional birthday options with Automation';
			description = account?.features?.texting?.enabled
				? 'Rather text happy birthday instead? Set up a Birthday Automation.'
				: 'Automatically approve birthday emails/texts.';
			break;
		}
		case Api.ResourceSelectorId.PolicyRenew: {
			title = 'Have a multi-step renewal process?';
			description = "You can set up an automation if you'd prefer a more complex renewal process.";
			break;
		}
		case Api.ResourceSelectorId.Turning65: {
			title = 'Send multiple emails ahead of the 65th birthday?';
			description = "You can set up an automation if you'd prefer a more complex process.";
			break;
		}
		case Api.ResourceSelectorId.Turning72: {
			title = 'Send multiple emails ahead of the 72nd birthday?';
			description = "You can set up an automation if you'd prefer a more complex process.";
			break;
		}
		case Api.ResourceSelectorId.FinancialReview: {
			title = 'Have a multi-step client review process?';
			description = "You can set up an automation if you'd prefer a more complex client review process.";
			break;
		}
		default: {
			break;
		}
	}
	if (!title || !description) {
		return null;
	}
	return (
		<div
			{...restProps}
			className={`start-automation-banner ${css(styleSheet.startAutomationBanner, ...styles)}  ${className || ''}`}
		>
			<div className={css(styleSheet.startAutomationBannerContent)}>
				<AutomationBannerIcon />
				<div>
					<div>{title}</div>
					<div>{description}</div>
				</div>
			</div>
		</div>
	);
};

export interface IAutomationTemplatesForResourceSelectorDropdownProps {
	onTemplateSelected?(option: Api.AutomationTemplateViewModel): void;
	selectedTemplate?: Api.AutomationTemplateViewModel;
	templates?: Api.AutomationTemplateViewModel[];
}

export const AutomationTemplatesForResourceSelectorDropdown: React.FC<
	IAutomationTemplatesForResourceSelectorDropdownProps
> = props => {
	const { onTemplateSelected, selectedTemplate, templates } = props;
	const optionStyles = React.useRef([styleSheet.automationTemplateSelectorOption]).current;
	const selectorStyles = React.useRef([styleSheet.automationTemplateSelector]).current;
	const toOptions = React.useCallback((t: Api.AutomationTemplateViewModel[]) => {
		return t?.map<ISelectOption<Api.AutomationTemplateViewModel>>(x => ({
			dataContext: x,
			// @ts-ignore
			id: x.id,
			styles: optionStyles,
			text: x.name,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [options, setOptions] = React.useState<ISelectOption<Api.AutomationTemplateViewModel>[]>(
		// @ts-ignore
		toOptions(templates.filter(x => x.id !== selectedTemplate?.id))
	);
	const [selected, setSelected] = React.useState<ISelectOption<Api.AutomationTemplateViewModel>>(options[0]);

	React.useEffect(() => {
		unstable_batchedUpdates(() => {
			// @ts-ignore
			setOptions(toOptions(templates.filter(x => x.id !== selected?.id)));
			// @ts-ignore
			setSelected(toOptions([selectedTemplate])[0]);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templates, selectedTemplate]);

	const onClick = React.useCallback(
		(option: ISelectOption<Api.AutomationTemplateViewModel>, wasSelected: boolean) => {
			if (wasSelected) {
				onTemplateSelected?.(option.dataContext);
			}
		},
		[onTemplateSelected]
	);

	const onRenderTrigger = React.useCallback((selectedOption: ISelectOption<Api.AutomationTemplateViewModel>) => {
		return (
			<div className={css(baseStyleSheet.horizontalStack)}>
				{selectedOption.icon || (
					<div className={css(styleSheet.automationTemplateSelectorAutomationIcon)}>
						<AutomationsIcon fillColor='#fff' />
					</div>
				)}
				<div>{selectedOption.text}</div>
			</div>
		);
	}, []);

	if (templates?.length === 1) {
		return (
			<div className={css(baseStyleSheet.truncateText, ...selectorStyles, ...optionStyles)} title={templates[0].name}>
				{templates[0].name}
			</div>
		);
	}
	return (
		<Select
			onOptionClick={onClick}
			options={options}
			selectedOption={selected}
			selectedOptionTitle={onRenderTrigger}
			styles={selectorStyles}
			triggerStyles={optionStyles}
		/>
	);
};

export interface IEditCampaignContentCreationTipsProps {
	canShow?: boolean;
}

export const EditCampaignContentCreationTips: React.FC<IEditCampaignContentCreationTipsProps> = ({ canShow }) => {
	const [showContentCreationTips, , toggle] = useToggle(false);

	React.useEffect(() => {
		if (showContentCreationTips && !canShow) {
			toggle(false)();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canShow]);
	const anchor = (
		<button className={css(styleSheet.contentCreationTipsPopoverAnchor)} onClick={toggle(true)}>
			Not sure what to write?
		</button>
	);
	return (
		<Popover
			anchor={anchor}
			dismissOnClickOutside={true}
			isOpen={!!showContentCreationTips}
			onRequestClose={canShow && toggle(false)}
			place='right'
			type={PopoverType.emailGuide}
		>
			<div className={css(styleSheet.contentCreationTipsPopoverContent)}>
				<div className={css(styleSheet.contentCreationTipsPopoverContentTitle)}>
					<BoatIcon />
					<span>Provide a business / personal update (Bonus: reference a joint interest)</span>
				</div>
				<div>
					{
						"Example: \"How are you? It's been a while since we've chatted and I just wanted to say hey. I have been crazy busy lately, but I guess that's a good thing because that means the business is growing! BTW, have you played a round of golf lately?\""
					}
				</div>
			</div>
		</Popover>
	);
};

export interface IEditCampaignRecipientsProps {
	initialEdit?: boolean;
	onContactEditButtonClicked?(contact: Api.ContactViewModel): void;
	styles?: StyleDeclarationValue[];
}

export const EditCampaignRecipients: React.FC<IEditCampaignRecipientsProps> = observer(
	({ children, initialEdit, onContactEditButtonClicked, styles = [] }) => {
		const { emailComposer } = useEmailComposerContext();
		return (
			<div className={css(styleSheet.recipients, ...styles)}>
				{/* @ts-ignore */}
				{emailComposer.selectedCustomEmailContact ? (
					<ContactContextTabView
						// @ts-ignore
						contact={emailComposer.selectedCustomEmailContact}
						initialEdit={initialEdit}
						onContactEditButtonClicked={onContactEditButtonClicked}
						styles={[styleSheet.contactContext]}
					/>
				) : (
					children
				)}
			</div>
		);
	}
);

export interface IEditCampaignEmailFollowUpRecipientsProps {
	contextFinishedEditingCtaText: string;
	isPriorFollowUp?: boolean;
	onContextFinishedEditingClicked?(e: React.MouseEvent<HTMLElement>): void;
	onFinishEditingContent?(): void;
}

export const EditCampaignEmailFollowUpRecipients: React.FC<IEditCampaignEmailFollowUpRecipientsProps> = observer(
	({ contextFinishedEditingCtaText, isPriorFollowUp, onContextFinishedEditingClicked, onFinishEditingContent }) => {
		const { emailComposer } = useEmailComposerContext();
		const h = useHistory();
		return (
			<EditCampaignRecipients>
				<div className={css(styleSheet.tagsHeader)} />
				<FollowUpTagsList
					emailComposer={emailComposer as ComposeFollowUpEmailViewModel}
					isPriorFollowUp={isPriorFollowUp}
					key='recipients'
					onPreviewClicked={onFinishEditingContent}
					styles={[styleSheet.tagsContent]}
				/>
				{/* @ts-ignore */}
				{emailComposer.recipients.length > 0 && !h.location.pathname.includes('follow-up') && (
					<div className={css(styleSheet.recipientsFooter)}>
						<button
							className={css(baseStyleSheet.ctaButton)}
							onClick={onContextFinishedEditingClicked}
							disabled={isPriorFollowUp}
						>
							<span>{contextFinishedEditingCtaText || 'Next: Send Options'}</span>
						</button>
					</div>
				)}
			</EditCampaignRecipients>
		);
	}
);

export interface IEditCampaignSurveyFollowUpRecipientOptionsProps {
	onFinishEditingContent?(): void;
}

export const EditCampaignSurveyFollowUpRecipientOptions: React.FC<IEditCampaignSurveyFollowUpRecipientOptionsProps> =
	observer(() => {
		const { emailComposer } = useEmailComposerContext<
			Api.ISurveyFollowUpOptions,
			Api.SurveyReportViewModel,
			ComposeSurveyFollowUpEmailViewModel
		>();
		const h = useHistory();
		const routeMatch = useRouteMatch();
		const onShowRecipientPreview = React.useCallback(() => {
			h.push(`${routeMatch.url.replace('/from-report', '')}`);
		}, [h, routeMatch.url]);

		let options: React.ReactNode = null;
		let nextButtonDisabled = false;
		// @ts-ignore
		if (emailComposer.followUpSource instanceof Api.SatisfactionSurveyReportViewModel) {
			options = <SatisfactionSurveyFollowUpOptions styles={[styleSheet.recipientsList]} />;
			nextButtonDisabled =
				// @ts-ignore
				// @ts-ignore
				(emailComposer as ComposeSatisfactionSurveyFollowUpEmailViewModel).selectedFollowUpSubTotals?.length === 0;
			// @ts-ignore
		} else if (emailComposer.followUpSource instanceof Api.EventSurveyReportViewModel) {
			options = <EventRegistrationSurveyFollowUpOptions styles={[styleSheet.recipientsList]} />;
			// @ts-ignore
			// @ts-ignore
			nextButtonDisabled = !(emailComposer as ComposeEventRegistrationSurveyFollowUpEmailViewModel).emailMessage.options
				.followUp?.surveyResponseFilter?.status?.length;
		}
		return (
			<EditCampaignRecipients styles={[styleSheet.followUpRecipients]}>
				{options}
				<div className={css(styleSheet.recipientsFooter)}>
					<button
						className={css(baseStyleSheet.ctaButton)}
						disabled={nextButtonDisabled}
						onClick={onShowRecipientPreview}
					>
						<span>Next: Preview Recipients</span>
					</button>
				</div>
			</EditCampaignRecipients>
		);
	});

export interface IEditCampaignSurveyFollowUpRecipientsProps {
	onContextFinishedEditingClicked?(e: React.MouseEvent<HTMLElement>): void;
}

export const EditCampaignSatisfactionSurveyFollowUpRecipients: React.FC<IEditCampaignSurveyFollowUpRecipientsProps> =
	observer(({ onContextFinishedEditingClicked }) => {
		const { emailComposer } = useEmailComposerContext<
			Api.ISurveyFollowUpOptions,
			Api.SatisfactionSurveyReportViewModel,
			ComposeSatisfactionSurveyFollowUpEmailViewModel
		>();
		const { logApiError } = useEventLogging('EditCampaignSurveyFollowUpRecipients');

		const [options, setOptions] = React.useState<IFollowUpRecipientDiscriminatorOption<{ rating: number }>[]>(
			// @ts-ignore
			emailComposer.selectedFollowUpSubTotals
				.sort((a, b) => (a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0))
				.map<IFollowUpRecipientDiscriminatorOption<Api.ISatisfactionSurveyStatsSubtotal>>(x => {
					return {
						count: x.count,
						disabled: x.count === 0,
						id: `rating-${x.rating}`,
						representedObject: x,
						text: `${x.rating} star ratings`,
					};
				})
		);
		const onRenderDiscriminator = React.useCallback(() => {
			return (
				<FollowUpRecipientDiscriminator
					hideSelectors={true}
					multiSelect={true}
					options={options}
					readonly={true}
					selectedOptions={options}
				/>
			);
		}, [options]);

		const getStats = React.useCallback(async () => {
			try {
				const stats = await emailComposer?.followUpSource.getStatsForDateRange(emailComposer.selectedFollowUpDateRange);
				// use the initial selection to search over the returned stats in range
				setOptions(
					// @ts-ignore
					emailComposer.selectedFollowUpSubTotals
						.sort((a, b) => (a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0))
						.map<IFollowUpRecipientDiscriminatorOption<Api.ISatisfactionSurveyStatsSubtotal>>(x => {
							// @ts-ignore
							// @ts-ignore
							const subtotalsForKnownContacts = stats.value.subtotalsForKnownContacts || [];
							const count = subtotalsForKnownContacts.find(y => y.rating === x.rating)?.count || 0;
							return {
								count,
								disabled: count === 0,
								id: `rating-${x.rating}`,
								representedObject: x,
								text: `${x.rating} star ratings`,
							};
						})
				);
			} catch (error) {
				// @ts-ignore
				logApiError('SatisfactionSurveyReportStatsLoad-Error', error);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [emailComposer?.followUpSource]);

		React.useEffect(() => {
			getStats();
			// @ts-ignore
		}, [emailComposer.selectedFollowUpDateRange, getStats]);

		return (
			<EditCampaignRecipients styles={[styleSheet.followUpRecipients]}>
				<SurveyFollowUpRecipients onRenderDiscriminator={onRenderDiscriminator} styles={[styleSheet.recipientsList]} />
				<div className={css(styleSheet.recipientsFooter)}>
					<button
						className={css(baseStyleSheet.ctaButton)}
						disabled={emailComposer?.recipients?.length === 0}
						onClick={onContextFinishedEditingClicked}
					>
						<span>Next: Send Options</span>
					</button>
				</div>
			</EditCampaignRecipients>
		);
	});

export function EditCampaignEventRegistrationSurveyFollowUpRecipients({
	onContextFinishedEditingClicked,
}: IEditCampaignSurveyFollowUpRecipientsProps) {
	const { emailComposer } = useEmailComposerContext<
		Api.ISurveyFollowUpOptions,
		Api.EventSurveyReportViewModel,
		ComposeEventRegistrationSurveyFollowUpEmailViewModel
	>();
	const onRenderDiscriminator = React.useCallback(() => {
		// @ts-ignore
		const options = emailComposer.emailMessage.options?.followUp?.surveyResponseFilter?.status?.map(status => {
			// @ts-ignore
			const survey = emailComposer.followUpSource.survey;
			// @ts-ignore
			// @ts-ignore
			const stats = survey.stats.responseStats.find(
				x => x.category === Api.EventRegistrationSurveyResponseStatCategory.KnownContacts
			);
			switch (status) {
				case Api.EventRegistrationResponseStatus.Attending: {
					return {
						// @ts-ignore
						count: stats.attending || 0,
						// @ts-ignore
						disabled: !stats.attending,
						// @ts-ignore
						id: `attending-${stats.attending || 0}`,
						representedObject: Api.EventRegistrationResponseStatus.Attending,
						text: 'Attending',
					};
				}
				case Api.EventRegistrationResponseStatus.NotAttending: {
					return {
						// @ts-ignore
						count: stats.notAttending || 0,
						// @ts-ignore
						disabled: !stats.notAttending,
						// @ts-ignore
						id: `notAttending-${stats.notAttending || 0}`,
						representedObject: Api.EventRegistrationResponseStatus.NotAttending,
						text: 'Not attending',
					};
				}
				default: {
					// Api.EventRegistrationResponseStatus.NoResponse
					return {
						// @ts-ignore
						count: stats.noResponse || 0,
						// @ts-ignore
						disabled: !stats.noResponse,
						// @ts-ignore
						id: `noResponse-${stats.noResponse || 0}`,
						representedObject: Api.EventRegistrationResponseStatus.NoResponse,
						text: 'No response',
					};
				}
			}
		});
		return (
			<FollowUpRecipientDiscriminator
				hideSelectors={true}
				multiSelect={true}
				// @ts-ignore
				options={options}
				readonly={true}
				// @ts-ignore
				selectedOptions={options}
			/>
		);
		// @ts-ignore
		// @ts-ignore
	}, [emailComposer.emailMessage.options?.followUp?.surveyResponseFilter?.status, emailComposer.followUpSource.survey]);

	return (
		<EditCampaignRecipients styles={[styleSheet.followUpRecipients]}>
			<SurveyFollowUpRecipients
				hideDateRangePicker={true}
				onRenderDiscriminator={onRenderDiscriminator}
				styles={[styleSheet.recipientsList]}
			/>
			<div className={css(styleSheet.recipientsFooter)}>
				<button
					className={css(baseStyleSheet.ctaButton)}
					disabled={emailComposer?.recipients?.length === 0}
					onClick={onContextFinishedEditingClicked}
				>
					<span>Next: Send Options</span>
				</button>
			</div>
		</EditCampaignRecipients>
	);
}

export function EditCampaignSurveyFollowUpRecipients(props: IEditCampaignSurveyFollowUpRecipientsProps) {
	const { emailComposer } = useEmailComposerContext<
		Api.ISurveyFollowUpOptions,
		Api.SurveyReportViewModel,
		ComposeSurveyFollowUpEmailViewModel
	>();
	if (emailComposer instanceof ComposeEventRegistrationSurveyFollowUpEmailViewModel) {
		return <EditCampaignEventRegistrationSurveyFollowUpRecipients {...props} />;
	}
	return <EditCampaignSatisfactionSurveyFollowUpRecipients {...props} />;
}

export interface IEmailContentGenerationWizardProps {
	content?: Api.IRawRichTextContentState;
	isNewEmail?: boolean;
	onContentGenerated?(email: Api.IEmailMessageCompose, undo?: boolean): void;
	subject?: string;
	templateId?: string;
}

/** Note: Content for this component could live in EditCampaign, post -> to functional component */
export const EmailContentGenerationWizard = ({
	content,
	isNewEmail,
	onContentGenerated,
	subject,
	templateId,
}: IEmailContentGenerationWizardProps) => {
	const h = useHistory();
	const { generateEmailContentAsync } = useEmailContentGenerationContext();
	const { logApiError } = useEventLogging('EmailContentGenerationWizard');
	const isRewritingRef = React.useRef(!isNewEmail);

	// @ts-ignore
	const [undoContent, setUndoContent] = React.useState<Api.IEmailMessageCompose>(null);
	const onUndoClicked = () => {
		if (undoContent) {
			onContentGenerated?.(undoContent, true);
		}
	};

	const onGenerateClicked = async (options: Api.IAIContentGenerationOptions) => {
		try {
			const request: Api.IEmailContentGenerationRequest = { ...options, templateId };
			if (isRewritingRef.current) {
				request.content = content;
				request.subject = subject;
			}

			setUndoContent({ content, subject });
			// @ts-ignore
			// @ts-ignore
			const generatedContent = await generateEmailContentAsync(request);
			// @ts-ignore
			onContentGenerated?.({ ...generatedContent.content, aiReference: { sessionId: generatedContent.sessionId } });
			return generatedContent;
		} catch (error) {
			// @ts-ignore
			logApiError('EmailContentGeneration-Error', error);
		}
	};

	return (
		<AIContentGenerationWizard<Api.IEmailMessageCompose, Api.IAIGeneratedContent<Api.IEmailMessageCompose>>
			cautionMessage='Please review content created with AI before sending.'
			isInitiallyRewriting={isRewritingRef.current}
			onCloseClicked={h.goBack}
			// @ts-ignore
			onGenerateClicked={onGenerateClicked}
			onRetryClicked={onUndoClicked}
			styleDeclarationValues={[styleSheet.aiContentGenerationWizard]}
			type={AIContentGenerationWizardType.Email}
			assistantMessageMap={AIContentGenerationWizardAssistantEmailMessages}
			requireInstructions
		/>
	);
};

const isDuplicateConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		title: 'Yes, Continue',
	},
	{
		isCancel: true,
		title: 'Cancel',
	},
];

export const DuplicateWarningDialog = ({
	isOpen,
	onRequestClose,
	onSuppressDuplicate,
	suppressIsDuplicateModal,
	title,
	type,
	duplicateType,
}: {
	isOpen: boolean;
	onRequestClose: (_: void, cancel: boolean) => void;
	onSuppressDuplicate: () => void;
	suppressIsDuplicateModal: boolean;
	title: string;
	type: 'campaign' | 'post';
	duplicateType: EDuplicateType;
}) => {
	return (
		<ConfirmationDialog
			icon={<WarningIcon />}
			modalProps={{
				isOpen,
				onRequestClose,
			}}
			className={css(styleSheet.duplicateWarning)}
			options={isDuplicateConfirmationOptions}
			title={title}
			footer={
				<div style={{ marginTop: 40, maxWidth: 300, width: '100%' }}>
					<Checkbox id='reminder' onChange={onSuppressDuplicate} checked={suppressIsDuplicateModal}>
						<div style={{ maxWidth: 300, width: '100%' }}>Don&apos;t remind me about this template again</div>
					</Checkbox>
				</div>
			}
		>
			<div className={css(styleSheet.isDuplicateModalContent)}>
				<p>
					{duplicateType === EDuplicateType.User
						? `You've already used this ${type}.`
						: `Someone on your account has already used this ${type}.`}
				</p>
				<p>Are you sure you want to continue?</p>
			</div>
		</ConfirmationDialog>
	);
};
