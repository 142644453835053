import * as React from 'react';
import { IModalProps, Modal } from '../../../web/components/Modal';
import { EnhancedCss } from '../../styles/styles';
import { styleSheet } from './styles';

export interface IFlyoutModalProps<TResult = any> extends IModalProps<TResult> {
	className?: string;
	isOpen: boolean;
}

export const FlyoutModal: React.FC<IFlyoutModalProps> = ({
	children,
	className = '',
	isOpen,
	useDefaultHeader = true,
	...restProps
}) => {
	return (
		<Modal
			{...restProps}
			className={`flyout-modal ${className}`}
			isOpen={isOpen}
			contentPresentationStyle={{
				afterOpenClassName: `${EnhancedCss(styleSheet.content)}--afterOpen`,
				beforeCloseClassName: `${EnhancedCss(styleSheet.content)}--beforeClose`,
				className: `${EnhancedCss(styleSheet.content)}`,
				transitionInDuration: 450,
				transitionOutDuration: 450,
			}}
			overlayPresentationStyle={{
				afterOpenClassName: `${EnhancedCss(styleSheet.overlay)}--afterOpen`,
				beforeCloseClassName: `${EnhancedCss(styleSheet.overlay)}--beforeClose`,
				className: `${EnhancedCss(styleSheet.overlay)}`,
				transitionInDuration: 450,
				transitionOutDuration: 450,
			}}
			useDefaultHeader={useDefaultHeader}
		>
			{children}
		</Modal>
	);
};
