import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
	AutomationStepAction,
	EditAutomationTemplateContext,
	EditAutomationTemplateStepsContext,
} from '../../../models/Automations';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { AutomationStepCard } from '../../components/automation/AutomationStepCard';
import { EditAutomationTemplateItemBumpers } from '../../components/automation/EditAutomationTemplateItemBumpers';
import { EditAutomationTemplateItemSeparator } from '../../components/automation/EditAutomationTemplateItemSeparator';
import { EditAutomationTemplateSegment } from '../../components/automation/EditAutomationTemplateSegment';
import { AutomationTemplateStepIndicatorIcon } from '../../components/svgs/icons/AutomationTemplateStepIndicator';
import { styleSheet } from './styles';

interface IEditAutomationStepProps {
	automationTemplate: Api.AutomationTemplateViewModel<Api.UserSessionContext>;
	index: number;
	onStepMenuItemClicked?(step: Api.AutomationTemplateEditorStep, action: AutomationStepAction): void;
	scrollToAnchorRef?: React.MutableRefObject<HTMLElement>;
	step: Api.AutomationTemplateEditorStep;
	stepIndicatorIconRef?: React.Ref<HTMLDivElement>;
	steps: Api.AutomationTemplateEditorStep[];
}

interface IEditAutomationSwitchStepProps extends IEditAutomationStepProps {
	step: Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>;
}

const EditAutomationSwitchStep = observer(
	React.forwardRef<HTMLDivElement, IEditAutomationSwitchStepProps>(function EditAutomationSwitchStepBase(props, ref) {
		const { step } = props;
		return (
			<div className={css(styleSheet.segments)} ref={ref}>
				{step.automationStep.cases?.map((caseStatement, i) => {
					return (
						<div key={caseStatement.id}>
							{i > 0 && <EditAutomationTemplateItemSeparator className={css(styleSheet.segmentSeparator)} />}
							<EditAutomationTemplateSegment
								caseStatement={caseStatement}
								key={caseStatement.automationTemplateId || caseStatement.id}
								switchStep={step}
							/>
						</div>
					);
				})}
			</div>
		);
	})
);

export const EditAutomationStep = React.forwardRef<HTMLDivElement, IEditAutomationStepProps>(
	function EditAutomationStepBase(props, ref) {
		const { automationTemplate, index, step, steps, stepIndicatorIconRef, scrollToAnchorRef } = props;
		const context = React.useContext(EditAutomationTemplateContext);
		const stepsContext = React.useContext(EditAutomationTemplateStepsContext);
		const errorMessages = useErrorMessages();
		const { logApiError, logEvent } = useEventLogging('EditAutomationStep');
		const isLast = index === steps.length - 1;

		const onMenuItemClicked = React.useCallback(
			async (action: AutomationStepAction) => {
				try {
					logEvent('MenuItemClicked', {
						action,
						automationTemplateId: automationTemplate?.id,
						stepId: step.automationStep?.id,
					});
					await stepsContext?.editorSteps?.executeActionForStep?.(step, action);
				} catch (error) {
					// @ts-ignore
					logApiError('MenuItemClicked-Error', error);
					// @ts-ignore
					// @ts-ignore
					errorMessages.pushApiError(error);
				}
				context?.onDraftUpdated?.();
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[step, stepsContext?.editorSteps, context?.onDraftUpdated, automationTemplate]
		);
		const switchStep =
			step.automationStep?.type === Api.AutomationStepType.Switch
				? (step as Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>)
				: null;

		const template = automationTemplate || step.automationStep?.automationTemplate;
		const isSwitchStepChild = !switchStep && !!template?.rootTemplate && template.rootTemplate !== template;

		const onRenderTitle = React.useMemo(() => {
			return isSwitchStepChild
				? () => {
						// @ts-ignore
						return null as JSX.Element;
					}
				: undefined;
		}, [isSwitchStepChild]);

		const onRenderLeftBumperContent = React.useCallback(() => {
			return (
				<div className={css(styleSheet.stepIndicator)}>
					<AutomationTemplateStepIndicatorIcon
						ref={stepIndicatorIconRef}
						step={step.automationStep}
						styles={[
							styleSheet.stepIndicatorIcon,
							// @ts-ignore
							isSwitchStepChild ? styleSheet.stepIndicatorIconForSwitchChildStep : null,
						]}
						type={isSwitchStepChild ? 'small' : 'default'}
					>
						<span>{index + 1}</span>
					</AutomationTemplateStepIndicatorIcon>
					<div
						className={css(
							styleSheet.stepIndicatorIconHorizontalLine,
							isSwitchStepChild ? styleSheet.stepIndicatorIconHorizontalLineForSwitchChildStep : null
						)}
					/>
					{!isSwitchStepChild && (
						<>
							{!isLast && (
								<div
									className={css(
										styleSheet.stepIndicatorIconVerticalLine,
										styleSheet.stepIndicatorIconVerticalLineBottom
									)}
								/>
							)}
							<div className={css(styleSheet.stepIndicatorIconVerticalLine, styleSheet.stepIndicatorIconVerticalTop)} />
						</>
					)}
				</div>
			);
		}, [step.automationStep, isSwitchStepChild, index, isLast, stepIndicatorIconRef]);

		return switchStep ? (
			<EditAutomationSwitchStep {...props} step={switchStep} ref={ref} />
		) : (
			<EditAutomationTemplateItemBumpers
				onRenderLeftBumperContent={onRenderLeftBumperContent}
				ref={ref}
				scrollAnchorId={step?.automationStep?.id || step?.uuid}
				scrollToAnchorRef={scrollToAnchorRef}
			>
				<AutomationStepCard
					index={index}
					onMenuItemClicked={onMenuItemClicked}
					onRenderTitle={onRenderTitle}
					readonly={!context?.canEdit}
					steps={steps}
					template={automationTemplate}
				/>
			</EditAutomationTemplateItemBumpers>
		);
	}
);
