import { css } from 'aphrodite';
import * as React from 'react';
import { PopoverType, TinyPopover } from '../../../../components/TinyPopover';
import { Toggle } from '../../../../components/Toggle';
import { InfoIcon } from '../../../../components/svgs/icons/InfoIcon';
import { brandSecondary } from '../../../../styles/colors';
import { bs } from '../../../../styles/styles';

export function EmailDuplicateContactSendToggle({
	isSelected,
	onChange,
}: {
	isSelected: boolean;
	onChange: (checked: boolean) => void;
}) {
	const [infoPopoverOpen, setInfoPopoverOpen] = React.useState(false);
	return (
		<div className={css(bs.flex, bs.itemsCenter, bs.gap1)}>
			<Toggle
				id='dontSendMultipleEmailsToSameAddressToggle'
				isOn={isSelected}
				text="Don't send multiple emails to the same address"
				textStyles={[bs.textXs, bs.textTitles]}
				uncheckedColor='#ccc'
				onToggleCheckChanged={onChange}
			/>
			<div onMouseEnter={() => setInfoPopoverOpen(true)} onMouseLeave={() => setInfoPopoverOpen(false)}>
				<TinyPopover
					anchor={<InfoIcon fillColor={brandSecondary} className={css(bs.h4, bs.w4)} />}
					isOpen={infoPopoverOpen}
					dismissOnOutsideAction={true}
					onRequestClose={() => setInfoPopoverOpen(false)}
					align='start'
					placement={['left', 'top']}
					type={PopoverType.lightBlue}
				>
					<div className={css(bs.boxBorder, bs.textLabel, bs.textXs, bs.maxWXs, bs.py2, bs.px3)}>
						Some recipients share the same email address. You can choose to send a copy to each.
					</div>
				</TinyPopover>
			</div>
		</div>
	);
}
