import { borderColor, white } from '../../styles/colors';
import { EnhancedStyleSheet } from '../../styles/styles';

const width = 420;
const duration = '.4s';

export const styleSheet = EnhancedStyleSheet.StyleSheet.create({
	content: {
		'&.&--afterOpen': {
			right: 0,
		},
		'&.&--beforeClose': {
			right: -width,
		},
		height: '100vh',
		position: 'fixed',
		top: 0,
		bottom: 0,
		right: -width,
		width,
		background: white,
		borderLeft: `1px solid ${borderColor}`,
		transition: `right ${duration} ease 0s`,
		zIndex: 11,
	},
	overlay: {
		'&.&--afterOpen': {
			background: 'rgba(255, 255, 255, 0.8)',
		},
		'&.&--beforeClose': {
			background: 'transparent',
		},
		background: 'transparent',
		height: '100vh',
		transition: `background ${duration} ease 0s`,
		width: '100vw',
	},
});
