import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { Noop } from '../../../../../extViewmodels/Utils';
import { baseStyleSheet } from '../../../../styles/styles';
import { Checkbox } from '../../../Checkbox';
import { RadioButton } from '../../../RadioButton';
import { EmailGuideTag } from '../../guide/EmailGuideTag';
import { styleSheet } from './styles';

export interface IFollowUpRecipientDiscriminatorOption<T = any> {
	count?: number;
	disabled?: boolean;
	id: string;
	representedObject: T;
	text: string;
}

interface IProps<T = any> {
	className?: string;
	hideSelectors?: boolean;
	multiSelect?: boolean;
	onSelectedOptionsChanged?(selectedOptions: IFollowUpRecipientDiscriminatorOption<T>[]): void;
	options: IFollowUpRecipientDiscriminatorOption<T>[];
	readonly?: boolean;
	selectedOptions: IFollowUpRecipientDiscriminatorOption<T>[];
	styles?: StyleDeclarationValue[];
}

export const FollowUpRecipientDiscriminator: React.FC<IProps> = props => {
	const {
		className,
		hideSelectors,
		multiSelect,
		onSelectedOptionsChanged,
		options,
		readonly,
		selectedOptions,
		styles = [],
	} = props;

	const selectedIds = React.useMemo(() => {
		return new Set(selectedOptions?.map(x => x.id) || []);
	}, [selectedOptions]);

	const disableIds = React.useMemo(() => {
		return new Set(options?.filter(x => x.disabled).map(x => x.id));
	}, [options]);

	const onOptionClicked = React.useCallback(
		(option: IFollowUpRecipientDiscriminatorOption) => () => {
			if (multiSelect) {
				const nextOptions = [...(selectedOptions || [])];
				const index = nextOptions.findIndex(x => x.id === option.id);
				if (index >= 0) {
					nextOptions.splice(index, 1);
				} else {
					nextOptions.push(option);
				}
				onSelectedOptionsChanged?.(nextOptions);
			} else {
				onSelectedOptionsChanged?.([option]);
			}
		},
		[onSelectedOptionsChanged, multiSelect, selectedOptions]
	);

	const renderSelector = (x: IFollowUpRecipientDiscriminatorOption) => {
		if (hideSelectors) {
			return null;
		}
		if (multiSelect) {
			return (
				<Checkbox
					checked={selectedIds.has(x.id)}
					id={`option-${x.id}`}
					onChange={!disableIds.has(x.id) ? onOptionClicked(x) : Noop}
					readOnly={readonly}
				/>
			);
		}
		return (
			<RadioButton
				checked={selectedIds.has(x.id)}
				disabled={disableIds.has(x.id)}
				id={`option-${x.id}`}
				name={`option-${x.id}`}
				onClick={!disableIds.has(x.id) ? onOptionClicked(x) : Noop}
				readOnly={readonly}
			/>
		);
	};
	return (
		<ul className={`${css(styleSheet.container, ...styles)} follow-up-recipient-discriminator ${className || ''}`}>
			{options?.map(x => {
				return (
					<li className={css(styleSheet.row)} key={x.id}>
						{renderSelector(x)}
						<EmailGuideTag
							className={css(disableIds.has(x.id) ? baseStyleSheet.disabled : null)}
							isFollowUp={true}
							isSelected={selectedIds.has(x.id)}
							// @ts-ignore
							onClick={!disableIds.has(x.id) ? onOptionClicked(x) : null}
							followUpCount={x.count}
							value={x.text}
						/>
					</li>
				);
			})}
		</ul>
	);
};
