import { brandPrimary, brandSecondary, grayIconFill, mention, mentionDark } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		marginTop: 20,
	},
	dragContainer: {
		alignItems: 'center',
		background: mention,
		border: `2px dashed rgba(0,170,232, 1)`,
		borderRadius: '6px',
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		height: '250px',
		opacity: 1,
		textAlign: 'center',
		width: '510px',
	},
	dragFooter: {
		color: brandSecondary,
		fontSize: '12px',
	},
	dragInfo: {
		marginTop: '60px',
	},
	dragText: {
		color: '#046cb6',
		fontSize: '18px',
	},
	header: {
		color: brandPrimary,
		fontSize: 18,
	},
	imageContainer: {
		border: `1px solid ${mentionDark}`,
		borderRadius: 3,
		boxShadow: '0px 0px 12px #E8F3F9',
		boxSizing: 'border-box',
		display: 'flex',
		marginTop: 18,
	},
	imageDisplayArea: {
		display: 'flex',
		height: '90%',
		justifyContent: 'space-between',
		width: '100%',
	},
	imageDisplayAreaClose: {
		margin: '10px 10px 0',
	},
	imageDisplayAreaImageContainer: {
		alignItems: 'center',
		color: grayIconFill,
		display: 'flex',
	},
	imageDisplayAreaInfo: {
		marginLeft: 20,
	},
	imagesContainer: {
		marginBottom: 20,
		maxHeight: '60vh',
		overflow: 'auto',
	},
	loadingFooter: {
		alignItems: 'flex-end',
		color: brandSecondary,
		display: 'flex',
		fontSize: '12px',
	},
	modal: {
		...CssSelectors.allDescendants({ minWidth: 500 }, '.modal-overlay-content'),
	},
	ownImage: {
		color: '#046CB6',
		fontSize: 14,
		marginBottom: 13,
		marginTop: 12,
	},
});
