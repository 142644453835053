import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, brandSecondary, success } from '../../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../../styles/styles';

const iconSize = 40;
export const CompleteColor = '#65AF1A';
export const styleSheet = StyleSheet.create({
	completed: {
		background: '#F1FCE7',
		border: `1px solid ${success}`,
		boxSizing: 'border-box',
		color: CompleteColor,
	},
	completedFooter: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	container: {
		background: '#E8F3F9',
		border: `1px solid ${brandSecondary}`,
		borderRadius: 3,
		boxSizing: 'border-box',
		color: brandPrimaryText,
		fontSize: 12,
		padding: 10,
		textAlign: 'left',
	},
	details: {
		width: `calc(100% - ${iconSize + 12}px)`,
	},
	footer: {
		marginTop: 5,
	},
	icon: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: '50%',
		boxSizing: 'border-box',
		display: 'flex',
		flexShrink: 0,
		height: iconSize,
		justifyContent: 'center',
		marginRight: 12,
		width: iconSize,
	},
	iconCompleted: {
		background: success,
	},
	iconSocial: {
		marginRight: 10,
		...CssSelectors.allChildren(
			{
				height: iconSize,
				width: iconSize,
			},
			'svg'
		),
	},
	socialInfo: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	subject: {
		...baseStyleSheet.fontBold,
	},
	targetType: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				height: 16,
				marginRight: 6,
			},
			'img'
		),
	},
	top: {
		alignItems: 'center',
		display: 'flex',
	},
});
