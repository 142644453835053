import { titles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'inline-block',
		width: 168,
	},
	content: {
		alignItems: 'center',
		display: 'flex',
	},
	dateText: {
		color: titles,
		flexGrow: 1,
		fontSize: 14,
	},
	nextPrev: {
		flexShrink: 0,
	},
});
