import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const DragGripperIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`drag-gripper-icon ${className || ''}`} height={20} width={11}>
			<g fill={fillColor || '#D3DBDE'}>
				<circle cx='2' cy='2' r='2' />
				<circle cx='9' cy='2' r='2' />
				<circle cx='2' cy='10' r='2' />
				<circle cx='9' cy='10' r='2' />
				<circle cx='2' cy='18' r='2' />
				<circle cx='9' cy='18' r='2' />
			</g>
		</SvgIcon>
	);
};
