import {
	FullscreenModalHeaderContext,
	IFullscreenModalHeaderContext,
	IModal,
	ModalChildComponentContextKey,
} from '@AppModels/.';
import {
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { excludeKeysOf } from '@ViewModels';
import { LifecycleHooks } from '@WebComponents/LifecycleHooks';
import { PortalDestination } from '@WebComponents/Portal';
import { PrivateRoute } from '@WebComponents/PrivateRoute';
import { FullscreenModalRouterSwitch } from '@WebComponents/fullscreen/FullscreenModalRouterSwitch';
import * as h from 'history';
import { IReactionDisposer, autorun, observable } from 'mobx';
import { Provider, inject, observer } from 'mobx-react';
import * as React from 'react';
import ReactModal from 'react-modal';
import { RouteComponentProps, Router, withRouter } from 'react-router';
import { v4 as uuidgen } from 'uuid';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { EnhancedCss } from '../../../styles/styles';
import { AppAutoUpdaterRefreshBlocker } from '../../AppAutoUpdaterRefreshBlocker';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../LocalNotificationObserver/WithNotificationService';
import { FullscreenModalTransitionDuration, styleSheet } from './styles';
import { StyleDeclarationValue } from 'aphrodite';

interface IProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		RouteComponentProps<any>,
		IFullscreenModalComponentProps,
		INotificationServiceComponentProps<string> {
	className?: string;
	name?: string;
	onAfterClose?(): void;
	onAfterOpen?(): void;
	customHeaderStyles?: StyleDeclarationValue[];
}

interface IState {
	closingModalLocation?: h.Location<any>;
	location?: h.Location<any>;
}

class _FullscreenModal extends React.Component<IProps, IState> {
	// @ts-ignore
	@observable private mMounted: boolean;
	private mChildContext: IModal;
	private mHeaderContext: IFullscreenModalHeaderContext;
	private mHeaderTitlePortalDestinationId: string;
	private mHeaderLeftAccessoryPortalDestinationId: string;
	private mHeaderRightAccessoryPortalDestinationId: string;
	// @ts-ignore
	private onModalHistoryChangedDisposer: IReactionDisposer;

	constructor(props: IProps) {
		super(props);
		this.mHeaderTitlePortalDestinationId = uuidgen();
		this.mHeaderRightAccessoryPortalDestinationId = uuidgen();
		this.mHeaderLeftAccessoryPortalDestinationId = uuidgen();
		this.state = {};

		this.mChildContext = {
			name: props.name || 'fullscreenModal',
			onRequestClose: this.close,
		};
		this.mHeaderContext = {
			headerLeftAccessoryPortalDestinationId: this.mHeaderLeftAccessoryPortalDestinationId,
			headerRightAccessoryPortalDestinationId: this.mHeaderRightAccessoryPortalDestinationId,
			headerTitlePortalDestinationId: this.mHeaderTitlePortalDestinationId,
			onBack: this.onBackButtonClicked,
			onClose: this.onCloseButtonClicked,
		};
	}

	public componentDidMount() {
		this.mMounted = true;
		const { fullscreenModal } = this.props;
		this.onModalHistoryChangedDisposer = autorun(() => {
			const { location, closingModalLocation } = this.state;
			// @ts-ignore
			// @ts-ignore
			if (location !== fullscreenModal.history.location && !!fullscreenModal.hasValidLocation) {
				// open
				this.setState({
					// @ts-ignore
					location: fullscreenModal.history.location,
				});
				return;
			}

			// @ts-ignore
			if (!fullscreenModal.hasValidLocation && !!location && closingModalLocation !== location) {
				// closing
				this.setState({
					closingModalLocation: location,
					// @ts-ignore
					location: null,
				});
			}
		});
	}

	public componentWillUnmount() {
		this.mMounted = false;
		// @ts-ignore
		this.props.fullscreenModal.history.listenToBrowserLocationStateChanges(false);
		if (this.onModalHistoryChangedDisposer) {
			this.onModalHistoryChangedDisposer();
			// @ts-ignore
			this.onModalHistoryChangedDisposer = null;
		}
	}

	public render() {
		const { className, fullscreenModal, userSession, children, customHeaderStyles } = this.props;
		const { closingModalLocation } = this.state;
		return (
			<ReactModal
				// @ts-ignore
				bodyOpenClassName={null}
				className={{
					afterOpen: `${EnhancedCss(styleSheet.modalContent)}--afterOpen`,
					base: EnhancedCss(styleSheet.modalContent),
					beforeClose: `${EnhancedCss(styleSheet.modalContent)}--beforeClose`,
				}}
				closeTimeoutMS={FullscreenModalTransitionDuration}
				// @ts-ignore
				isOpen={!!fullscreenModal.hasValidLocation && !!this.mMounted}
				onAfterClose={this.onAfterClose}
				onAfterOpen={this.onAfterOpen}
				overlayClassName={{
					afterOpen: `${EnhancedCss(styleSheet.modalOverlay)}--afterOpen`,
					base: EnhancedCss(styleSheet.modalOverlay),
					beforeClose: `${EnhancedCss(styleSheet.modalOverlay)}--beforeClose`,
				}}
				portalClassName={`modal fullscreen-modal ${EnhancedCss(styleSheet.modalPortal)} ${
					// @ts-ignore
					!!fullscreenModal.hasValidLocation || !!closingModalLocation ? 'modal-active' : ''
				} ${className || ''}`}
				shouldCloseOnOverlayClick={false}
			>
				<React.Fragment>
					<div className={EnhancedCss(styleSheet.header, ...(customHeaderStyles || []))}>
						<PortalDestination
							className={EnhancedCss(styleSheet.headerAccessory, styleSheet.headerAccessoryLeft)}
							id={this.mHeaderLeftAccessoryPortalDestinationId}
						/>
						<PortalDestination
							className={EnhancedCss(styleSheet.headerTitle)}
							id={this.mHeaderTitlePortalDestinationId}
						/>
						<div className={EnhancedCss(styleSheet.headerAccessory, styleSheet.headerAccessoryRight)}>
							<PortalDestination id={this.mHeaderRightAccessoryPortalDestinationId} />
						</div>
					</div>
					<div className={EnhancedCss(styleSheet.body)}>
						<Provider {...{ [ModalChildComponentContextKey]: this.mChildContext }}>
							<FullscreenModalHeaderContext.Provider value={this.mHeaderContext}>
								<div className={EnhancedCss(styleSheet.routerContainer)}>
									{/* @ts-ignore */}
									<Router history={fullscreenModal.history}>
										{/* @ts-ignore */}
										<FullscreenModalRouterSwitch location={fullscreenModal.history.location}>
											{children}
											<PrivateRoute render={this.onRenderInExternalRouter} userSession={userSession} />
										</FullscreenModalRouterSwitch>
									</Router>
									<AppAutoUpdaterRefreshBlocker />
								</div>
							</FullscreenModalHeaderContext.Provider>
						</Provider>
					</div>
				</React.Fragment>
			</ReactModal>
		);
	}

	private onAfterOpen = () => {
		const { onAfterOpen, postNotification } = this.props;
		onAfterOpen?.();
		postNotification?.({
			info: 'onAfterOpen',
			topic: Topics.APP_FULLSCREEN_MODAL_APPEARANCE,
		});
	};

	private onAfterClose = () => {
		// clear content only after close
		this.setState(
			{
				// @ts-ignore
				closingModalLocation: null,
				// @ts-ignore
				location: null,
			},
			() => {
				const { onAfterClose, postNotification } = this.props;
				if (onAfterClose) {
					onAfterClose();
				}
				postNotification?.({
					info: 'onAfterClose',
					topic: Topics.APP_FULLSCREEN_MODAL_APPEARANCE,
				});
			}
		);
	};

	private onBackButtonClicked = () => {
		const { fullscreenModal, logInput } = this.props;
		// @ts-ignore
		logInput('Back', 'Click');
		// @ts-ignore
		fullscreenModal.history.goBack();
	};

	private onCloseButtonClicked = (e?: React.MouseEvent<HTMLElement>) => {
		if (!!e && !!e.defaultPrevented) {
			return;
		}

		const { logInput } = this.props;
		// @ts-ignore
		logInput('Close', 'Click');
		this.close();
	};

	private close = () => {
		const { fullscreenModal } = this.props;
		// @ts-ignore
		fullscreenModal.history.goBackToLocationBeforeTrackedHistory(true);
	};

	private onRenderInExternalRouter = (props: RouteComponentProps<any>) => {
		return <LifecycleHooks onDidMount={this.onNavigateToExternalLocation(props.location)} />;
	};

	private onNavigateToExternalLocation = (location: h.Location<any>) => () => {
		const { history, logEvent, fullscreenModal } = this.props;
		// @ts-ignore
		logEvent('NavigateOutsideFullscreenModal', {
			location: location ? (typeof location === 'string' ? location : location.pathname) : '',
		});
		// @ts-ignore
		fullscreenModal.history.goBackToLocationBeforeTrackedHistory(true);
		setTimeout(() => {
			history.push(excludeKeysOf(location, ['key'])); // external router history
		}, 100);
	};
}

const FullscreenModalAsObserver = observer(_FullscreenModal);
const FullscreenModalWithRouter = withRouter(FullscreenModalAsObserver);
const FullscreenModalWithContext = inject(
	FullScreenModalViewModelKey,
	UserSessionViewModelKey
)(FullscreenModalWithRouter);
const FullscreenModalWithNotifications = withNotificationService(FullscreenModalWithContext);
export const FullscreenModal = withEventLogging(FullscreenModalWithNotifications, 'FullscreenModal');
