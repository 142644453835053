import * as Api from '@ViewModels';
import { StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ISelectOption, Select } from '../Select';
import { UserSelectModal } from '../UserSelectModal';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	additionalPinnedOptions?: ISelectOption<string | Api.UserViewModel>[];
	defaultOption?: ISelectOption<string | Api.UserViewModel>;
	disabled?: boolean;
	initialSelectedOption?: ISelectOption<string | Api.UserViewModel>;
	includeSearch?: boolean;
	onFilterOptions?(options: ISelectOption<string | Api.UserViewModel>[]): ISelectOption<string | Api.UserViewModel>[];
	onOptionSelected?(option: ISelectOption<string | Api.UserViewModel>, user?: Api.UserViewModel): void;
	onRenderPlaceholder?(): React.ReactNode;
	styles?: StyleDeclarationValue[];
}

const UserSelectBoxBase: React.FC<IProps> = observer(props => {
	const {
		styles = [],
		defaultOption,
		onOptionSelected,
		onFilterOptions,
		includeSearch = true,
		disabled,
		initialSelectedOption,
		additionalPinnedOptions,
		impersonationContext,
	} = props;
	const userSession = useUserSession();
	const [selectedUser, setSelectedUser] = React.useState<Api.UserViewModel>(null);
	const { logApiError } = useEventLogging('UserSelectBox');
	const options = React.useMemo(() => {
		const selectedUserOption = selectedUser
			? {
					dataContext: selectedUser,
					hoverText: selectedUser.email,
					id: `option-${selectedUser.id}`,
					styles: [styleSheet.selectOption],
					text: selectedUser.name,
				}
			: null;
		const searchOption = {
			dataContext: 'search',
			id: 'option-search',
			styles: [styleSheet.selectOption],
			text: 'Search Employee',
		};

		const currentUser = impersonationContext?.user
			? new Api.UserViewModel(userSession, impersonationContext.user).impersonate(impersonationContext)
			: new Api.UserViewModel(userSession, userSession.user);
		const computedOptions: ISelectOption<string | Api.UserViewModel>[] = [
			defaultOption ? { ...defaultOption, styles: [styleSheet.selectOption] } : defaultOption,
			selectedUserOption,
			selectedUserOption?.dataContext?.id !== currentUser.id
				? {
						dataContext: currentUser,
						hoverText: currentUser.email,
						id: `option-${currentUser.id}`,
						styles: [styleSheet.selectOption],
						text: userSession.user.id === currentUser.id ? 'Me' : currentUser.name,
					}
				: null,
			...(additionalPinnedOptions || []),
			includeSearch ? searchOption : null,
		].filter(x => !!x);
		return onFilterOptions ? onFilterOptions(computedOptions) : computedOptions;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser, defaultOption, onFilterOptions, additionalPinnedOptions]);
	React.useEffect(() => {
		if (typeof initialSelectedOption?.dataContext === 'object') {
			setSelectedUser(initialSelectedOption.dataContext);
		} else if (initialSelectedOption?.dataContext) {
			const user = new Api.UserViewModel(userSession, {
				id: initialSelectedOption.dataContext,
			}).impersonate(impersonationContext);
			user
				.load()
				.then(() => {
					// FOLLOWUP: Resolve
					// @ts-ignore
					setSelectedUser(user);
				})
				.catch(error => {
					logApiError('UserLoad-Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [impersonationContext?.account, impersonationContext?.user]);

	const [isUserSelectModalOpen, setIsUserSelectModalOpen] = React.useState<boolean>(false);
	const onUserSelectRequestClose = React.useCallback(
		(user?: Api.UserViewModel, cancel?: boolean) => {
			if (!cancel) {
				setSelectedUser(user);
				onOptionSelected?.({
					dataContext: user,
					id: user.id,
					text: user.name,
				});
			}
			setIsUserSelectModalOpen(false);
		},
		[onOptionSelected]
	);
	const userSelectModalProps = React.useMemo(
		() => ({
			isOpen: isUserSelectModalOpen,
			onRequestClose: onUserSelectRequestClose,
		}),
		[isUserSelectModalOpen, onUserSelectRequestClose]
	);

	const onOptionClick = React.useCallback(
		(option: ISelectOption<string | Api.UserViewModel>, wasSelected: boolean) => {
			if (wasSelected) {
				if (typeof option.dataContext === 'string') {
					if (option.dataContext === 'search') {
						setIsUserSelectModalOpen(true);
						return;
					}

					// fall back to default option
					setSelectedUser(null);
					onOptionSelected?.(
						option,
						typeof defaultOption?.dataContext !== 'string' ? defaultOption?.dataContext : null
					);
				} else {
					setSelectedUser(option.dataContext);
					onOptionSelected?.(option, option.dataContext);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onOptionSelected]
	);
	return (
		<>
			<Select
				disabled={disabled}
				key={selectedUser?.id || 'default'}
				onOptionClick={onOptionClick}
				options={options}
				optionStyles={[styleSheet.selectOption]}
				onRenderPlaceholder={props.onRenderPlaceholder}
				selectedOption={
					selectedUser
						? options?.find(x => (x.dataContext as Api.UserViewModel)?.id === selectedUser.id)
						: defaultOption
				}
				styles={[styleSheet.container, ...styles]}
			/>
			<UserSelectModal modalProps={userSelectModalProps} title='User Search' />
		</>
	);
});

export const UserSelectBox = inject(ImpersonationContextKey)(UserSelectBoxBase);
