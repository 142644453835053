import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const ContactsImportProcessingIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`contacts-import-processing-icon ${className || ''}`} height={268} width={265}>
			<defs>
				<path
					id='b-c'
					d='M17,2 L45,2 L45,22 C45,29.7319865 38.7319865,36 31,36 L31,36 C23.2680135,36 17,29.7319865 17,22 L17,2 Z'
				/>
				<circle id='b-g' cx='31.592' cy='31.592' r='26' />
			</defs>
			<g fill='none' fillRule='evenodd'>
				<mask id='b-b' fill='#fff'>
					<circle cx='132.5' cy='132.5' r='132.5' />
				</mask>
				<circle cx='132.5' cy='132.5' r='132.5' fill='#E8F3F9' />
				<path
					fill='#FFF'
					stroke='#CF949C'
					strokeWidth='2'
					d='M76,267 L188,267 L188,192 C188,190.343146 186.656854,189 185,189 L79,189 C77.3431458,189 76,190.343146 76,192 L76,267 Z'
					mask='url(#b-b)'
				/>
				<path
					fill='#FFF6F6'
					stroke='#CF949C'
					strokeWidth='2'
					d='M76,200 L188,200 L188,192 C188,190.343146 186.656854,189 185,189 L79,189 C77.3431458,189 76,190.343146 76,192 L76,200 Z'
					mask='url(#b-b)'
				/>
				<circle cx='81.5' cy='194.5' r='1.5' fill='#CF949C' mask='url(#b-b)' />
				<circle cx='86.5' cy='194.5' r='1.5' fill='#CF949C' mask='url(#b-b)' />
				<circle cx='91.5' cy='194.5' r='1.5' fill='#CF949C' mask='url(#b-b)' />
				<circle cx='110' cy='214' r='6' fill='#D9D0D1' mask='url(#b-b)' />
				<circle cx='110' cy='232' r='6' fill='#EFEFEF' mask='url(#b-b)' />
				<circle cx='110' cy='250' r='6' fill='#EFEFEF' mask='url(#b-b)' />
				<rect width='28' height='3' x='121' y='210' fill='#EFEEEF' mask='url(#b-b)' rx='1.5' />
				<rect width='15' height='3' x='121' y='216' fill='#EFEEEF' mask='url(#b-b)' rx='1.5' />
				<rect width='26' height='3' x='121' y='228' fill='#EFEEEF' mask='url(#b-b)' rx='1.5' />
				<path
					fill='#EFEEEF'
					d='M122.5,246 L151.5,246 C152.328427,246 153,246.671573 153,247.5 L153,247.5 C153,248.328427 152.328427,249 151.5,249 L122.5,249 C121.671573,249 121,248.328427 121,247.5 L121,247.5 C121,246.671573 121.671573,246 122.5,246 Z'
					mask='url(#b-b)'
				/>
				<rect width='35' height='3' x='121' y='234' fill='#EFEEEF' mask='url(#b-b)' rx='1.5' />
				<rect width='20' height='3' x='121' y='252' fill='#EFEEEF' mask='url(#b-b)' rx='1.5' />
				<g transform='translate(34 226)'>
					<path
						fill='#E0E0E0'
						stroke='#858585'
						strokeWidth='2'
						d='M13,1 L13,26 C13,34.2842712 19.7157288,41 28,41 L34,41 C42.2842712,41 49,34.2842712 49,26 L49,1 L13,1 Z'
					/>
					<path
						fill='#E0E0E0'
						stroke='#858585'
						strokeWidth='2'
						d='M0,24 L3.53474955,23.5581563 C6.08569548,23.2392881 8,21.0707979 8,18.5 L8,15 C8,14.4477153 7.55228475,14 7,14 L0,14 L-3.28626015e-14,9 L11,9 C12.1045695,9 13,9.8954305 13,11 L13,19 C13,24.5228475 8.5228475,29 3,29 L-3.28626015e-14,29 L-3.28626015e-14,24 Z'
						transform='matrix(-1 0 0 1 13 0)'
					/>
					<mask id='b-d' fill='#fff'>
						<path d='M17,2 L45,2 L45,22 C45,29.7319865 38.7319865,36 31,36 L31,36 C23.2680135,36 17,29.7319865 17,22 L17,2 Z' />
					</mask>
					<path
						fill='#EFEFEF'
						d='M17,2 L45,2 L45,22 C45,29.7319865 38.7319865,36 31,36 L31,36 C23.2680135,36 17,29.7319865 17,22 L17,2 Z'
					/>
					<rect width='6' height='34' x='32' y='2' fill='#F9F9F9' mask='url(#b-d)' />
				</g>
				<path
					stroke='#E9E9E9'
					strokeWidth='2'
					d='M67,205 C65,206.29986 64,207.966526 64,210 C64,212.94979 66.7272727,214 67,217 C67.1515152,218.666667 66.1515152,220.666667 64,223'
				/>
				<g transform='translate(36 99)'>
					<circle cx='31.871' cy='31.871' r='31' fill='#FFF6F6' stroke='#CF949C' strokeWidth='2' />
					<mask id='b-f' fill='#fff'>
						<circle cx='31.592' cy='31.592' r='26' />
					</mask>
					<circle fill='#FFF' cx='31.592' cy='31.592' r='26' />
					<g mask='url(#b-f)'>
						<g transform='translate(12 15)'>
							<polygon fill='#C0A093' points='18.199 15.816 20.497 15.816 20.497 13.519 18.199 13.519' />
							<polygon fill='#C0A093' points='24.982 18.114 27.28 18.114 27.28 15.816 24.982 15.816' />
							<polygon fill='#987E73' points='11.407 18.114 13.704 18.114 13.704 15.816 11.407 15.816' />
							<polygon
								fill='#C0A093'
								points='31.835 45.682 31.802 45.682 29.538 45.682 29.538 47.979 31.802 47.979 31.835 47.979 34.1 47.979 34.1 45.682'
							/>
							<polygon fill='#C0A093' points='31.802 50.277 34.1 50.277 34.1 47.979 31.802 47.979' />
							<polygon fill='#F2C1BF' points='18.199 11.252 20.497 11.252 20.497 8.955 18.199 8.955' />
							<polygon fill='#D2B4AA' points='18.199 25.006 20.497 25.006 20.497 22.708 18.199 22.708' />
							<polygon fill='#D2B4AA' points='20.479 25.006 22.776 25.006 22.776 22.708 20.479 22.708' />
							<polygon fill='#D2B4AA' points='15.985 25.006 18.282 25.006 18.282 22.708 15.985 22.708' />
							<polygon
								fill='#C0A093'
								points='16.008 52.574 15.985 52.574 13.71 52.574 13.71 54.86 13.71 54.872 13.71 57.157 15.985 57.157 16.008 57.157 18.282 57.157 18.282 54.872 18.282 54.86 18.282 52.574'
							/>
							<polygon
								fill='#C0A093'
								points='22.776 52.574 22.75 52.574 20.479 52.574 20.479 54.86 20.479 54.872 20.479 57.157 22.75 57.157 22.776 57.157 25.047 57.157 25.047 54.872 25.047 54.86 25.047 52.574'
							/>
							<polygon fill='#ECECEC' points='18.199 36.493 20.497 36.493 20.497 34.195 18.199 34.195' />
							<polygon fill='#ECECEC' points='9.117 36.493 11.414 36.493 11.414 34.195 9.117 34.195' />
							<polygon
								fill='#ECECEC'
								points='9.117 38.79 9.117 38.79 9.117 41.087 9.117 41.087 9.117 43.385 11.414 43.385 11.414 41.087 11.414 41.087 11.414 38.79 11.414 38.79 11.414 36.493 9.117 36.493'
							/>
							<polygon fill='#ECECEC' points='9.117 45.682 11.414 45.682 11.414 43.385 9.117 43.385' />
							<polygon fill='#ECECEC' points='27.27 43.385 29.568 43.385 29.568 41.087 27.27 41.087' />
							<polygon
								fill='#ECECEC'
								points='27.27 34.195 27.27 36.492 27.27 36.493 27.27 38.79 27.27 38.79 27.27 41.087 29.568 41.087 29.568 38.79 29.568 38.79 29.568 36.493 29.568 36.492 29.568 34.195'
							/>
							<polygon fill='#ECECEC' points='27.27 45.682 29.568 45.682 29.568 43.385 27.27 43.385' />
							<polygon
								fill='#C0A093'
								points='6.866 45.682 6.835 45.682 4.568 45.682 4.568 47.979 6.835 47.979 6.866 47.979 9.132 47.979 9.132 45.682'
							/>
							<polygon fill='#C0A093' points='4.568 50.277 6.866 50.277 6.866 47.979 4.568 47.979' />
							<polygon fill='#ECECEC' points='18.199 41.087 20.497 41.087 20.497 38.79 18.199 38.79' />
							<polygon fill='#ECECEC' points='18.199 45.682 20.497 45.682 20.497 43.385 18.199 43.385' />
							<polygon fill='#2F4149' points='11.407 59.466 13.704 59.466 13.704 57.169 11.407 57.169' />
							<polygon
								fill='#2F4149'
								points='16.008 57.169 15.985 57.169 13.71 57.169 13.71 59.466 15.985 59.466 16.008 59.466 18.282 59.466 18.282 57.169'
							/>
							<polygon
								fill='#2F4149'
								points='25.047 57.169 24.982 57.169 22.776 57.169 22.75 57.169 20.479 57.169 20.479 59.466 22.75 59.466 22.776 59.466 24.982 59.466 25.047 59.466 27.28 59.466 27.28 57.169'
							/>
							<polygon
								fill='#A4A18D'
								points='11.407 50.277 11.407 52.574 13.704 52.574 13.704 50.277 13.704 47.98 11.407 47.98'
							/>
							<polygon
								fill='#A4A18D'
								points='25.047 47.98 24.982 47.98 22.776 47.98 22.75 47.98 20.497 47.98 20.479 47.98 18.282 47.98 18.199 47.98 16.008 47.98 15.985 47.98 13.71 47.98 13.71 50.277 13.71 52.574 15.985 52.574 16.008 52.574 18.282 52.574 18.282 50.277 20.479 50.277 20.479 52.574 22.75 52.574 22.776 52.574 24.982 52.574 25.047 52.574 27.28 52.574 27.28 50.277 27.28 47.98'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 29.601 9.132 29.601 9.117 29.601 6.835 29.601 6.835 31.898 9.117 31.898 9.132 31.898 11.407 31.898 11.414 31.898 13.704 31.898 13.704 29.601 11.414 29.601'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 31.898 9.132 31.898 9.117 31.898 6.866 31.898 6.835 31.898 4.568 31.898 4.568 34.195 4.568 34.195 4.568 36.493 6.835 36.493 6.866 36.493 9.132 36.493 9.132 34.195 11.407 34.195 11.407 36.493 13.704 36.493 13.704 34.195 13.704 34.195 13.704 31.898 11.414 31.898'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 38.79 11.407 38.79 11.407 41.087 11.407 41.087 11.407 43.385 13.704 43.385 13.704 41.087 13.704 41.087 13.704 38.79 13.704 38.79 13.704 36.493 11.407 36.493'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 45.682 9.132 45.682 9.132 43.385 6.866 43.385 6.835 43.385 4.568 43.385 4.568 45.682 6.835 45.682 6.866 45.682 9.117 45.682 9.117 47.979 11.407 47.979 11.414 47.979 13.704 47.979 13.704 45.682 13.704 45.682 13.704 43.385 11.407 43.385'
							/>
							<polygon
								fill='#C3CBCC'
								points='27.28 31.898 29.538 31.898 29.568 31.898 31.835 31.898 31.835 29.601 29.568 29.601 29.538 29.601 27.28 29.601 24.982 29.601 24.982 31.898'
							/>
							<polygon
								fill='#C3CBCC'
								points='31.802 43.385 29.538 43.385 29.538 45.682 27.28 45.682 27.28 43.385 25.047 43.385 24.982 43.385 22.776 43.385 22.75 43.385 20.479 43.385 20.479 45.682 18.282 45.682 18.282 43.385 16.008 43.385 15.985 43.385 13.71 43.385 13.71 45.682 13.71 45.682 13.71 47.979 15.985 47.979 16.008 47.979 18.199 47.979 18.282 47.979 20.479 47.979 20.497 47.979 22.75 47.979 22.776 47.979 24.982 47.979 25.047 47.979 27.271 47.979 27.28 47.979 29.568 47.979 29.568 45.682 31.802 45.682 31.835 45.682 34.1 45.682 34.1 43.385 31.835 43.385'
							/>
							<polygon
								fill='#C3CBCC'
								points='31.835 31.898 31.802 31.898 29.568 31.898 29.538 31.898 27.28 31.898 25.047 31.898 24.982 31.898 22.776 31.898 22.75 31.898 20.497 31.898 20.479 31.898 18.282 31.898 18.199 31.898 16.008 31.898 15.985 31.898 13.71 31.898 13.71 34.195 13.71 34.195 13.71 36.493 15.985 36.493 16.008 36.493 18.282 36.493 18.282 34.195 20.479 34.195 20.479 36.493 22.75 36.493 22.776 36.493 24.982 36.493 25.047 36.493 27.28 36.493 27.28 34.195 29.538 34.195 29.538 36.493 31.802 36.493 31.835 36.493 34.1 36.493 34.1 34.195 34.1 34.195 34.1 31.898'
							/>
							<polygon
								fill='#C3CBCC'
								points='31.802 36.493 29.538 36.493 29.538 38.79 29.538 38.79 29.538 41.087 29.538 41.087 29.538 43.385 31.802 43.385 31.835 43.385 34.1 43.385 34.1 41.087 34.1 41.087 34.1 38.79 34.1 38.79 34.1 36.493 31.835 36.493'
							/>
							<polygon
								fill='#C3CBCC'
								points='6.866 43.385 9.132 43.385 9.132 41.087 9.132 41.087 9.132 38.79 9.132 38.79 9.132 36.493 6.866 36.493 6.835 36.493 4.568 36.493 4.568 38.79 4.568 38.79 4.568 41.087 4.568 41.087 4.568 43.385 6.835 43.385'
							/>
							<path
								fill='#C3CBCC'
								d='M18.2824568,41.0872063 L20.4788131,41.0872063 L20.4788131,38.7901217 L18.2824568,38.7901217 L18.2824568,41.0872063 Z M16.0075714,43.3845079 L18.1994444,43.3845079 L18.2823845,43.3845079 L20.4788131,43.3845079 L20.496746,43.3845079 L22.749649,43.3845079 L22.7761146,43.3845079 L24.9821605,43.3845079 L25.0470229,43.3845079 L27.2795344,43.3845079 L27.2795344,41.0872787 L27.2795344,41.0872063 L27.2795344,38.7900494 L27.2795344,38.7899771 L27.2795344,36.4926755 L25.0470229,36.4926755 L24.9821605,36.4926755 L22.7761146,36.4926755 L22.749649,36.4926755 L20.496746,36.4926755 L20.4788131,36.4926755 L18.2823845,36.4926755 L18.1994444,36.4926755 L16.0075714,36.4926755 L15.9850829,36.4926755 L13.7102698,36.4926755 L13.7102698,38.7899771 L13.7102698,38.7900494 L13.7102698,41.0872063 L13.7102698,41.0872787 L13.7102698,43.3845079 L15.9850829,43.3845079 L16.0075714,43.3845079 Z'
							/>
							<polygon fill='#D2B4AA' points='15.985 20.385 18.282 20.385 18.282 18.088 15.985 18.088' />
							<polygon fill='#D2B4AA' points='13.71 20.385 16.008 20.385 16.008 18.088 13.71 18.088' />
							<polygon fill='#D2B4AA' points='13.71 22.689 16.008 22.689 16.008 20.391 13.71 20.391' />
							<polygon fill='#D2B4AA' points='22.75 20.385 25.047 20.385 25.047 18.088 22.75 18.088' />
							<polygon fill='#D2B4AA' points='22.75 22.689 25.047 22.689 25.047 20.391 22.75 20.391' />
							<polygon fill='#D2B4AA' points='20.479 20.385 22.776 20.385 22.776 18.088 20.479 18.088' />
							<polygon fill='#C0A093' points='11.407 20.385 13.704 20.385 13.704 18.088 11.407 18.088' />
							<polygon fill='#6B6766' points='13.71 31.898 16.008 31.898 16.008 29.601 13.71 29.601' />
							<polygon fill='#6B6766' points='15.985 31.898 18.282 31.898 18.282 29.601 15.985 29.601' />
							<polygon fill='#6B6766' points='18.199 31.898 20.497 31.898 20.497 29.601 18.199 29.601' />
							<polygon fill='#6B6766' points='20.479 31.898 22.776 31.898 22.776 29.601 20.479 29.601' />
							<polygon fill='#C0A093' points='22.75 31.898 25.047 31.898 25.047 29.601 22.75 29.601' />
							<polygon fill='#C0A093' points='24.982 20.385 27.28 20.385 27.28 18.088 24.982 18.088' />
							<polygon fill='#C0A093' points='24.982 22.689 27.28 22.689 27.28 20.391 24.982 20.391' />
							<polygon fill='#C0A093' points='11.407 22.689 13.704 22.689 13.704 20.391 11.407 20.391' />
							<polygon fill='#C0A093' points='15.985 27.303 18.282 27.303 18.282 25.006 15.985 25.006' />
							<polygon fill='#C0A093' points='18.199 27.303 20.497 27.303 20.497 25.006 18.199 25.006' />
							<polygon fill='#C0A093' points='20.479 27.303 22.776 27.303 22.776 25.006 20.479 25.006' />
							<polygon fill='#987E73' points='15.985 29.6 18.282 29.6 18.282 27.303 15.985 27.303' />
							<polygon fill='#6B6766' points='13.71 29.6 16.008 29.6 16.008 27.303 13.71 27.303' />
							<polygon fill='#987E73' points='11.407 27.303 13.704 27.303 13.704 25.006 11.407 25.006' />
							<polygon fill='#987E73' points='11.407 29.6 13.704 29.6 13.704 27.303 11.407 27.303' />
							<polygon fill='#987E73' points='13.71 27.303 16.008 27.303 16.008 25.006 13.71 25.006' />
							<polygon fill='#C0A093' points='13.71 25.006 16.008 25.006 16.008 22.708 13.71 22.708' />
							<polygon fill='#C0A093' points='22.75 25.006 25.047 25.006 25.047 22.708 22.75 22.708' />
							<polygon fill='#987E73' points='11.407 25.006 13.704 25.006 13.704 22.708 11.407 22.708' />
							<polygon fill='#808286' points='15.985 9.022 18.282 9.022 18.282 6.725 15.985 6.725' />
							<polygon fill='#808286' points='24.982 9.022 27.28 9.022 27.28 6.725 24.982 6.725' />
							<polygon fill='#808286' points='15.985 4.595 18.282 4.595 18.282 2.297 15.985 2.297' />
							<polygon fill='#808286' points='24.982 4.595 27.28 4.595 27.28 2.297 24.982 2.297' />
							<polygon fill='#987E73' points='22.75 27.303 25.047 27.303 25.047 25.006 22.75 25.006' />
							<polygon fill='#987E73' points='24.982 27.303 27.28 27.303 27.28 25.006 24.982 25.006' />
							<polygon fill='#C0A093' points='24.982 25.006 27.28 25.006 27.28 22.708 24.982 22.708' />
							<polygon fill='#987E73' points='18.199 29.6 20.497 29.6 20.497 27.303 18.199 27.303' />
							<polygon fill='#987E73' points='20.479 29.6 22.776 29.6 22.776 27.303 20.479 27.303' />
							<polygon fill='#6B6766' points='22.75 29.6 25.047 29.6 25.047 27.303 22.75 27.303' />
							<polygon fill='#987E73' points='24.982 29.6 27.28 29.6 27.28 27.303 24.982 27.303' />
							<polygon
								fill='#242933'
								points='15.985 13.46 13.71 13.46 13.71 15.816 13.71 15.902 13.71 18.114 16.008 18.114 16.008 15.902 18.282 15.902 18.282 13.46 16.008 13.46'
							/>
							<polygon fill='#242933' points='9.117 25.092 11.414 25.092 11.414 22.794 9.117 22.794' />
							<polygon
								fill='#242933'
								points='29.538 20.477 27.271 20.477 27.271 22.774 29.538 22.774 29.568 22.774 31.835 22.774 31.835 20.477 29.568 20.477'
							/>
							<polygon
								fill='#242933'
								points='9.117 20.477 6.835 20.477 6.835 22.774 9.117 22.774 9.132 22.774 11.414 22.774 11.414 20.477 9.132 20.477'
							/>
							<polygon
								fill='#242933'
								points='11.407 2.297 11.407 4.592 11.407 4.595 11.407 4.678 9.132 4.678 9.117 4.678 6.835 4.678 6.835 6.811 4.568 6.811 4.568 9.041 2.297 9.041 2.297 11.307 0 11.307 0 13.605 0 15.902 2.297 15.902 2.297 18.2 4.568 18.2 4.568 20.471 6.835 20.471 6.866 20.471 9.117 20.471 9.132 20.471 11.414 20.471 11.414 18.2 11.414 18.174 11.414 15.902 13.704 15.902 13.704 13.605 13.704 13.519 13.704 11.252 13.704 11.222 13.704 9.022 13.704 8.955 13.704 6.889 13.704 6.725 13.704 4.595 13.704 4.592 13.704 2.297'
							/>
							<path
								fill='#242933'
								d='M22.7496635,0 L22.7496635,2.2973739 L20.4787552,2.2973739 L20.4787552,4.59192769 L18.2823989,4.59192769 L18.1994589,4.59192769 L16.0075859,4.59192769 L16.0075859,2.2973739 L16.0075859,0 L13.7102843,0 L13.7102843,2.2973739 L13.7102843,4.59192769 L13.7102843,4.5947478 L13.7102843,6.72465079 L13.7102843,6.88930159 L13.7102843,8.95506526 L13.7102843,9.02202469 L13.7102843,11.2524392 L15.985025,11.2524392 L16.0075859,11.2524392 L18.1994589,11.2524392 L18.2823989,11.2524392 L20.4787552,11.2524392 L20.4967605,11.2524392 L20.4787552,13.604769 L20.4787552,15.9021429 L22.7496635,15.9021429 L22.7496635,18.113612 L25.0470374,18.113612 L25.0470374,15.9021429 L27.2704377,15.9021429 L27.2704377,18.1736296 L27.2704377,18.1995168 L27.2704377,20.4710035 L29.5378751,20.4710035 L29.5678116,20.4710035 L31.8024924,20.4710035 L31.8351767,20.4710035 L34.0516353,20.4710035 L34.099794,20.4710035 L36.3489369,20.4710035 L36.3489369,18.1995168 L38.6463108,18.1995168 L38.6463108,15.9021429 L38.6463108,15.8163104 L40.9436847,15.8163104 L40.9436847,11.2215626 L38.6463108,11.2215626 L38.6463108,9.04089771 L36.3489369,9.04089771 L36.3489369,6.81055556 L34.099794,6.81055556 L34.099794,4.67776014 L31.8351767,4.67776014 L31.8351767,2.38320635 L29.5678116,2.38320635 L29.5678116,0.0858324515 L27.2704377,0.0858324515 L27.2704377,2.38320635 L27.2704377,4.59192769 L25.0470374,4.59192769 L25.0470374,2.2973739 L25.0470374,0 L22.7496635,0 Z M25.0470374,6.88930159 L27.2704377,6.88930159 L27.2704377,6.97513404 L27.2704377,8.95506526 L25.0470374,8.95506526 L25.0470374,6.88930159 Z M16.0075859,8.95506526 L18.1994589,8.95506526 L18.1994589,6.88930159 L16.0075859,6.88930159 L16.0075859,8.95506526 Z'
							/>
							<polygon fill='#242933' points='27.27 25.092 29.568 25.092 29.568 22.794 27.27 22.794' />
							<polygon
								fill='#C0A093'
								points='22.776 11.222 22.75 11.222 20.497 11.222 20.479 11.222 18.282 11.222 18.199 11.222 16.008 11.222 15.985 11.222 13.71 11.222 13.71 13.519 15.985 13.519 16.008 13.519 18.199 13.519 18.282 13.519 20.479 13.519 20.497 13.519 22.75 13.519 22.776 13.519 25.047 13.519 25.047 11.222'
							/>
							<polygon
								fill='#C0A093'
								points='20.479 20.391 18.282 20.391 18.199 20.391 15.985 20.391 15.985 22.689 18.199 22.689 18.282 22.689 20.479 22.689 20.497 22.689 22.776 22.689 22.776 20.391 20.497 20.391'
							/>
							<polygon
								fill='#C0A093'
								points='20.479 15.816 18.282 15.816 18.199 15.816 15.985 15.816 15.985 18.114 18.199 18.114 18.199 20.385 20.497 20.385 20.497 18.114 22.776 18.114 22.776 15.816 20.497 15.816'
							/>
						</g>
					</g>
					<g transform='translate(129)'>
						<circle cx='31.871' cy='31.871' r='31' fill='#FFF6F6' stroke='#CF949C' strokeWidth='2' />
						<mask id='b-h' fill='#fff'>
							<circle cx='31.592' cy='31.592' r='26' />
						</mask>
						<circle fill='#FFF' cx='31.592' cy='31.592' r='26' />
						<g mask='url(#b-h)'>
							<g transform='translate(19 13)'>
								<polygon fill='#F6F6F8' points='11.106 30.928 13.933 30.928 13.933 28.098 11.106 28.098' />
								<polygon fill='#ED213E' points='8.425 61.926 11.252 61.926 11.252 59.096 8.425 59.096' />
								<polygon fill='#ED213E' points='13.944 61.926 16.77 61.926 16.77 59.096 13.944 59.096' />
								<polygon
									fill='#242933'
									points='5.603 28.098 5.603 30.89 5.603 30.928 5.603 33.721 8.43 33.721 8.43 30.928 8.43 30.89 8.43 28.098'
								/>
								<polygon
									fill='#242933'
									points='2.827 28.098 2.766 28.098 0 28.098 0 30.89 0 30.928 0 33.721 2.766 33.721 2.827 33.721 5.593 33.721 5.593 30.928 5.593 30.89 5.593 28.098'
								/>
								<polygon fill='#242933' points='5.603 36.502 8.43 36.502 8.43 33.672 5.603 33.672' />
								<polygon fill='#242933' points='2.766 36.502 5.593 36.502 5.593 33.672 2.766 33.672' />
								<polygon
									fill='#F2C1BF'
									points='2.766 36.502 2.766 39.333 2.766 42.162 2.766 42.163 2.766 44.993 5.593 44.993 5.593 42.163 5.593 42.162 5.593 39.333 5.593 36.502'
								/>
								<polygon
									fill='#242933'
									points='19.494 33.672 19.435 33.672 16.667 33.672 16.667 36.502 19.435 36.502 19.494 36.502 22.261 36.502 22.261 33.672'
								/>
								<polygon
									fill='#F2C1BF'
									points='19.435 36.503 19.435 39.332 19.435 39.333 19.435 42.162 22.262 42.162 22.262 39.333 22.262 39.332 22.262 36.503'
								/>
								<polygon fill='#F2C1BF' points='19.435 44.994 22.261 44.994 22.261 42.164 19.435 42.164' />
								<polygon fill='#F6F6F8' points='11.106 36.502 13.933 36.502 13.933 33.672 11.106 33.672' />
								<polygon fill='#F6F6F8' points='11.106 42.163 13.933 42.163 13.933 39.332 11.106 39.332' />
								<polygon
									fill='#F2C1BF'
									points='8.425 50.654 8.425 53.437 8.425 53.484 8.425 56.267 8.425 59.097 11.252 59.097 11.252 56.267 11.252 53.484 11.252 53.437 11.252 50.654'
								/>
								<polygon
									fill='#F2C1BF'
									points='13.944 50.654 13.944 53.437 13.944 53.484 13.944 56.267 13.944 59.097 16.77 59.097 16.77 56.267 16.77 53.484 16.77 53.437 16.77 50.654'
								/>
								<polygon
									fill='#030404'
									points='16.77 44.993 16.667 44.993 13.944 44.993 13.944 47.823 16.667 47.823 16.77 47.823 19.494 47.823 19.494 44.993'
								/>
								<polygon
									fill='#030404'
									points='11.106 47.824 8.43 47.824 8.425 47.824 5.603 47.824 5.603 50.654 8.425 50.654 8.43 50.654 11.106 50.654 11.252 50.654 13.933 50.654 13.933 47.824 11.252 47.824'
								/>
								<polygon
									fill='#030404'
									points='16.667 47.824 13.944 47.824 13.944 50.654 16.667 50.654 16.77 50.654 19.494 50.654 19.494 47.824 16.77 47.824'
								/>
								<polygon
									fill='#030404'
									points='11.106 44.993 8.43 44.993 8.425 44.993 5.603 44.993 5.603 47.823 8.425 47.823 8.43 47.823 11.106 47.823 11.252 47.823 13.933 47.823 13.933 44.993 11.252 44.993'
								/>
								<polygon fill='#F2C1BF' points='11.106 14.126 13.933 14.126 13.933 11.295 11.106 11.295' />
								<polygon fill='#F2C1BF' points='11.106 11.288 13.933 11.288 13.933 8.458 11.106 8.458' />
								<polygon fill='#F2C1BF' points='13.944 11.288 16.77 11.288 16.77 8.458 13.944 8.458' />
								<polygon fill='#F2C1BF' points='8.425 11.288 11.252 11.288 11.252 8.458 8.425 8.458' />
								<polygon fill='#F2C1BF' points='5.603 11.288 8.43 11.288 8.43 8.458 5.603 8.458' />
								<polygon fill='#F2C1BF' points='11.106 8.468 13.933 8.468 13.933 5.638 11.106 5.638' />
								<polygon fill='#F2C1BF' points='8.425 8.468 11.252 8.468 11.252 5.638 8.425 5.638' />
								<polygon fill='#F2C1BF' points='5.603 8.468 8.43 8.468 8.43 5.638 5.603 5.638' />
								<polygon fill='#F2C1BF' points='11.106 16.92 13.933 16.92 13.933 14.089 11.106 14.089' />
								<polygon fill='#F6A4A5' points='16.667 16.92 19.494 16.92 19.494 14.089 16.667 14.089' />
								<polygon fill='#F2C1BF' points='11.106 22.496 13.933 22.496 13.933 19.666 11.106 19.666' />
								<polygon
									fill='#A4A4A4'
									points='11.252 28.098 8.425 28.098 8.425 30.89 8.425 30.928 8.425 33.672 8.425 33.721 8.425 36.502 5.603 36.502 5.603 39.332 5.603 42.162 8.425 42.162 8.425 42.163 11.252 42.163 11.252 39.332 13.933 39.332 13.933 36.502 11.252 36.502 11.106 36.502 8.43 36.502 8.43 36.502 11.252 36.502 11.252 33.721 13.933 33.721 13.933 30.89 11.252 30.89'
								/>
								<polygon
									fill='#A4A4A4'
									points='16.77 36.502 16.667 36.502 13.944 36.502 13.944 39.333 13.944 42.163 16.667 42.163 16.77 42.163 19.494 42.163 19.494 39.333 19.494 36.502'
								/>
								<polygon
									fill='#A4A4A4'
									points='16.77 33.721 16.77 33.672 16.77 30.928 16.77 30.89 16.77 28.098 13.944 28.098 13.944 30.89 13.944 30.928 13.944 33.672 13.944 33.721 13.944 36.502 16.77 36.502'
								/>
								<polygon
									fill='#A4A4A4'
									points='16.667 42.163 13.944 42.163 13.944 44.993 16.667 44.993 16.77 44.993 19.494 44.993 19.494 42.163 16.77 42.163'
								/>
								<polygon
									fill='#A4A4A4'
									points='11.106 42.163 8.43 42.163 8.425 42.163 5.603 42.163 5.603 44.993 8.425 44.993 8.43 44.993 11.106 44.993 11.252 44.993 13.933 44.993 13.933 42.163 11.252 42.163'
								/>
								<polygon fill='#F2C1BF' points='13.944 22.496 16.77 22.496 16.77 19.666 13.944 19.666' />
								<polygon fill='#F2C1BF' points='8.425 22.496 11.252 22.496 11.252 19.666 8.425 19.666' />
								<polygon fill='#F6A4A5' points='11.106 19.741 13.933 19.741 13.933 16.911 11.106 16.911' />
								<polygon fill='#F2C1BF' points='13.944 19.741 16.77 19.741 16.77 16.911 13.944 16.911' />
								<polygon fill='#F6A4A5' points='8.425 19.741 11.252 19.741 11.252 16.911 8.425 16.911' />
								<polygon fill='#F0CDCD' points='5.603 19.741 8.43 19.741 8.43 16.911 5.603 16.911' />
								<polygon fill='#F0CDCD' points='16.667 19.741 19.494 19.741 19.494 16.911 16.667 16.911' />
								<polygon fill='#E5A3A0' points='2.766 19.741 5.593 19.741 5.593 16.911 2.766 16.911' />
								<polygon fill='#E5A3A0' points='5.603 22.496 8.43 22.496 8.43 19.666 5.603 19.666' />
								<polygon fill='#E5A3A0' points='16.667 22.496 19.494 22.496 19.494 19.666 16.667 19.666' />
								<polygon fill='#242933' points='5.603 28.175 8.43 28.175 8.43 25.344 5.603 25.344' />
								<polygon
									fill='#242933'
									points='0 11.295 0 14.089 0 14.126 0 16.911 0 16.92 0 19.666 0 19.741 0 22.496 2.766 22.496 2.827 22.496 5.593 22.496 5.593 19.666 2.827 19.666 2.827 16.92 2.827 16.911 2.827 14.126 2.827 14.089 2.827 11.295'
								/>
								<polygon
									fill='#242933'
									points='2.827 22.503 2.766 22.503 0 22.503 0 25.333 2.766 25.333 2.827 25.333 5.593 25.333 5.593 22.503'
								/>
								<polygon
									fill='#242933'
									points='2.827 25.344 2.766 25.344 0 25.344 0 28.175 2.766 28.175 2.827 28.175 5.593 28.175 5.593 25.344'
								/>
								<polygon fill='#242933' points='5.603 25.333 8.43 25.333 8.43 22.503 5.603 22.503' />
								<polygon
									fill='#242933'
									points='2.827 5.638 2.766 5.638 0 5.638 0 8.458 0 8.468 0 11.288 2.766 11.288 2.827 11.288 5.593 11.288 5.593 8.468 5.593 8.458 5.593 5.638'
								/>
								<polygon fill='#242933' points='16.667 5.66 19.494 5.66 19.494 2.83 16.667 2.83' />
								<polygon
									fill='#332B27'
									points='13.944 0 13.944 2.83 13.944 5.66 16.77 5.66 16.77 2.83 19.435 2.83 19.435 5.66 22.261 5.66 22.261 2.83 19.494 2.83 19.494 0'
								/>
								<polygon fill='#332B27' points='2.766 5.66 5.593 5.66 5.593 2.83 2.766 2.83' />
								<polygon
									fill='#332B27'
									points='5.603 0 5.603 2.83 5.603 5.66 8.425 5.66 8.43 5.66 11.106 5.66 11.252 5.66 13.933 5.66 13.933 2.83 13.933 0'
								/>
								<polygon fill='#F2C1BF' points='16.667 25.333 19.494 25.333 19.494 22.503 16.667 22.503' />
								<polygon
									fill='#242933'
									points='22.261 28.098 22.15 28.098 19.494 28.098 19.435 28.098 16.667 28.098 16.667 30.89 16.667 30.928 16.667 33.721 19.435 33.721 19.494 33.721 22.15 33.721 22.261 33.721 24.977 33.721 24.977 28.098'
								/>
								<polygon fill='#E5A3A0' points='13.944 25.333 16.77 25.333 16.77 22.503 13.944 22.503' />
								<polygon
									fill='#E5A3A0'
									points='11.106 22.503 8.425 22.503 8.425 25.333 11.106 25.333 11.252 25.333 13.933 25.333 13.933 22.503 11.252 22.503'
								/>
								<polygon
									fill='#E5A3A0'
									points='11.106 25.344 8.425 25.344 8.425 28.175 11.106 28.175 11.252 28.175 13.933 28.175 13.933 25.344 11.252 25.344'
								/>
								<polygon fill='#E5A3A0' points='13.944 28.175 16.77 28.175 16.77 25.344 13.944 25.344' />
								<polygon fill='#F2C1BF' points='8.425 16.92 11.252 16.92 11.252 14.089 8.425 14.089' />
								<polygon fill='#F2C1BF' points='16.667 14.126 19.494 14.126 19.494 11.295 16.667 11.295' />
								<polygon fill='#242933' points='5.603 14.126 8.43 14.126 8.43 11.295 5.603 11.295' />
								<polygon fill='#F6A4A5' points='5.603 16.92 8.43 16.92 8.43 14.089 5.603 14.089' />
								<polygon fill='#E5A3A0' points='2.766 14.126 5.593 14.126 5.593 11.295 2.766 11.295' />
								<polygon fill='#E5A3A0' points='2.766 16.92 5.593 16.92 5.593 14.089 2.766 14.089' />
								<polygon fill='#F2C1BF' points='8.425 14.126 11.252 14.126 11.252 11.295 8.425 11.295' />
								<polygon fill='#242933' points='13.944 14.126 16.77 14.126 16.77 11.295 13.944 11.295' />
								<polygon fill='#E5A3A0' points='13.944 16.92 16.77 16.92 16.77 14.089 13.944 14.089' />
								<polygon
									fill='#242933'
									points='22.261 5.638 22.15 5.638 19.494 5.638 19.435 5.638 16.77 5.638 16.667 5.638 13.944 5.638 13.944 8.468 16.667 8.468 16.667 11.288 19.435 11.288 19.494 11.288 22.15 11.288 22.261 11.288 24.977 11.288 24.977 5.638'
								/>
								<polygon
									fill='#242933'
									points='22.261 25.344 22.15 25.344 19.494 25.344 19.435 25.344 16.667 25.344 16.667 28.175 19.435 28.175 19.494 28.175 22.15 28.175 22.261 28.175 24.977 28.175 24.977 25.344'
								/>
								<polygon
									fill='#242933'
									points='22.261 22.503 22.15 22.503 19.494 22.503 19.435 22.503 16.667 22.503 16.667 25.333 19.435 25.333 19.494 25.333 22.15 25.333 22.261 25.333 24.977 25.333 24.977 22.503'
								/>
								<polygon
									fill='#242933'
									points='22.261 11.295 22.15 11.295 19.435 11.295 19.435 14.089 19.435 14.126 19.435 16.911 19.435 16.92 19.435 19.666 19.435 19.741 19.435 22.496 22.15 22.496 22.261 22.496 24.977 22.496 24.977 11.295'
								/>
							</g>
						</g>
					</g>
				</g>
				<path
					fill='#CF949C'
					d='M37.2261833 104.45283C37.9033856 104.45283 38.4523665 103.903745 38.4523665 103.226415 38.4523665 102.549085 37.9033856 102 37.2261833 102 36.5489809 102 36 102.549085 36 103.226415 36 103.903745 36.5489809 104.45283 37.2261833 104.45283zM236.226183 120.45283C236.903386 120.45283 237.452367 119.903745 237.452367 119.226415 237.452367 118.549085 236.903386 118 236.226183 118 235.548981 118 235 118.549085 235 119.226415 235 119.903745 235.548981 120.45283 236.226183 120.45283zM27 122.000378C27.5522847 122.000378 28 121.552578 28 121.000189 28 120.4478 27.5522847 120 27 120 26.4477153 120 26 120.4478 26 121.000189 26 121.552578 26.4477153 122.000378 27 122.000378z'
				/>
				<g fill='#CF949C' transform='translate(24 107)'>
					<rect width='4' height='1.333' y='1.333' rx='.667' />
					<rect width='4' height='1.333' y='1.333' rx='.667' transform='rotate(90 2 2)' />
				</g>
				<g fill='#CF949C' transform='translate(194 89)'>
					<rect width='4' height='1.333' y='1.333' rx='.667' />
					<rect width='4' height='1.333' y='1.333' rx='.667' transform='rotate(90 2 2)' />
				</g>
				<path stroke='#979797' strokeDasharray='4' d='M88,168 C88.6666667,183.333333 96,196.666667 110,208' />
				<path
					stroke='#979797'
					strokeDasharray='4'
					d='M155,168 C155.666667,183.333333 163,196.666667 177,208'
					transform='matrix(-1 0 0 1 332 0)'
				/>
				<path stroke='#979797' strokeDasharray='4' d='M109,112.5 C125.666667,102.5 141.333333,102.5 156,112.5' />
				<polygon
					fill='#CF949C'
					points='109.044 235.653 105 231.61 106.768 229.842 108.725 231.799 112.867 226 114.901 227.452'
				/>
				<polygon
					fill='#CF949C'
					points='109.044 253.653 105 249.61 106.768 247.842 108.725 249.799 112.867 244 114.901 245.452'
				/>
			</g>
		</SvgIcon>
	);
};
