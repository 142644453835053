import { css } from 'aphrodite';
import { DefaultEditableActionItemContentCSS } from '../../../../../models';
import { FirstNamePlaceholder, LastNamePlaceholder } from '../../../../../models/Token';
import { titles } from '../../../../styles/colors';
import { bs } from '../../../../styles/styles';
import {
	IRichContentDocumentEditorProps,
	RichContentDocumentEditor,
} from '../../../richContent/RichContentDocumentEditor';

export function HwcAutomationStepEditor(props: IRichContentDocumentEditorProps) {
	const config = {
		autoresizeToFitContent: true,
		contentRawCss: DefaultEditableActionItemContentCSS.replace('padding: 0;', 'padding: 2px;').replace(
			/color:[ ]?[#]?[a-z0-9]*;/i,
			`color: ${titles};`
		),
		minHeight: 72,
		plugins: ['placeholders'],
		toolbar: false,
		placeholders: [FirstNamePlaceholder, LastNamePlaceholder],
	};

	return (
		<RichContentDocumentEditor
			config={config}
			styles={[bs.textField, bs.wFull]}
			className={css(bs.border0, bs.borderT, bs.borderSolid, bs.borderGray200)}
			{...props}
		/>
	);
}
