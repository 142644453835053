import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { useImageLoader } from '../../../../models/hooks/useImageLoader';
import AIAssistantLargeIconImageUrl from '../../../assets/aiAssistantIconLarge.png';
import { white } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { RefreshIcon } from '../../svgs/icons/RefreshIcon';
import { AIContentGenerationOptions } from '../AIContentGenerationOptions';
import {
	AIContentGenerationWizardImageUrls,
	AIContentGenerationWizardStep,
	AIContentGenerationWizardType,
} from './models';
import { AIContentGenerationWizardAssistantLoadingModal, AIContentGenerationWizardBackground } from './presentation';
import { styleSheet } from './styles';
import { useCycleThroughMessages } from './useCycleThroughMessages';

interface IProps<
	TContent = any,
	TGeneratedContent extends Api.IAIGeneratedContent<TContent> = Api.IAIGeneratedContent<TContent>,
> {
	cautionMessage?: string;
	className?: string;
	generateButtonCta?: React.ReactNode;
	instructionsPlaceholder?: string;
	isInitiallyRewriting?: boolean;
	onCloseClicked?(): void;
	onGenerateClicked?(options: Api.IAIContentGenerationOptions): Promise<TGeneratedContent>;
	onRetryClicked?(e: React.MouseEvent<HTMLElement>, options?: Api.IAIContentGenerationOptions): void;
	styleDeclarationValues?: StyleDeclarationValue[];
	type?: AIContentGenerationWizardType;
	assistantMessageMap: Record<AIContentGenerationWizardStep, string[]>;
	setInitialStep?: AIContentGenerationWizardStep;
	requireInstructions?: boolean;
}

export function AIContentGenerationWizard<
	TContent = any,
	TGeneratedContent extends Api.IAIGeneratedContent<TContent> = Api.IAIGeneratedContent<TContent>,
>({
	cautionMessage,
	className,
	generateButtonCta,
	instructionsPlaceholder,
	isInitiallyRewriting,
	onCloseClicked,
	onGenerateClicked,
	onRetryClicked,
	styleDeclarationValues = [],
	type,
	assistantMessageMap,
	setInitialStep,
	requireInstructions = false,
}: IProps<TContent, TGeneratedContent>) {
	useImageLoader(AIContentGenerationWizardImageUrls);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [generationOptions, setGenerationOptions] = React.useState<Api.IAIContentGenerationOptions>({
		creativity: 0.0,
		// @ts-ignore
		writingInstructions: null,
	});
	const initialStepRef = React.useRef(
		setInitialStep
			? setInitialStep
			: isInitiallyRewriting
				? AIContentGenerationWizardStep.ConfigWithContent
				: AIContentGenerationWizardStep.Config
	);
	const [step, setStep] = React.useState<AIContentGenerationWizardStep>(initialStepRef.current);
	const onGenerateButtonClicked = async () => {
		setStep(AIContentGenerationWizardStep.Generating);
		setIsLoading(true);
		try {
			const generatedContent = await onGenerateClicked?.(generationOptions);
			setStep(generatedContent ? AIContentGenerationWizardStep.RetryPrompt : initialStepRef.current);
		} catch {
			// no need to handle the error here... caller should
		}
		setIsLoading(false);
	};

	const onTryAgainClicked = (e: React.MouseEvent<HTMLElement>) => {
		onRetryClicked?.(e, generationOptions);
		if (!e.isDefaultPrevented()) {
			setStep(initialStepRef.current);
		}
	};

	const currentAssistantMessage = useCycleThroughMessages({
		messages: assistantMessageMap[step],
	});

	return (
		<div
			className={`${css(styleSheet.container, ...styleDeclarationValues)} ai-content-generation-wizard ${
				className || ''
			}`}
		>
			<AIContentGenerationWizardBackground />
			<div className={css(styleSheet.header)}>
				<button className={css(styleSheet.headerCloseButton)} onClick={onCloseClicked}>
					<span>Close AI</span>
				</button>
			</div>
			<section className={css(styleSheet.assistantContainer)}>
				<img className={css(styleSheet.assistantIcon)} src={AIAssistantLargeIconImageUrl} />
				<p className={css(styleSheet.pill)}>{currentAssistantMessage}</p>
			</section>
			{step !== AIContentGenerationWizardStep.RetryPrompt ? (
				<section>
					<AIContentGenerationOptions
						instructionsPlaceholder={instructionsPlaceholder}
						isRewriting={isInitiallyRewriting}
						type={type}
						generationOptions={generationOptions}
						onUpdateGenerationOptions={options => {
							if (options) {
								setGenerationOptions({ ...generationOptions, ...options });
							}
						}}
						disabled={isLoading}
					/>
				</section>
			) : null}
			<section className={css(styleSheet.footer)}>
				{step === AIContentGenerationWizardStep.RetryPrompt ? (
					<>
						<footer
							className={css(
								baseStyleSheet.horizontalStack,
								styleSheet.footerButtons,
								styleSheet.footerButtonsCentered
							)}
						>
							<button className={css(styleSheet.generateButton)} onClick={onCloseClicked} disabled={isLoading}>
								<span>Yes! Continue</span>
							</button>
							<button
								className={css(baseStyleSheet.outlineButtonContainer, styleSheet.footerCloseButton)}
								disabled={isLoading}
								onClick={onTryAgainClicked}
							>
								<span className={css(baseStyleSheet.horizontalStack)}>
									<RefreshIcon className={css(styleSheet.refreshIcon)} fillColor={white} />
									<span>Try Again</span>
								</span>
							</button>
						</footer>
						<p className={css(styleSheet.caution)}>
							<strong>CAUTION:</strong>{' '}
							<span>{cautionMessage || 'Please review content created with AI before submitting.'}</span>
						</p>
					</>
				) : (
					<footer
						className={css(baseStyleSheet.horizontalStack, styleSheet.footerButtons, styleSheet.footerButtonsCentered)}
					>
						<button
							className={css(
								styleSheet.generateButton,
								type === AIContentGenerationWizardType.BlogPost ? styleSheet.generateBlogButton : undefined
							)}
							onClick={onGenerateButtonClicked}
							disabled={isLoading || (requireInstructions && !generationOptions?.writingInstructions)}
						>
							<span className={css(baseStyleSheet.horizontalStack)}>
								<RefreshIcon className={css(styleSheet.refreshIcon)} fillColor={white} />
								{generateButtonCta ?? (
									<span>{`Generate ${type === AIContentGenerationWizardType.Email ? 'Email' : type === AIContentGenerationWizardType.BlogPost ? 'Blog Post' : 'Post'}`}</span>
								)}
							</span>
						</button>
						<button
							className={css(baseStyleSheet.outlineButtonContainer, styleSheet.footerCloseButton)}
							disabled={isLoading}
							onClick={onCloseClicked}
						>
							<span>Close</span>
						</button>
					</footer>
				)}
			</section>
			{/* @ts-ignore */}
			<AIContentGenerationWizardAssistantLoadingModal type={type} />
		</div>
	);
}
