import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

const emailRecipientsListMinWidth = 360;
export const styleSheet = StyleSheet.create({
	autocompleteDropdownContent: {
		width: 363,
		'@media screen and (max-width: 768px)': {
			width: '100%',
		},
	},
	avatar: {
		marginLeft: '0.5rem',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: emailRecipientsListMinWidth,
	},
	modal: {
		maxWidth: '400px',
	},
	noContactsMessage: {
		marginTop: 80,
		...CssSelectors.allChildren({
			textAlign: 'center',
		}),
		...CssSelectors.allChildren(
			{
				background: '#F9F9F9',
				borderRadius: '50%',
				boxSizing: 'border-box',
				height: 120,
				margin: '0 auto',
				padding: '25px 0 0',
				width: 120,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				color: '#4A4A4A',
				margin: '30px auto 0',
				width: '86%',
			},
			':last-child'
		),
	},
	recipientsListItemAvatarList: {
		height: 60,
		marginLeft: '0.5rem',
		position: 'relative',
		width: 60,
	},
	recipientsListItemAvatarsFirst: {
		height: 30,
		left: 0,
		position: 'absolute',
		top: 0,
		width: 30,
	},
	recipientsListItemAvatarsSecond: {
		bottom: 0,
		height: 30,
		position: 'absolute',
		right: 0,
		width: 30,
	},
});
