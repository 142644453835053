import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fillColor?: string;
	styles?: StyleDeclarationValue[];
}

export const SquareLinkIcon: React.FC<IProps> = props => {
	const { className, fillColor, styles = [] } = props;

	return (
		<SvgIcon className={`square-link-icon ${css(...styles)} ${className || ''}`} height={15} width={16}>
			<g fill='none' fillRule='evenodd' stroke={fillColor || grayIconFill} strokeWidth='2'>
				<rect width='8.263' height='8.263' x='6.526' y='1' rx='2' />
				<rect width='9.053' height='9.053' x='1' y='4.947' rx='2' />
			</g>
		</SvgIcon>
	);
};
