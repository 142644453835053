import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryText, titles } from '../../../styles/colors';
import { CssSelectors, cardStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	activeCount: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0 0 5px 5px',
	},
	activeCountLink: {
		fontSize: 16,
	},
	cancelAutomationButton: {
		margin: '20 100 20 100',
	},
	container: {
		...cardStyle,
		boxSizing: 'border-box',
		color: '#A7ABAD',
		fontSize: 16,
		padding: '25px 22px',
		position: 'relative',
	},
	moreMenu: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	name: {
		color: brandPrimaryText,
		fontSize: 18,
		marginBottom: 10,
		maxWidth: '100%',
	},
	publishButton: {},
	publishButtonContainer: {},
	section: {
		':not(:last-child)': {
			marginBottom: 10,
		},
	},
	sectionHorizontal: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 6,
	},
	sectionInner: {
		height: 55,
	},
	sectionInnerReporting: {
		alignItems: 'center',
		display: 'flex',
		height: 33,
	},
	sectionLabel: {
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: 1.1,
		marginBottom: 7,
		textTransform: 'uppercase',
	},
	sectionLabelReporting: {
		margin: 0,
	},
	SeeReport: {
		color: brandPrimary,
	},
	splitSection: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				width: '50%',
			},
			'*'
		),
	},
	statusSectionContent: {
		minHeight: 30,
	},
	statusSectionContentReporting: {
		fontSize: 11,
		marginLeft: 7,
	},
	stepsOverflowLabel: {
		color: '#AAAAAA',
		fontSize: 11,
	},
	trigger: {
		color: titles,
		fontSize: 14,
		marginBottom: 6,
		marginLeft: 5,
		maxWidth: '78%',
	},
	triggerIcon: {
		flexShrink: 0,
	},
	yoloModeIndicator: {
		...CssSelectors.allChildren({
			padding: '8px 20px 8px 21px',
		}),
		background: '#FFF1E7',
		color: '#4A4A4A',
		fontSize: '11px',
		lineHeight: 1.636,
		margin: '0px -25px -25px -22px',
	},
});
