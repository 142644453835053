import { IEventLoggingComponentProps } from '@AppModels/Logging';
import poweredByGiphyLogo from '../../assets/poweredByGiphyLogo.svg';

import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IFileAttachment } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, IModalContext, ImpersonationContextKey } from '../../../models';
import { IErrorMessageComponentProps } from '../../../models/AppState';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { IPixabayImageSearchOptions } from '../../../models/Pixabay';
import PixabayImageUrl from '../../assets/pixabay_logo.svg';
import { asModalComponent } from '../Modal';
import PoweredByGiphyImageUrl from '../richContent/GiphyChooser/poweredByGiphy.png';
import { MediaChooserContext, useMediaChooserContextController } from './context';
import { MediaChooserSearch, MediaChooserSuggestions } from './presentation';
import { styleSheet } from './styles';

export enum MediaType {
	Gif = 'gif',
	Image = 'image',
	Video = 'video',
}

interface IProps
	extends IEventLoggingComponentProps,
		IModalContext<IFileAttachment>,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps {
	className?: string;
	content?: string;
	imageOnly?: boolean; // When other media types are added this will be used to distinguish if the other queries and tabs should be used.
	onSave?: (img: IFileAttachment) => void;
	optionalImageName?: string;
	styles?: StyleDeclarationValue[];
	useBasicSearch?: boolean;
	imageSearchOptions?: IPixabayImageSearchOptions;
}

const MediaChooser: React.FC<IProps> = observer(props => {
	const { content, onSave, useBasicSearch = true, impersonationContext, imageSearchOptions } = props;
	const userSession = useUserSession();
	const [searchingMediaType, setSearchingMediaType] = React.useState(MediaType.Image);

	const context = useMediaChooserContextController({ impersonationContext, imageSearchOptions });
	const { setSelectedImage } = context;

	const onSettingMediaSearchType = (type: MediaType) => () => {
		setSearchingMediaType(type);
		// @ts-ignore
		setSelectedImage(null);
	};

	return (
		<div className={`${css(styleSheet.container, ...(props.styles || []))} pixabay-chooser ${props.className || ''}`}>
			<header className={css(styleSheet.header)}>
				<section className={css(styleSheet.headerTitle)}>
					<h2 className={css(styleSheet.suggestionHeader)}>Add Media</h2>
					{searchingMediaType === MediaType.Gif ? (
						<figure className={css(styleSheet.headerPoweredByGiphy)}>
							<picture>
								<img className={css(styleSheet.headerPoweredByGiphyImage)} src={PoweredByGiphyImageUrl} />
							</picture>
							<picture>
								<img className={css(styleSheet.headerPoweredByGiphyLogo)} src={poweredByGiphyLogo} />
							</picture>
						</figure>
					) : (
						<div className={css(styleSheet.headerPoweredBy)}>
							<div>Powered by</div>
							<a href='https://pixabay.com/' target='_blank' rel='noreferrer'>
								<img className={css(styleSheet.headerPoweredByImage)} src={PixabayImageUrl} />
							</a>
						</div>
					)}
				</section>
				<div className={css(styleSheet.headerTabs)}>
					<button
						className={css(styleSheet.mediaMenu, searchingMediaType === MediaType.Image && styleSheet.selectedMedia)}
						onClick={onSettingMediaSearchType(MediaType.Image)}
					>
						<span>Images</span>
					</button>
				</div>
			</header>
			<MediaChooserContext.Provider value={context}>
				<MediaChooserSearch optionalFileName={props.optionalImageName} onSave={onSave} mediaType={searchingMediaType} />
				{!useBasicSearch && userSession.account?.features?.contentGeneration?.enabled ? (
					<MediaChooserSuggestions content={content} mediaType={searchingMediaType} />
				) : null}
			</MediaChooserContext.Provider>
		</div>
	);
});

export const MediaChooserModal = asModalComponent(inject(ImpersonationContextKey)(MediaChooser), {
	className: css(styleSheet.modal),
	useDefaultHeader: true,
});
