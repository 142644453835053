import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEventLogging } from '../../../models/Logging';
import { useSystemTemplateTranslations } from '../../../queries/Templates';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { ISelectBoxOption, SelectBox } from '../SelectBox';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	disabled?: boolean;
	onTranslatedTemplateSelected?(template: Api.ITemplate): void;
	originalTemplate: Api.ITemplate;
	selectedTemplate?: Api.ITemplate;
}

export const OriginalContentOption: ISelectBoxOption<Api.Language> = {
	id: 'original',
	title: 'Original Content',
	// @ts-ignore
	value: null,
};

export function SystemTemplateTranslationSelector({
	disabled,
	onTranslatedTemplateSelected,
	originalTemplate,
	selectedTemplate,
}: IProps) {
	const { logApiError } = useEventLogging('SystemTemplateTranslationSelector');
	const {
		data: translatedTemplates,
		isError: hasError,
		isLoading,
	} = useSystemTemplateTranslations({
		// @ts-ignore
		id: originalTemplate?.id,
		onError: error => logApiError('SystemTemplateTranslations-Load', error),
	});
	const options = React.useMemo(() => {
		if (translatedTemplates?.length) {
			const result = translatedTemplates.map<ISelectBoxOption<Api.Language>>(t => ({
				id: t.id,
				title: t.languageDescription ?? t.language,
				value: t.language,
			}));
			if (originalTemplate) {
				return [OriginalContentOption].concat(result);
			}
			return result;
		}
		return [];
	}, [translatedTemplates, originalTemplate]);

	const onSelectionChanged = (option: ISelectBoxOption<Api.Language>) => {
		onTranslatedTemplateSelected?.(
			// @ts-ignore
			// @ts-ignore
			option.value === null ? originalTemplate : translatedTemplates.find(x => x.language === option.value)
		);
	};

	if (hasError || (!isLoading && !options?.length)) {
		return null;
	}

	return (
		<div className={css(baseStyleSheet.horizontalStack, styleSheet.container)}>
			<label>Translate to:</label>
			<SelectBox
				contentClassName={css(styleSheet.content)}
				className={css(styleSheet.select)}
				disabled={isLoading || disabled}
				leftAccessory={isLoading ? <LoadingSpinner type='tiny' /> : undefined}
				onSelectionChanged={onSelectionChanged}
				options={options}
				optionStyles={[styleSheet.option]}
				menuClassName={css(styleSheet.menu)}
				placeholder={<span className={css(styleSheet.placeholder)}>Choose Language</span>}
				selectedOption={options?.find(x => selectedTemplate?.language === x.value)}
				triggerClassName={` ${css(styleSheet.selectTrigger)}`}
			/>
		</div>
	);
}
