import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { useEmailComposerContext } from '../../../../../models/Email';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages } from '../../../../../models/hooks/appStateHooks';
import { ComposeSatisfactionSurveyFollowUpEmailViewModel } from '../../../../../viewmodels/AppViewModels';
import { grayIconFill } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { PopoverType, TinyPopover } from '../../../TinyPopover';
import { InfoIcon } from '../../../svgs/icons/InfoIcon';
import {
	FollowUpRecipientDiscriminator,
	IFollowUpRecipientDiscriminatorOption,
} from '../FollowUpRecipientDiscriminator';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const SatisfactionSurveyFollowUpOptions: React.FC<IProps> = props => {
	const { className, styles = [] } = props;
	const { emailComposer } = useEmailComposerContext<
		Api.ISurveyFollowUpOptions,
		Api.SatisfactionSurveyReportViewModel,
		ComposeSatisfactionSurveyFollowUpEmailViewModel
	>();
	// @ts-ignore
	const satisfactionSurveyReport: Api.SatisfactionSurveyReportViewModel = emailComposer.followUpSource;
	const { logApiError } = useEventLogging('SatisfactionSurveyFollowUpOptions');
	const errorMessages = useErrorMessages();

	const [options, setOptions] =
		// @ts-ignore
		React.useState<IFollowUpRecipientDiscriminatorOption<Api.ISatisfactionSurveyStatsSubtotal>[]>(null);
	const [loadingStats, setLoadingStats] = React.useState<boolean>(true);

	React.useEffect(() => {
		setLoadingStats(true);
		satisfactionSurveyReport
			.getStatsForDateRange()
			?.then(opResult => {
				setLoadingStats(false);
				// @ts-ignore
				const subtotalsForKnownContacts = opResult.value.subtotalsForKnownContacts || [];
				setOptions([
					{
						count: subtotalsForKnownContacts.reduce((count, x) => count + x.count, 0),
						id: 'all',
						// @ts-ignore
						representedObject: null,
						text: 'All recipients',
					},
					...subtotalsForKnownContacts
						.sort((a, b) => (a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0))
						.map<IFollowUpRecipientDiscriminatorOption<Api.ISatisfactionSurveyStatsSubtotal>>(x => {
							return {
								count: x.count,
								disabled: x.count === 0,
								id: `rating-${x.rating}`,
								representedObject: x,
								text: `${x.rating} star ratings`,
							};
						}),
				]);
			})
			?.catch(error => {
				logApiError('SatisfactionSurveyReportStatsLoad-Error', error);
				// @ts-ignore
				errorMessages.pushApiError(error);
				setLoadingStats(false);
			});
	}, [errorMessages, logApiError, satisfactionSurveyReport]);

	const [selectedOptions, setSelectedOptions] = React.useState<
		IFollowUpRecipientDiscriminatorOption<Api.ISatisfactionSurveyStatsSubtotal>[]
	>([]);
	const onSelectedOptionsChanged = React.useCallback(
		(o: IFollowUpRecipientDiscriminatorOption<Api.ISatisfactionSurveyStatsSubtotal>[]) => {
			const selected = selectedOptions || [];
			const indexOfSelectAll = o.findIndex(x => x.id === 'all');
			if (indexOfSelectAll >= 0) {
				if (!selected.find(x => x.id === 'all')) {
					setSelectedOptions(options);
					return;
				}
				o.splice(indexOfSelectAll, 1);
			} else {
				if (selected.find(x => x.id === 'all')) {
					setSelectedOptions([]);
					return;
				}
				if (o.length === options.length - 1) {
					setSelectedOptions(options);
					return;
				}
			}
			setSelectedOptions(o);
		},
		[selectedOptions, options]
	);

	React.useEffect(() => {
		const selected = selectedOptions?.find(x => x.id === 'all')
			? options.filter(x => !isNaN(x.representedObject?.rating))
			: selectedOptions;
		// @ts-ignore
		if (emailComposer.emailMessage.options.followUp) {
			// @ts-ignore
			emailComposer.emailMessage.options.followUp.surveyResponseFilter = {
				// @ts-ignore
				surveyId: satisfactionSurveyReport.survey?.id,
				// @ts-ignore
				...(emailComposer.emailMessage.options.followUp.surveyResponseFilter || {}),
				ratings: selected?.map(x => x.representedObject?.rating),
				_type: 'SatisfactionSurveyResponseFilterRequest',
			};
		}
		// @ts-ignore
		emailComposer.selectedFollowUpSubTotals = selected?.map(x => x.representedObject);
	}, [emailComposer, selectedOptions, options, satisfactionSurveyReport.survey?.id]);
	return (
		<div className={`${css(styleSheet.container, ...styles)} satisfaction-survey-followup-options ${className || ''}`}>
			<h4 className={css(styleSheet.header)}>
				Select Recipients to Follow-Up
				<TinyPopover
					anchor={
						<figure className={css(baseStyleSheet.flex, baseStyleSheet.itemsCenter)}>
							<InfoIcon fillColor={grayIconFill} />
						</figure>
					}
					anchorStyles={[styleSheet.infoPopoverAnchor]}
					dismissOnOutsideAction={true}
					toggleOnHover={true}
					autoCloseOtherPopoversOnHover={true}
					type={PopoverType.background}
					placement={['right', 'top', 'bottom']}
				>
					<p className={css(styleSheet.infoPopoverContent)}>
						Recipients who left multiple reviews will only receive a single email.
					</p>
				</TinyPopover>
			</h4>
			<div className={css(styleSheet.content)}>
				{loadingStats ? (
					<LoadingSpinner type='small' />
				) : (
					<FollowUpRecipientDiscriminator
						options={options}
						selectedOptions={selectedOptions}
						multiSelect={true}
						onSelectedOptionsChanged={onSelectedOptionsChanged}
					/>
				)}
			</div>
		</div>
	);
};
