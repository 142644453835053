import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const thumbProperties: React.CSSProperties = {
	appearance: 'none',
	background: '#FFFFFF',
	border: '2px solid #D3DBDE',
	borderRadius: '50%',
	boxShadow: '-2px 1px 2px rgba(140, 140, 140, 0.182819)',
	height: 20,
	width: 20,
};

export const styleSheet = StyleSheet.create({
	container: {
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginTop: 26,
			},
		}),
	},
	creativityHeader: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 14,
		justifyContent: 'space-between',
		marginBottom: 8,
	},
	creativitySlider: {
		'-webkit-appearance': 'none',
		'::-moz-range-thumb': thumbProperties as any,
		'::-webkit-slider-thumb': thumbProperties as any,
		appearance: 'none',
		background: '#D8D8D8',
		border: '1px solid #D3DBDE',
		borderRadius: 2,
		height: 4,
		outline: 'none',
		width: '100%',
	},
	inputLabel: {
		display: 'block',
		fontSize: 14,
		marginBottom: 8,
	},
	instructions: {
		fontSize: 12,
		height: 126,
		...CssSelectors.allChildren(
			{
				height: '100%',
			},
			'textarea'
		),
	},
	lengthDropdownTrigger: {
		maxWidth: 'unset',
	},
	selectorsContainer: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				width: '150px',
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				flexGrow: 1,
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	title: {
		fontSize: 16,
	},
});
