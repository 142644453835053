import { navigation, titles } from '../../../../styles/colors';
import { baseStyleSheet, Layouts } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const sectionSpacing = 26;
export const styleSheet = StyleSheet.create({
	container: {
		...Layouts.verticalStack(sectionSpacing),
		display: 'flex',
		flexDirection: 'column',
	},
	discriminator: {},
	emptyPlaceholder: {
		...Layouts.verticalStack(20),
		alignItems: 'center',
		color: navigation,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 14,
		justifyContent: 'center',
		marginTop: 30,
	},
	emptyPlaceholderGraphic: {
		height: 120,
		width: 'auto',
	},
	header: {
		color: titles,
		fontSize: 18,
		height: 30,
	},
	recipientsCount: {
		...baseStyleSheet.fontBold,
		fontSize: 14,
	},
	recipientsList: {
		flexGrow: 1,
		overflowY: 'auto',
	},
	timeRange: {
		color: titles,
		fontSize: 14,
	},
	timeRangeHeader: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	timeRangeInputs: {
		...Layouts.horizontalStack(10),
		alignItems: 'center',
		display: 'flex',
	},
});
