import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';
const fadeInKeyframes = {
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
};

export const styleSheet = StyleSheet.create({
	calendar: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderTop: '1px solid #D3DBDE',
		borderBottom: '1px solid #D3DBDE',
		...CssSelectors.allChildren(
			{
				maxWidth: 315,
				margin: '25px 0 17px',
			},
			'div'
		),
	},
	calendarInnerWrap: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		...CssSelectors.allChildren(
			{
				flex: '1 0 100%',
			},
			'div:last-child'
		),
	},
	dropdownTrigger: {
		border: '1px solid #D3DBDE',
		borderRadius: 8,
	},
	figure: {
		marginTop: 100,
		marginRight: 24,
		marginBottom: 50,
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 40,
		...CssSelectors.allChildren(
			{
				marginRight: 8,
			},
			'div:not(:last-child)'
		),
		...CssSelectors.allChildren(
			{
				width: 180,
				...CssSelectors.allChildren(
					{
						width: 180,
					},
					'button'
				),
			},
			'div'
		),
	},
	header: {
		justifyContent: 'center',
	},
	mainContainer: {
		maxWidth: 700,
		margin: '0 auto',
		width: '100%',
	},
	plannedCampaignListItem: {
		animationDelay: 'calc(var(--stagger-anim-order) * 100ms)',
		animationDuration: '300ms',
		animationFillMode: 'forwards',
		animationIterationCount: 1,
		animationName: [fadeInKeyframes],
		animationTimingFunction: 'ease-in-out',
		opacity: 0,
	},
	section: {
		...CssSelectors.allChildren(
			{
				margin: '0 0 5px 0',
				paddingTop: 37,
			},
			'h2'
		),
		...CssSelectors.allChildren(
			{
				margin: 0,
			},
			'p'
		),
	},
});
