import { StyleDeclarationValue, css } from 'aphrodite';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const EventRegistrationTriggerGraphic = ({ className, styles = [] }: IProps) => {
	return (
		<SvgIcon
			className={`manual-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<defs>
				<circle id='eventRegistrationGraphicPath1' cx='80' cy='80' r='80' />
				<linearGradient
					x1='50.1522038%'
					y1='-26.9879532%'
					x2='50.1522038%'
					y2='99.6955925%'
					id='eventRegistrationGraphicGradient3'
				>
					<stop stopColor='#FFFFFF' stopOpacity='0.148438354' offset='0%' />
					<stop stopColor='#00CCFF' stopOpacity='0.199778737' offset='100%' />
				</linearGradient>
				<circle id='eventRegistrationGraphicPath4' cx='80' cy='80' r='80' />
			</defs>
			<g id='eventRegistrationGraphicDesign' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g id='eventRegistrationGraphicAutomationType' transform='translate(-991.000000, -958.000000)'>
					<g id='eventRegistrationGraphicGroup48' transform='translate(921.000000, 934.000000)'>
						<g id='eventRegistrationGraphicGroup43' transform='translate(70.000000, 24.000000)'>
							<mask id='eventRegistrationGraphicMask2' fill='white'>
								<use xlinkHref='#eventRegistrationGraphicPath1' />
							</mask>
							<use id='eventRegistrationGraphicOval' fill='#FFFFFF' xlinkHref='#eventRegistrationGraphicPath1' />
							<mask id='eventRegistrationGraphicMask5' fill='white'>
								<use xlinkHref='#eventRegistrationGraphicPath4' />
							</mask>
							<use
								id='eventRegistrationGraphicOval'
								fill='url(#eventRegistrationGraphicGradient3)'
								xlinkHref='#eventRegistrationGraphicPath4'
							/>
							<g id='eventRegistrationGraphicGroup9' mask='url(#eventRegistrationGraphicMask5)'>
								<g transform='translate(74.494681, 17.100000)' />
							</g>
							<g id='eventRegistrationGraphicGroup46' mask='url(#eventRegistrationGraphicMask5)'>
								<g transform='translate(-30.130737, 29.000000)'>
									<path
										d='M78,0 L142,0 C146.418278,-8.11624501e-16 150,3.581722 150,8 L150,72 C150,76.418278 146.418278,80 142,80 L78,80 C73.581722,80 70,76.418278 70,72 L70,8 C70,3.581722 73.581722,5.41083001e-16 78,0 Z'
										id='eventRegistrationGraphicRectangle28'
										fill='#FFFFFF'
									/>
									<path
										d='M187,22 L215,22 C219.418278,22 223,25.581722 223,30 L223,58 C223,62.418278 219.418278,66 215,66 L187,66 C182.581722,66 179,62.418278 179,58 L179,30 C179,25.581722 182.581722,22 187,22 Z'
										id='eventRegistrationGraphicRectangle29'
										fill='#FFFFFF'
									/>
									<path
										d='M8,22 L36,22 C40.418278,22 44,25.581722 44,30 L44,58 C44,62.418278 40.418278,66 36,66 L8,66 C3.581722,66 2.705415e-16,62.418278 0,58 L0,30 C-5.41083001e-16,25.581722 3.581722,22 8,22 Z'
										id='eventRegistrationGraphicRectangle30'
										fill='#FFFFFF'
									/>
									<path
										d='M75,0 L145,0 C147.761424,-5.07265313e-16 150,2.23857625 150,5 L150,14.8148148 L150,14.8148148 L70,14.8148148 L70,5 C70,2.23857625 72.2385763,3.38176876e-16 75,0 Z'
										id='eventRegistrationGraphicRectangle1'
										fill='#0066FF'
									/>
									<path
										d='M184,22 L218,22 C220.761424,22 223,24.2385763 223,27 L223,30.1481481 L223,30.1481481 L179,30.1481481 L179,27 C179,24.2385763 181.238576,22 184,22 Z'
										id='eventRegistrationGraphicRectangle14'
										fill='#99DB56'
									/>
									<path
										d='M5,22 L39,22 C41.7614237,22 44,24.2385763 44,27 L44,30.1481481 L44,30.1481481 L0,30.1481481 L0,27 C-3.38176876e-16,24.2385763 2.23857625,22 5,22 Z'
										id='eventRegistrationGraphicRectangle15'
										fill='#E072BE'
									/>
								</g>
							</g>
							<g id='eventRegistrationGraphicGroup45' mask='url(#eventRegistrationGraphicMask5)'>
								<g transform='translate(51.000000, 54.500000)'>
									<path
										d='M4,73.5 L54,73.5 C56.209139,73.5 58,75.290861 58,77.5 L58,90.5 C58,92.709139 56.209139,94.5 54,94.5 L4,94.5 C1.790861,94.5 1.3527075e-16,92.709139 0,90.5 L0,77.5 C-2.705415e-16,75.290861 1.790861,73.5 4,73.5 Z'
										id='eventRegistrationGraphicRectangle28'
										fill='#FFFFFF'
									/>
									<rect
										id='eventRegistrationGraphicRectangle16'
										fill='#00CCFF'
										x='29'
										y='86.5'
										width='23'
										height='1'
										rx='0.5'
									/>
									<rect
										id='eventRegistrationGraphicRectangle17'
										fill='#00CCFF'
										x='29'
										y='81.5'
										width='15'
										height='1'
										rx='0.5'
									/>
									<path
										d='M6.4468825,87.4325448 L5.27083247,86.2392946 C4.91138772,85.8745928 4.89966608,85.2925498 5.24413604,84.9136721 C5.57511348,84.5496346 6.13853441,84.5228341 6.50257192,84.8538115 C6.50793844,84.8586907 6.51324517,84.8636352 6.51849094,84.868644 L7.91244344,86.1996201 L7.91244344,86.1996201 L12.4218816,79.8629615 C12.6962733,79.4773865 13.2312821,79.3872546 13.6168571,79.6616463 C13.6278266,79.6694527 13.6386106,79.6775166 13.6492001,79.6858311 C14.0455994,79.9970717 14.1298723,80.5637378 13.8411983,80.9768605 L9.51074277,87.1741958 C8.87806694,88.0796211 7.63118957,88.3007283 6.72576422,87.6680525 C6.62581928,87.5982148 6.53247003,87.5193841 6.4468825,87.4325448 Z'
										id='eventRegistrationGraphicFill1339'
										fill='#99DB56'
									/>
									<path
										d='M21.3045349,37.3178118 L7.47145445,25.8343073 C6.62157078,25.1287781 6.50454841,23.8678668 7.21007766,23.0179831 C7.27192484,22.9434817 7.33910069,22.87357 7.41107521,22.8087997 L10.448801,20.0751294 C11.1700885,19.4260378 12.2532406,19.3883394 13.0179105,19.9857131 L21.7386124,26.7984803 L21.7386124,26.7984803 L45.1959979,-0.170672601 C45.8477173,-0.91995982 46.9505179,-1.07756525 47.7860285,-0.540823709 L51.3106954,1.72346247 C52.2400239,2.32047366 52.5094203,3.55781644 51.9124091,4.48714491 C51.8634529,4.56335188 51.8093762,4.63614509 51.7505527,4.7050218 L24.1028503,37.0778226 C23.3925899,37.9094691 22.1460293,38.0163767 21.3045349,37.3178118 Z'
										id='eventRegistrationGraphicFill1339'
										fillOpacity='0.154022857'
										fill='#00CCFF'
									/>
								</g>
							</g>
							<path
								d='M171,109 C173.097921,127.387451 170.097921,139.387451 162,145 C153.902079,150.612549 141.170044,152.445882 123.803894,150.5'
								id='eventRegistrationGraphicPath7'
								stroke='#00CCFF'
								strokeDasharray='4'
								mask='url(#eventRegistrationGraphicMask5)'
							/>
							<line
								x1='78.8298212'
								y1='113.026733'
								x2='78.5'
								y2='126.5'
								id='eventRegistrationGraphicPath8'
								stroke='#00CCFF'
								strokeDasharray='3'
								mask='url(#eventRegistrationGraphicMask5)'
							/>
							<line
								x1='145.5'
								y1='71.5'
								x2='122.5'
								y2='71.5'
								id='eventRegistrationGraphicPath9'
								stroke='#00CCFF'
								strokeDasharray='3'
								mask='url(#eventRegistrationGraphicMask5)'
							/>
							<line
								x1='36.5'
								y1='71.5'
								x2='15.5'
								y2='71.5'
								id='eventRegistrationGraphicPath10'
								stroke='#00CCFF'
								strokeDasharray='3'
								mask='url(#eventRegistrationGraphicMask5)'
							/>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
