import './styles.less';
import * as React from 'react';

interface IProps {
	className?: string;
	draggingOverlay?: React.ReactNode;
	onDragEnter?: React.DragEventHandler<HTMLElement>;
	onDragLeave?: React.DragEventHandler<HTMLElement>;
	onDragOver?: React.DragEventHandler<HTMLElement>;
	onFilesDropped?(files: File[]): void;
	/** Lower case withouth "." */
	supportedFileExtensions?: string[];
	supportMultiple?: boolean;
	title?: string;
}

interface IState {
	isDraggingOver?: boolean;
}

export class FilesDropTarget extends React.Component<IProps, IState> {
	public static defaultProps: IProps = {
		supportMultiple: true,
	};
	public state: IState = {};

	public render() {
		const { title } = this.props;
		return (
			<div
				className={`files-drop-target ${this.state.isDraggingOver ? 'dragging-over' : ''} ${
					this.props.className || ''
				}`}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDragOver={this.onDragOver}
				onDrop={this.onFilesDropped}
				title={title}
			>
				{this.props.children}
			</div>
		);
	}

	private onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (this.props.onDragOver) {
			this.props.onDragOver(e);
		}
	};

	private onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({
			isDraggingOver: true,
		});

		if (this.props.onDragEnter) {
			this.props.onDragEnter(e);
		}
	};

	private onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		this.setState({
			isDraggingOver: false,
		});

		if (this.props.onDragLeave) {
			this.props.onDragLeave(e);
		}
	};

	private onFilesDropped = (e: React.DragEvent<HTMLDivElement>) => {
		const { supportedFileExtensions } = this.props;
		e.preventDefault();
		e.stopPropagation();

		this.setState({
			isDraggingOver: false,
		});

		if (!!this.props.onFilesDropped && !!e.dataTransfer && !!e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			let files: File[] = [];
			for (let i = 0; i < e.dataTransfer.files.length; i++) {
				// @ts-ignore
				files[i] = e.dataTransfer.files.item(i);
			}

			if (files.length > 1 && !this.props.supportMultiple) {
				files = [files[0]];
			}

			if (!!supportedFileExtensions && supportedFileExtensions.length > 0 && files.length > 0) {
				files = files.filter(x => {
					const comps = x.name ? x.name.split('.') : [];
					const ext = comps.length > 1 ? comps[comps.length - 1] : null;
					return ext ? supportedFileExtensions.indexOf(ext.toLocaleLowerCase()) >= 0 : false;
				});
			}

			if (files.length > 0) {
				this.props.onFilesDropped(files);
			}
		}
	};
}
