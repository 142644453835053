import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, titles } from '../../../styles/colors';
import { CssSelectors, Layouts, baseStyleSheet, cardStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	addTagButton: {
		alignItems: 'center',
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		width: '100%',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	addTagDropdownFooter: {
		borderTop: '1px #D8D8D8 solid',
		boxSizing: 'border-box',
		padding: '8px 20px',
	},
	autocompleteBox: {
		width: 250,
	},
	bold: {
		...baseStyleSheet.fontBold,
	},
	ctaButton: {
		marginTop: 14,
		color: brandPrimaryHover,
	},
	cancelOnReplyCheckbox: {
		marginTop: 35,
	},
	cancelOnReplyCheckboxText: {
		width: 500,
	},
	cardBody: {
		boxSizing: 'border-box',
		padding: 20,
	},
	cardHeader: {
		boxSizing: 'border-box',
		color: '#4a4a4a',
		fontSize: 14,
		padding: '8px 20px 20px 20px',
		...Layouts.verticalStack(14),
	},
	cardHeaderText: {
		margin: 0,
	},
	checkboxText: {
		color: '#4a4a4a',
		fontSize: 14,
		marginBottom: 2,
		marginLeft: 16,
		width: 300,
	},
	container: {
		...cardStyle,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 14,
		overflow: 'visible',
		padding: '14px 0 0',
		...CssSelectors.allChildren(
			{
				borderTop: '1px solid #F2F2F2',
				boxSizing: 'border-box',
			},
			':not(:first-child)'
		),
	},
	eventRegistrationTriggerDisclaimer: {
		marginTop: 20,
	},
	noMargin: {
		margin: 0,
	},
	iconSpan: {
		margin: '4px 8px 0px 4px',
	},
	searchBox: {
		backgroundColor: '#f9f9f9',
		border: '1px solid #CCC',
		borderRadius: 3,
		color: '#4a4a4a',
		display: 'inline-block',
		fontSize: 14,
		height: 40,
		outline: 'none',
		padding: 6,
		verticalAlign: 'middle',
		width: 458,
	},
	textingTriggerCheckboxText: {
		width: 458,
	},
	triggerSelect: {
		marginTop: 14,
		maxWidth: '100%',
		width: '100%',
	},
	triggerSelectOption: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		padding: '5px 15px',
		...CssSelectors.allChildren(
			{
				flexShrink: 0,
			},
			'.svg-icon'
		),
		...CssSelectors.allChildren(
			{
				flexGrow: 1,
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	triggerSelectOptionSelectTrigger: {
		color: titles,
		padding: 0,
	},
	triggerSettingsCheckbox: {
		marginTop: 14,
	},
	triggerSettingsCheckboxText: {
		margin: 0,
		minWidth: 400,
	},
	triggerSettingsTitle: {
		marginBottom: 20,
	},
	triggerSettingsWarning: {
		marginLeft: 27,
		marginRight: 27,
		marginTop: 10,
	},
});
