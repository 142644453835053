import { brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const SplitViewMasterWidth = 370;
const SplitViewMasterHeaderHeight = 150;

export const styleSheet = StyleSheet.create({
	campaignCardContainerStyles: {
		margin: ' 20px 0 0px 10px',
		paddingRight: 10,
		width: 'calc(100%-20px)',
	},
	campaignSearch: {
		boxSizing: 'border-box',
		height: 'calc(100% - 150px)',
		padding: '20px 10px 20px 20px',
		...CssSelectors.allDescendants(
			{
				height: 'calc(100vh - 416px)', // accounts for "new email, search, filters"
			},
			'.card-container'
		),
	},
	campaignSelector: {
		boxSizing: 'border-box',
		height: `calc(100% - ${SplitViewMasterHeaderHeight}px)`,
		padding: '10px 0 0 0 ',
	},
	campaignSelectStyles: {
		margin: '0 10px',
		width: 'calc(100% - 20px)',
	},
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
	},
	createNewTemplateButton: {
		alignItems: 'center',
		color: brandSecondary,
		display: 'flex',
		fontSize: 18,
		width: 210,
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	createNewTemplateIcon: {
		flexShrink: 0,
		height: 60,
		width: 60,
	},
	splitViewDetail: {
		boxSizing: 'border-box',
		padding: '30px 30px 0 30px',
		width: `calc(100% - ${SplitViewMasterWidth}px)`,
	},
	splitViewMaster: {
		borderRight: '1px solid #D3DBDE',
		boxSizing: 'border-box',
		height: '100%',
		width: SplitViewMasterWidth,
	},
	splitViewMasterHeader: {
		alignItems: 'center',
		borderBottom: '1px solid #D3DBDE',
		boxSizing: 'border-box',
		display: 'flex',
		height: SplitViewMasterHeaderHeight,
		justifyContent: 'center',
	},
	templateEditor: {
		height: '100%',
	},
	templateList: {
		height: `calc(100% - ${SplitViewMasterHeaderHeight}px)`,
	},
});
