import * as Api from '@ViewModels';
import * as React from 'react';
import { SocialMediaPostViewModel } from '../../../../extViewmodels';
import { useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { ImpersonationContextViewModel } from '../../../../viewmodels/AdminViewModels';
import { useEditSocialMediaPostContext } from '../../../containers/socialMedia/EditSocialMediaPost/context';
import { useVideoAttachmentFromSystemJob, useVideoUploadMutation } from '../SocialMediaImageSearchAndDisplay/hooks';

export function useVideoFileUploader({
	impersonationContext,
	onError,
	post,
}: {
	impersonationContext?: ImpersonationContextViewModel;
	onError?: (err: Api.IOperationResultNoValue) => void;
	post?: SocialMediaPostViewModel;
}) {
	const { schedulePost, updatePost, selectedCreator } = useEditSocialMediaPostContext();

	// @ts-ignore
	const [scheduledPost, setScheduledPost] = React.useState<SocialMediaPostViewModel>(post);
	const [draftCreated, setDraftCreated] = React.useState<boolean>(false);
	React.useEffect(() => {
		setDraftCreated(false);
	}, [post]);
	const [jobId, setJobId] = React.useState<string>();
	const toaster = useToaster();
	const userSession = useUserSession();

	const videoUploadMutation = useVideoUploadMutation({
		onError: err => {
			// @ts-ignore
			toaster.push({
				message: err?.systemMessage ?? 'Something went wrong while uploading the video.',
				type: 'errorMessage',
			});
			onError?.(err);
		},
		onSuccess: setJobId,
	});
	// @ts-ignore
	const { videoAttachment, errorMessage, resetErrorMessage } = useVideoAttachmentFromSystemJob({ jobId });
	const isLoading = videoUploadMutation.isLoading || (Boolean(jobId) && !videoAttachment && !errorMessage);

	const uploadVideo = React.useCallback(
		async (file: File, videoElm: HTMLVideoElement) => {
			let postId = scheduledPost?.id;

			try {
				if (postId) {
					// @ts-ignore
					await updatePost(scheduledPost);
				} else {
					// save draft first
					// @ts-ignore
					const [postViewModel] = await schedulePost(null, null, impersonationContext);
					postId = postViewModel.id;
					// store vm so we can return it when done
					setScheduledPost(postViewModel);
					setDraftCreated(true);
				}

				videoUploadMutation.mutate({
					duration: videoElm.duration,
					file,
					// @ts-ignore
					forUserId:
						Boolean(selectedCreator?.id) && selectedCreator?.id !== userSession.user?.id
							? selectedCreator?.id
							: undefined,
					height: videoElm.videoHeight,
					// @ts-ignore
					postId,
					width: videoElm.videoWidth,
				});
			} catch (ex) {
				onError?.(Api.asApiError(ex));
			}
		},
		[
			impersonationContext,
			onError,
			scheduledPost,
			schedulePost,
			selectedCreator?.id,
			updatePost,
			userSession.user?.id,
			videoUploadMutation,
		]
	);

	React.useEffect(() => {
		// clear out job on success or error
		// @ts-ignore
		setJobId(null);
	}, [videoAttachment, errorMessage]);
	return {
		draftCreated,
		errorMessage,
		isLoading,
		resetErrorMessage,
		scheduledPost,
		uploadVideo,
		videoAttachment,
	} as const;
}
