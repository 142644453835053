import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const NewPersonalClientAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [] } = props;
	return (
		<SvgIcon
			className={`new-personal-client-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={172}
			width={172}
		>
			<defs>
				<filter
					id='newPersonalClientAutomationTriggerGraphicFilter'
					width='111.2%'
					height='111.2%'
					x='-5.6%'
					y='-5.6%'
					filterUnits='objectBoundingBox'
				>
					<feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='3' />
					<feColorMatrix
						in='shadowBlurOuter1'
						values='0 0 0 0 0.54424426   0 0 0 0 0.54424426   0 0 0 0 0.54424426  0 0 0 0.100033967 0'
					/>
				</filter>
				<linearGradient
					id='newPersonalClientAutomationTriggerGraphicGradient'
					x1='-14.748%'
					x2='60.229%'
					y1='106.162%'
					y2='23.78%'
				>
					<stop offset='0%' stopColor='#00D5F5' stopOpacity='0' />
					<stop offset='100%' stopColor='#00E82C' />
				</linearGradient>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(6 6)'>
				<mask id='newPersonalClientAutomationTriggerGraphicMask' fill='#fff'>
					<circle cx='80' cy='80' r='80' />
				</mask>
				<circle fill='#000' filter='url(#newPersonalClientAutomationTriggerGraphicFilter)' cx='80' cy='80' r='80' />
				<circle fill='#DBF2C3' cx='80' cy='80' r='80' />
				<g mask='url(#newPersonalClientAutomationTriggerGraphicMask)'>
					<g transform='translate(4 42)'>
						<polygon
							fill='url(#newPersonalClientAutomationTriggerGraphicGradient)'
							points='24.762 1 0 53.615 0 114.203 115 123 131 83.909'
							opacity='.225'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,68 L25,83 C42.9468955,83 43.3085155,83 63.4925373,83 C72.2386346,83 72.8496325,83 77,83 C77,74.7157288 70.2842712,68 62,68 L25,68 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,68 L25,78.952381 C42.9468955,78.952381 43.3085155,78.952381 63.4925373,78.952381 C69.4392038,78.952381 73.9315777,79.9121397 76.9590104,81.8826137 C76.3873966,74.1201774 69.9084203,68 62,68 L25,68 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,68 L25,74.9047619 C42.9468955,74.9047619 43.3085155,74.9047619 63.4925373,74.9047619 C69.2769119,74.9047619 73.7241337,76.6993926 76.7520898,80.2695772 C75.4683115,73.2889959 69.3518113,68 62,68 L25,68 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M25,1 L25,71 C42.9468955,71 43.3085155,71 63.4925373,71 C69.4651783,71 73.9901996,73.766211 77,79.1897947 L77,16 C77,7.71572875 70.2842712,1 62,1 L25,1 Z'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,68 L78,83 C95.9468955,83 96.3085155,83 116.492537,83 C125.238635,83 125.849633,83 130,83 C130,74.7157288 123.284271,68 115,68 L78,68 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,68 L78,78.952381 C95.9468955,78.952381 96.3085155,78.952381 116.492537,78.952381 C122.439204,78.952381 126.931578,79.9121397 129.95901,81.8826137 C129.387397,74.1201774 122.90842,68 115,68 L78,68 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,68 L78,74.9047619 C95.9468955,74.9047619 96.3085155,74.9047619 116.492537,74.9047619 C122.276912,74.9047619 126.724134,76.6993926 129.75209,80.2695772 C128.468311,73.2889959 122.351811,68 115,68 L78,68 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<path
							fill='#FFF'
							stroke='#00AAE8'
							strokeWidth='2'
							d='M78,1 L78,71 C95.9468955,71 96.3085155,71 116.492537,71 C122.465178,71 126.9902,73.766211 130,79.1897947 L130,16 C130,7.71572875 123.284271,1 115,1 L78,1 Z'
							transform='matrix(-1 0 0 1 208 0)'
						/>
						<polygon fill='#00AAE8' points='54 75 54 88 58 86.375 62 88 62 75' />
						<g transform='translate(40 10)'>
							<circle cx='9.5' cy='9.5' r='9.5' fill='#FFF' stroke='#00AAE8' strokeWidth='2' />
							<g
								stroke='#00AAE8'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								transform='translate(5.542 4.75)'
							>
								<path d='M7.67676768,8.36683007 L7.67676768,7.45615468 C7.67676768,6.45025043 6.81751619,5.63480392 5.75757576,5.63480392 L1.91919192,5.63480392 C0.85925149,5.63480392 1.06536553e-16,6.45025043 0,7.45615468 L0,8.36683007' />
								<ellipse cx='4.03' cy='1.821' rx='1.919' ry='1.821' />
							</g>
						</g>
						<rect width='17' height='2' x='41' y='35' fill='#00AAE8' rx='1' />
						<rect width='12' height='2' x='41' y='40' fill='#00AAE8' rx='1' />
						<rect width='5' height='2' x='39' y='48' fill='#DBF2C3' rx='1' />
						<rect width='8' height='2' x='46' y='48' fill='#DBF2C3' rx='1' />
						<rect width='8' height='2' x='39' y='52' fill='#DBF2C3' rx='1' />
						<rect width='6' height='2' x='55' y='48' fill='#DBF2C3' rx='1' />
						<rect width='6' height='2' x='49' y='52' fill='#DBF2C3' rx='1' />
					</g>
				</g>
				<g fill='#00AAE8' mask='url(#newPersonalClientAutomationTriggerGraphicMask)'>
					<path
						d='M22.9987,9 L22.8147,9 C22.3997,7.838 21.3007,7 19.9987,7 C18.6967,7 17.5977,7.838 17.1837,9 L9.81470002,9 C9.39970002,7.838 8.30070002,7 6.99870002,7 C5.69670002,7 4.59770002,7.838 4.18370002,9 L2.99870002,9 L2.99870002,8 C2.99870002,6.897 3.89570002,6 4.99870002,6 L22.9987,6 L22.9987,9 Z M19.9987,11 C19.4477,11 18.9987,10.551 18.9987,10 C18.9987,9.449 19.4477,9 19.9987,9 C20.5497,9 20.9987,9.449 20.9987,10 C20.9987,10.551 20.5497,11 19.9987,11 L19.9987,11 Z M6.99870002,11 C6.44770002,11 5.99870002,10.551 5.99870002,10 C5.99870002,9.449 6.44770002,9 6.99870002,9 C7.54970002,9 7.99870002,9.449 7.99870002,10 C7.99870002,10.551 7.54970002,11 6.99870002,11 L6.99870002,11 Z M9.61670002,2 L13.9987,2 L13.9987,4 L8.61670002,4 L9.61670002,2 Z M19.9987,4 L15.9987,4 L15.9987,2 L18.4987,2 L19.9987,4 Z M22.9987,4 L22.4987,4 L19.7987,0.4 C19.6097,0.148 19.3137,0 18.9987,0 L8.99870002,0 C8.61970002,0 8.27370002,0.214 8.10470002,0.553 L6.38070002,4 L4.99870002,4 C2.79270002,4 0.998700024,5.794 0.998700024,8 L0.998700024,10 C0.998700024,10.552 1.44670002,11 1.99870002,11 L4.18370002,11 C4.59770002,12.162 5.69670002,13 6.99870002,13 C8.30070002,13 9.39970002,12.162 9.81470002,11 L17.1837,11 C17.5977,12.162 18.6967,13 19.9987,13 C21.3007,13 22.3997,12.162 22.8147,11 L23.9987,11 C24.5507,11 24.9987,10.552 24.9987,10 L24.9987,6 C24.9987,4.897 24.1017,4 22.9987,4 L22.9987,4 Z'
						transform='translate(95 85)'
					/>
				</g>
				<g fill='#00AAE8' mask='url(#newPersonalClientAutomationTriggerGraphicMask)'>
					<g transform='translate(97 54)'>
						<path d='M11.9987,10 C11.9987,10.497 11.6667,10.928 10.9987,10.995 C10.3297,10.927 9.99870002,10.495 9.99870002,10 C9.99870002,9.449 10.4477,9 10.9987,9 C11.5497,9 11.9987,9.449 11.9987,10 M7.99870002,10 C7.99870002,11.142 8.63570002,12.14 9.59170002,12.643 L8.69770002,16 L13.2997,16 L12.4057,12.643 C13.3617,12.14 13.9987,11.142 13.9987,10 C13.9987,8.346 12.6527,7 10.9987,7 C9.34470002,7 7.99870002,8.346 7.99870002,10' />
						<path d='M16.9987,9 C16.4467,9 15.9987,9.448 15.9987,10 L15.9987,18 L5.99870002,18 L5.99870002,10 C5.99870002,9.448 5.55070002,9 4.99870002,9 L4.41270002,9 L10.9987,2.414 L17.5847,9 L16.9987,9 Z M20.7057,9.293 L11.7057,0.293 C11.3147,-0.098 10.6827,-0.098 10.2917,0.293 L1.29170002,9.293 C1.00570002,9.579 0.919700024,10.009 1.07470002,10.383 C1.22970002,10.757 1.59470002,11 1.99870002,11 L3.99870002,11 L3.99870002,19 C3.99870002,19.552 4.44670002,20 4.99870002,20 L16.9987,20 C17.5507,20 17.9987,19.552 17.9987,19 L17.9987,11 L19.9987,11 C20.4027,11 20.7677,10.757 20.9227,10.383 C21.0777,10.009 20.9917,9.579 20.7057,9.293 L20.7057,9.293 Z' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
