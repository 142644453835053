import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	childRoute: {
		boxSizing: 'border-box',
		height: '100%',
		overflow: 'auto',
		padding: 20,
	},
	container: {
		height: '100%',
		width: '100%',
	},
});
