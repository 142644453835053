import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import {
	IMainContainerBackgroundContext,
	IMainContainerBackgroundContextSetters,
	MainContainerBackgroundContext,
	MainContainerBackgroundSettersContext,
} from './context';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const MainContainerBackground: React.FC<IProps> = props => {
	const { className, styles = [], ...restProps } = props;
	// @ts-ignore
	const [contextClassName, setContextClassName] = React.useState<string>(null);
	const [contextStyles, setContextStyles] = React.useState<StyleDeclarationValue[]>([]);
	const [context, setContext] = React.useState<IMainContainerBackgroundContext>({
		className: contextClassName,
		styles: contextStyles,
	});
	// @ts-ignore
	const settersContext = React.useRef<IMainContainerBackgroundContextSetters>({
		setClassName: setContextClassName,
		setStyles: setContextStyles,
	}).current;

	React.useEffect(() => {
		setContext(v => ({ ...v, className: contextClassName }));
	}, [contextClassName]);

	React.useEffect(() => {
		setContext(v => ({ ...v, styles: contextStyles }));
	}, [contextStyles]);

	return (
		<MainContainerBackgroundContext.Provider value={context}>
			<MainContainerBackgroundSettersContext.Provider value={settersContext}>
				<div
					{...restProps}
					className={`${css(...styles, ...(contextStyles || []))} ${className || ''} ${contextClassName || ''}`}
				/>
			</MainContainerBackgroundSettersContext.Provider>
		</MainContainerBackgroundContext.Provider>
	);
};

export function useDarkMainContainerBackground() {
	const { setClassName } = React.useContext(MainContainerBackgroundSettersContext) || {};
	React.useEffect(() => {
		setClassName?.('main-container-dark-background');
		return () => {
			// @ts-ignore
			setClassName?.(null);
		};
	}, [setClassName]);
}

export const DarkMainContainerBackground: React.FC = () => {
	useDarkMainContainerBackground();
	// @ts-ignore
	return null as JSX.Element;
};
