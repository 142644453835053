import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const NewContactIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`new-contact-icon ${className || ''}`} height={24} width={24}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g fill={fillColor || '#F9F9F9'} transform='translate(-1132.000000, -485.000000)'>
					<path d='M1143.90889,498 C1146.39353,498 1148.42929,499.923041 1148.54047,502.346627 L1148.54525,502.555556 L1148.54525,504.333333 C1148.54525,504.885618 1148.09754,505.333333 1147.54525,505.333333 C1147.03242,505.333333 1146.60975,504.947293 1146.55198,504.449954 L1146.54525,504.333333 L1146.54525,502.555556 C1146.54525,501.203342 1145.45856,500.088784 1144.07615,500.005049 L1143.90889,500 L1136.63616,500 C1135.23136,500 1134.09064,501.062549 1134.00497,502.394481 L1133.9998,502.555556 L1133.9998,504.333333 C1133.9998,504.885618 1133.55208,505.333333 1132.9998,505.333333 C1132.48696,505.333333 1132.06429,504.947293 1132.00653,504.449954 L1131.9998,504.333333 L1131.9998,502.555556 C1131.9998,500.104529 1133.96655,498.113403 1136.42433,498.004676 L1136.63616,498 L1143.90889,498 Z M1140.63616,487 C1143.19179,487 1145.27253,489.034499 1145.27253,491.555556 C1145.27253,494.076612 1143.19179,496.111111 1140.63616,496.111111 C1138.08054,496.111111 1135.9998,494.076612 1135.9998,491.555556 C1135.9998,489.034499 1138.08054,487 1140.63616,487 Z M1153.5,487 L1153.5,490 L1156.5,490 L1156.5,492 L1153.5,492 L1153.5,495 L1151.5,495 L1151.5,492 L1148.5,492 L1148.5,490 L1151.5,490 L1151.5,487 L1153.5,487 Z M1140.63616,489 C1139.17517,489 1137.9998,490.149254 1137.9998,491.555556 C1137.9998,492.961858 1139.17517,494.111111 1140.63616,494.111111 C1142.09715,494.111111 1143.27253,492.961858 1143.27253,491.555556 C1143.27253,490.149254 1142.09715,489 1140.63616,489 Z' />
				</g>
			</g>
		</SvgIcon>
	);
};
