import { css } from 'aphrodite';
import { CameraIcon } from '../svgs/icons/CameraIcon';
import { styleSheet } from './styles';
export const ImageUploadButtons = ({
	hasImage,
	onClickFreeImage,
	onClickMedia,
}: {
	hasImage: boolean;
	onClickFreeImage: () => void;
	onClickMedia: () => void;
}) => {
	return (
		<>
			<div className={css(styleSheet.addMediaContainer, styleSheet.contentContainers)}>
				<div>
					<button
						type='button'
						className={css(styleSheet.iconButtons)}
						disabled={hasImage}
						onClick={onClickFreeImage}
						title={hasImage ? 'Only one image is supported at this time' : undefined}
					>
						<CameraIcon />
						<div className={css(styleSheet.freeImage)}> Free Images</div>
					</button>
					<div className={css(styleSheet.sourceMention)}>Royalty free image library</div>
				</div>
				<div>
					<button
						type='button'
						className={css(styleSheet.ownImage)}
						disabled={hasImage}
						onClick={onClickMedia}
						title={hasImage ? 'Only one image is supported at this time' : undefined}
					>
						+ Upload Media
					</button>
					<div className={css(styleSheet.sourceMention)}>Choose file from your computer</div>
				</div>
			</div>
		</>
	);
};
