import { brandSecondary } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	bodyContent: {
		padding: '36px 20px 16px 20px',
		textAlign: 'center',
	},
	container: {
		width: 400,
	},
	finishButton: {
		textDecoration: 'none',
	},
	footer: {
		marginTop: 40,
	},
	header: {
		color: brandSecondary,
		fontSize: 18,
		lineHeight: '22px',
	},
	loading: {},
	subtitle: {
		color: '#858585',
		fontSize: 12,
		marginTop: 14,
	},
	title: {
		color: '#4A4A4A',
		fontSize: 14,
		marginTop: 26,
	},
});
