import { CSSProperties, StyleDeclarationValue, StyleSheet } from 'aphrodite';
import { darkGrayFontColor } from '../../../../web/styles/colors';
import { DefaultBorder } from '../../../../web/styles/styles';

const ColumnSpacing = 60;
const FirstColumnSpacing = 30;
const ActionsColumnWidth = 70;
const CreationDateColumnWidth = 70;
const IndustriesColumnWidth = 180;
const GripperColumnWidth = 12;
export const ActionsColumnStyle: StyleDeclarationValue & CSSProperties = {
	flexShrink: 0,
	marginLeft: ColumnSpacing,
	width: ActionsColumnWidth,
};
export const CreationDateColumnStyle: StyleDeclarationValue & CSSProperties = {
	flexShrink: 0,
	marginLeft: ColumnSpacing,
	width: CreationDateColumnWidth,
};
export const IndustriesColumnStyle: StyleDeclarationValue & CSSProperties = {
	flexShrink: 0,
	marginLeft: ColumnSpacing,
	width: IndustriesColumnWidth,
};
export const GripperColumnStyle: StyleDeclarationValue & CSSProperties = {
	flexShrink: 0,
	width: GripperColumnWidth,
};
export const NameColumnStyle: StyleDeclarationValue & CSSProperties = {
	display: 'flex',
	flexGrow: 1,
	marginLeft: FirstColumnSpacing,
};

export const styleSheet = StyleSheet.create({
	actionsCol: ActionsColumnStyle,
	container: {},
	creationDateCol: CreationDateColumnStyle,
	gripperCol: GripperColumnStyle,
	header: {
		alignItems: 'center',
		borderBottom: DefaultBorder,
		boxSizing: 'border-box',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 11,
		letterSpacing: '1.1px',
		padding: '18px 0',
		textTransform: 'uppercase',
	},
	industriesCol: IndustriesColumnStyle,
	item: {
		borderBottom: DefaultBorder,
	},
	loadingSpinner: {
		marginTop: 20,
	},
	nameCol: NameColumnStyle,
});
