import { SliderArrowLeft, IProps as SliderProps } from '../../svgs/icons/SliderArrowLeft';
import { SliderArrowRight } from '../../svgs/icons/SliderArrowRight';
import { useEmblaSlider } from '../hooks/useEmblaSlider';
import { styleSheet } from './styles';
import { StyleDeclaration, css } from 'aphrodite';
import * as React from 'react';

export function DashboardCarousel({
	sliderLeftProps,
	sliderRightProps,
	buttonColumnStyleDeclaration,
	children,
	visibleSlides = 1,
}: {
	sliderLeftProps?: SliderProps;
	sliderRightProps?: SliderProps;
	buttonColumnStyleDeclaration?: StyleDeclaration;
	children: JSX.Element[];
	visibleSlides?: number;
}) {
	const { emblaRef, handleNext, handlePrev, nextBtnEnabled, prevBtnEnabled } = useEmblaSlider();

	return (
		<div className={css(styleSheet.root)}>
			<div className={css(styleSheet.buttonColumn, buttonColumnStyleDeclaration)}>
				<button className={css(!prevBtnEnabled && styleSheet.hide)} onClick={handlePrev}>
					<SliderArrowLeft {...sliderLeftProps} />
				</button>
			</div>
			<div ref={emblaRef} className={css(styleSheet.carouselViewport)}>
				<div className={css(styleSheet.carouselContainer)} style={{ gridAutoColumns: `${100 / visibleSlides}%` }}>
					{React.Children.map(children, child => (
						<div className={css(styleSheet.carouselSlide)} key={child.key}>
							{child}
						</div>
					))}
				</div>
			</div>
			<div className={css(styleSheet.buttonColumn, buttonColumnStyleDeclaration)}>
				<button className={css(!nextBtnEnabled && styleSheet.hide)} onClick={handleNext}>
					<SliderArrowRight {...sliderRightProps} />
				</button>
			</div>
		</div>
	);
}
