import { SvgIcon } from '../SvgIcon';

export interface IProps {
	className?: string;
	fillColor?: string;
	width?: number;
	height?: number;
}

export const SliderArrowLeft = ({ className, fillColor, width = 16, height = 18 }: IProps) => {
	return (
		<SvgIcon className={className} viewBox='0 0 16 18' width={width} height={height}>
			<path
				fill={fillColor || 'white'}
				fillRule='evenodd'
				d='M14 17.414 15.414 16l-7-7 7-7L14 .586 5.586 9 14 17.414Z'
				clipRule='evenodd'
			/>
			<path
				fill={fillColor || 'white'}
				fillRule='evenodd'
				d='M9 17.414 10.414 16l-7-7 7-7L9 .586.586 9 9 17.414Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
};
