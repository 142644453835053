import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useRef, useState } from 'react';
import { DragDropContext, DropResult, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { IFileAttachment, SocialMediaPostViewModel } from '../../../../extViewmodels';
import { useModalContext } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { getFileSizeStringValue } from '../../../../models/UiUtils';
import { useErrorMessages, useToaster } from '../../../../models/hooks/appStateHooks';
import { useImageAttachmentMutation } from '../../../../queries';
import { ImpersonationContextViewModel } from '../../../../viewmodels/AdminViewModels';
import { useEditSocialMediaPostContext } from '../../../containers/socialMedia/EditSocialMediaPost/context';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { asModalComponent } from '../../Modal';
import { CloudUploadIcon } from '../../svgs/icons/CloudUploadIcon';
import { useVideoFileUploader } from './hooks';
import { styleSheet } from './styles';

interface IFileInputModalProps {
	acceptedMimeTypes?: string;
	impersonationContext?: ImpersonationContextViewModel;
	limit?: number;
	onPostScheduled?: (postViewModel: SocialMediaPostViewModel) => void;
	/**
	 * @param file
	 * @returns false to prevent processing of the file
	 */
	onValidateSelectedFile?: (file: File) => boolean;
	post?: SocialMediaPostViewModel;
}

function FileInputModalBase({
	acceptedMimeTypes,
	impersonationContext,
	limit,
	onPostScheduled,
	onValidateSelectedFile,
	post,
}: IFileInputModalProps) {
	const { logApiError } = useEventLogging('FileInputModal');
	const { pushApiError: showApiError } = useErrorMessages();
	const toaster = useToaster();
	const { postImages, setImages } = useEditSocialMediaPostContext();
	const { parentModal } = useModalContext<SocialMediaPostViewModel>() || {};

	const [isDraggingOver, setIsDraggingOver] = useState(false);
	const [shouldAddAnotherImage, setShouldAddAnotherImage] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const videoElement = useRef<HTMLVideoElement>(null);
	const onUploadError = React.useCallback(
		(err: Api.IOperationResultNoValue) => {
			logApiError('FileInput-Upload-Error', err);
			showApiError(err);
		},
		[logApiError, showApiError]
	);
	const { draftCreated, errorMessage, isLoading, uploadVideo, resetErrorMessage, scheduledPost, videoAttachment } =
		useVideoFileUploader({
			impersonationContext,
			onError: onUploadError,
			post,
		});
	React.useEffect(() => {
		if (draftCreated && onPostScheduled && scheduledPost) {
			onPostScheduled(scheduledPost);
		}
	}, [draftCreated, onPostScheduled, scheduledPost]);
	const { mutateAsync: uploadImages } = useImageAttachmentMutation();

	const onRemoveClicked = React.useCallback(
		(image: IFileAttachment) => () => {
			// @ts-ignore
			setImages(val => val?.filter(x => x.id !== image.id));
		},
		[setImages]
	);

	React.useEffect(() => {
		if (!videoAttachment) {
			return;
		}
		// @ts-ignore
		setImages(val => {
			return val.concat(videoAttachment);
		});
	}, [videoAttachment, setImages]);

	React.useEffect(() => {
		if (!errorMessage) {
			return;
		}
		// @ts-ignore
		toaster.push({
			message: errorMessage,
			type: 'errorMessage',
		});
		resetErrorMessage();
	}, [errorMessage, resetErrorMessage, toaster]);

	const onFileChange = async (file: File) => {
		if (!file) {
			return; // If no file is selected, return early
		}

		const isValid = onValidateSelectedFile ? onValidateSelectedFile(file) : true;
		if (!isValid) {
			// Clear the input field
			if (inputRef.current) {
				inputRef.current.value = '';
			}
			return;
		}

		const mimeType = file.type?.toLocaleLowerCase();

		if (mimeType.startsWith('video/')) {
			// Handle video upload
			try {
				// Create a video element
				const video = document.createElement('video');
				video.preload = 'metadata';

				// Once video metadata is loaded
				video.onloadedmetadata = () => uploadVideo(file, video);

				// Set the source of the video element
				video.src = URL.createObjectURL(file);
				// @ts-ignore
				videoElement.current = video;
			} catch (err) {
				// @ts-ignore
				logApiError('LocalImageUpload-Error', err);
			}
		} else if (mimeType.startsWith('image/')) {
			// Handle image upload
			try {
				const imageAttachment = await uploadImages({ files: [file], resizeForSocialMedia: true, impersonationContext });

				// Add the uploaded image to the images state
				// @ts-ignore
				setImages(val => val.concat(imageAttachment[0]));
			} catch (err) {
				// @ts-ignore
				toaster.push({
					// @ts-ignore
					message: err?.systemMessage ?? 'Something went wrong while uploading the image.',
					type: 'errorMessage',
				});
			}
		}

		// Clear the input field
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(false);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDraggingOver(false);

		const file = e.dataTransfer.files?.[0];

		if (file) {
			onFileChange(file);
		}
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
	};

	const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		parentModal?.onRequestClose(scheduledPost);
	};

	const onRenderContent = (image: IFileAttachment) => {
		if (image?.mimeType?.startsWith('video/')) {
			return (
				<div className={css(styleSheet.imageDisplayArea)}>
					<figure className={css(styleSheet.imageDisplayAreaImageContainer)}>
						<video src={image.url} width={200} controls />
						<figcaption className={css(styleSheet.imageDisplayAreaInfo)}>
							<div>
								<span>File size:&nbsp;</span>
								{getFileSizeStringValue(image.fileSize)}
							</div>
						</figcaption>
					</figure>

					{/* This is where to insert other info about the content */}
					<DeprecatedCloseButton className={css(styleSheet.imageDisplayAreaClose)} onClick={onRemoveClicked(image)} />
				</div>
			);
		}
		return (
			<div className={css(styleSheet.imageDisplayArea)}>
				<figure className={css(styleSheet.imageDisplayAreaImageContainer)}>
					<img src={image.url} width={200} />
					<figcaption className={css(styleSheet.imageDisplayAreaInfo)}>
						<div>
							<div>{image.fileName}&nbsp;</div>
							<span>File size:&nbsp;</span>
							{getFileSizeStringValue(image.fileSize)}
							<div>Source: Upload&nbsp;</div>
						</div>
					</figcaption>
				</figure>

				{/* This is where to insert other info about the content */}
				<DeprecatedCloseButton className={css(styleSheet.imageDisplayAreaClose)} onClick={onRemoveClicked(image)} />
			</div>
		);
	};

	const onRenderDropZone = () => {
		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='file-upload'>
					{(droppableProvided: DroppableProvided) => {
						return (
							<div>
								{!isLoading ? (
									<>
										<div
											onDragEnter={handleDragEnter}
											onDragOver={handleDragOver}
											onDragLeave={handleDragLeave}
											onClick={() => {
												inputRef.current?.click();
											}}
											onDrop={handleDrop}
											className={isDraggingOver ? css(styleSheet.dragContainer) : css(styleSheet.dragContainer)}
											ref={droppableProvided ? droppableProvided.innerRef : undefined}
										>
											<input
												ref={inputRef}
												type='file'
												accept={acceptedMimeTypes}
												style={{ display: 'none' }}
												onChange={ev => {
													// @ts-ignore
													if (ev.target.files.length > 0) {
														// @ts-ignore
														onFileChange(ev.target.files[0]);
													}
												}}
											/>
											<div className={css(styleSheet.dragInfo)}>
												<CloudUploadIcon />
												<div className={css(styleSheet.dragText)}>Drag and drop image or video to upload</div>
												<div className={css(styleSheet.dragFooter)}>Click to browse device</div>
											</div>
										</div>
									</>
								) : null}
								{isLoading ? (
									<div className={css(styleSheet.loadingFooter)}>
										Your video is transcoding... please wait.
										<LoadingSpinner type='tiny' />
									</div>
								) : null}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		);
	};

	// @ts-ignore
	// @ts-ignore
	// @ts-ignore
	const hideUploadButton = !isNaN(limit) ? postImages?.length >= limit : false;
	return (
		<div>
			<label className={css(styleSheet.header)}>Add Media</label>
			<div className={css(styleSheet.imagesContainer)}>
				{postImages?.map(image => {
					return (
						<div className={css(styleSheet.imageContainer)} key={image.id}>
							{onRenderContent(image)}
						</div>
					);
				})}
				{isLoading && (
					<figure>
						{/* @ts-ignore */}
						<video src={videoElement.current.src} width={200} />
					</figure>
				)}
			</div>
			{(shouldAddAnotherImage || postImages?.length === 0) && onRenderDropZone()}
			<div>
				<button className={css(baseStyleSheet.ctaButtonSmall, styleSheet.button)} onClick={onClose}>
					<span>Save</span>
				</button>
				{hideUploadButton ? null : (
					<button className={css(styleSheet.ownImage)} onClick={() => setShouldAddAnotherImage(true)}>
						{'+ Upload another image '}
					</button>
				)}
			</div>
		</div>
	);
}

export const FileInputModal = asModalComponent(FileInputModalBase, {
	className: css(styleSheet.modal),
	useDefaultHeader: true,
});
