import { StyleSheet } from 'aphrodite';
import { brandPrimaryText } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	anchor: {
		fontSize: 20,
	},
	container: {
		display: 'flex',
	},
	copyLinkButton: {
		alignItems: 'center',
		marginLeft: 6,
	},
	popover: {
		color: 'rgb(13, 15, 17)',
		fontSize: 11,
		marginBottom: 0,
		marginTop: 0,
		padding: 4,
	},
	previewText: {
		color: brandPrimaryText,
		marginLeft: 6,
	},
});
