import * as React from 'react';

export const useCycleThroughMessages = ({
	messages,
	timeoutDuration = 2000,
}: {
	messages: string[];
	timeoutDuration?: number;
}) => {
	const [index, setIndex] = React.useState<number>(0);
	// Strart from scratch when messages change.
	React.useEffect(() => {
		setIndex(0);
	}, [messages]);
	React.useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (index < messages.length - 1) {
			timeout = setTimeout(() => {
				setIndex(i => i + 1);
			}, timeoutDuration);
		}
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [index, messages, timeoutDuration]);
	return messages[index];
};
