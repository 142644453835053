import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface Params {
	file: Blob;
	impersonationContext?: Api.IImpersonationContext;
}
export function useBlogFileImportMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (val: boolean) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({ file, impersonationContext }: Params) => {
			const formData = new FormData();
			formData.append('files', file);
			const value = await userSession.webServiceHelper.callAsync<boolean>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `blog/importFromFile`,
				}),
				'POST',
				formData
			);
			return value;
		},
		onError,
		onSuccess,
	});
}
