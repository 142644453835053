import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	chevron: {
		marginLeft: 2,
		marginRight: 6,
	},
	chevronFlip: {
		transform: 'rotate3d(0,0,1,180deg)',
	},
	chevronIcon: {
		transform: 'rotate3d(0,0,1,0deg)',
	},
	container: {},
	expander: {
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
	},
	moreButton: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		padding: '12px 0 6px',
	},
});
