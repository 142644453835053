import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, mention } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	disclaimerText: {
		fontSize: 12,
		paddingBottom: 20,
		textAlign: 'center',
		width: '60%',
	},
	disclaimerTextInstagram: {
		fontSize: 12,
		paddingBottom: 20,
		textAlign: 'center',
		width: '60%',
	},
	iconRow: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		margin: '0 auto',
		padding: 4,
		...CssSelectors.allChildren(
			{
				height: 24,
				opacity: 0.5,
			},
			'img'
		),
	},
	iconRowSelected: {
		...CssSelectors.allChildren(
			{
				height: 38,
				opacity: 1,
			},
			'img'
		),
	},
	pointLeft: {
		height: 15,
		transform: 'rotateZ(90deg)',
		width: 20,
	},

	pointRight: {
		height: 15,
		transform: 'rotateZ(270deg)',
		width: 20,
	},
	previewContentBox: {
		background: 'white',
		borderRadius: 10,
		color: 'black',
		fontSize: 14,
		marginBottom: 20,
		opacity: 1,
		overflowWrap: 'break-word',
		transform: 'translate3d(0,0,0)',
		transition: 'opacity 0.2s, transform 0.5s',
		whiteSpace: 'pre-wrap',
		width: 372,
		wordBreak: 'break-word',
		wordWrap: 'break-word',
	},
	previewContentBoxInstagram: {},
	previewContentContainer: {
		alignItems: 'center',
		background: mention,
		color: brandPrimaryText,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 16,
		paddingTop: 50,
	},
	previewHeader: {
		margin: '0 auto',
	},
	textOnlyPadding: {
		padding: 14,
	},
});
