import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, brandSecondary } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	cardHeaderTitleName: {},
	indicator: {
		height: '100%',
		position: 'relative',
		width: '100%',
	},
	indicatorHorizontalLine: {
		borderTop: `1px dashed ${brandSecondary}`,
		left: 62,
		position: 'absolute',
		top: 72,
		width: `calc(100% - 62px)`,
	},
	indicatorIcon: {
		left: 0,
		position: 'absolute',
		top: 41,
	},
	indicatorVerticalLine: {
		borderLeft: `1px dashed ${brandSecondary}`,
		left: 31,
		position: 'absolute',
	},
	indicatorVerticalLineBottom: {
		height: `calc(100% - 104px)`,
		top: 104,
	},
	indicatorVerticalLineTop: {
		height: 41,
		top: 1,
	},
	segment: {},
	segmentAddStepButton: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
		justifyContent: 'center',
	},
	segmentCardHeader: {
		paddingBottom: 14,
	},
	segmentEditNameButton: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	segmentSteps: {
		marginBottom: 20,
	},
	segmentStepsFooter: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
});
