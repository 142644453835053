import { brandSecondary } from '../../styles/colors';
import { Layouts } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		...Layouts.verticalStack(20),
		maxWidth: 500,
	},
	header: {
		color: brandSecondary,
		fontSize: 18,
	},
});
