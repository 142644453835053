import { brandPrimaryHover, mention, titles, urgentDate } from '../../../../styles/colors';
import { truncateTextStyle } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const TagHeight = 40;
const transition = 'all 0.1s linear';

export const styleSheet = StyleSheet.create({
	birthday: {
		background: '#FFEDE0',
	},
	birthdayCount: {
		color: '#C75DA6',
	},
	check: {
		flexShrink: 0,
		height: 24,
		transition,
		width: 24,
	},
	clock: {
		position: 'absolute',
		right: 0,
		top: 0,
		zIndex: 1,
	},
	container: {
		alignItems: 'center',
		background: mention,
		borderRadius: TagHeight / 2,
		boxSizing: 'border-box',
		color: titles,
		display: 'inline-flex',
		fontSize: 16,
		height: TagHeight,
		maxWidth: 300,
		padding: '8px 20px 8px 10px',
		position: 'relative',
		transition,
	},
	containerFollowUp: {
		fontSize: 14,
		height: (TagHeight * 2) / 3,
		padding: '8px 14px 8px 2px',
	},
	containerSelected: {
		background: brandPrimaryHover,
		color: '#fff',
	},
	containerWithTagAlert: {
		background: '#F6F1F2',
	},
	count: {
		color: urgentDate,
		fontWeight: 600,
		marginLeft: 10,
		transition,
	},
	countSelected: {
		color: '#fff',
	},
	isFollowUp: {
		color: titles,
		fontWeight: 900,
	},
	radio: {
		marginBottom: 3,
		marginLeft: 32,
	},
	title: {
		marginLeft: 10,
		...truncateTextStyle,
	},
});
