import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useEventLogging } from '../../../../models/Logging';
import { getDefaultDateStringValue } from '../../../../models/UiUtils';
import { ScheduleSendIcon } from '../../../components/svgs/icons/ScheduleSendIcon';
import { SuccessCheckIcon } from '../../../components/svgs/icons/SuccessCheckIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	post: Api.SocialMediaPostViewModel;
	styles?: StyleDeclarationValue[];
}

export const SocialMediaPostScheduleComplete: React.FC<IProps> = props => {
	const { className, styles = [], post } = props;
	const { logInput } = useEventLogging('SocialMediaPostScheduleComplete');
	const onReportingLinkClicked = React.useCallback(() => {
		logInput('REportingLink', 'Click');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const isSendNow = !!post?.dueMoment && moment(post.dueMoment).isSame(moment(), 'hour');
	return (
		<div className={`${css(styleSheet.container, ...styles)} social-media-post-schedule-complete ${className || ''}`}>
			<div className={css(styleSheet.iconContainer)}>
				<ScheduleSendIcon />
				<SuccessCheckIcon className={css(styleSheet.successIcon)} />
			</div>
			<div className={css(styleSheet.titleMessage)}>
				{isSendNow ? (
					<span>Your post will be delivered in the next 5 minutes.</span>
				) : (
					<span>
						{!post.dueMoment
							? 'Your post has been saved as a draft.'
							: `Your post will be delivered on ${getDefaultDateStringValue(post.dueMoment)}`}{' '}
					</span>
				)}
			</div>
			<div className={css(styleSheet.detailMessage)}>
				You can view the queued up post in &nbsp;
				<NavLink
					className={css(baseStyleSheet.brandLink, styleSheet.link)}
					onClick={onReportingLinkClicked}
					to='/reporting/social-media-posts'
				>
					<span>Social Media Reports</span>
				</NavLink>
				.
			</div>
		</div>
	);
};
