import { getDefaultDateStringValue, getDefaultTimeStringValue } from '../../../../../models/UiUtils';
import { useLambda } from '../../../../../models/hooks/useLambda';
import { baseStyleSheet } from '../../../../styles/styles';
import { PopoverType, TinyPopover } from '../../../TinyPopover';
import {
	EmailMessageTemplateSaveButton,
	IEmailMessageTemplateSaveButtonProps,
} from '../EmailMessageTemplateSaveButton';
import { styleSheet } from './styles';
import { IRichContentEditorState, ITemplate } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps extends IEmailMessageTemplateSaveButtonProps {
	canDelete?: boolean;
	canImpersonateOnTemplateSave?: boolean;
	className?: string;
	disableEditing?: boolean;
	isEditing?: boolean;
	isQueuedCampaign?: boolean;
	editorState?: IRichContentEditorState;
	onDeleteClicked?(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
	onEditClicked?(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
	onPreviewClicked?(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
	styles?: StyleDeclarationValue[];
}

export const EmailMessageTemplateButtons: React.FC<IProps> = props => {
	const {
		canDelete,
		canImpersonateOnTemplateSave,
		disableEditing,
		editorState,
		className,
		isEditing,
		isQueuedCampaign,
		onDeleteClicked,
		onEditClicked,
		onPreviewClicked,
		onTemplateSaved,
		styles,
		...restProps
	} = props;
	const [saveText, setSaveText] = React.useState('');
	const [showPopover, , setShowPopover] = useLambda(false);

	const updateSaveText = React.useCallback(
		(template: ITemplate) => {
			if (template?.name) {
				const date = new Date();
				setSaveText(`"${template.name}" saved ${getDefaultDateStringValue(date)} ${getDefaultTimeStringValue(date)}`);
			}
			onTemplateSaved?.(template);
		},
		[onTemplateSaved]
	);

	const text = isEditing ? (isQueuedCampaign ? 'Save' : 'Preview') : 'Edit';

	return (
		<div
			className={`${css(styleSheet.container, ...(styles || []))} email-message-template-buttons ${className || ''}`}
		>
			<div className={css(styleSheet.saveText)}>{saveText}</div>
			{!(text === 'Edit' && disableEditing) && (
				<div className={css(text === 'Edit' && disableEditing && styleSheet.disabledEdit)}>
					<button
						className={css(baseStyleSheet.ctaButtonReverseSmall)}
						disabled={text === 'Edit' && disableEditing}
						onClick={isEditing ? onPreviewClicked : onEditClicked}
					>
						<span>{text}</span>
					</button>
				</div>
			)}
			{!isQueuedCampaign && (
				<EmailMessageTemplateSaveButton
					editorState={editorState}
					canImpersonateOnTemplateSave={canImpersonateOnTemplateSave}
					onTemplateSaved={updateSaveText}
					{...restProps}
				/>
			)}
			{canDelete ? (
				<button className={css(baseStyleSheet.ctaButtonDestructiveSmall)} onClick={onDeleteClicked}>
					<span>Delete</span>
				</button>
			) : (
				<TinyPopover
					align='center'
					anchor={
						<div className={css(styleSheet.disabled)}>
							<span
								onMouseEnter={setShowPopover(true)}
								onMouseLeave={setShowPopover(false)}
								className={css(baseStyleSheet.ctaButtonDestructiveSmall, styleSheet.disabled)}
							>
								Delete
							</span>
						</div>
					}
					dismissOnOutsideAction={true}
					isOpen={showPopover}
					placement={['bottom']}
					type={PopoverType.background}
				>
					<div className={css(styleSheet.popover)}>You do not have permission to delete this template</div>
				</TinyPopover>
			)}
		</div>
	);
};
