import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps extends React.SVGProps<SVGSVGElement> {
	fillColor?: string;
}

export const DoubleArrowRightIcon: React.FC<IProps> = ({ fillColor, width = 20, height = 17, ...rest }: IProps) => {
	return (
		<SvgIcon height={height} width={width} {...rest}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.055 12.152c-3.584 1.599-5.517 2.462-6.206 2.582l3.326-5.542h9.56c-2.747 1.206-4.94 2.183-6.68 2.96ZM1.893 1.842c.727.149 2.663 1.013 6.188 2.584 1.737.775 3.92 1.748 6.653 2.948H5.175L1.892 1.842Zm17.558 5.606c-4.7-2.039-8.122-3.564-10.629-4.682C3.934.586 2.621 0 1.704 0 .92 0 .532.476.406.632a1.825 1.825 0 0 0-.148 2.082L3.6 8.284.259 13.85a1.822 1.822 0 0 0 .147 2.082c.126.157.514.634 1.299.634h.001c.914 0 2.223-.584 7.09-2.755a987.66 987.66 0 0 1 10.655-4.695.91.91 0 0 0 0-1.669Z'
				fill='#89C947'
			/>
		</SvgIcon>
	);
};
