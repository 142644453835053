import { titles } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const CameraIcon: React.FC<IProps> = ({ className = '', fillColor }) => {
	return (
		<SvgIcon className={`camera-icon ${className || ''}`} height={18} width={20}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 13C8.346 13 7 11.654 7 10C7 8.346 8.346 7 10 7C11.654 7 13 8.346 13 10C13 11.654 11.654 13 10 13ZM10 5C7.243 5 5 7.243 5 10C5 12.757 7.243 15 10 15C12.757 15 15 12.757 15 10C15 7.243 12.757 5 10 5Z'
				fill={fillColor || titles}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2 16V5H6.535L8.535 2H11.465L13.465 5H18L18.001 16H2ZM18 3H14.535L13.129 0.89C12.757 0.333 12.135 0 11.465 0H8.535C7.865 0 7.243 0.333 6.871 0.89L5.465 3H2C0.897 3 0 3.897 0 5V16C0 17.102 0.897 18 2 18H18C19.103 18 20 17.102 20 16V5C20 3.897 19.103 3 18 3Z'
				fill={fillColor || titles}
			/>
		</SvgIcon>
	);
};
