import { navigation, titles } from '../../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const ContactListItemAvatarSize = 50;
export const ContactListItemCheckboxSize = 14;
export const ContactListItemHorizontalPadding = 10;
export const ContactListItemInterItemSpacing = 20;

export const styleSheet = StyleSheet.create({
	avatar: {
		flexShrink: 0,
		height: ContactListItemAvatarSize,
		width: ContactListItemAvatarSize,
	},
	check: {
		alignItems: 'center',
		display: 'flex',
		height: ContactListItemCheckboxSize,
		justifyContent: 'center',
		width: ContactListItemCheckboxSize,
	},
	container: {
		alignItems: 'center',
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		padding: `8px ${ContactListItemHorizontalPadding}px`,
		...CssSelectors.allChildren(
			{
				marginLeft: ContactListItemInterItemSpacing,
			},
			':not(:first-child)'
		),
	},
	containerChecked: {
		background: '#E8F3F9',
	},
	info: {
		width: `calc(100% - ${
			ContactListItemCheckboxSize + ContactListItemAvatarSize + 3 * ContactListItemInterItemSpacing
		}px)`,
	},
	infoName: {
		color: titles,
		fontSize: 18,
		...truncateTextStyle,
	},
	infoTitle: {
		color: navigation,
		fontSize: 12,
		marginTop: 2,
		...truncateTextStyle,
	},
	infoWithoutCheckbox: {
		width: `calc(100% - ${ContactListItemAvatarSize + 2 * ContactListItemInterItemSpacing}px)`,
	},
	success: {
		alignItems: 'center',
		display: 'flex',
		height: 14,
		justifyContent: 'center',
		width: 14,
	},
	trash: {},
});
