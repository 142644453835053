import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { isValidEmail } from '../../../../models/UiUtils';
import { useToaster } from '../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../styles/styles';
import { TextInput } from '../../TextInput';
import { lastUsedComplianceRecipientEmail } from '../../campaigns/CampaignDetailsSummary';
import { ComplianceIcon } from '../../svgs/icons/ComplianceIcon';
import { styleSheet } from './styles';
import { ImpersonationContextViewModel } from '../../../../viewmodels/AdminViewModels';

interface IProps {
	onScheduleCompliance(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string): void;
	onScheduleWithoutCompliance(e: React.MouseEvent<HTMLElement>): void;
	impersonationContext?: ImpersonationContextViewModel;
}

export const ComplianceApproval: React.FC<IProps> = observer(
	({ onScheduleCompliance, onScheduleWithoutCompliance, impersonationContext }) => {
		const toaster = useToaster();
		const [sendWithComplianceEmail, setSendWithComplianceEmail] = React.useState<string>(() => {
			return localStorage.getItem(lastUsedComplianceRecipientEmail) ?? '';
		});

		const checkComplianceEmail = () => {
			if (sendWithComplianceEmail.length > 0 && !isValidEmail(sendWithComplianceEmail)) {
				toaster.push({
					message: `Please enter a valid email address`,
					type: 'errorMessage',
				});
				return false;
			}
			return true;
		};

		const isImpersonating = impersonationContext?.isValid ?? false;

		return (
			<div className={`${css(styleSheet.container)} send-scheduler-complete`}>
				<div className={css(styleSheet.iconContainer)}>
					<ComplianceIcon className={css(styleSheet.icon)} />
				</div>
				<div className={css(styleSheet.title)}>Do you need approval from compliance officer?</div>
				<div className={css(styleSheet.detailMessage)}>
					We will email you a copy of the campaign and you can work with your compliance officer to get approved. In the
					meantime, you can schedule this campaign , and it&apos;ll be held in a pending state until you come back and
					mark it as approved.
				</div>
				<div className={css(baseStyleSheet.verticalStack, styleSheet.stack)}>
					<TextInput
						inputId='user-emailAddress'
						placeholder='Please enter Compliance Email'
						type='text'
						onBlur={() => {
							if (!checkComplianceEmail()) {
								return;
							}
							localStorage.setItem(lastUsedComplianceRecipientEmail, sendWithComplianceEmail);
						}}
						onChange={ev => {
							setSendWithComplianceEmail(ev.target.value);
						}}
						className={css(styleSheet.complianceInput)}
						value={sendWithComplianceEmail}
					/>
					<button
						className={css(baseStyleSheet.ctaButton, styleSheet.stack)}
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							if (!checkComplianceEmail()) {
								return;
							}
							onScheduleCompliance(e, sendWithComplianceEmail);
						}}
					>
						<span>Schedule Campaign with Compliance Pending</span>
					</button>
					{!isImpersonating ? (
						<button
							className={css(baseStyleSheet.ctaButtonReverse, styleSheet.stack)}
							onClick={onScheduleWithoutCompliance}
						>
							<span>Send Campaign without Compliance</span>
						</button>
					) : null}
				</div>
			</div>
		);
	}
);
