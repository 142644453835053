import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../models/Logging';
import { baseStyleSheet } from '../../styles/styles';
import { AutoCompleteSearchField, IAutoCompleteSearchFieldComponent } from '../autocomplete/AutoCompleteSearchField';
import { Modal } from '../Modal';
import { SearchIcon } from '../svgs/icons/SearchIcon';
import { styleSheet } from './styles';

interface ISelectASurveyModalProps {
	automationTemplate?: Api.AutomationTemplateViewModel;
	trigger: Api.IEventRegistrationTrigger;
	onClose: () => void;
	onSurveySelected: (trigger: Api.IEventRegistrationTrigger) => void;
}

interface IAutomationTriggerSettingsProps<T extends Api.IAutomationTrigger = Api.IAutomationTrigger> {
	automationTemplate?: Api.AutomationTemplateViewModel;
	onRequestSetTrigger?(trigger: T): Promise<any>;
	trigger?: T;
}

export const AutomationSurveyAutocomplete: React.FC<
	IAutomationTriggerSettingsProps & {
		onSurveySelected?(survey: Api.IEventRegistrationSurvey): void;
		className?: string;
		limit?: number;
		initialEventName?: string;
	}
> = observer(props => {
	const { automationTemplate, onSurveySelected, limit = 10, initialEventName = '' } = props;

	const [searchQuery] = React.useState<string>(initialEventName);
	const autoCompleteSearchFieldComponentRef = React.useRef<IAutoCompleteSearchFieldComponent>(null);

	const onCreateAutoCompleteViewModel = React.useCallback(
		(_: Api.ResourceAutoCompleteViewModelType, suggestedVm: Api.ResourceAutoCompleteViewModel) => {
			return automationTemplate?.impersonationContext
				? suggestedVm.impersonate(automationTemplate.impersonationContext)
				: suggestedVm;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onAutoCompleteSearchFieldRef = React.useCallback((ref?: IAutoCompleteSearchFieldComponent) => {
		autoCompleteSearchFieldComponentRef.current = ref;
	}, []);

	const onAutocompleteItemSelectedSurveyTrigger = React.useCallback(
		(survey: Api.IEventRegistrationSurvey): void => {
			onSurveySelected?.(survey);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[automationTemplate, onSurveySelected]
	);

	const onInputBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		const searchValue = (e.target.value || '').trim();
		if (searchValue) {
			autoCompleteSearchFieldComponentRef.current?.setSearchQuery(searchValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AutoCompleteSearchField
			className={props.className}
			anchorClassName={css(styleSheet.searchBox)}
			startSearchingWhenOpen
			clearSearchFieldAfterSelectingItem={false}
			dropdownContentClassName={css(styleSheet.autocompleteBox)}
			initialSearchQuery={searchQuery}
			inputId='automation-trigger-tag-search'
			inputProps={{
				disabled: automationTemplate?.id ? !automationTemplate.canEdit : false,
				onBlur: onInputBlur,
				placeholder: 'Search for an event',
			}}
			leftAccessory={
				<span className={css(styleSheet.iconSpan)}>
					<SearchIcon fillColor='#CCC' />
				</span>
			}
			onCreateAutoCompleteViewModel={onCreateAutoCompleteViewModel}
			onInnerRef={onAutoCompleteSearchFieldRef}
			onItemSelected={onAutocompleteItemSelectedSurveyTrigger}
			pageSize={limit}
			resultsLimit={limit}
			type={Api.ResourceAutoCompleteViewModelType.EventRegistrationSurvey}
		/>
	);
});

export function SelectASurveyModal(props: ISelectASurveyModalProps) {
	const [eventId, setEventId] = React.useState<string>();
	const [eventName, setEventName] = React.useState<string>();

	const logger = useEventLogging('SelectASurveyModal');
	const errorMessages = useErrorMessages();

	const onCreateClicked = () => {
		props.onSurveySelected({
			...props.trigger,
			eventId,
			eventName,
			validResponses: [Api.EventRegistrationResponseStatus.Attending],
		});
	};

	const onCancelClicked = () => {
		props.onClose();
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => {
				props.onClose();
			}}
			useDefaultHeader={true}
		>
			<div className={css(styleSheet.triggerSettings)}>
				<div>
					<div className={css(styleSheet.triggerSettingsTitle)}>Set an event registration as your trigger</div>
					<AutomationSurveyAutocomplete
						{...props}
						initialEventName={(props.trigger as Api.IEventRegistrationTrigger)?.eventName}
						onSurveySelected={survey => {
							props.automationTemplate
								?.addTrigger<Api.IEventRegistrationTrigger>({
									_type: 'EventRegistrationTrigger',
									eventId: survey.id,
									eventName: survey.name,
								})
								?.catch((err: Api.IOperationResultNoValue) => {
									errorMessages.pushApiError(err);
									logger.logApiError('AutomationTemplateAddTagTrigger', err);
								});
							setEventId(survey.id);
							setEventName(survey.name);
						}}
					/>
				</div>
				<div className={css(baseStyleSheet.horizontalStack)}>
					<button className={css(baseStyleSheet.ctaButton)} disabled={!eventId} onClick={onCreateClicked}>
						<span>Create Automation</span>
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancelClicked}>
						<span>Cancel</span>
					</button>
				</div>
			</div>
		</Modal>
	);
}
