import { AIContentGenerationStatus } from '../../../../models/Ai';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';
import * as React from 'react';

export interface IAIContentGenerationStatusContext {
	clearContentGenerationStatus?(): void;
	contentGenerationStatus: AIContentGenerationStatus;
}

// @ts-ignore
export const AIContentGenerationStatusContext = React.createContext<IAIContentGenerationStatusContext>(null);

export const useAIContentGenerationStatusContext = () =>
	useContextGuard(AIContentGenerationStatusContext, 'AIContentGenerationStatusContext');
