import { Observer } from 'mobx-react';
import * as React from 'react';
import MediaQuery, { MediaQueryProps } from 'react-responsive';

export const MediaQueryObserver: React.FunctionComponent<MediaQueryProps> = props => {
	const { children, ...restProps } = props;
	return (
		<MediaQuery {...restProps}>
			{(matches: boolean) => {
				return (
					<Observer>
						{() => {
							return typeof children === 'function'
								? (children as (matches: boolean) => React.ReactNode)?.(matches) || null
								: matches
									? (children as any)
									: null;
						}}
					</Observer>
				);
			}}
		</MediaQuery>
	);
};
