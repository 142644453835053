import { destructive } from '../../../styles/colors';
import { BreakPoints, CssSelectors, baseStyleSheet } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	pointDown: {
		transform: 'rotateZ(90deg)',
	},
	pointUp: {
		transform: 'rotateZ(270deg)',
	},
	reachOutTag: {
		background: '#FFF1E6',
	},
	reachOutTagCount: {
		color: destructive,
		...baseStyleSheet.fontBold,
	},
	tag: {
		display: 'inline-flex',
		[BreakPoints.tabletCompactWidth]: {
			maxWidth: 150,
		},
	},
	tagList: {
		overflowX: 'auto',
	},
	tagSearches: {
		height: 'auto',
		marginLeft: -10,
	},
	tagSearchesContainer: {
		overflow: 'hidden',
	},
	tagTokenContainer: {
		maxHeight: 'unset',
		...CssSelectors.allChildren({
			marginLeft: 10,
		}),
	},
	triggerText: {
		marginRight: 10,
	},
});
