import { Layouts } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	row: {
		...Layouts.horizontalStack(10),
		':not(:first-child)': {
			marginTop: 15,
		},
		cursor: 'pointer',
	},
});
