import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	buttonColumn: {
		alignItems: 'flex-start',
		boxSizing: 'border-box',
		display: 'flex',
		padding: '0.5rem 0.25rem 0',
		width: '1.5rem',
	},
	carouselContainer: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridGap: '1rem',
	},
	carouselSlide: {
		minWidth: 0,
		position: 'relative',
	},
	carouselViewport: {
		overflow: 'hidden',
	},
	hide: {
		display: 'none',
	},
	root: {
		display: 'grid',
		gridTemplateColumns: 'auto 1fr auto',
		width: '100%',
	},
});
