import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	CampaignViewModel,
	CampaignsReportingViewModel,
	EmailSendStatus,
	PostStatus,
	SocialMediaPostReportViewModel,
	SocialMediaPostsReportingViewModel,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { baseStyleSheet } from '../../../../styles/styles';
import { CampaignListItem } from '../CampaignListItem';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps {
	campaigns: CampaignsReportingViewModel | SocialMediaPostsReportingViewModel;
	className?: string;
	hideCompleted?: boolean;
	onRenderEmptyPlaceholder?(): React.ReactNode;
	styles?: StyleDeclarationValue[];
}

class _CampaignList extends React.Component<IProps> {
	public render() {
		const { className, styles, hideCompleted, onRenderEmptyPlaceholder } = this.props;
		const total = (this.planned?.length ?? 0) + (!hideCompleted ? this.completed?.length ?? 0 : 0);
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} campaign-list ${className || ''}`}>
				{total === 0 ? (
					onRenderEmptyPlaceholder ? (
						onRenderEmptyPlaceholder()
					) : (
						<div className={css(baseStyleSheet.absoluteCenter)}>No Campaigns</div>
					)
				) : (
					<>
						{!hideCompleted && (this.completed?.length ?? 0) > 0 && this.renderSection(true)}
						{(this.planned?.length ?? 0) > 0 && this.renderSection(false)}
					</>
				)}
			</div>
		);
	}

	private renderSection(completed: boolean) {
		const campaigns = completed ? this.completed : this.planned;

		return (
			<div key={completed ? 'completed' : 'planned'}>
				<div className={css(baseStyleSheet.tableColumnHeader, styleSheet.sectionLabel)}>
					{`${completed ? 'Completed' : 'Planned'} Campaigns`}
				</div>
				<ul>
					{campaigns?.map((campaign, i) => (
						<CampaignListItem
							campaign={campaign}
							className={css(styleSheet.listItem)}
							key={campaign.id}
							style={{ '--stagger-anim-order': i } as React.CSSProperties}
						/>
					))}
				</ul>
			</div>
		);
	}

	@computed
	private get isSocial() {
		const { campaigns } = this.props;
		return campaigns instanceof SocialMediaPostsReportingViewModel;
	}

	@computed
	private get completed() {
		const { campaigns } = this.props;
		const completedCheck = this.isSocial ? PostStatus.Succeeded : EmailSendStatus.Complete;
		const completedCheckStarted = this.isSocial && PostStatus.Started;
		const completedCheckPartial = this.isSocial && PostStatus.PartiallySucceeded;
		return (
			campaigns?.items
				?.filter(
					x => x.status === completedCheck || x.status === completedCheckStarted || x.status === completedCheckPartial
				)
				?.sort(this.sortByStartDate(true)) || []
		);
	}

	@computed
	private get planned() {
		const { campaigns } = this.props;
		const plannedCheckScheduled = this.isSocial ? PostStatus.Scheduled : EmailSendStatus.Queued;
		const plannedCheckStarted = !this.isSocial && EmailSendStatus.Sending;

		return (
			campaigns?.items
				?.filter(x => x.status === plannedCheckScheduled || x.status === plannedCheckStarted)
				?.sort(this.sortByStartDate()) || []
		);
	}

	private sortByStartDate =
		(completed = false) =>
		(
			a?: CampaignViewModel | SocialMediaPostReportViewModel,
			b?: CampaignViewModel | SocialMediaPostReportViewModel
		) => {
			const aDate =
				a instanceof CampaignViewModel ? (completed ? a?.completedDate : a?.schedule?.startDate) : a?.dueDate;
			const bDate =
				b instanceof CampaignViewModel ? (completed ? b?.completedDate : b?.schedule?.startDate) : b?.dueDate;

			if (!!aDate && !!bDate) {
				const aStartMoment = moment(aDate);
				if (aStartMoment.isSame(bDate, 'day')) {
					return 0;
				}
				return aStartMoment.isBefore(bDate) ? -1 : 1;
			}
			return 0;
		};
}

const CampaignListAsObserver = observer(_CampaignList);
export const CampaignList = withEventLogging(CampaignListAsObserver, 'CampaignList');
