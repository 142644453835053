import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { baseStyleSheet } from '../../styles/styles';
import { NextPreviousInputAccessory } from '../NextPreviousInputAccessory';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	date?: Date;
	disabled?: boolean;
	onDateChanged?(date: Date): void;
	styles?: StyleDeclarationValue[];
}

export const MonthPickerInput: React.FC<IProps> = props => {
	const { className, styles = [], date, onDateChanged, children, disabled } = props;
	const [selectedMoment, setSelectedMoment] = React.useState<moment.Moment>(
		moment(date || new Date()).startOf('month')
	);

	React.useEffect(() => {
		const nextSelectedMoment = moment(date);
		nextSelectedMoment.startOf('month');
		if (!nextSelectedMoment.isSame(selectedMoment)) {
			setSelectedMoment(nextSelectedMoment);
		}
	}, [selectedMoment, date]);

	const onChangeDate = (change: number) => () => {
		const nextMoment = selectedMoment.clone();
		if (change < 0) {
			nextMoment.subtract(Math.abs(change), 'month');
		} else {
			nextMoment.add(change, 'month');
		}
		nextMoment.startOf('month');
		if (onDateChanged) {
			onDateChanged(nextMoment.toDate());
		} else {
			setSelectedMoment(nextMoment);
		}
	};

	return (
		<div
			className={`${css(baseStyleSheet.textField, styleSheet.container, ...styles)} month-picker-input ${
				className || ''
			}`}
		>
			<div className={css(styleSheet.content)}>
				<div className={css(styleSheet.dateText)}>{selectedMoment.format('MMMM YYYY')}</div>
				<NextPreviousInputAccessory
					disabled={disabled}
					onNextClicked={onChangeDate(1)}
					onPreviousClicked={onChangeDate(-1)}
					styles={[styleSheet.nextPrev]}
				/>
				{children}
			</div>
		</div>
	);
};
