import { destructive } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const CancelIconFill: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`cancel-icon-fill ${className || ''}`} height={20} width={20} viewBox={`0 0 ${20} ${20}`}>
			<g fill='none'>
				<circle fill={destructive} cx='10' cy='10' r='8' />
				<circle cx='10' cy='10' r='9' stroke={destructive} strokeOpacity='0.203646' strokeWidth='2' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M13 7.48719L12.5128 7L10 9.51281L7.48719 7L7 7.48719L9.51281 10L7 12.5128L7.48719 13L10 10.4875L12.5128 13L13 12.5128L10.4872 10L13 7.48719Z'
					fill='white'
					stroke='white'
					strokeWidth='1.5'
				/>
			</g>
		</SvgIcon>
	);
};
