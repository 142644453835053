import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	citationContentBox: {
		background: '#F9F9F9',
		color: 'black',
		fontSize: 14,
		height: 450,
		marginBottom: 20,
		marginTop: 25,
		minWidth: 500,
		overflow: 'auto',
		overflowWrap: 'break-word',
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-word',
		wordWrap: 'break-word',
	},
	container: {},
	contentBoxTitle: {
		color: '#046CB6',
		fontSize: 16,
		fontWeight: 600,
		margin: 0,
	},
});
