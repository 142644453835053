import { css } from 'aphrodite';
import * as React from 'react';
import { INameValue, ValueSelector } from '../ValueSelector';
import StatesCollection from './states.json';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onSelectionChanged?(selected: INameValue): void;
	selectedValue?: string;
}

export const ExtendedUSStates = StatesCollection as INameValue[];
export const USStates = ExtendedUSStates.filter(s => !['AS', 'GU', 'MP', 'PR', 'VI', 'DC'].includes(s.value));

export const StateSelector: React.FC<IProps> = props => {
	const { className, ...restProps } = props;

	return (
		<ValueSelector
			className={`${css(styleSheet.container)} state-selector ${className || ''}`}
			options={ExtendedUSStates}
			{...restProps}
		/>
	);
};
