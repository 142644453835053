import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const MergeIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`merge-icon ${className || ''}`} height={18} width={18}>
			<g fill={fillColor || '#F2B639'} fillRule='evenodd'>
				<path d='M5.625 13.5C5.625 11.4291 6.9291 10.125 9 10.125 11.0709 10.125 12.375 11.4291 12.375 13.5L5.625 13.5zM11.25 6.75C11.25 7.9929 10.2429 9 9 9 7.7571 9 6.75 7.9929 6.75 6.75 6.75 5.5071 7.7571 4.5 9 4.5 10.2429 4.5 11.25 5.5071 11.25 6.75M16.2 18L12.6 18 12.6 16.2 16.2 16.2 16.2 12.6 18 12.6 18 16.2C18 17.1927 17.1927 18 16.2 18M18 5.4L16.2 5.4 16.2 1.8 12.6 1.8 12.6 0 16.2 0C17.1927 0 18 .8064 18 1.8L18 5.4zM1.8 5.4L0 5.4 0 1.8C0 .8064.8073 0 1.8 0L5.4 0 5.4 1.8 1.8 1.8 1.8 5.4zM5.4 18L1.8 18C.8073 18 0 17.1927 0 16.2L0 12.6 1.8 12.6 1.8 16.2 5.4 16.2 5.4 18z' />
			</g>
		</SvgIcon>
	);
};
