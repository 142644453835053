import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fillColor?: string;
	width?: number;
	height?: number;
}

export const SliderArrowRight = ({ className, fillColor, width = 16, height = 18 }: IProps) => {
	return (
		<SvgIcon className={className} width={width} height={height} viewBox='0 0 16 18'>
			<path
				fill={fillColor || 'white'}
				fillRule='evenodd'
				d='M2 17.414.586 16l7-7-7-7L2 .586 10.414 9 2 17.414Z'
				clipRule='evenodd'
			/>
			<path
				fill={fillColor || 'white'}
				fillRule='evenodd'
				d='M7 17.414 5.586 16l7-7-7-7L7 .586 15.414 9 7 17.414Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
};
