import { StyleDeclaration, css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { AdvancedTagFilter } from '../../../models';
import { FilterOperator, ResourceAutoCompleteViewModelType } from '../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../styles/styles';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../autocomplete/SimpleAutoCompleteSearchField';
import { EntityPropertySearchTag, IEntityPropertySearch } from '../entities/EntityPropertySearchTag';
import { AddTagButton } from '../tags/AddTagButton';
import { styleSheet } from './styles';

interface IProps {
	className?: StyleDeclaration;
	disabled?: boolean;
	filters: AdvancedTagFilter[];
	onAddFilter?: (filter: AdvancedTagFilter) => void;
	onRemoveFilter?: (filter: AdvancedTagFilter) => void;
	type: FilterOperator;
}

const FiltersEditorBase = ({ className, disabled, filters, onAddFilter, onRemoveFilter, type }: IProps) => {
	const [isShowingAddTag, setIsShowingAddTag] = useState(false);

	useEffect(() => {
		setIsShowingAddTag(false);
	}, [filters]);

	useEffect(() => {
		if (disabled) {
			setIsShowingAddTag(false);
		}
	}, [disabled]);

	const onAddTagClick = () => setIsShowingAddTag(true);

	const onRemove = useCallback(
		({ value }: IEntityPropertySearch) => {
			const filter = filters.find(f => f.tag === value);
			// @ts-ignore
			onRemoveFilter?.(filter);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters]
	);

	const hideInput = () => setIsShowingAddTag(false);

	const onTagSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<string>) => {
		const filter = new AdvancedTagFilter();
		filter.type = type;
		filter.tag = e.selection;
		onAddFilter?.(filter);
	};

	const renderSearchRightAccessory = () => {
		return <span />;
	};

	const sortedFilters = useMemo(() => {
		const orFilters = filters.filter(filter => filter.type === FilterOperator.Or);
		const andFilters = filters.filter(filter => filter.type === FilterOperator.And);
		const notFilters = filters.filter(filter => filter.type === FilterOperator.Not);
		return [...orFilters, ...andFilters, ...notFilters];
	}, [filters]);

	return (
		<div className={css(styleSheet.container, disabled && styleSheet.disabled, className)}>
			{sortedFilters.map(filter => (
				<EntityPropertySearchTag
					key={uuid()}
					onRemoveSearch={onRemove}
					op={filter.type}
					search={{ value: filter.tag }}
				/>
			))}

			{!disabled && isShowingAddTag && (
				<div className={css(styleSheet.searchContainer)}>
					<SimpleAutoCompleteSearchField
						dropdownContentStyle={styleSheet.dropdownItemsContainer}
						onBlur={hideInput}
						// @ts-ignore
						onItemSelected={onTagSelected}
						style={styleSheet.search}
						onRenderRightAccessory={renderSearchRightAccessory}
						pageSize={10}
						resultsLimit={10}
						type={ResourceAutoCompleteViewModelType.Tag}
					/>
					<button className={css(baseStyleSheet.brandLink, styleSheet.searchCancelButton)} onClick={hideInput}>
						Cancel
					</button>
				</div>
			)}
			{!disabled && !isShowingAddTag && (
				<AddTagButton className={css(styleSheet.addTagCta)} disabled={false} onClick={onAddTagClick} />
			)}
		</div>
	);
};

export const FiltersEditor = observer(FiltersEditorBase);
