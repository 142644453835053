import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { EditAutomationTemplateContext } from '../../../../models/Automations';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { navigation } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { RoundAddIcon } from '../../svgs/icons/RoundAddIcon';
import { EditAutomationSegmentAdditionalFilters, EditAutomationSegmentPrimaryFilter } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	automationTemplate?: Api.AutomationTemplateViewModel<Api.UserSessionContext> | null;
	className?: string;
	styles?: StyleDeclarationValue[];
	caseStatement: Api.IAutomationStepCaseStatement;
	switchStep: Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>;
}

export const EditAutomationTemplateSegmentHeader: React.FC<IProps> = props => {
	const { className, styles = [], caseStatement, switchStep, automationTemplate } = props;
	const { onDraftUpdated } = React.useContext(EditAutomationTemplateContext);
	const { logEvent, logApiError } = useEventLogging('EditAutomationTemplateSegmentHeader');
	const errorMessages = useErrorMessages();

	// #region config filters
	const [primaryCriteria, setPrimaryCriteria] = React.useState<Api.IContactFilterCriteria>(() => {
		// @ts-ignore
		const sorted = Api.VmUtils.sortContactFilterCriteria(caseStatement?.filter?.criteria);
		const criteriaCollection = [...sorted.filters, ...sorted.searches];
		const first = criteriaCollection?.[0];
		return first || null;
	});

	const [additionalTagFilters, setAdditionalTagFilters] = React.useState<Api.IContactFilterCriteria[]>(() => {
		// @ts-ignore
		const sorted = Api.VmUtils.sortContactFilterCriteria(caseStatement?.filter?.criteria);
		return sorted.searches.filter(x => Api.VmUtils.isTagSearchContactFilterCriteria(x) && x !== primaryCriteria);
	});

	const [showAdditionalFilters, , setShowAdditionalFiltersLambda] = useLambda(
		() => additionalTagFilters?.length > 0,
		[]
	);
	// #endregion

	// #region handle filter changes
	const saveCaseStatement = React.useCallback(
		async (primary?: Api.IContactFilterCriteria, additional = additionalTagFilters) => {
			// @ts-ignore
			const next: Api.IContactFilterCriteria = primary
				? {
						criteria: [primary, ...(additional || [])],
						op: Api.FilterOperator.And,
					}
				: null;
			const nextCase: Api.IAutomationStepCaseStatementBase = {
				...(caseStatement || {}),
				filter: next,
				isDefault: !next,
			};

			try {
				logEvent('SaveCaseStatement');
				const caseAction = caseStatement.id
					? switchStep.automationStep.updateCase(caseStatement.id, nextCase)
					: switchStep.automationStep.addCase(nextCase);
				await caseAction;
				onDraftUpdated?.();
			} catch (error) {
				// @ts-ignore
				logApiError('SaveCaseStatement-Error', error);
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[caseStatement, additionalTagFilters, primaryCriteria, switchStep, onDraftUpdated]
	);

	const onPrimaryCriteriaChanged = React.useCallback(
		async (nextPrimaryCriteria?: Api.IContactFilterCriteria) => {
			if (!nextPrimaryCriteria) {
				// default case
				// @ts-ignore
				setPrimaryCriteria(null);
				// @ts-ignore
				saveCaseStatement(null);
				return;
			}

			setPrimaryCriteria(nextPrimaryCriteria);

			if ((!!nextPrimaryCriteria.property && !!nextPrimaryCriteria.value) || !!nextPrimaryCriteria.criteria) {
				saveCaseStatement(nextPrimaryCriteria);
			}
		},
		[saveCaseStatement]
	);

	const onTagCriteriaCollectionChanged = React.useCallback(
		(criteria: Api.IContactFilterCriteria[]) => {
			setAdditionalTagFilters(criteria);
			saveCaseStatement(primaryCriteria, criteria);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[saveCaseStatement, additionalTagFilters, primaryCriteria]
	);
	// #endregion

	const primaryFilterStyles = React.useRef<StyleDeclarationValue[]>([styleSheet.primaryFilter]).current;
	const primaryFilterOperatorSelectBoxStyles = React.useRef<StyleDeclarationValue[]>([
		styleSheet.primaryFilterOperatorSelectBox,
	]).current;
	return (
		<div
			className={`${css(styleSheet.container, ...styles)} edit-automation-template-segment-header ${className || ''}`}
		>
			<div className={css(styleSheet.initialFilter)}>
				<div className={css(styleSheet.if)}>IF</div>
				<EditAutomationSegmentPrimaryFilter
					caseStatement={caseStatement}
					criteria={primaryCriteria}
					filterOperatorSelectBoxStyles={primaryFilterOperatorSelectBoxStyles}
					onCriteriaChanged={onPrimaryCriteriaChanged}
					styles={primaryFilterStyles}
					switchStep={switchStep}
					impersonationContext={automationTemplate?.impersonationContext}
				/>
			</div>
			{showAdditionalFilters ? (
				<div className={css(baseStyleSheet.verticalStack, styleSheet.tagFilters)}>
					<EditAutomationSegmentAdditionalFilters
						initialCriteria={additionalTagFilters}
						onCriteriaChanged={onTagCriteriaCollectionChanged}
					/>
				</div>
			) : (
				!!primaryCriteria && (
					<div className={css(styleSheet.footer)}>
						<button
							className={css(baseStyleSheet.horizontalStack, styleSheet.addFilterButton)}
							onClick={setShowAdditionalFiltersLambda(true)}
						>
							<RoundAddIcon fillColor={navigation} />
							<span>Add other filters</span>
						</button>
					</div>
				)
			)}
		</div>
	);
};
