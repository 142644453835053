import * as Api from '@ViewModels';
import { FavoriteFor, TagViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { useCreateTag } from '../../../../../queries';
import { baseStyleSheet } from '../../../../styles/styles';
import { DeprecatedCloseButton } from '../../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { IModalProps, Modal } from '../../../Modal';
import { TextInput } from '../../../TextInput';
import { styleSheet } from './styles';

interface IProps extends IModalContext<TagViewModel> {
	className?: string;
	impersonationContext?: Api.IImpersonationContext;
}

function _AddTag({ className, parentModal, impersonationContext }: IProps) {
	const { logInput, logApiError } = useEventLogging('AddTag');
	const [errorMessage, setErrorMessage] = React.useState<string>();
	const [tagValue, setTagValue] = React.useState<string>('');
	const addTagMutation = useCreateTag({
		// @ts-ignore
		impersonationContext,
		onError: error => {
			logApiError('Add-Error', error);
			setErrorMessage(error.systemMessage);
		},
		onSuccess: data => {
			onRequestClose(data, false);
		},
	});
	const onRequestClose = (result?: TagViewModel, cancel = false) => {
		parentModal?.onRequestClose(result, cancel);
	};
	const onCloseClicked = () => {
		// @ts-ignore
		onRequestClose(null, true);
	};
	const onTagValueChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		setErrorMessage(null);
		setTagValue(ev.target.value);
	};
	const onTagValueTextInputFocus = () => {
		// @ts-ignore
		setErrorMessage(null);
	};
	const onAddButtonClicked = () => {
		const value = tagValue.trim();
		if (!value) {
			setErrorMessage('Please enter a value for this tag');
			return;
		}
		logInput('Add', 'Click');
		addTagMutation.mutate({ accountTag: { favoriteFor: FavoriteFor.None, tag: value } });
	};
	const onTagValueTextInputKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
		if (ev.key === 'Enter') {
			onAddButtonClicked();
		}
	};
	return (
		<div className={`${css(styleSheet.addTag)} ${className || ''}`}>
			<div className={css(styleSheet.addTagHeader)}>
				<DeprecatedCloseButton disabled={addTagMutation.isLoading} onClick={onCloseClicked} />
			</div>
			<div className={css(styleSheet.addTagTitle)}>Add Tag</div>
			<div>
				<TextInput
					autoFocus={true}
					className={css(styleSheet.addTagTextInput)}
					disabled={addTagMutation.isLoading}
					inputId='add-tag-input'
					onChange={onTagValueChanged}
					onFocus={onTagValueTextInputFocus}
					onKeyDown={onTagValueTextInputKeyDown}
					placeholder='Enter the new tag here'
					type='text'
					value={tagValue}
				/>
			</div>
			<div className={css(styleSheet.addTagFooter)}>
				<button
					className={css(styleSheet.addTagFooterAddButton, baseStyleSheet.ctaButton)}
					disabled={addTagMutation.isLoading}
					onClick={onAddButtonClicked}
				>
					<span>Add</span>
				</button>
				{errorMessage ? (
					<span className={css(styleSheet.addTagFooterErrorMessage, baseStyleSheet.truncateText)} title={errorMessage}>
						{errorMessage}
					</span>
				) : null}
			</div>
			{addTagMutation.isLoading ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' /> : null}
		</div>
	);
}

const AddTagAsObserver = observer(_AddTag);
export const AddTag = inject(ModalChildComponentContextKey)(AddTagAsObserver);

export const AddTagModal = (props: IProps & { modalProps?: IModalProps }) => {
	const { modalProps = {}, ...restProps } = props;
	return (
		<Modal {...modalProps}>
			<AddTag className={css(styleSheet.addTagModalRoot)} {...restProps} />
		</Modal>
	);
};
