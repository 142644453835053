import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import {
	CampaignsApprovalViewModel,
	ICampaign,
	IOperationResult,
	IOperationResultNoValue,
} from '../../../../viewmodels/AppViewModels';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../../components/LocalNotificationObserver/WithNotificationService';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { SendNowIcon } from '../../../components/svgs/icons/SendNowIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { EditSendOptions } from '../../templates/EditSendOptions';
import { styleSheet } from './styles';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IErrorMessageComponentProps,
		INotificationServiceComponentProps<ICampaign[]> {
	approval: CampaignsApprovalViewModel;
	className?: string;
	disableHeader?: boolean;
	onApproved?(): void;
	onScheduleCtaClicked?(e: React.MouseEvent<HTMLElement>): void;
	requireEstimateToSend?: boolean;
	scheduleCtaText?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	initialSendDate?: Date;
}

class _BulkEmailApprovalSetSchedule extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			initialSendDate: props.approval?.activeCampaign
				? new Date(props.approval.activeCampaign.schedule.startDate)
				: null,
		};
	}

	public render() {
		const { className, styles, approval, disableHeader, scheduleCtaText, requireEstimateToSend } = this.props;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} bulk-email-approval-set-schedule ${
					className || ''
				}`}
			>
				{!disableHeader && <MultiContainerHeader fullscreenHeader='Pick a Time' />}
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.headerContent)}>
						<div>
							<SendNowIcon className={css(styleSheet.headerIcon)} />
						</div>
						<div className={css(styleSheet.headerMessage)}>
							<div className={css(styleSheet.headerMessageTitle)}>Schedule Send</div>
							{this.renderScheduleSendMessage()}
						</div>
					</div>
				</div>
				<EditSendOptions
					disableSendNow={true}
					emailComposer={approval?.campaignComposer}
					hideHeader={true}
					onScheduleCtaClicked={this.approvalComplete}
					requireEstimateToSend={requireEstimateToSend}
					styles={[styleSheet.sendOptions]}
					submitButtonText={scheduleCtaText || 'Approve'}
				/>
			</div>
		);
	}

	private approvalComplete = (e: React.MouseEvent<HTMLElement>): Promise<IOperationResult<ICampaign>> => {
		const { approval, logApiError, onApproved, errorMessages, postNotification, onScheduleCtaClicked } = this.props;
		onScheduleCtaClicked?.(e);
		if (!e.defaultPrevented && !approval.isBusy) {
			const campaign = approval.activeCampaign;
			const promise = approval.approveCampaign();
			if (promise) {
				promise
					.then(() => {
						postNotification({
							info: [campaign.toJs()],
							topic: Topics.EDIT_CAMPAIGNS_ITEM,
						});
						if (onApproved) {
							onApproved();
						}
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError(`Approve-Error`, error);
						errorMessages.pushApiError(error);
					});
				return promise;
			}
		}
		return null;
	};

	private renderScheduleSendMessage() {
		const { initialSendDate } = this.state;
		if (!initialSendDate) {
			return null;
		}
		const dateStringValue = moment(initialSendDate).format('MMMM Do');
		const datePassed = moment(initialSendDate).isBefore(moment(), 'day');
		return datePassed ? (
			<span>
				Please pick a new date to send this campaign. The date suggested by your Success Manager has passed &nbsp;
				<span>(</span>
				<span className={css(baseStyleSheet.fontBold)}>{dateStringValue}</span>
				<span>)</span>
			</span>
		) : (
			<span>
				Your success manager has recommended &nbsp;
				<span className={css(baseStyleSheet.fontBold)}>{dateStringValue}</span>
				&nbsp; to send the campaign; you can accept or choose a different date.
			</span>
		);
	}
}

const BulkEmailApprovalSetScheduleAsObserver = observer(_BulkEmailApprovalSetSchedule);
const BulkEmailApprovalSetScheduleWithContext = inject(ErrorMessagesViewModelKey)(
	BulkEmailApprovalSetScheduleAsObserver
);
const BulkEmailApprovalSetScheduleWithLocalNotifications = withNotificationService(
	BulkEmailApprovalSetScheduleWithContext
);
export const BulkEmailApprovalSetSchedule = withEventLogging(
	BulkEmailApprovalSetScheduleWithLocalNotifications,
	'BulkEmailApprovalSetSchedule'
);
