import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import moment from 'moment';
import React from 'react';
import { Noop } from '../../../../extViewmodels/Utils';
import { CampaignType } from '../../../../models/AdminModels';
import { CalendarType } from '../../../containers/ContentCalendar/models';
import { brandPrimary, brandPrimaryHover, magicOrange } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { CalendarDateIcon } from '../../svgs/icons/CalendarDateIcon';
import { ClockAlertIcon } from '../../svgs/icons/ClockAlertIcon';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { styleSheet } from './styles';

const scheduleList = [
	Api.EmailSendStatus.Sending,
	Api.EmailSendStatus.Ready,
	Api.PostStatus.Scheduled,
	Api.BlogStatus.Queued,
];
const pendingList = [Api.EmailSendStatus.WaitingForApproval, Api.PostStatus.Pending, Api.BlogStatus.Pending];
const completeList = [
	Api.EmailSendStatus.Complete,
	Api.PostStatus.Succeeded,
	Api.PostStatus.PartiallySucceeded,
	Api.BlogStatus.Completed,
];

const getLabel = (type: CampaignType) => {
	let label = '';
	switch (type) {
		case CampaignType.Email:
			label = 'Email';
			break;
		case CampaignType.Social:
			label = 'Social Post';
			break;
		case CampaignType.Blog:
			label = 'Blog Post';
			break;
		default:
			label = '';
			break;
	}
	return label;
};

export const CampaignTypeLabel = ({ type }: { type: CampaignType }) => {
	return <span className={css(styleSheet.cardLabel)}>{getLabel(type)}</span>;
};

export const CampaignTypeMonthlyLabel = ({ type }: { type: CampaignType }) => {
	return <span className={css(bs.truncateText, styleSheet.cardLabelMonthly)}>{getLabel(type)}</span>;
};
export const CampaignTypeFeaturedLabel = ({ type, isQuarterly }: { type: CampaignType; isQuarterly?: boolean }) => {
	return (
		<div
			className={css(
				bs.truncateText,
				styleSheet.cardLabelMonthly,
				styleSheet.featuredHeader,
				isQuarterly ? styleSheet.cardLabelQuarterlyFeatured : undefined
			)}
		>
			Featured {getLabel(type)}
		</div>
	);
};

export const CampaignFooter = ({
	sendStatus,
	name,
	children,
	calendarType,
}: React.PropsWithChildren<{
	sendStatus: Api.EmailSendStatus | Api.PostStatus | Api.BlogStatus;
	name: string;
	calendarType: CalendarType;
}>) => {
	const styles: StyleDeclarationValue[] = [];
	const isSchedule = scheduleList.includes(sendStatus);
	const isPending = pendingList.includes(sendStatus);
	const isComplete = completeList.includes(sendStatus);
	if (isSchedule) {
		styles.push(styleSheet.footerInnerScheduled);
	}
	if (isPending) {
		styles.push(styleSheet.footerInnerPending);
	}
	if (isComplete) {
		styles.push(styleSheet.footerInnerComplete);
	}
	let icon: React.ReactNode = null;
	let text = '';
	if (isComplete) {
		icon = <SuccessCheckIcon className={css(styleSheet.footerIcon)} />;
		text = 'Sent by:';
	}
	if (isPending) {
		icon = <ClockAlertIcon fillColor={magicOrange} className={css(styleSheet.footerIcon)} />;
		text = 'Pending for:';
	}
	if (isSchedule) {
		icon = <CalendarDateIcon fillColor={brandPrimaryHover} className={css(styleSheet.footerIcon)} />;
		text = 'Scheduled by:';
	}

	return (
		<div className={css(styleSheet.footerInner, ...styles)}>
			<div>
				<div className={css(styleSheet.maxWidthContainer)}>
					<span>{text}</span> {calendarType === CalendarType.Month ? <br /> : null}{' '}
					<span className={css(bs.boldOnHighDpi)}>{name}</span>{' '}
				</div>
				{icon}
			</div>
			<div>{children}</div>
		</div>
	);
};
/**
 * @TODO remove this when the checkbox is implemented
 */
const hideCheckBox = true;
export const SuggestionFooter = ({
	value,
	onClick,
}: { value: boolean; onClick: (e: React.MouseEvent<HTMLInputElement>) => void }) => {
	return (
		<div className={css(styleSheet.footerInner, styleSheet.suggestionFooterInner)}>
			{!hideCheckBox ? (
				<Checkbox id='suggested' label='Schedule' checked={value} onClick={onClick} onChange={Noop}>
					<span className={css(styleSheet.scheduleLabel)}>Schedule</span>
				</Checkbox>
			) : (
				<div className={css(styleSheet.scheduleLabelLink, bs.boldOnHighDpi)}>
					<span>Schedule Content</span>
					<DisclosureIcon
						className={css(styleSheet.calendarCardApproveForwardIcon)}
						fillColor={brandPrimary}
						type='chevron'
					/>
				</div>
			)}
		</div>
	);
};

export const CalendarEventCardIsNotifyClientButton = ({
	message,
	calendarType,
	onNotifyClientClicked,
}: {
	message: string;
	calendarType: CalendarType;
	onNotifyClientClicked: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
	const buttonRef = React.useRef<HTMLButtonElement>(null);
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		onNotifyClientClicked(e);
	};
	return (
		<span
			role='button'
			tabIndex={0}
			ref={buttonRef}
			onKeyUp={e => {
				e.preventDefault();
				e.stopPropagation();
				if (e.key === 'Enter' || e.key === ' ') {
					buttonRef.current.click();
				}
			}}
			className={css(
				styleSheet.calendarCardApprove,
				calendarType === CalendarType.Quarterly ? styleSheet.calendarCardApproveQuarterly : undefined
			)}
			onClick={handleClick}
		>
			<span className={css(bs.boldOnHighDpi)}>{message}</span>
			<DisclosureIcon
				className={css(styleSheet.calendarCardApproveForwardIcon)}
				fillColor={brandPrimary}
				type='chevron'
			/>
		</span>
	);
};

export const CalendarEventCardPendingDecorator = ({
	message,
	calendarType,
}: { message: string; calendarType: CalendarType }) => {
	return (
		<p
			className={css(
				styleSheet.calendarCardApprove,
				calendarType === CalendarType.Quarterly ? styleSheet.calendarCardApproveQuarterly : undefined
			)}
		>
			<span className={css(bs.boldOnHighDpi)}>{message}</span>
			<DisclosureIcon
				className={css(styleSheet.calendarCardApproveForwardIcon)}
				fillColor={brandPrimary}
				type='chevron'
			/>
		</p>
	);
};

export const HeaderDate = (props: { date: string | React.ReactNode; calendarType: CalendarType; id: string }) => {
	if (props.calendarType !== CalendarType.Quarterly) {
		return null;
	}
	return (
		<span className={css(bs.flex, bs.justifyEnd, bs.relative, bs.boldOnHighDpi)}>
			{typeof props.date === 'string' ? (
				<time dateTime={moment(props.date).format('DD')}>{moment(props.date).format('DD')}</time>
			) : (
				props.date
			)}
		</span>
	);
};
