import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { MoreMenu, MoreMenuItem } from '@WebComponents/MoreMenu';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ConditionalAccess } from '../../../../admin/components/ConditionalAccess';
import { asAdminUserSession } from '../../../../admin/utils';
import { ILocationState } from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { AdminPermission } from '../../../../viewmodels/AdminViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TextInputModal } from '../../TextInputModal';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { AutomationDisableCancelModal } from '../AutomationDisableCancelModal';
import { AutomationSteps } from '../AutomationSteps';
import { useAutomationCardData } from './hooks';
import {
	AutomationProgressStats,
	AutomationStatus,
	AutomationTrigger,
	AutomationYoloModeIndicator,
} from './presentation';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IErrorMessageComponentProps,
		IToasterComponentProps {
	automationTemplate: Api.AutomationTemplateViewModel;
	automationTemplates?: Api.AutomationTemplatesViewModel;
	className?: string;
	hideActiveCount?: boolean;
	hideStatus?: boolean;
	maxStepsToDisplay?: number;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	/** Return true if consumer will handle editing */
	onRequestEdit?(): boolean;
	styles?: StyleDeclarationValue[];
}

export enum AutomationTemplateAction {
	ClearPendingAutomations = 'clearPendingAutomations',
	Copy = 'copy',
	Delete = 'delete',
	Disable = 'disable',
	Edit = 'edit',
	Enable = 'enable',
	RestoreCancelledKeyFacts = 'restoreCancelledKeyFacts',
}

const CancelInProgressConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		title: 'Yes, Cancel All Automations',
	},
	{
		isCancelLink: true,
		title: 'No, I changed my mind',
	},
];

const _AutomationCard = ({
	automationTemplates,
	automationTemplate,
	className,
	errorMessages,
	logApiError,
	logEvent,
	logInput,
	hideActiveCount,
	hideStatus,
	history,
	match,
	maxStepsToDisplay,
	onRequestEdit,
	onClick,
	styles,
	toaster,
}: IProps) => {
	const userSession = useUserSession();
	const [showCancelInProgressModal, setShowCancelInProgressModal] = React.useState(false);
	const [showClearPendingConfirmation, setShowClearPendingConfirmation] = React.useState(false);
	const [showDuplicationNameInputModal, setShowDuplicationNameInputModal] = React.useState(false);
	const [showDisableConfirmation, setShowDisableConfirmation] = React.useState(false);
	const adminUserSession = getAdminUserSession();
	const {
		automationId,
		automationName,
		completedContactString,
		inProgressContactString,
		statusIcon,
		trigger,
		triggerDescription,
		showCompletedButton,
		showInProgressButton,
	} = useAutomationCardData(automationTemplate);

	const navigateToEditor = () => {
		logInput('EditAutomation', 'Click', { id: automationTemplate.id });
		const handledExternally = onRequestEdit?.();
		if (!handledExternally) {
			const locationState: ILocationState<Api.AutomationTemplateViewModel, any> = { viewModel: automationTemplate };
			history?.push({
				pathname: `${match.url}/${automationTemplate.id}`,
				state: locationState,
			});
		}
	};

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		if (onClick) {
			onClick(e);
		}
		if (!e.defaultPrevented) {
			navigateToEditor();
		}
	};

	const onMenuItemClicked = (menuItem: AutomationTemplateAction) => {
		switch (menuItem) {
			case AutomationTemplateAction.Copy: {
				logInput('DuplicateAutomationTemplate', 'Click', {
					id: automationTemplate.id,
				});
				setShowDuplicationNameInputModal(true);
				break;
			}
			case AutomationTemplateAction.Delete: {
				logInput('DeleteAutomationTemplate', 'Click', {
					id: automationTemplate.id,
				});
				automationTemplates?.deleteTemplate(automationTemplate).catch((error: Api.IOperationResultNoValue) => {
					logApiError('DeleteAutomationTemplate-Error', error);
					errorMessages.pushApiError(error);
				});
				break;
			}
			case AutomationTemplateAction.Enable: {
				automationTemplate?.setStatus(Api.TemplateStatus.Published).catch((error: Api.IOperationResultNoValue) => {
					logApiError('EnablingAutomationTemplate-Error', error);
					errorMessages.pushApiError(error);
				});
				break;
			}
			case AutomationTemplateAction.Disable: {
				if (automationTemplate?.stats?.inProgress >= 0) {
					setShowDisableConfirmation(true);
					return;
				}
				break;
			}
			case AutomationTemplateAction.Edit: {
				navigateToEditor();
				break;
			}
			case AutomationTemplateAction.RestoreCancelledKeyFacts: {
				logInput('RestoreCancelledKeyFactsForAutomationTemplate', 'Click', {
					id: automationTemplate.id,
				});
				automationTemplates
					?.restoreKeyFacts(automationTemplate)
					.then(() => {
						toaster?.push({
							message: 'Any future key facts have been restored.',
							type: 'successMessage',
						});
					})
					.catch((error: Api.IOperationResultNoValue) => {
						logApiError('RestoreCancelledKeyFactsForAutomationTemplate-Error', error);
						errorMessages.pushApiError(error);
					});

				break;
			}
			case AutomationTemplateAction.ClearPendingAutomations: {
				setShowClearPendingConfirmation(true);
				break;
			}
			default: {
				break;
			}
		}
	};
	const onPublishChangesClicked = () => {
		logInput('PublishChanges', 'Click', { id: automationTemplate.id });
		navigateToEditor();
	};
	const onCancelAutomationsClick = () => {
		setShowCancelInProgressModal(true);
	};
	const cancelAutomationInProgress = async () => {
		const numAutomations = automationTemplate.stats?.inProgress;

		await automationTemplate
			.cancelAllInProgress()
			?.then(() => {
				toaster.push({
					message: `${numAutomations} automation${numAutomations > 1 ? 's' : ''} cancelled`,
					type: 'successMessage',
				});
			})
			?.catch((err: Api.IOperationResultNoValue) => {
				errorMessages.pushApiError(err);
			});
	};
	const onCancelInProgressRequestClose = async (_?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
		if (!cancel) {
			await cancelAutomationInProgress();
		}
		setShowCancelInProgressModal(false);
	};

	const duplicateAutomation = async (duplicateName: string) => {
		logEvent('DuplicateAutomation', {
			id: automationTemplate.id,
			nameLength: duplicateName.length,
		});
		await automationTemplates
			?.duplicateTemplate(automationTemplate, duplicateName)
			?.catch((error: Api.IOperationResultNoValue) => {
				logApiError('DuplicateAutomationTemplate-Error', error);
				errorMessages.pushApiError(error);
			});
	};

	const onDuplicateAutomationNameInputModalRequestClose = async (result?: string, cancel?: boolean) => {
		if (!cancel && !!result) {
			await duplicateAutomation(result);
		}
		setShowDuplicationNameInputModal(false);
	};

	const onDisableModalClosed = () => {
		setShowDisableConfirmation(false);
	};

	const clearPendingAutomations = async () => {
		await automationTemplate
			.clearPendingAutomations()
			?.then(opResult => {
				const cleared = opResult.value;
				toaster.push({
					message: `${cleared} contact${cleared > 1 ? 's' : ''} had this automation removed.`,
					type: 'successMessage',
				});
			})
			?.catch((err: Api.IOperationResultNoValue) => {
				errorMessages.pushApiError(err);
			});
	};

	const onClearPendingAutomationsRequestClose = async (_?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
		if (!cancel) {
			await clearPendingAutomations();
		}
		setShowClearPendingConfirmation(false);
	};

	function getAdminUserSession() {
		return asAdminUserSession(userSession);
	}

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} automation-card ${className || ''}`}>
			<button className={css(baseStyleSheet.truncateText, styleSheet.name)} onClick={handleClick}>
				<span>{automationTemplate.name}</span>
			</button>
			<MoreMenu disabled={automationTemplate.isBusy} menuButtonClassName={css(styleSheet.moreMenu)}>
				{automationTemplate?.scope === Api.TemplateScope.Industry ? (
					<>
						<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.Edit)}>View</MoreMenuItem>
						<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.Copy)}>Duplicate</MoreMenuItem>
					</>
				) : null}
				<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.Edit)}>
					{automationTemplate.status === Api.TemplateStatus.Archived || !automationTemplate.canEdit ? 'View' : 'Manage'}
				</MoreMenuItem>
				{automationTemplate.canEdit ? (
					<>
						<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.Copy)}>Duplicate</MoreMenuItem>
						{automationTemplate.status === Api.TemplateStatus.Published ? (
							<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.Disable)}>Disable</MoreMenuItem>
						) : null}
						{automationTemplate.status === Api.TemplateStatus.Disabled ? (
							<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.Enable)}>Enable</MoreMenuItem>
						) : null}
						{(automationTemplate.status !== Api.TemplateStatus.Archived &&
							automationTemplate.status !== Api.TemplateStatus.Published) ||
						(automationTemplate.status === Api.TemplateStatus.Published &&
							automationTemplate.stats?.inProgress === 0) ? (
							<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.Delete)}>
								{automationTemplate.hasPublishedVersion ? 'Archive' : 'Delete'}
							</MoreMenuItem>
						) : null}
					</>
				) : null}
				{adminUserSession?.hasPermission(AdminPermission.CancelAutomations) &&
				!!automationTemplate.hasPublishedVersion &&
				automationTemplate.publishedTriggerReference?._type === Api.AutomationTriggerType.ResourceSelector ? (
					<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.RestoreCancelledKeyFacts)}>
						Restore Cancelled Key Facts
					</MoreMenuItem>
				) : null}
				{adminUserSession?.hasPermission(AdminPermission.CancelAutomations) ? (
					<MoreMenuItem onClick={() => onMenuItemClicked(AutomationTemplateAction.ClearPendingAutomations)}>
						Clear Pending
					</MoreMenuItem>
				) : null}
			</MoreMenu>
			{!hideStatus && automationTemplate?.scope !== Api.TemplateScope.Industry && (
				<div className={css(styleSheet.splitSection)}>
					<AutomationStatus name={automationName} icon={statusIcon} />

					<div className={css(styleSheet.sectionInner)}>
						<div className={css(styleSheet.sectionLabel)}>Creator</div>
						<div
							className={css(
								baseStyleSheet.horizontalStack,
								baseStyleSheet.truncateText,
								styleSheet.statusSectionContent
							)}
						>
							{Api.VmUtils.getDisplayName(automationTemplate?.creator, true)}
						</div>
					</div>
				</div>
			)}

			<AutomationTrigger trigger={trigger} triggerDescription={triggerDescription} />

			<div className={css(styleSheet.section)}>
				<div className={css(styleSheet.sectionLabel)}>
					{`${automationTemplate.publishedStepReferences?.length} Step${
						automationTemplate.publishedStepReferences?.length > 1 ? 's' : ''
					} - ${automationTemplate.runtimeDays}-Day Runtime`}
				</div>
				<div className={css(baseStyleSheet.horizontalStack)}>
					<AutomationSteps automationTemplate={automationTemplate} maxStepsToDisplay={maxStepsToDisplay} />
				</div>
			</div>
			{!hideActiveCount && automationTemplate?.scope !== Api.TemplateScope.Industry && (
				<AutomationProgressStats
					automationId={automationId}
					showCompletedButton={showCompletedButton}
					showInProgressButton={showInProgressButton}
					completedContactString={completedContactString}
					inProgressContactString={inProgressContactString}
				/>
			)}
			<footer className={css(styleSheet.section, styleSheet.publishButtonContainer)}>
				{!!automationTemplate.hasDraftVersion && !!automationTemplate.canEdit && (
					<button
						className={css(baseStyleSheet.ctaButtonSmall, styleSheet.publishButton)}
						onClick={onPublishChangesClicked}
					>
						<span>Publish Changes</span>
					</button>
				)}
			</footer>
			{automationTemplate.stats?.inProgress > 0 && (
				<ConditionalAccess
					permission={AdminPermission.OnlyForDevelopers}
					or={[AdminPermission.SupportLevel3, AdminPermission.CancelAutomations]}
				>
					<div className={css(styleSheet.section)}>
						<button
							className={css(baseStyleSheet.ctaButtonDestructiveSmall, styleSheet.cancelAutomationButton)}
							disabled={automationTemplate.gettingInProgressCount}
							onClick={onCancelAutomationsClick}
						>
							<span>
								{automationTemplate.gettingInProgressCount ? (
									<LoadingSpinner type='tiny' />
								) : (
									`Cancel Automation${automationTemplate.stats?.inProgress > 1 ? 's' : ''}`
								)}
							</span>
						</button>
						{showCancelInProgressModal && (
							<ConfirmationDialog
								icon={<WarningIcon />}
								modalProps={{
									isOpen: showCancelInProgressModal,
									onRequestClose: onCancelInProgressRequestClose,
								}}
								options={CancelInProgressConfirmationOptions}
								stackButtonsVertically={true}
								title={<span>Are you sure?</span>}
							>
								<div style={{ textAlign: 'center' }}>
									{`This will cancel ${
										automationTemplate.stats?.inProgress > 1
											? `all ${automationTemplate.stats.inProgress} automations`
											: 'the automation'
									} in process`}
									<br />
									and once you&apos;ve cancelled, it cannot be undone.
								</div>
							</ConfirmationDialog>
						)}
					</div>
				</ConditionalAccess>
			)}
			<AutomationYoloModeIndicator trigger={trigger} />
			{!!showDuplicationNameInputModal && (
				<TextInputModal
					cta='Duplicate'
					modalProps={{
						isOpen: !!showDuplicationNameInputModal,
						onRequestClose: onDuplicateAutomationNameInputModalRequestClose,
					}}
					placeholderText='New Automation name'
					title={`Duplicate Automation: "${automationTemplate?.name}"`}
				/>
			)}
			<AutomationDisableCancelModal
				automationTemplate={automationTemplate}
				isOpen={showDisableConfirmation}
				onRequestClose={onDisableModalClosed}
			/>
			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: showClearPendingConfirmation,
					onRequestClose: onClearPendingAutomationsRequestClose,
				}}
				options={[
					{ isDestructive: true, representedObject: true, title: 'Confirm' },
					{ isCancel: true, representedObject: false, title: 'Cancel' },
				]}
				title={
					<span>
						This will clear any suggested usages of this automation from contacts.
						<br />
						<span className={css(baseStyleSheet.fontBold)}>These suggestions cannot be restored.</span>
					</span>
				}
			/>
			{automationTemplate.isBusy ||
				(automationTemplate.cancelling && (
					<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
				))}
		</div>
	);
};

const AutomationCardAsObserver = observer(_AutomationCard);
const AutomationCardWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey
)(AutomationCardAsObserver);
const AutomationCardWithRouter = withRouter(AutomationCardWithContext);
export const AutomationCard = withEventLogging(AutomationCardWithRouter, 'AutomationCard');
