import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, brandSecondary, titles } from '../../../styles/colors';
import { CssSelectors, Layouts, cardStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	addFilterButton: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
		justifyContent: 'center',
	},
	container: {
		...cardStyle,
		...Layouts.verticalStack(15),
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 14,
		overflow: 'visible',
		padding: '15px 20px',
	},
	filterSearchField: {
		...CssSelectors.allDescendants(
			{
				width: 200,
			},
			'.autocomplete-search-field-input'
		),
		...CssSelectors.allDescendants(
			{
				flexShrink: 0,
			},
			'.svg-icon'
		),
		...CssSelectors.allDescendants(
			{
				minWidth: 0,
			},
			'input'
		),
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	if: {
		color: brandSecondary,
	},
	initialFilter: {
		...CssSelectors.allChildren(
			{
				maxWidth: '100%',
			},
			'div'
		),
		...Layouts.horizontalStack(6),
	},
	primaryFilter: {
		...CssSelectors.allChildren(
			{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			'div'
		),
		flexGrow: 1,
	},
	primaryFilterAlt: {
		flexGrow: 1,
	},
	primaryFilterOperatorSelectBox: {
		maxWidth: 'inherit',
	},
	tagFilters: {},
	tagFiltersInput: {
		maxWidth: 'auto',
		width: '100%',
	},
	tagFiltersLabel: {
		':not(first-of-kind)': {
			marginTop: 10,
		},
		color: titles,
		fontSize: 14,
		marginBottom: 10,
	},
});
