import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, brandSecondary, header, mention, nameCircles } from '../../styles/colors';
import { Layouts } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	dragContainer: {
		alignItems: 'center',
		background: mention,
		border: `2px dashed rgba(0,170,232, 1)`,
		borderRadius: '6px',
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		height: '250px',
		marginBottom: 20,
		opacity: 1,
		textAlign: 'center',
		width: '510px',
	},
	dragContainerHover: {
		border: `4px dashed rgba(0,170,232, 1)`,
	},
	dragFooter: {
		color: brandSecondary,
		fontSize: '12px',
	},
	dragInfo: {
		marginTop: '60px',
	},
	dragText: {
		color: '#046cb6',
		fontSize: '18px',
	},
	logoRemove: {
		left: 'auto',
		marginRight: 12,
		position: 'absolute',
		right: 0,
		top: '50%',
		transform: 'translateY(-50%)',
	},
	noMargin: {
		margin: 0,
	},
	previewImage: {
		padding: '0 10px',
		width: 124,
	},
	previewRemove: {
		left: 'auto',
		position: 'absolute',
		right: 4,
		top: 10,
	},
	previewImageDescriptionTxt: {
		color: header,
		fontSize: 14,
	},
	previewImageDescriptionWrap: {
		padding: '33px 0 22px',
	},
	previewImageFileName: {
		marginBottom: 12,
		width: 300,
	},
	previewImageWrap: {
		':after': {
			background: nameCircles,
			content: '""',
			height: '100%',
			left: 144,
			position: 'absolute',
			top: 0,
			width: 1,
		},
		...Layouts.horizontalStack(24),
		background: '#fff',
		border: `1px solid ${nameCircles}`,
		borderRadius: 3,
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		marginBottom: 24,
		position: 'relative',
		width: 508,
	},
	title: {
		color: brandPrimaryText,
		fontSize: 18,
		margin: '0 0 16px',
	},
});
