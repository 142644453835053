import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import { styleSheet } from './styles';

interface IProps {
	backgroundStyles?: StyleDeclarationValue[];
	children?: React.ReactNode;
	className?: string;
	disableInnerCircle?: boolean;
	fillColor?: string;
	step?: Api.AutomationStepViewModel;
	styles?: StyleDeclarationValue[];
	type?: 'default' | 'small';
}

export const AutomationTemplateStepIndicatorIcon = observer(
	React.forwardRef<HTMLDivElement, IProps>(function AutomationTemplateStepIndicatorIconBase(props, ref) {
		const { className, styles = [], backgroundStyles, children, fillColor, disableInnerCircle, step, type } = props;
		const size = type === 'small' ? 39 : 62;
		return (
			<div
				className={`automation-template-step-indicator-icon ${className || ''} ${css(styleSheet.container, ...styles)}`}
				ref={ref}
			>
				<SvgIcon className={css(...(backgroundStyles || []))} height={size} width={size}>
					{step?.type === Api.AutomationStepType.Switch ? (
						<>
							<defs>
								<linearGradient id='path-a' x1='50%' x2='50%' y1='0%' y2='100%'>
									<stop offset='0%' stopColor='#59DEFA' />
									<stop offset='100%' stopColor='#BC4DF9' />
								</linearGradient>
							</defs>
							<g fill='none' fillRule='evenodd' transform='translate(1 1)'>
								<circle cx='30' cy='30' r='30' stroke='#00AAE8' />
								<g transform='translate(9 9)'>
									<circle cx='21' cy='21' r='21' fill='url(#path-a)' />
									<path
										fill='#FFF'
										d='M28.9998,28 L25.0014001,28 L25.0014001,25 L28.9998,25 L28.9998,28 Z M17.0006001,28 L13.0002,28 L13.0002,25 L17.0006001,25 L17.0006001,28 Z M19.0008001,12 L23.0012001,12 L23.0012001,15 L19.0008001,15 L19.0008001,12 Z M29.9999,23 L27.9997,23 L27.9997,22 C27.9997,20.346 26.6545655,19 25.0014001,19 L22.0011001,19 L22.0011001,17 L24.0013001,17 C24.5533553,17 25.0014001,16.552 25.0014001,16 L25.0014001,11 C25.0014001,10.448 24.5533553,10 24.0013001,10 L18.0007001,10 C17.4486449,10 17.0006001,10.448 17.0006001,11 L17.0006001,16 C17.0006001,16.552 17.4486449,17 18.0007001,17 L20.0009001,17 L20.0009001,19 L17.0006001,19 C15.3464346,19 14.0003,20.346 14.0003,22 L14.0003,23 L12.0001,23 C11.4480448,23 11,23.448 11,24 L11,29 C11,29.552 11.4480448,30 12.0001,30 L18.0007001,30 C18.5527553,30 19.0008001,29.552 19.0008001,29 L19.0008001,24 C19.0008001,23.448 18.5527553,23 18.0007001,23 L16.0005001,23 L16.0005001,22 C16.0005001,21.449 16.449545,21 17.0006001,21 L25.0014001,21 C25.5514551,21 25.9994999,21.449 25.9994999,22 L25.9994999,23 L24.0013001,23 C23.4492449,23 23.0012001,23.448 23.0012001,24 L23.0012001,29 C23.0012001,29.552 23.4492449,30 24.0013001,30 L29.9999,30 C30.5519552,30 31,29.552 31,29 L31,24 C31,23.448 30.5519552,23 29.9999,23 L29.9999,23 Z'
									/>
								</g>
							</g>
						</>
					) : type === 'small' ? (
						<>
							<defs>
								<circle id='path-b' cx='17.5' cy='17.5' r='17.5' />
								<filter
									id='path-a'
									width='137.1%'
									height='137.1%'
									x='-18.6%'
									y='-18.6%'
									filterUnits='objectBoundingBox'
								>
									<feMorphology in='SourceAlpha' operator='dilate' radius='.5' result='shadowSpreadOuter1' />
									<feOffset in='shadowSpreadOuter1' result='shadowOffsetOuter1' />
									<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2' />
									<feComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1' />
									<feColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0' />
								</filter>
							</defs>
							<g fill='none' fillRule='evenodd' transform='translate(2 2)'>
								<use fill='#000' filter='url(#path-a)' xlinkHref='#path-b' />
								<use fill='#FFF' stroke='#00AAE8' xlinkHref='#path-b' />
							</g>
						</>
					) : (
						<>
							<defs>
								<circle id='group-d' cx='30' cy='30' r='21' />
								<filter
									id='group-c'
									width='128.6%'
									height='128.6%'
									x='-14.3%'
									y='-14.3%'
									filterUnits='objectBoundingBox'
								>
									<feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
									<feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2' />
									<feColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0' />
								</filter>
							</defs>
							<g fill='none' fillRule='evenodd' transform='translate(1 1)'>
								<circle cx='30' cy='30' r='30' stroke={fillColor || brandSecondary} />
								{disableInnerCircle ? null : (
									<>
										<use fill='#000' filter='url(#group-c)' xlinkHref='#group-d' />
										<use fill={fillColor || brandSecondary} xlinkHref='#group-d' />
									</>
								)}
							</g>
						</>
					)}
				</SvgIcon>
				{step?.type !== Api.AutomationStepType.Switch && <div className={css(styleSheet.content)}>{children}</div>}
			</div>
		);
	})
);
