import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext } from '../../../../models';
import { brandSecondary } from '../../../styles/colors';
import { asModalComponent } from '../../Modal';
import { RadioButton } from '../../RadioButton';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

interface IProps extends IModalContext<Api.IFileAttachment> {
	images?: Api.IFileAttachment[];
	initialImageUrl?: string;
}

function _SocialMediaImagesPreview({ images, initialImageUrl }: IProps) {
	const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(
		initialImageUrl ? images.findIndex(i => i.url === initialImageUrl) : 0
	);
	const onImageIndexChange = (index: number) => () => {
		setCurrentImageIndex(index);
	};
	return (
		<>
			{images?.length > 1 ? (
				<div>
					<div className={css(styleSheet.container)}>
						<button onClick={onImageIndexChange(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1)}>
							<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointLeft)} />
						</button>
						<figure className={css(styleSheet.imageContainer)}>
							<img src={images?.[currentImageIndex]?.url} alt={images?.[currentImageIndex]?.fileName} />
						</figure>
						<button onClick={onImageIndexChange(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1)}>
							<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointRight)} />
						</button>
					</div>
					<div className={`${css(styleSheet.tabNavigation)}`}>
						{images.map((_, i) => {
							return (
								<RadioButton
									checked={i === currentImageIndex || false}
									className={css(styleSheet.circle)}
									id={`images-${i}`}
									key={`images-${i}`}
									onChange={onImageIndexChange(i)}
								/>
							);
						})}
					</div>
				</div>
			) : (
				<div className={css(styleSheet.container)}>
					<figure className={css(styleSheet.imageContainer)}>
						<img src={images?.[currentImageIndex]?.url} alt={images?.[currentImageIndex]?.fileName} />
					</figure>
				</div>
			)}
		</>
	);
}

const SocialMediaImagesPreview = observer(_SocialMediaImagesPreview);

export const SocialMediaImagesPreviewModal = asModalComponent(SocialMediaImagesPreview, {
	className: `social-media-images-preview-modal`,
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
