import { StyleDeclarationValue, css } from 'aphrodite';
import { copyToClipboard } from '../../../../models/UiUtils';
import { useToaster } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { PopoverType, TinyPopover } from '../../../components/TinyPopover';
import { QuestionMarkIcon } from '../../../components/svgs/icons/QuestionMarkIcon';
import { SquareLinkIcon } from '../../../components/svgs/icons/SquareLinkIcon';
import { brandPrimaryText } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	anonymousAccessLink: string;
	styles?: StyleDeclarationValue[];
}

export const CopyPreviewLink = ({ anonymousAccessLink, styles = [] }: IProps) => {
	const [showPopover, , setShowPopover] = useLambda(false);
	const toaster = useToaster();

	const previewLink = anonymousAccessLink || '';

	const onCopyLinkToClipboard = () => {
		const success = copyToClipboard(previewLink);
		// @ts-ignore
		toaster.push({
			message: success ? 'Copied to clipboard' : 'Failed to copy anything to clipboard',
			type: success ? 'successMessage' : 'errorMessage',
		});
	};

	if (anonymousAccessLink === '') {
		return null;
	}

	return (
		<div className={`${css(styleSheet.container, ...styles)}`}>
			<TinyPopover
				align='end'
				anchor={
					<QuestionMarkIcon
						fillColor={brandPrimaryText}
						onMouseEnter={setShowPopover(true)}
						onMouseLeave={setShowPopover(false)}
					/>
				}
				anchorStyles={[styleSheet.anchor]}
				dismissOnOutsideAction={true}
				isOpen={showPopover}
				placement={['top', 'right', 'bottom']}
				type={PopoverType.white}
			>
				<p className={css(styleSheet.popover)}>
					{!previewLink
						? 'Save this post in order to generate a preview link'
						: 'Click here for a direct link to preview this post'}
				</p>
			</TinyPopover>

			<button
				disabled={!previewLink}
				className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.copyLinkButton)}
				onClick={onCopyLinkToClipboard}
			>
				<SquareLinkIcon fillColor={brandPrimaryText} />
				<span className={css(styleSheet.previewText)}>Copy Preview Link</span>
			</button>
		</div>
	);
};
