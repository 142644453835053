import { titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { ContactsSelfImportContentPadding } from '../ContactsSelfImport/styles';
import { StyleSheet } from 'aphrodite';

const footerHeight = 90;
export const tableBorderColor = '#979797';

export const styleSheet = StyleSheet.create({
	body: {
		flexGrow: 1,
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
	},
	bodyInner: {
		height: '100%',
		width: '100%',
		overflow: 'auto',
	},
	checkboxPlaceholder: {
		height: 17,
		marginBottom: 20,
	},
	checkboxText: {
		marginBottom: 20,
	},
	container: {
		position: 'relative',
	},
	content: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: `calc(100% - ${footerHeight}px)`,
		padding: `${ContactsSelfImportContentPadding}px ${ContactsSelfImportContentPadding}px 0 ${ContactsSelfImportContentPadding}px`,
		width: '100%',
	},
	ctaContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	footer: {
		background: '#fff',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		display: 'flex',
		flexDirection: 'column',
		height: footerHeight,
		width: '100%',
	},
	header: {
		marginBottom: 10,
	},
	headerArrow: {
		marginLeft: 70,
	},
	headerSubtitle: {
		position: 'relative',
	},
	headerTitle: {
		color: titles,
		fontSize: 16,
		margin: '0 0 5px',
	},
	mergeByNameContainer: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		width: '100%',
		...CssSelectors.allChildren({
			marginBottom: 0,
		}),
	},
	table: {
		borderCollapse: 'collapse',
		fontSize: 12,
		margin: '0',
	},
	tableBodyTrTd: {
		border: `1px solid ${tableBorderColor}`,
		boxSizing: 'border-box',
		padding: 10,
	},
	tableHeadTr: {
		width: '100%',
	},
	tableSampleMessage: {
		color: titles,
		fontSize: 16,
		left: '50%',
		margin: 0,
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
});
