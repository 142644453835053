import { brandPrimary } from '../../../styles/colors';
import { CssSelectors, EnhancedStyleSheet } from '../../../styles/styles';

export const FullscreenModalHeaderHeight = 50;
export const FullscreenModalContentPadding = 20;
export const FullscreenModalTransitionDuration = 600;

export const styleSheet = EnhancedStyleSheet.StyleSheet.create({
	body: {
		height: `calc(100% - ${FullscreenModalHeaderHeight}px)`,
		overflowY: 'auto',
		position: 'relative',
	},
	header: {
		alignItems: 'center',
		background: brandPrimary,
		boxSizing: 'border-box',
		display: 'flex',
		height: FullscreenModalHeaderHeight,
		justifyContent: 'space-between',
		padding: `0 ${FullscreenModalContentPadding}px`,
	},
	headerAccessory: {
		alignItems: 'center',
		display: 'flex',
		width: 90,
	},
	headerAccessoryLeft: {
		justifyContent: 'flex-start',
	},
	headerAccessoryRight: {
		justifyContent: 'flex-end',
	},
	headerTitle: {
		color: '#fff',
		flexGrow: 1,
		fontSize: 20,
	},
	modalContent: {
		'&.&--afterOpen': {
			opacity: 1,
			transform: 'translate3d(0, 0, 0)',
			transition: `${FullscreenModalTransitionDuration}ms ease-in-out`,
			willChange: 'transform',
		},
		'&.&--beforeClose': {
			opacity: 0,
			transform: 'translate3d(0, 100%, 0)',
			transition: `${FullscreenModalTransitionDuration}ms ease-out`,
			willChange: 'transform',
		},
		'-webkit-backface-visibility': 'hidden',
		'-webkit-perspective': 1000,
		'-webkit-transform-style': 'preserve-3d',
		background: '#fff',
		boxShadow: '0 2px 6px 0 rgba(0,0,0,0.20)',
		display: 'block',
		height: '100%',
		left: 0,
		opacity: 0,
		position: 'absolute',
		top: 0,
		transform: 'translate3d(0, 100%, 0)',
		transition: `${FullscreenModalTransitionDuration}ms ease-in-out`,
		width: '100%',
		willChange: 'transform',
	},
	modalOverlay: {
		'&.&--afterOpen': {
			background: 'rgba(1.0, 1.0, 1.0, 0.33)',
			transition: `${FullscreenModalTransitionDuration}ms ease-in-out`,
			willChange: 'background',
		},
		'&.&--beforeClose': {
			background: 'transparent',
			transition: `${FullscreenModalTransitionDuration}ms ease-out`,
			willChange: 'background',
		},
		'-webkit-backface-visibility': 'hidden',
		alignItems: 'center',
		background: 'transparent',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		overflowY: 'hidden',
		transition: `${FullscreenModalTransitionDuration}ms ease-in-out`,
		width: '100%',
	},
	modalPortal: {
		zIndex: 8,
	},
	routerContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		overflow: 'auto',
		width: '100%',
		...CssSelectors.allChildren(
			{
				minHeight: '100%',
				width: '100%',
			},
			'*'
		),
	},
});
