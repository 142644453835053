import { StyleSheet } from 'aphrodite';
import { brandSecondary, label } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	modalBodyText: {
		color: label,
		fontSize: 14,
		lineHeight: '18px',
		marginTop: '0.5rem',
	},
	searchEmployeeBox: {
		fontSize: 14,
		marginTop: '1rem',
	},
	selectAnEmployeeCTA: {
		marginTop: 100,
	},
	selectAnEmployeeModal: {
		maxWidth: 360,
	},
	selectAnEmployeeTitle: {
		color: brandSecondary,
		fontSize: 22,
		fontWeight: 600,
		maxWidth: '80%',
	},
});
