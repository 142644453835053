import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AutomationTemplatesViewModel } from '../../../extViewmodels';
import {
	IEditAutomationTemplateRequest,
	IImpersonationContextComponentProps,
	ILocationState,
	ImpersonationContextKey,
} from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { getDefaultRichContentForTextingStep } from '../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { IModalProps } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { SelectASurveyModal } from '../../components/SelectASurvey';
import { baseStyleSheet } from '../../styles/styles';
import { INavigationItemProps } from '../MainContainer';
import {
	NewAutomationTemplateCard,
	NewLeadAutomationTemplateSetupSettingsModal,
	NewTagAutomationTemplateSetupSettingsModal,
} from './presentation';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any>, IImpersonationContextComponentProps, INavigationItemProps {
	className?: string;
	/** Return true if consumer will handle editing */
	onRequestEdit?(automationTemplate: Api.AutomationTemplateViewModel): boolean;
	styles?: StyleDeclarationValue[];
	templates?: AutomationTemplatesViewModel;
}

const CreateAutomationTemplateFc = (props: IProps) => {
	const {
		routeContainerClassName,
		className,
		onRequestEdit,
		history,
		impersonationContext,
		templates,
		styles = [],
	} = props;
	const userSession = useUserSession();
	const logger = useEventLogging('CreateAutomationTemplate');
	// @ts-ignore
	const { pushApiError: showApiError } = useErrorMessages();
	const automationTemplatesViewModel = React.useRef(
		templates || new AutomationTemplatesViewModel(userSession).impersonate(impersonationContext)
	).current;
	const locationName = React.useRef('Create New Automation').current;

	const createAndEditNewAutomation = React.useCallback(
		async (name?: string, trigger?: Api.IAutomationTrigger) => {
			try {
				const automationTemplate = await automationTemplatesViewModel.create(name, trigger);
				if (trigger?._type === Api.AutomationTriggerType.Texting) {
					// create default first step
					const textingStep: Api.ITextingAutomationStep = {
						_type: 'TextingAutomationStep',
						// @ts-ignore
						content: getDefaultRichContentForTextingStep(
							impersonationContext?.account || userSession.account.toJs(),
							trigger
						),
						name: 'Texting Step',
						schedule: {
							criteria: Api.AutomationStepScheduleCriteria.Immediately,
						},
					};
					await automationTemplate.addStep<Api.IAutomationStep, Api.AutomationStepViewModel>(textingStep);
				}

				if (!onRequestEdit?.(automationTemplate)) {
					const locationState: ILocationState<any, IEditAutomationTemplateRequest> = {
						model: {
							automationTemplate,
						},
					};
					history?.push({
						pathname: `/automations/${automationTemplate.id}`,
						state: locationState,
					});
				}
			} catch (err) {
				// @ts-ignore
				logger.logApiError('CreateAutomationTemplate-Error', err);
				showApiError(err);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onRequestEdit, history, impersonationContext?.account]
	);

	// #region Tag trigger settings modal
	const [tagTriggerSettingsModalProps, setTagTriggerSettingsModalProps] = React.useState<IModalProps>({
		isOpen: false,
		// @ts-ignore
		onRequestClose: null,
	});
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onTagTriggerSettingsModalRequestCloseRef = React.useRef((_?: string, __ = false) => {
		setTagTriggerSettingsModalProps({
			...tagTriggerSettingsModalProps,
			isOpen: false,
		});
	});
	React.useEffect(() => {
		onTagTriggerSettingsModalRequestCloseRef.current = async (tag?: string, cancel = false) => {
			setTagTriggerSettingsModalProps({
				...tagTriggerSettingsModalProps,
				isOpen: false,
			});
			if (!cancel && tag) {
				const tagTrigger = {
					// @ts-ignore
					...automationTemplatesViewModel.availableTriggers.TagAutomationTrigger.trigger,
					tag,
				};
				createAndEditNewAutomation(undefined, tagTrigger);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tagTriggerSettingsModalProps, createAndEditNewAutomation]);
	React.useEffect(() => {
		setTagTriggerSettingsModalProps({
			...tagTriggerSettingsModalProps,
			onRequestClose: (tag?: string, cancel?: boolean) => {
				onTagTriggerSettingsModalRequestCloseRef.current?.(tag, cancel);
			},
		});

		const triggerPromise = automationTemplatesViewModel.loadTriggers();
		if (triggerPromise) {
			logger.logEvent('TriggersLoad');
			triggerPromise.catch(err => {
				logger.logApiError('TriggersLoad-Error', err);
				showApiError(err);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// #endregion

	// #region New Lead trigger settings modal
	const [newLeadTriggerSettingsModalProps, setNewLeadTriggerSettingsModalProps] = React.useState<IModalProps>({
		isOpen: false,
		// @ts-ignore
		onRequestClose: null,
	});
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onNewLeadTriggerSettingsModalRequestCloseRef = React.useRef((_?: Api.INewLeadAutomationTrigger, __ = false) => {
		setNewLeadTriggerSettingsModalProps({
			...newLeadTriggerSettingsModalProps,
			isOpen: false,
		});
	});
	React.useEffect(() => {
		onNewLeadTriggerSettingsModalRequestCloseRef.current = async (
			triggerToSave?: Api.INewLeadAutomationTrigger,
			cancel = false
		) => {
			setNewLeadTriggerSettingsModalProps({
				...newLeadTriggerSettingsModalProps,
				isOpen: false,
			});
			if (!cancel && triggerToSave) {
				createAndEditNewAutomation(undefined, triggerToSave);
			}
		};
	}, [newLeadTriggerSettingsModalProps, createAndEditNewAutomation]);
	React.useEffect(() => {
		setNewLeadTriggerSettingsModalProps({
			...newLeadTriggerSettingsModalProps,
			onRequestClose: (triggerToSave?: Api.INewLeadAutomationTrigger, cancel?: boolean) => {
				onNewLeadTriggerSettingsModalRequestCloseRef.current?.(triggerToSave, cancel);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// #endregion

	// #region Event Registration trigger settings modal
	const [eventRegistrationModalIsOpen, setEventRegistrationModalIsOpen] = React.useState(false);
	const eventRegistrationModalOnClose = () => setEventRegistrationModalIsOpen(false);
	const eventRegistrationModalOnSurveySelected = (trigger: Api.IEventRegistrationTrigger) => {
		createAndEditNewAutomation(undefined, trigger);
	};
	// #endregion

	const onCardClicked = React.useCallback(
		(trigger: Api.IAutomationTrigger) => async () => {
			// @ts-ignore
			if (Api.VmUtils.Automations.triggers.automationTriggerModelTypesWithCreateOptions.indexOf(trigger?._type) >= 0) {
				// prompt for more info
				switch (trigger._type) {
					case Api.AutomationTriggerType.Tag: {
						setTagTriggerSettingsModalProps({
							...tagTriggerSettingsModalProps,
							isOpen: true,
						});
						break;
					}
					case Api.AutomationTriggerType.NewLead: {
						setNewLeadTriggerSettingsModalProps({
							...newLeadTriggerSettingsModalProps,
							isOpen: true,
						});
						break;
					}
					case Api.AutomationTriggerType.EventRegistration: {
						setEventRegistrationModalIsOpen(true);
						break;
					}
					default: {
						break;
					}
				}
			} else {
				createAndEditNewAutomation(undefined, trigger);
			}
		},
		[tagTriggerSettingsModalProps, newLeadTriggerSettingsModalProps, createAndEditNewAutomation]
	);

	return (
		<div className={`${routeContainerClassName} ${css(...styles)} create-automation-template ${className || ''}`}>
			<div className={css(styleSheet.container)}>
				<MultiContainerHeader
					appBarHeader={<NavigationBreadcrumbsBar currentLocationName={locationName} />}
					fullscreenHeader={locationName}
				/>
				<DarkMainContainerBackground />
				<section className={css(styleSheet.header)}>
					<h3>Create New Automation</h3>
					<p className={css(styleSheet.headerDetails)}>Select an automation type to begin</p>
				</section>
				<div className={css(styleSheet.cards)}>
					<div className={css(styleSheet.cardsContent)}>
						{automationTemplatesViewModel.isBusy ? (
							<LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />
						) : (
							automationTemplatesViewModel.supportedAvailableTriggerTypes.map((x, i) => {
								return (
									<NewAutomationTemplateCard
										key={`${x.trigger?._type}-${i}`}
										// @ts-ignore
										onClick={onCardClicked(x.trigger)}
										trigger={x.trigger}
										// @ts-ignore
										triggerName={x.name}
									/>
								);
							})
						)}
					</div>
				</div>
				<NewTagAutomationTemplateSetupSettingsModal
					modalProps={tagTriggerSettingsModalProps}
					// @ts-ignore
					trigger={automationTemplatesViewModel.availableTriggers?.TagAutomationTrigger?.trigger}
				/>
				{eventRegistrationModalIsOpen ? (
					<SelectASurveyModal
						trigger={automationTemplatesViewModel.availableTriggers.EventRegistrationTrigger.trigger}
						onClose={eventRegistrationModalOnClose}
						onSurveySelected={eventRegistrationModalOnSurveySelected}
					/>
				) : null}
				<NewLeadAutomationTemplateSetupSettingsModal
					modalProps={newLeadTriggerSettingsModalProps}
					// @ts-ignore
					trigger={
						automationTemplatesViewModel.supportedAvailableTriggerTypes?.find(
							x => x.trigger?._type === Api.AutomationTriggerType.NewLead
						)?.trigger
					}
				/>
			</div>
		</div>
	);
};

const CreateAutomationTemplateAsObserver = observer(CreateAutomationTemplateFc);
export const CreateAutomationTemplate = inject(ImpersonationContextKey)(withRouter(CreateAutomationTemplateAsObserver));
