import { brandSecondary } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	anotherCampaign: {
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.20)',
		marginTop: 24,
		padding: 16,
		width: '100%',
	},
	anotherCampaignButton: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 16,
	},
	anotherCampaignImage: {
		height: 48,
		marginRight: 16,
		width: 48,
	},
	anotherCampaignMessage: {
		color: '#4a4a4a',
		fontSize: 14,
		marginTop: 4,
	},
	anotherCampaignRow: {
		display: 'flex',
	},
	anotherCampaignText: {
		display: 'block',
	},
	anotherCampaignTitle: {
		color: brandSecondary,
		fontSize: 18,
	},
	button: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 24,
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
	},
	content: {
		display: 'block',
		maxWidth: 400,
	},
	image: {
		textAlign: 'center',
	},
	link: {
		color: brandSecondary,
		textDecoration: 'none',
	},
	message: {
		color: '#4a4a4a',
		fontSize: 14,
		marginTop: 16,
		textAlign: 'center',
	},
	title: {
		fontSize: 20,
		marginTop: 16,
		textAlign: 'center',
	},
});
