import AIAssistantIconImageUrl from '../../assets/aiAssistantIcon.png';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	styleDeclarationValues?: StyleDeclarationValue[];
}

export const AIAssistantButton = ({ styleDeclarationValues = [], children, ...restProps }: IProps) => {
	return (
		<button
			className={css(
				baseStyleSheet.horizontalStack,
				baseStyleSheet.brandLink,
				styleSheet.container,
				...styleDeclarationValues
			)}
			{...restProps}
		>
			<img className={css(styleSheet.icon)} src={AIAssistantIconImageUrl} />
			<span>{children}</span>
		</button>
	);
};
