import { SvgIcon } from '../../icons/SvgIcon';

export const BlogsCircleGraphic = () => {
	return (
		<SvgIcon width='100' height='100'>
			<circle cx='50' cy='50' r='50' fill='#0CF' fillOpacity='.096' />
			<mask id='a' width='100' height='100' x='0' y='0' maskUnits='userSpaceOnUse' style={{ maskType: 'luminance' }}>
				<circle cx='50' cy='50' r='50' fill='#fff' />
			</mask>
			<g mask='url(#a)'>
				<path fill='#E9EBEF' d='M-24 20h91v46a3 3 0 0 1-3 3h-85a3 3 0 0 1-3-3V20Z' />
				<path fill='#06F' d='M-24 18.5a3.5 3.5 0 0 1 3.5-3.5h84a3.5 3.5 0 0 1 3.5 3.5V20h-91v-1.5Z' />
				<path fill='#fff' d='M-22 23h87v44h-87z' />
				<path fill='#E9EBEF' d='M-11 30h67v1h-67zm21 5h24v1H10zm0 9h24v1H10zm0 4h24v1H10zm-11-9h47v1H-1z' />
				<rect width='13' height='3' x='43' y='56' fill='#06F' rx='1' />
				<path
					fill='#9F7761'
					d='M67 106h-2.033L64 98h3v8Zm8 0h-2.033L72 98h3v8Zm.51-24.291a1.813 1.813 0 0 1-.722-1.276A1.914 1.914 0 0 1 75.203 79L74 60l3.466.374.095 18.605c.56.66.587 1.682.064 2.378-.523.697-1.431.848-2.115.352Z'
				/>
				<path
					fill='#2F2E41'
					d='m67.213 101.999-2.316-.11a.77.77 0 0 1-.735-.763L64 77.775a.769.769 0 0 1 .883-.767l9.264 1.34c.379.053.66.375.661.756L76 100.741a.77.77 0 0 1-.772.775H72.73a.767.767 0 0 1-.762-.65s-1.886-16.23-2.145-16.22c-.26.005-1.808 16.676-1.808 16.676a.774.774 0 0 1-.767.678l-.036-.001Z'
				/>
				<path
					fill='#F3CE0D'
					d='M74.56 65.858a.757.757 0 0 1-.29-.564l-.267-5.123c-.055-1.051.647-1.977 1.626-2.145.978-.167 1.918.477 2.177 1.493l1.17 4.58c.1.399-.12.808-.495.917l-3.326.958a.667.667 0 0 1-.596-.116Z'
				/>
				<circle cx='71' cy='49' r='4' fill='#9F7761' />
				<path
					fill='#9F7761'
					d='M55.635 57.373c.021.091.035.184.042.278l7.538 4.2 1.832-1.02L67 63.302 63.08 66l-8.6-6.553a1.885 1.885 0 0 1-2.028-.5 1.731 1.731 0 0 1-.233-2.01 1.86 1.86 0 0 1 1.864-.922c.759.097 1.377.638 1.552 1.358Z'
				/>
				<path
					fill='#F3CE0D'
					d='M63.004 61.516a.687.687 0 0 1 .204-.566l3.451-3.387a1.985 1.985 0 0 1 2.615-.135c.779.618.956 1.714.41 2.538l-2.457 3.717a.72.72 0 0 1-.983.205l-2.92-1.86a.693.693 0 0 1-.32-.512Z'
				/>
				<path
					fill='#2F2E41'
					d='M73.956 52.561c-.798.851-2.28.394-2.385-.768a1.411 1.411 0 0 1 .002-.27c.054-.516.351-.983.28-1.526a.8.8 0 0 0-.146-.375c-.636-.852-2.13.381-2.73-.39-.368-.473.065-1.218-.218-1.747-.372-.697-1.477-.353-2.17-.735-.77-.425-.724-1.608-.216-2.327.618-.878 1.703-1.346 2.774-1.413 1.07-.068 2.134.222 3.134.612 1.136.443 2.262 1.055 2.962 2.054.85 1.215.931 2.85.506 4.27-.258.865-1.141 1.92-1.793 2.615Z'
				/>
				<path
					fill='#F3CE0D'
					d='M74.756 55.867a5.314 5.314 0 0 0-1.472-.78V54h-3.142v1.009c-2.129.598-3.702 2.434-3.996 4.665l-2.139 16.184a.765.765 0 0 0 .154.573c.123.16.306.263.505.282.741.076 2.279.368 4.246 1.482a6.213 6.213 0 0 0 3.094.805 7.333 7.333 0 0 0 2.517-.457.751.751 0 0 0 .483-.675c.077-1.742.528-10.707 1.84-16.175.521-2.194-.303-4.492-2.09-5.826Z'
				/>
			</g>
		</SvgIcon>
	);
};
