import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import Linkify from 'react-linkify';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { asModalComponent } from '../../Modal';
import { styleSheet } from './styles';

interface IProps extends IModalContext {
	className?: string;
	styles?: StyleDeclarationValue[];
}

const ViewCitations: React.FC<IProps> = props => {
	const { children, className, parentModal, styles = [] } = props;
	const onRenderLink = (decoratedHref: string, decoratedText: string, key: number) => {
		return (
			<a
				key={key}
				className={css(baseStyleSheet.brandLink)}
				href={decoratedHref}
				target='_blank'
				rel='noreferrer'
			>{`${decoratedText}`}</a>
		);
	};
	const onCitationRequestClose = () => {
		parentModal?.onRequestClose();
	};
	return (
		<div className={`${css(styleSheet.container, ...styles)} view-citations ${className || ''}`}>
			<article>
				<h3 className={css(styleSheet.contentBoxTitle)}>View Citations</h3>
				<p className={css(styleSheet.citationContentBox)}>
					<Linkify componentDecorator={onRenderLink}>{children}</Linkify>
				</p>
			</article>
			<button className={css(baseStyleSheet.ctaButton)} title='Cancel' onClick={onCitationRequestClose}>
				Close
			</button>
		</div>
	);
};

const ViewCitationsWithContext = inject(ModalChildComponentContextKey)(ViewCitations);
export const ViewCitationsModal = asModalComponent(ViewCitationsWithContext, { useDefaultHeader: true });
