import { StyleDeclaration, css } from 'aphrodite';
import * as React from 'react';
import { navigation } from '../../styles/colors';
import { bs } from '../../styles/styles';
import { AttachIcon } from '../svgs/icons/AttachIcon';

// The way we're working with input[type='file'] is a bit cumbersome.
// Involves creating a hidden input, attaching a ref and a button that does ref.current.click() on click.
// This is meant to replace that
// E.g. <AttachInputButton onChange={(ev) => { const files = ev.target.files; }} />
// Comes with a default attachment icon to work out of the box, but can be overriden by passing children
// E.g. <AttachInputButton onChange={(ev) => { const files = ev.target.files; }}><button>Attach</button></AttachInputButton>
export function AttachInputButton({
	styleDeclarations = [],
	width = 12,
	height = 18,
	fillColor = navigation,
	children,
	...props
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
	styleDeclarations?: StyleDeclaration[];
	fillColor?: string;
	children?: React.ReactNode;
}) {
	return (
		<div className={css(bs.relative, bs.cursorPointer, styleDeclarations)} style={{ width, height }}>
			{children ? children : <AttachIcon fillColor={fillColor} width={width} height={height} />}
			<input
				type='file'
				className={css(bs.absolute, bs.flex, bs.top0, bs.left0, bs.z0, bs.cursorPointer, bs.opacity0)}
				style={{ width, height, fontSize: 0 }}
				{...props}
			/>
		</div>
	);
}
