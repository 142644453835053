import * as Api from '@ViewModels';
import { useContactFilterContainsDuplicatesQuery } from '../../../../queries';
import { EmailDuplicateContactSendToggle } from './components/EmailDuplicateContactSendToggle';

interface Props {
	filterRequest: Api.IBulkContactsRequest;
	isSelected: boolean;
	onChange: (checked: boolean) => void;
}

export function EmailDuplicateContactSendCondition({ filterRequest, isSelected, onChange }: Props) {
	const filterContainsDuplicateQuery = useContactFilterContainsDuplicatesQuery({ filterRequest });
	if (filterContainsDuplicateQuery.isSuccess && filterContainsDuplicateQuery.data) {
		return <EmailDuplicateContactSendToggle isSelected={isSelected} onChange={onChange} />;
	}
	return null;
}
