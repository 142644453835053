import { StyleSheet } from 'aphrodite';
import { connectionStatusText, darkGrayFontColor, destructive, label } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	basicContainer: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		paddingBottom: 4,
		paddingLeft: 4,
		paddingRight: 8,
		paddingTop: 4,
	},
	connectionStatus: {
		color: connectionStatusText,
		marginLeft: 4,
	},
	disconnectionStatus: {
		color: destructive,
		marginLeft: 4,
	},
	iconRow: {
		alignItems: 'center',
		display: 'flex',
		height: 16,
		marginLeft: 8,
		...CssSelectors.allChildren(
			{
				height: 16,
				padding: 4,
			},
			'img'
		),
	},
	iconRowBasic: {
		height: 20,
		marginLeft: 0,
		...CssSelectors.allChildren(
			{
				paddingLeft: 0,
			},
			'img'
		),
	},
	nameAdjustment: {
		color: darkGrayFontColor,
		fontSize: 14,
		marginLeft: 6,
		marginRight: 6,
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: label,
		fontSize: 12,
		lineHeight: '18px',
		maxWidth: 180,
		padding: '6px 6px 6px 6px',
	},
	popoverMargin: {
		margin: 2,
	},
});
