import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { isIE11 } from '../../../models/Browser';
import { useEventLogging } from '../../../models/Logging';
import { EmailUnscubscibePlaceholder } from '../../../models/Token';
import { IOperationResultNoValue, ITemplate, TemplatesViewModel, asApiError } from '../../../viewmodels/AppViewModels';
import CustomCssUrl from '../HtmlNewsletterEditor/customCss.unlayer.css';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import escapeRegExp from 'lodash.escaperegexp';
import { inject } from 'mobx-react';
import * as React from 'react';

interface IProps extends IUserSessionComponentProps, IImpersonationContextComponentProps, IErrorMessageComponentProps {
	className?: string;
	style?: React.CSSProperties;
	styles?: StyleDeclarationValue[];
	template?: ITemplate;
}

const HtmlNewsletterPreviewSfc: React.FC<IProps> = props => {
	const { className, styles, template, userSession, impersonationContext, errorMessages, style } = props;
	const account = (impersonationContext?.isValid ? impersonationContext.account : null) || userSession?.account;
	const templateId = account?.features?.htmlNewsletter?.unsubscribeTemplateId;
	// @ts-ignore
	const [unsubscribeTemplate, setUnsubscribeTemplate] = React.useState<ITemplate>(null);
	const logger = useEventLogging('HtmlNewsletterPreview');

	// Load the unsubscribe template
	React.useEffect(() => {
		if (templateId) {
			// @ts-ignore
			new TemplatesViewModel(userSession)
				.impersonate(impersonationContext)
				.getById(templateId)
				.then(temp => {
					setUnsubscribeTemplate(temp);
				})
				.catch((err: IOperationResultNoValue) => {
					const error = asApiError(err);
					logger.logApiError('UnsubscribTemplateLoad-Error', error);
					errorMessages?.pushApiError(error);
					// @ts-ignore
					setUnsubscribeTemplate(null);
				});
		} else {
			// @ts-ignore
			setUnsubscribeTemplate(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateId]);

	// replace the unsubscribe footer placeholder with content from the unsubscribe template
	const srcDoc = React.useMemo(() => {
		let unsubscribeContent = '';
		if (unsubscribeTemplate) {
			// @ts-ignore
			// @ts-ignore
			unsubscribeContent = unsubscribeTemplate.content.document.replace(/<\/?lev-content>/gim, '');
		}
		const replacementRegExp = new RegExp(
			`<span[^/]*data-placeholder-value="${escapeRegExp(EmailUnscubscibePlaceholder.value)}"[^<]*</span>`,
			'igm'
		);
		return template?.content?.document?.replace(replacementRegExp, unsubscribeContent);
	}, [template, unsubscribeTemplate]);

	// add token css to iframe document
	const onLoad = React.useCallback((e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
		const doc = e.currentTarget.contentDocument;
		if (isIE11()) {
			// @ts-ignore
			// @ts-ignore
			doc.write(srcDoc);
		}
		// @ts-ignore
		const styleEl = doc.createElement('link');
		styleEl.setAttribute('rel', 'stylesheet');
		styleEl.setAttribute('type', 'text/css');
		styleEl.setAttribute('href', encodeURIComponent(CustomCssUrl));
		// @ts-ignore
		doc.head.appendChild(styleEl);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<iframe
			className={`${css(styleSheet.container, ...(styles || []))} html-newsletter-preview ${className || ''}`}
			frameBorder={0}
			onLoad={onLoad}
			srcDoc={!isIE11() ? srcDoc : undefined}
			style={style}
		/>
	);
};

export const HtmlNewsletterPreview = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey,
	ErrorMessagesViewModelKey
)(HtmlNewsletterPreviewSfc);
